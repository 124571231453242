import { ORDERS as types } from '../constants';
import { toOrderDetailsData, toOrderMetadata } from '../../utils';

const initialState = {
  orderDetails: {
    isLoading: false,
    isError: false,
    error: null,
    data: null
  }
};

const ordersReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.GETTING_ORDER_BY_ID:
      return {
        ...state,
        orderDetails: {
          ...state.orderDetails,
          isLoading: true,
          isError: false,
          error: null,
          data: null
        }
      };

    case types.GET_ORDER_BY_ID_SUCCESS:
      return {
        ...state,
        orderDetails: {
          ...state.orderDetails,
          isLoading: false,
          isError: false,
          error: null,
          data: toOrderDetailsData(payload)
        }
      };

    case types.GET_ORDER_BY_ID_FAILURE:
      return {
        ...state,
        orderDetails: {
          ...state.orderDetails,
          isLoading: false,
          isError: true,
          error: payload,
          data: null
        }
      };

    case types.CLEAR_ORDER_DETAILS:
      return {
        ...state,
        orderDetails: {
          ...state.orderDetails,
          isLoading: false,
          isError: false,
          error: null,
          data: null
        }
      };

    case types.UPDATE_ORDER_METADATA_SUCCESS:
      if (!state.orderDetails.data) return { ...state }; //dont alter if data doesnt already exist
      return {
        ...state,
        orderDetails: {
          ...state.orderDetails,
          data: {
            ...state.orderDetails.data,
            metadata: {
              ...(state.orderDetails.data?.metadata || {}),
              ...toOrderMetadata(payload)
            }
          }
        }
      };

    case types.UPDATE_ORDER_TICKET_DATA:
      const updatedOpsTicket = { ...payload };
      const updatedOrderDetails = {
        ...state?.orderDetails?.data,
        opsTicket: updatedOpsTicket
      };
      const finalState = {
        ...state,
        orderDetails: {
          ...state.orderDetails,
          isLoading: false,
          isError: false,
          error: null,
          data: toOrderDetailsData(updatedOrderDetails)
        }
      };
      return finalState;

    default:
      return state;
  }
};

export default ordersReducer;

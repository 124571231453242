import { HStack, VStack, Heading, Text, Spinner } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { getVdaStats } from '../../store/actions/funnelActions';
import { useDispatch } from 'react-redux';
import { convertHoursToHoursAndMinutes } from '../../utils';

const VdaStats = () => {
  const [stats, setStats] = useState({});
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    dispatch(
      getVdaStats({
        onSuccess: (data) => {
          setStats(
            Object.keys(data).reduce((prev, curr) => {
              return {
                ...prev,
                [curr]: Number(data[curr]).toFixed(2)
              };
            }, {})
          );
        },
        onComplete: () => {
          setLoading(false);
        }
      })
    );
  }, []);

  if (loading) return <Spinner color="white" />;

  return (
    <HStack px={'5'} width={'full'} justifyContent={'space-between'}>
      <VStack>
        <Heading fontSize={'xl'} color={'purple.100'}>
          {stats?.profit || '-'}
        </Heading>
        <Text fontSize={'md'} color={'colorPrimary'}>
          {'Profit per order'}
        </Text>
      </VStack>
      <VStack>
        <Heading fontSize={'xl'} color={'purple.100'}>
          {convertHoursToHoursAndMinutes(stats?.avgDuration) || '-'}
        </Heading>
        <Text fontSize={'md'} color={'colorPrimary'}>
          {'Average duration for transfer'}
        </Text>
      </VStack>
      <VStack>
        <Heading fontSize={'xl'} color={'purple.100'}>
          {stats?.totalProfit || '-'}
        </Heading>
        <Text fontSize={'md'} color={'colorPrimary'}>
          {'Total Profit Earned'}
        </Text>
      </VStack>
      <VStack>
        <Heading fontSize={'xl'} color={'purple.100'}>
          {Math.floor(stats?.totalTransactions) || '-'}
        </Heading>
        <Text fontSize={'md'} color={'colorPrimary'}>
          {'Total Transactions'}
        </Text>
      </VStack>
    </HStack>
  );
};

export default VdaStats;

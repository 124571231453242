import {
  Box,
  Grid,
  Heading,
  Text,
  VStack,
  Table,
  Thead,
  Th,
  Tr,
  Td,
  Tbody,
  HStack,
  Button
} from '@chakra-ui/react';
import { camelCaseToSpaceSeparated } from '../../utils';

const cellStyle = {
  border: 'none',
  padding: '5px'
};

const Content = ({ data }) => {
  if (!data) return <Text color={'white'}>-</Text>;
  if (typeof data !== 'object') {
    return (
      <Text
        color="white"
        maxWidth={'20rem'}
        whiteSpace={'break-word'}
        maxHeight={'30rem'}
        overflowY={'auto'}
      >
        {data}
      </Text>
    );
  }
  return (
    <Table cellSpacing={2} fontSize={'0.9rem'}>
      <Thead>
        <Tr>
          <Th style={{ ...cellStyle }}></Th>
          <Th style={{ ...cellStyle }}></Th>
        </Tr>
      </Thead>
      <Tbody>
        {Object.keys(data)
          .filter((key) => key !== 'status')
          .map((elKey) => {
            return (
              <Tr key={`content-${elKey}`} style={{ alignContent: 'start' }}>
                <Td
                  style={{
                    ...cellStyle
                  }}
                  verticalAlign={'top'}
                >
                  <Text
                    color="shadedGray.400"
                    maxWidth={'20rem'}
                    whiteSpace={'break-word'}
                  >
                    {camelCaseToSpaceSeparated(elKey)}:
                  </Text>
                </Td>
                <Td style={{ ...cellStyle }}>
                  {Array.isArray(data[elKey]) ? (
                    <>
                      {Object.values(data[elKey]).map((el) => (
                        <Content key={`content-${elKey}`} data={el} />
                      ))}
                    </>
                  ) : (
                    <Content data={data[elKey]} />
                  )}
                </Td>
              </Tr>
            );
          })}
      </Tbody>
    </Table>
  );
};

const NestedDataCard = ({
  isOnboarding = true,
  title = null,
  data,
  docKey = null,
  handleEditClick = () => {}
}) => {
  if (!data || typeof data !== 'object') return null;
  return (
    <Box width={'full'} p={6} bg={'altGray.400'} borderRadius={'1rem'}>
      <VStack width={'full'} alignItems={'stretch'} gap={2}>
        {title && (
          <HStack
            width={'auto'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Heading color={'white'} size={'md'}>
              {camelCaseToSpaceSeparated(title)}
            </Heading>
            {isOnboarding && (
              <Text fontSize={'md'} color={'white'}>
                {data?.status}
              </Text>
            )}
            {!isOnboarding && (
              <HStack>
                <Text fontSize={'md'} color={'white'}>
                  {data?.status}
                </Text>
                <Button
                  variant={'link'}
                  colorScheme="brand"
                  onClick={() => handleEditClick(docKey)}
                >
                  Edit
                </Button>
              </HStack>
            )}
          </HStack>
        )}
        <Content data={data} />
      </VStack>
    </Box>
  );
};

export default NestedDataCard;

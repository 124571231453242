import { Box, VStack } from '@chakra-ui/react';
import React from 'react';
import Pagination from '../Pagination';
import UserCard from '../UserCard';

const UsersList = ({
  users,
  pageNo,
  totalPages,
  handlePageChange,
  onUserCardClick,
  isNrUser = false
}) => {
  return (
    <>
      {totalPages > 0 && (
        <Box alignSelf={'start'} pt={2}>
          <Pagination
            totalPages={totalPages}
            currentPage={pageNo + 1}
            onPageChange={handlePageChange}
          />
        </Box>
      )}
      <VStack py={10} alignSelf={'flex-start'} maxW={'100%'}>
        {users?.length > 0 &&
          users.map((item, idx) => (
            <UserCard
              key={idx}
              user={item}
              onUserCardClick={onUserCardClick}
              isNrUser={isNrUser}
            />
          ))}
      </VStack>
    </>
  );
};

export default UsersList;

const MANUAL_PAYOUT_BATCH = {
  FETCH_SHEETS_REQUEST: 'FETCH_SHEETS_REQUEST',
  FETCH_SHEETS_SUCCESS: 'FETCH_SHEETS_SUCCESS',
  FETCH_SHEETS_FAILURE: 'FETCH_SHEETS_FAILURE',
  DOWNLOAD_SHEET_REQUEST: 'DOWNLOAD_SHEET_REQUEST',
  DOWNLOAD_SHEET_SUCCESS: 'DOWNLOAD_SHEET_SUCCESS',
  DOWNLOAD_SHEET_FAILURE: 'DOWNLOAD_SHEET_FAILURE',
  CREATE_BATCH_REQUEST: 'CREATE_BATCH_REQUEST',
  CREATE_BATCH_SUCCESS: 'CREATE_BATCH_SUCCESS',
  CREATE_BATCH_FAILURE: 'CREATE_BATCH_FAILURE',
  UPLOAD_SHEET_REQUEST: 'UPLOAD_SHEET_REQUEST',
  UPLOAD_SHEET_SUCCESS: 'UPLOAD_SHEET_SUCCESS',
  UPLOAD_SHEET_FAILURE: 'UPLOAD_SHEET_FAILURE',
  FETCH_BATCH_DETAILS_REQUEST: 'FETCH_BATCH_DETAILS_REQUEST',
  FETCH_BATCH_DETAILS_SUCCESS: 'FETCH_BATCH_DETAILS_SUCCESS',
  FETCH_BATCH_DETAILS_FAILURE: 'FETCH_BATCH_DETAILS_FAILURE',
  FETCH_PENDING_DETAILS_SUCCESS: 'FETCH_PENDING_DETAILS_SUCCESS',
  FETCH_PENDING_DETAILS_REQUEST: 'FETCH_PENDING_DETAILS_REQUEST',
  FETCH_PENDING_DETAILS_FAILURE: 'FETCH_PENDING_DETAILS_FAILURE',
};

export default MANUAL_PAYOUT_BATCH;
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getReconEntries,
  getRecentOrdersByUserId,
  mapReconToPayment
} from '../../store/actions/baerscrestActions';
import {
  VStack,
  Heading,
  Grid,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Spinner,
  Button,
  Input,
  HStack,
  Select,
  FormLabel,
  Text,
  Box
} from '@chakra-ui/react';
import ContentLayout from '../../layouts/ContentLayout';
import ModalLayout from '../../components/Modal/ModalLayout';
import { useDisclosure } from '@chakra-ui/react';
import Pagination from '../../components/Pagination';
import { updateBaerscrestOpsReqData } from '../../store/actions/reqDataActions';
import dayjs from 'dayjs';
import useDebounce from '../../hooks/useDebounce';
import { RECON_STATUS } from '../../constants';
import DatePicker from 'react-datepicker';
import { tableCellStyles } from '../../components/NrOnboard/constants';
import useIsMobile from '../../hooks/useIsMobile';
import { camelCaseToSpaceSeparated } from '../../utils';
import { FaArrowRight } from 'react-icons/fa6';
import GenericUpdateModal from '../../components/Modal/GenericUpdateModal';
import { setAlert } from '../../store/actions/alertActions';
const BaerscrestOpsPage = () => {
  const dispatch = useDispatch();
  const { baerscrestOps } = useSelector((state) => state.reqData);
  const { pageNumber, pageSize, fromDate, orderId, status, totalPages } =
    baerscrestOps;

  const debounced = useDebounce(orderId, 500);

  const [reconEntries, setReconEntries] = useState([]);
  const [selectedRecon, setSelectedRecon] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isMapToReconLoading, setIsMapToReconLoading] = useState(false);
  const [recentOrders, setRecentOrders] = useState([]);
  const [recentOrdersLoading, setRecentOrdersLoading] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const {
    isOpen: isMapOrderModalOpen,
    onOpen: onMapOrderModalOpen,
    onClose: onMapOrderModalClose
  } = useDisclosure();

  useEffect(() => {
    fetchReconEntries();
  }, []);

  useEffect(() => {
    selectedRecon && setSelectedRecon(null);
    fetchReconEntries();
  }, [status, pageNumber, debounced, pageSize, fromDate]);

  const fetchReconEntries = () => {
    setIsLoading(true);
    dispatch(
      getReconEntries({
        fromDate,
        pageNumber,
        pageSize,
        orderId,
        status,
        onComplete: () => {
          setIsLoading(false);
        },
        onSuccess: (data) => {
          setReconEntries(data?.data);
          dispatch(
            updateBaerscrestOpsReqData({
              ...baerscrestOps,
              totalPages: data?.totalPages
            })
          );
        }
      })
    );
  };

  const handleReqParamsChange = (e) => {
    const name = e?.target?.name;
    let value = e?.target?.value;

    if (name === 'fromDate') {
      value = dayjs(value).format('YYYY-MM-DD');
    }

    dispatch(
      updateBaerscrestOpsReqData({
        ...baerscrestOps,
        [name]: value
      })
    );
  };

  const handlePageChange = (page) => {
    console.log(page);
    page - 1 <= totalPages &&
      dispatch(
        updateBaerscrestOpsReqData({
          ...baerscrestOps,
          pageNumber: page - 1
        })
      );
  };

  useEffect(() => {
    setRecentOrders([]);
  }, [selectedRecon]);

  const handleMapToReconClick = (order) => {
    setSelectedOrder(order);
    onMapOrderModalOpen();
  };

  const handleMapOrderCancel = () => {
    selectedOrder && setSelectedOrder(null);
    onMapOrderModalClose();
  };

  const handleMapOrderSubmit = ({ remarks }) => {
    if (!remarks) {
      dispatch(
        setAlert({
          title: 'Remarks are required',
          status: 'error'
        })
      );
      return;
    }
    setIsMapToReconLoading(true);
    dispatch(
      mapReconToPayment({
        transactionId: selectedRecon?.transactionId,
        reqBody: {
          orderId: selectedOrder?.orderId,
          remarks
        },
        onSuccess: () => {
          fetchReconEntries();
        },
        onComplete: () => {
          setIsMapToReconLoading(false);
          handleMapOrderCancel();
        }
      })
    );
  };

  const cellStyles = tableCellStyles(12);
  const normalCellStyles = tableCellStyles();
  const isMobile = useIsMobile();

  return (
    <>
      <ModalLayout isOpen={isMapOrderModalOpen} onClose={handleMapOrderCancel}>
        <GenericUpdateModal
          title={`Map to Order - ${selectedOrder?.orderId}`}
          data={{
            // orderId: selectedOrder?.orderId,
            remarks: ''
          }}
          isLoading={isMapToReconLoading}
          onCancel={handleMapOrderCancel}
          onSubmit={handleMapOrderSubmit}
        />
      </ModalLayout>
      <ContentLayout>
        <Grid
          color={'white'}
          gridTemplateColumns={{ base: '1fr', lg: '7fr 0fr 2fr' }}
        >
          <VStack
            width={{ base: 'full', lg: '70vw' }}
            alignItems={'stretch'}
            gap={4}
            overflowY={{ base: 'initial', lg: 'scroll' }}
            overflowX={'hidden'}
            h={{ lg: '90vh' }}
            py={10}
            px={{ base: 4, sm: 10 }}
            className="scroll"
          >
            <Heading size={'lg'} color={'white'}>
              Baerscrest Ops
            </Heading>
            {!isLoading ? (
              <>
                <HStack my={3} width={'full'} justifyContent={'end'}>
                  <Box alignItems={'start'}>
                    <FormLabel color={'white'}>Search by OrderID</FormLabel>
                    <Input
                      value={orderId}
                      name="orderId"
                      onChange={handleReqParamsChange}
                      placeholder="Search by OrderID"
                      color={'white'}
                    />
                  </Box>
                  <Box>
                    <FormLabel>Page Size</FormLabel>
                    <Select
                      placeholder="Select Recon Status"
                      value={pageSize}
                      name="pageSize"
                      onChange={handleReqParamsChange}
                    >
                      {[10, 25, 50].map((key) => (
                        <option value={key} key={key}>
                          {key}
                        </option>
                      ))}
                    </Select>
                  </Box>
                  <Box>
                    <FormLabel>Status</FormLabel>
                    <Select
                      placeholder="Select Recon Status"
                      value={status}
                      name="status"
                      onChange={handleReqParamsChange}
                    >
                      {Object.keys(RECON_STATUS).map((key) => (
                        <option value={key} key={key}>
                          {key}
                        </option>
                      ))}
                    </Select>
                  </Box>

                  <Box>
                    <FormLabel color={'white'}>From Date</FormLabel>
                    <Input
                      type="date"
                      value={fromDate}
                      onChange={handleReqParamsChange}
                      name="fromDate"
                    />
                  </Box>
                </HStack>
                <Pagination
                  currentPage={pageNumber + 1}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
                <Table width={'full'}>
                  <Thead>
                    <Tr>
                      <Th style={cellStyles}>Transation ID</Th>
                      <Th style={cellStyles}>Order ID</Th>
                      <Th style={cellStyles}>Amount</Th>
                      <Th style={cellStyles}>Recon Status</Th>
                      <Th style={cellStyles}>Partner</Th>
                      <Th style={cellStyles}>Remarks</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {reconEntries?.map((recon, ind) => (
                      <Tr
                        key={recon?.transactionId || ind}
                        _hover={{
                          bg: 'gray.900'
                        }}
                        cursor={'pointer'}
                        onClick={() => {
                          setSelectedRecon(recon);
                        }}
                      >
                        <Td style={cellStyles}>
                          <Text color={'white'} fontSize={'md'}>
                            {recon?.transactionId || '-'}
                          </Text>
                        </Td>
                        <Td style={cellStyles}>
                          <Text color={'white'} fontSize={'md'}>
                            {recon?.orderId || '-'}
                          </Text>
                        </Td>
                        <Td style={cellStyles}>
                          <Text color={'white'} fontSize={'md'}>
                            {recon?.amount || '-'}
                          </Text>
                        </Td>
                        <Td style={cellStyles}>
                          <Text color={'white'} fontSize={'md'}>
                            {recon?.reconStatus || '-'}
                          </Text>
                        </Td>
                        <Td style={cellStyles}>
                          <Text color={'white'} fontSize={'md'}>
                            {recon?.partner || '-'}
                          </Text>
                        </Td>
                        <Td style={cellStyles}>
                          <Text color={'white'} fontSize={'md'}>
                            {recon?.remarks || '-'}
                          </Text>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </>
            ) : (
              <Spinner />
            )}
          </VStack>
          {!isMobile && selectedRecon && (
            <VStack
              width={'21rem'}
              alignItems={'start'}
              gap={2}
              m={0}
              px={4}
              py={10}
              maxH={'90vh'}
              className="scroll-sm"
              overflowY={'auto'}
            >
              <Heading color={'white'} size={'md'}>
                Recon Details
              </Heading>
              <Table>
                <Tbody>
                  {Object.keys(selectedRecon)?.map((key) => (
                    <Tr key={key} width={'full'}>
                      <Td style={tableCellStyles()}>
                        <Text flexGrow={1} color={'gray.500'} fontSize={'sm'}>
                          {`${camelCaseToSpaceSeparated(key)}:`}
                        </Text>
                      </Td>
                      <Td style={tableCellStyles()}>
                        <Text
                          whiteSpace={'normal'}
                          flexGrow={1}
                          color={'white'}
                          fontSize={'sm'}
                        >
                          {selectedRecon[key] || '-'}
                        </Text>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
              {/* TODO: check for button conditions here */}
              {true && (
                <Button
                  size={'md'}
                  width={'full'}
                  variant={'outline'}
                  colorScheme="brand"
                  onClick={() => {
                    setRecentOrdersLoading(true);
                    dispatch(
                      getRecentOrdersByUserId({
                        userId: selectedRecon?.userId,
                        onSuccess: (data) => {
                          setRecentOrders(data);
                        },
                        onComplete: () => {
                          setRecentOrdersLoading(false);
                        }
                      })
                    );
                  }}
                >
                  Get Recent Orders
                </Button>
              )}
              {recentOrders.length > 0 &&
                (!recentOrdersLoading ? (
                  <VStack width={'full'} alignItems={'stretch'} gap={2}>
                    {recentOrders?.map((order) => (
                      <Table
                        width={'full'}
                        variant={'unstyled'}
                        key={order?.orderId}
                        background={'#1c1c1c'}
                        padding={'2px 4px'}
                        boxShadow={'lg'}
                        borderRadius={'lg'}
                      >
                        <Tbody>
                          <Tr>
                            <Td style={normalCellStyles}>
                              <VStack alignItems={'stretch'}>
                                <Text
                                  fontSize={'xs'}
                                  color={'gray.500'}
                                  size={'xs'}
                                >
                                  OrderID
                                </Text>
                                <Text fontSize={'sm'} color={'white'}>
                                  {order?.orderId}
                                </Text>
                              </VStack>
                            </Td>
                            <Td style={normalCellStyles}>
                              <VStack alignItems={'stretch'}>
                                <Text
                                  fontSize={'xs'}
                                  color={'gray.500'}
                                  size={'xs'}
                                >
                                  Amount
                                </Text>
                                <Text fontSize={'sm'} color={'white'}>
                                  {order?.amount}
                                </Text>
                              </VStack>
                            </Td>
                          </Tr>
                          <Tr>
                            <Td style={normalCellStyles}>
                              <VStack alignItems={'stretch'}>
                                <Text
                                  fontSize={'xs'}
                                  color={'gray.500'}
                                  size={'xs'}
                                >
                                  Status
                                </Text>
                                <Text fontSize={'sm'} color={'white'}>
                                  {order?.orderStatus}
                                </Text>
                              </VStack>
                            </Td>
                            <Td style={normalCellStyles}>
                              <VStack alignItems={'stretch'}>
                                <Text
                                  fontSize={'xs'}
                                  color={'gray.500'}
                                  size={'xs'}
                                >
                                  Acquirer
                                </Text>
                                <Text fontSize={'sm'} color={'white'}>
                                  {order?.acquirer}
                                </Text>
                              </VStack>
                            </Td>
                          </Tr>
                          <Tr>
                            <Td></Td>
                            <Td>
                              <Button
                                size={'sm'}
                                variant={'outline'}
                                onClick={() => {
                                  handleMapToReconClick(order);
                                }}
                                colorScheme="brand"
                                rightIcon={<FaArrowRight />}
                              >
                                Map to Recon
                              </Button>
                            </Td>
                          </Tr>
                        </Tbody>
                      </Table>
                    ))}
                  </VStack>
                ) : (
                  <Spinner />
                ))}
            </VStack>
          )}
        </Grid>
      </ContentLayout>
    </>
  );
};

export default BaerscrestOpsPage;

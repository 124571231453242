import {
  Button,
  FormControl,
  FormLabel,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  Select,
  Text,
  VStack
} from '@chakra-ui/react';
import React, { useCallback, useState } from 'react';

const GenericSelectModal = ({
  referenceId,
  options,
  label,
  initialValue,
  placeholder,
  title,
  subtitle,
  handleSubmit,
  isLoading
}) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = useCallback((e) => {
    setValue(e.target.value);
  }, []);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleSubmit({
      referenceId,
      value
    });
  };
  return (
    <>
      <ModalHeader textAlign={'center'} py={0}>
        {title}
      </ModalHeader>
      {subtitle?.length && (
        <Text
          textAlign={'center'}
          fontSize={'0.75rem'}
          color={'shadedGray.400'}
        >
          {subtitle}
        </Text>
      )}
      <ModalCloseButton />
      <ModalBody>
        <FormControl as={'form'} onSubmit={handleFormSubmit} px={2}>
          <VStack gap={2} w={'100%'} alignItems={'stretch'}>
            <VStack alignItems={'flex-start'}>
              {label?.length && (
                <FormLabel
                  fontSize={'0.9rem'}
                  fontWeight={'500'}
                  mb={0}
                  lineHeight={'2rem'}
                  pr={2}
                >
                  {label}
                </FormLabel>
              )}
              <Select
                rounded={'lg'}
                placeholder={placeholder}
                name={'Status'}
                value={value}
                onChange={handleChange}
                size={'sm'}
                w={'100%'}
                _focusVisible={{
                  boxShadow: 'brand.200',
                  borderColor: 'brand.200'
                }}
                required
              >
                {options.map((option) => (
                  <option key={option} value={option} my={3}>
                    {option}
                  </option>
                ))}
              </Select>
            </VStack>
          </VStack>
          <ModalFooter justifyContent={'center'}>
            <Button
              colorScheme="brand"
              color="altGray.400"
              mr={3}
              size={'sm'}
              fontSize={'0.75rem'}
              type="submit"
              isLoading={isLoading}
            >
              Submit Now
            </Button>
          </ModalFooter>
        </FormControl>
      </ModalBody>
    </>
  );
};

export default GenericSelectModal;

export default function baerscrestOpsApi(fetch, baseUrl) {
  return {
    getReconEntries({ fromDate, status, orderId, pageNumber, pageSize }) {
      return fetch.get(
        `${baseUrl}/recon-entries?from_date=${fromDate}
        ${status && '&status=' + status}${
          orderId && '&order_id=' + orderId
        }&page_number=${pageNumber}&page_size=${pageSize}`
      );
    },
    mapReconToPayment({ transactionId, reqBody }) {
      return fetch.post(`${baseUrl}/map-to-payment/${transactionId}`, reqBody);
    },
    getRecentOrdersByUserId(userId) {
      return fetch.get(`${baseUrl}/recent_orders/${userId}`);
    }
  };
}

const RES_DATA = {
  SEARCHING_USERS: 'SEARCHING_USERS',
  SEARCH_USERS_SUCCESS: 'SEARCH_USERS_SUCCESS',
  SEARCH_USERS_FAILURE: 'SEARCH_USERS_FAILURE',

  SEARCHING_ORDERS: 'SEARCHING_ORDERS',
  SEARCH_ORDERS_SUCCESS: 'SEARCH_ORDERS_SUCCESS',
  SEARCH_ORDERS_FAILURE: 'SEARCH_ORDERS_FAILURE',

  SEARCHING_LULU_ORDERS: 'SEARCHING_LULU_ORDERS',
  SEARCH_LULU_ORDERS_SUCCESS: 'SEARCH_LULU_ORDERS_SUCCESS',
  SEARCH_LULU_ORDERS_FAILURE: 'SEARCH_LULU_ORDERS_FAILURE'
};

export default RES_DATA;

import api from '../../api';
import { FILTER_BY_FLAG } from '../../constants';
import { getErrorMessage, transformToNrobFunnelReqData } from '../../utils';
import { FUNNEL as types } from '../constants';
import { setAlert } from './alertActions';

export const getFunnelOrders =
  ({
    status,
    pageNo = 0,
    pageSize = 10,
    filterByFlag = FILTER_BY_FLAG.UNFILTERED
  }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: types.GETTING_FUNNEL_ORDERS,
        payload: {
          status,
          filterByFlag,
          pageNo
        }
      });

      const { data } = await api.orders.funnelOrders({
        status: status.toUpperCase(),
        filterByFlag: filterByFlag,
        pageNo,
        pageSize
      });

      return dispatch({
        type: types.GET_FUNNEL_ORDERS_SUCCESS,
        payload: {
          status,
          filterByFlag,
          data
        }
      });
    } catch (error) {
      console.log(error);
      return dispatch({
        type: types.GET_FUNNEL_ORDERS_FAILURE,
        payload: {
          status,
          error: error,
          filterByFlag,
          pageNo
        }
      });
    }
  };

export const getFunnelkycUsers =
  ({ status, pageNo = 0, pageSize = 10 }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: types.GETTING_FUNNEL_USERS,
        payload: {
          status,
          pageNo
        }
      });

      const { data } = await api.user.kycFunnelUsers({
        status: status.toUpperCase(),
        pageNo,
        pageSize
      });

      return dispatch({
        type: types.GET_FUNNEL_USERS_SUCCESS,
        payload: {
          status,
          data
        }
      });
    } catch (error) {
      console.log(error);
      return dispatch({
        type: types.GET_FUNNEL_USERS_FAILURE,
        payload: {
          status,
          error,
          pageNo
        }
      });
    }
  };

export const getLuluFunnelOrders =
  ({ tab, pageNo = 0, pageSize = 10 }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: types.GETTING_LULU_FUNNEL_ORDERS,
        payload: {
          tab,
          pageNo
        }
      });

      const { data } = await api.orders.luluFunnelOrders({
        tab: tab.toUpperCase(),
        pageNo,
        pageSize
      });

      return dispatch({
        type: types.GET_LULU_FUNNEL_ORDERS_SUCCESS,
        payload: {
          tab,
          data
        }
      });
    } catch (error) {
      console.log(error);
      return dispatch({
        type: types.GET_LULU_FUNNEL_ORDERS_FAILURE,
        payload: {
          tab,
          error: error,
          pageNo
        }
      });
    }
  };

export const getFunnelV2Orders =
  (status, acquirer, searchReqData, ordersInPastHours, orderType) =>
  async (dispatch) => {
    try {
      dispatch({
        type: types.GETTING_FUNNEL_V2_ORDERS,
        payload: {
          status
        }
      });

      const { data } = await api.orders.funnelOrdersV2({
        status,
        acquirer,
        ordersInPastHours,
        orderType,
        ...searchReqData
      });

      return dispatch({
        type: types.GET_FUNNEL_V2_ORDERS_SUCCESS,
        payload: {
          status,
          data
        }
      });
    } catch (error) {
      dispatch(
        setAlert({
          title: getErrorMessage({
            error,
            genericMessage: `Failed to get orders for status ${status}!`
          }),
          status: 'error'
        })
      );
      dispatch({
        type: types.GET_FUNNEL_V2_ORDERS_FAILURE,
        payload: {
          status,
          error
        }
      });
    }
  };

export const getNronboardFunnel =
  ({
    funnelState,
    funnelSubstate = null,
    query = null,
    pageNo = 0,
    pageSize = 20,
    country = null,
    additionalFilters = null
  }) =>
  async (dispatch) => {
    try {
      const reqData = transformToNrobFunnelReqData({
        query,
        filters: {
          country,
          funnelState,
          funnelSubstate
        },
        pageNo,
        pageSize,
        additionalFilters
      });
      dispatch({
        type: types.GETTING_NROB_FUNNEL,
        payload: {}
      });
      const { data } = await api.nronboard.getFunnelData({
        data: reqData
      });
      dispatch({
        type: types.GET_NROB_FUNNEL_SUCCESS,
        payload: {
          funnelState,
          data: data?.data,
          totalSize: data?.totalResults,
          page: data?.page,
          totalPages: data?.totalPages
        }
      });
    } catch (error) {
      console.error(error?.message);
      dispatch({
        type: types.GET_NROB_FUNNEL_FAILURE,
        payload: {
          errMsg: error?.message
        }
      });
      // dispatch(
      //   setAlert({
      //     title: error?.message,
      //     status: 'error'
      //   })
      // );
    }
  };

export const getVdaTransactions =
  ({ status, page, pageSize, search = '' }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: types.GETTING_SABER_FUNNEL
      });

      const { data } = await api.vdaFunnel.getTransactionsByStatus({
        status,
        page: page - 1,
        pageSize,
        search
      });

      dispatch({
        type: types.GET_SABER_FUNNEL_SUCCESS,
        payload: {
          data: { ...data },
          status
        }
      });
    } catch (error) {
      console.error(error?.message);
      dispatch({
        type: types.GET_SABER_FUNNEL_FAILURE,
        payload: {
          errMsg: error?.message
        }
      });
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    }
  };

export const getVdaStats =
  ({ onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      const { data } = await api.vdaFunnel.getStats();
      onSuccess && onSuccess(data);
    } catch (error) {
      console.error(error.message);
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

import api from '../../api';
import { setAlert } from './alertActions';

export const getPlatforms =
  ({ onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      const { data } = await api.platformTransferFees.getPlatforms();
      onSuccess && onSuccess(data['providersResponse']);
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const updatePlatform =
  ({ platformData, onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      const { data } = await api.platformTransferFees.updatePlatform({
        payload: {
          providers_request: [{ ...platformData }]
        }
      });
      onSuccess && onSuccess(data['providersResponse'][0]);
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

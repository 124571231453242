import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  VStack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  useToast
} from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { createUser } from '../../store/actions/accessManagementActions';

const CreateUserModal = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const [formData, setFormData] = useState({
    user_name: '',
    phone_no: '',
    email: '',
    password: ''
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: '' });
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.user_name.trim()) {
      newErrors.user_name = 'Name is required';
    }

    const phoneRegex = /^\d+$/;
    if (!phoneRegex.test(formData.phone_no)) {
      newErrors.phone_no = 'Phone number should contain only digits';
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      newErrors.email = 'Invalid email address';
    }

    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
    if (!passwordRegex.test(formData.password)) {
      newErrors.password = 'Password must be at least 8 characters long and contain both letters and numbers';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsLoading(true);
      try {
        await dispatch(createUser(formData));
        onClose();
        toast({
          title: 'User created successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } catch (error) {
        console.error('Error in handleSubmit:', error);
        toast({
          title: 'Failed to create user',
          description: error.message || 'An error occurred',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    } else {
      toast({
        title: 'Validation Error',
        description: 'Please check the form for errors',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
      <ModalContent bg="rgba(0, 0, 0, 0.8)" borderColor="whiteAlpha.200" borderWidth={1}>
        <ModalHeader color="brand.500">Create New User</ModalHeader>
        <ModalCloseButton color="whiteAlpha.700" />
        <ModalBody>
          <VStack as="form" onSubmit={handleSubmit} spacing={4}>
            <FormControl isInvalid={!!errors.user_name}>
              <FormLabel color="whiteAlpha.900">Name</FormLabel>
              <Input bg="whiteAlpha.300" color="white" name="user_name" value={formData.user_name} onChange={handleChange} required />
              <FormErrorMessage>{errors.user_name}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.phone_no}>
              <FormLabel color="whiteAlpha.900">Phone</FormLabel>
              <Input bg="whiteAlpha.300" color="white" name="phone_no" value={formData.phone_no} onChange={handleChange} required />
              <FormErrorMessage>{errors.phone_no}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.email}>
              <FormLabel color="whiteAlpha.900">Email</FormLabel>
              <Input bg="whiteAlpha.300" color="white" name="email" value={formData.email} onChange={handleChange} required />
              <FormErrorMessage>{errors.email}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.password}>
              <FormLabel color="whiteAlpha.900">Password</FormLabel>
              <Input bg="whiteAlpha.300" color="white" name="password" type="password" value={formData.password} onChange={handleChange} required />
              <FormErrorMessage>{errors.password}</FormErrorMessage>
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button variant="outline" mr={3} onClick={onClose} color="whiteAlpha.700">
            Cancel
          </Button>
          <Button
            bg="brand.500"
            color="black"
            _hover={{ bg: 'brand.600' }}
            onClick={handleSubmit}
            isLoading={isLoading}
          >
            Create User
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreateUserModal;
import {
  Box,
  Button,
  FormLabel,
  HStack,
  Input,
  ModalBody,
  ModalFooter,
  ModalHeader,
  VStack
} from '@chakra-ui/react';
import { useState } from 'react';

const WithdrawEventModal = ({ isLoading, onCancel, onSubmit }) => {
  const [modalState, setModalState] = useState({
    eventId: '',
    withdrawAmt: ''
  });

  const handleChange = (e) => {
    const key = e.target.name;
    const value = e.target.value;
    setModalState((prev) => ({
      ...prev,
      [key]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit &&
      onSubmit({
        ...modalState,
        withdrawAmt: Number.parseFloat(modalState.withdrawAmt)
      });
  };

  return (
    <>
      <ModalHeader>Withdraw</ModalHeader>
      <form onSubmit={handleSubmit}>
        <ModalBody>
          <VStack width={'full'} alignItems={'stretch'} gap={4}>
            <Box>
              <FormLabel>Withdraw Amount</FormLabel>
              <Input
                name="withdrawAmt"
                type="number"
                value={modalState.withdrawAmt}
                onChange={handleChange}
              />
            </Box>
            <Box>
              <FormLabel>Event ID</FormLabel>
              <Input
                name="eventId"
                type="text"
                value={modalState.eventId}
                onChange={handleChange}
              />
            </Box>
            <HStack justifyContent={'end'} gap={2}>
              <Button
                type="button"
                onClick={onCancel}
                variant={'outline'}
                colorScheme="red"
                isDisabled={isLoading}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant={'outline'}
                colorScheme="brand"
                isLoading={isLoading}
              >
                Submit
              </Button>
            </HStack>
          </VStack>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </form>
    </>
  );
};

export default WithdrawEventModal;

const PROVIDER_HOLIDAYS = {
  GETTING_ALL_HOLIDAYS: 'GETTING_ALL_HOLIDAYS',
  GET_ALL_HOLIDAYS_SUCCESS: 'GET_ALL_HOLIDAYS_SUCCESS',
  GET_ALL_HOLIDAYS_FAILURE: 'GET_ALL_HOLIDAYS_FAILURE',
  CREATING_HOLIDAY: 'CREATING_HOLIDAY',
  CREATE_HOLIDAY_SUCCESS: 'CREATE_HOLIDAY_SUCCESS',
  CREATE_HOLIDAY_FAILURE: 'CREATE_HOLIDAY_FAILURE'
};

export default PROVIDER_HOLIDAYS;

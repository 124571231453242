import React, { useEffect, useMemo, useState } from 'react';
import {
  Stack,
  Grid,
  Box,
  StackDivider,
  VStack,
  RadioGroup,
  Radio,
  HStack,
  Text,
  useDisclosure,
  Button
} from '@chakra-ui/react';
import { Link, useSearchParams } from 'react-router-dom';
import UserDetails from './UserDetails';
import { useParams } from 'react-router-dom';
import OrderDetailsCard from '../../components/OrderDetailsCard';
import ContentLayout from '../../layouts/ContentLayout';
import { useSelector, useDispatch } from 'react-redux';
import {
  clearUserDetails,
  clearUserReferralCode,
  deleteUser,
  getUserById,
  getUserReferralCode,
  syncWallets
} from '../../store/actions/usersActions';
import FullPageSpinner from '../../components/FullPageSpinner';
import useIsMobile from '../../hooks/useIsMobile';
import useIsAdmin from '../../hooks/useIsAdmin';
import useAuthRole from '../../hooks/useAuthRole';
import UserTransfers from './UserTransfers';
import { ORDER_PRODUCTS, ROLES } from '../../constants';
import UserInvestments from './UserInvestments';
import useDidMountEffect from '../../hooks/useDidMount';
import UserSips from './UserSips';
import InvestmentOrderDetailsCard from '../../components/InvestmentOrderDetailsCard';
import KycV2Details from './KycV2Details';
import { MdOutlineAccountBalance } from 'react-icons/md';
import ModalLayout from '../../components/Modal/ModalLayout';
import ConfirmModal from '../../components/Modal/ConfirmForceInitiate';
import { syncVanceCashWallet } from '../../store/actions/vanceCashActions';
import { FaUserFriends } from 'react-icons/fa';
import { PiStudentFill } from 'react-icons/pi';

const User = () => {
  const dispatch = useDispatch();
  const [isSyncingWallet, setIsSyncingWallet] = useState(false);
  const { data: userDetails, isLoading: isLoadingUser } = useSelector(
    (state) => state.users.userDetails
  );
  const isAdmin = useIsAdmin();
  const { hasAnyAuthority } = useAuthRole();
  const [search] = useSearchParams();
  const initialOrderId = search.get('orderId');
  const initialOrderType = search.get('product');
  const { userId } = useParams();
  const [selectedOrder, setSelectedOrder] = useState({
    orderId: initialOrderId || null,
    product: initialOrderType || null
  });
  const [activeOrderType, setActiveOrderType] = useState(
    initialOrderType || ORDER_PRODUCTS.REMITTANCE
  );
  const [deleteBtnLoading, setDeleteBtnLoading] = useState(false);
  const {
    isOpen: isConfirmDeleteOpen,
    onOpen: onConfirmDeleteOpen,
    onClose: onConfirmDeleteClose
  } = useDisclosure();

  const { referralCode: referralCodeData } = useSelector(
    (state) => state.users.userDetails
  );
  const referralCode = useMemo(
    () => referralCodeData?.data?.referralCode,
    [referralCodeData]
  );
  const isMobile = useIsMobile();

  useEffect(() => {
    dispatch(getUserById(userId));
    dispatch(getUserReferralCode(userId));
  }, [dispatch, userId]);

  useEffect(() => {
    return () => {
      dispatch(clearUserDetails());
      dispatch(clearUserReferralCode());
    };
  }, []);

  useDidMountEffect(() => {
    setSelectedOrder(null);
  }, [activeOrderType]);

  const onWalletSync = () => {
    setIsSyncingWallet(true);
    dispatch(
      syncWallets({ userId, onFinish: () => setIsSyncingWallet(false) })
    );
  };

  const handleVanceCashWalletSync = () => {
    setIsSyncingWallet(true);
    dispatch(
      syncVanceCashWallet({
        userId,
        onSuccess: (data) => {
          dispatch(getUserById(userId));
        },
        onComplete: () => {
          setIsSyncingWallet(false);
        }
      })
    );
  };

  const handleDeleteClick = () => {
    onConfirmDeleteOpen();
  };

  const handleDeleteModalConfirm = () => {
    setDeleteBtnLoading(true);
    dispatch(
      deleteUser({
        userId,
        onSuccess: () => {
          dispatch(getUserById(userId));
        },
        onComplete: () => {
          setDeleteBtnLoading(false);
          onConfirmDeleteClose();
        }
      })
    );
  };

  if (isLoadingUser) return <FullPageSpinner />;

  return (
    <>
      <ModalLayout isOpen={isConfirmDeleteOpen} onClose={onConfirmDeleteClose}>
        <ConfirmModal
          prompt={'Are you sure you want to delete this user ?'}
          handleSubmit={handleDeleteModalConfirm}
          isLoading={deleteBtnLoading}
        />
      </ModalLayout>
      <ContentLayout>
        <Grid gridTemplateColumns={{ base: '1fr', lg: '7fr 2fr' }}>
          <VStack
            overflowY={{ base: 'initial', lg: 'auto' }}
            overflowX={'hidden'}
            h={{ lg: '90vh' }}
            py={10}
            px={{ base: 4, sm: 10 }}
            alignItems={'stretch'}
            className="scroll"
            divider={
              <StackDivider
                style={{ margin: '0' }}
                borderColor="whiteAlpha.300"
              />
            }
            gap={2}
          >
            {hasAnyAuthority(ROLES.ADMIN_ROLE, ROLES.MANAGER_ROLE) && (
              <HStack justifyContent={'end'} alignItems={'center'} gap={2}>
                <Button
                  variant={'outline'}
                  colorScheme="red"
                  isLoading={deleteBtnLoading}
                  isDisabled={userDetails?.phoneNumber?.endsWith('-delete')}
                  onClick={handleDeleteClick}
                >
                  Delete
                </Button>
              </HStack>
            )}
            <VStack
              alignItems={userDetails?.kycData ? 'center' : 'flex-start'}
              pb={5}
            >
              <Grid
                gridTemplateColumns={{ base: '1fr', xl: 'auto auto' }}
                alignItems={'center'}
                justifyContent={'flex-start'}
                gap={{ base: '1.25rem', xl: '3rem', '2xl': '4.25rem' }}
                width={'100%'}
              >
                {userDetails && (
                  <UserDetails
                    userData={userDetails}
                    referralCode={referralCode}
                    allowAddFreeTransfer={isAdmin}
                    allowSyncWallet={hasAnyAuthority(
                      ROLES.ADMIN_ROLE,
                      ROLES.MANAGER_ROLE
                    )}
                    onWalletSync={onWalletSync}
                    isSyncingWallet={isSyncingWallet}
                    onVanceCashWalletSync={handleVanceCashWalletSync}
                  />
                )}

                <VStack alignItems={'start'} height={'full'} gap={3}>
                  {/* {userDetails?.userKycs?.length && ( */}
                  <KycV2Details
                    kycData={userDetails?.userKycs || []}
                    username={userDetails?.name || ''}
                    userId={userId}
                    country={userDetails?.countryCode}
                  />
                  {/* )} */}
                  <Link
                    to={`/user/${userId}/source-accounts`}
                    justifyContent={'flex-start'}
                    w={'100%'}
                    fontSize={'sm'}
                  >
                    <HStack>
                      <MdOutlineAccountBalance
                        display={'inline'}
                        color="green"
                      />
                      <Text color="brand.500">Source Accounts</Text>
                    </HStack>
                  </Link>
                  <Link
                    to={`/user/${userId}/beneficiaries`}
                    justifyContent={'flex-start'}
                    w={'100%'}
                    fontSize={'sm'}
                  >
                    <HStack>
                      <FaUserFriends display={'inline'} color="green" />
                      <Text color="brand.500">Beneficiaries</Text>
                    </HStack>
                  </Link>
                  <Link
                    to={`/user/${userId}/student-program`}
                    justifyContent={'flex-start'}
                    w={'100%'}
                    fontSize={'sm'}
                  >
                    <HStack>
                      <PiStudentFill display={'inline'} color="green" />
                      <Text color="brand.500">Student Program</Text>
                    </HStack>
                  </Link>
                </VStack>

                {/* {!userDetails?.userKycs?.length && (
                  <Link
                    to={`/user/${userId}/source-accounts`}
                    justifyContent={'flex-start'}
                    w={'100%'}
                    fontSize={'sm'}
                  >
                    <HStack>
                      <MdOutlineAccountBalance
                        display={'inline'}
                        color="brand.500"
                      />
                      <Text color="brand.500">Source Accounts</Text>
                    </HStack>
                  </Link>
                )} */}
              </Grid>
            </VStack>

            <VStack mt={5}>
              <RadioGroup
                alignSelf={'start'}
                color={'white'}
                onChange={(value) => setActiveOrderType(value)}
                value={activeOrderType}
              >
                <Stack spacing={5} direction="row">
                  <Radio colorScheme="brand" value={ORDER_PRODUCTS.REMITTANCE}>
                    Transfers
                  </Radio>
                  <Radio colorScheme="brand" value={ORDER_PRODUCTS.INVEST_MF}>
                    Investments
                  </Radio>
                  <Radio
                    colorScheme="brand"
                    value={ORDER_PRODUCTS.INVEST_MF_SIP}
                  >
                    SIPs
                  </Radio>
                </Stack>
              </RadioGroup>
              {activeOrderType === ORDER_PRODUCTS.REMITTANCE ? (
                <UserTransfers
                  userId={userId}
                  setSelectedOrder={setSelectedOrder}
                />
              ) : activeOrderType === ORDER_PRODUCTS.INVEST_MF ? (
                <UserInvestments
                  userId={userId}
                  setSelectedOrder={setSelectedOrder}
                />
              ) : (
                <UserSips userId={userId} />
              )}
            </VStack>
          </VStack>

          {!isMobile && (
            <Box m={0} alignSelf={'flex-start'}>
              {selectedOrder?.orderId &&
                (selectedOrder.product === ORDER_PRODUCTS.INVEST_MF ? (
                  <InvestmentOrderDetailsCard
                    userId={userId}
                    orderId={selectedOrder.orderId}
                    isSidebar
                  />
                ) : (
                  <OrderDetailsCard
                    userId={userId}
                    orderId={selectedOrder.orderId}
                    isSidebar
                  />
                ))}
            </Box>
          )}
        </Grid>
      </ContentLayout>
    </>
  );
};

export default User;

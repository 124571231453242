export default function sourceAccountApi(fetch, baseUrl) {
  return {
    getSourceAccounts({
      acquirer = 'LEANTECH',
      status,
      pageNo = 0,
      pageSize = 10
    }) {
      return fetch.get(
        `${baseUrl}?${
          status?.length ? `status=${status}` : ''
        }&acquirer=${acquirer}&page=${pageNo}&page_size=${pageSize}`
      );
    }
  };
}

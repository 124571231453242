import { BAERSCREST_OPS as types } from '../constants/baerscrestConstants';

const initialState = {
  reconEntries: [],
  isLoading: false,
  totalPages: 0,
  totalResults: 0,
};

const baerscrestOpsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GETTING_RECON_ENTRIES:
      return { ...state, isLoading: true };
    case types.GET_RECON_ENTRIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        reconEntries: action.payload.data,
        totalPages: action.payload.totalPages,
        totalResults: action.payload.totalResults,
      };
    case types.GET_RECON_ENTRIES_FAILURE:
      return { ...state, isLoading: false };
    case types.GETTING_RECENT_ORDERS:
      return { ...state, isLoadingRecentOrders: true };
    case types.GET_RECENT_ORDERS_SUCCESS:
      return { ...state, isLoadingRecentOrders: false, recentOrders: action.payload };
    case types.GET_RECENT_ORDERS_FAILURE:
      return { ...state, isLoadingRecentOrders: false };
    default:
      return state;
  }
};

export default baerscrestOpsReducer;
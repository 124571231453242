import React, { useState } from 'react';
import {
  Flex,
  ModalBody,
  Textarea,
  Button,
  ModalFooter,
  FormControl,
  FormLabel,
  ModalHeader,
  ModalCloseButton,
  Text,
  Box
} from '@chakra-ui/react';
import NumberInputComponent from '../NumberInput';

const UpdateBenchmarkReturns = ({
  benchmark,
  currentValue,
  handleSubmit,
  isLoading
}) => {
  const [returnValue, setReturnValue] = useState(currentValue);

  const handleChange = (e) => setReturnValue(e.target.value);

  const handleLocalSubmit = (e) => {
    e.preventDefault();
    handleSubmit({ benchmark, returnValue });
  };

  return (
    <>
      <ModalHeader textAlign={'center'} py={0}>
        Update {benchmark} Returns
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <FormControl as={'form'} onSubmit={handleLocalSubmit}>
          <Box my={'1rem'}>
            <Flex
              w={'20%'}
              justifyContent={'flex-start'}
              height={'100%'}
              alignItems={'center'}
            >
              <FormLabel
                alignSelf={'flex-end'}
                fontSize={'0.75rem'}
                fontWeight={'500'}
                mb={0}
                lineHeight={'2rem'}
                pr={2}
              >
                Returns :
              </FormLabel>
            </Flex>
            <NumberInputComponent
              min={0}
              required={true}
              handleChange={(value) =>
                handleChange({
                  target: {
                    name: 'returnValue',
                    value
                  }
                })
              }
              value={returnValue}
            />
          </Box>

          <ModalFooter justifyContent={'center'}>
            <Button
              colorScheme="brand"
              color="altGray.400"
              mr={3}
              size={'sm'}
              fontSize={'0.75rem'}
              type="submit"
              isLoading={isLoading}
            >
              Submit
            </Button>
          </ModalFooter>
        </FormControl>
      </ModalBody>
    </>
  );
};

export default UpdateBenchmarkReturns;

import {
  HStack,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  VStack,
  Box,
  FormLabel,
  Input
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { searchUserDocs } from '../../store/actions/workflowActions';
import { useParams } from 'react-router-dom';
import api from '../../api';
import { setAlert } from '../../store/actions/alertActions';
import {
  camelCaseString,
  camelCaseToSpaceSeparated,
  kebabCaseToSpaceSeperate
} from '../../utils';
import { triggerWorkflowByDoc } from '../../store/actions/workFlowsActionsV2';
import { KYC_PROVIDER } from '../../constants';

function getDocLinks(doc, docLinks) {
  switch (doc) {
    case 'PASSPORT_FRONT':
      return docLinks?.filter(
        (doc) =>
          doc?.documentId === 'PASSPORT_FRONT' ||
          doc?.documentId === 'PASSPORT_BACK'
      );
    case 'BANK_STATEMENT':
      return docLinks?.filter((doc) => doc?.documentId === 'BANK_STATEMENT');
  }
}

function getInitialStateBasedOnDoc({ doc, ocrData, kycProvider }) {
  const key = camelCaseString(doc);
  const docData = ocrData[key]?.ocrData;
  switch (doc) {
    case 'PASSPORT_FRONT':
      return !docData
        ? {
            passportNumber: '',
            fullName: '',
            ...(kycProvider && kycProvider === KYC_PROVIDER.ENCRYPTUS
              ? {
                  dateOfExpiry: '',
                  issuingCountry: ''
                }
              : {})
          }
        : {
            passportNumber: docData?.passportNumber || '',
            fullName: [...docData?.firstName, ...docData?.lastName].join(' '),
            ...(kycProvider && kycProvider === KYC_PROVIDER.ENCRYPTUS
              ? {
                  dateOfExpiry: docData?.dateOfExpiry || '',
                  issuingCountry: docData?.issuingCountry || ''
                }
              : {})
          };
    case 'BANK_STATEMENT':
      return {
        accountNumber: docData?.accountNumber
      };
    default:
      return {};
  }
}

function transformStateToPaylod({ state, doc, kycProvider }) {
  switch (doc) {
    case 'PASSPORT_FRONT':
      const cleaned = String(state?.fullName)
        .trim()
        .split(' ')
        .filter((val) => val !== '')
        .map((val) => val.trim());
      if (cleaned.length < 2) {
        throw new Error('Please enter a valid name');
      }

      if (kycProvider === KYC_PROVIDER.ENCRYPTUS && !state?.dateOfExpiry) {
        throw new Error('Enter a valid date of expiry');
      }

      if (kycProvider === KYC_PROVIDER.ENCRYPTUS && !state?.issuingCountry) {
        throw new Error('Enter a valid issuing country');
      }

      const [firstName, ...lastName] = cleaned;
      return {
        documentType: 'PASSPORT_FRONT',
        passportNumber: state?.passportNumber,
        firstName: [firstName],
        lastName,
        ...(kycProvider && kycProvider === KYC_PROVIDER.ENCRYPTUS
          ? {
              dateOfExpiry: state?.dateOfExpiry,
              issuingCountry: state?.issuingCountry
            }
          : {})
      };
    case 'BANK_STATEMENT':
      return {
        documentType: 'BANK_STATEMENT',
        account_number: state?.accountNumber
      };
    default:
      return {};
  }
}

const ReKycOcrModal = ({
  kycProvider,
  ocrData,
  doc,
  isLoading,
  onCancel,
  onSubmit,
  onTriggerOcr
}) => {
  const { userId } = useParams();
  const dispatch = useDispatch();
  const [triggerOcrLoading, setTriggerOcrLoading] = useState(false);

  const [modalState, setModalState] = useState(
    getInitialStateBasedOnDoc({
      doc,
      ocrData,
      kycProvider
    })
  );
  const [docLinks, setDocLinks] = useState([]);

  useEffect(() => {
    dispatch(
      searchUserDocs({
        userId,
        pageNo: 0,
        pageSize: 10,
        fromStore: false,
        onSuccess: (data) => {
          setDocLinks(getDocLinks(doc, data?.data));
        },
        onComplete: () => {}
      })
    );
  }, []);

  const handleViewDocClick = (link) => {
    api.workflow
      .docByDocUrl({ docUrl: link })
      .then((res) => res.data)
      .then((data) => window.open(data.url))
      .catch((err) => {
        console.error(err?.message);
        dispatch(
          setAlert({
            title: 'Something went wrong while fetching the document!',
            status: 'error'
          })
        );
      });
  };

  const handleChange = (e) => {
    const key = e?.target?.name;
    const value = e?.target?.value;
    setModalState((prev) => ({
      ...prev,
      [key]: value
    }));
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    try {
      const transformedPayload = transformStateToPaylod({
        state: modalState,
        doc,
        kycProvider
      });
      onSubmit && onSubmit(transformedPayload, doc);
    } catch (error) {
      dispatch(
        setAlert({
          title: error.message,
          status: 'error'
        })
      );
    }
  };

  const handleTriggerOCRClick = () => {
    setTriggerOcrLoading(true);
    const transformed = docLinks.reduce((prev, curr) => {
      return {
        ...prev,
        [curr?.documentId]: curr?.urlPath
      };
    }, {});
    dispatch(
      triggerWorkflowByDoc({
        documentKey: transformed[doc],
        userId,
        subDocType: doc,
        onSuccess: () => {
          onTriggerOcr && onTriggerOcr();
        },
        onError: (errMsg) => {
          dispatch(
            setAlert({
              status: 'error',
              title: `${errMsg} - please manually fill OCR fields`
            })
          );
        },
        onComplete: () => {
          setTriggerOcrLoading(false);
        }
      })
    );
  };

  return (
    <>
      <ModalHeader>Update OCR</ModalHeader>
      <form onSubmit={handleSubmit}>
        <ModalBody>
          <VStack alignItems={'stretch'} gap={2}>
            {Object.keys(modalState).map((key) => (
              <Box key={key}>
                <FormLabel>{camelCaseToSpaceSeparated(key)}</FormLabel>
                <Input
                  value={modalState[key]}
                  name={key}
                  onChange={handleChange}
                />
              </Box>
            ))}
            {doc === 'PASSPORT_FRONT' && (
              <HStack justifyContent={'end'}>
                <Button
                  onClick={handleTriggerOCRClick}
                  variant={'link'}
                  colorScheme="brand"
                  isLoading={triggerOcrLoading}
                  isDisabled={isLoading}
                >
                  Trigger OCR
                </Button>
              </HStack>
            )}
            <VStack gap={1} alignItems={'end'}>
              {docLinks?.map((docLink) => (
                <Button
                  key={docLink?.documentId}
                  onClick={() => handleViewDocClick(docLink?.urlPath)}
                  variant={'link'}
                  colorScheme="brand"
                >
                  {`View ${kebabCaseToSpaceSeperate(docLink?.documentId)}`}
                </Button>
              ))}
            </VStack>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <HStack justifyContent={'end'}>
            <Button
              onClick={onCancel}
              disabled={isLoading}
              variant={'outline'}
              colorScheme="red"
              type="button"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              colorScheme="brand"
              variant={'outline'}
              isLoading={isLoading}
            >
              Verify
            </Button>
          </HStack>
        </ModalFooter>
      </form>
    </>
  );
};

export default ReKycOcrModal;

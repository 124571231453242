import { Grid, Box } from '@chakra-ui/react';
import FeatureDetail from './FeatureDetail';
import dayjs from 'dayjs';

const OverallTxnDetailsCard = ({
  orderId,
  createDate,
  sendAmount,
  fee,
  yblFulfillmentStatus,
  vanceOrderStatus
}) => {
  /* 
        {
    "order_id": "RSQLTVOIVN",
    "create_date": null,
    "send_amount": 5000,
    "fee": 3,
    "ybl_fulfillment_status": "DEFAULT",
    "vance_order_status": "NEW"
}
    */

  return (
    <Box
      width={'full'}
      px={4}
      py={5}
      background={'#1C1C1C'}
      borderRadius={'lg'}
    >
      <Grid
        gridTemplateColumns={'1fr 1fr 1fr'}
        width={'full'}
        columnGap={3}
        rowGap={4}
      >
        <FeatureDetail featureName={'Order ID'} featureValue={orderId} />
        <FeatureDetail featureName={'Send Amount'} featureValue={sendAmount} />
        <FeatureDetail featureName={'Fee'} featureValue={fee} />
        <FeatureDetail
          featureName={'Vance Order Status'}
          featureValue={vanceOrderStatus}
        />
        <FeatureDetail
          featureName={'YBL Fulfillment Status'}
          featureValue={yblFulfillmentStatus}
        />
        <FeatureDetail
          featureName={'Create Date'}
          featureValue={dayjs(createDate).format('DD MMM YYYY HH:MM A')}
        />
      </Grid>
    </Box>
  );
};

export default OverallTxnDetailsCard;

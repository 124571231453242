import { YBL_DETAILS } from '../constants';

const initialState = {
  isLoading: false,
  isError: false,
  error: null,
  data: {},
  prevState: {}
};

const yblDetailsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case YBL_DETAILS.GETTING_YBL_DETAILS:
      return {
        isLoading: true,
        isError: false,
        error: null,
        data: state?.data,
        prevState: state?.prevState
      };
    case YBL_DETAILS.GET_YBL_DETAILS_SUCCESS:
      return {
        isLoading: false,
        isError: false,
        error: null,
        data: { ...payload },
        prevState: { ...payload }
      };
    case YBL_DETAILS.GET_YBL_DETAILS_FAILURE:
      return {
        isLoading: false,
        isError: true,
        error: payload?.errMsg,
        data: state?.data,
        prevState: state?.prevState
      };
    case YBL_DETAILS.UPDATING_YBL_DETAILS:
      return {
        isLoading: false,
        isError: false,
        error: null,
        data: state?.data,
        prevState: state?.prevState
      };
    case YBL_DETAILS.UPDATE_YBL_DETAILS_SUCCESS:
      return {
        isLoading: false,
        isError: false,
        error: null,
        data: { ...payload },
        prevState: { ...payload }
      };
    case YBL_DETAILS.UPDATE_YBL_DETAILS_FAILURE:
      return {
        isLoading: false,
        isError: true,
        error: payload?.errMsg,
        data: state?.data,
        prevState: state?.prevState
      };
    case YBL_DETAILS.MODIFY_YBL_FIELD_DATA:
      return {
        ...state,
        data: {
          ...state?.data,
          ...payload
        }
      };
    case YBL_DETAILS.RESET_YBL_FORM_STATE:
      return {
        ...state,
        data: {
          ...state?.prevState
        }
      };
    default:
      return state;
  }
};

export default yblDetailsReducer;

import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  VStack,
  Heading,
  Text,
  Button,
  Checkbox,
  useToast,
  Divider,
  Flex,
  Spinner,
  Box
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserPermissions, getAllRoles } from '../../store/actions/accessManagementActions';

const UserPermissionsModal = ({ isOpen, onClose, user }) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const allRoles = useSelector((state) => state.accessManagement.allRoles);
  const isLoadingRoles = useSelector((state) => state.accessManagement.isLoadingRoles);
  const currentUserId = useSelector((state) => state.auth.user.userId); // Get current user ID from Redux

  useEffect(() => {
    if (isOpen && !allRoles.length) {
      dispatch(getAllRoles());
    }
  }, [isOpen, dispatch, allRoles]);

  useEffect(() => {
    if (user && user.roles) {
      setSelectedRoles(user.roles);
    }
  }, [user]);

  const handleRoleToggle = (role) => {
    setSelectedRoles((prevRoles) =>
      prevRoles.includes(role)
        ? prevRoles.filter((r) => r !== role)
        : [...prevRoles, role]
    );
  };

  const handleSubmit = async () => {
    if (user.userId === currentUserId) {
      toast({
        title: 'Permission Denied',
        description: "You cannot edit your own permissions.",
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    setIsLoading(true);
    try {
      // Determine roles to add and remove
      const rolesToAdd = selectedRoles.filter(role => !user.roles.includes(role));
      const rolesToRemove = user.roles.filter(role => !selectedRoles.includes(role));
  
      await dispatch(updateUserPermissions({
        userId: user.userId,
        rolesToAdd,        // New structure for roles to add
        rolesToRemove,     // New structure for roles to remove
        onSuccess: () => {
          toast({
            title: 'Permissions updated successfully',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
          onClose();
        },
        onError: (error) => {
          toast({
            title: 'Failed to update permissions',
            description: error.message,
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        },
      }));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md">
      <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
      <ModalContent bg="rgba(0, 0, 0, 0.8)" borderColor="whiteAlpha.200" borderWidth={1}>
        <ModalHeader>
          <Heading size="md" color="brand.500">Edit User Permissions</Heading>
        </ModalHeader>
        <ModalCloseButton color="whiteAlpha.700" />
        <ModalBody>
          <VStack align="stretch" spacing={4}>
            <Heading size="sm" fontWeight="bold" color="whiteAlpha.900">{user?.name}</Heading>
            <Text fontSize="sm" color="whiteAlpha.700">User ID: {user?.userId}</Text>
            <Divider />
            <Heading size="sm" fontWeight="bold" color="whiteAlpha.900">Roles</Heading>
            {isLoadingRoles ? (
              <Flex justify="center">
                <Spinner />
              </Flex>
            ) : (
              <Box 
                maxHeight="200px" 
                overflowY="auto" 
                pr={2}
                css={{
                  '&::-webkit-scrollbar': {
                    width: '4px',
                  },
                  '&::-webkit-scrollbar-track': {
                    width: '6px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    background: 'white',
                    borderRadius: '24px',
                  },
                }}
              >
                <VStack align="stretch" spacing={2}>
                  {allRoles.map((role) => (
                    <Checkbox
                      key={role}
                      isChecked={selectedRoles.includes(role)}
                      onChange={() => handleRoleToggle(role)}
                      color="whiteAlpha.900"
                      colorScheme="brand"
                    >
                      {role}
                    </Checkbox>
                  ))}
                </VStack>
              </Box>
            )}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button variant="outline" mr={3} onClick={onClose} color="whiteAlpha.700">
            Cancel
          </Button>
          <Button
            bg="brand.500"
            color="black"
            _hover={{ bg: 'brand.600' }}
            onClick={handleSubmit}
            isLoading={isLoading}
            loadingText="Updating"
          >
            Update Permissions
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UserPermissionsModal;
import api from '../../api';
import { getErrorMessage } from '../../utils';
import { NRE_NRO as types } from '../constants';
import { setAlert } from './alertActions';

export const getOnboardingStatus =
  ({ userId }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: types.GETTING_ONBOARDING_STATUS
      });
      const { data } = await api.nreNro.getOnboardingStatus({ userId });

      return dispatch({
        type: types.GET_ONBOARDING_STATUS_SUCCESS,
        payload: data
      });
    } catch (error) {
      dispatch(
        setAlert({
          title: getErrorMessage({
            error,
            genericMessage: 'Failed to get Onboarding status'
          }),
          status: 'error'
        })
      );
      return dispatch({
        type: types.GET_ONBOARDING_STATUS_FAILURE,
        payload: error
      });
    }
  };

export const updateOnboardingStatus =
  ({ userId, status, onSuccess, onFinish }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: types.UPDATING_ONBOARDING_STATUS
      });
      await api.nreNro.updateOnboardingStatus({ userId, status });
      if (onSuccess) onSuccess();

      dispatch(getOnboardingStatus({ userId }));
      dispatch(
        setAlert({
          title: 'Updated onboarding status!',
          status: 'success'
        })
      );

      return dispatch({
        type: types.UPDATE_ONBOARDING_STATUS_SUCCESS
      });
    } catch (error) {
      dispatch(
        setAlert({
          title: getErrorMessage({
            error,
            genericMessage: 'Failed to update onboarding status'
          }),
          status: 'error'
        })
      );
      return dispatch({
        type: types.UPDATE_ONBOARDING_STATUS_FAILURE,
        payload: error
      });
    } finally {
      if (onFinish) onFinish();
    }
  };

export const yblDedupe =
  ({ userId, onSuccess, onFinish }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: types.CALLING_YBL_DEDUPE
      });
      await api.nreNro.yblDedupe({ userId });
      if (onSuccess) onSuccess();

      dispatch(
        setAlert({
          title: 'YBL dedupe success!',
          status: 'success'
        })
      );

      return dispatch({
        type: types.YBL_DEDUPE_SUCCESS
      });
    } catch (error) {
      dispatch(
        setAlert({
          title: getErrorMessage({
            error,
            genericMessage: 'YBL dedupe: Failure'
          }),
          status: 'error'
        })
      );
      return dispatch({
        type: types.YBL_DEDUPE_FAILURE,
        payload: error
      });
    } finally {
      if (onFinish) onFinish();
    }
  };

export const yblCustomerDetails =
  ({ userId, onSuccess, onFinish }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: types.CALLING_YBL_CUSTOMER_DETAILS
      });
      await api.nreNro.yblCustomerDetails({ userId });
      if (onSuccess) onSuccess();

      dispatch(
        setAlert({
          title: 'YBL Customer Details success!',
          status: 'success'
        })
      );

      return dispatch({
        type: types.YBL_CUSTOMER_DETAILS_SUCCESS
      });
    } catch (error) {
      dispatch(
        setAlert({
          title: getErrorMessage({
            error,
            genericMessage: 'YBL Customer details: Failure'
          }),
          status: 'error'
        })
      );
      return dispatch({
        type: types.YBL_CUSTOMER_DETAILS_FAILURE,
        payload: error
      });
    } finally {
      if (onFinish) onFinish();
    }
  };

export const yblSubmitJourney =
  ({ userId, onSuccess, onFinish }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: types.CALLING_YBL_SUBMIT_JOURNEY
      });
      await api.nreNro.yblSubmitJourney({ userId });
      if (onSuccess) onSuccess();

      dispatch(
        setAlert({
          title: 'YBL Submit journey success!',
          status: 'success'
        })
      );

      return dispatch({
        type: types.YBL_SUBMIT_JOURNEY_SUCCESS
      });
    } catch (error) {
      dispatch(
        setAlert({
          title: getErrorMessage({
            error,
            genericMessage: 'YBL Submit journey: Failure'
          }),
          status: 'error'
        })
      );
      return dispatch({
        type: types.YBL_SUBMIT_JOURNEY_FAILURE,
        payload: error
      });
    } finally {
      if (onFinish) onFinish();
    }
  };

export const yblUploadDoc =
  ({ userId, docType, onSuccess, onFinish }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: types.CALLING_YBL_UPLOAD_DOC
      });
      await api.nreNro.yblUploadDocs({ userId, docType });
      if (onSuccess) onSuccess();

      dispatch(
        setAlert({
          title: 'YBL Upload doc success!',
          status: 'success'
        })
      );

      return dispatch({
        type: types.YBL_UPLOAD_DOC_FAILURE
      });
    } catch (error) {
      dispatch(
        setAlert({
          title: getErrorMessage({
            error,
            genericMessage: 'YBL Upload doc: Failure'
          }),
          status: 'error'
        })
      );
      console.log(error);
      return dispatch({
        type: types.YBL_UPLOAD_DOC_FAILURE,
        payload: error
      });
    } finally {
      if (onFinish) onFinish();
    }
  };

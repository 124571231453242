import {
  VStack,
  Text,
  HStack,
  Button,
  FormLabel,
  Switch,
  useDisclosure
} from '@chakra-ui/react';
import {
  FTNR_DISABLED_JOURNEY_STEPS,
  FTNR_FORM_MAPPER,
  FTNR_JOURNEY_STEPS
} from './constants';
import React, { useState } from 'react';
import FtnrFormBody from './FormBody';
import ModalLayout from '../Modal/ModalLayout';
import FtnrErrorDetailsModal from './ErrorDetailsModal';
import { FaExternalLinkAlt } from 'react-icons/fa';

const FtnrFormComponent = ({
  formData,
  handleChange,
  handleViewFileClick,
  handleTriggerClick,
  handleReinitiateClick,
  handleUpdateClick,
  updateBtnDisabled = false
}) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const { error_details: errorDetails, ...yblFormData } = formData;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const journeyStep = formData?.journeyStep;
  const disableTriggerApis = formData?.disabledTriggerApis;

  const BUTTON_TYPES = {
    RETRIGGER: 'RETRIGGER',
    UPDATE: 'UPDATE',
    REINITIATE: 'REINTIATE',
    TOGGLE_SWITCH: 'TOGGLE_SWITCH'
  };

  const getDisabledStatus = (state) => {
    switch (state) {
      case BUTTON_TYPES.RETRIGGER:
        if (Object.keys(FTNR_DISABLED_JOURNEY_STEPS).includes(journeyStep))
          return true;
        if (disableTriggerApis) return true;
        return false;
      case BUTTON_TYPES.UPDATE:
        if (Object.keys(FTNR_DISABLED_JOURNEY_STEPS).includes(journeyStep))
          return true;
        if (updateBtnDisabled) return true;
        if (disableTriggerApis) return true;
        return false;
      case BUTTON_TYPES.REINITIATE:
        if (Object.keys(FTNR_DISABLED_JOURNEY_STEPS).includes(journeyStep))
          return true;
        if (disableTriggerApis) return true;
        return false;
      case BUTTON_TYPES.TOGGLE_SWITCH:
        if (disableTriggerApis) return true;
        return false;
      default:
        return false;
    }
  };

  return (
    <>
      <ModalLayout size="2xl" isOpen={isOpen} onClose={onClose}>
        <FtnrErrorDetailsModal errorDump={errorDetails} />
      </ModalLayout>
      <VStack width={'full'} gap={2} alignItems={'stretch'}>
        <HStack
          width={'full'}
          justifyContent={'space-between'}
          alignItems={'center'}
          position={'sticky'}
          top={'0'}
          backgroundColor={'black'}
          zIndex={'2'}
          paddingY={'10px'}
        >
          <Text color={'white'} fontSize={'2xl'}>
            Customer YBL details
          </Text>
          <VStack alignItems={'end'} gap={1}>
            <HStack gap={1}>
              {Object.keys(FTNR_JOURNEY_STEPS).includes(journeyStep) && (
                <>
                  <FormLabel htmlFor="is-editable" color={'white'}>
                    Edit mode
                  </FormLabel>
                  <Switch
                    name="is-editable"
                    isChecked={!isDisabled}
                    onChange={() => {
                      setIsDisabled((prev) => !prev);
                    }}
                    size={'lg'}
                    colorScheme="brand"
                    disabled={getDisabledStatus(BUTTON_TYPES.TOGGLE_SWITCH)}
                  />
                </>
              )}
              <Button
                type="submit"
                variant={'outline'}
                colorScheme="brand"
                onClick={handleUpdateClick}
                disabled={getDisabledStatus(BUTTON_TYPES.UPDATE)}
              >
                Update
              </Button>
              <Button
                variant={'outline'}
                colorScheme="shadedGray"
                onClick={handleTriggerClick}
                disabled={getDisabledStatus(BUTTON_TYPES.RETRIGGER)}
              >
                Retrigger
              </Button>
              {journeyStep === FTNR_JOURNEY_STEPS.NEW && (
                <Button
                  variant={'outline'}
                  colorScheme="shadedGray"
                  onClick={handleReinitiateClick}
                  disabled={getDisabledStatus(BUTTON_TYPES.REINITIATE)}
                >
                  Reintiate
                </Button>
              )}
            </HStack>
            {disableTriggerApis && (
              <Text color="gray.500" fontSize={'sm'} fontWeight={'bold'}>
                Uploading data to YBL, please wait…
              </Text>
            )}
          </VStack>
        </HStack>
        {!disableTriggerApis && errorDetails && errorDetails.length > 0 && (
          <HStack gap={1} justifyContent={'end'}>
            <Button onClick={onOpen} variant={'link'} colorScheme="red">
              <HStack gap={1} alignItems={'center'}>
                <Text colorScheme="red">View Error Details</Text>
                <FaExternalLinkAlt colorScheme="red" />
              </HStack>
            </Button>
          </HStack>
        )}
        <FtnrFormBody
          mapper={FTNR_FORM_MAPPER}
          formData={yblFormData}
          handleChange={handleChange}
          handleViewFileClick={handleViewFileClick}
          disabled={isDisabled}
        />
      </VStack>
    </>
  );
};

export default FtnrFormComponent;

import { mapJobsReqData } from '../../utils';

export default function jobsApi(fetch, baseUrl) {
  return {
    get(searchReqData) {
      return fetch.post(`${baseUrl}`, mapJobsReqData(searchReqData));
    },

    updateStatus({ jobId, status }) {
      return fetch.put(`${baseUrl}/${jobId}`, { jobStatus: status });
    }
  };
}

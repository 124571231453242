import {
  Heading,
  VStack,
  Flex,
  Box,
  Grid,
  Divider,
  Spinner,
  useDisclosure
} from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../../components/Pagination';
import ContentLayout from '../../layouts/ContentLayout';
import useDidMount from '../../hooks/useDidMount';
import { clearKycChecks, getKycChecks } from '../../store/actions/kycActions';
import { useParams } from 'react-router-dom';
import { updateKycChecksReqData } from '../../store/actions/reqDataActions';
import KycCheckCard from '../../components/Kyc/KycCheckCard';
import ModalLayout from '../../components/Modal/ModalLayout';
import JsonViewer from '../../components/Modal/JsonViewer';

const KycChecks = () => {
  const { userId } = useParams();
  const dispatch = useDispatch();

  const { isLoading, data, totalPages } = useSelector(
    (state) => state.kyc.user.checks
  );

  const searchReqData = useSelector((state) => state.reqData.kyc.user.checks);

  const {
    isOpen: isCheckInfoOpen,
    onOpen: onCheckInfoOpen,
    onClose: onCheckInfoClose
  } = useDisclosure();

  const [activeCheck, setActiveCheck] = useState(null);

  useDidMount(() => {
    triggerGetChecks(searchReqData);
  }, [searchReqData.pageNo]);

  useEffect(() => {
    if (!data?.length) {
      triggerGetChecks(searchReqData);
    }

    return () => dispatch(clearKycChecks());
  }, []);

  const triggerGetChecks = useCallback(
    (searchReqData) => {
      dispatch(
        getKycChecks({
          userId,
          ...searchReqData
        })
      );
    },
    [dispatch, userId]
  );

  const handlePageChange = useCallback(
    (pageNo) => {
      pageNo - 1 !== searchReqData.pageNo &&
        dispatch(updateKycChecksReqData({ pageNo: pageNo - 1 }));
    },
    [searchReqData.pageNo, dispatch]
  );

  const handleCheckInfoClose = () => setActiveCheck(null);

  useEffect(() => {
    if (activeCheck) onCheckInfoOpen();
    else onCheckInfoClose();
  }, [activeCheck]);

  const getCheckResStr = useCallback(
    (checkResData) =>
      checkResData
        ?.replace(`'`, `\"`)
        .replace('True', 'true')
        .replace('False', 'false'),
    []
  );

  const onCheckUpdateSuccess = () => triggerGetChecks(searchReqData);

  return (
    <Box position={'relative'}>
      <ModalLayout
        isOpen={isCheckInfoOpen}
        onClose={handleCheckInfoClose}
        size={'lg'}
      >
        <JsonViewer
          title={'Check Result Breakdown'}
          jsonString={getCheckResStr(activeCheck?.checkResultBreakdown)}
        />
      </ModalLayout>
      <ContentLayout>
        <Grid gridTemplateColumns={{ base: '1fr', lg: '7fr 0fr 2fr' }}>
          <VStack
            p={10}
            gap={4}
            overflowY={{ base: 'initial', lg: 'scroll' }}
            overflowX={'hidden'}
            h={{ lg: '90vh' }}
            px={{ base: 4, sm: 10 }}
            alignItems={'stretch'}
            className="scroll"
          >
            <Flex
              w={'100%'}
              alignItems={'baseline'}
              justifyContent={'space-between'}
              pb={2}
            >
              <Heading color={'colorPrimary'}>KYC Checks</Heading>
            </Flex>

            {totalPages > 0 && (
              <Box alignSelf={'start'}>
                <Pagination
                  totalPages={totalPages}
                  currentPage={searchReqData.pageNo + 1}
                  onPageChange={handlePageChange}
                />
              </Box>
            )}
            {isLoading && <Spinner size={'lg'} color={'brand.200'} />}
            <VStack alignSelf={'flex-start'} maxW={'100%'} gap={1}>
              {data?.length > 0 &&
                data.map((item) => (
                  <KycCheckCard
                    key={item.id}
                    check={item}
                    userId={userId}
                    onCheckUpdateSuccess={onCheckUpdateSuccess}
                  />
                ))}
            </VStack>
          </VStack>
          <Divider
            style={{ margin: '0' }}
            borderColor="whiteAlpha.300"
            orientation="vertical"
          ></Divider>
        </Grid>
      </ContentLayout>
    </Box>
  );
};

export default KycChecks;

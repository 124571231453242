import React from 'react';
import { ModalBody, Button, ModalFooter, Text } from '@chakra-ui/react';

const ConfirmModal = ({ handleSubmit, isLoading, prompt }) => {
  return (
    <ModalBody>
      <Text color={'white'}>{prompt}</Text>
      <ModalFooter justifyContent={'center'}>
        <Button
          colorScheme="brand"
          color="altGray.400"
          mr={3}
          size={'sm'}
          fontSize={'0.75rem'}
          type="submit"
          onClick={handleSubmit}
          isLoading={isLoading}
        >
          Confirm
        </Button>
      </ModalFooter>
    </ModalBody>
  );
};

export default ConfirmModal;

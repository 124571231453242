import {
  Button,
  FormLabel,
  HStack,
  Input,
  ModalBody,
  ModalFooter,
  ModalHeader,
  VStack,
  Box
} from '@chakra-ui/react';
import { useState } from 'react';

const UpdateExchangeRateModal = ({
  appName,
  transferFees,
  exchangeRate,
  onSubmit,
  onCancel,
  loading = false
}) => {
  const [platformData, setPlatformData] = useState({
    transferFees,
    exchangeRate
  });

  const handleChange = (e) => {
    setPlatformData((prev) => ({
      ...prev,
      [e?.target?.name]: e?.target?.value
    }));
  };

  return (
    <>
      <ModalHeader>Update {appName}</ModalHeader>
      <form
        onSubmit={(e) => {
          e?.preventDefault();
          onSubmit &&
            onSubmit({
              appName,
              ...platformData
            });
        }}
      >
        <ModalBody>
          <VStack gap={2} alignItems={'stretch'} width={'auto'}>
            <Box>
              <FormLabel htmlFor="transferFees">Transfer Fee</FormLabel>
              <Input
                name="transferFees"
                value={platformData?.transferFees}
                onChange={handleChange}
              />
            </Box>
            <Box>
              <FormLabel htmlFor="exchangeRate">Exchange Rate</FormLabel>
              <Input
                name="exchangeRate"
                value={platformData?.exchangeRate}
                onChange={handleChange}
              />
            </Box>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <HStack justifyContent={'end'} alignItems={'center'} gap={2}>
            <Button
              type="button"
              variant={'outline'}
              colorScheme="red"
              onClick={onCancel}
              isDisabled={loading}
            >
              Cancel
            </Button>
            <Button
              isDisabled={loading}
              type="submit"
              variant={'outline'}
              colorScheme="brand"
            >
              Update
            </Button>
          </HStack>
        </ModalFooter>
      </form>
    </>
  );
};

export default UpdateExchangeRateModal;

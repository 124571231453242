import {
  Button,
  HStack,
  ModalBody,
  ModalFooter,
  ModalHeader,
  VStack,
  Text,
  Table,
  Tbody,
  Tr,
  Td,
  Thead,
  Th
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';

const cellStyles = {
  border: 'none',
  padding: '8px'
};

const FtnrVerifyUpdateModal = ({
  prevState = {},
  updatedFields,
  handleUpdate,
  handleDiscard,
  isLoading = false
}) => {
  return (
    <>
      <ModalHeader>Updated Fields</ModalHeader>
      <ModalBody>
        <VStack alignItems={'stretch'} gap={1}>
          <Table>
            <Thead>
              <Tr>
                <Th style={cellStyles}>Field</Th>
                <Th style={cellStyles}>Updated Value</Th>
                <Th style={cellStyles}>Previous Value</Th>
              </Tr>
            </Thead>
            <Tbody>
              {Object.keys(updatedFields).map((key) => {
                return (
                  <Tr key={`tr-${key}`}>
                    <Td style={cellStyles}>
                      <Text color={'white'} fontSize={'md'}>
                        {key}
                      </Text>
                    </Td>
                    <Td style={cellStyles}>
                      <Text color={'white'} fontSize={'md'}>
                        {updatedFields[key]}
                      </Text>
                    </Td>
                    <Td style={cellStyles}>
                      <Text color={'white'} fontSize={'md'}>
                        {prevState[key]}
                      </Text>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </VStack>
        <ModalFooter>
          <HStack>
            <Button
              variant={'outline'}
              colorScheme="red"
              onClick={handleDiscard}
              disabled={isLoading}
            >
              Discard
            </Button>
            <Button
              variant={'outline'}
              colorScheme="green"
              onClick={handleUpdate}
              isLoading={isLoading}
            >
              Update
            </Button>
          </HStack>
        </ModalFooter>
      </ModalBody>
    </>
  );
};

export default FtnrVerifyUpdateModal;

import { ORDER_SCREEN_CONF_COMP_TYPES } from '../../constants';

export const transformOrderScreenConfToPayload = (orderScreenConfig) => {
  if (!orderScreenConfig) return;
  const isComplianceFlag = orderScreenConfig?.isComplianceFlag;
  const screenConfig = orderScreenConfig?.screenConfig
    ?.filter((configEl) => configEl)
    ?.map((configEl) => {
      if (
        configEl?.type === ORDER_SCREEN_CONF_COMP_TYPES.INPUT_FIELD ||
        configEl?.type === ORDER_SCREEN_CONF_COMP_TYPES.UPLOAD_DOC
      ) {
        return {
          label: configEl?.label,
          id: configEl?.id,
          type: configEl?.type
        };
      }
      return {
        label: configEl?.label,
        id: configEl?.id,
        type: configEl?.type,
        options: configEl?.options
      };
    });
  return {
    isComplianceFlag,
    screenConfig
  };
};

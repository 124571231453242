import {
  doesExist,
  toOrderData,
  toOrderMetadata,
  toUserData,
  toLuluOrderData
} from '../../utils';
import { FUNNEL as types, ORDERS } from '../constants';
import {
  ALL_FUNNEL_KYC_STATUS,
  ALL_FUNNEL_ORDERS_STATUS,
  ALL_FUNNEL_V2_ORDERS_STATUS,
  ALL_LULU_ORDERS_FUNNEL_TABS,
  FILTER_BY_FLAG
} from '../../constants';

import { updateFunnelListFromOrderMetaData } from './utils';
import vdaFunnel from '../../api/vdaFunnel';
import dayjs from 'dayjs';
import { VDA_FUNNEL_STATES } from '../../views/Funnel/SaberFunnel/constants';

const defaultFunnelOrdersCount = ALL_FUNNEL_ORDERS_STATUS.reduce(
  (prev, status) => ({
    ...prev,
    [status]: {
      filtered: '-',
      unfiltered: '-'
    }
  }),
  {}
);

const defaultFunnelOrders = ALL_FUNNEL_ORDERS_STATUS.reduce(
  (orders, status) => {
    return {
      ...orders,
      [status]: {
        filtered: {
          pages: {},
          isLoading: false,
          totalPages: 0,
          totalOrders: 0
        },
        unfiltered: {
          pages: {},
          isLoading: false,
          totalPages: 0,
          totalOrders: 0
        }
      }
    };
  },
  {}
);

const defaultFunnelV2OrdersCount = ALL_FUNNEL_V2_ORDERS_STATUS.reduce(
  (prev, status) => ({
    ...prev,
    [status]: '-'
  }),
  {}
);

const defaultFunnelV2Orders = ALL_FUNNEL_V2_ORDERS_STATUS.reduce(
  (orders, status) => {
    return {
      ...orders,
      [status]: {
        data: null,
        isLoading: false,
        isError: false,
        error: null,
        totalPages: 0,
        totalOrders: 0
      }
    };
  },
  {}
);

const defaultLuluFunnelOrdersCount = ALL_LULU_ORDERS_FUNNEL_TABS.reduce(
  (prev, tab) => ({
    ...prev,
    [tab]: '-'
  }),
  {}
);

const defaultLuluFunnelOrders = ALL_LULU_ORDERS_FUNNEL_TABS.reduce(
  (orders, tab) => {
    return {
      ...orders,
      [tab]: {
        pages: {},
        isLoading: false,
        totalPages: 0,
        totalOrders: 0
      }
    };
  },
  {}
);

const defaultFunnelKycUsersCount = ALL_FUNNEL_KYC_STATUS.reduce(
  (prev, status) => ({
    ...prev,
    [status]: '-'
  }),
  {}
);

const defaultFunnelKycUsers = ALL_FUNNEL_KYC_STATUS.reduce((users, status) => {
  return {
    ...users,
    [status]: {
      isLoading: false,
      pages: {},
      totalPages: 0
    }
  };
}, {});

const initialState = {
  orders: {
    isLoading: false,
    isError: false,
    error: null,
    funnelData: defaultFunnelOrdersCount,
    orders: defaultFunnelOrders
  },
  kyc: {
    isLoading: false,
    isError: false,
    error: null,
    funnelData: defaultFunnelKycUsersCount,
    users: defaultFunnelKycUsers
  },
  luluOrders: {
    isLoading: false,
    isError: false,
    error: null,
    tabCount: defaultLuluFunnelOrdersCount,
    tabData: defaultLuluFunnelOrders
  },
  ordersV2: {
    isLoading: false,
    tabCount: defaultFunnelV2OrdersCount,
    tabData: defaultFunnelV2Orders
  },
  nronboard: {
    isLoading: false,
    isError: false,
    error: null,
    data: {}
  },
  vdaFunnel: {
    isLoading: false,
    isError: false,
    error: null,
    data: Object.keys(VDA_FUNNEL_STATES)?.reduce((prev, curr) => {
      return {
        ...prev,
        [curr]: {
          totalResults: '-',
          data: [],
          totalPages: 0
        }
      };
    }, {})
  }
};

const funnelReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.GETTING_FUNNEL_ORDERS:
      return {
        ...state,
        orders: {
          ...state.orders,
          funnelData: {
            ...state.orders.funnelData,
            [payload.status]: {
              ...state.orders.funnelData[payload.status],
              [payload.filterByFlag]: '-'
            }
          },
          orders: {
            ...state.orders.orders,
            [payload.status]: {
              ...state.orders.orders[payload.status],
              [payload.filterByFlag]: {
                ...state.orders.orders[payload.status][payload.filterByFlag],
                isLoading: true,
                pages: payload.refresh
                  ? {}
                  : {
                      ...state.orders.orders[payload.status][
                        payload.filterByFlag
                      ].pages,
                      [payload.pageNo]: {
                        isCached: false,
                        isError: false,
                        error: null,
                        data: null
                      }
                    },
                totalPages: payload.refresh
                  ? 0
                  : state.orders.orders[payload.status][payload.filterByFlag]
                      .totalPages,
                totalOrders: payload.refresh
                  ? 0
                  : state.orders.orders[payload.status][payload.filterByFlag]
                      .totalOrders
              }
            }
          }
        }
      };

    case types.GETTING_LULU_FUNNEL_ORDERS:
      return {
        ...state,
        luluOrders: {
          ...state.luluOrders,
          tabCount: {
            ...state.luluOrders.tabCount,
            [payload.tab]: '-'
          },
          tabData: {
            ...state.luluOrders.tabData,
            [payload.tab]: {
              ...state.luluOrders.tabData[payload.tab],
              isLoading: true,
              pages: payload.refresh
                ? {}
                : {
                    ...state.luluOrders.tabData[payload.tab].pages,
                    [payload.pageNo]: {
                      isCached: false,
                      isError: false,
                      error: null,
                      data: null
                    }
                  }
            }
          }
        }
      };

    case types.GET_FUNNEL_ORDERS_SUCCESS:
      return {
        ...state,
        orders: {
          ...state.orders,
          funnelData: {
            ...state.orders.funnelData,
            [payload.status]: {
              ...state.orders.funnelData[payload.status],
              [payload.filterByFlag]: doesExist(payload.data.totalOrders)
                ? payload.data.totalOrders
                : '-'
            }
          },
          orders: {
            ...state.orders.orders,
            [payload.status]: {
              ...state.orders.orders[payload.status],
              [payload.filterByFlag]: {
                ...state.orders.orders[payload.status][payload.filterByFlag],
                isLoading: false,
                pages: {
                  ...state.orders.orders[payload.status][payload.filterByFlag]
                    .pages,
                  [payload.data.currentPageNumber]: {
                    isCached: true,
                    isError: false,
                    error: null,
                    data:
                      payload.data.orders?.map((item) => toOrderData(item)) ||
                      []
                  }
                },
                totalPages: payload.data.totalPage,
                totalOrders: payload.data.totalOrders
              }
            }
          }
        }
      };

    case types.GET_LULU_FUNNEL_ORDERS_SUCCESS:
      return {
        ...state,
        luluOrders: {
          ...state.luluOrders,
          tabCount: {
            ...state.luluOrders.tabCount,
            [payload.tab]: doesExist(payload.data.totalResults)
              ? payload.data.totalResults
              : '-'
          },
          tabData: {
            ...state.luluOrders.tabData,
            [payload.tab]: {
              isLoading: false,
              pages: {
                ...state.luluOrders.tabData[payload.tab].pages,
                [payload.data.page]: {
                  isCached: true,
                  isError: false,
                  error: null,
                  data:
                    payload.data.data?.map((item) => toLuluOrderData(item)) ||
                    []
                }
              },
              totalPages: payload.data.totalPages,
              totalOrders: payload.data.totalResults
            }
          }
        }
      };

    case types.GET_FUNNEL_ORDERS_FAILURE:
      return {
        ...state,
        orders: {
          ...state.orders,
          funnelData: {
            ...state.orders.funnelData,
            [payload.status]: {
              ...state.orders.funnelData[payload.status],
              [payload.filterByFlag]: '-'
            }
          },
          orders: {
            ...state.orders.orders,
            [payload.status]: {
              ...state.orders.orders[payload.status],
              [payload.filterByFlag]: {
                ...state.orders.orders[payload.status][payload.filterByFlag],
                isLoading: false,

                pages: {
                  ...state.orders.orders[payload.status][payload.filterByFlag]
                    .pages,
                  [payload.pageNo]: {
                    isCached: false,
                    isError: true,
                    error: payload.error,
                    data: null
                  }
                },
                totalPages:
                  state.orders.orders[payload.status][payload.filterByFlag]
                    .totalPages,
                totalOrders:
                  state.orders.orders[payload.status][payload.filterByFlag]
                    .totalOrders
              }
            }
          }
        }
      };

    case types.GET_LULU_FUNNEL_ORDERS_FAILURE:
      return {
        ...state,
        luluOrders: {
          ...state.luluOrders,
          tabCount: {
            ...state.luluOrders.tabCount,
            [payload.tab]: '-'
          },
          tabData: {
            ...state.luluOrders.tabData,
            [payload.tab]: {
              isLoading: false,
              pages: {
                ...state.luluOrders.tabData[payload.tab].pages,
                [payload.pageNo]: {
                  isCached: false,
                  isError: true,
                  error: payload.error,
                  data: null
                }
              },
              totalPages: state.luluOrders.tabData[payload.tab].totalPages,
              totalOrders: state.luluOrders.tabData[payload.tab].totalOrders
            }
          }
        }
      };

    /* UPDATE FILTERED AND UNFILTERED LIST FOR SELECTED STATUS */
    case ORDERS.UPDATE_ORDER_METADATA_SUCCESS:
      const metadata = toOrderMetadata(payload);

      let updatedOrdersState = updateFunnelListFromOrderMetaData(
        state.orders,
        metadata,
        FILTER_BY_FLAG.FILTERED
      );
      updatedOrdersState = updateFunnelListFromOrderMetaData(
        updatedOrdersState,
        metadata,
        FILTER_BY_FLAG.UNFILTERED
      );

      return {
        ...state,
        orders: updatedOrdersState
      };

    case types.GETTING_FUNNEL_USERS:
      return {
        ...state,
        kyc: {
          ...state.kyc,
          users: {
            ...state.kyc.users,
            [payload.status]: {
              ...state.kyc.users[payload.status],
              isLoading: true,
              pages: payload.refresh
                ? {}
                : {
                    ...state.kyc.users[payload.status].pages,
                    [payload.pageNo]: {
                      isCached: false,
                      isError: false,
                      error: null,
                      data: null
                    }
                  },
              totalPages: payload.refresh
                ? 0
                : state.kyc.users[payload.status].totalPages
            }
          }
        }
      };

    case types.GET_FUNNEL_USERS_SUCCESS:
      return {
        ...state,
        kyc: {
          ...state.kyc,
          funnelData: {
            ...state.kyc.funnelData,
            [payload.status]: doesExist(payload.data.totalUsers)
              ? payload.data.totalUsers
              : '-'
          },
          users: {
            ...state.kyc.users,
            [payload.status]: {
              ...state.kyc.users[payload.status],
              isLoading: false,
              pages: {
                ...state.kyc.users[payload.status].pages,
                [payload.data.currentPageNumber]: {
                  isCached: true,
                  isError: false,
                  error: null,
                  data:
                    payload.data.users?.map((user) => toUserData(user)) || []
                }
              },
              totalPages: payload.data.totalPage
            }
          }
        }
      };

    case types.GET_FUNNEL_USERS_FAILURE:
      return {
        ...state,
        kyc: {
          ...state.kyc,
          funnelData: {
            ...state.kyc.funnelData,
            [payload.status]: 0
          },
          users: {
            ...state.kyc.users,
            [payload.status]: {
              ...state.kyc.users[payload.status],
              isLoading: false,
              pages: payload.refresh
                ? {}
                : {
                    ...state.kyc.users[payload.status].pages,
                    [payload.pageNo]: {
                      isCached: false,
                      isError: true,
                      error: payload,
                      data: null
                    }
                  },
              totalPages: state.kyc.users[payload.status].totalPages
            }
          }
        }
      };

    case types.GETTING_FUNNEL_V2_ORDERS:
      return {
        ...state,
        ordersV2: {
          ...state.ordersV2,
          tabCount: {
            ...state.ordersV2.tabCount,
            [payload.status]: '-'
          },
          tabData: {
            ...state.ordersV2.tabData,
            [payload.status]: {
              isLoading: true,
              isError: false,
              error: null,
              data: null,
              totalPages: 0,
              totalOrders: 0
            }
          }
        }
      };

    case types.GET_FUNNEL_V2_ORDERS_SUCCESS:
      return {
        ...state,
        ordersV2: {
          ...state.ordersV2,
          tabCount: {
            ...state.ordersV2.tabCount,
            [payload.status]: payload.data.totalOrders
          },
          tabData: {
            ...state.ordersV2.tabData,
            [payload.status]: {
              isLoading: false,
              isError: false,
              error: null,
              data:
                payload.data.orders?.map((order) => toOrderData(order)) || [],
              totalPages: payload.data.totalPage,
              totalOrders: payload.data.totalOrders
            }
          }
        }
      };

    case types.GET_FUNNEL_V2_ORDERS_FAILURE:
      return {
        ...state,
        ordersV2: {
          ...state.ordersV2,
          tabCount: {
            ...state.ordersV2.tabCount,
            [payload.status]: '-'
          },
          tabData: {
            ...state.ordersV2.tabData,
            [payload.status]: {
              isLoading: false,
              isError: true,
              error: payload.error,
              data: null,
              totalPages: 0,
              totalOrders: 0
            }
          }
        }
      };

    case types.GETTING_NROB_FUNNEL:
      return {
        ...state,
        nronboard: {
          isLoading: true,
          isError: false,
          error: null,
          data: state?.nronboard?.data
        }
      };

    case types.GET_NROB_FUNNEL_SUCCESS:
      return {
        ...state,
        nronboard: {
          isLoading: false,
          error: null,
          isError: false,
          data: {
            ...state?.nronboard?.data,
            [payload?.funnelState]: {
              data: payload?.data,
              totalSize: payload?.totalSize,
              page: payload?.page,
              totalPages: payload?.totalPages
            }
          }
        }
      };

    case types.GET_NROB_FUNNEL_FAILURE:
      return {
        ...state,
        nronboard: {
          isLoading: false,
          isError: true,
          error: payload?.errMsg,
          data: state?.nronboard?.data
        }
      };

    case types.GETTING_SABER_FUNNEL:
      return {
        ...state,
        vdaFunnel: {
          ...state?.vdaFunnel,
          isLoading: true,
          isError: false,
          error: null
        }
      };

    case types.GET_SABER_FUNNEL_SUCCESS:
      const { status, data } = payload;
      return {
        ...state,
        vdaFunnel: {
          isLoading: false,
          isError: false,
          error: null,
          data: {
            ...state?.vdaFunnel?.data,
            [status]: data
          }
        }
      };

    case types.GET_SABER_FUNNEL_FAILURE:
      return {
        ...state,
        vdaFunnel: {
          ...state?.vdaFunnel,
          isError: true,
          error: payload?.errMsg,
          isLoading: false
        }
      };
    default:
      return state;
  }
};

export default funnelReducer;

export default function nronboardApi(fetch, baseUrl) {
  return {
    rejectApplication({ userId, screenId, docType, rejectReason }) {
      return fetch.put(`${baseUrl}/${userId}/reject-application`, {
        screenId,
        subDocumentType: docType,
        rejectionReason: rejectReason
      });
    },
    getOnboardDetails(userId) {
      return fetch(`${baseUrl}/${userId}`);
    },
    /* for pickup in UK flow */
    getAvailablePickupSlots(userId) {
      return fetch(`${baseUrl}/${userId}/docs-pickup/available-slots`);
    },
    schedulePickupSlot(userId, slot) {
      return fetch.put(`${baseUrl}/${userId}/docs-pickup/schedule`, slot);
    },
    updatePickupStatus(userId, status) {
      return fetch.put(`${baseUrl}/${userId}/docs-pickup/status`, { status });
    },
    /* for delivery in UK flow */
    scheduleDocsDelivery(userId, slot) {
      return fetch.put(`${baseUrl}/${userId}/docs-delivery/schedule`, {
        ...slot
      });
    },
    updateDocsDeliveryStatus(userId, status) {
      return fetch.put(`${baseUrl}/${userId}/docs-delivery/status`, { status });
    },
    /* for appointment UAE flow */
    getAvailableAppointmentSlots(userId) {
      return fetch(`${baseUrl}/${userId}/appointment/available-slots`);
    },
    scheduleAppointment(userId, slot) {
      return fetch.put(`${baseUrl}/${userId}/appointment/schedule`, slot);
    },
    updateAppointmentStatus(userId, status) {
      return fetch.put(`${baseUrl}/${userId}/appointment/status`, { status });
    },
    /* verify user */
    verifyApplication(userId) {
      return fetch.put(`${baseUrl}/${userId}/application/verify`);
    },
    /* ybl */
    retriggerYblCalls(userId) {
      return fetch.put(`${baseUrl}/${userId}/ybl/trigger`);
    },
    getYblDetails(userId) {
      return fetch(`${baseUrl}/${userId}/ybl/customer-details`);
    },
    patchYblCustomerDetails(userId, modifiedFields) {
      return fetch.patch(`${baseUrl}/${userId}/ybl/customer-details`, {
        ...modifiedFields
      });
    },
    /* ocr address */
    getOcrAddress(userId) {
      return fetch(`${baseUrl}/${userId}/ocr-address`);
    },
    updateAddress({ userId, payload }) {
      return fetch.put(`${baseUrl}/${userId}/address`, { ...payload });
    },
    rejectApplicationV2({ userId, payload }) {
      return fetch.put(`${baseUrl}/${userId}/reject-application`, {
        ...payload
      });
    },
    updateDocstoYblStatus({ userId, status }) {
      return fetch.put(`${baseUrl}/${userId}/docs-delivery-ybl/status`, {
        status
      });
    },
    getFunnelData({ data }) {
      return fetch.post(`${baseUrl}/funnel`, { ...data });
    },
    getDhlReports({ data }) {
      return fetch.post(`${baseUrl}/reports/dhl-pickup`, { ...data });
    },
    renotarizeDocument({ userId, docType }) {
      return fetch.put(`${baseUrl}/${userId}/notarization/docs/${docType}`);
    },
    reinitiate({ userId }) {
      return fetch.put(`${baseUrl}/${userId}/re-initiate`);
    },
    syncApplication({ userId }) {
      return fetch.put(`${baseUrl}/${userId}/ybl/application-form`);
    },
    updateAttestedDocsStatus({ userId, status }) {
      return fetch.put(`${baseUrl}/${userId}/attested-docs`, { status });
    },
    verifyDocs({ userId, payload }) {
      return fetch.put(`${baseUrl}/${userId}/verify-documents`, { ...payload });
    }
  };
}

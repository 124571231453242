export default function (fetch, baseUrl) {
  return {
    getIsFxRatePeaking(currency) {
      return fetch.get(`${baseUrl}/${currency}`);
    },
    putPollingStatus({ currency, isEnabled }) {
      return fetch.put(`${baseUrl}/${currency}`, {
        isEnabled
      });
    }
  };
}

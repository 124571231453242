import React from 'react';
import { Button, Input, Text, Select } from '@chakra-ui/react';
import { transformFieldToInputData, getOptionsDataFromKey } from './utils';

const FtnrFormItem = ({
  itemKey,
  value,
  isDisabled = false,
  handleChange,
  onViewFileClick
}) => {
  const { isDropdown, isEditable, isFile, isDate } = transformFieldToInputData(
    itemKey,
    value
  );

  const disabled = isDisabled ? isDisabled : !isEditable;

  if (!isEditable) return <Text color={'white'}>{value}</Text>;
  if (isEditable && isDropdown)
    return (
      <Select
        value={value || ''}
        placeholder="select value"
        disabled={disabled}
        size={'sm'}
        w={'100%'}
        color={'white'}
        rounded={'lg'}
        onChange={(e) => handleChange(itemKey, e?.target?.value)}
      >
        {Object.keys(getOptionsDataFromKey(itemKey)).map((key) => {
          return (
            <option key={key} value={key}>
              {key}
            </option>
          );
        })}
      </Select>
    );
  if (isFile)
    return (
      <Button
        variant={'link'}
        onClick={() => {
          onViewFileClick && onViewFileClick(value);
        }}
      >
        View File
      </Button>
    );
  if (isEditable)
    return (
      <Input
        type={isDate ? 'date' : 'text'}
        size={'sm'}
        rounded={'lg'}
        color={'white'}
        value={value || ''}
        onChange={(e) => handleChange(itemKey, e?.target?.value)}
        disabled={disabled}
      />
    );
  return null;
};

export default FtnrFormItem;

import React from 'react';
import { Flex, Avatar, AvatarBadge, Text } from '@chakra-ui/react';
import jigneshAvatar from './avatars/jignesh.png';

const Header = ({ isTyping }) => {
  return (
    <Flex px={3} pb={2}>
      <Avatar bg="brand.500" src={jigneshAvatar} size="md">
        {' '}
        <AvatarBadge boxSize="1.25em" bg="brand.500" />
      </Avatar>

      <Flex flexDirection="column" mx="5" justify="center">
        <Text fontSize="lg" fontWeight="bold">
          Jignesh
        </Text>
        <Text color="brand.500"> {isTyping ? 'Typing...' : 'Online'} </Text>
      </Flex>
    </Flex>
  );
};

export default Header;

import {
  toNrAccountOnboardingDetails,
  toNrUserData,
  toNrUserDetailsData
} from '../../utils';
import { NR_ACCOUNT as types } from '../constants';

const initialState = {
  users: {
    isLoading: false,
    isError: false,
    error: null,
    pages: {},
    totalPages: 0
  },

  userDetails: {
    isLoading: false,
    isError: false,
    error: null,
    data: null
  },

  onboarding: {
    isLoading: false,
    isError: false,
    error: null,
    data: null
  }
};

const nrAccountReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.GETTING_NR_USERS:
      return {
        ...state,
        users: {
          ...state.users,
          isLoading: true,
          isError: false,
          error: null,
          pages: payload.refresh
            ? {}
            : {
                ...state.users.pages,
                [payload.pageNo]: null
              }, //clear all pages if refresh is true
          totalPages: payload.refresh ? 0 : state.users.totalPages
        }
      };

    case types.GET_NR_USERS_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          isLoading: false,
          isError: false,
          error: null,
          pages: {
            ...state.users.pages,
            [payload.page]: {
              isCached: true,
              data: payload.data?.map((user) => toNrUserData(user)) || []
            }
          },
          totalPages: payload.totalPages
        }
      };

    case types.GET_NR_USERS_FAILURE:
      return {
        ...state,
        users: {
          ...state.users,
          isLoading: false,
          isError: true,
          error: payload.error,
          pages: {
            ...state.users.pages,
            [payload.pageNo]: {
              isCached: false,
              data: null
            }
          },
          totalPages: 0
        }
      };

    case types.GETTING_NR_USER_DETAILS:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          isLoading: true,
          isError: false,
          error: null,
          data: null
        }
      };

    case types.GET_NR_USER_DETAILS_SUCCESS:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          isLoading: false,
          isError: false,
          error: null,
          data: toNrUserDetailsData(payload)
        }
      };

    case types.GET_NR_USER_DETAILS_FAILURE:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          isLoading: true,
          isError: true,
          error: payload,
          data: null
        }
      };

    case types.CLEAR_NR_USER_DETAILS:
      return {
        ...state,
        userDetails: {
          isLoading: false,
          isError: false,
          error: null,
          data: null
        }
      };

    case types.GETTING_ONBOARDING_DETAILS:
      return {
        ...state,
        onboarding: {
          ...state.onboarding,
          isLoading: true,
          isError: false,
          error: null,
          data: null
        }
      };

    case types.GET_ONBOARDING_DETAILS_SUCCESS:
      return {
        ...state,
        onboarding: {
          ...state.onboarding,
          isLoading: false,
          isError: false,
          error: null,
          data: toNrAccountOnboardingDetails(payload)
        }
      };

    case types.GET_ONBOARDING_DETAILS_FAILURE:
      return {
        ...state,
        onboarding: {
          ...state.onboarding,
          isLoading: false,
          isError: true,
          error: payload,
          data: null
        }
      };

    case types.UPDATE_NR_USER_STATUS_SUCCESS:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          data: {
            ...state.userDetails.data,
            accountStatus: payload.accountStatus
          }
        }
      };

    case types.CLEAR_ONBOARDING_DETAILS:
      return {
        ...state,
        onboarding: {
          isLoading: false,
          isError: false,
          error: null,
          data: null
        }
      };

    default:
      return { ...state };
  }
};

export default nrAccountReducer;

import { useDispatch, useSelector } from 'react-redux';
import ContentLayout from '../../layouts/ContentLayout';
import {
  Grid,
  VStack,
  Heading,
  HStack,
  FormLabel,
  Select,
  Table,
  Tbody,
  Tr,
  Td,
  Text,
  Thead,
  Th,
  Spinner,
  Button,
  useDisclosure
} from '@chakra-ui/react';
import { useEffect } from 'react';
import {
  confirmReward,
  getReferralUserId,
  getRewardFunnelData,
  invalidateReward
} from '../../store/actions/rewardActions';
import { REWARD_STATUS, REWARD_TYPE } from '../../constants';
import {
  camelCaseToSpaceSeparated,
  kebabCaseToSpaceSeperate
} from '../../utils';
import { useState } from 'react';
import { tableCellStyles } from '../../components/NrOnboard/constants';
import dayjs from 'dayjs';
import Pagination from '../../components/Pagination';
import ModalLayout from '../../components/Modal/ModalLayout';
import { updateRewardsReqData } from '../../store/actions/reqDataActions';
import { setAlert } from '../../store/actions/alertActions';
import RewardsConfirmOrInvalidateModal from '../../components/Rewards/RewardsConfirmOrInvalidateModal';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const RewardsFunnel = () => {
  const navigate = useNavigate();

  const reqData = useSelector((state) => state?.reqData?.rewards);
  const { pageSize, pageNumber, totalPages, rewardType, rewardStatus } =
    reqData;

  const dispatch = useDispatch();

  const [rewards, setRewards] = useState([]);
  const [selectedReward, setSelectedReward] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [isReferrerDetailsLoading, setIsReferrerDetailsLoading] =
    useState(false);

  const {
    isOpen: isActionModalOpen,
    onOpen: onActionModalOpen,
    onClose: onActionModalClose
  } = useDisclosure();

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setSelectedReward(null);
    fetchData();
  }, [pageNumber, rewardType, rewardStatus]);

  const fetchData = () => {
    setIsLoading(true);
    dispatch(
      getRewardFunnelData({
        pageSize,
        pageNumber,
        rewardType,
        rewardStatus,
        onSuccess: (rewardsData) => {
          const { data, totalPages } = rewardsData;
          setRewards(data);
          dispatch(
            updateRewardsReqData({
              ...reqData,
              totalPages
            })
          );
        },
        onComplete: () => {
          setIsLoading(false);
        }
      })
    );
  };

  const handleReqDataChange = (e) => {
    const key = e?.target?.name,
      value = e?.target?.value;
    dispatch(
      updateRewardsReqData({
        ...reqData,
        [key]: value
      })
    );
  };

  const handleRewardActionClick = () => {
    onActionModalOpen();
  };

  const handleActionModalClose = () => {
    onActionModalClose && onActionModalClose();
    setSelectedReward(null);
  };

  const handleActionModalSubmit = ({ action }) => {
    if (!action) {
      dispatch(
        setAlert({
          title: 'Please choose an action',
          status: 'error'
        })
      );
      return;
    }

    setIsActionLoading(true);

    action === 'CONFIRM'
      ? dispatch(
          confirmReward({
            userId: selectedReward?.userId,
            onSuccess: () => {
              fetchData();
            },
            onComplete: () => {
              setIsActionLoading(false);
              handleActionModalClose();
            }
          })
        )
      : dispatch(
          invalidateReward({
            userId: selectedReward?.userId,
            onSuccess: () => {
              fetchData();
            },
            onComplete: () => {
              setIsActionLoading(false);
              handleActionModalClose();
            }
          })
        );
  };

  const handlePageChange = (page) => {
    page - 1 <= totalPages - 1 &&
      dispatch(
        updateRewardsReqData({
          ...reqData,
          pageNumber: page - 1
        })
      );
  };

  const handleReferrerDetailsClick = () => {
    setIsReferrerDetailsLoading(true);
    dispatch(
      getReferralUserId({
        referrerId: selectedReward?.referenceId,
        onSuccess: (data) => {
          const { referrer } = data;
          navigate(`/user/${referrer}`);
        },
        onComplete: () => {
          setIsReferrerDetailsLoading(false);
        }
      })
    );
  };

  const cellStyles = tableCellStyles();

  return (
    <>
      <ModalLayout isOpen={isActionModalOpen} onClose={handleActionModalClose}>
        <RewardsConfirmOrInvalidateModal
          isLoading={isActionLoading}
          onCancel={handleActionModalClose}
          onSubmit={handleActionModalSubmit}
        />
      </ModalLayout>
      <ContentLayout>
        <Grid gridTemplateColumns={{ base: '1fr', lg: '7fr 2fr' }}>
          <VStack
            overflowY={{ base: 'initial', lg: 'auto' }}
            overflowX={'hidden'}
            h={{ lg: '90vh' }}
            py={10}
            px={{ base: 4, sm: 10 }}
            alignItems={'stretch'}
            className="scroll"
            gap={4}
          >
            <Heading color={'white'}>Rewards</Heading>
            <HStack justifyContent={'end'} gap={4}>
              <VStack alignItems={'stretch'}>
                <FormLabel>Reward Type</FormLabel>
                <Select
                  value={rewardType}
                  name="rewardType"
                  placeholder="Select Reward Type"
                  onChange={handleReqDataChange}
                >
                  {Object.keys(REWARD_TYPE)?.map((rewardType) => (
                    <option key={rewardType} value={rewardType}>
                      {kebabCaseToSpaceSeperate(rewardType)}
                    </option>
                  ))}
                </Select>
              </VStack>
              <VStack alignItems={'stretch'}>
                <FormLabel>Reward Status</FormLabel>
                <Select
                  value={rewardStatus}
                  name="rewardStatus"
                  placeholder="Select Reward Status"
                  onChange={handleReqDataChange}
                >
                  {Object.keys(REWARD_STATUS)?.map((rewardStatus) => (
                    <option key={rewardStatus} value={rewardStatus}>
                      {kebabCaseToSpaceSeperate(rewardStatus)}
                    </option>
                  ))}
                </Select>
              </VStack>
            </HStack>
            <Pagination
              currentPage={pageNumber + 1}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
            {!isLoading ? (
              <Table>
                <Thead>
                  <Tr>
                    <Th style={cellStyles}>Reward Type</Th>
                    <Th style={cellStyles}>Reward Status</Th>
                    <Th style={cellStyles}>Reward Currency</Th>
                    <Th style={cellStyles}>Reward Segment</Th>
                    <Th style={cellStyles}>Eligibility Starts At</Th>
                    <Th style={cellStyles}>Expires At</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {rewards?.map((reward) => (
                    <Tr
                      _hover={{
                        backgroundColor: 'gray.900',
                        cursor: 'pointer'
                      }}
                      onClick={() => {
                        setSelectedReward(reward);
                      }}
                      key={reward?.userId + reward?.referrenceId}
                    >
                      <Td style={cellStyles}>
                        <Text color={'white'} fontSize={'md'}>
                          {kebabCaseToSpaceSeperate(reward?.rewardType) || '-'}
                        </Text>
                      </Td>
                      <Td style={cellStyles}>
                        <Text color={'white'} fontSize={'md'}>
                          {reward?.rewardStatus || '-'}
                        </Text>
                      </Td>
                      <Td style={cellStyles}>
                        <Text color={'white'} fontSize={'md'}>
                          {reward?.rewardCurrency || '-'}
                        </Text>
                      </Td>
                      <Td style={cellStyles}>
                        <Text color={'white'} fontSize={'md'}>
                          {reward?.rewardsSegment || '-'}
                        </Text>
                      </Td>
                      <Td style={cellStyles}>
                        <Text color={'white'} fontSize={'md'}>
                          {reward?.eligibilityStartsAt
                            ? dayjs(reward?.eligibilityStartsAt)
                            : '-'}
                        </Text>
                      </Td>
                      <Td style={cellStyles}>
                        <Text color={'white'} fontSize={'md'}>
                          {reward?.expiresAt
                            ? dayjs(reward?.expiresAt).format(
                                'DD MMM YYYY HH:mm '
                              )
                            : '-'}
                        </Text>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            ) : (
              <Spinner />
            )}
          </VStack>
          {selectedReward && (
            <VStack alignItems={'stretch'} gap={2} px={3} py={4}>
              <Heading size={'sm'} color={'white'}>
                Reward Details
              </Heading>
              <Table>
                <Tbody>
                  {Object.keys(selectedReward)?.map((key) => (
                    <Tr key={key}>
                      <Td
                        style={{
                          padding: '8px 8px 8px 0px',
                          border: 'none'
                        }}
                      >
                        <Text fontSize={'sm'} color={'gray.500'}>
                          {key ? camelCaseToSpaceSeparated(key) : '-'}:
                        </Text>
                      </Td>
                      <Td
                        style={{
                          padding: '8px 8px 8px 0px',
                          border: 'none'
                        }}
                      >
                        <Text fontSize={'sm'} color={'white'}>
                          {selectedReward[key] || '-'}
                        </Text>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
              {selectedReward?.rewardType === REWARD_TYPE.GOLD_REWARD &&
                selectedReward?.rewardStatus === REWARD_STATUS.SUBMITTED && (
                  <>
                    <Button
                      onClick={handleRewardActionClick}
                      variant={'outline'}
                      colorScheme="brand"
                    >
                      Confirm or Invalidate Referral
                    </Button>
                  </>
                )}
              {selectedReward?.referenceId && (
                <Button
                  variant={'outline'}
                  colorScheme="brand"
                  rightIcon={<FaExternalLinkAlt />}
                  onClick={handleReferrerDetailsClick}
                  isLoading={isReferrerDetailsLoading}
                >
                  Referrer Details
                </Button>
              )}
            </VStack>
          )}
        </Grid>
      </ContentLayout>
    </>
  );
};

export default RewardsFunnel;

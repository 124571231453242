import React, { useCallback, useRef } from 'react';
import InputComponent from '../../components/Input';
import { Button, VStack } from '@chakra-ui/react';
import { MdEast, MdSearch } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { updateLuluOrdersReqData } from '../../store/actions/reqDataActions';
import { getLuluOrders } from '../../store/actions/ordersActions';
import useDidMountEffect from '../../hooks/useDidMount';
import { useNavigate } from 'react-router-dom';
import GenericCard from '../../components/GenericCard';
import { CARD_TYPE } from '../../constants';
import useIsMobile from '../../hooks/useIsMobile';

const LuluOrdersContent = () => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const searchTimer = useRef(null);

  const searchReqData = useSelector(
    (state) => state.reqData.dashboard.luluOrders
  );

  const { data: orders, isLoading } = useSelector(
    (state) => state.resData.luluOrders
  );

  //refetch orders data on change in username query, with a delay of 2 seconds
  useDidMountEffect(() => {
    if (searchTimer.current) {
      clearTimeout(searchTimer.current);
      searchTimer.current = setTimeout(() => {
        searchOrders(true);
      }, 2000);
    } else {
      searchOrders(true);
      searchTimer.current = true;
    }
  }, [searchReqData.query]);

  useDidMountEffect(() => {
    searchOrders();
  }, [searchReqData.pageNo]);

  const searchOrders = async (refresh = true) => {
    if (refresh || !orders?.length) dispatch(getLuluOrders(searchReqData));
  };

  const handleSearchReqChange = useCallback(
    (e) => {
      if (e.target.value === searchReqData.query) return;
      dispatch(updateLuluOrdersReqData({ query: e.target.value }));
    },
    [dispatch, searchReqData.query]
  );

  const handlePageChange = useCallback(
    (pageNo) => {
      pageNo - 1 !== searchReqData.pageNo &&
        dispatch(updateLuluOrdersReqData({ pageNo: pageNo - 1 }));
    },
    [dispatch, searchReqData.pageNo]
  );

  const onOrderDetailsClick = useCallback(
    (orderId, userId) => {
      navigate(
        isMobile ? `/order/${orderId}` : `/user/${userId}?orderId=${orderId}`
      );
    },
    [isMobile, navigate]
  );

  console.log(orders);

  return (
    <>
      <InputComponent
        type="text"
        value={searchReqData.query}
        name="search"
        handleChange={handleSearchReqChange}
        placeholder="Search by Order ID or Txn Ref ID"
        inputTitle="Search lulu orders"
        leftElement={<MdSearch size="2rem" color="white" />}
        width="40rem"
        maxWidth="100%"
        autoComplete={'off'}
        spellcheck={false}
        autoCorrect={'off'}
      />

      <Button
        type="submit"
        color="black"
        rounded="lg"
        colorScheme="brand"
        my={5}
        py={2}
        onClick={() => searchOrders()}
        fontWeight={600}
        isLoading={isLoading}
        alignSelf="start"
        style={{
          marginTop: '1em'
        }}
      >
        Search
      </Button>

      <VStack
        py={5}
        alignSelf={'flex-start'}
        w={{ base: '100%', lg: '56rem' }}
        maxW={'100%'}
        alignItems={'stretch'}
        gap={1}
      >
        {orders?.length &&
          orders.map((item) => (
            <GenericCard
              key={item.orderId}
              cardData={item}
              cardType={CARD_TYPE.LULU_ORDER_CARD}
              ActionButtons={
                item.referredUserId === null
                  ? []
                  : [
                      <Button
                        key={'user-details-link'}
                        mt={{ base: 2, md: 0 }}
                        ml={{ base: 0, md: 1 }}
                        rightIcon={<MdEast color="black" />}
                        type="submit"
                        rounded="lg"
                        color={'black'}
                        colorScheme="brand"
                        alignSelf={{ base: 'center', md: 'center' }}
                        px={5}
                        onClick={() =>
                          onOrderDetailsClick(item.orderId, item.userId)
                        }
                      >
                        Order Details
                      </Button>
                    ]
              }
            />
          ))}
      </VStack>
    </>
  );
};

export default LuluOrdersContent;

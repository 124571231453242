import {
  VStack,
  Grid,
  Heading,
  useDisclosure,
  Table,
  Text,
  Spinner,
  Tbody,
  Tr,
  Td,
  IconButton,
  Thead,
  Th
} from '@chakra-ui/react';
import ContentLayout from '../../layouts/ContentLayout';
import ModalLayout from '../../components/Modal/ModalLayout';
import UpdateExchangeRateModal from '../../components/ExchangeRates/UpdateExchangeRateModal';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  getPlatforms,
  updatePlatform
} from '../../store/actions/platformTransferFeeActions';
import { tableCellStyles } from '../../components/NrOnboard/constants';
import { MdEdit } from 'react-icons/md';

const ExchangeRates = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [platforms, setPlatforms] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const [selectedPlatform, setSelectedPlatform] = useState(null);

  useEffect(() => {
    fetchPlatforms();
  }, []);

  const fetchPlatforms = () => {
    setLoading(true);
    dispatch(
      getPlatforms({
        onSuccess: (data) => setPlatforms(data),
        onComplete: () => {
          setLoading(false);
        }
      })
    );
  };

  const handleSubmit = (data) => {
    setLoading(true);
    dispatch(
      updatePlatform({
        platformData: data,
        onSuccess: (data) => {
          setPlatforms((prevPlatforms) =>
            prevPlatforms?.map((platform) =>
              platform?.appName === data?.appName ? data : platform
            )
          );
          handleCancel();
        },
        onComplete: () => {
          setLoading(false);
        }
      })
    );
  };

  const handleCancel = () => {
    setSelectedPlatform(null);
    onClose();
  };

  const handleEditPlatformClick = (platform) => {
    setSelectedPlatform(platform);
    onOpen();
  };

  const cellStyles = tableCellStyles(4);

  return (
    <>
      <ModalLayout isOpen={isOpen} onClose={handleCancel}>
        {selectedPlatform?.appName && (
          <UpdateExchangeRateModal
            appName={selectedPlatform?.appName}
            transferFees={selectedPlatform?.transferFees}
            exchangeRate={selectedPlatform?.exchangeRate}
            onCancel={handleCancel}
            onSubmit={handleSubmit}
            loading={loading}
          />
        )}
      </ModalLayout>
      <ContentLayout>
        <Grid gridTemplateColumns={{ base: '1fr', lg: '7fr 0fr 2fr' }}>
          <VStack
            p={10}
            gap={4}
            px={{ base: 4, sm: 10 }}
            alignItems={'flex-start'}
            w={'100%'}
          >
            <Heading color={'white'} size={'md'}>
              Exchange Rates
            </Heading>
            {!loading ? (
              <>
                {platforms?.length === 0 && (
                  <Text color="gray.400">No platforms data found</Text>
                )}
                {platforms?.length > 0 && (
                  <Table>
                    <Thead>
                      <Tr>
                        <Th style={cellStyles}>Platform</Th>
                        <Th style={cellStyles}>Transfer Fee</Th>
                        <Th style={cellStyles}>Exchange Rate</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {platforms?.map((platform) => (
                        <Tr key={platform?.appName}>
                          <Td style={cellStyles}>
                            <Text color={'white'} fontSize={'md'}>
                              {platform?.appName}
                            </Text>
                          </Td>
                          <Td style={cellStyles}>
                            <Text color={'white'} fontSize={'md'}>
                              {platform?.transferFees}
                            </Text>
                          </Td>
                          <Td style={cellStyles}>
                            <Text color={'white'} fontSize={'md'}>
                              {platform?.exchangeRate}
                            </Text>
                          </Td>
                          <Td style={cellStyles}>
                            <IconButton
                              variant={'unstyled'}
                              onClick={() => handleEditPlatformClick(platform)}
                              icon={<MdEdit color="green" />}
                            />
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                )}
              </>
            ) : (
              <Spinner />
            )}
          </VStack>
        </Grid>
      </ContentLayout>
    </>
  );
};

export default ExchangeRates;

import OrderDetailsCard from "../../components/OrderDetailsCard";
import { TICKET_TYPES } from "../../constants";

const ReferenceDetails = ({ticketType, referenceId, ...props}) => {
    switch(ticketType) {
        case TICKET_TYPES.PENDING_ORDER:
            return <OrderDetailsCard orderId={referenceId} {...props} />
        default: return null
    }
}

export default ReferenceDetails;
import { WORKFLOW_V2 as types } from '../constants';

const initialState = {
  workflowType: null,
  isLoading: false,
  isError: false,
  error: null,
  data: null
};

const workflowReducerV2 = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.GETTING_WORKFLOW_SCREENS_DATA:
      return state;

    case types.GET_WORKFLOW_SCREENS_DATA_SUCCESS:
      const finalState = {
        workflowType: payload?.workflowType,
        ...state,
        isLoading: false,
        isError: false,
        data: { ...payload }
      };
      return finalState;
    case types.GET_WORKFLOW_SCREENS_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: payload.errMsg
      };

    default:
      return { ...state };
  }
};

export default workflowReducerV2;

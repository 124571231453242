import {
  Box,
  Button,
  FormLabel,
  HStack,
  Input,
  ModalBody,
  ModalFooter,
  ModalHeader,
  VStack
} from '@chakra-ui/react';
import { useState } from 'react';
import {
  camelCaseToSpaceSeparated,
  kebabCaseToSpaceSeperate
} from '../../utils';

const GenericUpdateModal = ({
  data,
  isLoading,
  onCancel,
  onSubmit,
  title = 'Update'
}) => {
  const initialModalState = { ...data };
  const [modalState, setModalState] = useState(initialModalState);

  const handleChange = (e) => {
    const key = e?.target?.name;
    const value = e?.target?.value;
    setModalState((prev) => ({
      ...prev,
      [key]: value
    }));
  };

  const handleCancel = () => {
    setModalState(initialModalState);
    onCancel && onCancel();
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    onSubmit && onSubmit(modalState);
  };

  return (
    <>
      <ModalHeader>{title}</ModalHeader>
      <form onSubmit={handleSubmit}>
        <ModalBody>
          <VStack width={'full'} alignItems={'stretch'}>
            {Object.keys(modalState)?.map((key) => (
              <Box key={key}>
                <FormLabel>{camelCaseToSpaceSeparated(key)}</FormLabel>
                <Input
                  value={modalState[key]}
                  onChange={handleChange}
                  name={key}
                />
              </Box>
            ))}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <HStack>
            <Button
              type="button"
              variant={'outline'}
              isDisabled={isLoading}
              onClick={handleCancel}
              colorScheme="red"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant={'outline'}
              colorScheme="brand"
              isLoading={isLoading}
            >
              Update
            </Button>
          </HStack>
        </ModalFooter>
      </form>
    </>
  );
};

export default GenericUpdateModal;

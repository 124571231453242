import api from '../../api';
import { getErrorMessage } from '../../utils';
import { REFERRALS as types } from '../constants';
import { setAlert } from './alertActions';

export const searchCampaigns =
  (searchReqData, refresh = false) =>
  async (dispatch) => {
    try {
      dispatch({
        type: types.SEARCHING_ACTIVE_CAMPAIGNS,
        payload: {
          refresh,
          pageNo: searchReqData.pageNo
        }
      });

      const { data } = await api.referrals.searchCampaigns(searchReqData);

      return dispatch({
        type: types.SEARCH_ACTIVE_CAMPAIGNS_SUCCESS,
        payload: data
      });
    } catch (error) {
      return dispatch({
        type: types.SEARCH_ACTIVE_CAMPAIGNS_FAILURE,
        payload: error
      });
    }
  };

export const getCampaignByReferralCode = (campaignId) => async (dispatch) => {
  try {
    dispatch({
      type: types.GETTING_CAMPAIGN_BY_ID
    });

    const { data } = await api.referrals.campaignByReferralCode(campaignId);

    return dispatch({
      type: types.GET_CAMPAIGN_BY_ID_SUCCESS,
      payload: data
    });
  } catch (error) {
    return dispatch({
      type: types.GET_CAMPAIGN_BY_ID_FAILURE,
      payload: error
    });
  }
};

export const createCampaign =
  (formData, onSuccess, onError, onFinish) => async (dispatch) => {
    try {
      await api.referrals.create(formData);

      if (onSuccess) onSuccess();

      dispatch(
        setAlert({
          title: `Campaign ${formData.referralCode} successfully created!`,
          status: 'success'
        })
      );
      dispatch({
        type: types.CREATE_CAMPAIGN_SUCCESS
      });
    } catch (error) {
      if (onError) onError();

      const genericMessage =
        'Failed to create campaign. Status code: ' + error.status;

      dispatch(
        setAlert({
          title: error.message || genericMessage,
          status: 'error'
        })
      );
      dispatch({
        type: types.CREATE_CAMPAIGN_FAILURE
      });
    } finally {
      if (onFinish) onFinish();
    }
  };

export const editCampaign =
  (formData, onSuccess, onError, onFinish) => async (dispatch) => {
    try {
      await api.referrals.edit(formData);

      if (onSuccess) onSuccess();

      dispatch(
        setAlert({
          title: `Campaign ${formData.referralCode} successfully edited!`,
          status: 'success'
        })
      );
      dispatch({
        type: types.EDIT_CAMPAIGN_SUCCESS
      });
    } catch (error) {
      if (onError) onError();

      const genericMessage =
        'Failed to edit campaign. Status code: ' + error.status;

      dispatch(
        setAlert({
          title: error.message || genericMessage,
          status: 'error'
        })
      );
      dispatch({
        type: types.EDIT_CAMPAIGN_FAILURE
      });
    } finally {
      if (onFinish) onFinish();
    }
  };

export const clearCampaignDetails = () => ({
  type: types.CLEAR_CAMPAIGN_DETAILS
});

export const getRedeemedCoins = (searchReqData) => async (dispatch) => {
  try {
    dispatch({
      type: types.GETTING_REDEEMED_COINS
    });

    const { data } = await api.referralsV2.getRedeemedCoins(searchReqData);

    return dispatch({
      type: types.GET_REDEEMED_COINS_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch(
      setAlert({
        title: getErrorMessage({
          error,
          genericMessage: 'Failed to get redeemed coins!'
        }),
        status: 'error'
      })
    );
    dispatch({
      type: types.GET_REDEEMED_COINS_FAILURE,
      payload: error
    });
  }
};

export const getUserRedeemedCoins =
  (userId, searchReqData) => async (dispatch) => {
    try {
      dispatch({
        type: types.GETTING_USER_REDEEMED_COINS
      });

      const { data } = await api.referralsV2.getUserRedeemedCoins(
        userId,
        searchReqData
      );

      return dispatch({
        type: types.GET_USER_REDEEMED_COINS_SUCCESS,
        payload: data
      });
    } catch (error) {
      dispatch(
        setAlert({
          title: getErrorMessage({
            error,
            genericMessage: 'Failed to get redeemed coins!'
          }),
          status: 'error'
        })
      );
      dispatch({
        type: types.GET_USER_REDEEMED_COINS_FAILURE,
        payload: error
      });
    }
  };

export const redeemCoinsCompleted =
  ({ redeemId, metadata, onSuccess, onFinish }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: types.COMPLETING_REDEEMED_COINS
      });
      await api.referralsV2.markRedeemCoinsCompleted({ redeemId, metadata });
      if (onSuccess) onSuccess();

      dispatch(
        setAlert({
          title: 'Updated Redeemd coins status to completed!',
          status: 'success'
        })
      );

      return dispatch({
        type: types.COMPLETE_REDEEMED_COINS_SUCCESS
      });
    } catch (error) {
      dispatch(
        setAlert({
          title: getErrorMessage({
            error,
            genericMessage: 'Failed to update redeem coins status to completed!'
          }),
          status: 'error'
        })
      );
      return dispatch({
        type: types.COMPLETE_REDEEMED_COINS_FAILURE,
        payload: error
      });
    } finally {
      if (onFinish) onFinish();
    }
  };

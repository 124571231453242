import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import Login from './views/Login';

function UnauthenticatedApp() {
  return (
    <BrowserRouter>
      {/* {import.meta.env.PROD && <SegmentAnalytics />} */}
      <Routes>
        <Route path="login" element={<Login />} />
        <Route path="/*" element={<Navigate to="/login" />} />
      </Routes>
    </BrowserRouter>
  );
}

export { UnauthenticatedApp };

import { RDA_DASHBOARD_CONSTANTS as actionTypes } from './../constants';

const initialState = {
  overall: {
    isLoading: false,
    isError: false,
    error: null,
    data: [],
    totalPages: 1
  },
  truelayer: {
    isLoading: false,
    isError: false,
    error: null,
    data: [],
    totalPages: 1
  },
  clearbank: {
    isLoading: false,
    isError: false,
    error: null,
    data: [],
    totalPages: 1
  },
  ybl: {
    isLoading: false,
    isError: false,
    error: null,
    data: [],
    totalPages: 1
  }
};

const rdaDashboardReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.GETTING_OVERALL_TXNS:
      return {
        ...state,
        overall: {
          ...state.overall,
          isLoading: true,
          isError: false,
          error: null
        }
      };
    case actionTypes.GET_OVERALL_TXNS_SUCCESS:
      return {
        ...state,
        overall: {
          isLoading: false,
          isError: false,
          error: null,
          data: payload?.data,
          totalPages: payload?.totalPages
        }
      };
    case actionTypes.GET_OVERALL_TXNS_FAIL:
      return {
        ...state,
        overall: {
          ...state.overall,
          isLoading: false,
          isError: true,
          error: payload?.message
        }
      };

    case actionTypes.GETTING_YBL_PARTNER_TXNS:
      return {
        ...state,
        ybl: {
          ...state.ybl,
          isLoading: true,
          isError: false,
          error: null
        }
      };
    case actionTypes.GET_YBL_PARTNER_TXNS_SUCCESS:
      return {
        ...state,
        ybl: {
          isLoading: false,
          isError: false,
          error: null,
          data: payload?.data,
          totalPages: payload?.totalPages
        }
      };
    case actionTypes.GET_YBL_PARTNER_TXNS_FAIL:
      return {
        ...state,
        ybl: {
          ...state.ybl,
          isLoading: false,
          isError: true,
          error: payload?.message
        }
      };

    case actionTypes.GETTING_TL_TXNS:
      return {
        ...state,
        truelayer: {
          ...state.truelayer,
          isLoading: true,
          isError: false,
          error: null
        }
      };
    case actionTypes.GET_TL_TXNS_SUCCESS:
      return {
        ...state,
        truelayer: {
          isLoading: false,
          isError: false,
          error: null,
          data: payload?.data,
          totalPages: payload?.totalPages
        }
      };
    case actionTypes.GET_TL_TXNS_FAIL:
      return {
        ...state,
        truelayer: {
          ...state.truelayer,
          isLoading: false,
          isError: true,
          error: payload?.message
        }
      };

    case actionTypes.GETTING_CB_TXNS:
      return {
        ...state,
        clearbank: {
          ...state.clearbank,
          isLoading: true,
          isError: false,
          error: null
        }
      };
    case actionTypes.GET_CB_TXNS_SUCCESS:
      return {
        ...state,
        clearbank: {
          isLoading: false,
          isError: false,
          error: null,
          data: payload?.data,
          totalPages: payload?.totalPages
        }
      };
    case actionTypes.GET_CB_TXNS_FAIL:
      return {
        ...state,
        clearbank: {
          ...state.clearbank,
          isLoading: false,
          isError: true,
          error: payload?.message
        }
      };

    default:
      return state;
  }
};

export default rdaDashboardReducer;

import {
  Grid,
  Heading,
  Table,
  Tbody,
  Td,
  Tr,
  VStack,
  Text,
  Thead,
  Th,
  Spinner,
  useDisclosure,
  HStack,
  Button
} from '@chakra-ui/react';
import ContentLayout from '../../layouts/ContentLayout';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { getOrderTransferChecks } from '../../store/actions/ordersActions';
import { tableCellStyles } from '../../components/NrOnboard/constants';
import ModalLayout from '../../components/Modal/ModalLayout';
import BeneficiaryChecksModal from '../../components/OrderTransferChecks/BeneficiaryChecksModal';
import { updateBeneficiaryRisks } from '../../store/actions/thunesActions';

const OrderTransferChecks = () => {
  const { orderId } = useParams();
  const dispatch = useDispatch();
  const [orderTransferChecks, setOrderTransferChecks] = useState({});
  const [loading, setLoading] = useState(false);
  const {
    isOpen: isChecksOpen,
    onOpen: onChecksOpen,
    onClose: onChecksClose
  } = useDisclosure();

  useEffect(() => {
    setLoading(true);
    dispatch(
      getOrderTransferChecks({
        orderId,
        onSuccess: (data) => {
          setOrderTransferChecks(data);
        },
        onComplete: () => {
          setLoading(false);
        }
      })
    );
  }, []);

  const cellStyles = tableCellStyles();

  const handleOrderChecksOpen = () => {
    onChecksOpen();
  };

  const handleOrderChecksClose = () => {
    onChecksClose();
  };

  const handleOrderChecksSubmit = (data) => {
    setLoading(true);
    dispatch(
      updateBeneficiaryRisks({
        riskCategory: data?.riskCategory,
        acctNo: '',
        orderId,
        onSuccess: () => {
          dispatch(
            getOrderTransferChecks({
              orderId,
              onSuccess: (data) => {
                setOrderTransferChecks(data);
              },
              onComplete: () => {
                setLoading(false);
              }
            })
          );
          handleOrderChecksClose();
        },
        onComplete: () => {
          setLoading(false);
        }
      })
    );
  };

  return (
    <>
      <ModalLayout isOpen={isChecksOpen} onClose={handleOrderChecksClose}>
        <BeneficiaryChecksModal
          isLoading={loading}
          handleSubmit={handleOrderChecksSubmit}
          handleCancel={handleOrderChecksClose}
        />
      </ModalLayout>
      <ContentLayout>
        <Grid gridTemplateColumns={{ base: '1fr', lg: '7fr 0fr 2fr' }}>
          <VStack
            alignItems={'stretch'}
            width={'auto'}
            gap={4}
            overflowY={{ base: 'initial', lg: 'scroll' }}
            overflowX={'hidden'}
            h={{ lg: '90vh' }}
            py={10}
            px={{ base: 4, sm: 10 }}
            className="scroll"
          >
            <HStack
              justifyContent={'space-between'}
              alignItems={'center'}
              width={'full'}
            >
              <Heading size={'lg'} color={'white'}>
                Order Transfer Checks
              </Heading>
              <Button
                onClick={handleOrderChecksOpen}
                variant={'outline'}
                colorScheme="brand"
              >
                Update Beneficiary Risk
              </Button>
            </HStack>
            <Table>
              <Tbody>
                <Tr>
                  <Td style={cellStyles}>
                    <Text fontSize={'md'} color={'gray.400'}>
                      Order Id:
                    </Text>
                  </Td>
                  <Td style={cellStyles}>
                    <Text fontSize={'md'} color={'white'}>
                      {orderId}
                    </Text>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
            {!loading ? (
              <>
                {orderTransferChecks?.checks?.length > 0 && (
                  <Table>
                    <Thead>
                      <Tr>
                        <Th style={cellStyles}>Check Name</Th>
                        <Th style={cellStyles}>Result</Th>
                        <Th style={cellStyles}>Message</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {orderTransferChecks?.checks?.map((check) => (
                        <Tr key={check?.checkName}>
                          <Td style={cellStyles}>
                            <Text fontSize={'md'} color={'white'}>
                              {check?.checkName}
                            </Text>
                          </Td>
                          <Td style={cellStyles}>
                            <Text fontSize={'md'} color={'white'}>
                              {check?.result?.result}
                            </Text>
                          </Td>
                          <Td style={cellStyles}>
                            <Text fontSize={'md'} color={'white'}>
                              {check?.result?.message || '-'}
                            </Text>
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                )}
                {orderTransferChecks?.checks?.length === 0 && (
                  <Text color={'white'} fontSize={'md'}>
                    No Checks found
                  </Text>
                )}
              </>
            ) : (
              <Spinner />
            )}
          </VStack>
        </Grid>
      </ContentLayout>
    </>
  );
};

export default OrderTransferChecks;

import { Grid, Box } from '@chakra-ui/react';
import FeatureDetail from './FeatureDetail';
import dayjs from 'dayjs';

const CbTxnDetailsCard = ({ createdAt, updatedAt, payoutAmount, status }) => {
  return (
    <Box
      width={'full'}
      px={4}
      py={5}
      background={'#1C1C1C'}
      borderRadius={'lg'}
    >
      <Grid
        gridTemplateColumns={'1fr 1fr 1fr'}
        width={'full'}
        columnGap={3}
        rowGap={4}
      >
        <FeatureDetail
          featureValue={payoutAmount}
          featureName={'Payout Amount'}
        />
        <FeatureDetail featureValue={status} featureName={'Status'} />
        <FeatureDetail
          featureValue={dayjs(createdAt).format('DD MMM YYYY HH:MM A')}
          featureName={'Created At'}
        />
        <FeatureDetail
          featureValue={dayjs(updatedAt).format('DD MMM YYYY HH:MM A')}
          featureName={'Updated At'}
        />
      </Grid>
    </Box>
  );
};

export default CbTxnDetailsCard;

import { useEffect, useState } from 'react';
import ContentLayout from '../../../layouts/ContentLayout';
import {
  Grid,
  Heading,
  Spinner,
  VStack,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Th,
  IconButton,
  HStack,
  useDisclosure,
  Button
} from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import {
  getDowntimeConfig,
  getTat,
  updateDowntime,
  updateTurnAroundTime
} from '../../../store/actions/configActions';
import { tableCellStyles } from '../../../components/NrOnboard/constants';
import dayjs from 'dayjs';
import { MdEdit } from 'react-icons/md';
import ModalLayout from '../../../components/Modal/ModalLayout';
import UpdateDowntimeModal from '../../../components/TransferExperience/UpdateDowntimeModal';
import UpdateTurnAroundTimeModal from '../../../components/TransferExperience/UpdateTurnAroundTimeModal';

const TransferExperienceConfig = () => {
  const dispatch = useDispatch();

  const [isActiveDowntimesLoading, setActiveDowntimesLoading] = useState(false);
  const [activeDowntimes, setActiveDowntimes] = useState([]);
  const [turnAroundTime, setTurnAroundTime] = useState([]);
  const [isTatLoading, setIsTatLoading] = useState(false);
  const [selectedDowntime, setSelectedDowntime] = useState(null);
  const [selectedTat, setSelectedTat] = useState(null);

  const {
    isOpen: isUpdateDowntimeOpen,
    onOpen: onUpdateDowntimeOpen,
    onClose: onUpdateDowntimeClose
  } = useDisclosure();

  const {
    isOpen: isUpdateTatOpen,
    onOpen: onUpdateTatOpen,
    onClose: onUpdateTatClose
  } = useDisclosure();

  const cellStyles = tableCellStyles();

  useEffect(() => {
    setActiveDowntimesLoading(true);
    dispatch(
      getDowntimeConfig({
        onSuccess: (data) => {
          setActiveDowntimes(data?.activeDowntimes);
        },
        onComplete: () => {
          setActiveDowntimesLoading(false);
        }
      })
    );

    setIsTatLoading(true);
    dispatch(
      getTat({
        onSuccess: (data) => {
          setTurnAroundTime(data);
        },
        onComplete: () => {
          setIsTatLoading(false);
        }
      })
    );
  }, []);

  const handleUpdateDowntimeOpen = (downtime, isNew = false) => {
    !isNew && setSelectedDowntime(downtime);
    onUpdateDowntimeOpen();
  };

  const handleUpdateDowntimeClose = () => {
    setSelectedDowntime(null);
    onUpdateDowntimeClose();
  };

  const handleUpdateDowntimeSubmit = ({
    paymentAcquirer,
    downtimeStartDate,
    downtimeEndDate,
    downtimeReason
  }) => {
    setActiveDowntimesLoading(true);
    dispatch(
      updateDowntime({
        paymentAcquirer,
        downtimeStartDate,
        downtimeEndDate,
        downtimeReason,
        onSuccess: () => {
          dispatch(
            getDowntimeConfig({
              onSuccess: (data) => {
                handleUpdateDowntimeClose();
                setActiveDowntimes(data?.activeDowntimes);
              }
            })
          );
        },
        onComplete: () => {
          setActiveDowntimesLoading(false);
        }
      })
    );
  };

  const handleUpdateTatOpen = (tat, isNew = false) => {
    !isNew && setSelectedTat(tat);
    onUpdateTatOpen();
  };

  const handleUpdateTatCancel = () => {
    setSelectedTat(null);
    onUpdateTatClose();
  };

  const handleUpdateTatSubmit = (newTat) => {
    setIsTatLoading(true);
    dispatch(
      updateTurnAroundTime({
        tat: newTat,
        onSuccess: () => {
          setSelectedTat(null);
          dispatch(
            getTat({
              onSuccess: (data) => {
                setTurnAroundTime(data);
              }
            })
          );
          handleUpdateTatCancel();
        },
        onComplete: () => {
          setIsTatLoading(false);
        }
      })
    );
  };

  return (
    <>
      <ModalLayout isOpen={isUpdateTatOpen} onClose={handleUpdateTatCancel}>
        <UpdateTurnAroundTimeModal
          initialTat={selectedTat}
          isLoading={isTatLoading}
          onCancel={handleUpdateTatCancel}
          onSubmit={handleUpdateTatSubmit}
        />
      </ModalLayout>
      <ModalLayout
        isOpen={isUpdateDowntimeOpen}
        onClose={handleUpdateDowntimeClose}
      >
        <UpdateDowntimeModal
          isNew={false}
          downtime={selectedDowntime}
          onCancel={handleUpdateDowntimeClose}
          onSubmit={handleUpdateDowntimeSubmit}
          isLoading={isActiveDowntimesLoading}
        />
      </ModalLayout>
      <ContentLayout>
        <Grid gridTemplateColumns={{ base: '1fr', lg: '7fr 0fr 2fr' }}>
          <VStack
            width={'auto'}
            alignItems={'stretch'}
            gap={4}
            overflowY={{ base: 'initial', lg: 'scroll' }}
            overflowX={'hidden'}
            h={{ lg: '90vh' }}
            py={10}
            px={{ base: 4, sm: 10 }}
            className="scroll"
          >
            <Heading size={'lg'} color={'#81EBAB'}>
              Transfer Experience Configuration
            </Heading>
            <Heading color={'white'} size={'md'}>
              Active Downtimes
            </Heading>
            {activeDowntimes.length === 0 && (
              <Text color="white" fontSize="md">
                No downtimes found!
              </Text>
            )}
            {isActiveDowntimesLoading ? (
              <Spinner />
            ) : (
              <Table>
                <Thead>
                  <Tr>
                    <Th style={cellStyles}>
                      <Text fontSize={'md'}>Acquirer</Text>
                    </Th>
                    <Th style={cellStyles}>
                      <Text fontSize={'md'}>Downtime Start Date</Text>
                    </Th>
                    <Th style={cellStyles}>
                      <Text fontSize={'md'}>Downtime End Date</Text>
                    </Th>
                    <Th style={cellStyles}>
                      <Text fontSize={'md'}>Downtime Reason</Text>
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {activeDowntimes?.map((downtime) => (
                    <Tr key={downtime?.paymentAcquirer}>
                      <Td style={cellStyles}>
                        <Text color={'white'} fontSize={'md'}>
                          {downtime?.paymentAcquirer || '-'}
                        </Text>
                      </Td>
                      <Td style={cellStyles}>
                        <Text color={'white'} fontSize={'md'}>
                          {downtime?.downtimeStartDate
                            ? dayjs(downtime?.downtimeStartDate).format(
                                'DD MMM YYYY, HH:MM A'
                              )
                            : '-'}
                        </Text>
                      </Td>
                      <Td style={cellStyles}>
                        <Text color={'white'} fontSize={'md'}>
                          {downtime?.downtimeEndDate
                            ? dayjs(downtime?.downtimeEndDate).format(
                                'DD MMM YYYY, HH:MM A'
                              )
                            : '-'}
                        </Text>
                      </Td>
                      <Td style={cellStyles}>
                        <Text color={'white'} fontSize={'md'}>
                          {downtime?.downtimeReason}
                        </Text>
                      </Td>
                      <Td style={cellStyles}>
                        <IconButton
                          onClick={() => handleUpdateDowntimeOpen(downtime)}
                          variant={'unstyled'}
                          icon={<MdEdit color="white" />}
                        />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            )}
            <HStack justifyContent={'end'} width={'full'}>
              <Button
                colorScheme="brand"
                variant={'outline'}
                type="button"
                onClick={() => {
                  handleUpdateDowntimeOpen(null, true);
                }}
              >
                Add Downtime
              </Button>
            </HStack>
            <Heading color={'white'} size={'md'}>
              Turn Around Time
            </Heading>
            {!isTatLoading ? (
              <>
                <Table>
                  <Thead>
                    <Tr>
                      <Th style={cellStyles}>
                        <Text color={'gray.500'} fontSize={'md'}>
                          Acquirer
                        </Text>
                      </Th>
                      <Th style={cellStyles}>
                        <Text color={'gray.500'} fontSize={'md'}>
                          TAT
                        </Text>
                      </Th>
                      <Th style={cellStyles}></Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {turnAroundTime?.map((tat) => (
                      <Tr key={tat?.id}>
                        <Td style={cellStyles}>
                          <Text color={'white'} fontSize={'md'}>
                            {tat?.type}
                          </Text>
                        </Td>
                        <Td style={cellStyles}>
                          <Text color={'white'} fontSize={'md'}>
                            {tat?.value}
                          </Text>
                        </Td>
                        <Td style={cellStyles}>
                          <IconButton
                            variant={'unstyled'}
                            icon={<MdEdit color="white" />}
                            onClick={() => handleUpdateTatOpen(tat)}
                          />
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
                <HStack justifyContent={'end'} width={'full'}>
                  <Button
                    colorScheme="brand"
                    variant={'outline'}
                    type="button"
                    onClick={() => {
                      handleUpdateTatOpen(null, true);
                    }}
                  >
                    Add TAT
                  </Button>
                </HStack>
              </>
            ) : (
              <Spinner />
            )}
            {/* </HStack> */}
          </VStack>
        </Grid>
      </ContentLayout>
    </>
  );
};

export default TransferExperienceConfig;

import { useEffect, useState } from 'react';
import ModalLayout from '../Modal/ModalLayout';
import TicketModal from './../Modal/TicketModal';
import {
  Button,
  Spinner,
  VStack,
  useDisclosure,
  Text,
  Table,
  Tr,
  Tbody,
  Td
} from '@chakra-ui/react';
import { getTicket } from '../../store/actions/ticketActions';
import { useDispatch, useSelector } from 'react-redux';
import { tableCellStyles } from '../NrOnboard/constants';
import { camelCaseToSpaceSeparated } from '../../utils';
import dayjs from 'dayjs';
import { getAllCxUsers } from '../../store/actions/cxUsersActions';

const TicketWidget = ({ referenceId, ticketType }) => {
  const [ticket, setTicket] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { data: cxUsers } = useSelector((state) => state?.cxUsers);

  useEffect(() => {
    dispatch(getAllCxUsers());
  }, []);

  useEffect(() => {
    if (Object.keys(cxUsers || {})?.length === 0) return;
    setLoading(true);
    dispatch(
      getTicket({
        referenceId,
        ticketType,
        onSuccess: (ticketData) => {
          setTicket(ticketData ? { ...ticketData } : null);
        },
        onComplete: () => {
          setLoading(false);
        }
      })
    );
  }, [referenceId, ticketType, cxUsers]);

  const transformTicket = (ticketData) => {
    if (!ticketData) return {};
    const { status, notes, ticketType, ticketId } = ticketData;
    const assignedTo = cxUsers && cxUsers[ticketData?.assignedTo]?.name;
    const createdOn = dayjs(ticketData?.createDate).format('DD/MM/YY');
    return {
      createdOn,
      status,
      notes,
      ticketType,
      assignedTo,
      ticketId
    };
  };

  /* 
    checks if the ticket exists if exists show the details 
    and option to edit it 
    else shows ticket details and option to update it
*/

  const cellStyles = tableCellStyles(4);

  return (
    <>
      <ModalLayout isOpen={isOpen} onClose={onClose}>
        <TicketModal
          status={ticket?.status || ''}
          ticketType={ticketType}
          referenceId={referenceId}
          assignedTo={ticket?.assignedTo || ''}
          notes={ticket?.notes || null}
          ticketId={ticket?.ticketId || null}
          onClose={() => {
            onClose();
          }}
          callback={(data) => {
            setTicket(data);
          }}
          updateStore={false}
        />
      </ModalLayout>
      <VStack alignItems={'stretch'} padding={'1rem'} gap={2}>
        <Text color={'white'} fontSize={'lg'}>
          Ticket Details
        </Text>
        {loading ? (
          <Spinner />
        ) : !ticket ? (
          <Text color={'white'} fontSize={'md'}>
            No ticket found
          </Text>
        ) : (
          <Table>
            <Tbody>
              {Object.keys(transformTicket(ticket))?.map((key) => (
                <Tr key={key}>
                  <Td style={cellStyles}>
                    <Text color={'gray.400'} fontSize={'sm'}>
                      {camelCaseToSpaceSeparated(key)}:
                    </Text>
                  </Td>
                  <Td style={cellStyles}>
                    <Text color={'white'} fontSize={'sm'}>
                      {String(transformTicket(ticket)[key])}
                    </Text>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
        <Button
          colorScheme="green"
          variant={'outline'}
          isLoading={loading}
          onClick={() => {
            onOpen();
          }}
        >
          {!ticket ? 'Create Ticket' : 'Edit Ticket'}
        </Button>
      </VStack>
    </>
  );
};

export default TicketWidget;

import {
  Grid,
  Heading,
  Spinner,
  VStack,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Th,
  useDisclosure,
  HStack,
  Button
} from '@chakra-ui/react';
import ContentLayout from '../../layouts/ContentLayout';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  creditVanceCash,
  getAllVanceCashTxns
} from '../../store/actions/vanceCashActions';
import Pagination from '../../components/Pagination';
import { updateVanceCashReqData } from '../../store/actions/reqDataActions';
import { tableCellStyles } from '../../components/NrOnboard/constants';
import dayjs from 'dayjs';
import ModalLayout from '../../components/Modal/ModalLayout';
import { CreateVanceCashTxnModal } from '../../components/VanceCash';
import useAuthRole from '../../hooks/useAuthRole';
import { ROLES } from '../../constants';

const VanceCash = () => {
  const { userId } = useParams();
  const dispatch = useDispatch();
  const [vanceCashResponse, setVanceCashResponse] = useState({
    totalAvailableCash: 0,
    currency: 'AED',
    transactionHistory: {}
  });
  const {
    totalAvailableCash,
    currency,
    transactionHistory = {
      data: [],
      totalPages: 0
    }
  } = vanceCashResponse;
  const vanceCashTxns = transactionHistory?.data || [];
  const totalPages = transactionHistory?.totalPages || 0;
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  const {
    isOpen: isCreateTxnOpen,
    onOpen: onCreateTxnOpen,
    onClose: onCreateTxnClose
  } = useDisclosure();

  const { page, pageSize } = useSelector((state) => state?.reqData?.vanceCash);
  const { hasAnyAuthority } = useAuthRole();
  const { user } = useSelector((state) => state?.auth);

  useEffect(() => {
    setLoading(true);
    dispatch(
      getAllVanceCashTxns({
        userId,
        page: page - 1,
        pageSize,
        onSuccess: (data) => {
          setVanceCashResponse(data);
        },
        onComplete: () => {
          setLoading(false);
        }
      })
    );
  }, [page]);

  const handlePageChange = (page) => {
    dispatch(
      updateVanceCashReqData({
        page
      })
    );
  };

  const cellStyles = tableCellStyles(16);

  const handleAddVanceCashClick = () => {
    onCreateTxnOpen && onCreateTxnOpen();
  };

  const handleCreditVanceCashSubmit = (data) => {
    setBtnLoading(true);
    dispatch(
      creditVanceCash({
        userId,
        ...{ ...data, remarks: data?.remarks + ` - ${user.name}` },
        onSuccess: () => {
          setLoading(true);
          dispatch(
            getAllVanceCashTxns({
              userId,
              page: page - 1,
              pageSize,
              onSuccess: (data) => {
                setVanceCashResponse(data);
              },
              onComplete: () => {
                setLoading(false);
              }
            })
          );
        },
        onComplete: () => {
          setBtnLoading(false);
          handleCreditVanceCashCancel();
        }
      })
    );
  };
  const handleCreditVanceCashCancel = () => {
    onCreateTxnClose();
  };

  return (
    <>
      <ModalLayout
        isOpen={isCreateTxnOpen}
        onClose={handleCreditVanceCashCancel}
      >
        <CreateVanceCashTxnModal
          currency={currency}
          isLoading={btnLoading}
          onCancel={handleCreditVanceCashCancel}
          onSubmit={handleCreditVanceCashSubmit}
        />
      </ModalLayout>
      <ContentLayout>
        <Grid gridTemplateColumns={{ base: '1fr', lg: '7fr 0fr 2fr' }}>
          <VStack
            py={{ base: 5, lg: 20 }}
            px={5}
            w={{ base: '100%', lg: '70rem' }}
            overflowY={{ lg: 'scroll' }}
            h={{ lg: '100vh' }}
            className="scroll"
            alignItems={'stretch'}
            gap={2}
          >
            <Heading color={'white'} size={'lg'}>
              Vance Cash
            </Heading>
            {hasAnyAuthority(ROLES.REFERRAL_MANAGER) && (
              <HStack
                width={'full'}
                alignItems={'center'}
                justifyContent={'space-between'}
              >
                <HStack gap={2} alignItems={'center'}>
                  <Text color={'gray.500'} fontSize={'md'}>
                    Balance:
                  </Text>
                  <Text color={'white'} fontSize={'md'}>
                    {totalAvailableCash}
                  </Text>
                </HStack>
                <Button
                  variant={'outline'}
                  colorScheme="brand"
                  onClick={handleAddVanceCashClick}
                >
                  Add Vance Cash
                </Button>
              </HStack>
            )}
            {!loading ? (
              <>
                {vanceCashTxns.length === 0 && (
                  <Text color={'white'} fontSize={'md'}>
                    No Vance Cash Txns Found!
                  </Text>
                )}
                {vanceCashTxns.length > 0 && (
                  <>
                    <Pagination
                      currentPage={page}
                      totalPages={totalPages}
                      onPageChange={handlePageChange}
                    />
                    <Table>
                      <Thead>
                        <Tr>
                          <Th style={cellStyles}>Currency</Th>
                          <Th style={cellStyles}>Transaction Type</Th>
                          <Th style={cellStyles}>Reward Amount</Th>
                          <Th style={cellStyles}>Status</Th>
                          <Th style={cellStyles}>Rewarded on</Th>
                          <Th style={cellStyles}>Message</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {vanceCashTxns.map((txn, ind) => (
                          <Tr key={ind}>
                            <Td style={cellStyles}>
                              <Text color={'white'} fontSize={'md'}>
                                {txn.currency}
                              </Text>
                            </Td>
                            <Td style={cellStyles}>
                              <Text color={'white'} fontSize={'md'}>
                                {txn.transactionType}
                              </Text>
                            </Td>
                            <Td style={cellStyles}>
                              <Text color={'white'} fontSize={'md'}>
                                {txn.rewardAmount}
                              </Text>
                            </Td>
                            <Td style={cellStyles}>
                              <Text color={'white'} fontSize={'md'}>
                                {txn.transactionStatus}
                              </Text>
                            </Td>
                            <Td style={cellStyles}>
                              <Text color={'white'} fontSize={'md'}>
                                {dayjs(txn.rewardDate).format('DD MMM YYYY')}
                              </Text>
                            </Td>
                            <Td style={cellStyles}>
                              <Text color={'white'} fontSize={'md'}>
                                {txn.rewardMessage}
                              </Text>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </>
                )}
              </>
            ) : (
              <Spinner color="white" />
            )}
            <></>
          </VStack>
        </Grid>
      </ContentLayout>
    </>
  );
};

export default VanceCash;

import { SOURCE_ACCOUNTS as types } from '../constants';

const initialState = {
  accounts: {
    isLoading: false,
    isError: false,
    error: null,
    data: null,
    totalPages: 0
  },
  userSourceAccounts: {
    isLoading: false,
    isError: false,
    error: null,
    data: [],
    totalPages: 0
  }
};

const sourceAccountReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.GETTING_SOURCE_ACCOUNTS:
      return {
        ...state,
        accounts: {
          ...state.accounts,
          isLoading: true,
          isError: false,
          error: null,
          data: null,
          totalPages: 0
        }
      };
    case types.GET_SOURCE_ACCOUNTS_SUCCESS:
      return {
        ...state,
        accounts: {
          ...state.accounts,
          isLoading: false,
          isError: false,
          error: null,
          data: payload.data,
          totalPages: payload.totalPages
        }
      };

    case types.GET_SOURCE_ACCOUNTS_FAILURE:
      return {
        ...state,
        accounts: {
          ...state.accounts,
          isLoading: false,
          isError: true,
          error: payload,
          data: null,
          totalPages: 0
        }
      };

    case types.GETTING_USER_SOURCE_ACCOUNTS:
      return {
        ...state,
        userSourceAccounts: {
          ...state?.userSourceAccounts,
          isLoading: true,
          isError: false,
          error: null,
          totalPages: 0
        }
      };

    case types.GET_USER_SOURCE_ACCOINTS_FAILURE:
      return {
        ...state,
        userSourceAccounts: {
          ...state?.userSourceAccounts,
          isLoading: false,
          isError: true,
          error: payload?.errMsg
        }
      };

    case types.GET_USER_SOURCE_ACCOUNTS_SUCCESS:
      return {
        ...state,
        userSourceAccounts: {
          ...state?.userSourceAccounts,
          isLoading: false,
          isError: false,
          error: null,
          totalPages: payload?.totalPages || 0,
          data: payload?.data
        }
      };

    case types.DELETING_USER_SOURCE_ACCOUNT:
      return {
        ...state,
        userSourceAccounts: {
          ...state?.userSourceAccounts,
          isLoading: true,
          isError: false,
          error: null
        }
      };

    case types.DELETE_USER_SOURCE_ACCOUNT_SUCCESS:
      return {
        ...state,
        userSourceAccounts: {
          ...state?.userSourceAccounts,
          isLoading: false,
          isError: false,
          error: null
        }
      };

    case types.DELETE_USER_SOURCE_ACCOUNT_FAILURE:
      return {
        ...state,
        userSourceAccounts: {
          ...state?.userSourceAccounts,
          isLoading: false,
          isError: true,
          error: payload?.errMsg
        }
      };

    default:
      return {
        ...state
      };
  }
};

export default sourceAccountReducer;

import {
  Box,
  Button,
  Checkbox,
  FormLabel,
  HStack,
  Input,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Select,
  Textarea,
  VStack
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import {
  NR_OB_COUNTRY,
  NR_OB_UAE_REQ_DOCS,
  NR_OB_UK_REQ_DOCS
} from '../../constants';
import { getRejectReasonsBasedOnDoc } from '../../utils';

const NrobDocRejectModal = ({
  country,
  btnLoading,
  handleRejectSubmit,
  docs
}) => {
  const [step, setStep] = useState(0);
  const [docsState, setDocsState] = useState([]);
  const [overallRejectReason, setOverallRejectReason] = useState('');

  const handleSubmit = (e) => {
    e?.preventDefault();
    if (step === 0) {
      setStep(1);
      return;
    }
    handleRejectSubmit &&
      handleRejectSubmit({
        rejectReason: overallRejectReason,
        rejectedDocuments: docsState
          .filter((doc) => doc?.isChecked)
          .map((doc) => ({
            subDocumentType: doc?.docName,
            rejectionReason: doc?.rejectReason
          }))
      });
  };

  useEffect(() => {
    // const docs =
    //   country === NR_OB_COUNTRY.AE ? NR_OB_UAE_REQ_DOCS : NR_OB_UK_REQ_DOCS;
    setDocsState(
      docs?.map((doc) => ({
        docName: doc,
        isChecked: false,
        rejectReason: ''
      }))
    );
  }, [country]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <ModalHeader>Document Reject Modal</ModalHeader>
        <ModalBody>
          {step === 0 && (
            <VStack alignItems={'stretch'} gap={1}>
              {docsState.map((doc) => (
                <Checkbox
                  key={doc?.docName}
                  value={doc?.docName}
                  isChecked={doc?.isChecked}
                  onChange={(e) => {
                    setDocsState((prevDocs) =>
                      prevDocs?.map((prevDoc) =>
                        prevDoc?.docName === doc?.docName
                          ? {
                              ...prevDoc,
                              isChecked: !prevDoc?.isChecked
                            }
                          : prevDoc
                      )
                    );
                  }}
                >
                  {doc?.docName}
                </Checkbox>
              ))}
            </VStack>
          )}
          {step === 1 && (
            <VStack alignItems={'stretch'} gap={1}>
              {docsState
                ?.filter((doc) => doc?.isChecked)
                ?.map((doc) => (
                  <Box key={doc?.docName}>
                    <FormLabel htmlFor={doc?.docName}>{doc?.docName}</FormLabel>
                    {/* <Input
                      required
                      type="text"
                      value={doc?.rejectReason}
                      placeholder={`Reject reason for ${doc?.docName}`}
                      onChange={(e) => {
                        setDocsState((prevDocs) =>
                          prevDocs?.map((prevDoc) =>
                            prevDoc?.docName === doc?.docName
                              ? { ...prevDoc, rejectReason: e?.target?.value }
                              : prevDoc
                          )
                        );
                      }}
                    /> */}
                    <Select
                      required
                      value={doc?.rejectReason}
                      placeholder={`Reject reason for ${doc?.docName}`}
                      onChange={(e) => {
                        setDocsState((prevDocs) =>
                          prevDocs?.map((prevDoc) =>
                            prevDoc?.docName === doc?.docName
                              ? { ...prevDoc, rejectReason: e?.target?.value }
                              : prevDoc
                          )
                        );
                      }}
                    >
                      {getRejectReasonsBasedOnDoc(doc?.docName).map(
                        (rr, ind) => (
                          <option
                            key={`${doc?.docName}-rr-${ind + 1}`}
                            value={rr}
                          >
                            {rr}
                          </option>
                        )
                      )}
                    </Select>
                  </Box>
                ))}
              <FormLabel>Overall Reason</FormLabel>
              <Textarea
                required
                value={overallRejectReason}
                onChange={(e) => {
                  setOverallRejectReason(e?.target?.value);
                }}
              ></Textarea>
            </VStack>
          )}
        </ModalBody>
        <ModalFooter>
          <HStack gap={1} justifyContent={'end'}>
            {step === 0 && (
              <Button
                variant={'outline'}
                colorScheme="shadedGray"
                type="submit"
              >
                Next
              </Button>
            )}
            {step === 1 && (
              <>
                <Button
                  variant={'outline'}
                  colorScheme="shadedGray"
                  type="button"
                  onClick={() => setStep(0)}
                >
                  Prev
                </Button>
                <Button
                  isLoading={btnLoading}
                  variant={'outline'}
                  colorScheme="red"
                  type="submit"
                >
                  Reject
                </Button>
              </>
            )}
          </HStack>
        </ModalFooter>
      </form>
    </>
  );
};

export default NrobDocRejectModal;

import { toInvestmentOrderData, toInvestmentSipData } from '../../utils';
import { INVESTMENTS as types } from '../constants';

const initialState = {
  orders: {
    isLoading: false,
    isError: false,
    error: null,
    data: null,
    totalPages: 0
  },

  userOrders: {
    isLoading: false,
    isError: false,
    error: null,
    data: null,
    totalPages: 0
  },

  userSips: {
    isLoading: false,
    isError: false,
    error: null,
    data: null,
    totalPages: 0
  },

  orderDetails: {
    isLoading: false,
    isError: false,
    error: null,
    data: null
  },

  sipDetails: {
    isLoading: false,
    isError: false,
    error: null,
    data: null
  }
};

const investmentReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.GETTING_ORDERS:
      return {
        ...state,
        orders: {
          ...state.orders,
          isLoading: true,
          isError: false,
          error: null,
          data: null,
          totalPages: 0
        }
      };
    case types.GET_ORDERS_SUCCESS:
      return {
        ...state,
        orders: {
          ...state.orders,
          isLoading: false,
          isError: false,
          error: null,
          data: payload.data?.map((order) => toInvestmentOrderData(order)),
          totalPages: payload.totalPages
        }
      };

    case types.GET_ORDERS_FAILURE:
      return {
        ...state,
        orders: {
          ...state.orders,
          isLoading: false,
          isError: true,
          error: payload,
          data: null,
          totalPages: 0
        }
      };

    case types.GETTING_USER_ORDERS:
      return {
        ...state,
        userOrders: {
          ...state.userOrders,
          isLoading: true,
          isError: false,
          error: null,
          data: null,
          totalPages: 0
        }
      };
    case types.GET_USER_ORDERS_SUCCESS:
      return {
        ...state,
        userOrders: {
          ...state.userOrders,
          isLoading: false,
          isError: false,
          error: null,
          data: payload.data?.map((order) => toInvestmentOrderData(order)),
          totalPages: payload.totalPages
        }
      };

    case types.GET_USER_ORDERS_FAILURE:
      return {
        ...state,
        userOrders: {
          ...state.userOrders,
          isLoading: false,
          isError: true,
          error: payload,
          data: null,
          totalPages: 0
        }
      };

    case types.CLEAR_USER_ORDERS:
      return {
        ...state,
        userOrders: {
          ...state.userOrders,
          isLoading: false,
          isError: false,
          error: null,
          data: null,
          totalPages: 0
        }
      };

    case types.GETTING_ORDER_BY_ID:
      return {
        ...state,
        orderDetails: {
          ...state.orderDetails,
          isLoading: true,
          isError: false,
          error: null,
          data: null,
          totalPages: 0
        }
      };
    case types.GET_ORDER_BY_ID_SUCCESS:
      return {
        ...state,
        orderDetails: {
          ...state.orderDetails,
          isLoading: false,
          isError: false,
          error: null,
          data: payload
        }
      };

    case types.GET_ORDER_BY_ID_FAILURE:
      return {
        ...state,
        orderDetails: {
          ...state.orderDetails,
          isLoading: false,
          isError: true,
          error: payload,
          data: null
        }
      };

    case types.CLEAR_ORDER_BY_ID:
      return {
        ...state,
        orderDetails: {
          ...state.orderDetails,
          isLoading: false,
          isError: false,
          error: null,
          data: null,
          totalPages: 0
        }
      };

    case types.GETTING_USER_SIPS:
      return {
        ...state,
        userSips: {
          ...state.userSips,
          isLoading: true,
          isError: false,
          error: null,
          data: null,
          totalPages: 0
        }
      };
    case types.GET_USER_SIPS_SUCCESS:
      return {
        ...state,
        userSips: {
          ...state.userSips,
          isLoading: false,
          isError: false,
          error: null,
          data: payload.data?.map((order) => toInvestmentSipData(order)),
          totalPages: payload.totalPages
        }
      };

    case types.GET_USER_SIPS_FAILURE:
      return {
        ...state,
        userSips: {
          ...state.userSips,
          isLoading: false,
          isError: true,
          error: payload,
          data: null,
          totalPages: 0
        }
      };

    case types.CLEAR_USER_SIPS:
      return {
        ...state,
        userSips: {
          ...state.userSips,
          isLoading: false,
          isError: false,
          error: null,
          data: null,
          totalPages: 0
        }
      };

    case types.GETTING_SIP_BY_ID:
      return {
        ...state,
        sipDetails: {
          ...state.sipDetails,
          isLoading: true,
          isError: false,
          error: null,
          data: null,
          totalPages: 0
        }
      };
    case types.GET_SIP_BY_ID_SUCCESS:
      return {
        ...state,
        sipDetails: {
          ...state.sipDetails,
          isLoading: false,
          isError: false,
          error: null,
          data: payload
        }
      };

    case types.GET_SIP_BY_ID_FAILURE:
      return {
        ...state,
        sipDetails: {
          ...state.sipDetails,
          isLoading: false,
          isError: true,
          error: payload,
          data: null
        }
      };

    case types.CLEAR_SIP_BY_ID:
      return {
        ...state,
        sipDetails: {
          ...state.sipDetails,
          isLoading: false,
          isError: false,
          error: null,
          data: null,
          totalPages: 0
        }
      };

    default:
      return {
        ...state
      };
  }
};

export default investmentReducer;

import {
  Button,
  FormControl,
  FormLabel,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  Text,
  Textarea,
  VStack,
  Select
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  KYC_DOC_STATUS,
  KYC_PROVIDER,
  KYC_REJECT_REASONS_V2
} from '../../constants';
import {
  clearAllKycs,
  updateKycDocStatus
} from '../../store/actions/kycActions';
import { setAlert } from '../../store/actions/alertActions';

const KycUpdateModal = ({ statusUpdateData, onClose, onUpdateSuccess }) => {
  const {
    userId,
    status,
    kycDetailsId,
    kycType,
    subDocumentType,
    isReject = false,
    rejectionReason,
    provider
  } = statusUpdateData;

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    remarks: statusUpdateData?.remarks || '',
    rejectionReason,
    status,
    kycDetailsId
  });

  const { name: adminName } = useSelector((state) => state?.auth?.user);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    dispatch(
      updateKycDocStatus({
        userId,
        formData: {
          ...formData,
          remarks: formData?.remarks + ' - ' + adminName
        },
        onFinish: () => setIsLoading(false),
        onSuccess: () => {
          onClose();
          onUpdateSuccess({ status: formData.status });
        },
        onFailure: (errorMessage) => {
          dispatch(
            setAlert({
              title: errorMessage || 'Failed to updated Kyc Doc Status!',
              status: 'error'
            })
          );
        }
      })
    );
  };

  function getRejectReasons() {
    if (
      provider === KYC_PROVIDER.ON_RAMP_MONEY &&
      kycType === 'ON_RAMP_KYC_DOCS'
    ) {
      return {
        DATE_OUT_OF_RANGE: 'DATE_OUT_OF_RANGE',
        NOT_A_VALID_BANK_STATEMENT: 'NOT_A_VALID_BANK_STATEMENT'
      };
    }
    return KYC_REJECT_REASONS_V2;
  }

  const handleChange = (e) =>
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));

  return (
    <>
      <ModalHeader textAlign={'center'} py={1}>
        Update Kyc Doc Status
      </ModalHeader>
      <Text
        textAlign={'center'}
        fontSize={'0.75rem'}
        color={'shadedGray.400'}
        py={1}
      >
        {kycType}
      </Text>
      {subDocumentType && (
        <Text
          textAlign={'center'}
          fontSize={'0.75rem'}
          color={'shadedGray.400'}
        >
          ( {subDocumentType} )
        </Text>
      )}

      <ModalCloseButton />
      <ModalBody>
        <FormControl as={'form'} onSubmit={handleSubmit} px={2}>
          <VStack gap={2} w={'100%'} alignItems={'stretch'}>
            {isReject && (
              <VStack alignItems={'flex-start'}>
                <FormLabel
                  fontSize={'0.9rem'}
                  fontWeight={'500'}
                  mb={0}
                  lineHeight={'2rem'}
                  pr={2}
                >
                  Reject Reason
                </FormLabel>
                <Select
                  name="rejectionReason"
                  value={formData?.rejectionReason}
                  onChange={handleChange}
                  placeholder="Select reject reason"
                  required
                >
                  {Object.keys(getRejectReasons()).map((rejectionReason) => (
                    <option key={rejectionReason} value={rejectionReason}>
                      {rejectionReason}
                    </option>
                  ))}
                </Select>
              </VStack>
            )}
            <VStack alignItems={'flex-start'}>
              <FormLabel
                fontSize={'0.9rem'}
                fontWeight={'500'}
                mb={0}
                lineHeight={'2rem'}
                pr={2}
              >
                Remarks :
              </FormLabel>

              <Textarea
                my={2}
                className={'scroll-sm'}
                resize={'none'}
                size={'sm'}
                pl={3}
                fontSize={'0.75rem'}
                fontWeight={'600'}
                type="text"
                rounded="lg"
                bg={'shadedGray.400'}
                color={'shadedGray.800'}
                border={0}
                placeholder={`Enter remarks for ${
                  status === KYC_DOC_STATUS.REJECTED
                    ? 'rejection'
                    : 'verification'
                }`}
                _placeholder={{ opacity: 1, color: 'shadedGray.600' }}
                value={formData.remarks}
                onChange={handleChange}
                name={'remarks'}
                required
              />
            </VStack>
          </VStack>
          <ModalFooter justifyContent={'center'}>
            <Button
              colorScheme="brand"
              color="altGray.400"
              mr={3}
              size={'sm'}
              fontSize={'0.75rem'}
              type="submit"
              isLoading={isLoading}
            >
              Submit Now
            </Button>
          </ModalFooter>
        </FormControl>
      </ModalBody>
    </>
  );
};

export default KycUpdateModal;

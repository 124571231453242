const NRE_NRO = {
  GETTING_ONBOARDING_STATUS: 'GETTING_ONBOARDING_STATUS',
  GET_ONBOARDING_STATUS_SUCCESS: 'GET_ONBOARDING_STATUS_SUCCESS',
  GET_ONBOARDING_STATUS_FAILURE: 'GET_ONBOARDING_STATUS_FAILURE',

  UPDATING_ONBOARDING_STATUS: 'UPDATING_ONBOARDING_STATUS',
  UPDATE_ONBOARDING_STATUS_SUCCESS: 'UPDATE_ONBOARDING_STATUS_SUCCESS',
  UPDATE_ONBOARDING_STATUS_FAILURE: 'UPDATE_ONBOARDING_STATUS_FAILURE',

  CALLING_YBL_DEDUPE: 'CALLING_YBL_DEDUPE',
  YBL_DEDUPE_SUCCESS: 'YBL_DEDUPE_SUCCESS',
  YBL_DEDUPE_FAILURE: 'YBL_DEDUPE_FAILURE',

  CALLING_YBL_CUSTOMER_DETAILS: 'CALLING_YBL_CUSTOMER_DETAILS',
  YBL_CUSTOMER_DETAILS_SUCCESS: 'YBL_CUSTOMER_DETAILS_SUCCESS',
  YBL_CUSTOMER_DETAILS_FAILURE: 'YBL_CUSTOMER_DETAILS_FAILURE',

  CALLING_YBL_SUBMIT_JOURNEY: 'CALLING_YBL_SUBMIT_JOURNEY',
  YBL_SUBMIT_JOURNEY_SUCCESS: 'YBL_SUBMIT_JOURNEY_SUCCESS',
  YBL_SUBMIT_JOURNEY_FAILURE: 'YBL_SUBMIT_JOURNEY_FAILURE',

  CALLING_YBL_UPLOAD_DOC: 'CALLING_YBL_UPLOAD_DOC',
  YBL_UPLOAD_DOC_SUCCESS: 'YBL_UPLOAD_DOC_SUCCESS',
  YBL_UPLOAD_DOC_FAILURE: 'YBL_UPLOAD_DOC_FAILURE'
};

export default NRE_NRO;

const INVESTMENTS = {
  GETTING_ORDERS: 'GETTING_INVESTMENT_ORDERS',
  GET_ORDERS_SUCCESS: 'GET_INVESTMENT_ORDERS_SUCCESS',
  GET_ORDERS_FAILURE: 'GET_INVESTMENT_ORDERS_FAILURE',

  GETTING_USER_ORDERS: 'GETTING_INVESTMENT_USER_ORDERS',
  GET_USER_ORDERS_SUCCESS: 'GET_INVESTMENT_USER_ORDERS_SUCCESS',
  GET_USER_ORDERS_FAILURE: 'GET_INVESTMENT_USER_ORDERS_FAILURE',
  CLEAR_USER_ORDERS: 'CLEAR_INVESTMENT_USER_ORDERS',

  GETTING_ORDER_BY_ID: 'GETTING_ORDER_BY_ID',
  GET_ORDER_BY_ID_SUCCESS: 'GET_INVESTMENT_ORDER_BY_ID_SUCCESS',
  GET_ORDER_BY_ID_FAILURE: 'GET_INVESTMENT_ORDER_BY_ID_FAILURE',
  CLEAR_ORDER_BY_ID: 'CLEAR_INVESTMENT_ORDER_BY_ID',

  GETTING_USER_SIPS: 'GETTING_INVESTMENT_USER_SIPS',
  GET_USER_SIPS_SUCCESS: 'GET_INVESTMENT_USER_SIPS_SUCCESS',
  GET_USER_SIPS_FAILURE: 'GET_INVESTMENT_USER_SIPS_FAILURE',
  CLEAR_USER_SIPS: 'CLEAR_INVESTMENT_USER_SIPS',

  GETTING_SIP_BY_ID: 'GETTING_SIP_BY_ID',
  GET_SIP_BY_ID_SUCCESS: 'GET_INVESTMENT_SIP_BY_ID_SUCCESS',
  GET_SIP_BY_ID_FAILURE: 'GET_INVESTMENT_SIP_BY_ID_FAILURE',
  CLEAR_SIP_BY_ID: 'CLEAR_INVESTMENT_SIP_BY_ID'
};

export default INVESTMENTS;

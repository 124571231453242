import {
  Stack,
  Text,
  VStack,
  Grid,
  HStack,
  StackDivider,
  Icon,
  Spinner,
  Box,
  IconButton
} from '@chakra-ui/react';
import React, { useState } from 'react';
import {
  formatDate,
  getCheckOutcomeColor,
  getCheckStatusColor,
  getErrorMessage,
  kebabCaseToSpaceSeperate
} from '../../utils';
import { MdOutlineOpenInNew } from 'react-icons/md';
import api from '../../api';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../store/actions/alertActions';
import { KYC_CHECK_STATUS } from '../../constants';
import { AiOutlineSync } from 'react-icons/ai';
import { updateCheckStatus } from '../../store/actions/kycActions';

const KycCheckCard = ({ check, userId, onCheckUpdateSuccess }) => {
  const {
    id,
    status,
    checkOutcome,
    checkType,
    checkResultBreakdown,
    createDate,
    checkId
  } = check;

  const [isLoading, setIsLoading] = useState(false);
  const [isStatusUpdateLoading, setIsStatusUpdateLoading] = useState(false);
  const dispatch = useDispatch();

  const handleCheckUpdateClick = () => {
    setIsStatusUpdateLoading(true);
    dispatch(
      updateCheckStatus({
        checkId,
        onSuccess: onCheckUpdateSuccess,
        onFinish: () => setIsStatusUpdateLoading(false)
      })
    );
  };

  const handleCheckBreakdownClick = () => {
    setIsLoading(true);
    api.kyc
      .getKycFile(checkResultBreakdown, userId)
      .then((res) => res.data)
      .then((data) => window.open(data.url))
      .catch((error) => {
        dispatch(
          setAlert({
            title: getErrorMessage({
              error,
              genericMessage:
                'Something went wrong while fetching check result breakdown!'
            }),
            status: 'error'
          })
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Box position={'relative'} maxW={'100%'}>
      {isStatusUpdateLoading ? (
        <Spinner
          color={'brand.200'}
          size={'sm'}
          position={'absolute'}
          top={-2}
          right={-2}
        />
      ) : (
        status !== KYC_CHECK_STATUS.COMPLETE &&
        status !== KYC_CHECK_STATUS.FAILED && (
          <IconButton
            position={'absolute'}
            rounded={'full'}
            top={-2}
            right={-2}
            bg={'altGray.500'}
            color={'brand.200'}
            _hover={{
              background: 'brand.200',
              color: 'altGray.500'
            }}
            size={'sm'}
            fontSize={'0.9rem'}
            fontWeight={400}
            icon={<AiOutlineSync />}
            onClick={handleCheckUpdateClick}
          />
        )
      )}
      <VStack
        bg="backGround"
        borderRadius="12px"
        w={'52rem'}
        maxW={'100%'}
        pb={'1rem'}
        divider={
          <StackDivider style={{ margin: '0' }} borderColor="whiteAlpha.300" />
        }
      >
        <Stack
          direction={{ base: 'column', sm: 'row' }}
          w={'90%'}
          justifyContent="space-between"
          mt={'1rem'}
        >
          <HStack>
            <Text fontSize={{ base: 'sm', sm: 'md' }} color="white">
              {kebabCaseToSpaceSeperate(checkType)?.toUpperCase()}
            </Text>
          </HStack>
          <HStack>
            <Text fontSize={{ base: 'sm' }} color="white">
              Created on: {formatDate(createDate)}
            </Text>
          </HStack>
        </Stack>
        <Grid
          gridTemplateColumns={{
            base: '1fr',
            md: `repeat(4, 1fr)`
          }}
          gap={2}
          alignItems={'flex-start'}
          w={'90%'}
          m={'1rem'}
          textAlign={{ base: 'end', md: 'start' }}
        >
          <Stack
            direction={{ base: 'row', md: 'column' }}
            justifyContent={'space-between'}
          >
            <Text fontSize={'sm'} color={'gray'}>
              Breakdown
            </Text>
            {isLoading ? (
              <Spinner size={'sm'} color={'gray'} />
            ) : checkResultBreakdown?.length ? (
              <MdOutlineOpenInNew
                color={'gray'}
                cursor={'pointer'}
                onClick={handleCheckBreakdownClick}
              />
            ) : (
              <Text fontSize={'sm'} color={'white'}>
                -
              </Text>
            )}
          </Stack>
          <Stack
            direction={{ base: 'row', md: 'column' }}
            justifyContent={'space-between'}
          >
            <Text fontSize={'sm'} color={'gray'}>
              Document Type
            </Text>
            <Text fontSize={{ base: 'sm', sm: 'md' }} color="white">
              {check.documentType ? check.documentType : '-'}
            </Text>
          </Stack>
          <Stack
            direction={{ base: 'row', md: 'column' }}
            justifyContent={'space-between'}
          >
            <Text fontSize={'sm'} color={'gray'}>
              Status
            </Text>
            <HStack>
              <Icon
                viewBox="0 0 200 200"
                color={getCheckStatusColor(status)}
                w="8px"
              >
                <path
                  fill="currentColor"
                  d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
                />
              </Icon>
              <Text fontSize={{ base: 'sm', sm: 'md' }} color="white">
                {status}
              </Text>
            </HStack>
          </Stack>
          <Stack
            direction={{ base: 'row', md: 'column' }}
            justifyContent={'space-between'}
          >
            <Text fontSize={'sm'} color={'gray'}>
              Outcome
            </Text>
            <Text fontSize={'sm'} color={getCheckOutcomeColor(checkOutcome)}>
              {checkOutcome ? checkOutcome : '-'}
            </Text>
          </Stack>
        </Grid>
      </VStack>
    </Box>
  );
};

export default KycCheckCard;

export const COUNTRIES = {
  /* 
      US("US", "USA", "+1", "USD.svg", "USD.png", false, true, ZoneId.of("America/Los_Angeles"),
      RegexConstants.US_PHONE_REGEX, RegexConstants.US_INPUT_PHONE_REGEX),
  AE("AE", "UAE", "+971", "AED.svg", "AED.png", false, true, ZoneId.of("Asia/Dubai"),
      RegexConstants.AE_PHONE_REGEX, RegexConstants.AE_INPUT_PHONE_REGEX),
  GB("GB", "United Kingdom", "+44", "GBP.svg", "GBP.png", false, true, ZoneId.of("Europe/London"),
      RegexConstants.UK_PHONE_REGEX, RegexConstants.UK_INPUT_PHONE_REGEX),
  SG("SG", "Singapore", "+65", "SGD.svg", "SGD.png", false, false, ZoneId.of("Asia/Singapore"),
      RegexConstants.SG_PHONE_REGEX, RegexConstants.SG_INPUT_PHONE_REGEX),
  CA("CA", "Canada", "+1", "CAD.svg", "CAD.png", false, true, ZoneId.of("America/Toronto"),
      RegexConstants.CA_PHONE_REGEX, RegexConstants.CA_INPUT_PHONE_REGEX),
  DE("DE", "Germany", "+49", "EUR.svg", "EUR.png", false, true, ZoneId.of("Europe/Berlin"),
      RegexConstants.DE_PHONE_REGEX, RegexConstants.DE_INPUT_PHONE_REGEX),
  IE("IE", "Ireland", "+353", "Ireland.svg", "Ireland.png", false, true, ZoneId.of("Europe/Dublin"),
      RegexConstants.IE_PHONE_REGEX, RegexConstants.IE_INPUT_PHONE_REGEX),
  NL("NL", "Netherlands", "+31", "Netherlands.svg", "Netherlands.png", false, true,
      ZoneId.of("Europe/Amsterdam"), RegexConstants.NL_PHONE_REGEX,
      RegexConstants.NL_INPUT_PHONE_REGEX),
  SE("SE", "Sweden", "+46", "SEK.svg", "SEK.png", false, true, ZoneId.of("Europe/Stockholm"),
      RegexConstants.SE_PHONE_REGEX, RegexConstants.SE_INPUT_PHONE_REGEX),
  IT("IT", "Italy", "+39", "Italy.svg", "Italy.png", false, true, ZoneId.of("Europe/Rome"),
      RegexConstants.IT_PHONE_REGEX, RegexConstants.IT_INPUT_PHONE_REGEX),
    */
  US: 'US',
  AE: 'AE',
  GB: 'GB',
  DE: 'DE',
  NL: 'NL',
  FR: 'FR',
  PT: 'PT',
  ES: 'ES',
  IE: 'IE',
  IT: 'IT'
};

/* list of all available currencies */
export const CURRENCIES = {
  GBP: 'GBP',
  AED: 'AED',
  //   USD: 'USD',
  //   CAD: 'CAD',
  EUR: 'EUR'
  //   AUD: 'AUD',
  //   SGD: 'SGD',
  //   INR: 'INR'
};

export const FEATURE_FLAGS = {
  firebase_phone_auth_default: 'firebase_phone_auth_default',
  beneficiary_first_as_default: 'beneficiary_first_as_default',
  kyc_first_as_default: 'kyc_first_as_default',
  otp_on_init_enabled: 'otp_on_init_enabled',
  nr_onboarding_service_enabled: 'nr_onboarding_service_enabled',
  uk_auto_off_ramp_enabled: 'uk_auto_off_ramp_enabled',
  saber_vda_config: 'saber_vda_config',
  verification_service_enabled: 'verification_service_enabled',
  onramp_money_kyc_enabled_after_date: 'onramp_money_kyc_enabled_after_date',
  onramp_money_bank_statement_generation_enabled:
    'onramp_money_bank_statement_generation_enabled',
  onramp_money_cc_poa_check_enabled: 'onramp_money_cc_poa_check_enabled',
  internal_phone_numbers: 'internal_phone_numbers',
  kyc_incentivisation_reward_enabled: 'kyc_incentivisation_reward_enabled',
  raffle_reward_enabled: 'raffle_reward_enabled',
  saber_gbp_to_usdt_conversion_rate: 'saber_gbp_to_usdt_conversion_rate',
  encryptus_kyc_enabled: 'encryptus_kyc_enabled',
  whitelist_config: 'whitelist_config',
  onramp_money_uae_user_whitelist: 'onramp_money_uae_user_whitelist',
  onramp_money_uk_kyc_user_whitelist: 'onramp_money_uk_kyc_user_whitelist',
  onramp_money_payout_mid_2_enabled: 'onramp_money_payout_mid_2_enabled',
  onramp_money_uk_payout_user_whitelist:
    'onramp_money_uk_payout_user_whitelist',
  striga_multi_level_kyc_whitelist: 'striga_multi_level_kyc_whitelist',
  auth_otp_providers: 'auth_otp_providers'
};

export const VDA_ELIGIBILITY_STATUS = {
  /* 
  NEW,
  ELIGIBLE,
  BLOCKED
  */
  NEW: 'NEW',
  ELIGIBLE: 'ELIGIBLE',
  BLOCKED: 'BLOCKED'
};

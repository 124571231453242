import React, { useEffect, useRef } from 'react';
import { Flex, Text } from '@chakra-ui/react';

const Messages = ({ messages }) => {
  const AlwaysScrollToBottom = () => {
    const elementRef = useRef();
    useEffect(() => elementRef.current.scrollIntoView());
    return <div ref={elementRef} />;
  };

  return (
    <Flex
      overflowY="scroll"
      flexDirection="column"
      p="3"
      className="scroll-dark"
    >
      {messages.map((item, index) => {
        if (item.from === 'me') {
          return (
            <Flex key={index} mr={1} justify="flex-end" maxWidth={'100%'}>
              <Flex
                bg="brand.600"
                color="white"
                my="1"
                p="2"
                rounded="lg"
                maxWidth={'80%'}
              >
                <Text fontSize={'sm'} maxWidth={'100%'}>
                  {item.text}
                </Text>
              </Flex>
            </Flex>
          );
        } else {
          return (
            <Flex key={index} maxWidth={'100%'}>
              <Flex
                bg="altGray.400"
                color="white"
                my="1"
                ml={1}
                p="2"
                rounded="lg"
                maxWidth={'80%'}
              >
                <Text fontSize={'sm'} maxWidth={'100%'}>
                  {item.text}
                </Text>
              </Flex>
            </Flex>
          );
        }
      })}
      <AlwaysScrollToBottom />
    </Flex>
  );
};

export default Messages;

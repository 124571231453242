import { Box, FormLabel, HStack, Input, Select } from '@chakra-ui/react';
import { camelCaseToSpaceSeparated } from '../../../utils';

/* Can be customized further intended to wrap as a filter template */

const GenericFiltersComponent = ({
  features = {},
  featuresState = {},
  onChange = (e) => {}
}) => {
  return (
    <>
      <HStack width={'full'} justifyContent={'end'} gap={3}>
        {Object.keys(features)?.map((key) => {
          const feature = features[key];
          return (
            <Box>
              <FormLabel>{camelCaseToSpaceSeparated(key)}</FormLabel>
              {feature?.filterType === 'DATE' && (
                <>
                  <Input
                    type="date"
                    value={featuresState[feature?.name]}
                    onChange={onChange}
                    name={
                      !feature?.prefix
                        ? feature?.name
                        : `${feature.prefix}.${feature.name}`
                    }
                  />
                </>
              )}
              {feature?.filterType === 'SELECT' && (
                <>
                  <Select
                    value={featuresState[feature?.name]}
                    onChange={onChange}
                    name={
                      !feature?.prefix
                        ? feature?.name
                        : `${feature.prefix}.${feature.name}`
                    }
                  >
                    {feature?.options?.map((opt) => (
                      <option key={opt} value={opt}>
                        {opt}
                      </option>
                    ))}
                  </Select>
                </>
              )}
              {feature?.filterType === 'SEARCH' && (
                <>
                  <Input
                    type="text"
                    value={featuresState[feature?.name]}
                    onChange={onChange}
                    name={
                      !feature?.prefix
                        ? feature?.name
                        : `${feature.prefix}.${feature.name}`
                    }
                  />
                </>
              )}
            </Box>
          );
        })}
      </HStack>
    </>
  );
};

export default GenericFiltersComponent;

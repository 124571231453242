import { VStack, Heading, Grid } from '@chakra-ui/react';
import DataElement from './DataElement';
import dayjs from 'dayjs';

const OnRampData = ({ onRampData }) => {
  return (
    // <VStack alignItems={'stretch'} width={'full'} gap={2}>
    //   <Heading color={'white'} size={'sm'}>
    //     On Ramp Data
    //   </Heading>
    <Grid
      gridTemplateColumns={{
        sm: '1fr',
        md: '1fr 1fr 1fr 1fr'
      }}
      gap={4}
    >
      {/* transaction data  */}
      <DataElement title={'Txn ID'} value={onRampData?.txnId} />
      <DataElement title={'Txn Hash'} value={onRampData?.txnHash} />
      <DataElement title={'Txn Status'} value={onRampData?.status} />
      <DataElement
        title={'Txn Created On'}
        value={dayjs(onRampData?.createdAt).format('DD MMM YYYY')}
      />
      <DataElement title={'Currency From'} value={onRampData?.currencyFrom} />
      <DataElement title={'Send Amount'} value={onRampData?.sentAmount} />
      <DataElement title={'Exchange Rate'} value={onRampData?.exchangeRate} />
      <DataElement title={'USDT Amount'} value={onRampData?.usdtAmount} />
      {/* payment status  */}
      <DataElement
        title={'Payment Acquirer'}
        value={onRampData?.paymentAcquirer}
      />
      <DataElement title={'Payment ID'} value={onRampData?.paymentId} />
      <DataElement title={'Payment Status'} value={onRampData?.paymentStatus} />
      <DataElement
        title={'Updated At'}
        value={dayjs(onRampData?.updatedAt).format('DD MMM YYYY')}
      />
    </Grid>
    // </VStack>
  );
};

export default OnRampData;

import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Link,
  Spinner,
  StackDivider,
  Text,
  VStack
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import DetailsTable from '../OrderDetailsCard/DetailsTable';
import { Link as ReactLink } from 'react-router-dom';
import {
  BsArrowRight,
  BsArrowsCollapse,
  BsChevronExpand
} from 'react-icons/bs';
import {
  clearInvestmentOrderDetails,
  getInvestmentOrderDetails
} from '../../store/actions/investmentActions';
import { getInvestmentOrderDetailsTable } from './utils';
import { ORDER_PRODUCTS } from '../../constants';

const InvestmentOrderDetailsCard = ({
  orderId,
  isSidebar = false,
  showNavLink = false
}) => {
  const dispatch = useDispatch();
  const [orderDetailsTable, setOrderDetailsTable] = useState([]);
  const [showAllPayments, setShowAllPayments] = useState(false);
  const [showAllFundOrders, setShowAllFundOrders] = useState(false);

  const { data: orderDetails, isLoading } = useSelector(
    (state) => state.investments.orderDetails
  );

  useEffect(() => {
    dispatch(getInvestmentOrderDetails(orderId));
  }, [orderId, dispatch]);

  useEffect(() => {
    setOrderDetailsTable(
      orderDetails ? getInvestmentOrderDetailsTable(orderDetails) : null
    );
  }, [orderDetails]);

  useEffect(() => {
    return () => dispatch(clearInvestmentOrderDetails());
  }, [dispatch]);

  const toggleAllPayments = () => setShowAllPayments((prev) => !prev);
  const toggleAllFundOrders = () => setShowAllFundOrders((prev) => !prev);

  if (isLoading)
    return (
      <Flex m={'1rem'} justifyContent={'center'} alignItems={'center'}>
        <Spinner color="brand.600" />
      </Flex>
    );

  return (
    <Box
      m={'1rem'}
      mb={0}
      mr={0}
      pr={'1rem'}
      maxH={isSidebar ? '86vh' : 'none'}
      className="scroll-sm"
      overflow={isSidebar ? 'auto' : 'inherit'}
    >
      <VStack
        alignItems={'stretch'}
        divider={
          <StackDivider style={{ margin: '0' }} borderColor="whiteAlpha.300" />
        }
      >
        {orderDetailsTable?.length &&
          orderDetailsTable.map(
            (item, i) =>
              item.data?.length &&
              (!item.defaultHidden ||
                (item.detailType === 'payments' && showAllPayments) ||
                (item.detailType === 'fundOrders' && showAllFundOrders)) && (
                <Box key={item.title || i} pb={3}>
                  {i === 0 ? (
                    <Flex
                      justifyContent={'space-between'}
                      alignItems={'baseline'}
                      pr={isSidebar ? 2 : 6}
                    >
                      <Heading
                        fontSize={'2xl'}
                        color={'brand.500'}
                        mb={'0.5rem'}
                      >
                        {item.title}
                      </Heading>
                      {showNavLink && orderDetails.userId && (
                        <Link
                          as={ReactLink}
                          to={`/user/${orderDetails.userId}?orderId=${orderId}&product=${ORDER_PRODUCTS.INVEST_MF}`}
                          color="brand.500"
                          fontSize={'sm'}
                        >
                          <HStack>
                            <Text>User Details</Text>
                            <BsArrowRight display={'inline'} />
                          </HStack>
                        </Link>
                      )}
                    </Flex>
                  ) : (
                    <Heading
                      fontSize={'lg'}
                      color={'white'}
                      mb={'0.5rem'}
                      mt={4}
                    >
                      {item.title}
                    </Heading>
                  )}
                  {item.data && (
                    <DetailsTable isSidebar={isSidebar} details={item.data} />
                  )}
                  {item.title === 'Investment Order:' &&
                  orderDetails.sipId?.length ? (
                    <Link
                      as={ReactLink}
                      to={`/investments/sips/${orderDetails.sipId}`}
                      color="white"
                      fontSize={'sm'}
                    >
                      <HStack pt={2}>
                        <Text>Sip Details</Text>
                        <BsArrowRight display={'inline'} />
                      </HStack>
                    </Link>
                  ) : null}
                  {item.title === 'Order Payments' &&
                  orderDetails.payments?.length > 1 ? (
                    <Button
                      variant={'link'}
                      color={'white'}
                      size={'sm'}
                      rightIcon={
                        showAllPayments ? (
                          <BsArrowsCollapse />
                        ) : (
                          <BsChevronExpand />
                        )
                      }
                      onClick={toggleAllPayments}
                      py={2}
                    >
                      All Payments
                    </Button>
                  ) : null}
                  {item.title === 'Fund Orders' &&
                  orderDetails.fundOrders?.length > 1 ? (
                    <Button
                      variant={'link'}
                      color={'white'}
                      size={'sm'}
                      rightIcon={
                        showAllFundOrders ? (
                          <BsArrowsCollapse />
                        ) : (
                          <BsChevronExpand />
                        )
                      }
                      onClick={toggleAllFundOrders}
                      py={2}
                    >
                      All Fund Orders
                    </Button>
                  ) : null}
                </Box>
              )
          )}
        {/* <Notes
          note={orderDetails?.metadata?.details || null}
          complianceFlagged={orderDetails?.metadata?.complianceFlagged}
          updatedByEmail={orderDetails?.metadata?.updatedByEmail}
          updatedAt={orderDetails?.metadata?.updatedAt}
          orderId={orderId}
        /> */}
      </VStack>
    </Box>
  );
};

export default InvestmentOrderDetailsCard;

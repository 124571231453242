import React, { useCallback, useEffect, useState } from 'react';
import ContentLayout from '../../layouts/ContentLayout';
import { useDispatch, useSelector } from 'react-redux';
import { updateRedeemedCoinsReqData } from '../../store/actions/reqDataActions';
import { CARD_TYPE, CASH_REWARD_STATUS, ROLES } from '../../constants';

import useDidMountEffect from '../../hooks/useDidMount';
import {
  Box,
  Divider,
  Flex,
  Grid,
  Heading,
  Spinner,
  VStack,
  Button,
  useDisclosure,
  Select
} from '@chakra-ui/react';
import Pagination from '../../components/Pagination';
import GenericCard from '../../components/GenericCard';
import {
  getRedeemedCoins,
  redeemCoinsCompleted
} from '../../store/actions/referralActions';
import ModalLayout from '../../components/Modal/ModalLayout';
import ConfirmWithRemarksModal from '../../components/Modal/ConfirmWithRemarksModal';
import useAuthRole from '../../hooks/useAuthRole';
import { useNavigate } from 'react-router-dom';

const RedeemedCoins = () => {
  const navigate = useNavigate();
  const { hasAnyAuthority } = useAuthRole();
  const {
    data: redeemedCoins,
    isLoading,
    totalPages
  } = useSelector((state) => state.referral.v2.redeemedCoins);
  const dispatch = useDispatch();
  const searchReqData = useSelector(
    (state) => state.reqData.referral.v2.redeemedCoins
  );
  const [activeRedeem, setActiveRedeem] = useState(null);
  const {
    isOpen: isConfirmModalOpen,
    onOpen: onConfirmModalOpen,
    onClose: onConfirmModalClose
  } = useDisclosure();
  const [isRedeemCompletedLoading, setIsRedeemCompletedLoading] =
    useState(false);

  useEffect(() => {
    if (redeemedCoins?.length) return;

    triggerGetRedeemedCoins(searchReqData);
  }, []);

  useDidMountEffect(() => {
    triggerGetRedeemedCoins(searchReqData);
  }, [searchReqData]);

  const triggerGetRedeemedCoins = useCallback(
    (searchReqData) => {
      dispatch(getRedeemedCoins(searchReqData));
    },
    [dispatch]
  );

  const handlePageChange = useCallback(
    (pageNo) => {
      pageNo - 1 !== searchReqData.pageNo &&
        dispatch(updateRedeemedCoinsReqData({ pageNo: pageNo - 1 }));
    },
    [searchReqData.pageNo, dispatch]
  );

  const handleStatusChange = (status) =>
    searchReqData.status !== status &&
    dispatch(updateRedeemedCoinsReqData({ status, pageNo: 0 }));

  const handleConfirmModalClose = () => {
    setActiveRedeem(null);
    onConfirmModalClose();
  };

  const handleConfirmModalSubmit = ({ remarks }) => {
    setIsRedeemCompletedLoading(true);
    dispatch(
      redeemCoinsCompleted({
        redeemId: activeRedeem,
        metadata: remarks,
        onSuccess: () => {
          const newSearchReqData = { ...searchReqData, pageNo: 0 };
          updateRedeemedCoinsReqData(searchReqData);
          triggerGetRedeemedCoins(newSearchReqData);
          handleConfirmModalClose();
        },
        onFinish: setIsRedeemCompletedLoading(false)
      })
    );
  };

  useEffect(() => {
    if (activeRedeem) onConfirmModalOpen();
  }, [activeRedeem]);

  const handleMarkCompletedButtonClick = (redeemId) =>
    setActiveRedeem(redeemId);

  const handleUserDetailsClick = (userId) => navigate('/user/' + userId);

  return (
    <Box position="relative">
      {activeRedeem && (
        <ModalLayout
          isOpen={isConfirmModalOpen}
          onClose={handleConfirmModalClose}
          size={'md'}
        >
          <ConfirmWithRemarksModal
            title={'Mark reward as completed?'}
            handleSubmit={handleConfirmModalSubmit}
            isLoading={isRedeemCompletedLoading}
          />
        </ModalLayout>
      )}
      <ContentLayout>
        <Grid gridTemplateColumns={{ base: '1fr', lg: '7fr 0fr 2fr' }}>
          <VStack
            p={10}
            gap={4}
            overflowY={{ base: 'initial', lg: 'scroll' }}
            overflowX={'hidden'}
            h={{ lg: '90vh' }}
            px={{ base: 4, sm: 10 }}
            alignItems={'stretch'}
            className="scroll"
          >
            <Flex
              w={'100%'}
              alignItems={'baseline'}
              justifyContent={'space-between'}
              pb={2}
            >
              <Heading color={'white'}>Redeemed Coins</Heading>
            </Flex>

            <Box maxWidth="15rem">
              <Select
                placeholder={`Filter Reward Status`}
                onChange={(e) => handleStatusChange(e.target.value)}
                name={'orderType'}
                value={searchReqData.orderType}
                bg="black"
                color="gray"
                size={'sm'}
                rounded={'lg'}
                _focus={{
                  border: '1px solid #81EBAB',
                  boxShadow: 'none'
                }}
              >
                {Object.keys(CASH_REWARD_STATUS).map((status) => (
                  <option value={status} key={status}>
                    {status}
                  </option>
                ))}
              </Select>
            </Box>

            {totalPages > 0 && (
              <Box alignSelf={'start'}>
                <Pagination
                  totalPages={totalPages}
                  currentPage={searchReqData.pageNo + 1}
                  onPageChange={handlePageChange}
                />
              </Box>
            )}
            {isLoading && <Spinner size={'lg'} color={'brand.200'} />}
            <VStack
              alignSelf={'flex-start'}
              w={'100%'}
              alignItems={'flex-start'}
              gap={1}
            >
              {redeemedCoins?.length > 0 &&
                redeemedCoins.map((item) => (
                  <GenericCard
                    key={item.id}
                    cardType={CARD_TYPE.REDEEMED_COINS_CARD}
                    cardData={item}
                    ActionButtons={[
                      <Button
                        key={'user-details-button'}
                        mt={{ base: 2, md: 0 }}
                        ml={{ base: 0, md: 1 }}
                        type="submit"
                        rounded="full"
                        color={'black'}
                        colorScheme="brand"
                        alignSelf={{ base: 'center', md: 'center' }}
                        px={5}
                        size={'sm'}
                        onClick={() => {
                          handleUserDetailsClick(item.userId);
                        }}
                      >
                        User Details
                      </Button>,
                      hasAnyAuthority(ROLES.ADMIN_ROLE) &&
                      item.status === 'PENDING' ? (
                        <Button
                          key={'update-satus-button'}
                          mt={{ base: 2, md: 0 }}
                          ml={{ base: 0, md: 1 }}
                          type="submit"
                          rounded="full"
                          color={'black'}
                          colorScheme="brand"
                          alignSelf={{ base: 'center', md: 'center' }}
                          px={5}
                          size={'sm'}
                          onClick={() => {
                            handleMarkCompletedButtonClick(item.id);
                          }}
                        >
                          Mark Completed
                        </Button>
                      ) : null
                    ].filter((actionButton) => actionButton !== null)}
                  />
                ))}
            </VStack>
          </VStack>
          <Divider
            style={{ margin: '0' }}
            borderColor="whiteAlpha.300"
            orientation="vertical"
          ></Divider>
        </Grid>
      </ContentLayout>
    </Box>
  );
};

export default RedeemedCoins;

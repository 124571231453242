import React from 'react';
import { useParams } from 'react-router-dom';
import ContentLayout from '../../layouts/ContentLayout';
import { Box, Grid } from '@chakra-ui/react';
import SipDetailCard from '../../components/Sip/SipDetailCard';

const SipOrder = () => {
  const { sipId } = useParams();

  return (
    <ContentLayout>
      <Box w={'100%'} h={'86vh'}>
        <SipDetailCard sipId={sipId} showNavLink={true} />
      </Box>
    </ContentLayout>
  );
};

export default SipOrder;

import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import {
  Grid,
  Tabs,
  TabList,
  Tab,
  VStack,
  Box,
  Text,
  HStack,
  Divider
} from '@chakra-ui/react';
import ContentLayout from '../../layouts/ContentLayout';
import React, { useEffect, useState } from 'react';
import {
  NR_OB_COUNTRY,
  NR_OB_UAE_STAGES,
  NR_OB_UK_STAGES,
  TICKET_TYPES
} from '../../constants';
import { camelCaseString, kebabCaseToSpaceSeperate } from '../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { getNrOnboardDetails } from '../../store/actions/nronboardActions';
import { NrOnboardDetailsWrapper } from '../../components/NrOnboard';
import FullPageSpinner from './../../components/FullPageSpinner';
import { AiOutlineUser } from 'react-icons/ai';
import useIsMobile from '../../hooks/useIsMobile';
import TicketWidget from '../../components/Tickets/TicketWidget';

const NrOnboardingPage = () => {
  const { userId } = useParams();
  const { data: nronboardState, isLoading } = useSelector(
    (state) => state?.nronboard?.details
  );
  const dispatch = useDispatch();

  const { activeStep, country } = nronboardState;

  const [selectedStage, setSelectedStage] = useState(
    NR_OB_UK_STAGES.APPLICATION
  );

  const TAB_STATES =
    country === NR_OB_COUNTRY.AE ? NR_OB_UAE_STAGES : NR_OB_UK_STAGES;

  const STATE_KEY_MAPPER = Object.keys(TAB_STATES).reduce((prev, curr) => {
    return {
      ...prev,
      [curr]: camelCaseString(curr)
    };
  }, {});

  const [ticket, setTicket] = useState(null);
  const [ticketLoading, setTicketLoading] = useState(false);

  useEffect(() => {
    if (!userId) return;
    dispatch(getNrOnboardDetails(userId));
    setTicketLoading(true);
  }, [userId, dispatch]);

  const isMobile = useIsMobile();

  if (isLoading) return <FullPageSpinner />;

  return (
    <>
      <ContentLayout>
        <Grid gridTemplateColumns={{ base: '1fr', lg: '7fr 0fr 2fr' }}>
          <VStack
            gap={'30px'}
            overflowY={{ base: 'initial', lg: 'scroll' }}
            overflowX={'hidden'}
            h={{ lg: '90vh' }}
            py={10}
            px={{ base: 4, sm: 10 }}
            alignItems={'stretch'}
            className="scroll"
          >
            <Text color={'white'} fontSize={'2xl'}>
              NR Onboarding
            </Text>
            <HStack justifyContent={'end'}>
              <Link color="brand.500" w={'100%'} to={`/user/${userId}`}>
                <HStack>
                  <AiOutlineUser color="green" />
                  <Text color="brand.500">User Details</Text>
                </HStack>
              </Link>
            </HStack>
            <Grid gridTemplateColumns={'0.8fr 1fr'} gap={2}>
              <>
                <Text fontSize={'md'} color={'gray.200'}>
                  Current Step:
                </Text>
                <Text fontSize={'md'} color={'white'}>
                  {activeStep}
                </Text>
              </>
              {nronboardState?.applicationRejectionReason && (
                <>
                  <Text fontSize={'md'} color={'white'}>
                    Application Rejected due to:
                  </Text>
                  <Text as={'u'} fontSize={'md'} color={'red'}>
                    {nronboardState?.applicationRejectionReason}
                  </Text>
                </>
              )}
              {nronboardState?.remarks && (
                <>
                  <Text fontSize={'md'} color={'gray.400'}>
                    Remarks:
                  </Text>
                  <Text fontSize={'md'} color={'white'}>
                    {nronboardState?.remarks}
                  </Text>
                </>
              )}
            </Grid>
            {/* <HStack>
              <Button onClick={openTicketModal}>Create Ticket</Button>
            </HStack> */}
            <Tabs
              variant={'enclosed'}
              size={'md'}
              onChange={(e) => {
                setSelectedStage(Object.keys(TAB_STATES)[e]);
              }}
              defaultIndex={Object.keys(TAB_STATES).indexOf(selectedStage)}
            >
              <TabList color={'white'}>
                {Object.keys(TAB_STATES).map((onBoardState, ind) => (
                  <Tab
                    key={ind}
                    _selected={{
                      color: 'brand.500',
                      border: '1px solid white',
                      borderBottom: '1px solid black'
                    }}
                  >
                    {kebabCaseToSpaceSeperate(onBoardState)}
                  </Tab>
                ))}
              </TabList>
            </Tabs>
            <Box>
              <NrOnboardDetailsWrapper
                selectedStage={selectedStage}
                data={nronboardState[STATE_KEY_MAPPER[selectedStage]] || {}}
                userId={userId}
              />
            </Box>
          </VStack>
          <Divider
            style={{ margin: '0' }}
            borderColor="whiteAlpha.300"
            orientation="vertical"
          />
          {!isMobile && (
            <Box m={0} alignSelf={'flex-start'}>
              <TicketWidget
                referenceId={userId}
                ticketType={TICKET_TYPES.NR_ONBOARD}
              />
            </Box>
          )}
        </Grid>
      </ContentLayout>
    </>
  );
};

export default NrOnboardingPage;

import {
  Box,
  Grid,
  VStack,
  Divider,
  Heading,
  HStack,
  Select,
  Text
} from '@chakra-ui/react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useIsMobile from '../../hooks/useIsMobile';
import ContentLayout from '../../layouts/ContentLayout';
import { getFunnelV2Orders } from '../../store/actions/funnelActions';
import {
  updateFunnelV2ReqData,
  updateFunnelV2OrdersReqData
} from '../../store/actions/reqDataActions';
import FunnelBar from './FunnelBar';
import { formatFunnelStatus } from './utils';
import {
  ALL_FUNNEL_V2_ORDERS_STATUS,
  ORDER_ACQUIRER,
  FILTER_BY_PAST_HOURS,
  ORDER_TYPE_V2
} from '../../constants';
import useDidMountEffect from '../../hooks/useDidMount';
import OrderDetailsCard from '../../components/OrderDetailsCard';
import OrdersList from '../../components/OrdersList';
import { useLocation, useNavigate } from 'react-router-dom';

const OrdersV2Funnel = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const orders = useSelector((state) => state.funnel.ordersV2.tabData);
  const tabData = useSelector((state) => state.funnel.ordersV2.tabCount);
  const isMobile = useIsMobile();
  const searchReqData = useSelector(
    (state) => state.reqData.funnel.ordersV2.funnelReqData
  );
  const { activeFunnel, acquirer, ordersInPastHours, orderType } = useSelector(
    (state) => state.reqData.funnel.ordersV2
  );
  const [selectedOrder, setSelectedOrder] = useState(null);

  const location = useLocation();

  const putQueryParams = (params) => {
    const searchParams = new URLSearchParams();
    Object.keys(params)?.map((key) => {
      if (!params[key]) return;
      searchParams.append(key, params[key]);
    });
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const loadQueryParams = () => {
    const queryParams = new URLSearchParams(location.search);
    const orderType = queryParams.get('orderType');
    const acquirer = queryParams.get('acquirer');
    const ordersInPastHours = queryParams.get('ordersInPastHours');
    const activeFunnel = queryParams.get('activeFunnel');
    const params = {
      orderType,
      acquirer,
      ordersInPastHours,
      activeFunnel
    };
    const reduced = Object.keys(params).reduce((prev, curr) => {
      if (!params[curr]) return { ...prev };
      return {
        ...prev,
        [curr]: params[curr]
      };
    }, {});
    Object.keys(reduced).length > 0 && dispatch(updateFunnelV2ReqData(reduced));
  };

  useEffect(() => {
    loadQueryParams();
    triggerGetAllFunnelOrders();
  }, []);

  const prevActiveFunnel = useRef(null);
  useDidMountEffect(() => {
    if (prevActiveFunnel.current === activeFunnel) searchFunnelOrders(true);
    else searchFunnelOrders();
    prevActiveFunnel.current = activeFunnel;
  }, [searchReqData[activeFunnel]]);

  useDidMountEffect(() => {
    triggerGetAllFunnelOrders(true);
    putQueryParams({
      acquirer,
      ordersInPastHours,
      orderType,
      activeFunnel
    });
  }, [acquirer, ordersInPastHours, orderType]);

  const triggerGetAllFunnelOrders = (refresh) => {
    ALL_FUNNEL_V2_ORDERS_STATUS.forEach((status) => {
      if (refresh || !orders[activeFunnel]?.data?.length)
        dispatch(
          getFunnelV2Orders(
            status,
            acquirer,
            searchReqData[status],
            ordersInPastHours,
            orderType
          )
        );
    });
  };

  const searchFunnelOrders = (refresh = false) => {
    if (refresh || !orders[activeFunnel]?.data?.length)
      dispatch(
        getFunnelV2Orders(
          activeFunnel,
          acquirer,
          searchReqData[activeFunnel],
          ordersInPastHours
        )
      );
  };

  const handlePageChange = (pageNo) => {
    pageNo - 1 !== searchReqData[activeFunnel]?.pageNo &&
      dispatch(
        updateFunnelV2OrdersReqData(activeFunnel, {
          ...searchReqData,
          pageNo: pageNo - 1
        })
      );
  };

  const onOrderDetailsClick = useCallback(
    (orderId, userId) => {
      if (isMobile) navigate(`/order/${orderId}`);

      setSelectedOrder({
        orderId,
        userId
      });
    },
    [isMobile, navigate]
  );

  const handleAcquirerChange = (e) =>
    e.target.value !== acquirer &&
    dispatch(updateFunnelV2ReqData({ acquirer: e.target.value }));

  const handleOrderTypeChange = (e) =>
    e?.target?.value !== orderType &&
    dispatch(updateFunnelV2ReqData({ orderType: e?.target?.value }));

  const handleOrdersInPastHours = (e) => {
    const pastHours = e?.target?.value;
    pastHours !== ordersInPastHours &&
      dispatch(updateFunnelV2ReqData({ ordersInPastHours: pastHours }));
  };

  const handleActiveFunnelChange = (status) => {
    if (status !== activeFunnel) {
      dispatch(updateFunnelV2ReqData({ activeFunnel: status }));
      putQueryParams({
        acquirer,
        ordersInPastHours,
        orderType,
        activeFunnel: status
      });
    }
  };

  return (
    <ContentLayout>
      <Grid gridTemplateColumns={{ base: '1fr', lg: '7fr 0fr 2fr' }}>
        <VStack
          gap={4}
          overflowY={{ base: 'initial', lg: 'scroll' }}
          overflowX={'hidden'}
          h={{ lg: '90vh' }}
          py={10}
          px={{ base: 4, sm: 10 }}
          alignItems={'stretch'}
          className="scroll"
        >
          <FunnelBar
            setActiveFunnel={handleActiveFunnelChange}
            activeFunnel={activeFunnel}
            funnelData={tabData}
            isListLoading={orders[activeFunnel]?.isLoading}
          />
          <HStack justifyContent={'space-between'} gap={4}>
            <Heading
              color={'white'}
              fontSize={'1.5rem'}
              fontWeight={500}
              pt={3}
            >
              {formatFunnelStatus(activeFunnel)}
            </Heading>
            <VStack gap={2} alignItems={'flex-end'}>
              <Box maxWidth="15rem">
                <Select
                  placeholder={`Filter OrderType`}
                  onChange={handleOrderTypeChange}
                  name={'orderType'}
                  value={orderType}
                  bg="black"
                  color="gray"
                  size={'sm'}
                  rounded={'lg'}
                  _focus={{
                    border: '1px solid #81EBAB',
                    boxShadow: 'none'
                  }}
                >
                  {Object.keys(ORDER_TYPE_V2).map((ot) => (
                    <option value={ot} key={ot}>
                      {ot}
                    </option>
                  ))}
                </Select>
              </Box>
              <Box maxWidth="15rem">
                <Select
                  placeholder={`Filter Acquirer`}
                  onChange={handleAcquirerChange}
                  name={'orderAcquirer'}
                  value={acquirer}
                  bg="black"
                  color="gray"
                  size={'sm'}
                  rounded={'lg'}
                  _focus={{
                    border: '1px solid #81EBAB',
                    boxShadow: 'none'
                  }}
                >
                  {Object.keys(ORDER_ACQUIRER).map((acquirer) => (
                    <option value={acquirer} key={acquirer}>
                      {acquirer}
                    </option>
                  ))}
                </Select>
              </Box>
              <Grid
                gridTemplateColumns={'1fr auto'}
                gap={2}
                alignItems={'center'}
              >
                <Text color={'white'} fontSize={'0.95rem'}>
                  Orders stuck for more than{' '}
                </Text>
                <Box maxWidth="15rem">
                  <Select
                    onChange={handleOrdersInPastHours}
                    name={'pastHourOrders'}
                    value={ordersInPastHours}
                    bg="black"
                    color="gray"
                    size={'sm'}
                    rounded={'lg'}
                    _focus={{
                      border: '1px solid #81EBAB',
                      boxShadow: 'none'
                    }}
                  >
                    {FILTER_BY_PAST_HOURS.map((pastHours) => (
                      <option key={`hours-${pastHours}`} value={pastHours}>
                        {!pastHours ? 'All orders' : pastHours + ' hours'}
                      </option>
                    ))}
                  </Select>
                </Box>
              </Grid>
            </VStack>
          </HStack>
          <Divider style={{ margin: '0' }} borderColor="whiteAlpha.300" />
          <OrdersList
            orders={orders[activeFunnel]?.data}
            pageNo={searchReqData[activeFunnel].pageNo}
            totalPages={orders[activeFunnel]?.totalPages}
            handlePageChange={handlePageChange}
            onOrderDetailsClick={onOrderDetailsClick}
            show={'user'}
          />
        </VStack>

        <Divider
          style={{ margin: '0' }}
          borderColor="whiteAlpha.300"
          orientation="vertical"
        />

        {!isMobile && (
          <Box m={0} alignSelf={'flex-start'}>
            {selectedOrder && (
              <OrderDetailsCard
                userId={selectedOrder.userId}
                orderId={selectedOrder.orderId}
                isSidebar
                showNavLink
              />
            )}
          </Box>
        )}
      </Grid>
    </ContentLayout>
  );
};

export default OrdersV2Funnel;

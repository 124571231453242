import { Spinner, VStack } from '@chakra-ui/react';
import React, { useCallback, useEffect } from 'react';
import OrdersList from '../../components/OrdersList';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserSipsReqData } from '../../store/actions/reqDataActions';
import useDidMountEffect from '../../hooks/useDidMount';
import { useNavigate } from 'react-router-dom';
import { ORDER_PRODUCTS } from '../../constants';
import {
  clearUserSips,
  getUserSips
} from '../../store/actions/investmentActions';

const UserSips = ({ userId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    data: sips,
    totalPages,
    isLoading
  } = useSelector((state) => state.investments.userSips);

  const searchReqData = useSelector((state) => state.reqData.user.orders.sips);

  useDidMountEffect(() => {
    if (!sips.length)
      dispatch(
        getUserSips({
          userId,
          ...searchReqData
        })
      );

    return () => dispatch(clearUserSips());
  }, []);

  useEffect(() => {
    dispatch(
      getUserSips({
        userId,
        ...searchReqData
      })
    );
  }, [searchReqData]);

  const handlePageChange = useCallback(
    (pageNo) => {
      pageNo - 1 !== searchReqData.pageNo &&
        dispatch(updateUserSipsReqData({ pageNo: pageNo - 1 }));
    },
    [dispatch, searchReqData.pageNo]
  );

  const onOrderDetailsClick = useCallback(
    (sipId) => {
      navigate(`/investments/sips/${sipId}`);
    },
    [navigate]
  );

  if (isLoading) return <Spinner color="brand.500" />;

  return (
    <VStack w={'100%'} alignItems={'stretch'} gap={'0.5rem'}>
      <OrdersList
        orders={sips}
        pageNo={searchReqData.pageNo}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
        onOrderDetailsClick={onOrderDetailsClick}
        product={ORDER_PRODUCTS.INVEST_MF_SIP}
      />
    </VStack>
  );
};

export default UserSips;

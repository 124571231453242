import { Box, Grid, Spinner, Text, VStack } from '@chakra-ui/react';
import NestedDataCard from './NestedDataCard';

const NronboardOcrDetails = ({
  userId,
  isLoading,
  ocrDetails,
  isOnboarding = true,
  handleEditClick = () => {}
}) => {
  return (
    <>
      <Box>
        <VStack alignItems={'start'} width={'auto'} gap={2}>
          <Text color={'white'} fontSize={'xl'}>
            OCR Details
          </Text>
          {isLoading ? (
            <Spinner />
          ) : (
            <Grid
              gridTemplateColumns={{ base: '1fr', md: '1fr 1fr' }}
              w={'100%'}
              gap={6}
            >
              {Object.keys(ocrDetails).length === 0 && (
                <Text color={'gray.400'}>No OCR Details Found</Text>
              )}
              {Object.keys(ocrDetails).map((detail) => (
                <NestedDataCard
                  key={`ocr-detail-${detail}`}
                  title={detail}
                  data={ocrDetails[detail]}
                  isOnboarding={isOnboarding}
                  docKey={detail}
                  handleEditClick={handleEditClick}
                />
              ))}
            </Grid>
          )}
        </VStack>
      </Box>
    </>
  );
};

export default NronboardOcrDetails;

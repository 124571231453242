import React, { useState } from 'react';
import {
  Flex,
  ModalBody,
  Textarea,
  Button,
  ModalFooter,
  FormControl,
  FormLabel,
  ModalHeader,
  ModalCloseButton,
  Text,
  Box,
  Switch
} from '@chakra-ui/react';

const UpdateSettlementMsg = ({
  countryCode,
  currentMessage,
  isDelayed,
  handleSubmit,
  isLoading
}) => {
  const initialState = {
    settlementMessage: !currentMessage ? '' : currentMessage,
    isDelayed: Boolean(isDelayed) || false,
    countryCode: countryCode || ''
  };

  const [settlementConfig, setSettlementConfig] = useState(initialState);

  // const [settlementMessage, setSettlementMessage] = useState(currentMessage);

  const handleChange = (e) => {
    const key = e?.target?.name;
    const value = e?.target?.value;
    console.log({ key, value });
    setSettlementConfig((prev) => ({
      ...prev,
      [key]: value
    }));
  };

  const handleLocalSubmit = (e) => {
    e.preventDefault();
    handleSubmit(settlementConfig);
  };

  return (
    <>
      <ModalHeader textAlign={'center'} py={0}>
        Update Settlement Message
      </ModalHeader>
      <Text textAlign={'center'} fontSize={'0.75rem'} color={'shadedGray.400'}>
        for {countryCode}
      </Text>
      <ModalCloseButton />
      <ModalBody>
        <FormControl as={'form'} onSubmit={handleLocalSubmit}>
          <Box my={'1rem'}>
            <Flex
              w={'20%'}
              justifyContent={'flex-start'}
              height={'100%'}
              alignItems={'center'}
            >
              <FormLabel
                alignSelf={'flex-end'}
                fontSize={'0.75rem'}
                fontWeight={'500'}
                mb={0}
                lineHeight={'2rem'}
                pr={2}
              >
                Message :
              </FormLabel>
            </Flex>
            <Textarea
              className={'scroll-sm'}
              resize={'none'}
              size={'sm'}
              fontSize={'0.75rem'}
              fontWeight={'600'}
              type="text"
              rounded="lg"
              bg={'shadedGray.400'}
              color={'shadedGray.800'}
              border={0}
              placeholder={'Add note for order'}
              _placeholder={{ opacity: 1, color: 'shadedGray.600' }}
              name="settlementMessage"
              value={settlementConfig.settlementMessage}
              onChange={handleChange}
            />
          </Box>

          <Box my={'1rem'}>
            <Flex
              w={'20%'}
              justifyContent={'flex-start'}
              height={'100%'}
              alignItems={'center'}
            >
              <FormLabel
                alignSelf={'flex-end'}
                fontSize={'0.75rem'}
                fontWeight={'500'}
                mb={0}
                lineHeight={'2rem'}
                pr={2}
              >
                Is Delayed? :
              </FormLabel>
            </Flex>
            <Switch
              isChecked={settlementConfig.isDelayed}
              name="isDelayed"
              onChange={() => {
                setSettlementConfig((prev) => ({
                  ...prev,
                  isDelayed: !prev?.isDelayed
                }));
              }}
            />
          </Box>

          <ModalFooter justifyContent={'center'}>
            <Button
              colorScheme="brand"
              color="altGray.400"
              mr={3}
              size={'sm'}
              fontSize={'0.75rem'}
              type="submit"
              isLoading={isLoading}
            >
              Submit
            </Button>
          </ModalFooter>
        </FormControl>
      </ModalBody>
    </>
  );
};

export default UpdateSettlementMsg;

const REFERRALS = {
  SEARCHING_ACTIVE_CAMPAIGNS: 'SEARCHING_ACTIVE_CAMPAIGNS',
  SEARCH_ACTIVE_CAMPAIGNS_SUCCESS: 'SEARCH_ACTIVE_CAMPAIGNS_SUCCESS',
  SEARCH_ACTIVE_CAMPAIGNS_FAILURE: 'SEARCH_ACTIVE_CAMPAIGNS_FAILURE',
  GETTING_CAMPAIGN_BY_ID: 'GETTING_CAMPAIGN_BY_ID',
  GET_CAMPAIGN_BY_ID_SUCCESS: 'GET_CAMPAIGN_BY_ID_SUCCESS',
  GET_CAMPAIGN_BY_ID_FAILURE: 'GET_CAMPAIGN_BY_ID_FAILURE',
  EDITING_CAMPAIGN: 'EDITING_CAMPAIGN',
  EDIT_CAMPAIGN_SUCCESS: 'EDIT_CAMPAIGN_SUCCESS',
  EDIT_CAMPAIGN_FAILURE: 'EDIT_CAMPAIGN_FAILURE',
  CREATING_CAMPAIGN: 'CREATING_CAMPAIGN',
  CREATE_CAMPAIGN_SUCCESS: 'CREATE_CAMPAIGN_SUCCESS',
  CREATE_CAMPAIGN_FAILURE: 'EDIT_CAMPAIGN_FAILURE',
  CLEAR_CAMPAIGN_DETAILS: 'CLEAR_CAMPAIGN_DETAILS',

  GETTING_REDEEMED_COINS: 'GETTING_REDEEMED_COINS',
  GET_REDEEMED_COINS_SUCCESS: 'GET_REDEEMED_COINS_SUCCESS',
  GET_REDEEMED_COINS_FAILURE: 'GET_REDEEMED_COINS_FAILURE',

  GETTING_USER_REDEEMED_COINS: 'GETTING_USER_REDEEMED_COINS',
  GET_USER_REDEEMED_COINS_SUCCESS: 'GET_USER_REDEEMED_COINS_SUCCESS',
  GET_USER_REDEEMED_COINS_FAILURE: 'GET_USER_REDEEMED_COINS_FAILURE',

  COMPLETING_REDEEMED_COINS: 'COMPLETING_REDEEMED_COINS',
  COMPLETE_REDEEMED_COINS_SUCCESS: 'COMPLETE_REDEEMED_COINS_SUCCESS',
  COMPLETE_REDEEMED_COINS_FAILURE: 'COMPLETE_REDEEMED_COINS_FAILURE'
};

export default REFERRALS;

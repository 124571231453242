import { useParams } from 'react-router-dom';
import ContentLayout from '../../../layouts/ContentLayout';
import {
  Grid,
  Heading,
  Text,
  Spinner,
  Table,
  Tbody,
  Td,
  Tr,
  VStack,
  HStack,
  Button,
  useDisclosure
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  enableStudentKycFlow,
  getStudentProgramDetails,
  updateStudentProgramDetails
} from '../../../store/actions/usersActions';
import { tableCellStyles } from '../../../components/NrOnboard/constants';
import ModalLayout from '../../../components/Modal/ModalLayout';
import GenericUpdateModal from '../../../components/Modal/GenericUpdateModal';
import { camelCaseToSpaceSeparated } from '../../../utils';

const StudentProgramDetails = () => {
  const { userId } = useParams();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [studentData, setStudentData] = useState({});
  const [kycFlowNotInitiated, setKycFlowNotInitiated] = useState(false);
  const {
    isOpen: isUpdateModalOpen,
    onOpen: onUpdateModalOpen,
    onClose: onUpdateModalClose
  } = useDisclosure();

  const fetchDetails = () => {
    setIsLoading(true);
    dispatch(
      getStudentProgramDetails({
        userId,
        onSuccess: ({ data }) => {
          if (!data) {
            setKycFlowNotInitiated(true);
            setStudentData({});
            return;
          } else {
            setStudentData({
              ...data
            });
            setKycFlowNotInitiated(false);
          }
        },
        onComplete: () => {
          setIsLoading(false);
        }
      })
    );
  };

  const handleUpdateStudentDetailsClose = () => {
    onUpdateModalClose();
  };

  const handleUpdateStudentDetailsOpen = () => {
    onUpdateModalOpen();
  };

  const handleUpdateStudentDetailsSubmit = (data) => {
    setIsLoading(true);
    dispatch(
      updateStudentProgramDetails({
        payload: {
          ...data
        },
        onSuccess: () => {
          fetchDetails();
          handleUpdateStudentDetailsClose();
        },
        onComplete: () => {
          setIsLoading(false);
        }
      })
    );
  };

  const handleEnableKycClick = () => {
    setIsLoading(true);
    dispatch(
      enableStudentKycFlow({
        userId,
        onSuccess: () => {
          fetchDetails();
        },
        onComplete: () => {
          setIsLoading(false);
        }
      })
    );
  };

  useEffect(() => {
    fetchDetails();
  }, [userId]);

  const cellStyles = tableCellStyles();

  const modalData = {
    ...studentData,
    userId
  };

  return (
    <>
      <ModalLayout
        isOpen={isUpdateModalOpen}
        onClose={handleUpdateStudentDetailsClose}
      >
        <GenericUpdateModal
          title="Update Student Details"
          isLoading={isLoading}
          data={Object.keys(modalData).reduce((prev, curr) => {
            const requiredFields = [
              'userId',
              'courseCompletion',
              'university',
              'universityMail',
              'expiry'
            ];
            if (requiredFields.includes(curr)) {
              return {
                ...prev,
                [curr]: modalData[curr]
              };
            }
            return prev;
          }, {})}
          onCancel={handleUpdateStudentDetailsClose}
          onSubmit={handleUpdateStudentDetailsSubmit}
        />
      </ModalLayout>
      <ContentLayout>
        <Grid gridTemplateColumns={{ base: '1fr', lg: '7fr 2fr' }}>
          <VStack
            overflowY={{ base: 'initial', lg: 'scroll' }}
            overflowX={'hidden'}
            h={{ lg: '90vh' }}
            py={10}
            px={{ base: 4, sm: 10 }}
            alignItems={'stretch'}
            className="scroll"
            gap={2}
          >
            <Heading size={'lg'} color={'white'}>
              Student Program Details
            </Heading>
            {kycFlowNotInitiated ? (
              <>
                <Text color={'white'} fontSize={'md'}>
                  No Student Program details found against this user
                </Text>
                <HStack>
                  <Button
                    onClick={handleEnableKycClick}
                    isLoading={isLoading}
                    variant={'outline'}
                    colorScheme="brand"
                  >
                    Enable Student KYC
                  </Button>
                </HStack>
              </>
            ) : (
              <>
                <HStack justifyContent={'end'}>
                  <Button
                    colorScheme="brand"
                    variant={'outline'}
                    type="button"
                    isDisabled={isLoading}
                    onClick={handleUpdateStudentDetailsOpen}
                  >
                    Update
                  </Button>
                </HStack>
                {!isLoading ? (
                  <Table>
                    <Tbody>
                      {Object.keys(studentData)?.map((key) => (
                        <Tr>
                          <Td style={cellStyles}>
                            <Text color={'gray.400'} fontSize={'md'}>
                              {camelCaseToSpaceSeparated(key)}:
                            </Text>
                          </Td>
                          <Td style={cellStyles}>
                            <Text color={'white'} fontSize={'md'}>
                              {studentData[key] || '-'}
                            </Text>
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                ) : (
                  <Spinner />
                )}
              </>
            )}
          </VStack>
        </Grid>
      </ContentLayout>
    </>
  );
};

export default StudentProgramDetails;

const RDA_DASHBOARD_CONSTANTS = {
  GETTING_OVERALL_TXNS: 'GETTING_OVERALL_TXNS',
  GET_OVERALL_TXNS_SUCCESS: 'GET_OVERALL_TXNS_SUCCESS',
  GET_OVERALL_TXNS_FAIL: 'GET_OVERALL_TXNS_FAIL',
  GETTING_YBL_PARTNER_TXNS: 'GETTING_YBL_PARTNER_TXNS',
  GET_YBL_PARTNER_TXNS_SUCCESS: 'GET_YBL_PARTNER_TXNS_SUCCESS',
  GET_YBL_PARTNER_TXNS_FAIL: 'GET_YBL_PARTNER_TXNS_FAIL',
  GETTING_TL_TXNS: 'GETTING_TL_TXNS',
  GET_TL_TXNS_FAIL: 'GET_TL_TXNS_FAIL',
  GET_TL_TXNS_SUCCESS: 'GET_TL_TXNS_SUCCESS',
  GETTING_CB_TXNS: 'GETTING_CB_TXNS',
  GET_CB_TXNS_SUCCESS: 'GET_CB_TXNS_SUCCESS',
  GET_CB_TXNS_FAIL: 'GET_CB_TXNS_FAIL'
};

export default RDA_DASHBOARD_CONSTANTS;

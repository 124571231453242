import React from 'react';
import { useParams } from 'react-router-dom';
import ContentLayout from '../../layouts/ContentLayout';
import { Box } from '@chakra-ui/react';
import InvestmentOrderDetailsCard from '../../components/InvestmentOrderDetailsCard';

//todo : refactor to handle in index file only
const InvestmentOrderDetails = () => {
  const { userId, orderId } = useParams();

  return (
    <ContentLayout>
      <Box pt={4} pl={4} w={'100%'}>
        <InvestmentOrderDetailsCard
          userId={userId}
          orderId={orderId}
          showNavLink={true}
        />
      </Box>
    </ContentLayout>
  );
};

export default InvestmentOrderDetails;

import { useDispatch, useSelector } from 'react-redux';
import DocumentCards from './DocumentCards';
import { useEffect, useState } from 'react';
import {
  KYC_PROVIDER,
  NROB_STATES,
  NR_OB_COUNTRY,
  PRODUCT,
  WORKFLOWS_V2,
  NR_OB_UAE_REQ_DOCS,
  NR_OB_UK_REQ_DOCS,
  NROB_USER_TYPES,
  WAITLIST_ADDITIONAL_DETAILS
} from '../../constants';
import {
  getFinancialWorkflowDetails,
  verifyApplication,
  getNrOnboardDetails,
  getOcrDetails,
  getOcrAddress,
  updateAddress,
  getOnboardUserDocsV2,
  getOnboardUserDocs,
  syncApplicationForm
} from '../../store/actions/nronboardActions';
import {
  HStack,
  Button,
  VStack,
  Text,
  useDisclosure,
  Table,
  Tbody,
  Tr,
  Td,
  IconButton
} from '@chakra-ui/react';
import FullPageSpinner from '../FullPageSpinner';
import NrObFinancialWorkflowDetails from './FinancialDetails';
import NronboardOcrDetails from './OcrDetails';
import { Link } from 'react-router-dom';
import ModalLayout from '../Modal/ModalLayout';
import NrOnboardVerifyOcrAddressModal from './VerifyAddressModal';
import { onboardDocsUploaded } from '../../store/reducers/utils';
import { FaSyncAlt } from 'react-icons/fa';
import api from '../../api';

const tableCellStyle = {
  border: 'none',
  padding: '8px'
};

const NronboardApplication = ({ userId, applicationData }) => {
  const { status, kycVerificationStatus, applicationFormUrl } = applicationData;

  const { country } = useSelector((state) => state?.nronboard?.details?.data);
  const { onboardingUserType } = useSelector(
    (state) => state?.nronboard?.details?.data
  );

  const { data: docs, isLoading: docsLoading } = useSelector(
    (state) => state?.nronboard?.docs
  );

  const { data: financialDetails, isLoading: financialDetailsLoading } =
    useSelector((state) => state?.nronboard?.financialDetails);

  const { data: ocrDetails, isLoading: ocrDetailsLoading } = useSelector(
    (state) => state?.nronboard?.ocr
  );

  const {
    isOpen: isVerifyOpen,
    onOpen: onVerifyOpen,
    onClose: onVerifyClose
  } = useDisclosure();

  const [ocrAddress, setOcrAddress] = useState({
    address_line_1: '',
    address_line_2: '',
    city: '',
    state: '',
    post_code: '',
    country_code: country
  });
  const [addressVerificationConfig, setAddressVerificationConfig] =
    useState(null);
  const [btnsDisabled, setBtnsDisabled] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!userId || !country) return;
    country &&
      dispatch(
        getOnboardUserDocsV2({
          userId,
          country,
          requiredDocs:
            country === NR_OB_COUNTRY.AE
              ? NR_OB_UAE_REQ_DOCS
              : NR_OB_UK_REQ_DOCS
        })
      );
    country &&
      dispatch(
        getFinancialWorkflowDetails({
          userId,
          workflowType:
            onboardingUserType === 'NR_WAITLIST'
              ? country === NR_OB_COUNTRY.AE
                ? WAITLIST_ADDITIONAL_DETAILS.UAE_NR_WAITLIST_ADDITIONAL_DETAIL_SCREEN_ID
                : WAITLIST_ADDITIONAL_DETAILS.UK_NR_WAITLIST_ADDITIONAL_DETAIL_SCREEN_ID
              : (country === NR_OB_COUNTRY.AE ? 'UAE_' : '') +
                WORKFLOWS_V2.NR_ACCOUNT_OPENING_FINANCIAL_DETAILS
        })
      );
    dispatch(
      getOcrDetails({
        userId
      })
    );
  }, [userId, country, dispatch]);

  useEffect(() => {
    if (!country) return;
    const screenId =
      country === NR_OB_COUNTRY.GB
        ? 'UK_NRAOVD_ADDRESS_PROOF_SCREEN_ID'
        : 'UAE_NRAOVD_ADDRESS_PROOF_SCREEN_ID';
    dispatch(
      getOnboardUserDocs({
        screenId,
        userId,
        onSuccess: (data) => {
          const { verified } = data;
          verified &&
            dispatch(
              getOcrAddress({
                userId: userId,
                onSuccess: (data) => {
                  setOcrAddress(data);
                }
              })
            );
          setAddressVerificationConfig(data);
        }
      })
    );
  }, [status, country]);

  const handleVerifyBtnClick = () => {
    setBtnsDisabled(true);
    dispatch(
      verifyApplication({
        userId,
        onSuccess: () => {
          dispatch(getNrOnboardDetails(userId));
          country &&
            dispatch(
              getOnboardUserDocsV2({
                userId,
                country,
                requiredDocs:
                  country === NR_OB_COUNTRY.AE
                    ? NR_OB_UAE_REQ_DOCS
                    : NR_OB_UK_REQ_DOCS
              })
            );
        },
        onComplete: () => {
          setBtnsDisabled(false);
        }
      })
    );
  };

  const handleUpdateAddress = (payload) => {
    setBtnsDisabled(true);
    dispatch(
      updateAddress({
        userId,
        payload,
        onSuccess: () => {
          dispatch(getNrOnboardDetails(userId));
          country &&
            dispatch(
              getOnboardUserDocsV2({
                userId,
                country,
                requiredDocs:
                  country === NR_OB_COUNTRY.AE
                    ? NR_OB_UAE_REQ_DOCS
                    : NR_OB_UK_REQ_DOCS
              })
            );
          onVerifyClose();
        },
        onError: () => {},
        onComplete: () => {
          setBtnsDisabled(false);
        }
      })
    );
  };

  const handleSyncApplicationClick = () => {
    setBtnsDisabled(true);
    dispatch(
      syncApplicationForm({
        userId,
        onComplete: () => {
          setBtnsDisabled(false);
        }
      })
    );
  };

  const handleViewApplicationFormClick = ({ docUrl = applicationFormUrl }) => {
    api.workflowsV2
      .getDocByUrl({ userId, docUrl })
      .then(({ data }) => window.open(data?.url, '_blank'))
      .catch((err) => console.log(err));
  };

  if (docsLoading || financialDetailsLoading) <FullPageSpinner />;

  return (
    <>
      <ModalLayout size="2xl" isOpen={isVerifyOpen} onClose={onVerifyClose}>
        <NrOnboardVerifyOcrAddressModal
          addressState={ocrAddress}
          setAddressState={setOcrAddress}
          addressConfig={addressVerificationConfig}
          handleDocClick={handleViewApplicationFormClick}
          handleDiscard={() => {
            onVerifyClose();
          }}
          handleUpdate={handleUpdateAddress}
        />
      </ModalLayout>
      <VStack gap={4} width={'auto'} alignItems={'stretch'}>
        <HStack justifyContent={'end'} alignItems={'center'}>
          {(status === NROB_STATES.FORM_REVIEWED ||
            status === NROB_STATES.FORM_VERIFIED ||
            status === NROB_STATES.APPLICATION_SENT_TO_PROVIDER) && (
            <Link to={`/user/${userId}/ybl-details`}>
              <Text as={'u'} color="white">
                Check YBL Form
              </Text>
            </Link>
          )}
        </HStack>
        <Table>
          <Tbody>
            <Tr>
              <Td style={tableCellStyle}>
                <Text color={'gray.400'} fontSize={'md'}>
                  Status:
                </Text>
              </Td>
              <Td style={tableCellStyle}>
                <Text color={'white'} fontSize={'md'}>
                  {status}
                </Text>
              </Td>
              <Td style={tableCellStyle}>
                <Text color={'white'} fontSize={'md'}>
                  -
                </Text>
              </Td>
            </Tr>
            <Tr>
              <Td style={tableCellStyle}>
                <Text color={'gray.400'} fontSize={'md'}>
                  KYC Verification Status:
                </Text>
              </Td>
              <Td style={tableCellStyle}>
                <Text color={'white'} fontSize={'md'}>
                  {kycVerificationStatus}
                </Text>
              </Td>
              <Td style={tableCellStyle}>
                <Link
                  to={`/user/${userId}/kyc/product/${
                    PRODUCT.REMITTANCE
                  }/provider/${
                    country === NR_OB_COUNTRY.AE
                      ? KYC_PROVIDER.EFR
                      : KYC_PROVIDER.COMPLY_CUBE
                  }`}
                >
                  <Text color={'brand.500'} as={'u'} fontSize={'md'}>
                    Checkout KYC
                  </Text>
                </Link>
              </Td>
            </Tr>
            {applicationFormUrl &&
              [
                NROB_STATES.FORM_REVIEWED,
                NROB_STATES.APPLICATION_SENT_TO_PROVIDER,
                NROB_STATES.FORM_VERIFIED
              ].includes(status) && (
                <Tr>
                  <Td style={tableCellStyle}>
                    <Text color={'gray.400'} fontSize={'md'}>
                      Application Form:
                    </Text>
                  </Td>
                  <Td style={tableCellStyle}>
                    <HStack
                      justifyContent={'start'}
                      alignItems={'center'}
                      gap={2}
                    >
                      <Button
                        variant={'link'}
                        onClick={handleViewApplicationFormClick}
                        isLoading={btnsDisabled}
                      >
                        View File
                      </Button>

                      <IconButton
                        size={'sm'}
                        isLoading={btnsDisabled}
                        variant={'unstyled'}
                        icon={<FaSyncAlt color="gray" />}
                        onClick={handleSyncApplicationClick}
                      />
                    </HStack>
                  </Td>
                  <Td style={tableCellStyle}></Td>
                </Tr>
              )}
          </Tbody>
        </Table>
        {status === NROB_STATES.FORM_FILLED && (
          <>
            <HStack justifyContent={'end'}>
              <Button
                isLoading={btnsDisabled}
                onClick={() => {
                  onVerifyOpen();
                }}
              >
                Verify Address
              </Button>
            </HStack>
          </>
        )}
        <DocumentCards
          documents={docs}
          isLoading={docsLoading}
          userId={userId}
          showRejectBtn={
            onboardingUserType === NROB_USER_TYPES.NR_WAITLIST
              ? status === NROB_STATES.WAITLIST_FORM_FILLED
              : status === NROB_STATES.FORM_FILLED ||
                status === NROB_STATES.ADDRESS_VERIFIED
          }
        />
        <NrObFinancialWorkflowDetails
          isLoading={financialDetailsLoading}
          financialDetails={financialDetails}
          userId={userId}
        />
        <NronboardOcrDetails
          userId={userId}
          isLoading={ocrDetailsLoading}
          ocrDetails={ocrDetails}
        />
        <HStack width={'full'} justifyContent={'end'}>
          {status === NROB_STATES.ADDRESS_VERIFIED && (
            <VStack alignItems={'start'} gap={1}>
              <Button
                variant={'outline'}
                colorScheme="green"
                disabled={
                  kycVerificationStatus !== 'VERIFIED' ||
                  !onboardDocsUploaded(docs)
                }
                onClick={handleVerifyBtnClick}
                isLoading={btnsDisabled}
              >
                Generate YBL Form
              </Button>
              {!onboardDocsUploaded(docs) && (
                <Text color={'gray.400'} fontSize={'sm'}>
                  *All documents are not uploaded
                </Text>
              )}
              {kycVerificationStatus !== 'VERIFIED' && (
                <Text color="gray.400" fontSize={'sm'}>
                  *KYC verification is pending
                </Text>
              )}
            </VStack>
          )}
        </HStack>
      </VStack>
    </>
  );
};

export default NronboardApplication;

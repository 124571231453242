import api from '../../api';
import { setAlert } from './alertActions';
import { ORDER_SCREEN_CONFIG } from '../constants';

export const updateScreenConfLocal = (newConf) => async (dispatch) => {
  dispatch({
    type: ORDER_SCREEN_CONFIG.UPDATE_SCREEN_CONF_LOCAL,
    payload: newConf
  });
};

export const updateScreenConfig =
  ({ configData, orderId, onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      await api.orderScreenConfig.postScreenConfig({
        orderId,
        configData
      });
      onSuccess && onSuccess();
    } catch (error) {
      console.error(error?.message);
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const getOrderScreenConfigData =
  ({ orderId }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: ORDER_SCREEN_CONFIG.GETTING_SCREEN_CONFIG
      });
      const { data } = await api.orderScreenConfig.getScreenConfig({ orderId });
      dispatch({
        type: ORDER_SCREEN_CONFIG.GET_ORDER_SCREEN_CONF_SUCCESS,
        payload: {
          ...data
        }
      });
    } catch (error) {
      console.error(error?.message);
      dispatch({
        type: ORDER_SCREEN_CONFIG.GET_SCREEN_CONFIG_FAILURE,
        payload: {
          errMsg: error?.message
        }
      });
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    }
  };

import { useState } from 'react';
import ContentLayout from '../../../layouts/ContentLayout';
import { Grid, Heading, VStack, useDisclosure } from '@chakra-ui/react';
import { FEATURE_FLAGS } from '../../../constants/v2/common';
import { useDispatch } from 'react-redux';
import ModalLayout from '../../../components/Modal/ModalLayout';
import UpdateFeatureFlagModal from '../../../components/FeatureFlags/UpdateFeatureFlagModal';
import FeatureFlagItem from '../../../components/FeatureFlags/FeatureFlagItem';
import { updateFeatureFlagValue } from '../../../store/actions/configActions';

const FeatureFlags = () => {
  const [featureState, setFeatureState] = useState(
    Object.keys(FEATURE_FLAGS).reduce((prev, curr) => {
      return {
        ...prev,
        [curr]: ''
      };
    }, {})
  );
  const [selectedFeatureState, setSelectedFeatureState] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const {
    isOpen: isUpdateFeatureFlagOpen,
    onOpen: onUpdateFeatureFlagOpen,
    onClose: onUpdateFeatureFlagClose
  } = useDisclosure();

  const handleUpdateFeatureFlagCancel = () => {
    setSelectedFeatureState(null);
    onUpdateFeatureFlagClose();
  };
  const handleUpdateFeatureFlagSubmit = ({ featureName, featureValue }) => {
    setIsLoading(true);
    dispatch(
      updateFeatureFlagValue({
        featureName,
        featureValue,
        onSuccess: () => {
          setFeatureState((prev) => ({
            ...prev,
            [featureName]: featureValue
          }));
          handleUpdateFeatureFlagCancel();
        },
        onComplete: () => {
          setIsLoading(false);
        }
      })
    );
  };

  const handleEditClick = (featureState) => {
    setSelectedFeatureState(featureState);
    onUpdateFeatureFlagOpen();
  };

  return (
    <>
      <ModalLayout
        size="xl"
        isOpen={isUpdateFeatureFlagOpen}
        onClose={handleUpdateFeatureFlagCancel}
      >
        <UpdateFeatureFlagModal
          isLoading={isLoading}
          initialFeatureState={selectedFeatureState}
          onCancel={handleUpdateFeatureFlagCancel}
          onSubmit={handleUpdateFeatureFlagSubmit}
        />
      </ModalLayout>
      <ContentLayout>
        <Grid gridTemplateColumns={{ base: '1fr', lg: '7fr 0fr 2fr' }}>
          <VStack
            width={'auto'}
            alignItems={'stretch'}
            gap={4}
            overflowY={{ base: 'initial', lg: 'scroll' }}
            overflowX={'hidden'}
            h={{ lg: '90vh' }}
            py={10}
            px={{ base: 4, sm: 10 }}
            className="scroll"
          >
            <Heading size={'lg'} color={'white'}>
              Feature Flags
            </Heading>
            {/* <Table>
            <Tbody> */}
            {Object.keys(FEATURE_FLAGS).map((featureFlag) => (
              <FeatureFlagItem
                featureState={featureState}
                setFeatureState={setFeatureState}
                key={featureFlag}
                featureFlag={featureFlag}
                onEditClick={handleEditClick}
                isUpdated={isLoading}
              />
            ))}
            {/* </Tbody>
          </Table> */}
          </VStack>
        </Grid>
      </ContentLayout>
    </>
  );
};

export default FeatureFlags;

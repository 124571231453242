import {
  Button,
  Grid,
  HStack,
  Heading,
  ModalBody,
  ModalFooter,
  VStack,
  Text
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa';
import rdaDashboard from '../../api/rdaDashboard';

const ExploreModal = ({ onClose }) => {
  // add entry points to all pages
  const entryPoints = {
    nrOnboarding: {
      title: 'NR Onboarding',
      href: '/funnel/nr-onboarding'
    },
    orders: {
      title: 'Check Active Orders',
      href: '/funnel/orders'
    },
    luluDeals: {
      title: 'Check Active Lulu Deals',
      href: '/funnel/orders/lulu'
    },
    campaigns: {
      title: 'Active Campaigns',
      href: '/campaign'
    },
    verifications: {
      title: 'Verifications',
      href: '/kyc'
    },
    fulfillments: {
      title: 'On hold fulfillments',
      href: '/thunes/fulfillments'
    },
    jobs: {
      title: 'Jobs',
      href: '/jobs'
    },
    configSettings: {
      title: 'Config Settings',
      href: '/config'
    },
    redeemedCoins: {
      title: 'Redeemed Coins',
      href: '/redeemed-coins'
    },
    tickets: {
      title: 'Tickets',
      href: '/tickets'
    },
    vdaDashboard: {
      title: 'VDA Dashboard',
      href: '/vda-dashboard'
    },
    nonVerifiedOrderReceipts: {
      title: 'Non Verified Order Receipts',
      href: '/non-verified-order-receipts'
    },
    ManualPayouts: {
      title: 'Manual Payouts',
      href: 'manual-payouts/batch'
    },
    rewards: {
      title: 'Rewards',
      href: '/rewards'
    },
    baerscrestOps: {
      title: 'Baerscrest Ops',
      href: '/baerscrest-ops'
    },
    rdaDashboard: {
      title: 'RDA Dashboard',
      href: '/rda-dashboard'
    }
  };

  const navigate = useNavigate();

  const handleClick = (url) => {
    navigate(url);
    onClose && onClose();
  };

  return (
    <>
      <ModalBody>
        <VStack alignItems={'stretch'} gap={2}>
          <Heading textAlign={'center'} size={'lg'}>
            Explore
          </Heading>
          <Grid gap={4} gridTemplateColumns={'1fr 1fr'} padding={'4'}>
            {Object.keys(entryPoints).map((entryPoint) => (
              <Button
                textAlign={'start'}
                key={entryPoint}
                variant={'link'}
                colorScheme="brand"
                onClick={() => handleClick(entryPoints[entryPoint]?.href)}
              >
                <HStack>
                  <Text>{entryPoints[entryPoint]?.title}</Text>
                  <FaArrowRight />
                </HStack>
              </Button>
            ))}
          </Grid>
        </VStack>
      </ModalBody>
      <ModalFooter></ModalFooter>
    </>
  );
};

export default ExploreModal;

import {
  Box,
  Input,
  Table,
  Tbody,
  Tr,
  Td,
  Text,
  Spinner,
  VStack,
  Heading,
  useDisclosure,
  HStack,
  Button
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { tableCellStyles } from '../NrOnboard/constants';
import { useEffect, useState } from 'react';
import {
  getConfigList,
  updateConfigItems
} from '../../store/actions/configActions';
import ModalLayout from '../Modal/ModalLayout';
import FtnrVerifyUpdateModal from '../Ftnr/VerifyUpdateModal';
import { kebabCaseToSpaceSeperate } from '../../utils';

const ConfigList = () => {
  const { isLoading, data: configItems } = useSelector(
    (state) => state?.config?.configList
  );

  // local state to monitor changes
  const [configItemsState, setConfigItemsState] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();

  const dispatch = useDispatch();

  const cellStyles = tableCellStyles();

  const getConfigItemsObj = () => {
    return configItems?.reduce(
      (prev, curr) => ({
        ...prev,
        [curr?.configType]: curr?.value
      }),
      {}
    );
  };

  const getPatchData = () => {
    const source = getConfigItemsObj();
    return Object.keys(configItemsState)
      ?.filter((key) => configItemsState[key] !== source[key])
      .reduce((prev, curr) => {
        return {
          ...prev,
          [curr]: configItemsState[curr]
        };
      }, {});
  };

  useEffect(() => {
    dispatch && dispatch(getConfigList());
  }, []);

  useEffect(() => {
    if (!configItems) return;
    const configItemsState = getConfigItemsObj();
    setConfigItemsState(configItemsState);
  }, [configItems]);

  const handleConfigItemChange = (e) => {
    const newValue = e?.target?.value;
    const name = e?.target?.name;
    setConfigItemsState((prevState) => ({
      ...prevState,
      [name]: newValue
    }));
  };

  const handleDiscard = (e) => {
    const newState = getConfigItemsObj();
    setConfigItemsState(newState);
    onClose();
  };

  const handleUpdate = () => {
    dispatch(
      updateConfigItems({
        updatedFields: getPatchData(),
        onSuccess: () => {
          dispatch(getConfigList());
        },
        onComplete: () => {
          onClose();
        }
      })
    );
  };

  return (
    <>
      <ModalLayout size="2xl" isOpen={isOpen} onClose={onClose}>
        <FtnrVerifyUpdateModal
          handleDiscard={handleDiscard}
          handleUpdate={handleUpdate}
          prevState={getConfigItemsObj()}
          updatedFields={getPatchData()}
        />
      </ModalLayout>
      <Box width={'full'}>
        <VStack gap={2} alignItems={'stretch'}>
          <Heading fontSize={'1.3rem'} color={'white'}>
            Config List
          </Heading>
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              <Table>
                <Tbody>
                  {Object.keys(configItemsState)?.map((key) => (
                    <Tr key={key}>
                      <Td style={cellStyles}>
                        <Text color={'gray'} fontSize={'md'}>
                          {kebabCaseToSpaceSeperate(key)}:
                        </Text>
                      </Td>
                      <Td style={cellStyles}>
                        <Input
                          name={key}
                          color={'white'}
                          value={configItemsState[key]}
                          onChange={handleConfigItemChange}
                        />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
              <HStack justifyContent={'end'}>
                <Button
                  onClick={onOpen}
                  colorScheme="brand"
                  variant={'outline'}
                  size={'sm'}
                  disabled={Object.keys(getPatchData()).length === 0}
                >
                  Update
                </Button>
              </HStack>
            </>
          )}
        </VStack>
        {/* </Form> */}
      </Box>
    </>
  );
};

export default ConfigList;

import React, { useCallback, useState } from 'react';
import {
  Flex,
  ModalBody,
  Textarea,
  Button,
  ModalFooter,
  FormControl,
  FormLabel,
  ModalHeader,
  ModalCloseButton,
  Text,
  Box,
  Switch
} from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { updateOrderMetadata } from '../../store/actions/ordersActions';
import { doesExist } from '../../utils';

const OrderMetadataModal = ({
  orderId,
  onClose,
  initNote,
  initFlag,
  title,
  successMessage,
  failureMessage
}) => {
  const dispatch = useDispatch();
  const [note, setNote] = useState(initNote || '');
  const [flag, setFlag] = useState(doesExist(initFlag) ? initFlag : undefined);
  const [isLoading, setIsLoading] = useState(false);

  const handleNoteInputChange = useCallback((e) => setNote(e.target.value), []);

  const handleFlagInputChange = useCallback(
    (e) => setFlag(e.target.checked),
    []
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    dispatch(
      updateOrderMetadata({
        orderId,
        note,
        flag,
        successMessage,
        failureMessage,
        title,
        onSuccess: () => onClose(),
        onFinish: () => setIsLoading(false)
      })
    );
  };

  return (
    <>
      <ModalHeader textAlign={'center'} py={0}>
        {title}
      </ModalHeader>
      <Text textAlign={'center'} fontSize={'0.75rem'} color={'shadedGray.400'}>
        for order {orderId}
      </Text>
      <ModalCloseButton />
      <ModalBody>
        <FormControl as={'form'} onSubmit={handleSubmit}>
          <Flex my={'1rem'} justifyContent={'space-between'}>
            <Flex
              justifyContent={'flex-start'}
              height={'100%'}
              alignItems={'center'}
            >
              <FormLabel
                alignSelf={'flex-end'}
                fontSize={'0.75rem'}
                fontWeight={'500'}
                mb={0}
                lineHeight={'2rem'}
                pr={2}
              >
                Compliance Flag :
              </FormLabel>
            </Flex>

            <Switch
              size={'lg'}
              colorScheme={'brand'}
              onChange={handleFlagInputChange}
              isChecked={flag}
            />
          </Flex>
          <Box my={'1rem'}>
            <Flex
              w={'20%'}
              justifyContent={'flex-start'}
              height={'100%'}
              alignItems={'center'}
            >
              <FormLabel
                alignSelf={'flex-end'}
                fontSize={'0.75rem'}
                fontWeight={'500'}
                mb={0}
                lineHeight={'2rem'}
                pr={2}
              >
                Note :
              </FormLabel>
            </Flex>
            <Textarea
              className={'scroll-sm'}
              resize={'none'}
              size={'sm'}
              fontSize={'0.75rem'}
              fontWeight={'600'}
              type="text"
              rounded="lg"
              bg={'shadedGray.400'}
              color={'shadedGray.800'}
              border={0}
              placeholder={'Add note for order'}
              _placeholder={{ opacity: 1, color: 'shadedGray.600' }}
              value={note}
              onChange={handleNoteInputChange}
            />
          </Box>

          <ModalFooter justifyContent={'center'}>
            <Button
              colorScheme="brand"
              color="altGray.400"
              mr={3}
              size={'sm'}
              fontSize={'0.75rem'}
              type="submit"
              isLoading={isLoading}
            >
              Submit Now
            </Button>
          </ModalFooter>
        </FormControl>
      </ModalBody>
    </>
  );
};

export default OrderMetadataModal;

import Pagination from '../../../components/Pagination';
import ContentLayout from '../../../layouts/ContentLayout';
import {
  Box,
  Divider,
  Grid,
  HStack,
  Heading,
  Spinner,
  Text,
  VStack
} from '@chakra-ui/react';
import FunnelBar from '../FunnelBar';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { VDA_FUNNEL_STATES } from './constants';
import { getVdaTransactions } from '../../../store/actions/funnelActions';
import { updateVdaFunnelReqData } from '../../../store/actions/reqDataActions';
import VdaDataCompomet from '../../../components/vdaDashboard/VdaDataComponent';
import VdaStats from '../../../components/vdaDashboard/VdaStats';
import VdaOrderSearch from '../../../components/vdaDashboard/OrderSearch';

const VdaDashboard = () => {
  const { search, pageSize, page, status } = useSelector(
    (state) => state?.reqData?.funnel?.vdaFunnel
  );

  const {
    isLoading,
    data: vdaFunnelData,
    isError
  } = useSelector((state) => state?.funnel?.vdaFunnel);

  const dispatch = useDispatch();

  useEffect(() => {
    Object.keys(VDA_FUNNEL_STATES).map((funnelState) => {
      dispatch(getVdaTransactions({ status: funnelState, page, pageSize }));
    });
  }, []);

  useEffect(() => {
    dispatch(getVdaTransactions({ status, page, pageSize, search }));
  }, [page, pageSize, status, search]);

  const handlePageChange = (val) => {
    dispatch(
      updateVdaFunnelReqData({
        pageSize,
        status,
        page: val
      })
    );
  };

  const handleFunnelChange = (funnel) => {
    dispatch(
      updateVdaFunnelReqData({
        page,
        pageSize,
        status: funnel
      })
    );
  };

  return (
    <ContentLayout>
      <Grid gridTemplateColumns={{ base: '1fr', lg: '7fr 0fr 2fr' }}>
        <VStack
          gap={'30px'}
          overflowY={{ base: 'initial', lg: 'scroll' }}
          overflowX={'hidden'}
          h={{ lg: '90vh' }}
          py={10}
          px={{ base: 4, sm: 10 }}
          alignItems={'stretch'}
          className="scroll"
        >
          <Heading size={'lg'} color={'white'}>
            VDA Dashboard
          </Heading>
          <VdaStats />
          <Divider color={'whiteAlpha.400'} />
          <FunnelBar
            activeFunnel={status}
            setActiveFunnel={(funnel) => handleFunnelChange(funnel)}
            funnelData={Object.keys(VDA_FUNNEL_STATES).reduce((prev, curr) => {
              return {
                ...prev,
                [curr]: vdaFunnelData[curr]?.totalResults || 0
              };
            }, {})}
          />
          <VdaOrderSearch />
          {isLoading && <Spinner color="white" />}
          {isLoading ? (
            <Spinner />
          ) : vdaFunnelData[status]?.data?.length > 0 ? (
            <>
              <HStack width={'full'} justifyContent={'space-between'}>
                <Pagination
                  currentPage={page}
                  totalPages={vdaFunnelData[status]?.totalPages}
                  onPageChange={(val) => handlePageChange(val)}
                />
              </HStack>
              <VStack width={'full'} alignItems={'stretch'} gap={3}>
                {vdaFunnelData[status]?.data?.map((data) => (
                  <VdaDataCompomet
                    key={`${status}-${data?.orderId}`}
                    vdaData={data}
                    funnelStatus={status}
                  />
                ))}
              </VStack>
            </>
          ) : (
            <>
              <Text fontSize={'md'} color={'whiteAlpha.800'}>
                {'No Transactions found :('}
              </Text>
            </>
          )}
        </VStack>
      </Grid>
    </ContentLayout>
  );
};

export default VdaDashboard;

import {
  HStack,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Select,
  Button,
  FormLabel,
  Box,
  Text,
  VStack
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useState } from 'react';
import { slotStringBuilder } from '../../store/reducers/utils';

const ScheduleSlotModal = ({
  initialState = null,
  availableSlots,
  onSubmit,
  title = 'Schedule'
}) => {
  const [selectedPickupslot, setSelectedPickupslot] = useState({
    date: dayjs(initialState?.startTime).format('DD-MM-YYYY') || '',
    slot:
      slotStringBuilder(initialState?.startTime, initialState?.endTime) || ''
  });

  const handleDateChange = (e) => {
    const date = e?.target?.value;
    setSelectedPickupslot({ date, slot: '' });
  };

  const handleSlotChange = (e) => {
    setSelectedPickupslot((prev) => ({
      ...prev,
      slot: e?.target?.value
    }));
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    const { date, slot: slotStr } = selectedPickupslot;
    console.log(selectedPickupslot);
    console.log(
      availableSlots[date].reduce((prev, curr) => {
        const currSlotStr = slotStringBuilder(curr?.startTime, curr?.endTime);
        console.log(currSlotStr, slotStr);
        if (currSlotStr === slotStr)
          return { startTime: curr?.startTime, endTime: curr?.endTime };
        return prev;
      }, {})
    );
    const { startTime = null, endTime = null } = availableSlots[date].reduce(
      (prev, curr) => {
        const currSlotStr = slotStringBuilder(curr?.startTime, curr?.endTime);
        if (currSlotStr === slotStr)
          return { startTime: curr?.startTime, endTime: curr?.endTime };
        return prev;
      },
      {}
    );
    console.log(startTime, endTime);
    startTime && endTime && onSubmit({ startTime, endTime });
  };

  return (
    <>
      <ModalHeader>
        <Text color="white">{title}</Text>
      </ModalHeader>
      <form onSubmit={handleSubmit}>
        <ModalBody>
          <VStack width={'full'} alignItems={'start'} gap={2}>
            <Box width={'full'}>
              <FormLabel htmlFor="date">Date</FormLabel>
              <Select
                name="date"
                value={selectedPickupslot?.date}
                onChange={handleDateChange}
                disabled
              >
                {Object.keys(availableSlots).map((slotDate) => {
                  return (
                    <option
                      selected={selectedPickupslot?.date}
                      key={slotDate}
                      value={slotDate}
                    >
                      {slotDate}
                    </option>
                  );
                })}
              </Select>
            </Box>
            {selectedPickupslot?.date && (
              <Box width={'full'}>
                <FormLabel htmlFor="slot">Slot</FormLabel>
                <Select
                  name="slot"
                  value={selectedPickupslot?.slot}
                  onChange={handleSlotChange}
                  disabled
                >
                  {availableSlots[selectedPickupslot?.date]?.map((slot) => (
                    <option
                      selected={
                        slotStringBuilder(slot?.startTime, slot?.endTime) ===
                        selectedPickupslot?.slot
                      }
                      key={slotStringBuilder(slot?.startTime, slot?.endTime)}
                      value={slotStringBuilder(slot?.startTime, slot?.endTime)}
                    >
                      {slotStringBuilder(slot?.startTime, slot?.endTime)}
                    </option>
                  ))}
                </Select>
              </Box>
            )}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <HStack alignItems={'end'}>
            <Button colorScheme="brand" variant={'outline'} type="submit">
              Schedule
            </Button>
          </HStack>
        </ModalFooter>
      </form>
    </>
  );
};

export default ScheduleSlotModal;

import {
  Box,
  Button,
  FormLabel,
  HStack,
  Input,
  ModalBody,
  ModalFooter,
  ModalHeader,
  VStack
} from '@chakra-ui/react';
import { camelCaseToSpaceSeparated } from '../../utils';
import React, { useEffect, useState } from 'react';

const FormComponent = ({ parentKey = '', formData, setSource }) => {
  const modifyValue = (obj, keys, ind, value) => {
    if (ind === keys.length) {
      if (Array.isArray(obj)) return [value];
      return value;
    }
    const currKey = keys[ind];
    return {
      ...obj,
      [currKey]: modifyValue(obj[currKey], keys, ind + 1, value)
    };
  };

  const handleInputChange = (e) => {
    const value = e?.target?.value;
    const keys = (e?.target?.name).split('.');
    setSource((prev) => {
      return modifyValue({ ...prev }, keys, 0, value);
    });
  };

  if (!formData) return;

  return (
    <VStack alignItems={'stretch'} gap={2}>
      {Object.keys(formData).map((key) => {
        if (key === 'documentType') return null;

        if (formData[key] === null || typeof formData[key] !== 'object') {
          return (
            <Box key={parentKey + key}>
              <FormLabel>{camelCaseToSpaceSeparated(key)}</FormLabel>
              <Input
                name={parentKey + key}
                onChange={handleInputChange}
                value={formData[key] || ''}
              />
            </Box>
          );
        } else if (Array.isArray(formData[key])) {
          return (
            <Box key={parentKey + key}>
              <FormLabel>{key}</FormLabel>
              <VStack alignItems={'stretch'} gap={1} width={'full'}>
                {formData[key]?.map((value, ind) => (
                  <Input
                    name={parentKey + key}
                    value={value}
                    onChange={handleInputChange}
                  />
                ))}
              </VStack>
            </Box>
          );
        } else {
          return (
            <FormComponent
              key={parentKey}
              formData={formData[key]}
              parentKey={parentKey + key + '.'}
              setSource={setSource}
            />
          );
        }
      })}
    </VStack>
  );
};

const EditOcrModal = ({
  ocrData,
  onSubmit,
  onCancel,
  subDocumentType = 'sub-doc-type'
}) => {
  const [formData, setFormData] = useState({
    ...ocrData[subDocumentType]
  });

  useEffect(() => {
    if (!subDocumentType) return;
    setFormData(ocrData[subDocumentType]);
  }, [subDocumentType]);

  const setOcrFormData = (callback) => {
    setFormData((prev) => {
      const prevOcr = prev?.ocrData;
      return {
        ...prev,
        ocrData: callback(prevOcr)
      };
    });
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    onSubmit && onSubmit(formData);
  };

  if (!subDocumentType) return null;

  return (
    <>
      <ModalHeader>Edit OCR - {subDocumentType}</ModalHeader>
      <form onSubmit={handleSubmit}>
        <ModalBody>
          <Box px={1} py={2} maxHeight={96} overflow={'scroll'}>
            <FormComponent
              formData={formData?.ocrData}
              setSource={setOcrFormData}
            />
          </Box>
        </ModalBody>
        <ModalFooter>
          <HStack width={'full'} justifyContent={'end'} gap={1}>
            <Button
              type="button"
              onClick={onCancel}
              variant={'outline'}
              colorScheme="red"
            >
              Cancel
            </Button>
            <Button type="submit" variant={'outline'} colorScheme="brand">
              Update
            </Button>
          </HStack>
        </ModalFooter>
      </form>
    </>
  );
};

export default EditOcrModal;

import api from '../../api';
import { CONFIG_COUNTRIES } from '../../constants';
import {
  getErrorMessage,
  configCountryCodeLookup,
  transformCountryCodesForConfig
} from '../../utils';
import { CONFIG as types } from '../constants';
import { setAlert } from './alertActions';

export const getSettlementMessage = (countries) => async (dispatch) => {
  try {
    dispatch({
      type: types.GETTING_SETTLEMENT_MESSAGE
    });

    const settlementMessages = {};
    await Promise.all(
      transformCountryCodesForConfig(countries).map(async (item) => {
        const { country: countryCode, provider } = item;
        const { data } = await api.config.getSettlementMessage({
          countryCode,
          provider
        });
        if (!provider) {
          settlementMessages[countryCode] = {
            settlementMessage: data?.settlementMessage,
            isDelayed: data?.isDelayed
          };
        } else {
          settlementMessages[`${countryCode}_${provider}`] = {
            settlementMessage: data?.settlementMessage,
            isDelayed: data?.isDelayed
          };
        }
      })
    );

    return dispatch({
      type: types.GET_SETTLEMENT_MESSAGE_SUCCESS,
      payload: settlementMessages
    });
  } catch (error) {
    dispatch(
      setAlert({
        title: getErrorMessage({
          error,
          genericMessage: 'Failed to get Settlement messages'
        }),
        status: 'error'
      })
    );
    return dispatch({
      type: types.GET_SETTLEMENT_MESSAGE_FAILURE,
      payload: error
    });
  }
};

export const getFundBenchmarkReturns = () => async (dispatch) => {
  try {
    dispatch({
      type: types.GETTING_FUND_BENCHMARK_RETURNS
    });
    const { data } = await api.config.getFundBenchmarkReturns();

    return dispatch({
      type: types.GET_FUND_BENCHMARK_RETURNS_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch(
      setAlert({
        title: getErrorMessage({
          error,
          genericMessage: 'Failed to get benchmark returns'
        }),
        status: 'error'
      })
    );
    return dispatch({
      type: types.GET_FUND_BENCHMARK_RETURNS_FAILURE,
      payload: error
    });
  }
};

export const updateNiftyReturns =
  ({ niftyReturns, onSuccess, onFinish }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: types.UPDATING_NIFTY_RETURNS
      });
      await api.config.updateNiftyReturns(niftyReturns);
      if (onSuccess) onSuccess();

      dispatch(getFundBenchmarkReturns());
      dispatch(
        setAlert({
          title: 'Updated Nifty returns!',
          status: 'success'
        })
      );

      return dispatch({
        type: types.UPDATE_NIFTY_RETURNS_SUCCESS
      });
    } catch (error) {
      dispatch(
        setAlert({
          title: getErrorMessage({
            error,
            genericMessage: 'Failed to update nifty returns'
          }),
          status: 'error'
        })
      );
      return dispatch({
        type: types.UPDATE_NIFTY_RETURNS_FAILURE,
        payload: error
      });
    } finally {
      if (onFinish) onFinish();
    }
  };

export const updateFdReturns =
  ({ fdReturns, onSuccess, onFinish }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: types.UPDATING_NIFTY_RETURNS
      });
      await api.config.updateFdReturns(fdReturns);
      if (onSuccess) onSuccess();

      dispatch(getFundBenchmarkReturns());
      dispatch(
        setAlert({
          title: 'Updated FD returns!',
          status: 'success'
        })
      );

      return dispatch({
        type: types.UPDATE_FD_RETURNS_SUCCESS
      });
    } catch (error) {
      dispatch(
        setAlert({
          title: getErrorMessage({
            error,
            genericMessage: 'Failed to update FD returns'
          }),
          status: 'error'
        })
      );
      return dispatch({
        type: types.UPDATE_FD_RETURNS_FAILURE,
        payload: error
      });
    } finally {
      if (onFinish) onFinish();
    }
  };

export const getPollingStatus = () => async (dispatch) => {
  try {
    dispatch({
      type: types.GETTING_POLLING_STATUS
    });
    const { data } = await api.config.getPollingStatus();

    return dispatch({
      type: types.GET_POLLING_STATUS_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch(
      setAlert({
        title: getErrorMessage({
          error,
          genericMessage: 'Failed to get polling status'
        }),
        status: 'error'
      })
    );
    return dispatch({
      type: types.GET_POLLING_STATUS_FAILURE,
      payload: error
    });
  }
};

export const updateSettlementMessage =
  ({
    isDelayed,
    countryCode,
    settlementMessage,
    onSuccess,
    onFinish,
    provider
  }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: types.UPDATING_SETTLEMENT_MESSAGE
      });

      const { code, provider } = configCountryCodeLookup(countryCode);

      await api.config.updateSettlementMessage({
        isDelayed,
        countryCode: code,
        settlementMessage,
        provider
      });
      if (onSuccess) onSuccess();

      dispatch(getSettlementMessage(CONFIG_COUNTRIES));
      dispatch(
        setAlert({
          title: 'Updated settlement message for ' + countryCode,
          status: 'success'
        })
      );

      return dispatch({
        type: types.UPDATE_SETTLEMENT_MESSAGE_SUCCESS
      });
    } catch (error) {
      dispatch(
        setAlert({
          title: getErrorMessage({
            error,
            genericMessage:
              'Failed to update settlement message for' + countryCode
          }),
          status: 'error'
        })
      );
      return dispatch({
        type: types.UPDATE_SETTLEMENT_MESSAGE_FAILURE,
        payload: error
      });
    } finally {
      if (onFinish) onFinish();
    }
  };

export const updatePollingStatus =
  ({ pollingStatus, onSuccess, onFinish }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: types.UPDATING_POLLING_STATUS
      });
      await api.config.updatePollingStatus({
        pollingStatus
      });
      if (onSuccess) onSuccess();

      dispatch(getPollingStatus());
      dispatch(
        setAlert({
          title: 'Updated polling status!',
          status: 'success'
        })
      );

      return dispatch({
        type: types.UPDATE_POLLING_STATUS_SUCCESS
      });
    } catch (error) {
      dispatch(
        setAlert({
          title: getErrorMessage({
            error,
            genericMessage: 'Failed to update polling status'
          }),
          status: 'error'
        })
      );
      return dispatch({
        type: types.UPDATE_POLLING_STATUS_FAILURE,
        payload: error
      });
    } finally {
      if (onFinish) onFinish();
    }
  };

export const getConfigList = () => async (dispatch) => {
  try {
    dispatch({
      type: types.GETTING_CONFIG_LIST
    });
    const { data } = await api.config.getConfigList();
    dispatch({
      type: types.GET_CONFIG_LIST_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch(
      setAlert({
        title: error?.message,
        status: 'error'
      })
    );
    dispatch({
      type: types.GET_CONFIG_LIST_ERROR,
      payload: {
        errMsg: error?.message
      }
    });
  }
};

export const updateConfigItems =
  ({ updatedFields, onSuccess, onComplete, onError }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: types.UPDATING_CONFIG_LIST_ITEM
      });
      const fieldsToUpdate = Object.keys(updatedFields).map((key) => ({
        configType: key,
        value: updatedFields[key]
      }));

      const promises = fieldsToUpdate.map((field) => {
        const { value, configType } = field;
        return api.config.updateConfigItem({ value, configType });
      });

      const responses = await Promise.all(promises);
      const data = responses?.map((response) => response?.data);

      dispatch({
        type: types?.UPDATE_CONFIG_LIST_ITEM_SUCCESS
      });

      onSuccess && onSuccess();
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
      onError && onError();
      dispatch({
        type: types.UPDATE_CONFIG_LIST_ITEM_FAILURE,
        payload: {
          errMsg: error?.message
        }
      });
    } finally {
      onComplete && onComplete();
    }
  };

export const getReconFeatureFlagValue =
  ({ featureFlag, onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      // make an API request over here
      const { data } = await api.reconDashboard.getFeatureFlagValue({
        featureFlag
      });
      onSuccess && onSuccess(data);
    } catch (error) {
      dispatch(
        setAlert({
          title: error.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const updateReconFeatureFlagValue =
  ({
    featureFlagName: featureName,
    featureFlagValue: featureValue,
    onSuccess,
    onComplete
  }) =>
  async (dispatch) => {
    try {
      await api.reconDashboard.updateFeatureFlagValue({
        featureName,
        featureValue
      });

      onSuccess && onSuccess();
    } catch (error) {
      console.error(error.message);
      dispatch(
        setAlert({
          title: error.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const withdrawEvent =
  ({ eventId, withdrawAmt, provider, onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      console.log({
        eventId,
        withdrawAmt,
        provider
      });
      await api.reconDashboard.withdrawEvent({
        eventId,
        withdrawAmt,
        provider
      });
      onSuccess && onSuccess();
    } catch (error) {
      console.error(error.message);
      dispatch(
        setAlert({
          title: error.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const getIsFxRatePeaking =
  ({ currency, onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      const { data } = await api.fxRatePeakingConfig.getIsFxRatePeaking(
        currency
      );
      onSuccess && onSuccess(data?.isRatePeaked);
      return data?.isRatePeaked;
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
      return null;
    } finally {
      onComplete && onComplete();
      return null;
    }
  };

export const putPollingStatus =
  ({ currency, isEnabled, onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      await api.fxRatePeakingConfig.putPollingStatus({
        currency,
        isEnabled
      });
      onSuccess && onSuccess();
    } catch (error) {
      console.log(error.message);
      dispatch(
        setAlert({
          title: error.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const getDowntimeConfig =
  ({ onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      const { data } = await api.downtimeConfig.getDowntimeConfig();
      onSuccess && onSuccess(data);
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const getTat =
  ({ onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      const { data } = await api.config.getTurnAroundTime();
      onSuccess && onSuccess(data);
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const updateDowntime =
  ({
    paymentAcquirer,
    downtimeStartDate,
    downtimeEndDate,
    downtimeReason,
    onSuccess,
    onComplete
  }) =>
  async (dispatch) => {
    try {
      await api.downtimeConfig.updateAcquirerDowntime({
        paymentAcquirer,
        downtimeEndDate,
        downtimeStartDate,
        downtimeReason
      });
      onSuccess && onSuccess();
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const updateTurnAroundTime =
  ({ tat, onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      await api.config.updateTurnAroundTime({
        acquirer: tat?.type,
        transferTatDuration: tat?.value
      });
      onSuccess && onSuccess();
    } catch (error) {
      dispatch(
        setAlert({
          title: error.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const getFeatureFlagValue =
  ({ featureName, onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      const { data } = await api.featureFlags.getFeatureFlagValue({
        featureName
      });
      onSuccess && onSuccess(data);
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const updateFeatureFlagValue =
  ({ featureName, featureValue, onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      await api.featureFlags.updateFeatureFlagValue({
        featureName,
        featureValue
      });
      onSuccess && onSuccess();
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

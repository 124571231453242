import { useParams } from 'react-router-dom';
import ContentLayout from '../../layouts/ContentLayout';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getYblDetails,
  patchYblDetails,
  resetYblState,
  updateYblField
} from '../../store/actions/yblDetailsActions';
import { Grid, useDisclosure } from '@chakra-ui/react';
import { getPatchData } from '../../components/Ftnr/utils';
import FullPageSpinner from '../../components/FullPageSpinner';
import api from '../../api';
import {
  getNrOnboardDetails,
  reinitiateUser,
  retriggerYblApis
} from '../../store/actions/nronboardActions';
import FtnrFormComponent from '../../components/Ftnr/FormComponent';
import ModalLayout from './../../components/Modal/ModalLayout';
import FtnrVerifyUpdateModal from '../../components/Ftnr/VerifyUpdateModal';
import ConfirmModal from '../../components/Modal/ConfirmForceInitiate';

const FtnrForm = () => {
  const { userId } = useParams();
  const dispatch = useDispatch();
  const {
    data: yblDetails,
    prevState: yblSource,
    isLoading = null
  } = useSelector((state) => state?.yblDetails);

  const {
    isOpen: isUpdateFieldsOpen,
    onClose: onUpdateFieldsClose,
    onOpen: onUpdateFieldsOpen
  } = useDisclosure();

  const {
    isOpen: isTriggerOpen,
    onClose: onTriggerClose,
    onOpen: onTriggerOpen
  } = useDisclosure();

  const {
    isOpen: isReintiateOpen,
    onClose: onReintiateClose,
    onOpen: onReintiateOpen
  } = useDisclosure();

  const [btnsLoading, setBtnsLoading] = useState(false);

  useEffect(() => {
    if (!userId) return;
    dispatch(getYblDetails(userId));
  }, [userId, dispatch]);

  const handleChange = (key, value) => {
    dispatch(updateYblField(key, value));
  };

  const handleUpdateBtnClick = () => {
    const patchData = getPatchData(yblSource, yblDetails);
    if (Object.keys(patchData).length > 0) {
      onUpdateFieldsOpen();
    }
  };

  const handleUpdateClick = (e, discard = false) => {
    if (!discard) {
      const patchData = getPatchData(yblSource, yblDetails);
      setBtnsLoading(true);
      dispatch(
        patchYblDetails({
          userId,
          modifiedFields: patchData,
          onSuccess: () => {
            dispatch(getYblDetails(userId));
          },
          onComplete: () => {
            setBtnsLoading(false);
            onUpdateFieldsClose();
          }
        })
      );
      return;
    }
    dispatch(resetYblState());
    onUpdateFieldsClose();
  };

  const handleTriggerClick = (e) => {
    setBtnsLoading(true);
    dispatch(
      retriggerYblApis({
        userId,
        onSuccess: () => {
          dispatch(getNrOnboardDetails(userId));
        },
        onComplete: () => {
          setBtnsLoading(false);
          onTriggerClose();
          dispatch(getYblDetails(userId));
        }
      })
    );
  };

  const handleReinitiateClick = (e) => {
    setBtnsLoading(true);
    dispatch(
      reinitiateUser({
        userId,
        onSuccess: () => {
          dispatch(getYblDetails(userId));
        },
        onComplete: () => {
          setBtnsLoading(false);
          onReintiateClose();
        }
      })
    );
  };

  const handleViewFileClick = (docUrl) => {
    api.workflowsV2
      .getDocByUrl({ userId, docUrl })
      .then(({ data }) => window.open(data?.url, '_blank'))
      .catch((err) => console.log(err));
  };

  if (isLoading) return <FullPageSpinner />;

  return (
    <>
      <ModalLayout isOpen={isReintiateOpen} onClose={onReintiateClose}>
        <ConfirmModal
          prompt={'Are you sure you want Reintiate ?'}
          handleSubmit={() => {
            handleReinitiateClick();
          }}
          isLoading={btnsLoading}
        />
      </ModalLayout>
      <ModalLayout isOpen={isTriggerOpen} onClose={onTriggerClose}>
        <ConfirmModal
          prompt={
            !btnsLoading
              ? 'Are you sure you want retrigger ?'
              : 'This API would take around 2-3 minutes. Please be patient.'
          }
          handleSubmit={() => {
            handleTriggerClick();
          }}
          isLoading={btnsLoading}
        />
      </ModalLayout>
      <ModalLayout
        size="2xl"
        isOpen={isUpdateFieldsOpen}
        onClose={onUpdateFieldsClose}
      >
        <FtnrVerifyUpdateModal
          prevState={yblSource}
          updatedFields={getPatchData(yblSource, yblDetails)}
          handleDiscard={(e) => handleUpdateClick(e, true)}
          handleUpdate={(e) => handleUpdateClick(e, false)}
          isLoading={btnsLoading}
        />
      </ModalLayout>
      <ContentLayout>
        <Grid padding={'20px 60px'} gridTemplateColumns={'1fr'}>
          <FtnrFormComponent
            updateBtnDisabled={
              Object.keys(getPatchData(yblSource, yblDetails)).length === 0
            }
            handleUpdateClick={handleUpdateBtnClick}
            handleTriggerClick={() => {
              onTriggerOpen();
            }}
            handleReinitiateClick={() => {
              onReintiateOpen();
            }}
            formData={yblDetails}
            handleChange={handleChange}
            handleViewFileClick={handleViewFileClick}
          />
        </Grid>
      </ContentLayout>
    </>
  );
};

export default FtnrForm;

import { AuthenticatedApp } from './AuthenticatedApp';
import { UnauthenticatedApp } from './UnauthenticatedApp';
import FullPageSpinner from './components/FullPageSpinner';
import React, { useEffect } from 'react';
import './App.css';
import { useSelector, useDispatch } from 'react-redux';
import { loginFromLocalStorage } from './store/actions/authActions';
import { useToast } from '@chakra-ui/react';
import { clearAlert } from './store/actions/alertActions';
import { initializeDeviceId } from './utils';

function App() {
  const dispatch = useDispatch();
  const toast = useToast();
  const { user, isLoading, isError } = useSelector((state) => state.auth);
  const { alert } = useSelector((state) => state);

  useEffect(() => {
    initializeDeviceId();
    dispatch(loginFromLocalStorage());
  }, []);

  useEffect(() => {
    if (!alert.isActive) return;

    toast(alert.data);

    setTimeout(() => {
      dispatch(clearAlert());
    }, alert.data?.duration);
  }, [alert]);

  if (isLoading) return <FullPageSpinner />;
  return user ? <AuthenticatedApp /> : <UnauthenticatedApp />;
}

export { App };

import { Box, Grid, VStack, Divider, Heading, HStack } from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UsersList from '../../components/UsersList';
import useIsMobile from '../../hooks/useIsMobile';
import ContentLayout from '../../layouts/ContentLayout';
import { getFunnelkycUsers } from '../../store/actions/funnelActions';
import {
  updateActiveKycFunnel,
  updateFunnelKycUsersReqData
} from '../../store/actions/reqDataActions';
import FunnelBar from './FunnelBar';
import { formatFunnelStatus } from './utils';
import useDidMount from '../../hooks/useDidMount';
import { ALL_FUNNEL_KYC_STATUS } from '../../constants';

const KycFunnel = () => {
  const dispatch = useDispatch();
  const [activeFunnel, setActiveFunnel] = useState(
    useSelector((state) => state.reqData.funnel.kyc.activeFunnel)
  );
  const { isLoading: isAllDataLoading } = useSelector((state) => state.funnel);

  const users = useSelector((state) => state.funnel.kyc.users);
  const funnelData = useSelector((state) => state.funnel.kyc.funnelData);
  const isMobile = useIsMobile();
  const kycUsersReqData = useSelector(
    (state) => state.reqData.funnel.kyc.users
  );

  useDidMount(() => {
    dispatch(updateActiveKycFunnel(activeFunnel));
  }, [dispatch, activeFunnel]);

  const activePage = kycUsersReqData[activeFunnel].pageNo;

  useDidMount(() => {
    if (users[activeFunnel]?.pages[activePage]?.isCached) return;

    searchFunnelUsers();
  }, [activePage]);

  useEffect(() => {
    getAllFunnelUsers();
  }, []);

  const getAllFunnelUsers = useCallback(() => {
    ALL_FUNNEL_KYC_STATUS.forEach((status) => {
      if (!users[status]?.pages[activePage]?.isCached)
        dispatch(getFunnelkycUsers({ status }));
    });
  }, [activePage, users, dispatch]);

  const searchFunnelUsers = useCallback(
    (refresh = false) => {
      if (refresh || !users[activeFunnel].pages[activePage]?.data?.length) {
        dispatch(
          getFunnelkycUsers({
            status: activeFunnel,
            pageNo: activePage,
            pageSize: kycUsersReqData[activeFunnel].pageSize
          })
        );
      }
    },
    [activeFunnel, activePage, users, kycUsersReqData, dispatch]
  );

  const handlePageChange = useCallback(
    (pageNo) => {
      pageNo - 1 !== activePage &&
        dispatch(
          updateFunnelKycUsersReqData({
            status: activeFunnel,
            pageNo: pageNo - 1
          })
        );
    },
    [activeFunnel, activePage, dispatch]
  );

  return (
    <ContentLayout>
      <Grid gridTemplateColumns={{ base: '1fr', lg: '7fr 0fr 2fr' }}>
        <VStack
          gap={4}
          overflowY={{ base: 'initial', lg: 'scroll' }}
          overflowX={'hidden'}
          h={{ lg: '90vh' }}
          py={10}
          px={{ base: 4, sm: 10 }}
          alignItems={'stretch'}
          className="scroll"
        >
          <FunnelBar
            setActiveFunnel={(funnel) => setActiveFunnel(funnel)}
            activeFunnel={activeFunnel}
            funnelData={funnelData}
            isListLoading={users[activeFunnel].isLoading || isAllDataLoading}
          />
          <HStack justifyContent={'space-between'} gap={4}>
            <Heading
              color={'white'}
              fontSize={'1.5rem'}
              fontWeight={500}
              pt={3}
            >
              {formatFunnelStatus(activeFunnel)}
            </Heading>
          </HStack>
          <Divider style={{ margin: '0' }} borderColor="whiteAlpha.300" />
          <UsersList
            users={users[activeFunnel]?.pages[activePage]?.data}
            pageNo={activePage}
            totalPages={users[activeFunnel].totalPages}
            handlePageChange={handlePageChange}
          />
        </VStack>

        <Divider
          style={{ margin: '0' }}
          borderColor="whiteAlpha.300"
          orientation="vertical"
        />

        {!isMobile && (
          <Box m={0} alignSelf={'flex-start'}>
            {/* {selectedOrder && (
              <OrderDetailsCard
                userId={selectedOrder.userId}
                orderId={selectedOrder.orderId}
                isSidebar
                showNavLink
              />
            )} */}
          </Box>
        )}
      </Grid>
    </ContentLayout>
  );
};

export default KycFunnel;

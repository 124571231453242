import {
  Button,
  HStack,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Select
} from '@chakra-ui/react';
import { BENEFICIARY_RISK_REQUEST } from '../../constants';
import { useState } from 'react';

const BeneficiaryChecksModal = ({ isLoading, handleCancel, handleSubmit }) => {
  const [riskCategory, setRiskCategory] = useState('');

  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleSubmit &&
      handleSubmit({
        riskCategory
      });
  };

  return (
    <>
      <ModalHeader>Transfer Checks</ModalHeader>
      <form onSubmit={handleFormSubmit}>
        <ModalBody>
          <Select
            placeholder="Select Risk Category"
            required
            value={riskCategory}
            onChange={(e) => {
              setRiskCategory(e?.target?.value);
            }}
          >
            {Object.keys(BENEFICIARY_RISK_REQUEST).map((key) => (
              <option value={key} key={key}>
                {key}
              </option>
            ))}
          </Select>
        </ModalBody>
        <ModalFooter>
          <HStack justifyContent={'end'} gap={2} alignItems={'center'}>
            <Button
              isLoading={isLoading}
              type="button"
              onClick={handleCancel}
              variant={'outline'}
            >
              Cancel
            </Button>
            <Button
              isLoading={isLoading}
              type="submit"
              variant={'outline'}
              colorScheme="brand"
            >
              Submit
            </Button>
          </HStack>
        </ModalFooter>
      </form>
    </>
  );
};

export default BeneficiaryChecksModal;

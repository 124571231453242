import '@fontsource/dm-sans';

const brandTheme = {
  colors: {
    white: {
      10: '#10FFFFFF'
    },
    brand: {
      50: '#E9FCF0',
      100: '#C1F5D6',
      200: '#9AEFBC',
      300: '#72E9A1',
      400: '#4BE287',
      500: '#81EBAB',
      600: '#1CB057',
      700: '#158441',
      800: '#0E582B',
      900: '#072C16'
    },
    altGray: {
      100: '#5C5C5B',
      200: '#3D3D3B',
      300: '#1C1C1C',
      400: '#1d1d1b',
      500: '#0E0E0E',
      600: '#000000'
    },
    shadedGray: {
      50: '#F1F2F3',
      100: '#D9DBDE',
      200: '#C1C3C8',
      300: '#A8ACB3',
      400: '#90949D',
      500: '#777D88',
      600: '#60646C',
      700: '#484B51',
      800: '#303236',
      900: '#18191B'
    },
    backGround: '#1C1C1C',
    colorPrimary: '#81EBAB',
    purple: {
      100: '#B6A7FA'
    },
    cardColor: {
      100: 'rgba(255, 255, 255, 0.1)',
      500: 'rgba(255, 255, 255, 0.5)'
    }
  },
  fonts: {
    heading: 'DM Sans',
    body: 'DM Sans'
  }
};

export default brandTheme;

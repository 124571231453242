export default function nrApi(fetch, baseUrl) {
  return {
    holidays({ pageNo = 0, pageSize = 10 }) {
      return fetch(`${baseUrl}?page_size=${pageSize}&page=${pageNo}`);
    },

    createHoliday(formData) {
      return fetch.post(`${baseUrl}`, formData);
    }
  };
}

const KYC = {
  GETTING_JOBS: 'GETTING_JOBS',
  GET_JOBS_SUCCESS: 'GET_JOBS_SUCCESS',
  GET_JOBS_FAILURE: 'GET_JOBS_FAILURE',
  CLEAR_JOBS: 'CLEAR_KYC_DOCS',

  UPDATING_JOBS_STATUS: 'UPDATING_JOBS_STATUS',
  UPDATE_JOBS_STATUS_SUCCESS: 'UPDATE_JOBS_STATUS_SUCCESS',
  UPDATE_JOBS_STATUS_FAILURE: 'UPDATE_JOBS_STATUS_FAILURE'
};

export default KYC;

import { useEffect, useRef, useState } from 'react';

const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState('');
  const timeRef = useRef(null);

  useEffect(() => {
    clearTimeout(timeRef.current);
    timeRef.current = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(timeRef.current);
    };
  }, [value, delay]);

  return debouncedValue;
};

export default useDebounce;

import { MANUAL_PAYOUT_BATCH } from '../constants';
import api from '../../api';
import { setAlert } from './alertActions';

export const fetchBatchSheets = (payoutPartner) => async (dispatch) => {
  try {
    dispatch({ type: MANUAL_PAYOUT_BATCH.FETCH_SHEETS_REQUEST });
    const { data } = await api.manualPayoutBatch.getSheets(payoutPartner);
    dispatch({ type: MANUAL_PAYOUT_BATCH.FETCH_SHEETS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: MANUAL_PAYOUT_BATCH.FETCH_SHEETS_FAILURE, payload: error.message });
    dispatch(setAlert({ title: 'Failed to fetch payout batches', status: 'error' }));
  }
};

export const downloadBatchSheet = (batchId) => async (dispatch) => {
  try {
    dispatch({ type: MANUAL_PAYOUT_BATCH.DOWNLOAD_SHEET_REQUEST });
    
    const response = await api.manualPayoutBatch.downloadSheet(batchId, { responseType: 'blob' });

    const url = window.URL.createObjectURL(response.data);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `batch_${batchId}.xlsx`); // Set file extension to .xlsx
    document.body.appendChild(link);
    link.click();
    link.remove();
    
    dispatch({ type: MANUAL_PAYOUT_BATCH.DOWNLOAD_SHEET_SUCCESS });
    dispatch(setAlert({ title: 'Batch sheet downloaded successfully', status: 'success' }));
    
    window.URL.revokeObjectURL(url); // Clean up the object URL

  } catch (error) {
    console.error('Download error:', error);
    dispatch({ type: MANUAL_PAYOUT_BATCH.DOWNLOAD_SHEET_FAILURE, payload: error.message });
    dispatch(setAlert({ title: 'Failed to download batch details', status: 'error' }));
  }
};

export const createBatch = (payload) => async (dispatch) => {
  try {
    dispatch({ type: MANUAL_PAYOUT_BATCH.CREATE_BATCH_REQUEST });
        
    await api.manualPayoutBatch.createBatch(payload);
    dispatch(setAlert({ title: `Batch created successfully`, status: 'success' }));
    dispatch(fetchBatchSheets());
  } catch (error) {
    dispatch({ type: MANUAL_PAYOUT_BATCH.CREATE_BATCH_FAILURE, payload: error.message });
    dispatch(setAlert({ title: `Failed to create new batch: ${error.message}`, status: 'error' }));
  }
  setTimeout(() => {
    window.location.reload();
  }, 2000);
};

export const uploadBatchSheet = (batchId, file) => async (dispatch) => {
  try {
    dispatch({ type: MANUAL_PAYOUT_BATCH.UPLOAD_SHEET_REQUEST, payload: batchId });
    const response = await api.manualPayoutBatch.uploadSheet(batchId, file);
    dispatch({ type: MANUAL_PAYOUT_BATCH.UPLOAD_SHEET_SUCCESS });
    dispatch(setAlert({ title: response.data.message, status: 'success' }));
    setTimeout(() => {
      dispatch(fetchBatchSheets());
    }, 3000);
  } catch (error) {
    dispatch({ type: MANUAL_PAYOUT_BATCH.UPLOAD_SHEET_FAILURE, payload: error.message });
    dispatch(setAlert({ title: error.message || 'Failed to upload CSV', status: 'error' }));
  }
  dispatch(setAlert({ title: `Updating your sheet`, status: 'warning' }));
  setTimeout(() => {
    window.location.reload();
  }, 2000);
};

export const fetchBatchDetails = (batchId) => async (dispatch) => {
  try {
    dispatch({ type: MANUAL_PAYOUT_BATCH.FETCH_BATCH_DETAILS_REQUEST });
    const { data } = await api.manualPayoutBatch.getBatchDetails(batchId);
    console.log('API Response:', data); // Log the response
    dispatch({ type: MANUAL_PAYOUT_BATCH.FETCH_BATCH_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: MANUAL_PAYOUT_BATCH.FETCH_BATCH_DETAILS_FAILURE, payload: error.message });
    dispatch(setAlert({ title: 'Failed to fetch batch details', status: 'error' }));
  }
};


export const createPending = (payoutPartner) => async (dispatch) => {
  try {
    dispatch({ type: MANUAL_PAYOUT_BATCH.FETCH_PENDING_DETAILS_REQUEST });
    const { data } = await api.manualPayoutBatch.getPendingSheets(payoutPartner);
    console.log(data);
    dispatch({ type: MANUAL_PAYOUT_BATCH.FETCH_PENDING_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: MANUAL_PAYOUT_BATCH.FETCH_PENDING_DETAILS_FAILURE, payload: error.message });
    dispatch(setAlert({ title: 'Failed to fetch payout batches', status: 'error' }));
  }
};
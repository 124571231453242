import { THUNES_COLLECTION as types } from '../constants';

const initialState = {
  orders: {
    isLoading: false,
    isError: false,
    error: null,
    data: null,
    totalPages: 0
  },
  fulfillments: {
    isLoading: false,
    isError: false,
    error: null,
    data: null,
    totalPages: 0
  }
};

const thunesReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.GETTING_THUNES_ON_HOLD_ORDERS:
      return {
        ...state,
        orders: {
          ...state.orders,
          isLoading: true,
          isError: false,
          error: null,
          data: null,
          totalPages: 0
        }
      };

    case types.GET_THUNES_ON_HOLD_ORDERS_SUCCESS:
      return {
        ...state,
        orders: {
          ...state.orders,
          isLoading: false,
          isError: false,
          error: null,
          data: payload.data,
          totalPages: payload.totalPages
        }
      };
    case types.GET_THUNES_ON_HOLD_ORDERS_FAILURE:
      return {
        ...state,
        orders: {
          ...state.orders,
          isLoading: false,
          isError: true,
          error: payload,
          data: null,
          totalPages: 0
        }
      };

    case types.GETTING_THUNES_ON_HOLD_FULFILLMENTS:
      return {
        ...state,
        fulfillments: {
          ...state.fulfillments,
          isLoading: true,
          isError: false,
          error: null,
          data: null,
          totalPages: 0
        }
      };

    case types.GET_THUNES_ON_HOLD_FULFILLMENTS_SUCCESS:
      return {
        ...state,
        fulfillments: {
          ...state.fulfillments,
          isLoading: false,
          isError: false,
          error: null,
          data: payload?.data,
          totalPages: payload?.totalPages
        }
      };
    case types.GET_THUNES_ON_HOLD_FULFILLMENTS_FAILURE:
      return {
        ...state,
        fulfillments: {
          ...state.fulfillments,
          isLoading: false,
          isError: true,
          error: payload,
          data: null,
          totalPages: 0
        }
      };

    case types.SET_FULFILLMENTS_TO_INITIAL_STATE:
      return initialState;
    default:
      return { ...state };
  }
};

export default thunesReducer;

import { useDispatch, useSelector } from 'react-redux';
import ContentLayout from '../../layouts/ContentLayout';
import { Grid, VStack, Text, Heading, Spinner } from '@chakra-ui/react';
import { useEffect } from 'react';
import {
  delinkUserSourceAccount,
  getUserSourceAccounts
} from '../../store/actions/sourceAccountActions';
import { useParams } from 'react-router-dom';
import Pagination from '../../components/Pagination';
import { updateUserSourceAccountsReqData } from '../../store/actions/reqDataActions';
import SourceAccountCard from '../../components/SourceAccountCard';
import { setAlert } from '../../store/actions/alertActions';

const UserSourceAccount = () => {
  const { page, pageSize } = useSelector(
    (state) => state?.reqData?.userSourceAccount
  );
  const {
    data: userSourceAccounts,
    isLoading,
    totalPages
  } = useSelector((state) => state?.sourceAccount?.userSourceAccounts);

  const { userId } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getUserSourceAccounts({
        userId,
        page,
        pageSize
      })
    );
  }, [page, pageSize]);

  const handlePageChange = (pageNo) => {
    pageNo !== userSourceAccounts?.length - 1 &&
      dispatch(
        updateUserSourceAccountsReqData({
          page: pageNo,
          pageSize
        })
      );
  };

  const handleDelinkClick = (id) => {
    if (!id) {
      dispatch(
        setAlert({
          title: 'Invalid Source Account ID',
          status: 'error'
        })
      );
      return;
    }
    dispatch(
      delinkUserSourceAccount({
        userId,
        sourceAccountId: id,
        onSuccess: () => {
          dispatch(getUserSourceAccounts({ userId, page, pageSize }));
        },
        onComplete: () => {}
      })
    );
  };

  return (
    <ContentLayout>
      <Grid gridTemplateColumns={{ base: '1fr', lg: '7fr 0fr 2fr' }}>
        <VStack
          py={{ base: 5, lg: 20 }}
          px={5}
          w={{ base: '100%', lg: '70rem' }}
          overflowY={{ lg: 'scroll' }}
          h={{ lg: '100vh' }}
          className="scroll"
          alignItems={'start'}
          gap={2}
        >
          <Heading color={'white'}>User Source Accounts</Heading>
          {!isLoading ? (
            <>
              <Pagination
                currentPage={page + 1}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
              {userSourceAccounts?.length === 0 && (
                <Text color={'gray'} fontSize={'sm'}>
                  No source accounts found!
                </Text>
              )}
              {userSourceAccounts && userSourceAccounts?.length > 0 && (
                <>
                  {userSourceAccounts?.map((usrSrcAccount) => (
                    <SourceAccountCard
                      key={usrSrcAccount?.acquirer}
                      accountData={usrSrcAccount}
                      isUserSrcAcct={true}
                      onDelinkClick={() =>
                        handleDelinkClick(usrSrcAccount?.paymentSourceId)
                      }
                    />
                  ))}
                </>
              )}
            </>
          ) : (
            <Spinner />
          )}
        </VStack>
      </Grid>
    </ContentLayout>
  );
};

export default UserSourceAccount;

const USERS = {
  SEARCHING_USERS: 'SEARCHING_USERS',
  SEARCH_USERS_SUCCESS: 'SEARCH_USERS_SUCCESS',
  SEARCH_USERS_FAILURE: 'SEARCH_USERS_FAILURE',
  SEARCHING_USER_BY_ID: 'SEARCHING_USER_BY_ID',
  SEARCH_USER_BY_ID_SUCCESS: 'SEARCH_USER_BY_ID_SUCCESS',
  SEARCH_USER_BY_ID_FAILURE: 'SEARCH_USER_BY_ID_FAILURE',
  GET_USER_COUNT_SUCCESS: 'GET_USER_COUNT_SUCCESS',
  GET_USER_COUNT_FAILURE: 'GET_USER_COUNT_FAILURE ',
  CLEAR_USERS_LIST: 'CLEAR_USERS_LIST',
  CLEAR_USER_DETAILS: 'CLEAR_USER_DETAILS',
  GETTING_USER_ORDERS: 'GETTING_USER_ORDERS',
  GET_USER_ORDERS_SUCCESS: 'GET_USER_ORDERS_SUCCESS',
  GET_USER_ORDERS_FAILURE: 'GET_USER_ORDERS_FAILURE',
  CLEAR_USER_ORDERS: 'CLEAR_USER_ORDERS',
  REJECTING_USER_KYC: 'REJECTING_USER_KYC',
  REJECT_USER_KYC_SUCCESS: 'REJECT_USER_KYC_SUCCESS',
  REJECT_USER_KYC_FAILURE: 'REJECT_USER_KYC_FAILURE',
  UPDATING_USER_ADDRESS: 'UPDATING_USER_ADDRESS',
  UPDATE_USER_ADDRESS_SUCCESS: 'UPDATE_USER_ADDRESS_SUCCESS',
  UPDATE_USER_ADDRESS_FAILURE: 'UPDATE_USER_ADDRESS_FAILURE',
  GETTING_USER_REFERRAL_CODE: 'GETTING_USER_REFERRAL_CODE',
  GET_USER_REFERRAL_CODE_SUCCESS: 'GET_USER_REFERRAL_CODE_SUCCESS',
  GET_USER_REFERRAL_CODE_FAILURE: 'GET_USER_REFERRAL_CODE_FAILURE',
  CLEAR_USER_REFERRAL_CODE: 'CLEAR_USER_REFERRAL_CODE',

  GETTING_GOLDEN_TICKET_INFO: 'GETTING_GOLDEN_TICKET_INFO',
  GET_GOLDEN_TICKET_INFO_SUCCESS: 'GET_GOLDEN_TICKET_INFO_SUCCESS',
  GET_GOLDEN_TICKET_INFO_FAILURE: 'GET_GOLDEN_TICKET_INFO_FAILURE',
  CLEAR_GOLDEN_TICKET_INFO: 'CLEAR_USER_REFERRAL_CODE',

  SYNCING_WALLET: 'SYNCING_WALLET',
  SYNC_WALLET_SUCCESS: 'SYNC_WALLET_SUCCESS',
  SYNC_WALLET_FAILURE: 'SYNC_WALLET_FAILURE'
};

export default USERS;

const YblTxnDetailsCard = ({
  createdAt,
  updatedAt,
  status,
  sendAmount,
  weConvert,
  failureReason
}) => {
  return <></>;
};

export default YblTxnDetailsCard;

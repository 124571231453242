import { CONFIG as types } from '../constants';

const initialState = {
  settlementMessages: {
    isLoading: false,
    data: null,
    isError: false,
    error: null
  },

  pollingStatus: {
    isLoading: false,
    data: null,
    isError: false,
    error: null
  },

  fundBenchmarkReturns: {
    isLoading: false,
    data: null,
    isError: false,
    error: null
  },
  configList: {
    isLoading: false,
    data: [],
    isError: false,
    error: null
  }
};

const configReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.GETTING_SETTLEMENT_MESSAGE:
      return {
        ...state,
        settlementMessages: {
          ...state.settlementMessages,
          isLoading: true,
          data: null,
          isError: false,
          error: null
        }
      };

    case types.GET_SETTLEMENT_MESSAGE_SUCCESS:
      return {
        ...state,
        settlementMessages: {
          ...state.settlementMessages,
          isLoading: false,
          data: payload,
          isError: false,
          error: null
        }
      };

    case types.GET_SETTLEMENT_MESSAGE_FAILURE:
      return {
        ...state,
        settlementMessages: {
          ...state.settlementMessages,
          isLoading: false,
          data: null,
          isError: true,
          error: payload
        }
      };

    case types.GETTING_POLLING_STATUS:
      return {
        ...state,
        pollingStatus: {
          ...state.pollingStatus,
          isLoading: true,
          data: null,
          isError: false,
          error: null
        }
      };

    case types.GET_POLLING_STATUS_SUCCESS:
      return {
        ...state,
        pollingStatus: {
          ...state.pollingStatus,
          isLoading: false,
          data: payload,
          isError: false,
          error: null
        }
      };

    case types.GET_POLLING_STATUS_FAILURE:
      return {
        ...state,
        pollingStatus: {
          ...state.pollingStatus,
          isLoading: false,
          data: null,
          isError: true,
          error: payload
        }
      };

    case types.GETTING_FUND_BENCHMARK_RETURNS:
      return {
        ...state,
        fundBenchmarkReturns: {
          ...state.fundBenchmarkReturns,
          isLoading: true,
          data: null,
          isError: false,
          error: null
        }
      };

    case types.GET_FUND_BENCHMARK_RETURNS_SUCCESS:
      return {
        ...state,
        fundBenchmarkReturns: {
          ...state.fundBenchmarkReturns,
          isLoading: false,
          data: payload,
          isError: false,
          error: null
        }
      };

    case types.GET_FUND_BENCHMARK_RETURNS_FAILURE:
      return {
        ...state,
        fundBenchmarkReturns: {
          ...state.fundBenchmarkReturns,
          isLoading: false,
          data: null,
          isError: true,
          error: payload
        }
      };

    case types.GETTING_CONFIG_LIST:
      return {
        ...state,
        configList: {
          ...state?.configList,
          isLoading: true,
          data: [],
          isError: false,
          error: null
        }
      };

    case types.GET_CONFIG_LIST_SUCCESS:
      return {
        ...state,
        configList: {
          ...state?.configList,
          isLoading: false,
          isError: false,
          error: null,
          data: payload
        }
      };

    case types.GET_CONFIG_LIST_ERROR:
      return {
        ...state,
        configList: {
          ...state?.configList,
          isLoading: false,
          isError: true,
          error: payload?.errMsg
        }
      };

    case types.UPDATING_CONFIG_LIST_ITEM:
      return {
        ...state,
        configList: {
          ...state?.configList,
          isLoading: true,
          isError: false,
          error: null
        }
      };

    case types.UPDATE_CONFIG_LIST_ITEM_SUCCESS:
      return {
        ...state,
        configList: {
          ...state?.configList,
          isLoading: false,
          isError: false,
          error: null
        }
      };

    case types.UPDATE_CONFIG_LIST_ITEM_FAILURE:
      return {
        ...state,
        configList: {
          ...state?.configList,
          isLoading: false,
          isError: true,
          error: payload?.errMsg
        }
      };

    default:
      return {
        ...state
      };
  }
};

export default configReducer;

export default function nreNroApi(fetch, baseUrl) {
  return {
    getOnboardingStatus({ userId }) {
      return fetch(`${baseUrl}/onboarding/${userId}/status`);
    },

    updateOnboardingStatus({ userId, status }) {
      return fetch.put(`${baseUrl}/onboarding/${userId}/status`, {
        onboardingStatus: status
      });
    },

    yblDedupe({ userId }) {
      return fetch.put(`${baseUrl}/onboarding/${userId}/dedupe`);
    },

    yblCustomerDetails({ userId }) {
      return fetch.put(`${baseUrl}/onboarding/${userId}/customer-details`);
    },

    yblSubmitJourney({ userId }) {
      return fetch.put(`${baseUrl}/onboarding/${userId}/submit-journey`);
    },

    yblUploadDocs({ userId, docType }) {
      return fetch.put(`${baseUrl}/onboarding/${userId}/docs/${docType}`);
    }
  };
}

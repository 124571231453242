import { useParams } from 'react-router-dom';
import ContentLayout from '../../../layouts/ContentLayout';
import {
  Grid,
  Heading,
  useDisclosure,
  VStack,
  Text,
  Spinner,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import ModalLayout from '../../../components/Modal/ModalLayout';
import UpdateBeneficiartModal from '../../../components/OrderDetailsCard/UpdateBeneficiaryModal';
import { useDispatch } from 'react-redux';
import {
  getUserBeneficiaries,
  updateBeneficiaryV2,
  updateBeneficiaryVerificationData
} from '../../../store/actions/usersActions';
import { tableCellStyles } from '../../../components/NrOnboard/constants';
import { MdEdit } from 'react-icons/md';

const UserBeneficiaries = ({}) => {
  const {
    isOpen: isUpdateBeneficiaryOpen,
    onOpen: onUpdateBeneficiaryOpen,
    onClose: onUpdateBeneficiaryClose
  } = useDisclosure();

  const { userId } = useParams();
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedBeneficiary, setSelectedBeneficiary] = useState(null);

  const dispatch = useDispatch();

  const cellStyles = tableCellStyles(12);

  const fetchBeneficiaries = (userId) => {
    setIsLoading(true);
    dispatch(
      getUserBeneficiaries({
        userId,
        onSuccess: (data) => {
          setBeneficiaries(data);
        },
        onComplete: () => {
          setIsLoading(false);
        }
      })
    );
  };

  useEffect(() => {
    fetchBeneficiaries(userId);
  }, []);

  const handleUpdateBeneficiaryOpen = (beneficiary) => {
    setSelectedBeneficiary(beneficiary);
    onUpdateBeneficiaryOpen();
  };

  const handleUpdateBeneficiaryClose = () => {
    setSelectedBeneficiary(null);
    onUpdateBeneficiaryClose();
  };

  const handleUpdateBeneficiarySubmit = (payload) => {
    // TODO: update the api
    setIsLoading(true);
    dispatch(
      updateBeneficiaryVerificationData({
        userId,
        beneficiaryId: selectedBeneficiary.beneficiaryId,
        payload,
        onSuccess: () => {
          fetchBeneficiaries(userId);
          handleUpdateBeneficiaryClose();
        },
        onComplete: () => {
          setIsLoading(false);
        }
      })
    );
  };

  return (
    <>
      <ModalLayout
        isOpen={isUpdateBeneficiaryOpen}
        onClose={handleUpdateBeneficiaryClose}
      >
        <UpdateBeneficiartModal
          isLoading={isLoading}
          beneficiaryData={selectedBeneficiary}
          onCancel={handleUpdateBeneficiaryClose}
          onSubmit={handleUpdateBeneficiarySubmit}
        />
      </ModalLayout>
      <ContentLayout>
        <Grid gridTemplateColumns={{ base: '1fr', lg: '7fr 2fr' }}>
          <VStack
            overflowY={{ base: 'initial', lg: 'scroll' }}
            overflowX={'hidden'}
            h={{ lg: '90vh' }}
            py={10}
            px={{ base: 4, sm: 10 }}
            alignItems={'stretch'}
            className="scroll"
            gap={2}
          >
            <Heading size={'md'} color={'white'}>
              Beneficiaries
            </Heading>
            {!isLoading ? (
              <>
                {beneficiaries?.length === 0 ? (
                  <Text color={'white'} fontSize={'md'}>
                    No beneficiaries found!
                  </Text>
                ) : (
                  <Table overflowX={'scroll'}>
                    <Thead>
                      <Tr>
                        <Th style={cellStyles}>#</Th>
                        <Th style={cellStyles}>Account Number</Th>
                        <Th style={cellStyles}>IFSC Code</Th>
                        <Th style={cellStyles}>Account Holder Name</Th>
                        <Th style={cellStyles}>Account State</Th>
                        <Th style={cellStyles}>Bank Name</Th>
                        <Th style={cellStyles}>Beneficiary ID</Th>
                        {/* <Th style={cellStyles}>Beneficiary Type</Th> */}
                        <Th style={cellStyles}>Edit</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {beneficiaries?.map((beneficiary, ind) => (
                        <Tr key={beneficiary?.beneficiaryId}>
                          <Td style={cellStyles}>
                            <Text fontSize={'sm'} color={'white'}>
                              {ind + 1 || '-'}
                            </Text>
                          </Td>
                          <Td style={cellStyles}>
                            <Text fontSize={'sm'} color={'white'}>
                              {beneficiary?.accountNumber || '-'}
                            </Text>
                          </Td>
                          <Td style={cellStyles}>
                            <Text fontSize={'sm'} color={'white'}>
                              {beneficiary?.ifscCode || '-'}
                            </Text>
                          </Td>
                          <Td style={cellStyles}>
                            <Text fontSize={'sm'} color={'white'}>
                              {beneficiary?.accountHolderName || '-'}
                            </Text>
                          </Td>
                          <Td style={cellStyles}>
                            <Text fontSize={'sm'} color={'white'}>
                              {beneficiary?.accountState || '-'}
                            </Text>
                          </Td>
                          <Td style={cellStyles}>
                            <Text fontSize={'sm'} color={'white'}>
                              {beneficiary?.bankName || '-'}
                            </Text>
                          </Td>
                          <Td style={cellStyles}>
                            <Text fontSize={'sm'} color={'white'}>
                              {beneficiary?.beneficiaryId || '-'}
                            </Text>
                          </Td>
                          {/* <Td style={cellStyles}>
                            <Text fontSize={'sm'} color={'white'}>
                              {beneficiary?.beneficiaryType || '-'}
                            </Text>
                          </Td> */}
                          <Td style={cellStyles}>
                            <IconButton
                              icon={<MdEdit color="white" />}
                              variant={'unstyled'}
                              onClick={() => {
                                handleUpdateBeneficiaryOpen(beneficiary);
                              }}
                            />
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                )}
              </>
            ) : (
              <Spinner />
            )}
          </VStack>
        </Grid>
      </ContentLayout>
    </>
  );
};

export default UserBeneficiaries;

export default function workflowApiV2(fetch, baseUrl) {
  return {
    getScreens({ userId, workflowType }) {
      return fetch(`${baseUrl}/${userId}/screens/${workflowType}`);
    },
    getDocByUrl({ userId, docUrl }) {
      return fetch(`${baseUrl}/${userId}/docs/${docUrl}`);
    },
    getScreenData({ userId, screenId }) {
      return fetch(`${baseUrl}/${userId}/screens/details/${screenId}`);
    },
    getOcrDetails(userId) {
      return fetch(`${baseUrl}/${userId}/ocr`);
    },
    getUserDocs({ userId, subDocuments }) {
      return fetch.post(`${baseUrl}/${userId}/docs`, { subDocuments });
    },
    triggerOcrByDoc({ userId, subDocumentType, documentKey }) {
      return fetch.put(`${baseUrl}/${userId}/ocr`, {
        documentType: subDocumentType,
        documentKey
      });
    },
    updateOcrByDoc({ userId, payload }) {
      return fetch.put(`${baseUrl}/${userId}/ocr-details-update`, {
        ...payload
      });
    }
  };
}

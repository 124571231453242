import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Grid,
  HStack,
  Heading,
  Text
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import DataElement from './DataElement';
import OnRampData from './OnRampData';
import OffRampData from './OffRampData';
import { VDA_FUNNEL_STATES } from '../../views/Funnel/SaberFunnel/constants';
import { convertHoursToHoursAndMinutes } from '../../utils';
import { Link } from 'react-router-dom';
import { FaArrowRightFromBracket } from 'react-icons/fa6';

const VdaDataCompomet = ({ vdaData, funnelStatus }) => {
  const { offRampData, onRampData } = vdaData;

  const getDefaultIndices = (funnelStatus) => {
    switch (funnelStatus) {
      case VDA_FUNNEL_STATES.ON_RAMP_INITIATED:
      case VDA_FUNNEL_STATES.ON_RAMP_PROCESSING:
      case VDA_FUNNEL_STATES.ON_RAMP_COMPLETED:
        return [1];
      case VDA_FUNNEL_STATES.OFF_RAMP_INITIATED:
        return [2];
      case VDA_FUNNEL_STATES.OFF_RAMP_COMPLETED:
        return [0];
      default:
        return [];
    }
  };

  const indices = getDefaultIndices(funnelStatus);

  return (
    <Box
      position={'relative'}
      borderRadius={'lg'}
      w={{ base: '100%', md: '100%' }}
      maxW={'850px'}
      padding={'20px'}
      bg={'backGround'}
      py={'30px'}
    >
      <Box position={'absolute'} top={'0'} right={'0'}>
        <HStack py={'12px'} gap={3} px={'4'}>
          <Link color="brand.500" to={`/order/${vdaData?.orderId}`}>
            <HStack>
              <Text color={'brand.500'}>Order Details</Text>
              <FaArrowRightFromBracket color="white" />
            </HStack>
          </Link>
          <Link to={`/user/${vdaData?.userId}`} variant={'link'}>
            <HStack>
              <Text color={'brand.500'}>User Details</Text>
              <FaArrowRightFromBracket color="white" />
            </HStack>
          </Link>
        </HStack>
      </Box>
      <Accordion defaultIndex={indices} allowMultiple>
        <AccordionItem
          py={2}
          style={{
            borderTop: 'none'
          }}
        >
          <AccordionButton>
            <HStack
              width={'full'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Heading color={'white'} size={'sm'}>
                Breakdown
              </Heading>
              <AccordionIcon color={'white'} />
            </HStack>
          </AccordionButton>
          <AccordionPanel>
            <Grid
              gridTemplateColumns={{
                sm: '1fr',
                md: '1fr 1fr 1fr 1fr'
              }}
              gap={4}
            >
              <DataElement
                title={'Send Amount'}
                value={onRampData?.sentAmount}
              />
              <DataElement
                title={'Recieve Amount'}
                value={offRampData?.receiveAmount}
              />
              <DataElement title={'Order ID'} value={vdaData?.orderId} />
              <DataElement title={'User ID'} value={vdaData?.userId} />
              <DataElement
                title={'Profit Made (in dollars)'}
                value={Number(
                  offRampData?.receiveAmount / 86.5 -
                    onRampData?.sentAmount / onRampData?.exchangeRate
                ).toFixed(3)}
              />
              <DataElement
                title={'Time to complete (in hours)'}
                value={convertHoursToHoursAndMinutes(
                  dayjs(offRampData?.updatedAt)
                    .diff(dayjs(vdaData?.orderCreatedAt), 'hours', true)
                    .toFixed('3')
                )}
              />
              <DataElement
                title={'GBP-USD Exchange Rate'}
                value={`1 USD = ${onRampData?.exchangeRate} GBP`}
              />
              <DataElement
                title={'USD-INR Exchange Rate'}
                value={`1 USD = ${86.5} INR`}
              />
            </Grid>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem
          py={2}
          style={{
            borderTop: 'none'
          }}
        >
          <AccordionButton>
            <HStack
              width={'full'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Heading color={'white'} size={'sm'}>
                On Ramp Data
              </Heading>
              <AccordionIcon color={'white'} />
            </HStack>
          </AccordionButton>
          <AccordionPanel>
            <OnRampData onRampData={onRampData} />
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem
          py={2}
          style={{
            border: 'none'
          }}
        >
          <AccordionButton>
            <HStack
              width={'full'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Heading color={'white'} size={'sm'}>
                Off Ramp Data
              </Heading>
              <AccordionIcon color={'white'} />
            </HStack>
          </AccordionButton>
          <AccordionPanel>
            <OffRampData offRampData={offRampData} />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
};

export default VdaDataCompomet;

import { USER_REFERRALS as types } from "../constants";

const initialState = {
    isLoading: false,
    isError: false,
    error: null,
    pages: {},
    totalPages: 0
};

const userReferralReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch(type) {
        case types.GETTING_REFERRALS:
            return {
                ...state,
                isLoading: true,
                isError: false,
                error: null,
                pages: { ...state.pages },
                totalPages: 0
            };

        case types.GET_REFERRALS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                error: null,
                pages: payload.refresh ?
                    {} :
                    {
                        ...state.pages,
                        [payload.page]: {
                            isCached: false,
                            isError: false,
                            error: null,
                            data: payload.data
                        }
                    },
                totalPages: payload.refresh
                    ? 0
                    : payload.totalPages

            };

        case types.GET_REFERRALS_FAILURE:
            return {
                ...state,
                isLoading: false,
                isError: true,
                error: payload.error,
                pages: {},
                totalPages: 0
            };

        case types.CLEAR_USER_REFERRALS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                error: null,
                pages: {},
                totalPages: 0
            }

        default:
            return { ...state };
    }
}

export default userReferralReducer;
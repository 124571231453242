const AUTH = {
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  LOGOUT: 'LOGOUT',
  LOGOUT_FAILURE: 'LOGOUT_FAILURE',
  LOGGING_IN: 'LOGGING_IN',
  LOGIN_FROM_LOCAL_STORAGE_FAILURE: 'LOGIN_FROM_LOCAL_STORAGE_FAILURE'
};

export default AUTH;

export const CAMPAIGN_REWARD_CONDITIONS = {
  KYC_COMPLETED: 'KYC_COMPLETED',
  TRANSACTION_COMPLETED: 'TRANSACTION_COMPLETED',
  USER_CREATED: 'USER_CREATED'
};

export const toDate = (date) =>
  !date
    ? new Date().toLocaleDateString('fr-ca')
    : new Date(date).toLocaleDateString('fr-ca');

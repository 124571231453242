import {
  Box,
  Table,
  Tbody,
  Th,
  Td,
  Tr,
  Thead,
  VStack,
  Text,
  TabList,
  Tabs,
  Tab
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { camelCaseToSpaceSeparated } from '../../utils';
import FtnrFormItem from './FormItem';
import { BLOCKED_FIELDS } from './constants';
import FtnrDocuments from './FtnrDocuments';

const FtnrFormBody = ({
  mapper,
  formData,
  handleChange,
  showDetails = true,
  handleViewFileClick,
  disabled = false,
  isDocsTab = false
}) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const isFieldBlocked = (field) => {
    if (field in BLOCKED_FIELDS) return true;
    return false;
  };

  if (!formData || !showDetails) return null;
  if (isDocsTab)
    return (
      <FtnrDocuments
        handleViewFileClick={handleViewFileClick}
        formData={formData}
      />
    );
  return (
    <VStack width={'full'} alignItems={'stretch'} gap={2}>
      <Table>
        <Thead>
          <Tr>
            <Th
              style={{
                border: 'none',
                padding: 0
              }}
            ></Th>
            <Th
              style={{
                border: 'none',
                padding: 0
              }}
            ></Th>
          </Tr>
        </Thead>
        <Tbody>
          {Object.keys(mapper)
            .filter(
              (key) => typeof mapper[key] !== 'object' && !isFieldBlocked(key)
            )
            .map((key) => (
              <Tr key={key}>
                <Td
                  style={{
                    border: 'none',
                    padding: '8px'
                  }}
                >
                  <Text color={'white'}>{camelCaseToSpaceSeparated(key)}:</Text>
                </Td>
                <Td
                  style={{
                    border: 'none',
                    padding: '8px'
                  }}
                >
                  <FtnrFormItem
                    itemKey={key}
                    value={formData[key]}
                    handleChange={handleChange}
                    onViewFileClick={handleViewFileClick}
                    isDisabled={disabled}
                  />
                </Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
      <Tabs
        variant={'enclosed'}
        size={'md'}
        defaultValue={0}
        onChange={(e) => {
          setSelectedTab(e);
        }}
      >
        <TabList color={'white'}>
          {Object.keys(mapper)
            .filter((key) => typeof mapper[key] === 'object')
            .map((key, ind) => (
              <Tab
                key={`tab-${key}-${ind}`}
                _selected={{
                  color: 'brand.500',
                  border: '1px solid white',
                  borderBottom: '1px solid black'
                }}
              >
                {camelCaseToSpaceSeparated(key)}
              </Tab>
            ))}
        </TabList>
      </Tabs>
      <Box>
        {Object.keys(mapper)
          .filter((key) => typeof mapper[key] === 'object')
          .map((key, ind) => (
            <FtnrFormBody
              key={key}
              mapper={mapper[key]}
              formData={formData}
              showDetails={selectedTab === ind}
              isDocsTab={key === 'documents'}
              handleChange={handleChange}
              handleViewFileClick={handleViewFileClick}
              disabled={disabled}
            />
          ))}
      </Box>
    </VStack>
  );
};

export default FtnrFormBody;

import { CX_USERS as types } from "../constants"; 

const initialState = {
    isLoading: false,
    isError: false,
    data: {},
    error: null
}

const cxUsersReducer = (state=initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case types.GETTTING_ALL_USERS: 
        return {
            ...state,
            isLoading: true
        }
        case types.GET_ALL_USERS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                error: null,
                data: payload?.data?.reduce((prev, user) => ({
                    ...prev,
                    [user?.userId] : user
                }), {})
            }
        case types.GET_ALL_USERS_FAILURE:
            return {
                ...state,
                isLoading: false,
                isError: true,
                error: payload.errMsg,
                data: {}
            }
        default: return state
    }
}

export default cxUsersReducer;
import React, { useCallback, useRef } from 'react';
import InputComponent from '../../components/Input';
import { Box, Button, HStack, Select } from '@chakra-ui/react';
import { MdSearch } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { updateOrdersReqData } from '../../store/actions/reqDataActions';
import { getOrders } from '../../store/actions/ordersActions';
import useDidMountEffect from '../../hooks/useDidMount';
import { useNavigate } from 'react-router-dom';
import useIsMobile from '../../hooks/useIsMobile';
import OrdersList from '../../components/OrdersList';
import { ORDER_TYPE } from '../../constants';

const OrdersContent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchTimer = useRef(null);
  const isMobile = useIsMobile();

  const searchReqData = useSelector((state) => state.reqData.dashboard.orders);

  const searchResult = useSelector((state) => ({
    orders: state.resData.orders.data[searchReqData.pageNo] || [],
    isLoading: state.resData.orders.isLoading,
    isError: state.resData.orders.isError,
    totalPages: state.resData.orders.totalPages
  }));

  //refetch orders data on change in username query, with a delay of 2 seconds
  useDidMountEffect(() => {
    if (searchTimer.current) {
      clearTimeout(searchTimer.current);
      searchTimer.current = setTimeout(() => {
        searchOrders(true);
      }, 2000);
    } else {
      searchOrders(true);
      searchTimer.current = true;
    }
  }, [searchReqData.query]);

  useDidMountEffect(() => {
    searchOrders();
  }, [searchReqData.pageNo]);

  useDidMountEffect(() => {
    searchOrders(true);
  }, [searchReqData.orderType]);

  const searchOrders = async (refresh = false) => {
    if (refresh || !searchResult.orders?.length) {
      dispatch(getOrders({ ...searchReqData, refresh }));
    }
  };

  const handleSearchReqChange = useCallback(
    (e) => {
      if (e.target.value === searchReqData.query) return;
      dispatch(updateOrdersReqData({ query: e.target.value }));
    },
    [dispatch, searchReqData.query]
  );

  const handleOrderTypeChange = useCallback((e) => {
    console.log(e.target.value, searchReqData.orderType);
    e.target.value !== searchReqData.orderType &&
      dispatch(
        updateOrdersReqData({
          orderType: e.target.value
        })
      );
  });

  const handlePageChange = useCallback(
    (pageNo) => {
      pageNo - 1 !== searchReqData.pageNo &&
        dispatch(updateOrdersReqData({ pageNo: pageNo - 1 }));
    },
    [dispatch, searchReqData.pageNo]
  );

  const onOrderDetailsClick = useCallback(
    (orderId, userId) => {
      navigate(
        isMobile ? `/order/${orderId}` : `/user/${userId}?orderId=${orderId}`
      );
    },
    [isMobile, navigate]
  );

  return (
    <>
      <InputComponent
        type="text"
        value={searchReqData.query}
        name="search"
        handleChange={handleSearchReqChange}
        placeholder="Search by Client ID or Deal ID"
        inputTitle="Search orders"
        leftElement={<MdSearch size="2rem" color="white" />}
        width="40rem"
        maxWidth="100%"
        autoComplete={'off'}
        spellcheck={false}
        autoCorrect={'off'}
      />

      <HStack
        w={'100%'}
        justifyContent={'space-between'}
        maxW={'40rem'}
        alignSelf={'flex-start'}
      >
        <Button
          type="submit"
          color="black"
          rounded="lg"
          colorScheme="brand"
          my={5}
          py={2}
          onClick={() => searchOrders()}
          fontWeight={600}
          isLoading={searchResult.isLoading}
          alignSelf="start"
          style={{
            marginTop: '1em'
          }}
          size={{
            base: 'md',
            md: 'lg'
          }}
        >
          Search
        </Button>
        <Box maxWidth="15rem">
          <Select
            placeholder={`Filter Order Type`}
            onChange={handleOrderTypeChange}
            name={'orderType'}
            value={searchReqData.orderType}
            bg="black"
            color="gray"
            size={'sm'}
            rounded={'lg'}
            _focus={{
              border: '1px solid #81EBAB',
              boxShadow: 'none'
            }}
          >
            {Object.keys(ORDER_TYPE).map((orderType) => (
              <option value={orderType} key={orderType}>
                {orderType}
              </option>
            ))}
          </Select>
        </Box>
      </HStack>

      <OrdersList
        orders={searchResult.orders}
        pageNo={searchReqData.pageNo}
        totalPages={searchResult.totalPages}
        handlePageChange={handlePageChange}
        onOrderDetailsClick={onOrderDetailsClick}
        show={'user'}
      />
    </>
  );
};

export default OrdersContent;

import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  Select,
  Stack,
  VStack,
  Link,
  HStack,
  Text
} from '@chakra-ui/react';
import { Link as ReactLink } from 'react-router-dom';
import FullPageSpinner from '../../components/FullPageSpinner';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ContentLayout from '../../layouts/ContentLayout';
import {
  getNrUserDetails,
  updateNrAccountStatus
} from '../../store/actions/nrAccountActions';
import { NR_ACCOUNT_ONBOARDING_STATUS } from '../../constants';
import InputComponent from '../../components/Input';
import { BsArrowRight } from 'react-icons/bs';

const EditNrUser = () => {
  const { userId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    accountStatus: ''
  });
  const { data: user, isLoading: isUserLoading } = useSelector(
    (state) => state.nrAccount.userDetails
  );

  useEffect(() => {
    if (user?.accountStatus) {
      setFormData((prev) => ({
        ...prev,
        accountStatus: user.accountStatus
      }));
    }
  }, [user]);

  useEffect(() => {
    dispatch(getNrUserDetails(userId));
  }, [userId, dispatch]);

  const updateAccountStatus = useCallback(
    (formData) => {
      setIsLoading(true);
      dispatch(
        updateNrAccountStatus({
          userId,
          accountStatus: formData.accountStatus,
          username: user.name,
          onSuccess: () => {
            navigate(`/nr-account/users`);
          },

          onFinish: () => setIsLoading(false)
        })
      );
    },
    [user.name, userId, navigate, dispatch]
  );

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();

      updateAccountStatus(formData);
    },
    [formData, updateAccountStatus]
  );

  const handleChange = useCallback((e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  }, []);

  if (isUserLoading) return <FullPageSpinner />;

  return (
    <ContentLayout>
      <VStack alignItems={'flex-start'} p={10} gap={4}>
        <Stack
          direction={{ base: 'column', sm: 'row' }}
          justifyContent={'space-between'}
          w={'100%'}
          maxW={'50rem'}
        >
          <Heading color={'colorPrimary'}>Edit NRE/NRO User</Heading>
          <Link
            as={ReactLink}
            to={`/user/${userId}`}
            color="brand.500"
            justifyContent={'flex-start'}
          >
            <HStack>
              <Text>User details</Text>
              <BsArrowRight display={'inline'} />
            </HStack>
          </Link>
        </Stack>
        <FormControl color={'white'} maxW={'50rem'}>
          <VStack
            gap={4}
            alignItems={'stretch'}
            w={'100%'}
            as={'form'}
            onSubmit={handleSubmit}
          >
            {/* NAME */}
            <Grid
              gridTemplateColumns={{
                base: '1fr',
                md: '0.4fr 0.6fr'
              }}
            >
              <FormLabel display={'flex'} alignItems={'center'} mb={0}>
                Name:
              </FormLabel>
              <InputComponent
                type="name"
                value={user?.name || ''}
                name={'name'}
                errorBorderColor="#ffffff"
                boxShadow={'none !important'}
                disabled
                width="100%"
                maxWidth="50rem"
              />
            </Grid>
            {/* EMAIL */}
            <Grid
              gridTemplateColumns={{
                base: '1fr',
                md: '0.4fr 0.6fr'
              }}
            >
              <FormLabel display={'flex'} alignItems={'center'} mb={0}>
                Email:
              </FormLabel>
              <InputComponent
                type="email"
                value={user?.email || ''}
                name={'email'}
                errorBorderColor="#ffffff"
                boxShadow={'none !important'}
                disabled
                width="100%"
                maxWidth="50rem"
              />
            </Grid>
            {/* ONBOARDING accountStatus */}
            <Grid
              gridTemplateColumns={{
                base: '1fr',
                md: '0.4fr 0.6fr'
              }}
            >
              <FormLabel display={'flex'} alignItems={'center'} mb={0}>
                Onboarding accountStatus
              </FormLabel>
              <Box position="relative" width={'100%'} maxW="50rem">
                <Select
                  position={'relative'}
                  placeholder="Select option"
                  bg={'black'}
                  onChange={handleChange}
                  value={formData.accountStatus}
                  name={'accountStatus'}
                  boxShadow={'none !important'}
                  required
                  size="lg"
                  color="white"
                  rounded={'lg'}
                  focusBorderColor="brand.400"
                  autoFocus={false}
                  border={'none'}
                  errorBorderColor="red.300"
                  style={{
                    border: 0,
                    background: '#414141',
                    gap: '12px',
                    width: '100%',
                    maxWidth: '50rem',
                    margin: '0',
                    borderRadius: '12px'
                  }}
                >
                  {Object.keys(NR_ACCOUNT_ONBOARDING_STATUS)?.map(
                    (accountStatus) => (
                      <option value={accountStatus} key={accountStatus}>
                        {accountStatus}
                      </option>
                    )
                  )}
                </Select>
              </Box>
            </Grid>
            <Button
              colorScheme="brand"
              color="altGray.400"
              mr={3}
              size={'md'}
              type="submit"
              alignSelf={'center'}
              isLoading={isLoading}
            >
              Submit
            </Button>
          </VStack>
        </FormControl>
      </VStack>
    </ContentLayout>
  );
};

export default EditNrUser;

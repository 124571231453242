import dayjs from 'dayjs';
import { decamelizeAndUpperCase } from '../../utils';
import {
  NROB_MANDATORY_DOCS,
  NR_OB_COUNTRY,
  NR_OB_UAE_REQ_DOCS,
  NR_OB_UK_REQ_DOCS
} from '../../constants';

export const updateFunnelListFromOrderMetaData = (
  state,
  metadata,
  filterByFlag
) => {
  const orderId = metadata.orderId;

  let orderIndex = null,
    orderPageNo = null,
    orderStatus = null;

  /* FIND POSITION OF UPDATED ORDER IN EXISTING STATE */
  let found = false;
  for (const status of Object.keys(state.orders)) {
    for (const pageNo of Object.keys(
      state.orders[status][filterByFlag]?.pages
    )) {
      const orderList = state.orders[status][filterByFlag]?.pages[pageNo].data;
      if (!orderList) continue;
      for (let i = 0; i < orderList.length; i++) {
        if (orderList[i].orderId === orderId) {
          found = true;
          orderStatus = status;
          orderPageNo = pageNo;
          orderIndex = i;
          break;
        }
      }
      if (found) break;
    }
    if (found) break;
  }

  /* UPDATE ORDER METADATA IF IT EXISTS IN STATE  */
  if (
    !found ||
    !state.orders[orderStatus][filterByFlag]?.pages[orderPageNo]?.data?.length
  )
    return { ...state };

  return {
    ...state,
    orders: {
      ...state.orders,
      [orderStatus]: {
        ...state.orders[orderStatus],
        [filterByFlag]: {
          ...state.orders[orderStatus][filterByFlag],
          pages: {
            ...state.orders[orderStatus][filterByFlag].pages,
            [orderPageNo]: {
              ...state.orders[orderStatus][filterByFlag].pages[orderPageNo],
              data: [
                ...state.orders[orderStatus][filterByFlag].pages[
                  orderPageNo
                ].data.slice(0, orderIndex),
                {
                  ...state.orders[orderStatus][filterByFlag].pages[orderPageNo]
                    .data[orderIndex],
                  metadata: {
                    ...state.orders[orderStatus][filterByFlag].pages[
                      orderPageNo
                    ][orderIndex]?.metadata,
                    ...metadata //update metadata for order
                  }
                },
                ...state.orders[orderStatus][filterByFlag].pages[
                  orderPageNo
                ].data.slice(orderIndex + 1)
              ] //for unfiltered update metadata of the order
            }
          }
        }
      }
    }
  };
};

export const toWorkflowDoc = (doc) => ({
  ...doc
});

export const transformNroOnboarState = (state) => {
  const { onboardingType, onboardingUserType, country } = state;
  if (country === 'AE') {
    const {
      activeStep,
      accountDetails,
      appointmentDetails,
      status,
      applicationRejectionReason,
      applicationFormUrl,
      remarks
    } = state;
    return {
      remarks,
      applicationRejectionReason,
      country,
      onboardingType,
      onboardingUserType,
      activeStep,
      application: {
        applicationFormUrl,
        status: status?.applicationStatus,
        kycVerificationStatus: status?.kycVerificationStatus
      },
      account: {
        status: status?.accountOpeningStatus,
        ...accountDetails
      },
      appointment: {
        status: status?.appointmentStatus,
        address: appointmentDetails?.address,
        startTime: appointmentDetails?.selectedAppointmentSlot?.startTime,
        endTime: appointmentDetails?.selectedAppointmentSlot?.endTime
      },
      simVerification: {
        status: status?.simVerification
      }
    };
  }

  const {
    status,
    accountDetails,
    pickupDetails,
    applicationRejectionReason,
    applicationFormUrl,
    remarks
  } = state;

  const { printsScheduledOn, address } = pickupDetails;

  return {
    remarks,
    country,
    applicationRejectionReason,
    activeStep: state?.activeStep,
    application: {
      applicationFormUrl,
      status: status?.applicationStatus,
      kycVerificationStatus: status?.kycVerificationStatus
    },
    account: {
      status: status?.accountOpeningStatus,
      ...accountDetails
    },
    delivery: {
      status: status?.docsDeliveryStatus,
      address,
      scheduledDeliveryDate: printsScheduledOn
    },
    pickup: {
      status: status?.docsPickupStatus,
      ...pickupDetails,
      docsDeliveryToYblStatus: status?.docsDeliveryToYblStatus,
      attestedDocsStatus: status?.attestedDocsStatus
    },
    simVerification: {
      status: status?.simVerificationStatus
    },
    onboardingType,
    onboardingUserType
  };
};

// transforms onboarding user docs
export const transformOnboarUserDocs = (data) => {
  const { screenId, uploadedDocs } = data;
  const docdata = Object.keys(uploadedDocs)
    .map((key) => {
      return {
        docName: decamelizeAndUpperCase(key),
        docUrl: uploadedDocs[key]
      };
    })
    .reduce((prev, curr) => {
      return {
        ...prev,
        [curr.docName]: curr
      };
    }, {});
  return {
    screenId,
    ...docdata
  };
};

// returns false if all docs are not uploaded else true
export const onboardDocsUploaded = (docs) => {
  return docs.reduce((prev, curr) => {
    return Boolean(curr?.docUrl) && prev;
  }, true);
};

export const transformOnboardUserDocsV2 = (data) => {
  const { documents, country } = data;
  const requiredDocs =
    country === NR_OB_COUNTRY.AE ? NR_OB_UAE_REQ_DOCS : NR_OB_UK_REQ_DOCS;

  const transformedDocumentResponse = Object.values(documents)?.reduce(
    (prev, curr) => {
      const { subDocumentType } = curr;
      return {
        ...prev,
        [subDocumentType]: curr
      };
    },
    {}
  );

  return requiredDocs
    ?.filter((doc) => doc?.urlPath || NROB_MANDATORY_DOCS.includes(doc))
    ?.map((doc) => {
      if (doc in transformedDocumentResponse) {
        const { subDocumentType, urlPath } = transformedDocumentResponse[doc];
        return {
          ...transformedDocumentResponse[doc],
          docName: subDocumentType,
          docUrl: urlPath
        };
      }
      return {
        docName: doc,
        docUrl: '',
        rejectionReason: '',
        isRejected: false
      };
    });
};

export function convertSlots(slotsData) {
  return slotsData
    .map((slot) => {
      const { startTime, endTime } = slot;
      const start = dayjs(startTime).format('DD-MM-YYYY');
      return {
        date: start,
        startTime,
        endTime
      };
    })
    .reduce((prev, curr) => {
      const { date, startTime, endTime } = curr;
      const slotItem = {
        startTime,
        endTime
      };
      if (date in prev) {
        return {
          ...prev,
          [date]: [...prev[date], { ...slotItem }]
        };
      } else {
        return {
          ...prev,
          [date]: [{ ...slotItem }]
        };
      }
    }, {});
}

export const slotStringBuilder = (startTime, endTime) => {
  if (!startTime || !endTime) return null;
  return `${dayjs.utc(startTime).format('HH:mm A')} - ${dayjs
    .utc(endTime)
    .format('HH:mm A')}`;
};

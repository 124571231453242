import api from '../../api';
import { getErrorMessage } from '../../utils';
import { INVESTMENTS as types } from '../constants';
import { setAlert } from './alertActions';

export const getInvestmentOrders = (searchReqData) => async (dispatch) => {
  try {
    dispatch({
      type: types.GETTING_ORDERS
    });
    const { data } = await api.investments.orders(searchReqData);

    return dispatch({
      type: types.GET_ORDERS_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch(
      setAlert({
        title: getErrorMessage({
          error,
          genericMessage: 'Failed to get investment orders'
        }),
        status: 'error'
      })
    );
    return dispatch({
      type: types.GET_ORDERS_FAILURE,
      payload: error
    });
  }
};

export const getUserInvestmentOrders =
  ({ userId, pageNo, pageSize }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: types.GETTING_USER_ORDERS
      });

      const { data } = await api.investments.userOrders({
        userId,
        pageNo,
        pageSize
      });

      return dispatch({
        type: types.GET_USER_ORDERS_SUCCESS,
        payload: data
      });
    } catch (error) {
      dispatch(
        setAlert({
          title: getErrorMessage({
            error,
            genericMessage: 'Failed to get user investment orders'
          }),
          status: 'error'
        })
      );

      return dispatch({
        type: types.GET_USER_ORDERS_FAILURE,
        payload: error
      });
    }
  };

export const getInvestmentOrderDetails = (orderId) => async (dispatch) => {
  try {
    dispatch({
      type: types.GETTING_ORDER_BY_ID
    });
    const { data } = await api.investments.orderById({ orderId });

    return dispatch({
      type: types.GET_ORDER_BY_ID_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch(
      setAlert({
        title: getErrorMessage({
          error,
          genericMessage: 'Failed to get investment order details'
        }),
        status: 'error'
      })
    );

    return dispatch({
      type: types.GET_ORDER_BY_ID_FAILURE,
      payload: error
    });
  }
};

export const clearInvestmentOrderDetails = () => ({
  type: types.CLEAR_ORDER_BY_ID
});

export const clearUserInvestmentOrders = () => ({
  type: types.CLEAR_USER_ORDERS
});

export const getUserSips =
  ({ userId, pageNo, pageSize }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: types.GETTING_USER_SIPS
      });

      const { data } = await api.investments.userSips({
        userId,
        pageNo,
        pageSize
      });

      return dispatch({
        type: types.GET_USER_SIPS_SUCCESS,
        payload: data
      });
    } catch (error) {
      dispatch(
        setAlert({
          title: getErrorMessage({
            error,
            genericMessage: 'Failed to get user SIPs'
          }),
          status: 'error'
        })
      );

      return dispatch({
        type: types.GET_USER_SIPS_FAILURE,
        payload: error
      });
    }
  };

export const getSipDetails = (sipId) => async (dispatch) => {
  try {
    dispatch({
      type: types.GETTING_SIP_BY_ID
    });
    const { data } = await api.investments.sipById({ sipId });

    return dispatch({
      type: types.GET_SIP_BY_ID_SUCCESS,
      payload: data
    });
  } catch (error) {
    console.log(error);
    dispatch(
      setAlert({
        title: getErrorMessage({
          error,
          genericMessage: 'Failed to get SIP details'
        }),
        status: 'error'
      })
    );

    return dispatch({
      type: types.GET_SIP_BY_ID_FAILURE,
      payload: error
    });
  }
};

export const clearSipDetails = () => ({
  type: types.CLEAR_SIP_BY_ID
});

export const clearUserSips = () => ({
  type: types.CLEAR_USER_SIPS
});

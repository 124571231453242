import { toCampaignData } from '../../utils';
import { REFERRALS as types } from '../constants';
const initialState = {
  campaign: {
    campaignList: {
      isLoading: false,
      isError: false,
      error: null,
      totalPages: 0,
      data: {}
    },
    campaignDetails: {
      isLoading: false,
      isError: false,
      error: null,
      data: null
    }
  },
  v2: {
    redeemedCoins: {
      isLoading: false,
      isError: false,
      error: null,
      data: null,
      totalPages: 0
    },
    userRedeemedCoins: {
      isLoading: false,
      isError: false,
      error: null,
      data: null,
      totalPages: 0
    }
  }
};

const referralReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SEARCHING_ACTIVE_CAMPAIGNS:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          campaignList: {
            ...state.campaign.campaignList,
            isLoading: true,
            isError: false,
            error: null,
            data: payload.refresh
              ? {}
              : {
                  ...state.campaign.campaignList.data,
                  [payload.pageNo]: null
                }, //clear all pages if refresh is true
            totalPages: payload.refresh
              ? 0
              : state.campaign.campaignList.totalPages
          }
        }
      };

    case types.SEARCH_ACTIVE_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          campaignList: {
            ...state.campaign.campaignList,
            isLoading: false,
            isError: false,
            error: null,
            data: {
              ...state.campaign.campaignList.data,
              [payload.page]: payload?.data?.map((item) => toCampaignData(item))
            },
            totalPages: payload.totalPages
          }
        }
      };

    case types.SEARCH_ACTIVE_CAMPAIGNS_FAILURE:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          campaignList: {
            ...state.campaign.campaignList,
            isLoading: false,
            isError: true,
            error: payload,
            data: {
              ...state.campaign.campaignList.data,
              [payload.page]: null
            },
            totalPages: 0
          }
        }
      };

    case types.GETTING_CAMPAIGN_BY_ID:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          campaignDetails: {
            ...state.campaign.campaignDetails,
            isLoading: true,
            isError: false,
            error: null,
            data: null
          }
        }
      };

    case types.GET_CAMPAIGN_BY_ID_SUCCESS:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          campaignDetails: {
            ...state.campaign.campaignDetails,
            isLoading: false,
            isError: false,
            error: null,
            data: payload
          }
        }
      };

    case types.GET_CAMPAIGN_BY_ID_FAILURE:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          campaignDetails: {
            ...state.campaign.campaignDetails,
            isLoading: false,
            isError: true,
            error: payload,
            data: null
          }
        }
      };

    case types.CLEAR_CAMPAIGN_DETAILS:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          campaignDetails: {
            ...state.campaign.campaignDetails,
            isLoading: false,
            isError: false,
            error: false,
            data: null
          }
        }
      };

    case types.GETTING_REDEEMED_COINS:
      return {
        ...state,
        v2: {
          ...state.v2,
          redeemedCoins: {
            ...state.v2.redeemedCoins,
            isLoading: true,
            isError: false,
            error: null,
            data: null,
            totalPages: 0
          }
        }
      };

    case types.GET_REDEEMED_COINS_SUCCESS:
      return {
        ...state,
        v2: {
          ...state.v2,
          redeemedCoins: {
            ...state.v2.redeemedCoins,
            isLoading: false,
            isError: false,
            error: null,
            data: payload.data,
            totalPages: payload.totalPages
          }
        }
      };

    case types.GET_REDEEMED_COINS_FAILURE:
      return {
        ...state,
        v2: {
          ...state.v2,
          redeemedCoins: {
            ...state.v2.redeemedCoins,
            isLoading: false,
            isError: true,
            error: payload,
            data: null,
            totalPages: 0
          }
        }
      };

    case types.GETTING_USER_REDEEMED_COINS:
      return {
        ...state,
        v2: {
          ...state.v2,
          userRedeemedCoins: {
            ...state.v2.userRedeemedCoins,
            isLoading: true,
            isError: false,
            error: null,
            data: null,
            totalPages: 0
          }
        }
      };

    case types.GET_USER_REDEEMED_COINS_SUCCESS:
      return {
        ...state,
        v2: {
          ...state.v2,
          userRedeemedCoins: {
            ...state.v2.userRedeemedCoins,
            isLoading: false,
            isError: false,
            error: null,
            data: payload.data,
            totalPages: payload.totalPages
          }
        }
      };

    case types.GET_USER_REDEEMED_COINS_FAILURE:
      return {
        ...state,
        v2: {
          ...state.v2,
          userRedeemedCoins: {
            ...state.v2.userRedeemedCoins,
            isLoading: false,
            isError: true,
            error: payload,
            data: null,
            totalPages: 0
          }
        }
      };

    default:
      return state;
  }
};

export default referralReducer;

const ACCESS_MANAGEMENT = {
  GET_USER_PERMISSIONS: 'GET_USER_PERMISSIONS',
  GET_USER_PERMISSIONS_SUCCESS: 'GET_USER_PERMISSIONS_SUCCESS',
  GET_USER_PERMISSIONS_FAILURE: 'GET_USER_PERMISSIONS_FAILURE',
  UPDATE_USER_PERMISSIONS: 'UPDATE_USER_PERMISSIONS',
  UPDATE_USER_PERMISSIONS_SUCCESS: 'UPDATE_USER_PERMISSIONS_SUCCESS',
  UPDATE_USER_PERMISSIONS_FAILURE: 'UPDATE_USER_PERMISSIONS_FAILURE',
  GET_ALL_ROLES: 'GET_ALL_ROLES',
  GET_ALL_ROLES_SUCCESS: 'GET_ALL_ROLES_SUCCESS',
  GET_ALL_ROLES_FAILURE: 'GET_ALL_ROLES_FAILURE',
  CREATE_USER: 'CREATE_USER',
  CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
  CREATE_USER_FAILURE: 'CREATE_USER_FAILURE',
};

export default ACCESS_MANAGEMENT;

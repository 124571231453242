import {
  Grid,
  Heading,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  VStack,
  Text,
  Button,
  HStack,
  IconButton,
  Box,
  Input,
  FormLabel,
  Select
} from '@chakra-ui/react';
import ContentLayout from '../../layouts/ContentLayout';
import { useEffect, useState } from 'react';
import ModalLayout from '../../components/Modal/ModalLayout';
import GenericUpdateModal from '../../components/Modal/GenericUpdateModal';
import { useDispatch, useSelector } from 'react-redux';
import {
  acceptOrderReceipt,
  getPendingOrderReceipts,
  rejectOrderReceipt
} from '../../store/actions/ordersActions';
import { tableCellStyles } from '../../components/NrOnboard/constants';
import api from '../../api';
import {
  FaArrowRight,
  FaCheck,
  FaCross,
  FaExternalLinkAlt,
  FaInfo
} from 'react-icons/fa';
import useIsMobile from '../../hooks/useIsMobile';
import OrderDetailsCard from '../../components/OrderDetailsCard';
import { MdClose } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import Pagination from '../../components/Pagination';
import { updatePendingOrderReceiptsReqData } from '../../store/actions/reqDataActions';
import useDebounce from '../../hooks/useDebounce';
import { setAlert } from '../../store/actions/alertActions';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import { kebabCaseToSpaceSeperate } from '../../utils';
import RejectReceiptModal from '../../components/Modal/RejectReceiptModal';

const PendingOrderReceipts = () => {
  const {
    isOpen: isAcceptReceiptOpen,
    onOpen: onAcceptReceiptOpen,
    onClose: onAcceptReceiptClose
  } = useDisclosure();
  const {
    isOpen: isRejectReceiptOpen,
    onOpen: onRejectReceiptOpen,
    onClose: onRejectReceiptClose
  } = useDisclosure();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedReceipt, setSelectedReceipt] = useState(null);
  const [orderReceipts, setOrderReceipts] = useState(null);
  const [isAcceptLoading, setIsAcceptLoading] = useState(false);
  const [isRejectLoading, setIsRejectLoading] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const {
    orderId,
    pageNumber,
    pageSize,
    totalPages,
    startDate,
    endDate,
    receiptStatus
  } = useSelector((state) => state?.reqData?.pendingOrderReceipts);

  const { name: agentName } = useSelector((state) => state?.auth?.user);

  const debounced = useDebounce(orderId, 700);

  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const fetchPendingOrderReceipts = () => {
    setIsLoading(true);
    dispatch(
      getPendingOrderReceipts({
        orderId,
        pageNumber,
        pageSize,
        receiptStatus,
        startDate,
        endDate,
        onSuccess: (paginatedData) => {
          const { data, totalPages } = paginatedData;
          data && setOrderReceipts(data);
          dispatch(
            updatePendingOrderReceiptsReqData({
              totalPages
            })
          );
        },
        onComplete: () => {
          setIsLoading(false);
        }
      })
    );
  };

  useEffect(() => {
    fetchPendingOrderReceipts();
  }, []);

  const ACTION_TYPES = {
    ACCEPT: 'ACCEPT',
    REJECT: 'REJECT'
  };

  const handleModalOpen = ({ actionType, receipt }) => {
    setSelectedReceipt(receipt);
    if (actionType === ACTION_TYPES.ACCEPT) {
      onAcceptReceiptOpen();
      return;
    }
    onRejectReceiptOpen();
  };

  const handleModalClose = ({ actionType }) => {
    setSelectedReceipt(null);
    if (actionType === ACTION_TYPES.ACCEPT) {
      onAcceptReceiptClose();
      return;
    }
    onRejectReceiptClose();
  };

  const viewOrderReceipt = (docUrl) => {
    api.orders
      .getOrderReceiptLinkByResourcePath({ resourcePath: docUrl })
      .then(({ data }) => {
        window.open(data?.shareLink, '_blank');
      })
      .catch((err) => console.log(err));
  };

  const handleSubmit = ({ actionType, payload }) => {
    if (actionType === ACTION_TYPES.ACCEPT) {
      setIsAcceptLoading(true);
      const { receiptId, paymentReferenceId } = payload;
      dispatch(
        acceptOrderReceipt({
          paymentReferenceId,
          receiptId,
          onSuccess: () => {
            fetchPendingOrderReceipts();
            handleModalClose({ actionType: ACTION_TYPES.ACCEPT });
          },
          onComplete: () => {
            setIsAcceptLoading(false);
          }
        })
      );
      return;
    }

    const { receiptId, rejectionNote } = payload;
    if (!rejectionNote) {
      dispatch(
        setAlert({
          title: 'Please select a rejection reason',
          status: 'error'
        })
      );
      return;
    }
    setIsRejectLoading(true);
    dispatch(
      rejectOrderReceipt({
        rejectionNote: rejectionNote,
        receiptId,
        onSuccess: () => {
          fetchPendingOrderReceipts();
          handleModalClose({ actionType: ACTION_TYPES.REJECT });
        },
        onComplete: () => {
          setIsRejectLoading(false);
        }
      })
    );
  };

  const handlePageChange = (e) => {
    const newPageValue = e;
    newPageValue - 1 < totalPages &&
      dispatch(
        updatePendingOrderReceiptsReqData({
          pageNumber: newPageValue - 1
        })
      );
  };

  const handlePayloadChange = (e) => {
    const key = e?.target?.name;
    const value = e?.target?.value;
    dispatch(
      updatePendingOrderReceiptsReqData({
        [key]: value
      })
    );
  };

  useEffect(() => {
    if (orderReceipts === null) return;
    fetchPendingOrderReceipts();
  }, [pageNumber, debounced, startDate, endDate, receiptStatus]);

  const RECEIPT_STATUS = {
    ACCEPTED: 'ACCEPTED',
    REJECTED: 'REJECTED',
    REVIEW_PENDING: 'REVIEW_PENDING'
  };

  const cellStyles = tableCellStyles();

  const handleRejectSubmit = (data) => {
    const payload = {
      ...data,
      receiptId: selectedReceipt?.receiptId
    };
    handleSubmit({ actionType: ACTION_TYPES.REJECT, payload });
  };

  return (
    <>
      <ModalLayout
        isOpen={isAcceptReceiptOpen}
        onClose={() => {
          handleModalClose({ actionType: ACTION_TYPES.ACCEPT });
        }}
        size="2xl"
      >
        <GenericUpdateModal
          title={`Accept Order Receipt - ${selectedReceipt?.receiptId}`}
          data={{
            paymentReferenceId: selectedReceipt?.paymentReferenceId
          }}
          isLoading={isAcceptLoading}
          onCancel={() => handleModalClose({ actionType: ACTION_TYPES.ACCEPT })}
          onSubmit={(data) => {
            const payload = {
              ...data,
              receiptId: selectedReceipt?.receiptId
            };
            handleSubmit({
              actionType: ACTION_TYPES.ACCEPT,
              payload
            });
          }}
        />
      </ModalLayout>
      {/* reject receipt modal  */}
      <ModalLayout
      isOpen={isRejectReceiptOpen}
      onClose={() => handleModalClose({ actionType: ACTION_TYPES.REJECT })}
      size="2xl">
      <RejectReceiptModal
        isOpen={isRejectReceiptOpen}
        onClose={() => handleModalClose({ actionType: ACTION_TYPES.REJECT })}
        title="Reject Receipt"
        receiptId={selectedReceipt?.receiptId}
        isLoading={isRejectLoading}
        onSubmit={handleRejectSubmit}
      />
      </ModalLayout>
      <ContentLayout>
        <Grid gridTemplateColumns={{ base: '1fr', lg: '6.5fr 2.5fr' }}>
          <VStack
            overflowY={{ base: 'initial', lg: 'scroll' }}
            overflowX={'hidden'}
            h={{ lg: '90vh' }}
            py={10}
            px={{ base: 4, sm: 10 }}
            alignItems={'stretch'}
            className="scroll"
            gap={2}
          >
            <Heading color="white" size={'lg'}>
              Order Receipts
            </Heading>
            {!isLoading ? (
              <VStack width={'full'} alignItems={'stretch'} gap={3}>
                <HStack
                  width={'full'}
                  justifyContent={totalPages > 1 ? 'space-between' : 'end'}
                  alignItems={'end'}
                >
                  <Box>
                    <FormLabel color={'white'}>Search by Order ID</FormLabel>
                    <Input
                      color={'white'}
                      value={orderId}
                      name="orderId"
                      onChange={handlePayloadChange}
                    />
                  </Box>
                  <Box>
                    <FormLabel color={'white'}>Receipt Status</FormLabel>
                    <Select
                      name="receiptStatus"
                      onChange={handlePayloadChange}
                      placeholder="Select Status"
                      value={receiptStatus}
                      color={'white'}
                    >
                      {Object.keys(RECEIPT_STATUS).map((status) => (
                        <option color="white" key={status} value={status}>
                          {kebabCaseToSpaceSeperate(status)}
                        </option>
                      ))}
                    </Select>
                  </Box>
                  <Box>
                    <FormLabel color={'white'}>Start Date</FormLabel>
                    <Input
                      type="date"
                      name="startDate"
                      onChange={handlePayloadChange}
                      value={startDate}
                      max={endDate}
                      color={'white'}
                    />
                  </Box>
                  <Box>
                    <FormLabel color={'white'}>End Date</FormLabel>
                    <Input
                      type="date"
                      value={endDate}
                      max={dayjs().format('YYYY-MM-DD')}
                      name="endDate"
                      onChange={handlePayloadChange}
                      color={'white'}
                    />
                  </Box>
                </HStack>
                <Pagination
                  currentPage={pageNumber + 1}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
                <Table>
                  <Thead>
                    <Tr>
                      <Th style={cellStyles}>Order ID</Th>
                      <Th style={cellStyles}>Payment Reference ID</Th>
                      <Th style={cellStyles}>Receipt ID</Th>
                      <Th style={cellStyles}>Receipt</Th>
                      <Th style={cellStyles}></Th>
                      <Th style={cellStyles}></Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {orderReceipts &&
                      orderReceipts?.map((orderReceipt) => {
                        return (
                          <Tr key={orderReceipt?.receiptId}>
                            <Td style={cellStyles}>
                              <HStack>
                                <Text color={'white'} fontSize={'md'}>
                                  {orderReceipt.orderId}
                                </Text>
                                <IconButton
                                  variant={'unstyled'}
                                  icon={<FaInfo />}
                                  onClick={() => {
                                    setSelectedOrder(orderReceipt);
                                  }}
                                />
                              </HStack>
                            </Td>
                            <Td style={cellStyles}>
                              <Text color={'white'} fontSize={'md'}>
                                {orderReceipt.paymentReferenceId || '-'}
                              </Text>
                            </Td>
                            <Td style={cellStyles}>
                              <Text color={'white'} fontSize={'md'}>
                                {orderReceipt.receiptId || '-'}
                              </Text>
                            </Td>
                            <Td style={cellStyles}>
                              <Button
                                variant={'link'}
                                colorScheme="brand"
                                onClick={() => {
                                  viewOrderReceipt(orderReceipt.resourcePath);
                                }}
                                iconSpacing={2}
                                rightIcon={<FaExternalLinkAlt />}
                              >
                                View Receipt
                              </Button>
                            </Td>
                            <Td style={cellStyles}>
                              <Button
                                onClick={() =>
                                  handleModalOpen({
                                    actionType: ACTION_TYPES.ACCEPT,
                                    receipt: orderReceipt
                                  })
                                }
                                variant={'link'}
                                colorScheme="brand"
                                rightIcon={<FaCheck />}
                              ></Button>
                            </Td>
                            <Td style={cellStyles}>
                              <Button
                                rightIcon={<MdClose size={'22px'} />}
                                onClick={() => {
                                  handleModalOpen({
                                    actionType: ACTION_TYPES.REJECT,
                                    receipt: orderReceipt
                                  });
                                }}
                                variant={'link'}
                                colorScheme="red"
                              >
                                {/* Reject */}
                              </Button>
                            </Td>
                          </Tr>
                        );
                      })}
                  </Tbody>
                </Table>
              </VStack>
            ) : (
              <Spinner color="white" />
            )}
          </VStack>
          {!isMobile &&
            (!selectedOrder?.orderId ? null : (
              <VStack
                maxHeight={'100vh'}
                overflowY={'scroll'}
                alignItems={'stretch'}
                // width={'full'}
                gap={1}
              >
                <HStack px={4} py={1} justifyContent={'space-between'}>
                  <Button
                    variant={'link'}
                    onClick={() => {
                      navigate(`/user/${selectedOrder?.userId}`);
                    }}
                    colorScheme="brand"
                    rightIcon={<FaArrowRight />}
                  >
                    User Details
                  </Button>
                  <IconButton
                    variant={'unstyled'}
                    icon={<MdClose />}
                    onClick={() => {
                      setSelectedOrder(null);
                    }}
                  />
                </HStack>
                <OrderDetailsCard orderId={selectedOrder?.orderId} />
              </VStack>
            ))}
        </Grid>
      </ContentLayout>
    </>
  );
};

export default PendingOrderReceipts;
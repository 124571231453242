import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import ContentLayout from '../../layouts/ContentLayout';
import {
  Text,
  Grid,
  Heading,
  Stack,
  VStack,
  Spinner,
  Button
} from '@chakra-ui/react';
import {
  clearGoldenTicketInfo,
  getGoldenTicketInfo
} from '../../store/actions/usersActions';
import GenericCard from '../../components/GenericCard';
import { MdEast } from 'react-icons/md';
import { CARD_TYPE } from '../../constants';

const GoldenTickets = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userId } = useParams();
  const { isLoading, data: goldenTicketInfo } = useSelector(
    (state) => state.users.userDetails.goldenTickets
  );

  useEffect(() => {
    dispatch(getGoldenTicketInfo(userId));
    return () => dispatch(clearGoldenTicketInfo());
  }, []);

  const onUserDetailsClick = (userId) => navigate('/user/' + userId);
  return (
    <ContentLayout>
      <Grid gridTemplateColumns={{ base: '1fr', lg: '7fr 0fr 2fr' }}>
        {' '}
        <VStack
          p={10}
          gap={4}
          px={{ base: 4, sm: 10 }}
          alignItems={'flex-start'}
          w={'100%'}
        >
          <Stack
            direction={{ base: 'column', md: 'row' }}
            justifyContent={'space-between'}
            w={'100%'}
            alignItems={{ base: 'flex-start', md: 'center' }}
            gap={{ base: 3, md: 1 }}
          >
            <VStack alignItems={'flex-start'}>
              <Heading color={'colorPrimary'} pb={2}>
                Golden Tickets
              </Heading>
            </VStack>
          </Stack>
          <VStack
            py={5}
            alignSelf={'flex-start'}
            w={{ base: '100%', lg: '56rem' }}
            maxW={'100%'}
            alignItems={'stretch'}
            gap={1}
          >
            {isLoading ? (
              <Spinner color="brand.600" />
            ) : !goldenTicketInfo?.goldenTickets?.length ? (
              <Text color="brand.500">No golden tickets</Text>
            ) : (
              goldenTicketInfo.goldenTickets?.map((item) => (
                <GenericCard
                  key={item.ticketId}
                  cardData={item}
                  cardType={CARD_TYPE.GOLDEN_TICKET_CARD}
                  ActionButtons={
                    item.referredUserId === null
                      ? []
                      : [
                          <Button
                            key={'user-details-link'}
                            mt={{ base: 2, md: 0 }}
                            ml={{ base: 0, md: 1 }}
                            rightIcon={<MdEast color="black" />}
                            type="submit"
                            rounded="lg"
                            color={'black'}
                            colorScheme="brand"
                            alignSelf={{ base: 'center', md: 'center' }}
                            px={5}
                            onClick={() =>
                              onUserDetailsClick(item.referredUserId)
                            }
                          >
                            User Details
                          </Button>
                        ]
                  }
                />
              ))
            )}
          </VStack>
        </VStack>
      </Grid>
    </ContentLayout>
  );
};

export default GoldenTickets;

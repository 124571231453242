import {
  Box,
  Button,
  FormLabel,
  HStack,
  Input,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Select,
  Textarea,
  VStack
} from '@chakra-ui/react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../store/actions/alertActions';
import { getParsedObj } from '../../utils';

const RecursiveComponent = ({ data, stackedKey = '', depth = 0, onChange }) => {
  function isNotStringBooleanOrNumber(element) {
    return (
      typeof element !== 'string' &&
      typeof element !== 'boolean' &&
      typeof element !== 'number'
    );
  }

  return (
    <>
      {Object.keys(data).map((key) => {
        if (typeof data[key] === 'object' && Array.isArray(data[key])) {
          return (
            <Box key={stackedKey === '' ? key : stackedKey + '.' + key}>
              <FormLabel>{key}</FormLabel>
              <VStack>
                {data[key]?.map((value, ind) => (
                  <Input
                    name={stackedKey === '' ? key : stackedKey + '.' + key}
                    type={typeof value === 'number' ? 'number' : 'text'}
                    value={value}
                    key={
                      stackedKey === ''
                        ? key + '.' + ind
                        : stackedKey + '.' + key + '.' + ind
                    }
                    onChange={onChange}
                    id={ind}
                  />
                ))}
              </VStack>
            </Box>
          );
        }

        if (
          typeof data[key] === 'object' &&
          isNotStringBooleanOrNumber(data[key])
        ) {
          return (
            <VStack
              key={stackedKey === '' ? key : stackedKey + '.' + key}
              width={'full'}
              alignItems={'stretch'}
            >
              {depth > 0 && <FormLabel>{key}</FormLabel>}
              <RecursiveComponent
                data={data[key]}
                stackedKey={stackedKey === '' ? key : stackedKey + '.' + key}
                depth={depth + 1}
                onChange={onChange}
              />
            </VStack>
          );
        }

        return (
          <Box key={stackedKey === '' ? key : stackedKey + '.' + key}>
            <FormLabel>{key}</FormLabel>
            {typeof data[key] !== 'boolean' ? (
              <Input
                type={typeof data[key] === 'number' ? 'number' : 'text'}
                value={data[key]}
                name={stackedKey === '' ? key : stackedKey + '.' + key}
                onChange={onChange}
              />
            ) : (
              <Select
                name={stackedKey === '' ? key : stackedKey + '.' + key}
                value={data[key]}
                onChange={onChange}
                placeholder="Select value"
              >
                <option value={true}>True</option>
                <option value={false}>False</option>
              </Select>
            )}
          </Box>
        );
      })}
    </>
  );
};

const UpdateFeatureFlagModal = ({
  initialFeatureState,
  isLoading,
  onCancel,
  onSubmit
}) => {
  const featureName = initialFeatureState?.featureName;
  const featureValue = initialFeatureState?.featureValue;
  const [value, setValue] = useState(
    JSON.stringify(getParsedObj(featureValue), null, 4) || ''
  );

  const dispatch = useDispatch();

  const handleChange = (e) => {
    const value = e?.target?.value;
    setValue(value);
  };

  const handleCancel = () => {
    setValue(featureValue);
    onCancel && onCancel();
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    console.log({ featureName, featureValue: value });
    try {
      const updated = JSON.parse(value);
      onSubmit({
        featureName,
        featureValue: JSON.stringify(updated)
      });
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message || error,
          status: 'error'
        })
      );
    }
  };

  return (
    <>
      <ModalHeader>Update - " {initialFeatureState?.featureName} "</ModalHeader>
      <form onSubmit={handleSubmit}>
        <ModalBody>
          <FormLabel>Config Value</FormLabel>
          <Textarea
            rows={15}
            color={'white'}
            outline={'none'}
            _focus={{
              background: 'white.500',
              outline: 'none'
            }}
            background={'whiteAlpha.300'}
            width={'full'}
            value={value}
            onChange={handleChange}
          />
        </ModalBody>
        <ModalFooter>
          <HStack>
            <Button
              variant={'outline'}
              colorScheme="red"
              type="button"
              isDisabled={isLoading}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              variant={'outline'}
              colorScheme="brand"
              isLoading={isLoading}
              type="submit"
            >
              Update
            </Button>
          </HStack>
        </ModalFooter>
      </form>
    </>
  );
};

export default UpdateFeatureFlagModal;

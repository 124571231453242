import { HStack, Heading, VStack, Text, Grid, Button } from '@chakra-ui/react';
import React, { useState } from 'react';
import api from '../../api';
import { AiOutlineFile } from 'react-icons/ai';
import { isPlainObject } from '@reduxjs/toolkit';

const WorkflowScreenDataCard = ({
  userId,
  title,
  status,
  data,
  isV2 = false
}) => {
  const [activeFile, setActiveFile] = useState(null);
  const [isFileLoading, setIsFileLoading] = useState(false);

  const onFileClick = (url, fileName) => {
    setActiveFile(fileName);
    setIsFileLoading(true);
    if (!isV2) {
      api.workflow
        .docByDocUrl({ docUrl: url })
        .then(({ data }) => window.open(data?.url, '_blank'))
        .catch((err) => console.log(err))
        .finally(() => {
          setIsFileLoading(false);
          setActiveFile(null);
        });
      return;
    }
    api.workflowsV2
      .getDocByUrl({ userId: userId, docUrl: url })
      .then(({ data }) => window.open(data?.url, '_blank'))
      .catch((err) => console.log(err))
      .finally(() => {
        setIsFileLoading(false);
        setActiveFile(null);
      });
  };

  return (
    <VStack p={6} bg={'altGray.400'} borderRadius={'1rem'}>
      <HStack justifyContent={'space-between'} w={'100%'} pb={3}>
        <Heading size={'md'} color={'#ddd'} maxW={'85%'}>
          {title}
        </Heading>
        <Text
          color={status === 'FILLED' ? 'brand.200' : 'yellow.200'}
          fontSize={'sm'}
        >
          {status}{' '}
        </Text>
      </HStack>
      <Grid
        gap={2}
        gridTemplateColumns={'0.8fr 1fr'}
        w={'100%'}
        justifyContent={'flex-start'}
        fontSize={'0.9rem'}
      >
        {data &&
          Object.keys(data).map((key) => {
            return (
              <React.Fragment key={key}>
                <Text
                  color="shadedGray.400"
                  maxWidth={'20rem'}
                  whiteSpace={'break-word'}
                >
                  {key} :
                </Text>
                {data[key]?.isFile ? (
                  <Button
                    variant={'link'}
                    textDecoration={'underline'}
                    color="white"
                    maxWidth={'20rem'}
                    whiteSpace={'break-word'}
                    rightIcon={<AiOutlineFile />}
                    onClick={() => onFileClick(data[key]?.fileUrl, key)}
                    justifyContent={'flex-start'}
                    fontSize={'0.9rem'}
                    fontWeight={400}
                    isLoading={key === activeFile && isFileLoading}
                  >
                    {isPlainObject(data[key].value)
                      ? data[key].value
                      : JSON.stringify(data[key].value)}
                  </Button>
                ) : (
                  <Text
                    color="white"
                    maxWidth={'20rem'}
                    whiteSpace={'break-word'}
                    maxHeight={'30rem'}
                    className={'scroll-sm'}
                    overflowY={'auto'}
                  >
                    {!isPlainObject(data[key].value)
                      ? data[key].value
                      : JSON.stringify(data[key].value)}
                  </Text>
                )}
              </React.Fragment>
            );
          })}
      </Grid>
    </VStack>
  );
};

export default WorkflowScreenDataCard;

import { Box, HStack, Select } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { getSubStatesFromActiveState } from '../../utils/NrobFunnel';
import { updateNrobReqData } from '../../store/actions/reqDataActions';
import { useEffect } from 'react';

const NrobFunnelSubStateFilter = ({}) => {
  const funnelReqData = useSelector(
    (state) => state?.reqData?.funnel?.nronboard
  );
  const { activeFunnel, reqData } = funnelReqData;

  const dispatch = useDispatch();

  const subStates = getSubStatesFromActiveState(activeFunnel);

  const handleSubStateChange = (e) => {
    const value = e?.target?.value;
    if (!value) return;
    dispatch(
      updateNrobReqData({
        ...funnelReqData,
        reqData: {
          ...reqData,
          [activeFunnel]: {
            ...reqData[activeFunnel],
            subState: value
          }
        }
      })
    );
  };

  //   useEffect(() => {
  //     // if () return;
  //     dispatch(
  //       updateNrobReqData({
  //         ...funnelReqData,
  //         reqData: {
  //           ...reqData,
  //           [activeFunnel]: {
  //             ...reqData[activeFunnel],
  //             subState: ''
  //           }
  //         }
  //       })
  //     );
  //   }, [activeFunnel]);

  if (!subStates) return;

  return (
    <HStack justifyContent={'end'} width={'auto'} alignItems={'center'}>
      <Box maxWidth={'15rem'}>
        <Select
          placeholder="select substate"
          value={reqData[activeFunnel].subState}
          onChange={handleSubStateChange}
          bg="black"
          color="gray"
          size={'sm'}
          rounded={'lg'}
          _focus={{
            border: '1px solid #81EBAB',
            boxShadow: 'none'
          }}
        >
          {Object.keys(subStates).map((state) => (
            <option key={state} value={state}>
              {state}
            </option>
          ))}
        </Select>
      </Box>
    </HStack>
  );
};

export default NrobFunnelSubStateFilter;

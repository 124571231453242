import { ALERT as types } from '../constants';
const initialState = {
  isActive: false,
  data: {
    title: '',
    status: '',
    duration: 3000,
    isClosable: true,
    position: 'top',
    variant: 'subtle'
  }
};

const alertReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_ALERT:
      return {
        ...state,
        isActive: true,
        data: {
          ...state.data,
          title: payload.title,
          status: payload.status
        }
      };

    case types.CLEAR_ALERT:
      return {
        ...state,
        isActive: false,
        data: {
          ...state.data,
          title: '',
          status: ''
        }
      };

    default:
      return state;
  }
};

export default alertReducer;

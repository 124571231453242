import { Spinner, VStack } from '@chakra-ui/react';
import React, { useCallback, useEffect } from 'react';
import OrdersList from '../../components/OrdersList';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserInvestmentOrdersReqData } from '../../store/actions/reqDataActions';
import useDidMountEffect from '../../hooks/useDidMount';
import useIsMobile from '../../hooks/useIsMobile';
import { useNavigate } from 'react-router-dom';
import { PRODUCT } from '../../constants';
import {
  clearUserInvestmentOrders,
  getUserInvestmentOrders
} from '../../store/actions/investmentActions';

const UserInvestments = ({ userId, setSelectedOrder }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const {
    data: orders,
    totalPages,
    isLoading
  } = useSelector((state) => state.investments.userOrders);

  const searchReqData = useSelector(
    (state) => state.reqData.user.orders.investments
  );

  useDidMountEffect(() => {
    if (!orders.length) dispatch(getUserInvestmentOrders(searchReqData));

    return () => dispatch(clearUserInvestmentOrders());
  }, []);

  useEffect(() => {
    dispatch(
      getUserInvestmentOrders({
        userId,
        ...searchReqData
      })
    );
  }, [searchReqData]);

  const handlePageChange = useCallback(
    (pageNo) => {
      pageNo - 1 !== searchReqData.pageNo &&
        dispatch(updateUserInvestmentOrdersReqData({ pageNo: pageNo - 1 }));
    },
    [dispatch, searchReqData.pageNo]
  );

  const onOrderDetailsClick = useCallback(
    (orderId) => {
      if (isMobile) navigate(`/investments/orders/${orderId}`);
      else
        setSelectedOrder({
          product: PRODUCT.INVEST_MF,
          orderId
        });
    },
    [isMobile, userId, navigate, setSelectedOrder]
  );

  if (isLoading) return <Spinner color="brand.500" />;

  return (
    <VStack w={'100%'} alignItems={'stretch'} gap={'0.5rem'}>
      <OrdersList
        orders={orders}
        pageNo={searchReqData.pageNo}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
        onOrderDetailsClick={onOrderDetailsClick}
        product={PRODUCT.INVEST_MF}
      />
    </VStack>
  );
};

export default UserInvestments;

import {
  VStack,
  Box,
  Text,
  useDisclosure,
  Spinner,
  HStack,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Th,
  IconButton
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getNrOnboardDetails,
  rejectUserDocumentsV2,
  getOnboardUserDocsV2,
  verifyDocs
} from '../../store/actions/nronboardActions';
import api from '../../api';
import ModalLayout from './../Modal/ModalLayout';
import NrobDocRejectModal from './DocRejectStepperModal';
import {
  NROB_MANDATORY_DOCS,
  NROB_USER_TYPES,
  NR_OB_COUNTRY,
  NR_OB_UAE_REQ_DOCS,
  NR_OB_UK_REQ_DOCS,
  ROLES
} from '../../constants';
import { tableCellStyles } from './constants';
import JsonViewer from '../Modal/JsonViewer';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { TfiClose } from 'react-icons/tfi';
import ConfirmModal from '../Modal/ConfirmForceInitiate';
import useAuthRole from '../../hooks/useAuthRole';

const DocumentCards = ({
  documents,
  isLoading,
  userId,
  showRejectBtn = false
}) => {
  const { country } = useSelector((state) => state?.nronboard?.details?.data);
  const { onboardingUserType } = useSelector(
    (state) => state?.nronboard?.details?.data
  );
  const { user: cxUser } = useSelector((state) => state?.auth);
  const {
    isOpen: isJsonViewerOpen,
    onClose: closeJsonViewer,
    onOpen: openJsonViewer
  } = useDisclosure();
  const {
    isOpen: isDocRejectOpen,
    onOpen: onDocRejectOpen,
    onClose: onDocRejectClose
  } = useDisclosure();
  const {
    isOpen: isDocsVerifyOpen,
    onOpen: onDocsVerifyOpen,
    onClose: onDocsVerifyClose
  } = useDisclosure();

  const [selectedRejectReason, setSelectedRejectReason] = useState(null);
  const [btnLoading, setBtnLoading] = useState(false);
  const dispatch = useDispatch();
  const { hasAnyAuthority } = useAuthRole();

  const handleDocClick = (docUrl) => {
    api.workflowsV2
      .getDocByUrl({ userId, docUrl })
      .then(({ data }) => window.open(data?.url, '_blank'))
      .catch((err) => console.log(err));
  };

  const handleJsonViewerClose = () => {
    setSelectedRejectReason('');
    closeJsonViewer();
  };
  const handleJsonViewerOpen = (rejectReason) => {
    setSelectedRejectReason(rejectReason);
    openJsonViewer();
  };

  const handleDocReject = ({ rejectReason, rejectedDocuments }) => {
    setBtnLoading(true);
    const workflowType =
      country === NR_OB_COUNTRY.AE
        ? onboardingUserType === NROB_USER_TYPES.NR_WAITLIST
          ? 'UAE_NR_WAITLIST_WORKFLOW'
          : 'UAE_NR_ACCOUNT_OPENING_VERIFICATION_DOCUMENTS'
        : onboardingUserType === NROB_USER_TYPES.NR_WAITLIST
        ? 'UK_NR_WAITLIST_WORKFLOW'
        : 'NR_ACCOUNT_OPENING_VERIFICATION_DOCUMENTS';
    dispatch(
      rejectUserDocumentsV2({
        userId,
        rejectedDocuments,
        rejectReason,
        remarks: `Rejected by: ${cxUser?.firstName} ${cxUser?.lastName}`,
        workflowType,
        onSuccess: () => {
          dispatch(getNrOnboardDetails(userId));
          country &&
            dispatch(
              getOnboardUserDocsV2({
                userId,
                country,
                requiredDocs:
                  country === NR_OB_COUNTRY.AE
                    ? NR_OB_UAE_REQ_DOCS
                    : NR_OB_UK_REQ_DOCS
              })
            );
        },
        onComplete: () => {
          setBtnLoading(false);
        }
      })
    );
  };

  const handleDocsVerify = () => {
    setBtnLoading(true);
    dispatch(
      verifyDocs({
        userId,
        remarks: `Verified by: ${cxUser.firstName} ${cxUser.lastName}`,
        onSuccess: () => {
          dispatch(getNrOnboardDetails(userId));
          country &&
            dispatch(
              getOnboardUserDocsV2({
                userId,
                country,
                requiredDocs:
                  country === NR_OB_COUNTRY.AE
                    ? NR_OB_UAE_REQ_DOCS
                    : NR_OB_UK_REQ_DOCS
              })
            );
        },
        onComplete: () => {
          setBtnLoading(false);
        }
      })
    );
  };

  const cellStyle = tableCellStyles();

  return (
    <>
      <ModalLayout isOpen={isDocRejectOpen} onClose={onDocRejectClose}>
        <NrobDocRejectModal
          btnLoading={btnLoading}
          country={country}
          handleRejectSubmit={handleDocReject}
          // docs={transformDocsToRejectModal(documents, country)}
          docs={documents?.map((doc) => doc?.docName)}
        />
      </ModalLayout>
      <ModalLayout isOpen={isDocsVerifyOpen} onClose={onDocsVerifyClose}>
        <ConfirmModal
          prompt={
            "Are you sure you want to verify docs? once verified the documents can't be rejected"
          }
          handleSubmit={handleDocsVerify}
          isLoading={btnLoading}
        />
      </ModalLayout>
      <ModalLayout isOpen={isJsonViewerOpen} onClose={handleJsonViewerClose}>
        <JsonViewer
          jsonString={selectedRejectReason}
          title={'Rejection Reason'}
        />
      </ModalLayout>
      <Box padding={'1'}>
        <VStack alignItems={'stretch'} width={'auto'} gap={2}>
          <Text color={'white'} fontSize={'xl'}>
            Uploaded Documents
          </Text>
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              {!documents?.length && (
                <Text color={'shadedGray.400'}>No documents found</Text>
              )}

              {documents?.length > 0 && (
                <Table>
                  <Thead>
                    <Tr>
                      <Th style={cellStyle}>
                        <Text color={'white'} fontSize={'sm'}>
                          Document Name
                        </Text>
                      </Th>
                      <Th style={cellStyle}>
                        <Text color={'white'} fontSize={'sm'}>
                          File
                        </Text>
                      </Th>
                      <Th style={cellStyle}>
                        <Text color={'white'} fontSize={'sm'}>
                          Rejected?
                        </Text>
                      </Th>
                      <Th style={cellStyle}>
                        <Text color={'white'} fontSize={'sm'}>
                          Rejection Reason
                        </Text>
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {documents?.map((doc, ind) => (
                      <Tr key={doc?.docName}>
                        <Td style={cellStyle}>
                          <Text color={'gray.400'} fontSize={'sm'}>
                            {doc?.docName}
                          </Text>
                        </Td>
                        <Td style={cellStyle}>
                          {!doc?.docUrl ? (
                            <Text color={'white'} fontSize={'md'}>
                              -
                            </Text>
                          ) : (
                            <Button
                              variant={'link'}
                              colorScheme={'shadedGray'}
                              onClick={() => handleDocClick(doc?.docUrl)}
                            >
                              View file
                            </Button>
                          )}
                        </Td>
                        <Td style={cellStyle}>
                          <Text color={'gray.400'} fontSize={'sm'}>
                            {doc?.isRejected ? <TfiClose color="red" /> : '-'}
                          </Text>
                        </Td>
                        <Td style={cellStyle}>
                          {doc?.isRejected ? (
                            <FaExternalLinkAlt
                              style={{
                                color: '#a0aec0'
                              }}
                              cursor={'pointer'}
                              onClick={() => {
                                handleJsonViewerOpen(doc?.rejectionReason);
                              }}
                            />
                          ) : (
                            <Text color={'white'} fontSize={'md'}>
                              -
                            </Text>
                          )}
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              )}
              {showRejectBtn &&
                hasAnyAuthority(ROLES.MANAGER_ROLE, ROLES.ADMIN_ROLE) && (
                  <HStack gap={2} justifyContent={'end'} alignItems={'center'}>
                    {
                      // TODO: set according to on-board-status of the user
                      onboardingUserType === NROB_USER_TYPES.NR_WAITLIST && (
                        <Button
                          variant={'outline'}
                          colorScheme="brand"
                          onClick={onDocsVerifyOpen}
                        >
                          Verify Docs
                        </Button>
                      )
                    }
                    <Button
                      variant={'outline'}
                      colorScheme="shadedGray"
                      onClick={onDocRejectOpen}
                    >
                      Reject User Documents
                    </Button>
                  </HStack>
                )}
            </>
          )}
        </VStack>
      </Box>
    </>
  );
};

export default DocumentCards;

export default function configApi(fetch, baseUrl = '') {
  return {
    getSettlementMessage({ countryCode, provider = null }) {
      if (!provider)
        return fetch.get(`${baseUrl}/settlement-message/${countryCode}`);
      return fetch.get(
        `${baseUrl}/settlement-message/${countryCode}?provider=${provider}`
      );
    },

    getPollingStatus() {
      return fetch.get(`${baseUrl}/polling-status`);
    },

    updateSettlementMessage({
      isDelayed,
      countryCode,
      settlementMessage,
      provider = null
    }) {
      return fetch.put(
        `${baseUrl}/settlement-message/${countryCode}`,
        !provider
          ? { settlementMessage }
          : {
              settlementMessage,
              provider,
              isDelayed
            }
      );
    },

    updatePollingStatus({ pollingStatus }) {
      return fetch.put(`${baseUrl}/polling-status`, {
        poolingStatus: pollingStatus
      });
    },

    updateNiftyReturns(niftyReturns) {
      return fetch.put(`${baseUrl}/fund-benchmarks/nifty-returns`, {
        niftyReturns
      });
    },

    updateFdReturns(fdReturns) {
      return fetch.put(`${baseUrl}/fund-benchmarks/fd-returns`, { fdReturns });
    },

    getFundBenchmarkReturns() {
      return fetch.get(`${baseUrl}/fund-benchmarks`);
    },

    getConfigList() {
      return fetch.get(`${baseUrl}/list`);
    },

    updateConfigItem({ value, configType }) {
      return fetch.post(`${baseUrl}`, {
        value,
        configType
      });
    },

    getTurnAroundTime() {
      return fetch(`${baseUrl}/transfer-tat`);
    },

    updateTurnAroundTime(payload) {
      return fetch.put(`${baseUrl}/transfer-tat`, payload);
    }
  };
}

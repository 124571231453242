import api from '../../api';
import { setAlert } from './alertActions';

export const getReconEntries =
  ({
    fromDate,
    status,
    orderId,
    pageNumber,
    pageSize,
    onSuccess,
    onComplete
  }) =>
  async (dispatch) => {
    try {
      const { data } = await api.baerscrestOps.getReconEntries({
        fromDate,
        status,
        orderId,
        pageNumber,
        pageSize
      });
      onSuccess && onSuccess(data);
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const mapReconToPayment =
  ({ transactionId, reqBody, onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      await api.baerscrestOps.mapReconToPayment({ transactionId, reqBody });
      onSuccess && onSuccess();
    } catch (error) {
      dispatch(setAlert({ title: error.message, status: 'error' }));
    } finally {
      onComplete && onComplete();
    }
  };

export const getRecentOrdersByUserId =
  ({ userId, onComplete, onSuccess }) =>
  async (dispatch) => {
    try {
      const { data } = await api.baerscrestOps.getRecentOrdersByUserId(userId);
      onSuccess && onSuccess(data);
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

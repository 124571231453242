import { VStack, Grid, Heading } from '@chakra-ui/react';
import DataElement from './DataElement';
import dayjs from 'dayjs';

const OffRampData = ({ offRampData }) => {
  return (
    // <VStack alignItems={'stretch'} width={'full'} gap={2}>
    //   <Heading color={'white'} size={'sm'}>
    //     Off Ramp Data
    //   </Heading>
    <Grid
      gridTemplateColumns={{
        sm: '1fr',
        md: '1fr 1fr 1fr 1fr'
      }}
      gap={4}
    >
      {/* transaction data  */}
      <DataElement title={'Txn ID'} value={offRampData?.txnId} />
      <DataElement title={'Txn Hash'} value={offRampData?.txnHash} />
      <DataElement title={'Txn Status'} value={offRampData?.status} />
      <DataElement title={'Txn Type'} value={offRampData?.transferType} />
      <DataElement
        title={'Created At'}
        value={dayjs(offRampData?.createdAt).format('DD MMM YYYY')}
      />
      {/* amount details  */}
      <DataElement title={'Crypto Amount'} value={offRampData?.cryptoAmount} />
      <DataElement
        title={'Receive Amount'}
        value={offRampData?.receiveAmount}
      />
      {/* payment status  */}
      <DataElement title={'Fulfillment ID'} value={offRampData?.payoutId} />
      <DataElement
        title={'Fulfillment Status'}
        value={offRampData?.payoutStatus}
      />
    </Grid>
    // </VStack>
  );
};

export default OffRampData;

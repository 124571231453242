import { Flex, VStack, Text, Button } from '@chakra-ui/react';
import React from 'react';
import { formatFunnelStatus } from './utils';

const FunnelBar = ({
  setActiveFunnel,
  activeFunnel,
  isListLoading,
  funnelData,
  isFilterEnabled = false,
  transformDisplayVal = null
}) => {
  return (
    <Flex wrap={'wrap'} rowGap={4}>
      {funnelData &&
        Object.keys(funnelData).map((status) => (
          <VStack mx={2} key={status}>
            <Text color={'purple.100'}>
              {isFilterEnabled
                ? funnelData[status].unfiltered
                : funnelData[status]}
            </Text>
            <Button
              bg={status === activeFunnel ? 'colorPrimary' : 'altGray.400'}
              color={status === activeFunnel ? 'black' : 'white'}
              fontWeight={400}
              rounded={'md'}
              size={'sm'}
              isLoading={status === activeFunnel && isListLoading}
              disabled={status !== activeFunnel && isListLoading}
              _hover={{
                backgroundColor:
                  status === activeFunnel ? 'brand.600' : 'altGray.200'
              }}
              onClick={() => setActiveFunnel(status)}
            >
              {!transformDisplayVal
                ? formatFunnelStatus(status)
                : transformDisplayVal(status)}
            </Button>
          </VStack>
        ))}
    </Flex>
  );
};

export default FunnelBar;

import { Box, Text, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getForexRate } from '../../store/actions/rdaActions';

const RdaFxRate = () => {
  const [fxRate, setFxRate] = useState(0.0);
  const dispatch = useDispatch();

  useEffect(() => {
    const trigger = () =>
      dispatch(
        getForexRate({
          onSuccess: (data) => {
            setFxRate(data);
          }
        })
      );

    const timeout = setTimeout(() => {
      trigger();
    }, 2000);

    trigger();

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <Box>
      <VStack alignItems={'start'}>
        <Text color={'gray.400'} fontSize={'sm'}>
          FxRate:
        </Text>
        <Text color={'white'} fontSize={'xl'}>
          {fxRate}
        </Text>
      </VStack>
    </Box>
  );
};

export default RdaFxRate;

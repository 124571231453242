export default function userSourceAccount(fetch, baseUrl) {
  return {
    getUserSourceAccounts({ userId, page, pageSize }) {
      return fetch.get(
        `${baseUrl}/${userId}/source-accounts?page=${page}&page_size=${pageSize}`
      );
    },
    deleteUserSourceAccount({ userId, sourceAccountId }) {
      return fetch.delete(
        `${baseUrl}/${userId}/source-accounts/${sourceAccountId}`
      );
    }
  };
}

const NR_ACCOUNT = {
  GETTING_NR_USERS: 'GETTING_NR_USERS',
  GET_NR_USERS_SUCCESS: 'GET_NR_USERS_SUCCESS',
  GET_NR_USERS_FAILURE: 'GET_NR_USERS_FAILURE',
  GETTING_NR_USER_DETAILS: 'GETTING_NR_USER_DETAILS',
  GET_NR_USER_DETAILS_SUCCESS: 'GET_NR_USER_DETAILS_SUCCESS',
  GET_NR_USER_DETAILS_FAILURE: 'GET_NR_USER_DETAILS_FAILURE',
  CLEAR_NR_USER_DETAILS: 'CLEAR_NR_USER_DETAILS',
  UPDATING_NR_USER_STATUS: 'UPDATING_NR_USER_STATUS',
  UPDATE_NR_USER_STATUS_SUCCESS: 'UPDATE_NR_USER_STATUS_SUCCESS',
  UPDATE_NR_USER_STATUS_FAILURE: 'UPDATE_NR_USER_STATUS_FAILURE',
  GETTING_ONBOARDING_DETAILS: 'GETTING_ONBOARDING_DETAILS',
  GET_ONBOARDING_DETAILS_SUCCESS: 'GET_ONBOARDING_DETAILS_SUCCESS',
  GET_ONBOARDING_DETAILS_FAILURE: 'GET_ONBOARDING_DETAILS_FAILURE',
  CLEAR_ONBOARDING_DETAILS: 'CLEAR_ONBOARDING_DETAILS'
};

export default NR_ACCOUNT;

import {
  Box,
  Divider,
  Grid,
  HStack,
  Heading,
  Select,
  VStack
} from '@chakra-ui/react';
import ContentLayout from '../../layouts/ContentLayout';
import { useEffect, useState } from 'react';
import { NROB_ACTIVE_STEPS, NR_OB_COUNTRY } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { updateNrobReqData } from '../../store/actions/reqDataActions';
import { getNronboardFunnel } from '../../store/actions/funnelActions';
import FunnelBar from './FunnelBar';
import JsonToCsvParser from '../../components/NrOnboard/JsonToCsvParser';
import useIsMobile from '../../hooks/useIsMobile';
import NrobFunnelList from '../../components/NrOnboard/NrobFunnelList';
import { getActiveStepDisplayValue } from '../../utils/NrobFunnel';

const NronboardFunnel = () => {
  const dispatch = useDispatch();
  const funnelReqData = useSelector(
    (state) => state?.reqData?.funnel?.nronboard
  );
  const { activeFunnel, country, reqData, initialized } = funnelReqData;
  const funnelState = useSelector((state) => state?.funnel?.nronboard);
  const { isLoading, data: funnelData, isError } = funnelState;
  const { pageNo } = reqData[activeFunnel];

  const isMobile = useIsMobile();

  useEffect(() => {
    if (initialized) return;
    triggerFunnelStates();
    dispatch(
      updateNrobReqData({
        ...funnelReqData,
        initialized: true
      })
    );
  }, []);

  useEffect(() => {
    if (!initialized) return;
    dispatch(
      getNronboardFunnel({
        funnelState: activeFunnel,
        funnelSubstate: reqData[activeFunnel]?.subState,
        pageNo: reqData[activeFunnel]?.pageNo,
        country,
        additionalFilters: reqData[activeFunnel]?.additionalFilters
      })
    );
  }, [reqData[activeFunnel]]);

  useEffect(() => {
    if (!country) return;
    triggerFunnelStates();
  }, [country]);

  const handleFunnelChange = (funnel) => {
    dispatch(
      updateNrobReqData({
        ...funnelReqData,
        activeFunnel: funnel
      })
    );
  };

  const handleCountryChange = (e) => {
    const country = e?.target?.value;
    dispatch(
      updateNrobReqData({
        ...funnelReqData,
        country
      })
    );
  };

  const triggerFunnelStates = () => {
    Object.keys(NROB_ACTIVE_STEPS).forEach((state) => {
      dispatch(
        getNronboardFunnel({
          funnelState: state,
          country,
          ...(reqData[state]?.subState && {
            funnelSubstate: reqData[state]?.subState
          })
        })
      );
    });
  };

  const handlePageChange = (val) => {
    val !== pageNo - 1 &&
      dispatch(
        updateNrobReqData({
          activeFunnel,
          country,
          initialized,
          reqData: {
            ...reqData,
            [activeFunnel]: {
              ...reqData[activeFunnel],
              pageNo: val - 1
            }
          }
        })
      );
  };

  return (
    <ContentLayout>
      <Grid gridTemplateColumns={{ base: '1fr', lg: '7fr 0fr 2fr' }}>
        <VStack
          gap={4}
          overflowY={{ base: 'initial', lg: 'scroll' }}
          overflowX={'hidden'}
          h={{ lg: '90vh' }}
          py={10}
          px={{ base: 4, sm: 10 }}
          alignItems={'stretch'}
          className="scroll"
        >
          <FunnelBar
            activeFunnel={activeFunnel}
            setActiveFunnel={(funnel) => handleFunnelChange(funnel)}
            funnelData={Object.keys(NROB_ACTIVE_STEPS).reduce(
              (prev, curr) => ({
                ...prev,
                [curr]: funnelData[curr]?.totalSize || 0
              }),
              {}
            )}
            isListLoading={isLoading}
            transformDisplayVal={getActiveStepDisplayValue}
          />
          <HStack justifyContent={'space-between'}>
            <Heading color={'white'} fontSize={'lg'}>
              {getActiveStepDisplayValue(activeFunnel)}
            </Heading>
            <Box maxWidth={'15rem'}>
              <Select
                placeholder="Select a country"
                value={country}
                onChange={handleCountryChange}
                bg="black"
                name="country"
                color="gray"
                size={'sm'}
                rounded={'lg'}
                _focus={{
                  border: '1px solid #81EBAB',
                  boxShadow: 'none'
                }}
              >
                {Object.keys(NR_OB_COUNTRY).map((country) => (
                  <option key={country} value={country}>
                    {country}
                  </option>
                ))}
              </Select>
            </Box>
          </HStack>
          <NrobFunnelList
            filterType={activeFunnel}
            currentPage={pageNo}
            onPageChange={handlePageChange}
            totalPages={funnelData[activeFunnel]?.totalPages || 0}
            isLoading={isLoading}
            data={funnelData[activeFunnel]?.data}
          />
        </VStack>
        <Divider
          style={{ margin: '0' }}
          borderColor="whiteAlpha.300"
          orientation="vertical"
        />
        {/* {!isMobile && (
          <Box m={'1rem'} mr={0} pr={'1rem'} alignSelf={'flex-start'}>
            <VStack gap={2} alignItems={'start'}>
              <JsonToCsvParser />
            </VStack>
          </Box>
        )} */}
      </Grid>
    </ContentLayout>
  );
};

export default NronboardFunnel;

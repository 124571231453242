import ModalLayout from '../Modal/ModalLayout';
import {
  Box,
  Flex,
  VStack,
  TableContainer,
  Table,
  Tbody,
  Button,
  Td,
  Tr,
  Text,
  useDisclosure
} from '@chakra-ui/react';
import CopyableText from '../CopyableText';
import TicketModal from '../Modal/TicketModal';
import { useDispatch, useSelector } from 'react-redux';
import { updateOrderTicketData } from '../../store/actions/ordersActions';
import { useEffect } from 'react';
import { getAllCxUsers } from '../../store/actions/cxUsersActions';
import { IoTicket } from 'react-icons/io5';

const TicketDetails = ({
  isMobile = false,
  ticketData,
  referenceId,
  ticketExists,
  rowGap = 0,
  titleW = 45,
  allowUpdateActions = false
}) => {
  const { data: cxUsers } = useSelector((state) => state?.cxUsers);

  const transformTicketData = (ticketData) => [
    {
      id: 1,
      name: 'Reference ID',
      value: ticketData?.referenceId,
      isCopyable: true
    },
    {
      name: 'Status',
      value: ticketData?.status
    },
    {
      name: 'Ticket Type',
      value: ticketData?.ticketType
    },
    {
      name: 'Assigned To',
      value: cxUsers[ticketData?.assignedTo]?.name || '-'
    },
    {
      name: 'Notes',
      value: ticketData?.notes
    }
  ];

  const dispatch = useDispatch();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleUpdateTicketOnOrder = (ticketData) => {
    ticketData && dispatch(updateOrderTicketData(ticketData));
  };

  useEffect(() => {
    !Object.keys(cxUsers)?.length && dispatch(getAllCxUsers());
  }, []);

  return (
    <>
      <ModalLayout isOpen={isOpen} onClose={onClose}>
        {/* TODO: create ticket modal goes here */}
        <TicketModal
          assignedTo={ticketData?.assignedTo || null}
          ticketId={ticketData?.ticketId || null}
          notes={ticketData?.notes || null}
          referenceId={referenceId}
          ticketType={ticketData?.ticketType}
          onClose={onClose}
          callback={(ticketData) => {
            handleUpdateTicketOnOrder(ticketData);
          }}
        />
      </ModalLayout>
      <Box py={2}>
        <VStack gap={1} alignItems={'stretch'}>
          <Flex w={'full'} justifyContent={'space-between'}>
            <Text color={'white'}>Ticket</Text>
          </Flex>
          {!ticketExists ? (
            allowUpdateActions ? (
              <VStack w={'100%'}>
                <Button
                  onClick={onOpen}
                  size="sm"
                  colorScheme="brand"
                  color="black"
                  rightIcon={<IoTicket />}
                >
                  Raise Ticket
                </Button>
              </VStack>
            ) : null
          ) : (
            <>
              <TableContainer mt={'1rem'}>
                <Table
                  variant="simple"
                  w={'100%'}
                  style={{
                    borderCollapse: 'separate',
                    borderSpacing: `0 ${rowGap}`
                  }}
                >
                  <Tbody>
                    {transformTicketData(ticketData)?.map((item, idx) => (
                      <Tr key={idx}>
                        <Td
                          color={'gray'}
                          fontSize="sm"
                          pt="0"
                          pb={'1.3'}
                          //   pl={isSidebar ? 0 : 4}
                          border="none"
                          w={`${titleW}%`}
                          overflowWrap={'break-word'}
                        >
                          {item.name}
                        </Td>
                        <Td
                          color={'white'}
                          fontSize="sm"
                          p="0"
                          pb={'1.3'}
                          border="none"
                          pr={4}
                          w={`${100 - titleW}%`}
                        >
                          {item.isCopyable ? (
                            <CopyableText
                              w={isMobile ? 'initial' : '100%'}
                              minW={isMobile ? 'none' : '160px'}
                              wordBreak="break-all"
                              whiteSpace={'normal'}
                              m={0}
                              title={item.name
                                .trim()
                                .slice(0, item.name.length - 1)}
                            >
                              {item.value}
                            </CopyableText>
                          ) : (
                            <Text
                              w={isMobile ? 'initial' : '100%'}
                              minW={isMobile ? 'none' : '160px'}
                              wordBreak="break-all"
                              whiteSpace={'normal'}
                              m={0}
                            >
                              {item.value}
                            </Text>
                          )}
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
              {allowUpdateActions && (
                <VStack w={'100%'}>
                  <Button
                    onClick={onOpen}
                    size="sm"
                    colorScheme="brand"
                    color="black"
                    rightIcon={<IoTicket />}
                  >
                    Edit Ticket
                  </Button>
                </VStack>
              )}
            </>
          )}
        </VStack>
      </Box>
    </>
  );
};

export default TicketDetails;

export const falcon_ops = {
  GETTING_FALCON_TRANSACTIONS: 'GETTING_FALCON_TRANSACTIONS',
  GET_FALCON_TRANSACTIONS_SUCCESS: 'GET_FALCON_TRANSACTIONS_SUCCESS',
  GET_FALCON_TRANSACTIONS_FAILURE: 'GET_FALCON_TRANSACTIONS_FAILURE',

  GETTING_FALCON_TRANSACTION_DETAILS: 'GETTING_FALCON_TRANSACTION_DETAILS',
  GET_FALCON_TRANSACTION_DETAILS_FAILURE:
    'GET_FALCON_TRANSACTION_DETAILS_FAILURE',
  GET_FALCON_TRANSACTION_DETAILS_SUCCESS:
    'GET_FALCON_TRANSACTION_DETAILS_SUCCESS',
  UPDATE_FALCON_TRANSATION_DETAILS: 'UPDATE_FALCON_TRANSATION_DETAILS'
};

import {
  Button,
  HStack,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Select,
  Text,
  VStack,
  Input,
  Box,
  Image,
  FormLabel,
  Spinner,
  Heading,
  Divider
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { kebabCaseToSpaceSeperate } from '../../utils';
import { KYC_V2_STATUS } from '../../constants';
import api from '../../api';
import { useParams } from 'react-router-dom';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../store/actions/alertActions';

const ImageViewer = ({ userId, docType }) => {
  const [imageUrl, setImageUrl] = useState('');
  const [loading, setLoading] = useState(false);

  const fetchImage = () => {
    setLoading(true);
    api.workflow
      .docByDocType({
        userId,
        documentTye: docType
      })
      .then(({ data }) => {
        setImageUrl(data?.url);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchImage();
  }, [docType]);

  if (loading) return <Spinner />;

  return (
    <Box>
      <Image
        width={'full'}
        maxHeight={'50vh'}
        src={imageUrl}
        fit={'contain'}
        alt="Document photo"
      />
    </Box>
  );
};

const VerifyAllDocsModal = ({ isLoading, docs, onSubmit, onCancel }) => {
  const INITIAL_STATE = docs?.reduce((prev, curr) => {
    return {
      ...prev,
      [curr?.subDocumentType]: {
        status:
          curr?.status === KYC_V2_STATUS.VERIFIED ||
          curr?.status === KYC_V2_STATUS.REJECTED
            ? curr?.status
            : '',
        kycDetailsId: curr?.id,
        remarks: ''
      }
    };
  }, {});

  const [modalState, setModalState] = useState(INITIAL_STATE);

  const { userId } = useParams();

  const [selectedInd, setSelectedInd] = useState(0);
  const dispatch = useDispatch();

  const handleCancel = () => {
    onCancel && onCancel();
    setModalState(INITIAL_STATE);
  };

  const handleSubmit = (e) => {
    console.log(modalState);
    e.preventDefault();
    try {
      onSubmit &&
        onSubmit(
          Object.keys(modalState)?.map((doc) => {
            const { status } = modalState[doc];
            if (status === 'REJECTED' || status === 'VERIFIED') {
              return modalState[doc];
            }
            throw new Error(
              `${doc} - status should be marked as either VERIFIED or REJECTED`
            );
          })
        );
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    }
    // onSubmit && onSubmit(Object.values(modalState).map((value) => value));
  };

  const selectedDoc = Object.keys(modalState)[selectedInd];

  const handleChange = (e) => {
    const name = e?.target?.name;
    const value = e?.target?.value;
    const doc = e?.target?.id;
    setModalState((prev) => ({
      ...prev,
      [doc]: {
        ...prev[doc],
        [name]: value
      }
    }));
  };

  return (
    <>
      <ModalHeader>Verify Docs</ModalHeader>
      <form onSubmit={handleSubmit}>
        <ModalBody>
          <VStack gap={2} alignItems={'stretch'} width={'full'}>
            <HStack
              width={'full'}
              alignItems={'start'}
              gap={4}
              justifyContent={'center'}
            >
              <Box width={'full'}>
                <HStack justifyContent={'space-between'}>
                  <Button
                    variant={'unstyled'}
                    rightIcon={<BsArrowLeft />}
                    type="button"
                    isDisabled={selectedInd === 0}
                    onClick={() => {
                      if (selectedInd < 1) return;
                      setSelectedInd((prev) => prev - 1);
                    }}
                  />
                  <Heading size={'sm'} color={'white'}>
                    {kebabCaseToSpaceSeperate(selectedDoc)}
                  </Heading>
                  <Button
                    variant={'unstyled'}
                    rightIcon={<BsArrowRight />}
                    type="button"
                    isDisabled={
                      selectedInd >= Object.keys(modalState).length - 1
                    }
                    onClick={() => {
                      if (selectedInd >= Object.keys(modalState).length - 1)
                        return;
                      setSelectedInd((prev) => prev + 1);
                    }}
                  />
                </HStack>
                <ImageViewer userId={userId} docType={selectedDoc} />
              </Box>
              <VStack
                maxHeight={'50vh'}
                overflowY={'scroll'}
                gap={2}
                alignItems={'stretch'}
                width={'full'}
              >
                {Object.keys(modalState).map((doc, ind) => (
                  <VStack key={doc} width={'full'} alignItems={'stretch'}>
                    <Heading size={'sm'} color="white" marginBottom={2}>
                      {kebabCaseToSpaceSeperate(doc)}
                    </Heading>
                    <Box>
                      <FormLabel>KYC Status</FormLabel>
                      <Select
                        name="status"
                        placeholder="Select KYC Status"
                        value={modalState[doc]?.status}
                        onChange={handleChange}
                        id={doc}
                      >
                        <option value={'VERIFIED'}>Mark as Verified</option>
                        <option value={'REJECTED'}>Mark as Rejected</option>
                      </Select>
                    </Box>
                    <Box>
                      <FormLabel>Remarks</FormLabel>
                      <Input
                        name="remarks"
                        type="text"
                        placeholder="remarks"
                        onChange={handleChange}
                        value={modalState[doc]?.remarks}
                        id={doc}
                      />
                    </Box>
                    {ind !== Object.keys(modalState).length - 1 && (
                      <Divider color={'white'} marginY={2}></Divider>
                    )}
                  </VStack>
                ))}
              </VStack>
            </HStack>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <HStack>
            <Button
              variant={'outline'}
              onClick={handleCancel}
              type="button"
              isDisabled={isLoading}
              colorScheme="red"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              isLoading={isLoading}
              variant={'outline'}
              colorScheme="brand"
            >
              Update
            </Button>
          </HStack>
        </ModalFooter>
      </form>
    </>
  );
};

export default VerifyAllDocsModal;

import {
  Button,
  FormLabel,
  HStack,
  IconButton,
  Input,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Select,
  VStack,
  Box
} from '@chakra-ui/react';
import { ORDER_SCREEN_CONF_COMP_TYPES } from '../../constants';
import { useState } from 'react';
import { kebabCaseToSpaceSeperate } from '../../utils';
import { MdClose } from 'react-icons/md';
import { v4 as uuidV4 } from 'uuid';

const ScreenConfigCompModal = ({
  data,
  isLoading = false,
  handleCancel = () => {},
  handleSubmit = () => {}
}) => {
  const initialState = !data
    ? {
        type: '',
        id: '',
        label: '',
        options: []
      }
    : {
        ...data,
        options: data?.options?.map((option) => ({
          ...option,
          id: uuidV4()
        }))
      };

  const [compState, setCompState] = useState(initialState);

  function convertValToId(input, isUpperCase = false) {
    // Remove special characters and replace multiple spaces with underscores
    let formattedString = input
      .replace(/[^a-zA-Z0-9\s]/g, '')
      .replace(/\s+/g, '_');

    // Convert the string to uppercase
    formattedString = !isUpperCase
      ? formattedString.toLowerCase()
      : formattedString.toUpperCase();

    return formattedString;
  }

  function cleanupCompState(componentState) {
    return {
      ...componentState,
      id: convertValToId(componentState?.label),
      options: componentState?.options?.map((option) => ({
        text: option?.text,
        value: convertValToId(option?.text, true)
      }))
    };
  }

  const handleFormSubmit = (e) => {
    e?.preventDefault();
    handleSubmit && handleSubmit(cleanupCompState(compState));
  };

  const handleLabelChange = (e) => {
    const value = e?.target?.value;
    setCompState((prevState) => ({
      ...prevState,
      label: value
    }));
  };

  const handleAddOption = () => {
    setCompState((prevState) => ({
      ...prevState,
      options: [
        ...prevState?.options,
        {
          id: uuidV4(),
          text: 'Option'
        }
      ]
    }));
  };

  const handleEditOption = (value, id) => {
    if (!id) return;
    setCompState((prevState) => ({
      ...prevState,
      options: prevState?.options?.map((option) =>
        option?.id === id
          ? {
              ...option,
              text: value
            }
          : option
      )
    }));
  };

  const handleDeleteOption = (id) => {
    if (!id) return;
    setCompState((prevState) => ({
      ...prevState,
      options: prevState?.options?.filter((option) => option?.id !== id)
    }));
  };

  return (
    <>
      <ModalHeader>Config Component</ModalHeader>
      <form onSubmit={handleFormSubmit}>
        <ModalBody>
          <VStack gap={2} alignItems={'stretch'} width={'auto'}>
            <Box>
              <FormLabel>Component Type</FormLabel>
              <Select
                placeholder="Choose Component Type"
                value={compState?.type}
                onChange={(e) => {
                  const value = e?.target?.value;
                  setCompState((prevState) => ({
                    ...prevState,
                    type: value
                  }));
                }}
              >
                {Object.keys(ORDER_SCREEN_CONF_COMP_TYPES).map((compType) => {
                  return (
                    <option key={compType} value={compType}>
                      {kebabCaseToSpaceSeperate(compType)}
                    </option>
                  );
                })}
              </Select>
            </Box>
            {compState?.type && (
              <>
                <Box>
                  <FormLabel>Label for component</FormLabel>
                  <Input
                    type="text"
                    name="label"
                    value={compState?.label}
                    onChange={handleLabelChange}
                    required
                  />
                </Box>
                {compState?.type === ORDER_SCREEN_CONF_COMP_TYPES.DROP_DOWN && (
                  <>
                    <FormLabel>Options</FormLabel>
                    {compState?.options.map((option) => (
                      <HStack key={option?.id}>
                        <Input
                          value={option?.text}
                          onChange={(e) =>
                            handleEditOption(e?.target?.value, option?.id)
                          }
                          required
                        />
                        <IconButton
                          variant={'outline'}
                          onClick={() => handleDeleteOption(option?.id)}
                          icon={<MdClose />}
                        />
                      </HStack>
                    ))}
                    <HStack justifyContent={'end'}>
                      <Button variant={'link'} onClick={handleAddOption}>
                        Add Option
                      </Button>
                    </HStack>
                  </>
                )}
              </>
            )}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <HStack justifyContent={'end'}>
            <Button
              isLoading={isLoading}
              onClick={handleCancel}
              type="button"
              variant={'outline'}
            >
              Cancel
            </Button>
            <Button
              isLoading={isLoading}
              type="submit"
              variant={'outline'}
              colorScheme="brand"
            >
              Submit
            </Button>
          </HStack>
        </ModalFooter>
      </form>
    </>
  );
};

export default ScreenConfigCompModal;

import {
  Box,
  Button,
  Divider,
  Grid,
  Heading,
  Select,
  Stack,
  VStack
} from '@chakra-ui/react';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { MdSearch } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import InputComponent from '../../components/Input';
import UsersList from '../../components/UsersList';
import useDidMountEffect from '../../hooks/useDidMount';
import ContentLayout from '../../layouts/ContentLayout';
import { searchNrUsers } from '../../store/actions/nrAccountActions';
import { updateNrUsersReqData } from '../../store/actions/reqDataActions';
import {
  NR_ACCOUNT_ONBOARDING_STATUS,
  NR_ACCOUNT_SEARCH_KEYS
} from '../../constants';

const NrAccountUsers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const searchTimer = useRef(null);
  const filterValueTimer = useRef(null);

  const searchReqData = useSelector((state) => state.reqData.nrAccount.users);

  const searchResult = useSelector((state) => ({
    users: state.nrAccount.users.pages[searchReqData.pageNo]?.data || [],
    isCached:
      state.nrAccount.users.pages[searchReqData.pageNo]?.isCached || false,
    totalPages: state.nrAccount.users.totalPages || 0,
    isLoading: state.nrAccount.users.isLoading
  }));

  useDidMountEffect(() => {
    if (searchTimer.current) {
      clearTimeout(searchTimer.current);
      searchTimer.current = setTimeout(() => {
        searchUsers(true);
      }, 2000);
    } else {
      searchUsers(true);
      searchTimer.current = true;
    }
  }, [searchReqData.query]);

  useDidMountEffect(() => {
    if (
      searchReqData.filterKey !== '' &&
      searchReqData.filterValue === '' &&
      searchReqData.filterKey !==
        NR_ACCOUNT_SEARCH_KEYS.ACCOUNT_STATUS.displayText
    )
      return;

    if (
      searchReqData.filterKey !==
        NR_ACCOUNT_SEARCH_KEYS.ACCOUNT_STATUS.displayText &&
      searchReqData.filterKey !== ''
    ) {
      if (filterValueTimer.current) {
        clearTimeout(filterValueTimer.current);
        filterValueTimer.current = setTimeout(() => {
          searchUsers(true);
        }, 2000);
      } else {
        searchUsers(true);
        filterValueTimer.current = true;
      }
    } else {
      searchUsers(true);
    }
  }, [searchReqData.filterValue]);

  useDidMountEffect(() => {
    dispatch(
      updateNrUsersReqData({
        filterValue: ''
      })
    );
  }, [searchReqData.filterKey]);

  useDidMountEffect(() => {
    searchUsers();
  }, [searchReqData.pageNo]);

  useEffect(() => {
    if (searchResult.users?.length) return;

    searchUsers(true);
  }, []); //force fetch on page mount, and if referralcode in url changes

  const searchUsers = async (refresh = false) => {
    if (refresh || !searchResult.isCached)
      dispatch(
        searchNrUsers(
          {
            ...searchReqData,
            filterKey: searchReqData.filterKey?.length
              ? NR_ACCOUNT_SEARCH_KEYS[searchReqData.filterKey]?.key
              : ''
          },
          refresh
        )
      );
  };

  const handleSearchReqChange = useCallback(
    (key) => (e) => {
      dispatch(updateNrUsersReqData({ [key]: e.target.value, page: 0 }));
    },
    [dispatch]
  );

  //todo: enable when filter by key and value is available from backend
  // const handleFilterKeyChange = (e) => {
  //   dispatch(
  //     updateNrUsersReqData({
  //       pageNo: 0,
  //       filterKey: e.target.value
  //     })
  //   );
  // };

  const handleFilterValueChange = useCallback(
    (e) => {
      dispatch(
        updateNrUsersReqData({
          pageNo: 0,
          filterValue: e.target.value
        })
      );
    },
    [dispatch]
  );

  const handlePageChange = useCallback(
    (pageNo) => {
      pageNo - 1 !== searchReqData.pageNo &&
        dispatch(updateNrUsersReqData({ pageNo: pageNo - 1 }));
    },
    [dispatch, searchReqData.pageNo]
  );

  const onUserCardClick = useCallback(
    (userId) => {
      navigate(`/nr-account/users/${userId}/onboarding`);
    },
    [navigate]
  );

  const renderFilterValueInput = useCallback(() => {
    switch (searchReqData.filterKey) {
      case '':
      case null:
        return null;

      case NR_ACCOUNT_SEARCH_KEYS.ACCOUNT_STATUS.displayText:
        return (
          <Box width="100%">
            <Box maxWidth="20rem" my={2}>
              <Select
                placeholder="Filter account status"
                onChange={handleFilterValueChange}
                bg="black"
                color="gray"
                value={searchReqData.filterValue}
              >
                {Object.keys(NR_ACCOUNT_ONBOARDING_STATUS).map((status) => (
                  <option value={status} key={status}>
                    {status}
                  </option>
                ))}
              </Select>
            </Box>
          </Box>
        );

      default:
        return (
          <InputComponent
            type="text"
            value={searchReqData.filterValue}
            name="filterValue"
            handleChange={handleFilterValueChange}
            placeholder="Enter filter value"
            width="15rem"
            maxWidth="100%"
            autoComplete={'off'}
            spellcheck={false}
            autoCorrect={'off'}
            // alignSelf={'flex-end'} //remove comment out when more keys added
          />
        );
    }
  }, [
    searchReqData.filterKey,
    searchReqData.filterValue,
    handleFilterValueChange
  ]);

  return (
    <ContentLayout>
      <Grid gridTemplateColumns={{ base: '1fr', lg: '7fr 0fr 2fr' }}>
        <VStack
          p={10}
          gap={4}
          overflowY={{ base: 'initial', lg: 'scroll' }}
          overflowX={'hidden'}
          h={{ lg: '90vh' }}
          px={{ base: 4, sm: 10 }}
          alignItems={'stretch'}
          className="scroll"
        >
          <Heading alignSelf={'flex-start'} color={'colorPrimary'}>
            NRE/NRO Users
          </Heading>{' '}
          <InputComponent
            type="text"
            value={searchReqData.query}
            name="search"
            handleChange={handleSearchReqChange('query')}
            placeholder="Search by name, email, or mobile number...."
            inputTitle="Search users"
            leftElement={<MdSearch size="2rem" color="white" />}
            width="40rem"
            maxWidth="100%"
            autoComplete={'off'}
            spellcheck={false}
            autoCorrect={'off'}
          />
          <Stack
            direction={{ base: 'column', md: 'row' }}
            alignItems={'center'}
            width={'40rem'}
            maxW={'100%'}
            justifyContent={'space-between'}
          >
            {/* REMOVE COMMENT OUT WHEN MORE SEARCH KEYS ADDED */}
            {/* <Box width="100%">
              <Box maxWidth="15rem" my={2}>
                <Select
                  placeholder="Enter filter criteria"
                  onChange={handleFilterKeyChange}
                  bg="black"
                  color="gray"
                  value={searchReqData.filterKey}
                >
                  {Object.keys(NR_ACCOUNT_SEARCH_KEYS).map((key) => (
                    <option value={key} key={key}>
                      {key}
                    </option>
                  ))}
                </Select>
              </Box>
            </Box> */}
            {renderFilterValueInput()}
          </Stack>
          <Button
            type="submit"
            color="black"
            rounded="lg"
            colorScheme="brand"
            my={5}
            py={2}
            onClick={() => searchUsers(true)}
            fontWeight={600}
            isLoading={searchResult.isLoading}
            alignSelf="start"
            style={{
              marginTop: '1em'
            }}
          >
            Search
          </Button>
          <UsersList
            users={searchResult.users}
            pageNo={searchReqData.pageNo}
            totalPages={searchResult.totalPages}
            handlePageChange={handlePageChange}
            onUserCardClick={onUserCardClick}
            isNrUser
          />
        </VStack>
        <Divider
          style={{ margin: '0' }}
          borderColor="whiteAlpha.300"
          orientation="vertical"
        />
      </Grid>
    </ContentLayout>
  );
};

export default NrAccountUsers;

import { Box } from '@chakra-ui/react';

export default function DashboardLayout({ children, ...props }) {
  return (
    <Box
      bg="black"
      minHeight={'100vh'}
      h={{ lg: '100vh' }}
      display={{ base: 'block', lg: 'flex' }}
      justifyContent="end"
      alignItems="center"
      m={0}
      // divider={
      //   <StackDivider style={{ margin: '0' }} borderColor="whiteAlpha.300" />
      // }
    >
      {children}
    </Box>
  );
}

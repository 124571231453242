const YBL_DETAILS = {
  GETTING_YBL_DETAILS: 'GETTING_YBL_DETAILS',
  GET_YBL_DETAILS_SUCCESS: 'GET_YBL_DETAILS_SUCCESS',
  GET_YBL_DETAILS_FAILURE: 'GET_YBL_DETAILS_FAILURE',
  UPDATING_YBL_DETAILS: 'UPDATING_YBL_DETAILS',
  UPDATE_YBL_DETAILS_SUCCESS: 'UPDATE_YBL_DETAILS_SUCCESS',
  UPDATE_YBL_DETAILS_FAILURE: 'UPDATE_YBL_DETAILS_FAILURE',
  MODIFY_YBL_FIELD_DATA: 'MODIFY_YBL_FIELD_DATA',
  RESET_YBL_FORM_STATE: 'RESET_YBL_FORM_STATE'
};

export default YBL_DETAILS;

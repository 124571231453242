export default function (fetch, baseUrl) {
  return {
    getYblBalance() {
      return fetch.get(`${baseUrl}/balances/ybl`);
    },
    getYblPotBalance() {
      return fetch.get(`${baseUrl}/balances/ybl/pots`);
    },
    getTrueLayerBalance() {
      return fetch.get(`${baseUrl}/balances/true_layer`);
    },
    getOverallTxns({ status, fromDate, pageNumber, pageSize }) {
      return fetch.get(
        `${baseUrl}?status=${status}&from_date=${fromDate}&page_number=${pageNumber}&page_size=${pageSize}`
      );
    },
    getTrueLayerPartnerTxns({ fromDate, pageNumber, pageSize }) {
      return fetch.get(
        `${baseUrl}/partners/true_layer?from_date=${fromDate}&page_number=${pageNumber}&page_size=${pageSize}`
      );
    },
    getClearBankPartnerTxns({
      transactionType,
      fromDate,
      pageNumber,
      pageSize
    }) {
      return fetch.get(
        `${baseUrl}/partners/clear_bank?from_date=${fromDate}&page_number=${pageNumber}&page_size=${pageSize}&transaction_type=${transactionType}`
      );
    },
    getYblPartnerTxns({ fromDate, pageNumber, pageSize }) {
      return fetch.get(
        `${baseUrl}/partners/ybl?from_date=${fromDate}&page_number=${pageNumber}&page_size=${pageSize}`
      );
    },
    getRdaGbpBalance() {
      return fetch.get(`${baseUrl}/balances/rda_gbp`);
    }
  };
}

import React, { useEffect } from 'react';
import ContentLayout from '../../layouts/ContentLayout';
import {
  HStack,
  Heading,
  VStack,
  Text,
  IconButton,
  Stack,
  useDisclosure,
  Grid,
  Link
} from '@chakra-ui/react';
import { Link as ReactLink, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { AiFillEdit } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearNrUserAccountDetails,
  clearOnboardingDetails,
  getNrUserDetails,
  getOnboardingDetails
} from '../../store/actions/nrAccountActions';
import FullPageSpinner from '../../components/FullPageSpinner';
import ModalLayout from '../../components/Modal/ModalLayout';
import UpdateNrAccountStatus from '../../components/Modal/UpdateNrAccountStatus';
import OnboardingCard from '../../components/OnboardingCard';
import { camelCaseToSpaceSeparated, toScreenDataList } from '../../utils';
import { BsArrowRight } from 'react-icons/bs';
import { setAlert } from '../../store/actions/alertActions';

const Onboarding = () => {
  const { userId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: account, isLoading: isAccountLoading } = useSelector(
    (state) => state.nrAccount.userDetails
  );

  const {
    data: onboarding,
    isLoading: isOnboardingLoading,
    isError,
    error
  } = useSelector((state) => state.nrAccount.onboarding);

  const {
    isOpen: isEditStatusOpen,
    onOpen: onEditStatusOpen,
    onClose: onEditStatusClose
  } = useDisclosure();

  useEffect(() => {
    dispatch(getNrUserDetails(userId));
    dispatch(getOnboardingDetails(userId));
  }, [userId, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearNrUserAccountDetails());
      dispatch(clearOnboardingDetails());
    };
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      dispatch(
        setAlert({
          title:
            error.message ||
            'Something went wrong while fetching onboarding details!',
          status: 'error'
        })
      );
      navigate(-1);
    }
  }, [isError, dispatch, navigate]);

  if (isAccountLoading || isOnboardingLoading) return <FullPageSpinner />;

  return (
    <>
      <ModalLayout isOpen={isEditStatusOpen} onClose={onEditStatusClose}>
        <UpdateNrAccountStatus
          userId={userId}
          currentStatus={account?.accountStatus}
          onClose={onEditStatusClose}
        />
      </ModalLayout>
      <ContentLayout>
        <Grid gridTemplateColumns={{ base: '1fr', lg: '7fr 0fr 2fr' }}>
          {' '}
          <VStack
            p={10}
            gap={4}
            px={{ base: 4, sm: 10 }}
            alignItems={'flex-start'}
            w={'100%'}
          >
            <Stack
              direction={{ base: 'column', md: 'row' }}
              justifyContent={'space-between'}
              w={'100%'}
              alignItems={{ base: 'flex-start', md: 'center' }}
              gap={{ base: 3, md: 1 }}
            >
              <VStack alignItems={'flex-start'}>
                <Heading color={'colorPrimary'} pb={2}>
                  Onboarding Details
                </Heading>
                <HStack color={'colorPrimary'}>
                  <Text
                    color={'shadedGray.500'}
                    fontSize={{ base: '0.75rem', md: '0.9rem' }}
                  >
                    STATUS :
                  </Text>
                  <Text fontSize={{ base: '0.75rem', md: '0.9rem' }}>
                    {account?.accountStatus || ''}
                  </Text>
                  <IconButton
                    rounded={'full'}
                    color={'altGray.400'}
                    bg={'brand.200'}
                    _hover={{
                      color: 'brand.200',
                      background: 'altGray.400'
                    }}
                    size={'sm'}
                    fontWeight={400}
                    icon={<AiFillEdit />}
                    onClick={onEditStatusOpen}
                  />
                </HStack>
                <HStack>
                  <Text
                    color={'shadedGray.500'}
                    fontSize={{ base: '0.75rem', md: '0.9rem' }}
                  >
                    Account Types :
                  </Text>
                  <Text
                    color={'colorPrimary'}
                    fontSize={{ base: '0.75rem', md: '0.9rem' }}
                  >
                    {onboarding?.accountTypes?.join(',') || ''}
                  </Text>
                </HStack>
              </VStack>
              <VStack alignItems={{ base: 'flex-start', md: 'flex-end' }}>
                <Link
                  as={ReactLink}
                  to={`/user/${userId}`}
                  color="brand.500"
                  justifyContent={'flex-start'}
                >
                  <HStack>
                    <Text>User details</Text>
                    <BsArrowRight display={'inline'} />
                  </HStack>
                </Link>
              </VStack>
            </Stack>
            <Grid
              gridTemplateColumns={{ base: '1fr', md: '1fr 1fr' }}
              w={'100%'}
              columnGap={6}
              rowGap={8}
            >
              {onboarding?.screenModels &&
                Object.keys(onboarding?.screenModels).map((screen) => (
                  <OnboardingCard
                    key={screen}
                    title={camelCaseToSpaceSeparated(screen)}
                    status={
                      onboarding.screenModels[screen]?.screenFillingStatus
                    }
                    data={toScreenDataList(
                      onboarding.screenModels[screen]?.screenData
                    )}
                  />
                ))}
            </Grid>
          </VStack>
        </Grid>
      </ContentLayout>
    </>
  );
};

export default Onboarding;

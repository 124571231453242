import { Stack, HStack, Icon, Img, Text, VStack, Grid } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import profile from '../../logo/profile.svg';

const UserCard = ({ user, onUserCardClick, isNrUser = false }) => {
  const {
    name,
    createdAt,
    updatedAt,
    countryCode,
    phoneNumber,
    email,
    kycStatus,
    kycStatusColor,
    nrAccountStatus,
    userId
  } = user;
  const navigate = useNavigate();

  const defaultClickHandler = (userId) => {
    navigate(`/user/${userId}`);
  };

  return (
    <VStack
      cursor={'pointer'}
      bg="backGround"
      borderRadius="12px"
      w={'52rem'}
      maxW={'100%'}
      onClick={() =>
        onUserCardClick ? onUserCardClick(userId) : defaultClickHandler(userId)
      }
    >
      <Stack
        direction={{ base: 'column', sm: 'row' }}
        w={'90%'}
        justifyContent="space-between"
        mt={'1rem'}
      >
        <HStack>
          <Img src={profile} w={'2rem'} />
          <Text fontSize="md" color="white">
            {name}
          </Text>
        </HStack>
        <Stack direction={'row'}>
          <Text fontSize="md" color="white">
            {isNrUser ? 'Modified at: ' : 'Created on:'}
          </Text>
          <Text fontSize="md" color="white">
            {isNrUser ? updatedAt : createdAt}
          </Text>
        </Stack>
      </Stack>
      <HStack w={{ base: '100%', sm: '85%' }}>
        <Grid
          gridTemplateColumns={{
            base: '1fr',
            md: `1fr 1.2fr 0.9fr `
          }}
          gap={2}
          w={{ base: '100%', sm: '100%', lg: '85%' }}
          pb={'1rem'}
          alignItems={{ base: 'flex-start', sm: 'center' }}
          justifyContent={'flex-start'}
          ps={{ base: '1rem', md: 0 }}
        >
          <VStack alignItems={'flex-start'}>
            <Text fontSize={'sm'} color={'gray'}>
              {countryCode} {phoneNumber}
            </Text>
          </VStack>
          <VStack alignItems={'flex-start'}>
            <Text fontSize={'sm'} color={'gray'}>
              {email}
            </Text>
          </VStack>
          {isNrUser ? (
            <VStack alignItems={'flex-end'}>
              <Text fontSize={'sm'} color={'gray'}>
                {nrAccountStatus}
              </Text>
            </VStack>
          ) : // (
          //   <HStack justifySelf={{ base: 'flex-start', lg: 'flex-end' }}>
          //     <Icon viewBox="0 0 200 200" color={kycStatusColor} w="8px">
          //       <path
          //         fill="currentColor"
          //         d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
          //       />
          //     </Icon>
          //     <Text fontSize={'sm'} color="gray">
          //       {kycStatus}
          //     </Text>
          //   </HStack>
          // )
          null}
        </Grid>
      </HStack>
    </VStack>
  );
};

export default UserCard;

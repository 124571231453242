import {
  Button,
  FormControl,
  FormLabel,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Select,
  Text,
  Textarea,
  Box,
  VStack
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCxUsers } from '../../store/actions/cxUsersActions';
import { useEffect, useState } from 'react';
import { createTicket, updateTicket } from '../../store/actions/ticketActions';
import { TICKET_STATUS } from '../../constants';

const TicketModal = ({
  ticketId = null,
  ticketType = 'PENDING_ORDER',
  referenceId,
  assignedTo,
  notes,
  status,
  // for closing modal on ticket create or update
  onClose,
  // for updating ticket data on referenced item state
  callback,
  updateStore = true
}) => {
  const { data: cxUsers } = useSelector((state) => state.cxUsers);
  const dispatch = useDispatch();

  const [ticketState, setTicketState] = useState({
    notes: notes || '',
    assignedTo: assignedTo || '',
    status: status || TICKET_STATUS.UNRESOLVED
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    !Object.keys(cxUsers)?.length && dispatch(getAllCxUsers());
    return () => {
      setTicketState(null);
    };
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(
      !ticketId
        ? createTicket({
            ticketType,
            referenceId,
            notes: ticketState.notes,
            assignedTo: ticketState.assignedTo,
            onSuccess: (data) => {
              callback && callback(data);
            },
            onComplete: () => {
              setLoading(false);
              onClose();
            }
          })
        : updateTicket({
            ticketType,
            ticketId,
            status: ticketState?.status,
            assignedTo: ticketState?.assignedTo,
            notes: ticketState?.notes,
            updateStore,
            onSuccess: (data) => {
              callback && callback(data);
            },
            onComplete: () => {
              setLoading(false);
              onClose();
            }
          })
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <ModalHeader>
          <Text>{!ticketId ? 'Create Ticket' : 'Update Ticket'}</Text>
        </ModalHeader>
        <ModalBody>
          <Box>
            <FormControl>
              <FormLabel>Assign to</FormLabel>
              <Select
                placeholder="Assign ticket"
                color="gray"
                size={'sm'}
                rounded={'lg'}
                _focus={{
                  border: '1px solid #81EBAB',
                  boxShadow: 'none'
                }}
                value={ticketState?.assignedTo}
                onChange={(e) => {
                  setTicketState((prevState) => ({
                    ...prevState,
                    assignedTo: e?.target?.value
                  }));
                }}
                required
              >
                {Object.values(cxUsers)?.map((cxUser) => (
                  <option key={cxUser?.userId} value={cxUser?.userId}>
                    {cxUser?.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Box>
          {ticketId && (
            <Box>
              <FormControl>
                <FormLabel>Status</FormLabel>
                <Select
                  color="gray"
                  size={'sm'}
                  rounded={'lg'}
                  _focus={{
                    border: '1px solid #81EBAB',
                    boxShadow: 'none'
                  }}
                  value={ticketState?.status}
                  onChange={(e) => {
                    setTicketState((prevState) => ({
                      ...prevState,
                      status: e?.target?.value
                    }));
                  }}
                  required
                >
                  {Object.keys(TICKET_STATUS)?.map((ts) => (
                    <option key={ts} value={ts}>
                      {ts}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}
          <Box>
            <FormControl>
              <FormLabel>Notes</FormLabel>
              <Textarea
                required
                value={ticketState?.notes}
                onChange={(e) => {
                  setTicketState((prevState) => ({
                    ...prevState,
                    notes: e?.target?.value
                  }));
                }}
              ></Textarea>
            </FormControl>
          </Box>
        </ModalBody>
        <ModalFooter>
          <VStack w={'100%'}>
            <Button
              isLoading={loading}
              type="submit"
              colorScheme="brand"
              color={'black'}
              size={'sm'}
            >
              {!ticketId ? 'Create' : 'Update'}
            </Button>
          </VStack>
        </ModalFooter>
      </form>
    </>
  );
};

export default TicketModal;

import { AUTH as types } from '../constants';
const initialState = {
  user: null,
  isError: false,
  error: null,
  isLoading: true,
  role: null
};

const authReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.LOGGING_IN:
      return {
        ...state,
        user: null,
        isError: false,
        error: null,
        isLoading: true,
        role: null
      };

    case types.LOGIN_FAILURE:
      return {
        ...state,
        user: null,
        isError: true,
        error: payload,
        isLoading: false,
        role: null
      };

    case types.LOGIN_FROM_LOCAL_STORAGE_FAILURE:
      return {
        ...state,
        user: null,
        isError: false,
        error: null,
        isLoading: false,
        role: null
      };

    case types.LOGIN_SUCCESS:
      return {
        ...state,
        user: payload.userData,
        isError: false,
        error: null,
        isLoading: false,
        role: payload.role
      };

    case types.LOGOUT:
      return {
        ...state,
        user: null,
        isError: false,
        error: null,
        isLoading: false,
        role: null
      };
    default:
      return state;
  }
};

export default authReducer;

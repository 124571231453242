import React from 'react';
import { Box, Grid, Text } from '@chakra-ui/react';
import { camelCaseToSpaceSeparated, doesExist } from '../../utils';

const DisplayData = ({ data }) => {
  return (
    <Box marginY={'1'}>
      <Grid
        gap={2}
        gridTemplateColumns={'0.8fr 1fr'}
        w={'100%'}
        justifyContent={'flex-start'}
        fontSize={'0.9rem'}
      >
        {data &&
          Object.keys(data).map((key) => {
            return (
              <React.Fragment key={key}>
                <Text
                  color="shadedGray.400"
                  // maxWidth={'20rem'}
                  whiteSpace={'break-word'}
                >
                  {`${camelCaseToSpaceSeparated(key)}:`}
                </Text>
                <Text
                  color="white"
                  // maxWidth={'20rem'}
                  whiteSpace={'break-word'}
                  maxHeight={'30rem'}
                  className={'scroll-sm'}
                  overflowY={'auto'}
                >
                  {!doesExist(data[key]) ? '-' : String(data[key])}
                </Text>
              </React.Fragment>
            );
          })}
      </Grid>
    </Box>
  );
};

export default DisplayData;

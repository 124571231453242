import api from '../../api';
import { setAlert } from './alertActions';
import { falcon_ops as types } from '../constants/falconConstants';
export const getTransactions =
  ({ reqBody, pageNumber = 0 }) =>
  async (dispatch) => {
    try {
      const updatedReqBody = { ...reqBody, page_number: pageNumber };
      dispatch({
        type: types.GETTING_FALCON_TRANSACTIONS
      });
      const { data } = await api.falconTransactionsSearch.search({
        reqBody: updatedReqBody
      });

      return dispatch({
        type: types.GET_FALCON_TRANSACTIONS_SUCCESS,
        payload: data
      });
    } catch (error) {
      dispatch(setAlert({ title: error?.message, status: 'error' }));
    }
  };
export const getTransactionDetails =
  ({ transactionId }) =>
  async (dispatch) => {
    try {
      dispatch({ type: types.GETTING_FALCON_TRANSACTION_DETAILS });
      const { data } = await api.falconTransactionsSearch.getDetails({
        transactionId: transactionId
      });
      return dispatch({
        type: types.GET_FALCON_TRANSACTION_DETAILS_SUCCESS,
        payload: data
      });
    } catch (err) {
      dispatch(setAlert({ title: err?.message, status: 'error' }));
    }
  };

// src/pages/FalconSearchPage.js
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container,
  Text,
  Box,
  Spinner,
  Stack,
  Input,
  IconButton,
  VStack,
  Heading,
  HStack,
  Flex,
  SimpleGrid,
  useToast,
  Button,
  useDisclosure,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Modal
} from '@chakra-ui/react';

import { FaSearch } from 'react-icons/fa';
import Pagination from '../../../components/Pagination';
import {
  getTransactions,
  getTransactionDetails
} from '../../../store/actions/falconActions';
import { current } from '@reduxjs/toolkit';

const FalconSearchPage = () => {
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure(); // Chakra's modal control hooks
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const {
    falconTransactionsList,
    currentPage,
    totalPages,
    totalResults,
    error
  } = useSelector((state) => state.falconOps.transactionsData);

  const transactionData = useSelector(
    (state) => state.falconOps.transactionData
  );
  const reqBody = useSelector((state) => state.falconOps.reqBody);
  const itemsPerPage = reqBody?.itemsPerPage || 10;
  const fetchTransactions = (pageNumber = 1) => {
    setIsLoading(true);
    dispatch(
      getTransactions({ reqBody: reqBody, pageNumber: pageNumber })
    ).finally(() => {
      setIsLoading(false);
    });
  };

  const handleSearchChange = (event) => setSearchQuery(event.target.value);

  const handleSearchSubmit = () => {
    fetchTransactions(1, searchQuery);
  };
  const handleTransactionSelect = (transactionId) => {
    dispatch(getTransactionDetails({ transactionId: transactionId }));
    onOpen();
  };
  const handlePageChange = (page) => {
    let pageNumber = page - 1 >= 0 ? page - 1 : 0;
    fetchTransactions(pageNumber);
  };

  useEffect(() => {
    fetchTransactions(currentPage);
  }, [currentPage]);

  return (
    <Container
      overflow="scroll"
      maxW="full"
      py={5}
      bg="backGround"
      color="white"
      height="100vh"
    >
      {
        <VStack spacing={6} align="flex-start" w="full">
          <Heading as="h1" size="lg" color="brand.500" fontWeight="bold">
            Falcon Transactions
          </Heading>

          {/* Search Bar */}
          <HStack w="full" spacing={3}>
            <Input
              placeholder="Search transactions..."
              value={searchQuery}
              onChange={handleSearchChange}
              size="lg"
              width="70%"
              bg="altGray.600"
              color="white"
              focusBorderColor="colorPrimary"
              _placeholder={{ color: 'shadedGray.500' }}
              rounded="md"
            />
            <IconButton
              icon={<FaSearch />}
              onClick={handleSearchSubmit}
              colorScheme="brand"
              variant="solid"
              bg="colorPrimary"
              color="altGray.600"
              aria-label="Search Transactions"
              size="lg"
              _hover={{ bg: 'brand.400' }}
              rounded="md"
            />
          </HStack>

          {/* Loading Spinner */}
          {isLoading ? (
            <Flex justify="center" align="center" width="full" pt={5}>
              <Spinner size="xl" color="colorPrimary" />
            </Flex>
          ) : error ? (
            <Box
              color="red.400"
              p={4}
              bg="altGray.400"
              borderRadius="md"
              borderWidth="1px"
            >
              <Text>
                An error occurred while fetching transactions: {error.message}
              </Text>
            </Box>
          ) : (
            <Box w="full">
              {/* Transaction List */}
              <SimpleGrid
                columns={{ base: 1, md: 2, lg: 3 }}
                spacing={6}
                pt={4}
              >
                {falconTransactionsList.map((transaction) => (
                  <Box
                    key={transaction.transactionId}
                    p={5}
                    shadow="lg"
                    borderWidth="1px"
                    borderRadius="md"
                    bg="altGray.500"
                    color="white"
                    _hover={{ bg: 'altGray.200', cursor: 'pointer' }}
                    onClick={() => {
                      handleTransactionSelect(transaction.transactionId);
                    }}
                  >
                    <HStack textAlign="center">
                      <Heading size="sm" color="brand.100">
                        Transaction ID:
                      </Heading>
                      <Text>{transaction.transactionId} </Text>
                    </HStack>
                    <HStack>
                      <VStack>
                        <HStack alignSelf="start">
                          <Heading size="sm" color="brand.100">
                            Source Currency:
                          </Heading>
                          <Text>{transaction.sourceCurrency}</Text>
                        </HStack>
                        <HStack alignSelf="start">
                          <Heading size="sm" color="brand.100">
                            Source Amount:
                          </Heading>
                          <Text>{transaction.sourceAmount}</Text>
                        </HStack>
                      </VStack>
                      <VStack>
                        <HStack alignSelf="start">
                          <Heading size="sm" color="brand.100">
                            Payout Currency:
                          </Heading>
                          <Text>{transaction.payoutCurrency}</Text>
                        </HStack>
                        <HStack alignSelf="start">
                          <Heading size="sm" color="brand.100">
                            Payout Amount:
                          </Heading>
                          <Text>{transaction.payoutAmount}</Text>
                        </HStack>
                      </VStack>
                    </HStack>
                  </Box>
                ))}
              </SimpleGrid>

              {/* Pagination Controls */}
              {totalResults > itemsPerPage && (
                <Box justifyItems="center" width="100%">
                  <Pagination
                    currentPage={currentPage + 1 || 1}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                </Box>
              )}
            </Box>
          )}
        </VStack>
      }
      <Modal height="500px" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxWidth="600px" padding="20px">
          <ModalHeader fontWeight="bold" fontSize="lg">
            Transaction Details
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box marginBottom="4">
              <Heading size="md" marginBottom="2" color="teal.600">
                General Info
              </Heading>
              <Box paddingLeft="4">
                <Text>
                  <strong>Transaction ID:</strong>{' '}
                  {transactionData.transactionId}
                </Text>
                <Text>
                  <strong>Client Transaction ID:</strong>{' '}
                  {transactionData.clientTxnId}
                </Text>
                <Text>
                  <strong>Source Currency:</strong>{' '}
                  {transactionData.sourceCurrency}
                </Text>
                <Text>
                  <strong>Source Amount:</strong> $
                  {Number(transactionData.sourceAmount).toFixed()}
                </Text>
                <Text>
                  <strong>Payout Currency:</strong>{' '}
                  {transactionData.payoutCurrency}
                </Text>
                <Text>
                  <strong>Payout Amount:</strong> $
                  {Number(transactionData.payoutAmount).toFixed(2)}
                </Text>
                <Text>
                  <strong>Exchange Rate:</strong>{' '}
                  {Number(transactionData.exchangeRate).toFixed(4)}
                </Text>
              </Box>
            </Box>

            <Box marginBottom="4">
              <Heading size="md" marginBottom="2" color="teal.600">
                Off-Ramp Data
              </Heading>
              <Box paddingLeft="4">
                <Text>
                  <strong>Created At:</strong>{' '}
                  {transactionData?.offRampData?.createdAt || <>N/A</>}
                </Text>
                <Text>
                  <strong>Crypto Currency:</strong>{' '}
                  {transactionData?.offRampData?.crpytoCurrency || <>N/A</>}
                </Text>
                <Text>
                  <strong>Fullfillment ID:</strong>{' '}
                  {transactionData.offRampData?.fullfillmentId || <>N/A</>}
                </Text>
                <Text>
                  <strong>ID:</strong>{' '}
                  {transactionData.offRampData?.id || <>N/A</>}
                </Text>
                <Text>
                  <strong>Order ID:</strong>{' '}
                  {transactionData.offRampData?.orderId || <>N/A</>}
                </Text>
                <Text>
                  <strong>Payout ID:</strong>{' '}
                  {transactionData.offRampData?.payoutId || <>N/A</>}
                </Text>
                <Text>
                  <strong>Payout Partner:</strong>{' '}
                  {transactionData.offRampData?.payoutPartner || <>N/A</>}
                </Text>
                <Text>
                  <strong>Payout Status:</strong>{' '}
                  {transactionData.offRampData?.payoutStatus || <>N/A</>}
                </Text>
                <Text>
                  <strong>Previous State:</strong>{' '}
                  {transactionData.offRampData?.previousState || <>N/A</>}
                </Text>
                <Text>
                  <strong>Quote Rate:</strong>{' '}
                  {transactionData.offRampData?.quoteRate || <>N/A</>}
                </Text>
                <Text>
                  <strong>Receive Amount:</strong> $
                  {Number(transactionData.offRampData?.receiveAmount).toFixed(
                    2
                  ) || <>N/A</>}
                </Text>
                <Text>
                  <strong>Status:</strong>{' '}
                  {transactionData.offRampData?.status || <>N/A</>}
                </Text>
                <Text>
                  <strong>Transaction Hash:</strong>{' '}
                  {transactionData.offRampData?.transactionHash || <>N/A</>}
                </Text>
                <Text>
                  <strong>Transfer ID:</strong>{' '}
                  {transactionData.offRampData?.transferId || <>N/A</>}
                </Text>
                <Text>
                  <strong>Updated At:</strong>{' '}
                  {transactionData.offRampData?.updatedAt || <>N/A</>}
                </Text>
              </Box>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default FalconSearchPage;

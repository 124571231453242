const ORDER_SCREEN_CONFIG = {
  GETTING_SCREEN_CONFIG: 'GETTING_SCREEN_CONFIG',
  GET_ORDER_SCREEN_CONF_SUCCESS: 'GET_ORDER_SCREEN_CONF_SUCCESS',
  GET_SCREEN_CONFIG_FAILURE: 'GET_SCREEN_CONFIG_FAILURE',
  UPDATE_SCREEN_CONF_LOCAL: 'UPDATE_SCREEN_CONF_LOCAL',
  UPDATING_SCR_CONF: 'UPDATING_SCR_CONF',
  UPDATE_SCR_CONF_SUCCESS: 'UPDATE_SCR_CONF_SUCCESS',
  UPDATE_SCR_CONF_FAILURE: 'UPDATE_SCR_CONF_FAILURE'
};

export default ORDER_SCREEN_CONFIG;

import {
  useDisclosure,
  Grid,
  HStack,
  Text,
  IconButton,
  VStack,
  Table,
  Tbody,
  Td,
  Tr,
  Thead,
  Th,
  Button
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import ModalLayout from './../Modal/ModalLayout';
import ScheduleSlotModal from './ScheduleSlotModal';
import DisplayData from './DisplayData';
import UpdateNrObStatusModal from './UpdateNrObStatusModal';
import {
  NROB_ACTIONS,
  NROB_DOCS_TO_YBL_STATUS,
  NROB_PICKUP_ACTIONS,
  NROB_PICKUP_STATUS,
  NR_OB_COUNTRY
} from '../../constants';
import dayjs from 'dayjs';
import {
  rejectPickup,
  updatePickupStatus,
  schedulePickup,
  getAvailablePickupSlots,
  updateDocsToYblStatus,
  getNrOnboardDetails,
  updateAttestedDocsStatus,
  getOnboardUserDocsV2
} from '../../store/actions/nronboardActions';
import ConfirmModal from '../Modal/ConfirmForceInitiate';
import { useDispatch, useSelector } from 'react-redux';
import { FaCheck } from 'react-icons/fa';
import { IoClose } from 'react-icons/io5';
import { MdEdit } from 'react-icons/md';
import { tableCellStyles } from './constants';
import UpdateAttestedDocsModal from './UpdateAttestedDocsStatusModal';
import { kebabCaseToSpaceSeperate } from '../../utils';
import api from '../../api';

const NrOnboardPickup = ({ pickupData = {}, userId = 0 }) => {
  const [availableSlots, setAvailableSlots] = useState([]);
  const { country } = useSelector((state) => state?.nronboard?.details?.data);

  const {
    isOpen: isScheduleModalOpen,
    onOpen: onScheduleModalOpen,
    onClose: onScheduleModalClose
  } = useDisclosure();
  const {
    isOpen: isUpdateModalOpen,
    onOpen: onUpdateModalOpen,
    onClose: onUpdateModalClose
  } = useDisclosure();
  const {
    isOpen: isRejectModalOpen,
    onOpen: onRejectModalOpen,
    onClose: onRejectModalClose
  } = useDisclosure();

  const {
    isOpen: isDocsToYBLOpen,
    onOpen: onDocsToYBLOpen,
    onClose: onDocsToYBLClose
  } = useDisclosure();

  const {
    isOpen: isAttestedDocsOpen,
    onOpen: onAttestedDocsOpen,
    onClose: onAttestedDocsClose
  } = useDisclosure();

  const [isRejectPickupLoading, setIsRejectPickupLoading] = useState(false);
  const [isUpdateAttestedDocsLoading, setUpdateAttestedDocsLoading] =
    useState(false);
  const [attestedDocs, setAttestedDocs] = useState([]);

  const {
    address,
    status,
    selectedPickupSlot,
    docsDeliveryToYblStatus,
    attestedDocsStatus
  } = pickupData;

  const dispatch = useDispatch();

  const cellStyles = tableCellStyles(4);

  useEffect(() => {
    if (status === NROB_PICKUP_STATUS.SIGNED_DOCS_PICKUP_REQUESTED)
      dispatch(
        getAvailablePickupSlots({
          userId,
          onSuccess: (availableSlots) => {
            if (!availableSlots) return;
            setAvailableSlots(availableSlots);
          }
        })
      );
  }, [userId, dispatch, status]);

  useEffect(() => {
    // TODO: perform country based conditional as well
    if (country === NR_OB_COUNTRY.AE) return;
    // TODO: add a condition here to fetch based on docs delivery status
    if (false) return;
    dispatch(
      getOnboardUserDocsV2({
        userId,
        country: NR_OB_COUNTRY.GB,
        requiredDocs: [
          'NOTARIZED_PASSPORT_FRONT_NOT_SIGNED',
          'NOTARIZED_PASSPORT_BACK_NOT_SIGNED',
          'NOTARIZED_MAILING_ADDRESS_NOT_SIGNED'
        ],
        modifyStore: false,
        onSuccess: (data) => {
          const documents = data?.documents;
          setAttestedDocs(Object.values(documents));
        },
        onComplete: () => {
          console.log('Request Complete');
        }
      })
    );
  }, [userId, status, docsDeliveryToYblStatus]);

  const handleUpdateStatusModalSubmit = (status) => {
    dispatch(
      updatePickupStatus({ userId, status, onComplete: onUpdateModalClose })
    );
  };

  const handleScheduleModalSubmit = (slot) => {
    dispatch(
      schedulePickup({
        userId,
        slot,
        onComplete: onScheduleModalClose
      })
    );
  };

  const handleRejectModalSubmit = () => {
    setIsRejectPickupLoading(true);
    dispatch(
      rejectPickup({
        userId,
        onComplete: () => {
          onRejectModalClose();
          setIsRejectPickupLoading(false);
        }
      })
    );
  };

  const handleUpdateDocsToYblStatus = (status) => {
    setIsRejectPickupLoading(true);
    dispatch(
      updateDocsToYblStatus({
        userId,
        status,
        onSuccess: () => {
          onDocsToYBLClose();
          dispatch(getNrOnboardDetails(userId));
        },
        onComplete: () => {
          setIsRejectPickupLoading(false);
        }
      })
    );
  };

  const handleUpdateAttestedDocsStatus = (status) => {
    setUpdateAttestedDocsLoading(true);
    dispatch(
      updateAttestedDocsStatus({
        userId,
        status,
        onSuccess: () => {
          dispatch(getNrOnboardDetails(userId));
          onAttestedDocsClose();
        },
        onComplete: () => {
          setUpdateAttestedDocsLoading(false);
        }
      })
    );
  };

  const handleDocClick = (docUrl) => {
    api.workflowsV2
      .getDocByUrl({ userId, docUrl })
      .then(({ data }) => window.open(data?.url, '_blank'))
      .catch((err) => console.log(err));
  };

  return (
    <>
      <ModalLayout onClose={onDocsToYBLClose} isOpen={isDocsToYBLOpen}>
        <UpdateNrObStatusModal
          actionType={NROB_ACTIONS.DOCS_DELIVERY_TO_YBL}
          title="Update Docs to YBL status"
          onSubmit={handleUpdateDocsToYblStatus}
        />
      </ModalLayout>
      <ModalLayout onClose={onUpdateModalClose} isOpen={isUpdateModalOpen}>
        <UpdateNrObStatusModal
          actionType={NROB_ACTIONS.PICKUP}
          onSubmit={handleUpdateStatusModalSubmit}
          title="Update Pickup Status"
        />
      </ModalLayout>
      <ModalLayout isOpen={isScheduleModalOpen} onClose={onScheduleModalClose}>
        <ScheduleSlotModal
          onSubmit={handleScheduleModalSubmit}
          availableSlots={availableSlots}
          title="Schedule Pickup Slot"
          initialState={selectedPickupSlot}
        />
      </ModalLayout>
      <ModalLayout isOpen={isRejectModalOpen} onClose={onRejectModalClose}>
        <ConfirmModal
          handleSubmit={handleRejectModalSubmit}
          prompt={'Are you sure you want to reject the Pickup?'}
          isLoading={isRejectPickupLoading}
        />
      </ModalLayout>
      <ModalLayout isOpen={isAttestedDocsOpen} onClose={onAttestedDocsClose}>
        <UpdateAttestedDocsModal
          attestedDocsStatus={pickupData?.attestedDocsStatus || 'NEW'}
          isLoading={isUpdateAttestedDocsLoading}
          handleCancel={() => {
            onAttestedDocsClose();
          }}
          handleSubmit={handleUpdateAttestedDocsStatus}
        />
      </ModalLayout>
      <Grid
        marginBottom={'20px'}
        gridRowGap={4}
        gridTemplateColumns={'0.8fr 1fr'}
      >
        <>
          <Text color={'shadedGray.400'}>Status:</Text>
          <HStack>
            <Text color={'white'}>{status}</Text>
            {status === NROB_PICKUP_STATUS.SIGNED_DOCS_PICKUP_SCHEDULED && (
              <IconButton
                icon={<MdEdit color="black" />}
                colorScheme="brand"
                size={'sm'}
                rounded={'full'}
                onClick={() => onUpdateModalOpen()}
              />
            )}
          </HStack>
        </>
        <>
          <Text color={'shadedGray.400'}>Selected Slot:</Text>
          <HStack gap={2}>
            <Text color={'white'}>
              {!selectedPickupSlot
                ? '-'
                : `${dayjs(selectedPickupSlot?.startTime).format(
                    'DD-MM-YYYY'
                  )}, ${dayjs
                    .utc(selectedPickupSlot?.startTime)
                    .format('HH:mm A')} - ${dayjs
                    .utc(selectedPickupSlot?.endTime)
                    .format('HH:mm A')}`}
            </Text>
            {status === NROB_PICKUP_STATUS?.SIGNED_DOCS_PICKUP_REQUESTED && (
              <>
                <IconButton
                  onClick={() => {
                    onScheduleModalOpen && onScheduleModalOpen();
                  }}
                  icon={<FaCheck />}
                  size={'sm'}
                  rounded={'full'}
                  colorScheme="green"
                />
                <IconButton
                  onClick={() => {
                    onRejectModalOpen();
                  }}
                  colorScheme="red"
                  size={'sm'}
                  rounded={'full'}
                  icon={<IoClose />}
                />
              </>
            )}
          </HStack>
        </>
      </Grid>
      <VStack alignItems={'stretch'} gap={2}>
        <DisplayData
          data={{
            address
          }}
        />
        <hr />
        {status === NROB_PICKUP_ACTIONS.SIGNED_DOCS_PICKUP_COMPLETED && (
          <>
            <VStack alignItems={'stretch'}>
              <Text color={'white'} fontSize={'lg'} fontWeight={'bold'}>
                Docs delivered to YBL
              </Text>
              <Table>
                <Tbody>
                  <Tr>
                    <Td style={cellStyles}>
                      <Text color={'shadedGray.400'} fontSize={'md'}>
                        Status:
                      </Text>
                    </Td>
                    <Td style={cellStyles}>
                      <HStack>
                        <Text fontSize={'md'} color={'white'}>
                          {!docsDeliveryToYblStatus
                            ? '-'
                            : docsDeliveryToYblStatus}
                        </Text>
                        {docsDeliveryToYblStatus ===
                          NROB_DOCS_TO_YBL_STATUS.SENT_TO_YBL && (
                          <IconButton
                            icon={<MdEdit color="green" />}
                            rounded={'full'}
                            onClick={onDocsToYBLOpen}
                            variant={'outline'}
                            colorScheme="green"
                            size={'sm'}
                          />
                        )}
                      </HStack>
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </VStack>
            <hr />
          </>
        )}
        {
          /* TODO: add display condition for attested docs status */
          true && (
            <>
              <VStack alignItems={'stretch'}>
                <Text color={'white'} fontSize={'lg'} fontWeight={'bold'}>
                  Attested Documents
                </Text>
                <Table>
                  <Tbody>
                    <Tr>
                      <Td style={cellStyles}>
                        <Text color={'shadedGray.400'} fontSize={'md'}>
                          Status:
                        </Text>
                      </Td>
                      <Td style={cellStyles}>
                        <HStack>
                          <Text color={'white'} fontSize={'md'}>
                            {attestedDocsStatus || '-'}
                          </Text>
                          <IconButton
                            icon={<MdEdit color="green" />}
                            rounded={'full'}
                            onClick={() => {
                              onAttestedDocsOpen();
                            }}
                            variant={'outline'}
                            colorScheme="green"
                            size={'sm'}
                          />
                        </HStack>
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
                <Text color={'white'} fontSize={'md'} fontWeight={'bold'}>
                  Documents
                </Text>
                <Table>
                  <Thead>
                    <Tr>
                      <Th style={cellStyles}></Th>
                      <Th style={cellStyles}></Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {attestedDocs?.map((attestedDoc) => (
                      <Tr>
                        <Td style={cellStyles}>
                          <Text color={'gray.400'} fontSize={'sm'}>
                            {kebabCaseToSpaceSeperate(
                              attestedDoc?.subDocumentType
                            ) || '-'}
                          </Text>
                        </Td>
                        <Td style={cellStyles}>
                          <Button
                            variant={'link'}
                            colorScheme="brand"
                            onClick={() => handleDocClick(attestedDoc?.urlPath)}
                          >
                            View File
                          </Button>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </VStack>
              <hr />
            </>
          )
        }
      </VStack>
    </>
  );
};

export default NrOnboardPickup;

import { dummyApi } from '../../utils';

export default function referralsApi(fetch, baseUrl) {
  return {
    create(formData) {
      return fetch.post(`${baseUrl}`, formData);
    },

    searchCampaigns({ pageNo = 0, pageSize = 10, query }) {
      return fetch(
        `${baseUrl}?page_size=${pageSize}&page=${pageNo}${
          query?.length ? `&search=${query}` : ''
        }`
      );
    },

    campaignByReferralCode(code) {
      return fetch(`${baseUrl}/${code}`);
    },

    edit(formData) {
      /* FOR PROD */
      return fetch.put(`${baseUrl}`, formData);
    }
  };
}

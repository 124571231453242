import {
    Box,
    Button,
    Grid,
    HStack,
    Stack,
    StackDivider,
    Text,
    VStack
  } from '@chakra-ui/react';
  import React from 'react';
  import CopyableText from '../CopyableText';
  import { formatDate } from '../../utils';
  import { MdEast } from 'react-icons/md';
  
  const UserReferralCard = ({
    referralData,
    onUserDetailsClick
  }) => {
    return (
      <Box position={'relative'}>
        <VStack
          bg="backGround"
          borderRadius="12px"
          divider={
            <StackDivider style={{ margin: '0' }} borderColor="whiteAlpha.300" />
          }
        >
          <Grid
            gridTemplateColumns={{
              base: 'repeat(2, auto)',
              md: 'repeat(3, auto)'
            }}
            rowGap={2}
            w={'90%'}
            justifyContent="space-between"
            m={'1rem'}
          >
            <HStack>
              <Text fontSize={{ base: 'sm', sm: 'md' }} color="gray">
                Referee:
              </Text>
              <CopyableText
                title={'Refree'}
                fontSize={{ base: 'sm', sm: 'md' }}
                color="white"
              >
                {referralData.refereeName}
              </CopyableText>
            </HStack>
            <HStack>
              <Text fontSize={{ base: 'sm', sm: 'md' }} color="gray">
                Create Date:
              </Text>
              <CopyableText
                title={'Order Status'}
                fontSize={{ base: 'sm', sm: 'md' }}
                color="white"
              >
                {formatDate(referralData.createDate)}
              </CopyableText>
            </HStack>
            <HStack direction={{ base: 'column', sm: 'row' }}>
              <Text fontSize={{ base: 'sm', sm: 'md' }} color="gray">
                Modify Date:
              </Text>
              <Text fontSize={{ base: 'sm', sm: 'md' }} color="white">
                {formatDate(referralData.modifyDate)}
              </Text>
            </HStack>
          </Grid>
          <Grid
            gridTemplateColumns={{
                base: 'repeat(2, auto)',
                md: 'repeat(3, auto)'
              }}
              rowGap={2}
              w={'90%'}
              justifyContent="space-between"
              m={'1rem'}
          >
            <HStack>
              <Text fontSize={{ base: 'sm', sm: 'md' }} color="gray">
                Points:
              </Text>
              <Text fontSize={{ base: 'sm', sm: 'md' }} color="white">
                {referralData.points}
              </Text>
            </HStack>
            <HStack>
              <Text fontSize={{ base: 'sm', sm: 'md' }} color="gray">
                Free Transfers Earned:
              </Text>
              <CopyableText
                title={'Transaction ID'}
                fontSize={{ base: 'sm', sm: 'md' }}
                color="white"
              >
                {referralData.freeTransfersEarned}
              </CopyableText>
            </HStack>
            <Button
              mt={{ base: 2, md: 0 }}
              ml={{ base: 0, md: 1 }}
              rightIcon={<MdEast color="black" />}
              type="submit"
              rounded="lg"
              color={'black'}
              colorScheme="brand"
              alignSelf={{ base: 'center', md: 'center' }}
              px={5}
              onClick={() => onUserDetailsClick(referralData.refereeId)}
            >
              User Details
            </Button>
          </Grid>
        </VStack>
      </Box>
    );
  };
  
  export default UserReferralCard;
  
import {
  Stack,
  HStack,
  Text,
  VStack,
  Grid,
  Box,
  IconButton
} from '@chakra-ui/react';
import React from 'react';
import { AiFillEdit } from 'react-icons/ai';
import { useNavigate } from 'react-router';

const CampaignCard = ({ campaign }) => {
  const {
    expiryDate,
    freeTransferReward,
    campaignRewardCondition,
    rewardConditionTransferAmount,
    referralCode
  } = campaign;
  const navigate = useNavigate();

  const onEditClick = (code) => {
    navigate(`/campaign/edit/${code}`);
  };

  return (
    <Box position={'relative'} maxW={'100%'}>
      <IconButton
        position={'absolute'}
        rounded={'full'}
        top={-2}
        right={-2}
        bg={'altGray.500'}
        color={'brand.200'}
        _hover={{
          background: 'brand.200',
          color: 'altGray.500'
        }}
        size={'sm'}
        fontSize={'0.9rem'}
        fontWeight={400}
        icon={<AiFillEdit />}
        onClick={() => onEditClick(referralCode)}
      />
      <VStack
        bg="backGround"
        borderRadius="12px"
        w={'52rem'}
        maxW={'100%'}
        pb={'1rem'}
      >
        <Stack
          direction={{ base: 'column', sm: 'row' }}
          w={'90%'}
          justifyContent="space-between"
          mt={'1rem'}
        >
          <HStack>
            <Text fontSize="md" color="white">
              {referralCode}
            </Text>
          </HStack>

          <Stack direction={'row'}>
            <Text fontSize="md" color="white">
              Expires on:
            </Text>
            <Text fontSize="md" color="white">
              {expiryDate}
            </Text>
          </Stack>
        </Stack>
        <Grid
          gridTemplateColumns={{
            base: '1fr',
            md: `1fr 1fr 1fr`
          }}
          gap={2}
          alignItems={'flex-start'}
          w={'90%'}
          m={'1rem'}
          textAlign={{ base: 'end', md: 'start' }}
        >
          <Stack
            direction={{ base: 'row', md: 'column' }}
            justifyContent={'space-between'}
          >
            <Text fontSize={'sm'} color={'gray'}>
              Free transfer reward
            </Text>
            <Text fontSize={'sm'} color={'white'}>
              {freeTransferReward}
            </Text>
          </Stack>
          <Stack
            direction={{ base: 'row', md: 'column' }}
            justifyContent={'space-between'}
          >
            <Text fontSize={'sm'} color={'gray'}>
              Condition
            </Text>
            <Text fontSize={'sm'} color={'white'}>
              {campaignRewardCondition}
            </Text>
          </Stack>

          <Stack
            direction={{ base: 'row', md: 'column' }}
            justifyContent={'space-between'}
          >
            <Text fontSize={'sm'} color={'gray'}>
              Condition transfer amount
            </Text>
            <Text fontSize={'sm'} color={'white'}>
              {rewardConditionTransferAmount}
            </Text>
          </Stack>
        </Grid>
      </VStack>
    </Box>
  );
};

export default CampaignCard;

import React from 'react';
import {
  Button,
  FormControl,
  FormLabel,
  VStack,
  Select,
  Text,
  Box,
  Divider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { RECEIPT_REJECTION_REASONS } from '../../constants';

const RejectReceiptModal = ({
  isOpen,
  onClose,
  title,
  receiptId,
  isLoading,
  onSubmit
}) => {
  const [rejectionReason, setRejectionReason] = React.useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ rejectionNote: rejectionReason });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay bg="blackAlpha.200" backdropFilter="blur(10px)" />
      <ModalContent 
        bg="black" 
        color="white"
        borderRadius="xl"
        boxShadow="xl"
        maxWidth="600px"
        width="95%"
        border="1px solid white"
      >
        <ModalCloseButton top={4} right={4} />
        <ModalBody p={6}>
          <form onSubmit={handleSubmit}>
            <VStack spacing={6} align="stretch">
              <Text fontSize="xl" fontWeight="bold" color="white">
                {title}
              </Text>
              <Text fontSize="sm" color="gray.400">
                Receipt ID: {receiptId}
              </Text>
              <Divider borderColor="whiteAlpha.300" />
              <FormControl>
                <FormLabel color="gray.400" fontSize="sm" mb={2}>
                  Rejection Reason
                </FormLabel>
                <Select
                  value={rejectionReason}
                  onChange={(e) => setRejectionReason(e.target.value)}
                  placeholder="Select rejection reason"
                  bg="whiteAlpha.100"
                  color="white"
                  borderColor="whiteAlpha.300"
                  _hover={{ borderColor: 'whiteAlpha.400' }}
                  _focus={{ borderColor: 'brand.200', boxShadow: 'none' }}
                  size="md"
                  fontSize="sm"
                >
                  {Object.entries(RECEIPT_REJECTION_REASONS).map(([key, value]) => (
                    <option key={key} value={value} style={{ color: 'black' }}>
                      {value}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <Box pt={4}>
                <Button
                  type="submit"
                  colorScheme="brand"
                  isLoading={isLoading}
                  width="100%"
                  size="md"
                  fontSize="sm"
                  color="black"
                  isDisabled={!rejectionReason}
                >
                  Reject Receipt
                </Button>
                <Button
                  onClick={onClose}
                  variant="ghost"
                  colorScheme="white"
                  bg="whiteAlpha.300"
                  width="100%"
                  size="md"
                  fontSize="sm"
                  mt={3}
                >
                  Cancel
                </Button>
              </Box>
            </VStack>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default RejectReceiptModal;

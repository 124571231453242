import {
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Select,
  Text
} from '@chakra-ui/react';
import {
  NROB_ACTIONS,
  NROB_APPOINTMENT_ACTIONS,
  NROB_DELIVERY_ACTIONS,
  NROB_DOCS_TO_YBL_ACTIONS,
  NROB_PICKUP_ACTIONS
} from '../../constants';
import { useState } from 'react';

const UpdateNrObStatusModal = ({
  onSubmit,
  actionType = null,
  title = 'Update Status'
}) => {
  const [status, setStatus] = useState();

  const handleSubmit = (e) => {
    e?.preventDefault();
    onSubmit && onSubmit(status);
  };

  const getActionableOptions = (actionType) => {
    if (!actionType) return {};
    else if (actionType === NROB_ACTIONS.APPOINTMENT)
      return NROB_APPOINTMENT_ACTIONS;
    else if (actionType === NROB_ACTIONS.DELIVERY) return NROB_DELIVERY_ACTIONS;
    else if (actionType === NROB_ACTIONS.PICKUP) return NROB_PICKUP_ACTIONS;
    else if (actionType === NROB_ACTIONS.DOCS_DELIVERY_TO_YBL)
      return NROB_DOCS_TO_YBL_ACTIONS;
  };

  return (
    <>
      <ModalHeader>
        <Text color="white">{title}</Text>
      </ModalHeader>
      <form onSubmit={handleSubmit}>
        <ModalBody>
          <Select
            required
            onChange={(e) => {
              setStatus(e?.target?.value);
            }}
            value={status}
            name="status"
            placeholder="Choose status to update"
          >
            {Object.keys(getActionableOptions(actionType)).map((actionItem) => (
              <option key={actionItem} value={actionItem}>
                {actionItem}
              </option>
            ))}
          </Select>
        </ModalBody>
        <ModalFooter>
          <Button type="submit" colorScheme="brand" variant={'outline'}>
            Update
          </Button>
        </ModalFooter>
      </form>
    </>
  );
};

export default UpdateNrObStatusModal;

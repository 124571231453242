import { Text } from '@chakra-ui/react';
import React from 'react';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../store/actions/alertActions';

const CopyableText = ({ title = 'Text', children, ...props }) => {
  const dispatch = useDispatch();

  const onClick = (e) => {
    navigator.clipboard
      .writeText(e.target.innerHTML)
      .then(() => {
        dispatch(
          setAlert({
            title: `${title} copied to clipboard`,
            status: 'success'
          })
        );
      })
      .catch((error) => {
        dispatch(
          setAlert({
            title: `Failed to copy ${title} to clipboard`,
            status: 'error'
          })
        );
      });
  };

  return (
    <Text onClick={onClick} {...props} cursor={'pointer'}>
      {children}
    </Text>
  );
};

export default CopyableText;

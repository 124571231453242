import React from 'react';
import { useParams } from 'react-router-dom';
import ContentLayout from '../../layouts/ContentLayout';
import OrderDetailsCard from '../../components/OrderDetailsCard';
import { Box } from '@chakra-ui/react';

const OrderDetails = () => {
  const { orderId } = useParams();

  return (
    <ContentLayout>
      <Box pt={4} pl={4} w={'100%'}>
        <OrderDetailsCard
          orderId={orderId}
          showNavLink={true}
        ></OrderDetailsCard>
      </Box>
    </ContentLayout>
  );
};

export default OrderDetails;

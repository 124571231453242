import { Box, Grid, IconButton, Text, useDisclosure } from '@chakra-ui/react';
import DisplayData from './DisplayData';
import ModalLayout from '../Modal/ModalLayout';
import ScheduleSlotModal from './ScheduleSlotModal';
import { NROB_ACTIONS, NROB_APPOINTMENT_STATUS } from '../../constants';
import { useEffect, useState } from 'react';
import api from '../../api';
import { convertSlots } from '../../store/reducers/utils';
import UpdateNrObStatusModal from './UpdateNrObStatusModal';
import dayjs from 'dayjs';
import ConfirmModal from '../Modal/ConfirmForceInitiate';
import { useDispatch } from 'react-redux';
import {
  rejectAppointment,
  scheduleAppointment,
  updateAppointmentStatus
} from '../../store/actions/nronboardActions';
import { FaCheck } from 'react-icons/fa';
import { IoClose } from 'react-icons/io5';
import { MdEdit } from 'react-icons/md';

const NrOnboardAppointment = ({ appointmentData, userId }) => {
  const { status, startTime, endTime, ...data } = appointmentData;
  const [appointmentSlots, setAppointmentSlots] = useState([]);
  const [isRejectAppointmentLoading, setIsRejectAppointmentLoading] =
    useState(false);
  const {
    isOpen: isScheduleSlotOpen,
    onOpen: onScheduleSlotOpen,
    onClose: onScheduleSlotClose
  } = useDisclosure();
  const {
    isOpen: isUpdateStatusOpen,
    onOpen: onUpdateStatusOpen,
    onClose: onUpdateStatusClose
  } = useDisclosure();
  const {
    isOpen: isRejectModalOpen,
    onOpen: onRejectModalOpen,
    onClose: onRejectModalClose
  } = useDisclosure();

  const dispatch = useDispatch();

  useEffect(() => {
    api.nronboard
      .getAvailableAppointmentSlots(userId)
      .then((res) => res?.data)
      .then(({ availableSlots }) => {
        setAppointmentSlots(convertSlots(availableSlots));
      })
      .catch((err) => {
        console.error(err?.message);
      });
  }, [userId]);

  const handleScheduleSlotSubmit = (slot) => {
    // TODO: make api call and refresh the store
    dispatch(
      scheduleAppointment({
        userId,
        slot,
        onComplete: onScheduleSlotClose
      })
    );
  };

  const handleUpdateStatusSubmit = (status) => {
    // TODO: make api call and refresh the store
    dispatch(
      updateAppointmentStatus({
        userId,
        status,
        onComplete: onUpdateStatusClose
      })
    );
  };

  const handleAppoinmentRejectSubmit = () => {
    setIsRejectAppointmentLoading(true);
    dispatch(
      rejectAppointment({
        userId,
        onComplete: () => {
          setIsRejectAppointmentLoading(false);
          onRejectModalClose();
        }
      })
    );
  };

  return (
    <>
      <ModalLayout isOpen={isScheduleSlotOpen} onClose={onScheduleSlotClose}>
        <ScheduleSlotModal
          onSubmit={handleScheduleSlotSubmit}
          availableSlots={appointmentSlots}
          title={'Schedule Appointment'}
          initialState={{
            startTime,
            endTime
          }}
        />
      </ModalLayout>
      <ModalLayout isOpen={isUpdateStatusOpen} onClose={onUpdateStatusClose}>
        <UpdateNrObStatusModal
          onSubmit={handleUpdateStatusSubmit}
          actionType={NROB_ACTIONS.APPOINTMENT}
          title="Update Appointment Status"
        />
      </ModalLayout>
      <ModalLayout isOpen={isRejectModalOpen} onClose={onRejectModalClose}>
        <ConfirmModal
          handleSubmit={handleAppoinmentRejectSubmit}
          prompt={'Are you sure you want to reject this appointment ?'}
          isLoading={isRejectAppointmentLoading}
        />
      </ModalLayout>
      <Box>
        <Grid gridRowGap={2} gridTemplateColumns={'0.8fr 1fr'}>
          <Text color={'shadedGray.400'}>Status:</Text>
          <Box>
            <Text color={'white'}>{status}</Text>
            {status === NROB_APPOINTMENT_STATUS.APPOINTMENT_SCHEDULED && (
              <IconButton
                size={'sm'}
                rounded={'full'}
                colorScheme="grey"
                icon={<MdEdit />}
                onClick={() => {
                  onUpdateStatusOpen();
                }}
              />
            )}
          </Box>
          <>
            <Text color={'shadedGray.400'}>Selected Appointment Slot:</Text>
            <Box>
              <Text color={'white'}>
                {`${dayjs(startTime).format('DD-MM-YYYY')}, ${dayjs
                  .utc(startTime)
                  .format('HH:mm A')} - ${dayjs
                  .utc(endTime)
                  .format('HH:mm A')}`}
              </Text>
              {status === NROB_APPOINTMENT_STATUS.APPOINTMENT_REQUESTED && (
                <>
                  <IconButton
                    size={'sm'}
                    rounded={'full'}
                    colorScheme="green"
                    icon={<FaCheck />}
                    onClick={() => {
                      onScheduleSlotOpen();
                    }}
                  />
                  <IconButton
                    size={'sm'}
                    rounded={'full'}
                    colorScheme="red"
                    icon={<IoClose />}
                    onClick={() => {
                      onRejectModalOpen();
                    }}
                  />
                </>
              )}
            </Box>
          </>
        </Grid>
        <DisplayData data={data} />
      </Box>
    </>
  );
};

export default NrOnboardAppointment;

import { toOrderData, toOrderMetadata, toUserData } from '../../utils';
import { ORDERS, RES_DATA as types } from '../constants';
const initialState = {
  users: {
    isLoading: false,
    isError: false,
    error: null,
    data: {},
    totalPages: 0
  },
  orders: {
    isLoading: false,
    isError: false,
    error: null,
    data: {},
    totalPages: 0,
    totalOrders: 0
  },
  luluOrders: {
    isLoading: false,
    isError: false,
    error: null,
    data: {},
    totalPages: 0
  }
};

const resDataReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SEARCHING_USERS:
      return {
        ...state,
        users: {
          ...state.users,
          isLoading: true,
          isError: false,
          error: null,
          data: payload.refresh
            ? {}
            : {
                ...state.users.data,
                [payload.pageNo]: null
              }, //clear all pages if refresh is true
          totalPages: payload.refresh ? 0 : state.users.totalPages
        }
      };

    case types.SEARCH_USERS_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          isLoading: false,
          isError: false,
          error: null,
          data: {
            ...state.users.data,
            [payload.currentPageNumber]:
              payload.users?.map((user) => toUserData(user)) || []
          },
          totalPages: payload.totalPage
        }
      };

    case types.SEARCH_USERS_FAILURE:
      return {
        ...state,
        users: {
          ...state.users,
          isLoading: false,
          isError: true,
          error: payload.error,
          data: {
            ...state.users.data,
            [payload.pageNo]: null
          },
          totalPages: 0
        }
      };

    case types.SEARCHING_ORDERS:
      return {
        ...state,
        orders: {
          ...state.orders,
          isLoading: true,
          isError: false,
          error: null,
          data: payload.refresh
            ? {}
            : {
                ...state.orders.data,
                [payload.pageNo]: null
              }, //clear all pages if refresh is true
          totalPages: payload.refresh ? 0 : state.orders.totalPages,
          totalOrders: payload.refresh ? 0 : state.orders.totalOrders
        }
      };

    case types.SEARCH_ORDERS_SUCCESS:
      return {
        ...state,
        orders: {
          ...state.orders,
          isLoading: false,
          isError: false,
          error: null,
          data: {
            [payload.currentPageNumber]:
              payload.orders?.map((item) => toOrderData(item)) || []
          },
          totalPages: payload.totalPage,
          totalOrders: payload.totalOrders
        }
      };

    case types.SEARCH_ORDERS_FAILURE:
      return {
        ...state,
        orders: {
          ...state.orders,
          isLoading: false,
          isError: true,
          error: payload.error,
          data: {
            ...state.orders.data,
            [payload.pageNo]: null
          },
          totalPages: 0,
          totalOrders: 0
        }
      };

    case ORDERS.UPDATE_ORDER_METADATA_SUCCESS:
      const metadata = toOrderMetadata(payload);
      const orderId = metadata?.orderId;

      //find location of updated order in funnel data
      const { page, index } =
        state.orders &&
        Object.keys(state.orders.data).reduce(
          (res, page) => {
            const index = state.orders.data[page]?.findIndex(
              (order) => order?.orderId === orderId
            );

            return index === -1
              ? res
              : {
                  page,
                  index
                };
          },
          { page: null, index: null }
        );

      if (!page || !state.orders.data[page]?.length) return { ...state };

      return {
        ...state,
        orders: {
          ...state.orders,
          data: {
            ...state.orders.data,
            [page]: [
              ...state.orders.data[page].slice(0, index),
              {
                ...state.orders.data[page][index],
                metadata: {
                  ...state.orders.data[page][index].metadata,
                  ...metadata
                }
              },
              ...state.orders.data[page].slice(index + 1)
            ]
          }
        }
      };

    case types.SEARCHING_LULU_ORDERS:
      return {
        ...state,
        luluOrders: {
          ...state.orders,
          isLoading: true,
          isError: false,
          error: null,
          data: null,
          totalPages: 0
        }
      };

    case types.SEARCH_LULU_ORDERS_SUCCESS:
      return {
        ...state,
        luluOrders: {
          ...state.orders,
          isLoading: false,
          isError: false,
          error: null,
          data: payload?.data,
          totalPages: payload?.totalPages
        }
      };

    case types.SEARCH_LULU_ORDERS_FAILURE:
      return {
        ...state,
        luluOrders: {
          ...state.orders,
          isLoading: false,
          isError: true,
          error: payload,
          data: null,
          totalPages: 0
        }
      };

    default:
      return state;
  }
};

export default resDataReducer;

import api from '../../api';
import { WORKFLOW_V2 } from '../constants';
import { setAlert } from './alertActions';

export const getWorkflowsData =
  ({ userId, workflowType }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: WORKFLOW_V2.GETTING_WORKFLOW_SCREENS_DATA
      });

      const { data } = await api.workflowsV2.getScreens({
        userId,
        workflowType
      });

      dispatch({
        type: WORKFLOW_V2.GET_WORKFLOW_SCREENS_DATA_SUCCESS,
        payload: { ...data }
      });
    } catch (error) {
      dispatch({
        type: WORKFLOW_V2.GET_WORKFLOW_SCREENS_DATA_FAILURE,
        payload: {
          errMsg: error?.message
        }
      });
    }
  };

export const triggerWorkflowByDoc =
  ({ userId, subDocType, documentKey, onSuccess, onError, onComplete }) =>
  async (dispatch) => {
    try {
      const { data } = await api.workflowsV2.triggerOcrByDoc({
        userId,
        subDocumentType: subDocType,
        documentKey
      });
      if (data?.status !== 'SUCCESS') {
        throw new Error('Trigger OCR Failed');
      }
      onSuccess && onSuccess(data);
    } catch (error) {
      onError && onError(error?.message);
    } finally {
      onComplete && onComplete();
    }
  };

export const updateOcrByDoc =
  ({ userId, payload, onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      const apiPayload = {
        ocr_data: {
          ...payload?.ocrData,
          document_type: payload?.ocrData?.documentType
        }
      };
      delete apiPayload['ocr_data']['documentType'];
      await api.workflowsV2.updateOcrByDoc({
        userId,
        payload: apiPayload
      });
      onSuccess && onSuccess();
    } catch (error) {
      dispatch(
        setAlert({
          title: error.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

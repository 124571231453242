import api from '../../api';
import { USER_REFERRALS as types } from '../constants';
import { setAlert } from './alertActions';

export const getUserReferrals =
  ( userId, pageNo, pageSize ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: types.GETTING_REFERRALS
      });

      const { data } = await api.user.getReferrals({ userId, pageNo, pageSize });

      dispatch({
        type: types.GET_REFERRALS_SUCCESS,
        payload: data
      });

    } catch (error) {
      console.log(error);
      dispatch(
        setAlert({
          title: 'Something went wrong while fetching user referrals!',
          status: 'error'
        })
      );
      dispatch({
        type: types.GET_REFERRALS_FAILURE,
        payload: error
      });
    }
  };

export const clearUserReferrals = () =>
    async (dispatch) => {
        dispatch({
            type: types.CLEAR_USER_REFERRALS
        });
    };
import api from '../../api';
import { setAlert } from './alertActions';
import { RDA_DASHBOARD_CONSTANTS as actionTypes } from './../constants';

export const getTrueLayerBalance =
  ({ onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      const { data } = await api.rdaDashboard.getTrueLayerBalance();
      onSuccess && onSuccess(data);
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const getYblBalance =
  ({ onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      const { data } = await api.rdaDashboard.getYblBalance();
      onSuccess && onSuccess(data);
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const getYblPotBalance =
  ({ onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      const { data } = await api.rdaDashboard.getYblPotBalance();
      onSuccess && onSuccess(data);
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const getRdaGbpBalance =
  ({ onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      const { data } = await api.rdaDashboard.getRdaGbpBalance();
      onSuccess && onSuccess(data);
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const getOverallTxns =
  ({ status, fromDate, pageNumber, pageSize }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: actionTypes.GETTING_OVERALL_TXNS
      });

      const { data } = await api.rdaDashboard.getOverallTxns({
        status,
        fromDate,
        pageNumber,
        pageSize
      });

      dispatch({
        type: actionTypes.GET_OVERALL_TXNS_SUCCESS,
        payload: data
      });
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );

      dispatch({
        type: actionTypes.GET_OVERALL_TXNS_FAIL,
        payload: {
          message: error?.message
        }
      });
    }
  };

export const getTrueLayerPartnerTxns =
  ({ fromDate, pageNumber, pageSize }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: actionTypes.GETTING_TL_TXNS
      });

      const { data } = await api.rdaDashboard.getTrueLayerPartnerTxns({
        fromDate,
        pageNumber,
        pageSize
      });

      dispatch({
        type: actionTypes.GET_TL_TXNS_SUCCESS,
        payload: data
      });
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );

      dispatch({
        type: actionTypes.GET_TL_TXNS_FAIL,
        payload: {
          message: error?.message
        }
      });
    }
  };

export const getClearBankPartnerTxns =
  ({ transactionType, fromDate, pageNumber, pageSize }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: actionTypes.GETTING_CB_TXNS
      });

      const { data } = await api.rdaDashboard.getClearBankPartnerTxns({
        fromDate,
        pageNumber,
        pageSize,
        transactionType
      });

      dispatch({
        type: actionTypes.GET_CB_TXNS_SUCCESS,
        payload: data
      });
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );

      dispatch({
        type: actionTypes.GET_CB_TXNS_FAIL,
        payload: {
          message: error?.message
        }
      });
    }
  };

export const getYblPartnerTxns =
  ({ fromDate, pageNumber, pageSize }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: actionTypes.GETTING_YBL_PARTNER_TXNS
      });

      const { data } = await api.rdaDashboard.getYblPartnerTxns({
        fromDate,
        pageNumber,
        pageSize
      });

      dispatch({
        type: actionTypes.GET_YBL_PARTNER_TXNS_SUCCESS,
        payload: data
      });
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );

      dispatch({
        type: actionTypes.GET_YBL_PARTNER_TXNS_FAIL,
        payload: {
          message: error?.message
        }
      });
    }
  };

const KYC = {
  GETTING_KYC_DOCS: 'GETTING_KYC_DOCS',
  GET_KYC_DOCS_SUCCESS: 'GET_KYC_DOCS_SUCCESS',
  GET_KYC_DOCS_FAILURE: 'GET_KYC_DOCS_FAILURE',
  CLEAR_KYC_DOCS: 'CLEAR_KYC_DOCS',

  GETTING_KYC_CHECKS: 'GETTING_KYC_CHECKS',
  GET_KYC_CHECKS_SUCCESS: 'GET_KYC_CHECKS_SUCCESS',
  GET_KYC_CHECKS_FAILURE: 'GET_KYC_CHECKS_FAILURE',
  CLEAR_KYC_CHECKS: 'CLEAR_KYC_CHECKS',

  UPDATING_KYC_DOC_STATUS: 'UPDATING_KYC_DOC_STATUS',
  UPDATE_KYC_DOC_STATUS_SUCCESS: 'UPDATE_KYC_DOC_STATUS_SUCCESS',
  UPDATE_KYC_DOC_STATUS_FAILURE: 'UPDATE_KYC_DOC_STATUS_FAILURE',

  GETTING_ALL_KYCS: 'GETTING_ALL_KYCS',
  GET_ALL_KYCS_SUCCESS: 'GET_ALL_KYCS_SUCCESS',
  GET_ALL_KYCS_FAILURE: 'GET_ALL_KYCS_FAILURE',
  CLEAR_ALL_KYCS: 'CLEAR_ALL_KYCS',

  GETTING_USER_KYC: 'GETTING_USER_KYC',
  GET_USER_KYC_SUCCESS: 'GET_USER_KYC_SUCCESS',
  GET_USER_KYC_FAILURE: 'GET_USER_KYC_FAILURE',
  CLEAR_USER_KYC: 'CLEAR_USER_KYC',

  SYNCING_USER_KYC: 'SYNCING_USER_KYC',
  SYNC_USER_KYC_SUCCESS: 'SYNC_USER_KYC_SUCCESS',
  SYNC_USER_KYC_FAILURE: 'SYNC_USER_KYC_FAILURE',

  UPDATING_CHECK_STATUS: 'UPDATING_CHECK_STATUS',
  UPDATE_CHECK_STATUS_SUCCESS: 'UPDATE_CHECK_STATUS_SUCCESS',
  UPDATE_CHECK_STATUS_FAILURE: 'UPDATE_CHECK_STATUS_FAILURE',

  CREATING_E_KYC: 'CREATING_E_KYC',
  CREATE_E_KYC_SUCCESS: 'CREATE_E_KYC_SUCCESS',
  CREATE_E_KYC_FAILURE: 'CREATE_E_KYC_FAILURE',

  CREATING_M_KYC: 'CREATING_M_KYC',
  CREATE_M_KYC_SUCCESS: 'CREATE_M_KYC_SUCCESS',
  CREATE_M_KYC_FAILURE: 'CREATE_M_KYC_FAILURE'
};

export default KYC;

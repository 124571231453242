import {
  Flex,
  HStack,
  IconButton,
  Text,
  useDisclosure,
  VStack
} from '@chakra-ui/react';
import React from 'react';
import { AiFillEdit } from 'react-icons/ai';
import ModalLayout from '../Modal/ModalLayout';
import OrderMetaDataModal from '../Modal/OrderMetadataModal';

const Notes = ({
  note,
  updatedByEmail,
  updatedAt,
  orderId,
  complianceFlagged
}) => {
  const {
    isOpen: isPostNotesOpen,
    onOpen: onPostNotesOpen,
    onClose: onPostNotesClose
  } = useDisclosure();

  return (
    <>
      <ModalLayout isOpen={isPostNotesOpen} onClose={onPostNotesClose}>
        <OrderMetaDataModal
          orderId={orderId}
          onClose={onPostNotesClose}
          initNote={note}
          initFlag={complianceFlagged}
          title={note ? 'Edit note' : 'Add note'}
          successMessage={`${
            note ? 'Edited' : 'Added'
          } note for order ${orderId}`}
          failureMessage={`Failed to ${
            note ? 'edit' : 'add'
          } note for order ${orderId}`}
          isEdit={note}
        />
      </ModalLayout>

      <VStack
        color={'white'}
        py={4}
        pr={2}
        maxW={'40rem'}
        alignItems={'flex-start'}
        gap={1}
      >
        <Flex justifyContent={'space-between'} alignItems={'center'} w={'100%'}>
          <VStack alignItems={'flex-start'}>
            <Text>Notes: </Text>
            {updatedByEmail && (
              <Flex
                alignItems={'center'}
                w={'100%'}
                color={'shadedGray.500'}
                fontSize={'0.7rem'}
                gap={1}
                style={{ marginTop: '0px' }}
              >
                <Text>Last updated by: </Text>
                <Text>{updatedByEmail}</Text>
              </Flex>
            )}
            {updatedAt && (
              <Flex
                alignItems={'center'}
                w={'100%'}
                color={'shadedGray.500'}
                fontSize={'0.7rem'}
                gap={1}
                style={{ marginTop: '0px' }}
              >
                <Text>Updated at: </Text>
                <Text>{updatedAt}</Text>
              </Flex>
            )}
          </VStack>

          <HStack>
            <IconButton
              colorScheme="brand"
              aria-label="Search database"
              icon={<AiFillEdit />}
              size={'sm'}
              color="altGray.200"
              rounded={'full'}
              onClick={onPostNotesOpen}
            />
          </HStack>
        </Flex>

        <Text
          style={{
            marginTop: '0px'
          }}
          fontSize={'0.85rem'}
        >
          {note || '-'}
        </Text>
      </VStack>
    </>
  );
};

export default Notes;

import React, { useCallback, useEffect, useState } from 'react';
import ContentLayout from '../../layouts/ContentLayout';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  clearUserDocs,
  searchUserDocs
} from '../../store/actions/workflowActions';
import FullPageSpinner from '../../components/FullPageSpinner';
import {
  Box,
  Divider,
  Flex,
  Grid,
  Heading,
  VStack,
  useDisclosure
} from '@chakra-ui/react';
import Pagination from '../../components/Pagination';
import WorkflowDocCard from '../../components/WorkflowDocCard';
import NronboardOcrDetails from '../../components/NrOnboard/OcrDetails';
import { getOcrDetails } from '../../store/actions/nronboardActions';
import {
  camelCaseToSpaceSeparated,
  kebabCaseToSpaceSeperate
} from '../../utils';
import ModalLayout from '../../components/Modal/ModalLayout';
import EditOcrModal from './EditOcrModal';
import { updateOcrByDoc } from '../../store/actions/workFlowsActionsV2';

const UserWorkflowDocs = () => {
  const { userId } = useParams();
  const {
    isLoading,
    data: docs,
    totalPages
  } = useSelector((state) => state.workflow.docs);
  const [pageNo, setPageNo] = useState(0);

  const {
    isOpen: isEditOcrOpen,
    onOpen: onEditOcrOpen,
    onClose: onEditOcrClose
  } = useDisclosure();

  const dispatch = useDispatch();

  useEffect(() => {
    triggerSearchUserDocs({ userId, pageNo });
  }, [userId, pageNo]);

  /* For OCR Data */
  const [ocrData, setOcrData] = useState({});
  const [isOcrLoading, setOcrLoading] = useState(false);

  const [selectedOcrKey, setSelectedOcrKey] = useState(null);

  useEffect(() => {
    fetchOcrData();
    return () => dispatch(clearUserDocs());
  }, []);

  const fetchOcrData = () => {
    setOcrLoading(true);
    dispatch(
      getOcrDetails({
        userId,
        isNronboarding: false,
        onSuccess: (data) => {
          setOcrData(data?.ocrData);
        },
        onComplete: () => {
          setOcrLoading(false);
        }
      })
    );
  };

  const triggerSearchUserDocs = useCallback(
    ({ userId, pageNo }) => {
      dispatch(searchUserDocs({ userId, pageNo }));
    },
    [dispatch]
  );

  const handlePageChange = useCallback(
    (currPage) => {
      currPage - 1 !== pageNo && setPageNo(currPage - 1);
    },
    [pageNo]
  );

  if (isLoading) return <FullPageSpinner />;

  const hasOcrData = (docKey) => {
    function transform(str) {
      return str.replace(/[\s_]+/g, '').toLowerCase();
    }
    const DOC_KEYS = [
      'PASSPORT_FRONT',
      'PASSPORT_BACK',
      'BANK_STATEMENT',
      'BRP_FRONT',
      'BRP_BACK',
      'EMIRATES_ID',
      'VISA'
    ];
    return (
      DOC_KEYS.findIndex((key) => transform(key) === transform(docKey)) !== -1
    );
  };

  const handleEditOcrClick = (docKey) => {
    if (!docKey) return;
    setSelectedOcrKey(docKey);
    onEditOcrOpen && onEditOcrOpen();
  };

  const handleEditOcrSubmit = (data) => {
    setOcrLoading(true);
    dispatch(
      updateOcrByDoc({
        userId,
        payload: {
          ...data
        },
        onSuccess: () => {
          // TODO: fetch OCR data here
          fetchOcrData();
        },
        onComplete: () => {
          setOcrLoading(false);
          handleEditOcrCancel();
        }
      })
    );
  };

  const handleEditOcrCancel = () => {
    setSelectedOcrKey(null);
    onEditOcrClose && onEditOcrClose();
  };

  return (
    <>
      <ModalLayout isOpen={isEditOcrOpen} onClose={handleEditOcrCancel}>
        <EditOcrModal
          ocrData={ocrData}
          subDocumentType={selectedOcrKey}
          onCancel={handleEditOcrCancel}
          onSubmit={handleEditOcrSubmit}
        />
      </ModalLayout>
      <ContentLayout>
        <Grid gridTemplateColumns={{ base: '1fr', lg: '7fr 0fr 2fr' }}>
          <VStack
            p={10}
            gap={4}
            overflowY={{ base: 'initial', lg: 'scroll' }}
            overflowX={'hidden'}
            h={{ lg: '90vh' }}
            px={{ base: 4, sm: 10 }}
            alignItems={'stretch'}
            className="scroll"
          >
            <Flex
              w={'100%'}
              alignItems={'baseline'}
              justifyContent={'space-between'}
              pb={2}
            >
              <Heading color={'colorPrimary'}>User workflow docs</Heading>
            </Flex>

            {totalPages > 0 && (
              <Box alignSelf={'start'}>
                <Pagination
                  totalPages={totalPages}
                  currentPage={pageNo + 1}
                  onPageChange={handlePageChange}
                />
              </Box>
            )}
            <VStack alignSelf={'flex-start'} maxW={'100%'} gap={1}>
              {docs?.length > 0 &&
                docs.map((item, i) => (
                  <WorkflowDocCard
                    doc={item}
                    key={i}
                    hasOcr={hasOcrData(item?.documentId)}
                  />
                ))}
            </VStack>
            <NronboardOcrDetails
              ocrDetails={ocrData}
              isOnboarding={false}
              isLoading={isOcrLoading}
              handleEditClick={handleEditOcrClick}
            />
          </VStack>
          <Divider
            style={{ margin: '0' }}
            borderColor="whiteAlpha.300"
            orientation="vertical"
          ></Divider>
        </Grid>
      </ContentLayout>
    </>
  );
};

export default UserWorkflowDocs;

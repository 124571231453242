const ORDERS = {
  GETTING_ORDER_BY_ID: 'GETTING_ORDER_BY_ID',
  GET_ORDER_BY_ID_SUCCESS: 'GET_ORDER_BY_ID_SUCCESS',
  GET_ORDER_BY_ID_FAILURE: 'GET_ORDER_BY_ID_FAILURE',
  CLEAR_ORDER_DETAILS: 'CLEAR_ORDER_DETAILS',
  UPDATING_ORDER_METADATA: 'UPDATING_ORDER_METADATA',
  UPDATE_ORDER_METADATA_SUCCESS: 'UPDATE_ORDER_METADATA_SUCCESS',
  UPDATE_ORDER_METADATA_FAILURE: 'UPDATE_ORDER_METADATA_FAILURE',

  FAILING_ORDER: 'FAILING_ORDER',
  FAIL_ORDER_SUCCESS: 'FAIL_ORDER_SUCCESS',
  FAIL_ORDER_FAILURE: 'FAIL_ORDER_FAILURE',

  UPDATE_ORDER_TICKET_DATA: 'UPDATE_ORDER_TICKET_DATA'
};

export default ORDERS;

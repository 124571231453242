import {
  Box,
  Heading,
  Spinner,
  VStack,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Button,
  Th
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getOrderReceiptsByOrderId } from '../../store/actions/ordersActions';
import { tableCellStyles } from '../NrOnboard/constants';
import api from '../../api';

const OrderReceiptDetails = ({ orderId }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [receipts, setReceipts] = useState([]);
  const fetchReceipts = () => {
    setIsLoading(true);
    dispatch(
      getOrderReceiptsByOrderId({
        orderId,
        onSuccess: (data) => {
          setReceipts(data);
        },
        onComplete: () => {
          setIsLoading(false);
        }
      })
    );
  };
  useEffect(() => {
    fetchReceipts();
  }, []);

  const viewOrderReceipt = (resourcePath) => {
    api.orders
      .getOrderReceiptLinkByResourcePath({
        resourcePath
      })
      .then(({ data }) => {
        window.open(data?.shareLink, '_blank');
      })
      .catch((err) => {
        console.error(err?.message);
      });
  };

  const cellStyles = tableCellStyles();

  return (
    <VStack
      alignItems={'stretch'}
      width={'full'}
      marginY={4}
      gap={2}
      padding={0}
    >
      <Heading size={'md'} color={'white'}>
        Order Receipts
      </Heading>
      {!isLoading ? (
        <>
          {receipts?.length === 0 && (
            <Text color={'white'} fontSize={'md'}>
              No receipts found
            </Text>
          )}
          {receipts?.length > 0 && (
            <Table>
              <Thead>
                <Tr>
                  <Th style={cellStyles}>Receipt Id</Th>
                  <Th style={cellStyles}>Payment Ref</Th>
                  <Th style={cellStyles}>Receipt Id</Th>
                </Tr>
              </Thead>
              <Tbody>
                {receipts?.map((receipt) => (
                  <Tr key={receipt?.receiptId}>
                    <Td style={cellStyles}>
                      <Text color={'white'} fontSize={'md'}>
                        {receipt?.receiptId || '-'}
                      </Text>
                    </Td>
                    <Td style={cellStyles}>
                      <Text color={'white'} fontSize={'md'}>
                        {receipt?.paymentReferenceId || '-'}
                      </Text>
                    </Td>
                    <Td style={cellStyles}>
                      <Button
                        variant={'link'}
                        colorScheme="brand"
                        onClick={() => {
                          viewOrderReceipt(receipt?.resourcePath);
                        }}
                      >
                        View
                      </Button>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          )}
        </>
      ) : (
        <Spinner color="white" />
      )}
    </VStack>
  );
};

export default OrderReceiptDetails;

import {
  Grid,
  Heading,
  Table,
  Tbody,
  Tr,
  Td,
  Th,
  VStack,
  Spinner,
  Text,
  Thead
} from '@chakra-ui/react';
import ContentLayout from '../../layouts/ContentLayout';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getAllUaeManualPaymentsInLastTenMinutes } from '../../store/actions/uaeManualPaymentsActions';
import { tableCellStyles } from '../../components/NrOnboard/constants';

const UaeManualPayments = () => {
  const [loading, setLoading] = useState(false);
  const [payments, setPayments] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    dispatch(
      getAllUaeManualPaymentsInLastTenMinutes({
        onSuccess: (data) => {
          setPayments(data);
        },
        onComplete: () => {
          setLoading(false);
        }
      })
    );
  }, []);

  const cellStyles = tableCellStyles(4);

  /* 
  String userId;
  String orderId;
  String paymentId;
  UaeManualPaymentStatus status;
  BigDecimal amount;
  String transferId;
  String brnNumber;
  String bankDepositReference;
  String beneficiaryAccountId;
  String beneficiaryIban;
  Date createdAt;
  */

  return (
    <ContentLayout>
      <Grid gridTemplateColumns={{ base: '1fr', lg: '7fr 0fr 2fr' }}>
        <VStack
          width={'auto'}
          alignItems={'stretch'}
          gap={4}
          overflowY={{ base: 'initial', lg: 'scroll' }}
          overflowX={'hidden'}
          h={{ lg: '90vh' }}
          py={10}
          px={{ base: 4, sm: 10 }}
          className="scroll"
        >
          <Heading size={'md'} color={'white'}>
            UAE Manual Payments
          </Heading>
          {!loading ? (
            <Table>
              <Thead>
                <Tr>
                  <Th style={cellStyles}>Order ID</Th>
                  <Th style={cellStyles}>BRN Number</Th>
                  <Th style={cellStyles}>Status</Th>
                  <Th style={cellStyles}>Amount</Th>
                  <Th style={cellStyles}>Created At</Th>
                  <Th style={cellStyles}>Beneficiary Account ID</Th>
                </Tr>
              </Thead>
              <Tbody>
                {payments?.map((payment) => (
                  <Tr key={payment?.paymentId}>
                    <Td style={cellStyles}>
                      <Text fontSize={'md'} color={'white'}>
                        {payment?.orderId}
                      </Text>
                    </Td>
                    <Td style={cellStyles}>
                      <Text fontSize={'md'} color={'white'}>
                        {payment?.brnNumber}
                      </Text>
                    </Td>
                    <Td style={cellStyles}>
                      <Text fontSize={'md'} color={'white'}>
                        {payment?.status}
                      </Text>
                    </Td>
                    <Td style={cellStyles}>
                      <Text fontSize={'md'} color={'white'}>
                        {payment?.amount}
                      </Text>
                    </Td>
                    <Td style={cellStyles}>
                      <Text fontSize={'md'} color={'white'}>
                        {payment?.createdAt}
                      </Text>
                    </Td>
                    <Td style={cellStyles}>
                      <Text fontSize={'md'} color={'white'}>
                        {payment?.beneficiaryAccountId}
                      </Text>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          ) : (
            <Spinner />
          )}
        </VStack>
      </Grid>
    </ContentLayout>
  );
};

export default UaeManualPayments;

import api from '../../api';
import { NR_ACCOUNT as types } from '../constants';
import { setAlert } from './alertActions';

export const searchNrUsers = (searchReqData, refresh) => async (dispatch) => {
  try {
    dispatch({
      type: types.GETTING_NR_USERS,
      payload: {
        pageNo: searchReqData.pageNo,
        refresh
      }
    });

    const { data } = await api.nrAccount.users(searchReqData);

    return dispatch({
      type: types.GET_NR_USERS_SUCCESS,
      payload: data
    });
  } catch (error) {
    return dispatch({
      type: types.GET_NR_USERS_FAILURE,
      payload: {
        pageNo: searchReqData.pageNo,
        error
      }
    });
  }
};

export const getNrUserDetails = (userId) => async (dispatch) => {
  try {
    dispatch({
      type: types.GETTING_NR_USER_DETAILS
    });
    const { data } = await api.nrAccount.nrUserDetails(userId);

    return dispatch({
      type: types.GET_NR_USER_DETAILS_SUCCESS,
      payload: data
    });
  } catch (error) {
    return dispatch({
      type: types.GET_NR_USER_DETAILS_FAILURE,
      payload: error
    });
  }
};

export const updateNrAccountStatus =
  ({ userId, accountStatus, onSuccess, onFinish }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: types.UPDATING_NR_USER_STATUS
      });

      await api.nrAccount.updateNrAccountStatus({ userId, accountStatus });

      if (onSuccess) onSuccess();

      dispatch(
        setAlert({
          title: `Updated onboarding status to ${accountStatus}`,
          status: 'success'
        })
      );

      return dispatch({
        type: types.UPDATE_NR_USER_STATUS_SUCCESS,
        payload: {
          accountStatus
        }
      });
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message || `Failed to update onboarding status!`,
          status: 'error'
        })
      );

      return dispatch({
        type: types.UPDATE_NR_USER_STATUS_FAILURE
      });
    } finally {
      if (onFinish) onFinish();
    }
  };

export const getOnboardingDetails = (userId) => async (dispatch) => {
  try {
    dispatch({
      type: types.GETTING_ONBOARDING_DETAILS
    });

    const { data } = await api.nrAccount.onboardingDetails(userId);

    return dispatch({
      type: types.GET_ONBOARDING_DETAILS_SUCCESS,
      payload: data
    });
  } catch (error) {
    return dispatch({
      type: types.GET_ONBOARDING_DETAILS_FAILURE,
      payload: error
    });
  }
};

export const clearNrUserAccountDetails = () => ({
  type: types.CLEAR_NR_USER_DETAILS
});

export const clearOnboardingDetails = () => ({
  type: types.CLEAR_ONBOARDING_DETAILS
});

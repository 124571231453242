import {
  Box,
  Button,
  FormLabel,
  HStack,
  Input,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Select,
  Spinner,
  VStack
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getUserBeneficiaries } from '../../store/actions/usersActions';

const UpdateOrderBeneficiaryModal = ({
  initialBeneficiaryId,
  userId,
  isLoading,
  onCancel,
  onSubmit
}) => {
  const [isFetchLoading, setIsFetchLoading] = useState(false);
  const [userBeneficiaries, setUserBeneficiaries] = useState([]);
  const [selectedBeneficiary, setSelectedBeneficiary] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    setIsFetchLoading(true);
    dispatch(
      getUserBeneficiaries({
        userId,
        onSuccess: (data) => {
          const beneficiaries = data || [];
          setUserBeneficiaries(beneficiaries);
          const initial = beneficiaries?.filter(
            (beneficiary) => beneficiary?.id === initialBeneficiaryId
          );
          if (initial?.length > 0)
            setSelectedBeneficiary(initial[0]?.beneficiaryId);
        },
        onComplete: () => {
          setIsFetchLoading(false);
        }
      })
    );
  }, [userId, initialBeneficiaryId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit && onSubmit(selectedBeneficiary);
  };

  const handleCancel = () => {
    setSelectedBeneficiary(null);
    onCancel && onCancel();
  };

  return (
    <>
      <ModalHeader>Update Order Beneficiary</ModalHeader>
      <form onSubmit={handleSubmit}>
        {!isFetchLoading ? (
          <>
            <ModalBody>
              <VStack alignItems={'stretch'} w={'full'}>
                <Box width={'full'}>
                  <FormLabel>Beneficiary</FormLabel>
                  <Select
                    placeholder="Select Beneficiary"
                    value={selectedBeneficiary}
                    onChange={(e) => {
                      setSelectedBeneficiary(e?.target?.value);
                    }}
                  >
                    {userBeneficiaries?.map((beneficiary) => (
                      <option
                        selected={
                          beneficiary?.beneficiaryId === initialBeneficiaryId
                        }
                        value={beneficiary?.beneficiaryId}
                        key={beneficiary?.beneficiaryId}
                      >
                        {beneficiary?.accountHolderNickname} -{' '}
                        {beneficiary?.accountNumber}
                      </option>
                    ))}
                  </Select>
                </Box>
              </VStack>
            </ModalBody>
            <ModalFooter>
              <HStack>
                <Button
                  type="button"
                  variant={'outline'}
                  colorScheme="red"
                  isDisabled={isLoading}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  variant={'outline'}
                  colorScheme="brand"
                  isLoading={isLoading}
                  type="submit"
                >
                  Submit
                </Button>
              </HStack>
            </ModalFooter>
          </>
        ) : (
          <Spinner />
        )}
      </form>
    </>
  );
};

export default UpdateOrderBeneficiaryModal;

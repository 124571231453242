export default function nrApi(fetch, baseUrl) {
  return {
    users({
      pageNo = 0,
      pageSize = 10,
      query = '',
      filterKey = '',
      filterValue = ''
    }) {
      return fetch(
        `${baseUrl}/search?page_size=${pageSize}&page=${pageNo}${
          query?.length ? `&search=${query}` : ''
        }${
          filterKey?.length
            ? `&filter_key=${filterKey}&filter_value=${filterValue}`
            : ''
        }`
      );
    },

    nrUserDetails(userId) {
      return fetch(`${baseUrl}/${userId}`);
    },

    updateNrAccountStatus({ userId, accountStatus }) {
      return fetch.patch(`${baseUrl}/${userId}/status`, {
        accountStatus
      });
    },

    onboardingDetails(userId) {
      return fetch.get(`${baseUrl}/${userId}/onboarding`);
    },

    doc(url) {
      return fetch.get(`${baseUrl}/doc/${url}`);
    }
  };
}

import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import ContentLayout from '../../layouts/ContentLayout';
import { Box, Text, Grid, Heading, Stack, VStack, Spinner } from '@chakra-ui/react';
import {
    clearUserReferrals,
    getUserReferrals
} from '../../store/actions/userReferralActions';
import { updateUserReferralReqData } from '../../store/actions/reqDataActions';
import Pagination from '../../components/Pagination';
import UserReferralCard from '../../components/UserReferralCard';

const Referrals = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userId } = useParams();
  const referralReqData = useSelector((state) => state.reqData.user.referrals);
  const referrals = useSelector((state) => state.userReferral);
  const isLoading = useSelector((state) => state.userReferral.isLoading);

  useEffect(() => {
    dispatch(getUserReferrals( userId, referralReqData.pageNo ));
  }, [referralReqData]);

  useEffect(() => {
    return (() => dispatch(clearUserReferrals()));
  }, []);

  const handlePageChange = useCallback(
    (pageNo) => {
      pageNo - 1 !== referralReqData.pageNo &&
        dispatch(updateUserReferralReqData({ pageNo: pageNo - 1 }));
    },
    [dispatch, referralReqData.pageNo]
  );

  const onUserDetailsClick = useCallback(
    (userId) => {
      navigate(`/user/${userId}`);
    }, []
  );

  return (
    <ContentLayout>
      <Grid gridTemplateColumns={{ base: '1fr', lg: '7fr 0fr 2fr' }}>
        {' '}
        <VStack
          p={10}
          gap={4}
          px={{ base: 4, sm: 10 }}
          alignItems={'flex-start'}
          w={'100%'}
        >
            <Stack
                direction={{ base: 'column', md: 'row' }}
                justifyContent={'space-between'}
                w={'100%'}
                alignItems={{ base: 'flex-start', md: 'center' }}
                gap={{ base: 3, md: 1 }}
            >
                <VStack alignItems={'flex-start'}>
                    <Heading color={'colorPrimary'} pb={2}>
                        USER REFERRALS
                    </Heading>
                </VStack>
            </Stack>
            {referrals.totalPages > 1 && (
            <Box alignSelf={'start'}>
                <Pagination
                    totalPages={referrals.totalPages}
                    currentPage={referralReqData.pageNo + 1}
                    onPageChange={handlePageChange}
                />
            </Box>
            )}
            <VStack
                py={5}
                alignSelf={'flex-start'}
                w={{ base: '100%', lg: '56rem' }}
                maxW={'100%'}
                alignItems={'stretch'}
                gap={1}
            >
            {
                isLoading
                    ? <Spinner color="brand.600"/> :
                    referrals.pages[referralReqData.pageNo]?.data?.length === 0
                        ? <Text color="brand.500">No referrals</Text> :
                        referrals.pages[referralReqData.pageNo]?.data?.map((item) => (
                            <UserReferralCard
                                key={item.id}
                                referralData={item}
                                onUserDetailsClick={(userId) => onUserDetailsClick(userId)}
                            />
                        ))
            }
            </VStack>      
        </VStack>
      </Grid>
    </ContentLayout>
  );
};

export default Referrals;

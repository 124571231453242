import { FormLabel, Input, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import useDebounce from '../../hooks/useDebounce';
import { useDispatch, useSelector } from 'react-redux';
import { updateVdaFunnelReqData } from '../../store/actions/reqDataActions';
import { getVdaTransactions } from '../../store/actions/funnelActions';

const VdaOrderSearch = () => {
  const dispatch = useDispatch();
  const { search, page, pageSize, status } = useSelector(
    (state) => state?.reqData?.funnel?.vdaFunnel
  );
  const [searchQuery, setSearchQuery] = useState(search);
  const debouncedValue = useDebounce(searchQuery, 500);

  useEffect(() => {
    dispatch(
      updateVdaFunnelReqData({
        page,
        pageSize,
        status,
        search: debouncedValue
      })
    );
  }, [debouncedValue]);

  const handleChange = (e) => {
    const value = e?.target?.value;
    setSearchQuery(value);
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
  };

  return (
    <form onSubmit={handleSubmit}>
      <VStack>
        {/* <FormLabel>Search by Order ID</FormLabel> */}
        <Input
          color={'white'}
          placeholder="Search by Order ID"
          onChange={handleChange}
          value={searchQuery}
        />
      </VStack>
    </form>
  );
};

export default VdaOrderSearch;

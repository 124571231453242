import { Text } from '@chakra-ui/react';
import TlTxnDetailsCard from './Details/TlTxnDetails';
import CbTxnDetailsCard from './Details/CbTxnDetails';
import YblTxnDetailsCard from './Details/YblTxnDetails';
import OverallTxnDetailsCard from './Details/OverallTxnDetails';

const TxnDetailsCard = (props) => {
  const { cardType, ...componentProps } = props;

  switch (cardType) {
    case 'OVERALL':
      return <OverallTxnDetailsCard {...componentProps} />;

    case 'TRUE_LAYER':
      return <TlTxnDetailsCard {...componentProps} />;

    case 'CLEAR_BANK':
      return <CbTxnDetailsCard {...componentProps} />;

    case 'YBL':
      return <YblTxnDetailsCard {...componentProps} />;

    default:
      return (
        <>
          <Text color={'gray.500'} fontSize={'md'}>
            Default component
          </Text>
        </>
      );
  }
};

export default TxnDetailsCard;

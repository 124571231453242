import OffRampData from '../../components/vdaDashboard/OffRampData';
import { setAlert } from '../actions/alertActions';
import { falcon_ops as types } from '../constants/falconConstants';

const initialState = {
  reqBody: {
    search_key: '',
    search_value: '',
    filter_key: '',
    filter_value: [],
    page_number: 0,
    page_size: 10,
    sort_key: 'client_txn_id',
    sort_order: 'DESC'
  },
  transactionsData: {
    falconTransactionsList: [],
    currentPage: 0,
    totalPages: 1,
    totalResults: 0
  },
  transactionData: {}
};

const falconTransactionReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.GETTING_FALCON_TRANSACTIONS:
      return { ...state, isLoadingTransactionList: true };

    case types.GET_FALCON_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        isLoadingTransactionList: false,
        transactionsData: {
          falconTransactionsList: [...payload.data],
          currentPage: payload.page,
          totalPages: payload.totalPages,
          totalResults: payload.totalResults
        }
      };

    case types.GET_FALCON_TRANSACTIONS_FAILURE:
      return {
        ...state,
        isLoadingTransactionList: false
      };

    case types.GETTING_FALCON_TRANSACTION_DETAILS:
      return {
        ...state,
        isLoadingTransaction: true
      };

    case types.GET_FALCON_TRANSACTION_DETAILS_SUCCESS:
      return {
        ...state,
        isLoadingTransaction: false,
        transactionData: payload
      };

    case types.GET_FALCON_TRANSACTION_DETAILS_FAILURE:
      return {
        ...state,
        isLoadingTransaction: false
      };

    default:
      return state;
  }
};

export default falconTransactionReducer;

import { useSelector } from 'react-redux';
import { ROLES } from '../constants';

export default function useIsAdmin() {
  const role = useSelector((state) => state.auth.role);
  const isAdmin =
    role?.includes(ROLES.MANAGER_ROLE) || role?.includes(ROLES.ADMIN_ROLE);

  return isAdmin;
}

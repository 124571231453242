import { AUTH as types } from '../constants';
import api from '../../api';
import auth from '../../auth';
import { toErrorData } from '../../utils';
import { setAlert } from './alertActions';

const getAppData = async () => {
  if (!auth.getToken()) return Promise.reject();

  const { data } = await api.auth.me();
  return data;
};

export const login =
  ({ username, password }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: types.LOGGING_IN
      });

      const { data } = await api.auth.login({ username, password });

      if (!data?.accessToken)
        return dispatch({
          type: types.LOGIN_FAILURE,
          payload: {
            message: 'access_token missing in response',
            error: data
          }
        });

      auth.setToken(data.accessToken);
      auth.setRole(data.role);
      const userData = await getAppData();

      return dispatch({
        type: types.LOGIN_SUCCESS,
        payload: {
          userData,
          role: data.role
        }
      });
    } catch (error) {
      const message =
        error.code === 'ECONNABORTED'
          ? 'Please connect to VPN!'
          : error.message;
      dispatch(setAlert({ title: message, status: 'error' }));

      return dispatch({
        type: types.LOGIN_FAILURE,
        payload: error
      });
    }
  };

export const loginFromLocalStorage = () => async (dispatch) => {
  try {
    dispatch({
      type: types.LOGGING_IN
    });

    const userData = await getAppData();
    const role = await auth.getRole();

    return dispatch({
      type: types.LOGIN_SUCCESS,
      payload: {
        userData,
        role
      }
    });
  } catch (error) {
    return dispatch({
      type: types.LOGIN_FROM_LOCAL_STORAGE_FAILURE
    });
  }
};

export const refresh = () => async (dispatch) => {
  try {
    dispatch({
      type: types.LOGGING_IN
    });

    const userData = await getAppData();

    return dispatch({
      type: types.LOGIN_SUCCESS,
      payload: userData
    });
  } catch (error) {
    return dispatch({
      type: types.LOGIN_FAILURE,
      payload: error
    });
  }
};

export const logout = () => async (dispatch) => {
  try {
    await auth.removeToken();
    return dispatch({
      type: types.LOGOUT
    });
  } catch (error) {
    return dispatch({
      type: types.LOGOUT_FAILURE
    });
  }
};

import { Button, Spinner, Text, VStack } from '@chakra-ui/react';
import { CARD_TYPE } from '../../constants';
import Pagination from '../Pagination';
import GenericCard from '../GenericCard';
import { useNavigate } from 'react-router-dom';
import { MdEast } from 'react-icons/md';
import NrobFunnelLocalFilter from './NrobFunnelLocalFilter';
import NrobFunnelSubStateFilter from './NrobFunnelFilter';

const NrobFunnelList = ({
  filterType = null,
  currentPage,
  onPageChange,
  totalPages,
  data,
  isLoading
}) => {
  const navigate = useNavigate();
  if (isLoading) return <Spinner colorScheme="white" />;
  return (
    <>
      <VStack alignItems={'stretch'} gap={2}>
        <NrobFunnelLocalFilter filterType={filterType} />
        <NrobFunnelSubStateFilter />
        <Pagination
          currentPage={currentPage + 1}
          onPageChange={(val) => onPageChange(val)}
          totalPages={totalPages}
        />
        {data?.length === 0 && (
          <Text color={'white'} fontSize={'md'}>
            No users found!
          </Text>
        )}
        {data && data?.length > 0 && (
          <>
            {data?.map((user) => {
              return (
                <GenericCard
                  key={user?.userId}
                  cardType={CARD_TYPE.NRONBOARD}
                  cardData={user}
                  ActionButtons={[
                    <Button
                      key={'order-details-button'}
                      mt={{ base: 2, md: 0 }}
                      ml={{ base: 0, md: 1 }}
                      rightIcon={<MdEast color="black" />}
                      type="submit"
                      rounded="lg"
                      color={'black'}
                      colorScheme="brand"
                      alignSelf={{ base: 'center', md: 'center' }}
                      px={5}
                      onClick={() => {
                        navigate(`/onboarding/${user?.userId}`);
                      }}
                      size={'sm'}
                    >
                      Onboarding
                    </Button>
                  ]}
                />
              );
            })}
          </>
        )}
      </VStack>
    </>
  );
};

export default NrobFunnelList;

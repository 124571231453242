import { Heading, Spinner, VStack } from '@chakra-ui/react';
import React, { useCallback, useEffect } from 'react';
import OrdersList from '../../components/OrdersList';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserRemittanceOrdersReqData } from '../../store/actions/reqDataActions';
import useDidMountEffect from '../../hooks/useDidMount';
import {
  clearUserOrders,
  getUserOrders
} from '../../store/actions/usersActions';
import useIsMobile from '../../hooks/useIsMobile';
import { useNavigate } from 'react-router-dom';
import { PRODUCT } from '../../constants';

const UserTransfers = ({
  userId,
  setSelectedOrder,
  maxW = 'none',
  isSidebar = false
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const {
    data: orders,
    totalPages,
    isLoading
  } = useSelector((state) => state.users.userDetails.orders);

  const searchReqData = useSelector(
    (state) => state.reqData.user.orders.remittance
  );

  useDidMountEffect(() => {
    if (!orders.length || isSidebar)
      dispatch(getUserOrders({ userId, ...searchReqData }));

    return () => dispatch(clearUserOrders());
  }, [userId]);

  useEffect(() => {
    dispatch(getUserOrders({ userId, ...searchReqData }));
  }, [searchReqData]);

  const handlePageChange = useCallback(
    (pageNo) => {
      pageNo - 1 !== searchReqData.pageNo &&
        dispatch(updateUserRemittanceOrdersReqData({ pageNo: pageNo - 1 }));
    },
    [dispatch, searchReqData.pageNo]
  );

  const onOrderDetailsClick = useCallback(
    (orderId) => {
      // if (isSidebar) navigate(`/user/${userId}?orderId=${orderId}`);
      // else if (isMobile) navigate(`/order/${orderId}`);
      // else
      setSelectedOrder({
        product: PRODUCT.REMITTANCE,
        orderId
      });
    },
    [isMobile, userId, navigate, setSelectedOrder]
  );

  if (isLoading) return <Spinner color="brand.500" />;

  return (
    <VStack
      w={isSidebar ? '95%' : '100%'}
      alignItems={'stretch'}
      gap={'0.5rem'}
      maxW={maxW}
    >
      <OrdersList
        orders={orders}
        pageNo={searchReqData.pageNo}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
        onOrderDetailsClick={onOrderDetailsClick}
        show={'sourceAccount'}
        isSidebar={isSidebar}
      />
    </VStack>
  );
};

export default UserTransfers;

import React, { useEffect, useState } from 'react';
import useIsMobile from '../../hooks/useIsMobile';
import ContentLayout from '../../layouts/ContentLayout';
import {
  Divider,
  Grid,
  HStack,
  VStack,
  Box,
  Select,
  useDisclosure,
  Button,
  Tab,
  Tabs,
  TabList
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllTickets, updateTicket } from '../../store/actions/ticketActions';
import {
  CARD_TYPE,
  ROLES,
  TICKET_STATUS,
  TICKET_TYPES,
  TICKET_TYPE_TO_INDEX_MAPPER,
  INDEX_TO_TICKET_TYPE_MAPPER
} from '../../constants';
import GenericCard from '../../components/GenericCard';
import { getAllCxUsers } from '../../store/actions/cxUsersActions';
import Pagination from '../../components/Pagination';
import { updateTicketsReqData } from '../../store/actions/reqDataActions';
import ModalLayout from '../../components/Modal/ModalLayout';
import TicketModal from '../../components/Modal/TicketModal';
import useAuthRole from '../../hooks/useAuthRole';
import { FaAngleRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { IoTicket } from 'react-icons/io5';
import ReferenceDetails from './ReferenceDetails';
import JsonViewer from '../../components/Modal/JsonViewer';
import FullPageSpinner from '../../components/FullPageSpinner';
import { kebabCaseToSpaceSeperate } from '../../utils';

const Tickets = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { hasAnyAuthority } = useAuthRole();

  const {
    data: ticketsData,
    isLoading
    // totalPages
  } = useSelector((state) => state?.tickets);
  const { data: cxUsers } = useSelector((state) => state?.cxUsers);
  const dispatch = useDispatch();

  const [selectedTicketsType, setSelectedTicketsType] = useState(
    TICKET_TYPES.PENDING_ORDER
  );

  const [selectedTicket, setSelectedTicket] = useState(null);
  const {
    isOpen: isTicketModalOpen,
    onOpen: onTicketModalOpen,
    onClose: onTicketModalClose
  } = useDisclosure();

  const {
    isOpen: isNotePreviewModalOpen,
    onOpen: onNotePreviewModalOpen,
    onClose: onNotePreviewModalClose
  } = useDisclosure();

  /* request params for reqdata reducer */
  const { pageSize, pageNo, query, assignedTo, status } = useSelector(
    (state) => state?.reqData?.tickets
  );

  const handlePageChange = (pageNo) => {
    pageNo - 1 !== pageNo &&
      dispatch(
        updateTicketsReqData({
          pageNo: pageNo - 1
        })
      );
  };

  const handleStatusChange = (value) => {
    value !== status &&
      dispatch(
        updateTicketsReqData({
          status: value
        })
      );
  };

  const handleAssignedToChange = (value) => {
    value !== assignedTo &&
      dispatch(
        updateTicketsReqData({
          assignedTo: value
        })
      );
  };

  const transformTickets = (tickets) =>
    tickets.map((ticket) => ({
      ...ticket,
      assignedTo: cxUsers[ticket?.assignedTo]
    }));

  const handleUpdateTicketClick = (ticket) => {
    if (!ticket?.ticketId) return;
    setSelectedTicket({ ...ticket });
    onTicketModalOpen();
  };

  const [notesContent, setNotesContent] = useState('');

  const handleNotePreviewClick = (value) => {
    setNotesContent(value);
    onNotePreviewModalOpen();
  };

  //todo: use a factory to call appropiate method based on ticket type
  //todo: show sidebar on laptop screens instead of just navigating
  const handleReferenceDetailsClick = (ticket, isMobile) => {
    if (!ticket?.ticketId) return;
    setSelectedTicket({ ...ticket });
    switch (ticket?.ticketType) {
      case TICKET_TYPES.PENDING_ORDER:
        if (isMobile) return navigate(`/order/${ticket?.referenceId}`);
        return;
      case TICKET_TYPES.NR_ONBOARD:
        return navigate(`/onboarding/${ticket?.referenceId}`);
      default:
        console.log('Unknown ticket type!');
        return null;
    }
  };

  const handleTicketModalClose = () => {
    setSelectedTicket(null);
    onTicketModalClose();
  };

  useEffect(() => {
    Object.keys(TICKET_TYPES).map((tt) => {
      dispatch(
        getAllTickets({
          ticketType: tt,
          status,
          assignedTo,
          search: query,
          pageNo,
          pageSize
        })
      );
    });
    !Object.keys(cxUsers)?.length && dispatch(getAllCxUsers());
  }, []);

  useEffect(() => {
    dispatch(
      getAllTickets({
        ticketType: selectedTicketsType,
        status,
        assignedTo,
        search: query,
        pageNo,
        pageSize
      })
    );
  }, [assignedTo, status, query, pageNo]);

  if (isLoading) return <FullPageSpinner />;

  const tickets = ticketsData[selectedTicketsType]?.tickets || [];
  const totalPages = ticketsData[selectedTicketsType]?.totalPages || 0;

  return (
    <>
      <ModalLayout
        isOpen={isNotePreviewModalOpen}
        onClose={() => {
          onNotePreviewModalClose();
        }}
      >
        <JsonViewer title={'Notes'} jsonString={notesContent} />
      </ModalLayout>
      <ModalLayout isOpen={isTicketModalOpen} onClose={handleTicketModalClose}>
        <TicketModal
          ticketType={selectedTicketsType}
          assignedTo={selectedTicket?.assignedTo?.userId}
          referenceId={selectedTicket?.referenceId}
          notes={selectedTicket?.notes}
          status={selectedTicket?.status}
          ticketId={selectedTicket?.ticketId}
          onClose={handleTicketModalClose}
        />
      </ModalLayout>
      <ContentLayout>
        <Grid gridTemplateColumns={{ base: '1fr', lg: '7fr 0fr 2fr' }}>
          <VStack
            gap={4}
            overflowY={{ base: 'initial', lg: 'scroll' }}
            overflowX={'hidden'}
            h={{ lg: '90vh' }}
            py={10}
            px={{ base: 4, sm: 10 }}
            alignItems={'stretch'}
            className="scroll"
          >
            <Tabs
              variant={'enclosed'}
              size={'md'}
              onChange={(e) => {
                setSelectedTicketsType(INDEX_TO_TICKET_TYPE_MAPPER[e]);
              }}
              defaultIndex={TICKET_TYPE_TO_INDEX_MAPPER[selectedTicketsType]}
            >
              <TabList color={'white'}>
                {Object.keys(TICKET_TYPES).map((ticketType) => (
                  <Tab
                    key={TICKET_TYPE_TO_INDEX_MAPPER[ticketType]}
                    _selected={{
                      color: 'brand.500',
                      border: '1px solid white',
                      borderBottom: '1px solid black'
                    }}
                  >
                    {kebabCaseToSpaceSeperate(ticketType)}
                  </Tab>
                ))}
              </TabList>
            </Tabs>
            <HStack justifyContent={'space-between'} gap={4}>
              <Box>
                <HStack gap={4}>
                  <Box maxW={'15rem'}>
                    <Select
                      placeholder="Filter by assigned to "
                      value={assignedTo}
                      name={'assignedTo'}
                      onChange={(e) => handleAssignedToChange(e?.target?.value)}
                      bg="black"
                      color="gray"
                      size={'sm'}
                      rounded={'lg'}
                      _focus={{
                        border: '1px solid #81EBAB',
                        boxShadow: 'none'
                      }}
                    >
                      {Object.values(cxUsers).map((cxUser) => (
                        <option key={cxUser?.userId} value={cxUser?.userId}>
                          {cxUser?.name}
                        </option>
                      ))}
                    </Select>
                  </Box>
                  <Box maxW={'15rem'}>
                    <Select
                      placeholder="Filter by status"
                      name={'status'}
                      bg="black"
                      color="gray"
                      size={'sm'}
                      rounded={'lg'}
                      _focus={{
                        border: '1px solid #81EBAB',
                        boxShadow: 'none'
                      }}
                      onChange={(e) => handleStatusChange(e?.target?.value)}
                    >
                      {Object.keys(TICKET_STATUS).map((ticketStatus) => (
                        <option key={ticketStatus} value={ticketStatus}>
                          {ticketStatus}
                        </option>
                      ))}
                    </Select>
                  </Box>
                </HStack>
              </Box>
            </HStack>
            <Divider style={{ margin: '0' }} borderColor="whiteAlpha.300" />
            <Box>
              <Box py={'1rem'}>
                <Pagination
                  totalPages={totalPages}
                  currentPage={pageNo + 1}
                  onPageChange={handlePageChange}
                />
              </Box>
              <VStack gap={1} alignItems={'stretch'}>
                {tickets?.length > 0 &&
                  transformTickets(tickets)?.map((ticket) => (
                    <GenericCard
                      cardType={CARD_TYPE.OPS_TICKET_CARD}
                      cardData={ticket}
                      key={ticket?.ticketId}
                      onNotesPreviewClick={() => {
                        handleNotePreviewClick(ticket?.notes);
                      }}
                      ActionButtons={[
                        hasAnyAuthority(ROLES.OPS_MANAGER_ROLE) ? (
                          <Button
                            size={'sm'}
                            rounded={'lg'}
                            alignSelf={'center'}
                            rightIcon={<IoTicket />}
                            onClick={() => handleUpdateTicketClick(ticket)}
                          >
                            Update Ticket
                          </Button>
                        ) : null,
                        <Button
                          rightIcon={<FaAngleRight />}
                          size={'sm'}
                          rounded={'lg'}
                          alignSelf={'center'}
                          onClick={() =>
                            handleReferenceDetailsClick(ticket, isMobile)
                          }
                        >
                          {selectedTicketsType === TICKET_TYPES.PENDING_ORDER
                            ? 'Order Details'
                            : 'User Details'}
                        </Button>
                      ].filter((actionButton) => actionButton !== null)}
                    />
                  ))}
              </VStack>
            </Box>
          </VStack>
          <Divider
            style={{ margin: '0' }}
            borderColor="whiteAlpha.300"
            orientation="vertical"
          />
          {!isMobile && (
            <Box m={0} alignSelf={'flex-start'}>
              {selectedTicket?.referenceId && (
                <ReferenceDetails
                  ticketType={selectedTicketsType}
                  referenceId={selectedTicket?.referenceId}
                />
              )}
            </Box>
          )}
        </Grid>
      </ContentLayout>
    </>
  );
};

export default Tickets;

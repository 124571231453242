const NR_ON_BOARD = {
  GETTING_ON_BOARD_DETAILS: 'GETTING_ON_BOARD_DETAILS',
  GET_ON_BOARD_DETAILS_SUCCESS: 'GET_ON_BOARD_DETAILS_SUCCESS',
  GET_ON_BOARD_DETAILS_FAIL: 'GET_ON_BOARD_DETAILS_FAIL',
  FAIL_DOCS_DELIVERY: 'FAIL_DOCS_DELIVERY',
  FAIL_DOCS_PICKUP: 'FAIL_DOCS_PICKUP',
  GETTING_USER_DOCS: 'GETTING_NR_ONBOARD_USER_DOCS',
  GET_USER_DOCS_SUCCESS: 'GET_NR_ONBOARD_USER_DOCS_SUCCESS',
  GET_USER_DOCS_FAIL: 'GET_NR_ONBOARD_USER_DOCS_FAIL',
  GETTING_FINANCIAL_WORKFLOW_DETAILS: 'GETTING_FINANCIAL_WORKFLOW_DETAILS',
  GET_FINANCIAL_WORKFLOW_DETAILS_SUCCESS:
    'GET_FINANCIAL_WORKFLOW_DETAILS_SUCCESS',
  GET_FINANCIAL_WORKFLOW_DETAILS_FAILURE:
    'GET_FINANCIAL_WORKFLOW_DETAILS_FAILURE',
  GETTING_OCR_DETAILS: 'GETTING_OCR_DETAILS',
  GET_OCR_DETAILS_SUCCESS: 'GET_OCR_DETAILS_SUCCESS',
  GET_OCR_DETAILS_FAIL: 'GET_OCR_DETAILS_FAIL'
};

export default NR_ON_BOARD;

import { doesExist, formatDate } from '../../utils';

export const getInvestmentOrderDetailsTable = (orderData) => [
  {
    title: 'Investment Order:',
    data: [
      {
        name: 'Order Id:',
        value: orderData?.orderId || '-',
        isCopyable: true
      },
      {
        name: 'Order type:',
        value: orderData?.orderType || '-'
      },
      {
        name: 'Payment method:',
        value: orderData?.isSip ? 'SIP' : 'LUMP'
      },
      {
        name: 'Amount:',
        value:
          doesExist(orderData?.currency) && doesExist(orderData?.currency)
            ? `${orderData?.currency} ${orderData?.amount}`
            : '-'
      },

      {
        name: 'Status:',
        value: orderData?.status || '-'
      },
      {
        name: 'Previous status:',
        value: orderData?.previousStatus || '-'
      },
      {
        name: 'Sip ID:',
        value: orderData?.sipId || '-',
        isCopyable: orderData?.isSip
      },
      {
        name: 'Created at:',
        value: formatDate(orderData?.createdAt) || '-'
      },
      {
        name: 'Updated at:',
        value: formatDate(orderData?.updatedAt) || '-'
      }
    ]
  },

  {
    title: 'Order Payments',
    data: getOrderPaymentDetailsTable(orderData?.payments?.[0] || null)
  },
  ...orderData?.payments?.slice(1).map((payment) => ({
    title: null,
    defaultHidden: true,
    detailType: 'payments',
    data: getOrderPaymentDetailsTable(payment)
  })),

  {
    title: 'Fund Orders',
    data: getFundOrderDetailsTable(orderData?.fundOrders?.[0] || null)
  },
  ...orderData?.fundOrders?.slice(1).map((fundOrder) => ({
    title: null,
    defaultHidden: true,
    detailType: 'fundOrders',
    data: getFundOrderDetailsTable(fundOrder)
  }))
];

const getOrderPaymentDetailsTable = (payment) => [
  {
    name: 'Payment Id:',
    value: payment?.paymentId || '-',
    isCopyable: true
  },
  {
    name: 'Payment status:',
    value: payment?.status || '-'
  },
  {
    name: 'Previous status:',
    value: payment?.previousStatus || '-'
  },
  {
    name: 'Amount:',
    value:
      doesExist(payment?.amount) && doesExist(payment?.currency)
        ? `${payment.currency} ${payment.amount}`
        : '-'
  },
  {
    name: 'Created at:',
    value: formatDate(payment?.createdAt) || '-'
  },
  {
    name: 'Updated at:',
    value: formatDate(payment?.updatedAt) || '-'
  }
];

const getFundOrderDetailsTable = (fundOrder) => [
  {
    name: 'Fund Order Id:',
    value: fundOrder?.fundOrderId || '-',
    isCopyable: true
  },
  {
    name: 'Tarrakki Order Id:',
    value: fundOrder.tarrakkiOrderId || '-',
    isCopyable: true
  },
  {
    name: 'Fund Id:',
    value: fundOrder?.fundId || '-',
    isCopyable: true
  },
  {
    name: 'Fund Name:',
    value: fundOrder?.fundName || '-'
  },
  {
    name: 'Amount:',
    value:
      doesExist(fundOrder?.amount) && doesExist(fundOrder?.currency)
        ? `${fundOrder.currency} ${fundOrder.amount}`
        : '-'
  },
  {
    name: 'Units:',
    value: doesExist(fundOrder?.units) ? fundOrder?.units : '-'
  },
  {
    name: 'amc:',
    value: fundOrder?.amc || '-'
  },
  {
    name: 'Withdraw all:',
    value: doesExist(fundOrder?.isWithdrawFullAmount)
      ? fundOrder.isWithdrawFullAmount.toString()
      : '-'
  },
  {
    name: 'Created at:',
    value: formatDate(fundOrder?.createdAt) || '-'
  },
  {
    name: 'Updated at:',
    value: formatDate(fundOrder?.updatedAt) || '-'
  }
];

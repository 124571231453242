const WORKFLOW = {
  GETTING_USER_DOCS: 'GETTING_USER_DOCS',
  GET_USER_DOCS_SUCCESS: 'GET_USER_DOCS_SUCCESS',
  GET_USER_DOCS_FAILURE: 'GETTING_USER_DOCS_FAILURE',
  CLEAR_USER_DOCS: 'CLEAR_USER_DOCS',
  GETTING_WORKFLOW_SCREENS_DATA: 'GETTING_WORKFLOW_SCREENS_DATA',
  GET_WORKFLOW_SCREENS_DATA_SUCCESS: 'GET_WORKFLOW_SCREENS_DATA_SUCCESS',
  GET_WORKFLOW_SCREENS_DATA_FAILURE: 'GET_WORKFLOW_SCREENS_DATA_FAILURE'
};

export default WORKFLOW;

import { Flex, IconButton, ListItem, UnorderedList } from '@chakra-ui/react';
import React from 'react';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';
import { DOTS, usePagination } from './usePagination';

const Pagination = ({
  totalPages,
  currentPage,
  onPageChange,
  siblingCount = 1
}) => {
  const paginationRange = usePagination({
    currentPage,
    totalPages,
    siblingCount
  });

  if ((currentPage === 0, paginationRange?.length < 2)) return null;

  return (
    <Flex bg="black">
      <IconButton
        colorScheme={'shadedGray'}
        variant="outline"
        icon={<AiOutlineArrowLeft />}
        onClick={() => {
          onPageChange(currentPage - 1);
        }}
        disabled={currentPage === 1}
      />
      <UnorderedList
        display="flex"
        color="white"
        style={{ listStyle: 'none', margin: '0 1rem' }}
      >
        {paginationRange.map((pageNumber, index) => {
          if (pageNumber === DOTS)
            return (
              <ListItem key={index} style={{ padding: '0.5rem' }}>
                &#8230;
              </ListItem>
            );
          else
            return (
              <ListItem
                key={index}
                style={{ padding: '0.5rem', cursor: 'pointer' }}
                onClick={() => onPageChange(pageNumber)}
                color={pageNumber === currentPage ? 'white' : 'cardColor.500'}
              >
                {pageNumber}
              </ListItem>
            );
        })}
      </UnorderedList>
      <IconButton
        colorScheme={'shadedGray'}
        variant="outline"
        icon={<AiOutlineArrowRight />}
        onClick={() => {
          onPageChange(currentPage + 1);
        }}
        disabled={currentPage === totalPages}
      />
    </Flex>
  );
};

export default Pagination;

import api from '../../api';
import { ALERT, NR_ON_BOARD } from '../constants';
import { setAlert } from './alertActions';
import { NROB_PICKUP_STATUS, NROB_APPOINTMENT_STATUS } from '../../constants';
import { convertSlots } from '../reducers/utils';

export const getNrOnboardDetails = (userId) => async (dispatch) => {
  try {
    dispatch({
      type: NR_ON_BOARD.GETTING_ON_BOARD_DETAILS
    });

    const { data } = await api.nronboard.getOnboardDetails(userId);

    dispatch({
      type: NR_ON_BOARD.GET_ON_BOARD_DETAILS_SUCCESS,
      payload: {
        ...data
      }
    });
  } catch (error) {
    console.error(error.message);
    dispatch({
      type: NR_ON_BOARD.GET_ON_BOARD_DETAILS_FAIL,
      payload: {
        errMsg: error?.message
      }
    });
    dispatch({
      type: ALERT.SET_ALERT,
      payload: {
        title: error?.message,
        status: 'error'
      }
    });
  }
};

export const getOnboardUserDocs =
  ({ userId, screenId, onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      const { data } = await api.workflowsV2.getScreenData({
        userId,
        screenId
      });
      const { isBankStatementValidProof, selectedAddressProof } = data;
      onSuccess &&
        onSuccess({
          verified: isBankStatementValidProof === 'YES',
          selectedAddressProof:
            isBankStatementValidProof === 'YES'
              ? 'BANK_STATEMENT'
              : selectedAddressProof
        });
    } catch (error) {
      console.error(error.message);
      // dispatch(
      //   setAlert({
      //     title: error?.message,
      //     status: 'error'
      //   })
      // );
    } finally {
      onComplete && onComplete();
    }
  };

export const getOnboardUserDocsV2 =
  ({
    userId,
    requiredDocs,
    country,
    modifyStore = true,
    onSuccess,
    onComplete
  }) =>
  async (dispatch) => {
    try {
      modifyStore &&
        dispatch({
          type: NR_ON_BOARD.GETTING_USER_DOCS
        });
      const { data } = await api.workflowsV2.getUserDocs({
        userId,
        subDocuments: requiredDocs
      });
      modifyStore &&
        dispatch({
          type: NR_ON_BOARD.GET_USER_DOCS_SUCCESS,
          payload: {
            ...data,
            country
          }
        });
      onSuccess && onSuccess(data);
    } catch (error) {
      console.error(error.message);
      modifyStore &&
        dispatch({
          type: NR_ON_BOARD.GET_USER_DOCS_FAIL,
          payload: {
            errMsg: error?.message
          }
        });
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const schedulePickup =
  ({ userId, slot, onComplete }) =>
  async (dispatch) => {
    try {
      await api.nronboard.schedulePickupSlot(userId, slot);
      dispatch(getNrOnboardDetails(userId));
    } catch (err) {
      console.error(err?.message);
      dispatch(
        setAlert({
          title: err?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const updatePickupStatus =
  ({ userId, status, onComplete }) =>
  async (dispatch) => {
    try {
      await api.nronboard.updatePickupStatus(userId, status);
      dispatch(getNrOnboardDetails(userId));
    } catch (err) {
      console.error(err?.message);
      dispatch(
        setAlert({
          title: err?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };
export const rejectPickup =
  ({ userId, onComplete }) =>
  async (dispatch) => {
    try {
      await api.nronboard.updatePickupStatus(
        userId,
        NROB_PICKUP_STATUS.SIGNED_DOCS_PICKUP_UNAVAILABLE
      );
      dispatch(getNrOnboardDetails(userId));
    } catch (err) {
      console.error(err?.message);
      setAlert({
        title: err?.message,
        status: 'error'
      });
    } finally {
      onComplete && onComplete();
    }
  };

export const scheduleAppointment =
  ({ userId, slot, onComplete }) =>
  async (dispatch) => {
    try {
      await api.nronboard.scheduleAppointment(userId, slot);
      dispatch(getNrOnboardDetails(userId));
    } catch (err) {
      console.error(err?.message);
      dispatch(
        setAlert({
          title: err?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const updateAppointmentStatus =
  ({ userId, status, onComplete }) =>
  async (dispatch) => {
    try {
      await api.nronboard.updateAppointmentStatus(userId, status);
      dispatch(getNrOnboardDetails(userId));
    } catch (err) {
      console.error(err?.message);
      dispatch(
        setAlert({
          title: err?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const rejectAppointment =
  ({ userId, onComplete }) =>
  async (dispatch) => {
    try {
      await api.nronboard.updateAppointmentStatus(
        userId,
        NROB_APPOINTMENT_STATUS.APPOINTMENT_UNAVAILABLE
      );
      dispatch(getNrOnboardDetails(userId));
    } catch (err) {
      console.error(err?.message);
      dispatch(
        setAlert({
          title: err?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const scheduleDocsDelivery =
  ({ userId, startTime, onComplete }) =>
  async (dispatch) => {
    try {
      await api.nronboard.scheduleDocsDelivery(userId, {
        startTime,
        endTime: startTime
      });
      dispatch(getNrOnboardDetails(userId));
    } catch (err) {
      console.error(err?.message);
      dispatch(
        setAlert({
          title: err?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const updateDocsDeliveryStatus =
  ({ userId, status, onComplete }) =>
  async (dispatch) => {
    try {
      await api.nronboard.updateDocsDeliveryStatus(userId, status);
      dispatch(getNrOnboardDetails(userId));
    } catch (err) {
      console.error(err?.message);
      dispatch(
        setAlert({
          title: err?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const rejectUserDocument =
  ({ userId, docType, rejectReason, screenId, onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      await api.nronboard.rejectApplication({
        docType,
        rejectReason,
        userId,
        screenId
      });
      onSuccess && onSuccess();
    } catch (err) {
      console.error(err?.message);
      dispatch(
        setAlert({
          title: err?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const rejectUserDocumentsV2 =
  ({
    userId,
    workflowType,
    rejectReason,
    rejectedDocuments,
    onSuccess,
    onComplete,
    remarks = ''
  }) =>
  async (dispatch) => {
    try {
      const payload = {
        workflowType,
        rejectionReason: rejectReason,
        rejectedDocuments,
        remarks
      };
      await api.nronboard.rejectApplicationV2({
        userId,
        payload
      });
      onSuccess && onSuccess();
    } catch (error) {
      console.error(error?.message);
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
      onComplete && onComplete();
    }
  };

export const getAvailablePickupSlots =
  ({ userId, onSuccess }) =>
  async (dispatch) => {
    try {
      const { data } = await api.nronboard.getAvailablePickupSlots(userId);
      const { availableSlots } = data;
      onSuccess && onSuccess(convertSlots(availableSlots));
    } catch (err) {
      console.error(err?.message);
      dispatch(
        setAlert({
          title: err?.message,
          status: 'error'
        })
      );
    }
  };

export const verifyApplication =
  ({ userId, onSuccess = null, onComplete }) =>
  async (dispatch) => {
    try {
      await api.nronboard.verifyApplication(userId);
      onSuccess && onSuccess();
    } catch (error) {
      console.error(error?.message);
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const retriggerYblApis =
  ({ userId, onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      await api.nronboard.retriggerYblCalls(userId);
      onSuccess && onSuccess();
    } catch (error) {
      console.error(error?.message);
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const getFinancialWorkflowDetails =
  ({ userId, workflowType }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: NR_ON_BOARD.GETTING_FINANCIAL_WORKFLOW_DETAILS,
        payload: {}
      });
      const { data } = await api.workflowsV2.getScreens({
        userId,
        workflowType
      });
      const { screenModels } = data;
      dispatch({
        type: NR_ON_BOARD.GET_FINANCIAL_WORKFLOW_DETAILS_SUCCESS,
        payload: screenModels
      });
    } catch (error) {
      console.error(error?.message);
      dispatch({
        type: NR_ON_BOARD.GET_FINANCIAL_WORKFLOW_DETAILS_FAILURE,
        payload: {
          errMsg: error?.message
        }
      });
    }
  };

export const getOcrDetails =
  ({ userId, isNronboarding = true, onSuccess = null, onComplete = null }) =>
  async (dispatch) => {
    try {
      isNronboarding &&
        dispatch({
          type: NR_ON_BOARD.GETTING_OCR_DETAILS,
          payload: {}
        });

      const { data } = await api.workflowsV2.getOcrDetails(userId);
      const { ocrData } = data;
      isNronboarding &&
        dispatch({
          type: NR_ON_BOARD.GET_OCR_DETAILS_SUCCESS,
          payload: ocrData
        });
      onSuccess && onSuccess(data);
    } catch (error) {
      console.error(error?.message);
      isNronboarding &&
        dispatch({
          type: NR_ON_BOARD.GET_OCR_DETAILS_FAIL,
          payload: {
            errMsg: error?.message
          }
        });
    } finally {
      onComplete && onComplete();
    }
  };

export const getOcrAddress =
  ({ userId, onSuccess = () => {}, onError = () => {} }) =>
  async (dispatch) => {
    try {
      const { data } = await api.nronboard.getOcrAddress(userId);
      onSuccess && onSuccess(data);
    } catch (error) {
      console.error(error?.message);
      onError && onError();
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    }
  };

export const updateAddress =
  ({ userId, payload, onSuccess, onError, onComplete }) =>
  async (dispatch) => {
    try {
      const { data } = await api.nronboard.updateAddress({
        userId,
        payload
      });
      onSuccess && onSuccess();
    } catch (error) {
      console.error(error.message);
      dispatch(
        setAlert({
          title: error.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const updateDocsToYblStatus =
  ({ userId, status, onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      await api.nronboard.updateDocstoYblStatus({
        userId,
        status
      });
      onSuccess && onSuccess();
    } catch (error) {
      console.error(error?.message);
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const getDhlReports =
  ({ onComplete, onSuccess, onError }) =>
  async (dispatch) => {
    try {
      const { data } = await api.nronboard.getDhlReports({
        page_no: 0,
        page_size: 100,
        query: null,
        filters: [
          {
            filter_key: 'COUNTRY',
            search_operation: 'EQUALS',
            values: ['GB']
          }
        ]
      });
      onSuccess && onSuccess(data);
    } catch (error) {
      onError && onError(error?.message);
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const renotarizeUserDocument =
  ({ userId, docType, onComplete, onSuccess, onError }) =>
  async (dispatch) => {
    try {
      await api.nronboard.renotarizeDocument({ userId, docType });
      onSuccess && onSuccess();
    } catch (error) {
      console.error(error?.message);
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
      onError && onError();
    } finally {
      onComplete && onComplete();
    }
  };

export const reinitiateUser =
  ({ userId, onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      await api.nronboard.reinitiate({ userId });
      onSuccess && onSuccess();
    } catch (error) {
      console.error(error?.message);
      dispatch(
        setAlert({
          title: error.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const syncApplicationForm =
  ({ userId, onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      await api.nronboard.syncApplication({ userId });
      onSuccess && onSuccess();
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const updateAttestedDocsStatus =
  ({ userId, status, onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      await api.nronboard.updateAttestedDocsStatus({ userId, status });
      onSuccess && onSuccess();
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const getTicketDetails =
  ({ ticketType, userId, onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      const { data } = await api.tickets.getTicket({
        ticketType,
        referenceId: userId
      });
      onSuccess && onSuccess(data);
    } catch (error) {
      console.error(error?.message);
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const verifyDocs =
  ({ userId, remarks, onSuccess, onComplete, onError }) =>
  async (dispatch) => {
    try {
      await api.nronboard.verifyDocs({
        userId,
        payload: {
          remarks
        }
      });
      onSuccess && onSuccess();
    } catch (error) {
      console.error(error?.message);
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

import { NROB_ACTIVE_STEPS, NROB_STATES } from '../../constants';

export function getSubStatesFromActiveState(activeStep) {
  switch (activeStep) {
    case NROB_ACTIVE_STEPS.APPLICATION_PROCESSING:
      return NROB_STATES;
    default:
      return null;
  }
}

export function getActiveStepDisplayValue(val) {
  switch (val) {
    case NROB_ACTIVE_STEPS.ELIGIBLE:
      return 'Filling Form';
    case NROB_ACTIVE_STEPS.APPLICATION_PROCESSING:
      return 'Form under Review';
    case NROB_ACTIVE_STEPS.SIM_VERIFICATION_AND_PERMISSIONS:
      return 'Sim Verification';
    case NROB_ACTIVE_STEPS.ACCOUNT_INITIAL_FUNDING:
      return 'IP funding';
    case NROB_ACTIVE_STEPS.DOCUMENTS_PENDING:
      return 'Docs Pending';
    case NROB_ACTIVE_STEPS.ONBOARDING_COMPLETED:
      return 'Completed';
    default:
      return val;
  }
}

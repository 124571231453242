import {
  Box,
  Button,
  FormLabel,
  HStack,
  Input,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Select,
  VStack
} from '@chakra-ui/react';
import { useState } from 'react';

const RewardsConfirmOrInvalidateModal = ({ isLoading, onSubmit, onCancel }) => {
  const [action, setAction] = useState('');

  const handleSubmit = (e) => {
    e?.preventDefault();
    onSubmit && onSubmit({ action });
  };

  return (
    <>
      <ModalHeader>Reward Actions</ModalHeader>
      <form method="POST" onSubmit={handleSubmit}>
        <ModalBody>
          <VStack width={'full'} alignItems={'stretch'}>
            <Box>
              <FormLabel>Action</FormLabel>
              <Select
                placeholder="Select an action"
                value={action}
                onChange={(e) => {
                  setAction(e?.target?.value);
                }}
              >
                <option value={'CONFIRM'}>Confirm</option>
                <option value={'INVALIDATE'}>Invalidate</option>
              </Select>
            </Box>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <HStack>
            <Button
              type="button"
              isDisabled={isLoading}
              variant={'outline'}
              colorScheme="red"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant={'outline'}
              colorScheme="brand"
              isLoading={isLoading}
            >
              Submit
            </Button>
          </HStack>
        </ModalFooter>
      </form>
    </>
  );
};

export default RewardsConfirmOrInvalidateModal;

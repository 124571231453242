import {
  Heading,
  VStack,
  Flex,
  Box,
  Grid,
  Divider,
  Spinner,
  Select
} from '@chakra-ui/react';
import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../../components/Pagination';
import ContentLayout from '../../layouts/ContentLayout';
import { updateSourceAccountReqData } from '../../store/actions/reqDataActions';
import useDidMount from '../../hooks/useDidMount';
import FullPageSpinner from '../../components/FullPageSpinner';
import ThuneOrderCard from '../../components/ThunesCard/ThunesOrderCard';
import { useNavigate } from 'react-router-dom';
import { getSourceAccounts } from '../../store/actions/sourceAccountActions';
import SourceAccountCard from '../../components/SourceAccountCard';
import { SOURCE_ACCOUNTS_STATUS } from '../../constants';

const SourceAccount = () => {
  const dispatch = useDispatch();

  const {
    isLoading,
    data: accounts,
    totalPages
  } = useSelector((state) => state.sourceAccount.accounts);

  const searchReqData = useSelector(
    (state) => state.reqData.sourceAccount.accounts
  );

  useDidMount(() => {
    triggerGetSourceAccounts();
  }, [searchReqData.pageNo, searchReqData.status]);

  useEffect(() => {
    if (!accounts?.length) triggerGetSourceAccounts();
  }, []);

  const triggerGetSourceAccounts = () => {
    dispatch(getSourceAccounts(searchReqData));
  };

  const handlePageChange = (pageNo) => {
    pageNo - 1 !== searchReqData.pageNo &&
      dispatch(updateSourceAccountReqData({ pageNo: pageNo - 1 }));
  };

  const handleStatusFilterChange = useCallback(
    (e) => {
      searchReqData.status !== e.target.value &&
        dispatch(updateSourceAccountReqData({ status: e.target.value, pageNo: 0 }));
    },
    [dispatch, searchReqData.status]
  );

  return (
    <ContentLayout>
      <Grid gridTemplateColumns={{ base: '1fr', lg: '7fr 0fr 2fr' }}>
        <VStack
          p={10}
          gap={4}
          overflowY={{ base: 'initial', lg: 'scroll' }}
          overflowX={'hidden'}
          h={{ lg: '90vh' }}
          px={{ base: 4, sm: 10 }}
          alignItems={'stretch'}
          className="scroll"
        >
          <Flex
            w={'100%'}
            alignItems={'baseline'}
            justifyContent={'space-between'}
            pb={2}
          >
            <Heading color={'white'}>Leantech Source Accounts</Heading>
          </Flex>
          <Box width="100%">
            <Box maxWidth="15rem">
              <Select
                placeholder="Filter by status"
                onChange={handleStatusFilterChange}
                bg="black"
                color="gray"
                value={searchReqData.status}
                size={'sm'}
              >
                {Object.keys(SOURCE_ACCOUNTS_STATUS).map((status) => (
                  <option value={status} key={status}>
                    {status}
                  </option>
                ))}
              </Select>
            </Box>
          </Box>
          {totalPages > 0 && (
            <Box alignSelf={'start'}>
              <Pagination
                totalPages={totalPages}
                currentPage={searchReqData.pageNo + 1}
                onPageChange={handlePageChange}
              />
            </Box>
          )}
          {isLoading ? (
            <Spinner color={'brand.500'} />
          ) : (
            <VStack
              py={5}
              alignSelf={'flex-start'}
              w={{ base: '100%', lg: '56rem' }}
              maxW={'100%'}
              gap={1}
            >
              {accounts?.length > 0 &&
                accounts.map((item) => (
                  <SourceAccountCard key={item.id} accountData={item} />
                ))}
            </VStack>
          )}
        </VStack>
        <Divider
          style={{ margin: '0' }}
          borderColor="whiteAlpha.300"
          orientation="vertical"
        ></Divider>
      </Grid>
    </ContentLayout>
  );
};

export default SourceAccount;

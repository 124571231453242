import {
  Button,
  Heading,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack
} from '@chakra-ui/react';
import { tableCellStyles } from '../NrOnboard/constants';
import { FaCheck } from 'react-icons/fa';
import { camelCaseToSpaceSeparated } from '../../utils';
import { TfiReload } from 'react-icons/tfi';
import { FTNR_NOTARIZED_DOCS_LIST } from './constants';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { renotarizeUserDocument } from '../../store/actions/nronboardActions';

const FtnrDocumentTab = ({
  userId,
  title,
  documents,
  hasStatus = false,
  handleViewFileClick
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const cellStyles = tableCellStyles();
  const dispatch = useDispatch();
  const [selectedDoc, setSelectedDoc] = useState(null);
  const handleRenotarizeClick = (docType) => {
    setSelectedDoc(docType);
    setIsLoading(true);
    // selectedDoc &&
    dispatch(
      renotarizeUserDocument({
        userId,
        docType,
        onComplete: () => {
          setIsLoading(false);
          setSelectedDoc(null);
        }
      })
    );
  };
  return (
    <VStack alignItems={'stretch'} gap={1} padding={'1rem'}>
      <Heading size={'sm'} color={'white'}>
        {title}
      </Heading>
      <Table>
        <Thead>
          <Tr>
            <Th style={cellStyles}>Document</Th>
            <Th style={cellStyles}>File</Th>
            {hasStatus && <Th style={cellStyles}>Is Uploaded</Th>}
            <Th style={cellStyles}></Th>
          </Tr>
        </Thead>
        <Tbody>
          {documents.map((item) => (
            <Tr key={item?.name}>
              <Td style={cellStyles}>
                <Text color={'white'} fontSize={'md'}>
                  {camelCaseToSpaceSeparated(item?.name)}
                </Text>
              </Td>
              <Td style={cellStyles}>
                {!item?.fileUrl ? (
                  <Text color={'white'}> - </Text>
                ) : (
                  <Button
                    variant={'link'}
                    onClick={() => {
                      handleViewFileClick(item?.fileUrl);
                    }}
                  >
                    View File
                  </Button>
                )}
              </Td>
              {hasStatus && (
                <Td style={cellStyles}>
                  {!item?.status ? (
                    <Text color={'white'}>-</Text>
                  ) : (
                    <FaCheck color="green" />
                  )}
                </Td>
              )}
              <Td style={cellStyles}>
                {item?.name in FTNR_NOTARIZED_DOCS_LIST ? (
                  <Button
                    variant={'link'}
                    size={'sm'}
                    rightIcon={<TfiReload />}
                    isLoading={isLoading && selectedDoc === item?.name}
                    isDisabled={isLoading}
                    onClick={() => {
                      handleRenotarizeClick(
                        FTNR_NOTARIZED_DOCS_LIST[item?.name]
                      );
                    }}
                  >
                    Renotarize
                  </Button>
                ) : (
                  <Text color={'gray.400'}>-</Text>
                )}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </VStack>
  );
};

export default FtnrDocumentTab;

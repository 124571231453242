import {
  Box,
  Button,
  FormLabel,
  HStack,
  Input,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Select,
  VStack
} from '@chakra-ui/react';
import { useState } from 'react';
import dayjs from 'dayjs';
import { ORDER_ACQUIRER } from '../../constants';

const UpdateDowntimeModal = ({ downtime, isLoading, onCancel, onSubmit }) => {
  const initialState = !downtime
    ? {
        paymentAcquirer: '',
        downtimeStartDate: '',
        downtimeEndDate: '',
        downtimeReason: ''
      }
    : { ...downtime };
  const [modalState, setModalState] = useState(initialState);

  const handleDateChange = (e) => {
    const formatted = dayjs(e?.target.value).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    setModalState((prev) => ({
      ...prev,
      [e.target.name]: formatted
    }));
  };

  const handleCancel = () => {
    setModalState(initialState);
    onCancel && onCancel();
  };
  const handleSubmit = (e) => {
    e?.preventDefault();
    onSubmit && onSubmit(modalState);
  };

  return (
    <>
      <ModalHeader>
        {!downtime
          ? `Update Downtime - ${downtime?.paymentAcquirer}`
          : 'Create new downtime'}
      </ModalHeader>
      <form method="POST" onSubmit={handleSubmit}>
        <ModalBody>
          <VStack gap={4} alignItems={'stretch'}>
            <Box>
              <FormLabel>Payment Acquirer</FormLabel>
              <Select
                value={modalState?.paymentAcquirer}
                placeholder="Select acquirer"
                onChange={(e) => {
                  setModalState((prev) => ({
                    ...prev,
                    paymentAcquirer: e.target.value
                  }));
                }}
              >
                {Object.keys(ORDER_ACQUIRER).map((acquirer) => (
                  <option key={acquirer} value={acquirer}>
                    {acquirer}
                  </option>
                ))}
              </Select>
            </Box>
            <Box>
              <FormLabel>{'Downtime Start Date'}</FormLabel>
              <Input
                type="datetime-local"
                name="downtimeStartDate"
                value={
                  modalState?.downtimeStartDate
                    ? dayjs(modalState?.downtimeStartDate).format(
                        'YYYY-MM-DDTHH:mm'
                      )
                    : ''
                }
                onChange={handleDateChange}
              />
            </Box>
            <Box>
              <FormLabel>{'Downtime End Date'}</FormLabel>
              <Input
                type="datetime-local"
                name="downtimeEndDate"
                value={
                  modalState?.downtimeEndDate
                    ? dayjs(modalState?.downtimeEndDate).format(
                        'YYYY-MM-DDTHH:mm'
                      )
                    : ''
                }
                onChange={handleDateChange}
              />
            </Box>
            <Box>
              <FormLabel>{'Downtime Reason'}</FormLabel>
              <Input
                value={modalState?.downtimeReason}
                onChange={(e) => {
                  setModalState((prev) => ({
                    ...prev,
                    downtimeReason: e.target.value
                  }));
                }}
              />
            </Box>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <HStack gap={2} justifyContent={'end'}>
            <Button
              isDisabled={isLoading}
              type="button"
              colorScheme="red"
              variant="outline"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              isLoading={isLoading}
              type="submit"
              variant="outline"
              colorScheme="brand"
            >
              Update
            </Button>
          </HStack>
        </ModalFooter>
      </form>
    </>
  );
};

export default UpdateDowntimeModal;

import {
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  Thead,
  Text,
  Tr,
  Tbody,
  Td,
  Th
} from '@chakra-ui/react';

const cellStyles = {
  border: 'none',
  padding: '4px'
};

const FtnrErrorDetailsModal = ({ errorDump }) => {
  return (
    <>
      <ModalHeader>FTNR Error Details</ModalHeader>
      <ModalBody>
        <Table>
          <Thead>
            <Tr>
              <Th style={cellStyles}>#</Th>
              <Th style={cellStyles}>Code</Th>
              <Th style={cellStyles}>FieldName</Th>
              <Th style={cellStyles}>Error Detail</Th>
            </Tr>
          </Thead>
          <Tbody>
            {errorDump?.map((error, ind) => (
              <Tr key={error?.code}>
                <Td style={cellStyles} color={'white'} fontSize={'md'}>
                  {ind + 1}
                </Td>
                <Td style={cellStyles} color={'white'} fontSize={'md'}>
                  {error?.code}
                </Td>
                <Td style={cellStyles}>
                  <Text
                    whiteSpace={'nowrap'}
                    overflowX={'scroll'}
                    maxW={'250px'}
                    color={'white'}
                    fontSize={'md'}
                    paddingY={'12px'}
                  >
                    {error?.fieldName}
                  </Text>
                </Td>
                <Td style={cellStyles}>
                  <Text
                    whiteSpace={'nowrap'}
                    overflowX={'scroll'}
                    maxW={'250px'}
                    color={'white'}
                    fontSize={'md'}
                    paddingY={'12px'}
                  >
                    {error?.message}
                  </Text>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </ModalBody>
      <ModalFooter></ModalFooter>
    </>
  );
};

export default FtnrErrorDetailsModal;

import React, { useCallback, useEffect, useState } from 'react';
import { Button, VStack, Text } from '@chakra-ui/react';
import Logo from '../../components/Logo';
import InputComponent from '../../components/Input';
import { useDispatch, useSelector } from 'react-redux';

import AuthLayout from '../../layouts/AuthLayout';
import { login } from '../../store/actions/authActions';

const Login = () => {
  const dispatch = useDispatch();
  const { user, error } = useSelector((state) => state.auth);

  const [formData, setFormData] = useState({
    username: '',
    password: ''
  });

  const [isLoading, setIsLoading] = useState(false);

  const [formErrors, setFormErrors] = useState({
    username: '',
    password: ''
  });

  useEffect(() => {
    if (user || error) setIsLoading(false);
  }, [user, error]);

  const validateForm = useCallback(() => {
    let inFormValidated = true;
    let { username, password } = formData;
    if (username.length === 0) {
      setFormErrors((prev) => ({ ...prev, emailError: true }));
      inFormValidated = false;
    }

    if (password.length === 0) {
      setFormErrors((prev) => ({ ...prev, passwordError: true }));
      inFormValidated = false;
    }
    return inFormValidated;
  }, [formData]);

  const onLogin = useCallback(
    (e) => {
      setIsLoading(true);
      e.preventDefault();

      if (!validateForm()) {
        setIsLoading(false);
        return;
      }

      dispatch(login(formData));
    },
    [dispatch, formData, validateForm]
  );

  const handleChange = useCallback((e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));

    setFormErrors((prev) => ({
      ...prev,
      [e.target.name]: null
    }));
  }, []);

  return (
    <AuthLayout>
      <Logo justifyContent={{ base: 'center', sm: 'start' }} />
      <VStack
        as="form"
        w={{ base: '100%', sm: '500px', lg: '500px' }}
        my={8}
        mb={12}
        maxW="100%"
      >
        <Text
          fontSize="4xl"
          alignSelf={{ base: 'center', sm: 'flex-start' }}
          color="white"
        >
          Sign in
        </Text>
        <VStack
          spacing={4}
          w="100%"
          py="6"
          boxShadow="base"
          rounded="md"
          maxW="100%"
        >
          <InputComponent
            type="text"
            value={formData.name}
            name="username"
            handleChange={handleChange}
            placeholder="Username"
            error={formErrors.name}
            inputTitle="Username"
            size={{ base: 'md', sm: 'lg' }}
            autoComplete={'off'}
            spellcheck={false}
            autoCorrect={'off'}
          />

          <InputComponent
            type="password"
            value={formData.email}
            name="password"
            handleChange={handleChange}
            placeholder="Password"
            error={formErrors.email}
            inputTitle="Password"
            size={{ base: 'md', sm: 'lg' }}
          />
        </VStack>

        <Button
          type="submit"
          color="black"
          rounded="lg"
          colorScheme="brand"
          onClick={onLogin}
          fontWeight={600}
          alignSelf={{ base: 'center', sm: 'start' }}
          isLoading={isLoading}
          px={5}
        >
          Continue
        </Button>
      </VStack>
    </AuthLayout>
  );
};

export default Login;

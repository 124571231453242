import api from '../../api';
import { WORKFLOW as types } from '../constants';
import { setAlert } from './alertActions';

export const searchUserDocs =
  ({
    userId,
    pageNo = 0,
    pageSize = 10,
    fromStore = true,
    onSuccess,
    onComplete
  }) =>
  async (dispatch) => {
    try {
      fromStore &&
        dispatch({
          type: types.GETTING_USER_DOCS
        });
      const { data } = await api.workflow.userDocs({
        userId,
        pageNo,
        pageSize
      });

      fromStore &&
        dispatch({
          type: types.GET_USER_DOCS_SUCCESS,
          payload: data
        });

      !fromStore && onSuccess && onSuccess(data);
    } catch (error) {
      console.log(error);
      dispatch(
        setAlert({
          title: 'Something went wrong while fetching user docs!',
          status: 'error'
        })
      );
      fromStore &&
        dispatch({
          type: types.GET_USER_DOCS_FAILURE,
          payload: error
        });
    } finally {
      onComplete && onComplete();
    }
  };

export const clearUserDocs = () => ({
  type: types.CLEAR_USER_DOCS
});

export const getWorkflowScreensData =
  ({ workflowId, userId }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: types.GETTING_WORKFLOW_SCREENS_DATA,
        payload: {
          workflowId
        }
      });

      const { data } = await api.workflow.getWorkflowScreenData({
        userId,
        workflowId
      });

      return dispatch({
        type: types.GET_WORKFLOW_SCREENS_DATA_SUCCESS,
        payload: {
          workflowId,
          data
        }
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: types.GET_WORKFLOW_SCREENS_DATA_FAILURE,
        payload: {
          workflowId,
          data: error
        }
      });
    }
  };

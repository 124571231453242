import {
  Box,
  Button,
  Grid,
  HStack,
  Icon,
  IconButton,
  Stack,
  StackDivider,
  Text,
  VStack
} from '@chakra-ui/react';
import React from 'react';
import { MdEast } from 'react-icons/md';
import { getOrderStatusColor } from '../../utils';
import { BsFillFlagFill, BsPlusCircle } from 'react-icons/bs';
import CopyableText from '../CopyableText';

const OrderCard = ({
  onOrderDetailsClick,
  orderData,
  show,
  onFlagButtonClick,
  isSidebar = false
}) => {
  return (
    <Box position={'relative'}>
      {orderData?.metadata?.complianceFlagged ? (
        <IconButton
          position={'absolute'}
          rounded={'full'}
          top={-2}
          right={-2}
          bg={'altGray.500'}
          color={'red.500'}
          _hover={{
            background: 'red.500',
            color: 'altGray.500'
          }}
          size={'sm'}
          fontSize={'0.9rem'}
          fontWeight={400}
          icon={<BsFillFlagFill />}
          onClick={onFlagButtonClick}
        />
      ) : (
        <IconButton
          position={'absolute'}
          rounded={'full'}
          top={-2}
          right={-2}
          bg={'altGray.500'}
          color={'brand.200'}
          _hover={{
            background: 'brand.200',
            color: 'altGray.500'
          }}
          size={'sm'}
          fontSize={'0.9rem'}
          fontWeight={400}
          icon={<BsPlusCircle />}
          onClick={onFlagButtonClick}
        />
      )}
      <VStack
        bg="backGround"
        borderRadius="12px"
        divider={
          <StackDivider style={{ margin: '0' }} borderColor="whiteAlpha.300" />
        }
      >
        <Grid
          gridTemplateColumns={
            isSidebar
              ? {
                  base: 'repeat(1, auto)'
                }
              : {
                  base: 'repeat(2, auto)',
                  md: 'repeat(3, auto)'
                }
          }
          rowGap={2}
          w={'90%'}
          justifyContent="space-between"
          m={'1rem'}
        >
          <HStack>
            <Text fontSize={{ base: 'sm', sm: 'md' }} color="gray">
              Order ID:
            </Text>
            <CopyableText
              title={'Order ID'}
              fontSize={{ base: 'sm', sm: 'md' }}
              color="white"
            >
              {orderData.orderId}
            </CopyableText>
          </HStack>
          <HStack>
            <Icon
              viewBox="0 0 200 200"
              color={getOrderStatusColor(orderData.orderStatus)}
              w="8px"
            >
              <path
                fill="currentColor"
                d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
              />
            </Icon>
            <Text fontSize={{ base: 'sm', sm: 'md' }} color="white">
              {orderData.orderStatus}
            </Text>
          </HStack>
          <Stack direction={{ base: 'column', sm: 'row' }}>
            <Text fontSize={{ base: 'sm', sm: 'md' }} color="gray">
              Created on:
            </Text>
            <Text fontSize={{ base: 'sm', sm: 'md' }} color="white">
              {orderData.createdAt}
            </Text>
          </Stack>
        </Grid>
        <Grid
          gridTemplateColumns={
            isSidebar
              ? {
                  base: '1fr'
                }
              : {
                  base: '1fr',
                  md: `1fr 1fr ${show ? '1.2fr' : ''} 1.2fr auto`
                }
          }
          gap={2}
          alignItems={'flex-start'}
          w={'90%'}
          m={'1rem'}
          textAlign={{ base: 'end', md: 'start' }}
        >
          <Stack
            direction={{ base: 'row', md: 'column' }}
            justifyContent={'space-between'}
          >
            <Text fontSize={'sm'} color={'gray'}>
              Debit Amount
            </Text>
            <Text fontSize={'sm'} color={'white'}>
              {`${orderData.amount} ${orderData.currencyFrom}`}
            </Text>
          </Stack>
          <Stack
            direction={{ base: 'row', md: 'column' }}
            justifyContent={'space-between'}
          >
            <Text fontSize={'sm'} color={'gray'}>
              Credit amount
            </Text>
            <Text fontSize={'sm'} color={'white'}>
              {`${orderData.receiveAmnt} ${orderData.currencyTo}`}
            </Text>
          </Stack>
          {show && (
            <Stack
              direction={{ base: 'row', md: 'column' }}
              justifyContent={'space-between'}
            >
              <Text fontSize={'sm'} color={'gray'}>
                {show
                  .replace(/([A-Z])/g, ' $1')
                  .split(' ')
                  .map((word) => word.slice(0, 1).toUpperCase() + word.slice(1))
                  .join(' ')}
              </Text>
              <Text fontSize={'sm'} color={'white'}>
                {orderData[show] || '-'}
              </Text>
            </Stack>
          )}
          <Stack
            direction={{ base: 'row', md: 'column' }}
            justifyContent={'space-between'}
          >
            <Text fontSize={'sm'} color={'gray'}>
              Recipient
            </Text>
            <Text fontSize={'sm'} color={'white'}>
              {orderData.beneficiaryName}
            </Text>
          </Stack>
          <Button
            mt={{ base: 2, md: 0 }}
            ml={{ base: 0, md: 1 }}
            rightIcon={<MdEast color="black" />}
            type="submit"
            rounded="lg"
            color={'black'}
            colorScheme="brand"
            alignSelf={{ base: 'center', md: 'center' }}
            px={5}
            onClick={() => {
              onOrderDetailsClick(orderData.orderId);
            }}
          >
            Order details
          </Button>
        </Grid>
      </VStack>
    </Box>
  );
};

export default OrderCard;

import { AiFillMessage } from 'react-icons/ai';
import {
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Grid,
  Box
} from '@chakra-ui/react';
import React, { useState } from 'react';
import Footer from './Footer';
import Header from './Header';
import Messages from './Messages';
import api from '../../api';

const JigneshChatBot = () => {
  const [messages, setMessages] = useState([
    { from: 'server', text: 'Hi, I am Jignesh! How can I help you?' }
  ]);
  const [inputMessage, setInputMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSendMessage = async () => {
    if (!inputMessage.trim().length) {
      return;
    }

    setMessages((old) => [...old, { from: 'me', text: inputMessage }]);
    setInputMessage('');

    try {
      setIsLoading(true);
      const { data } = await api.jignesh.ask(inputMessage);

      setIsLoading(false);
      setMessages((old) => [...old, { from: 'jignesh', text: data.answer }]);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      <Popover placement={'bottom-start'}>
        <PopoverTrigger>
          <IconButton
            icon={<AiFillMessage />}
            colorScheme="shadedGray"
            position="fixed"
            top={'1rem'}
            right={'1rem'}
            size="lg"
            rounded="full"
            color="white"
            zIndex={10}
          ></IconButton>
        </PopoverTrigger>
        <PopoverContent bg="altGray.200" color="white" border={0}>
          <PopoverArrow bg="altGray.200" boxShadow={'none !important'} />
          <PopoverCloseButton />
          <PopoverBody p={0} overflow={'hidden'} height={'70vh'}>
            <Box w={'100%'} h={'100%'} px={0} pt={4} pb={3}>
              <Grid gridTemplateRows={'auto 1fr auto'} h={'100%'}>
                <Header isTyping={isLoading} />
                <Messages messages={messages} />
                <Footer
                  inputMessage={inputMessage}
                  setInputMessage={setInputMessage}
                  handleSendMessage={handleSendMessage}
                />
              </Grid>
            </Box>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </>
  );
};

export default JigneshChatBot;

export default function (fetch, baseUrl) {
  return {
    getFeatureFlagValue({ featureFlag }) {
      console.log(featureFlag);
      return fetch(`${baseUrl}/feature-flag/${featureFlag}`);
    },
    updateFeatureFlagValue(payload) {
      return fetch.put(`${baseUrl}/feature-flag`, {
        ...payload
      });
    },
    withdrawEvent(payload) {
      return fetch.post(`${baseUrl}/withdraw-amt`, { ...payload });
    }
  };
}

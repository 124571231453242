import { HStack, Heading, VStack, Text, Grid, Button } from '@chakra-ui/react';
import React, { useState } from 'react';
import api from '../../api';
import { AiOutlineFile } from 'react-icons/ai';

const OnboardingCard = ({ title, status, data }) => {
  const [isFileLoading, setIsFileLoading] = useState(false);

  const onFileClick = (url) => {
    setIsFileLoading(true);
    api.nrAccount
      .doc(url)
      .then(({ data }) => window.open(data?.url, '_blank'))
      .catch((err) => console.log(err))
      .finally(() => setIsFileLoading(false));
  };
  return (
    <VStack p={6} bg={'altGray.400'} borderRadius={'1rem'}>
      <HStack justifyContent={'space-between'} w={'100%'} pb={3}>
        <Heading size={'md'} color={'brand.400'} maxW={'85%'}>
          {title}
        </Heading>
        <Text
          color={status === 'FILLED' ? 'brand.200' : 'yellow.200'}
          fontSize={'sm'}
        >
          {status}{' '}
        </Text>
      </HStack>
      <Grid
        gap={2}
        gridTemplateColumns={'0.8fr 1fr'}
        w={'100%'}
        justifyContent={'flex-start'}
        fontSize={'0.9rem'}
      >
        {data &&
          Object.keys(data).map((key) => (
            <React.Fragment key={key}>
              <Text
                color="shadedGray.400"
                maxWidth={'20rem'}
                whiteSpace={'break-word'}
              >
                {key} :
              </Text>
              {data[key]?.isFile ? (
                <Button
                  variant={'link'}
                  textDecoration={'underline'}
                  color="white"
                  maxWidth={'20rem'}
                  whiteSpace={'break-word'}
                  rightIcon={<AiOutlineFile />}
                  onClick={() => onFileClick(data[key]?.fileUrl)}
                  justifyContent={'flex-start'}
                  fontSize={'0.9rem'}
                  fontWeight={400}
                  isLoading={isFileLoading}
                >
                  {data[key].value}
                </Button>
              ) : (
                <Text
                  color="white"
                  maxWidth={'20rem'}
                  whiteSpace={'break-word'}
                >
                  {data[key].value}
                </Text>
              )}
            </React.Fragment>
          ))}
      </Grid>
    </VStack>
  );
};

export default OnboardingCard;

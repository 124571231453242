import { USERS as types, ORDERS, KYC } from '../constants';
import { toUserDetailsData, toUserKycAddress } from '../../views/User/utils';
import { toOrderData, toOrderMetadata } from '../../utils';
import { KYC_STATUS } from '../../constants';

const initialState = {
  userDetails: {
    isLoading: false,
    isError: false,
    error: null,
    data: null,
    referralCode: {
      isLoading: false,
      isError: false,
      error: null,
      data: null
    },
    orders: {
      isLoading: false,
      isError: false,
      error: null,
      data: [],
      totalPages: 0,
      totalOrders: 0
    },
    goldenTickets: {
      isLoading: false,
      isError: false,
      error: null,
      data: null
    }
  },
  userCount: '-'
};

const usersReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SEARCHING_USER_BY_ID:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          isLoading: true,
          isError: false,
          error: null,
          data: null
        }
      };

    case types.SEARCH_USER_BY_ID_SUCCESS:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          isLoading: false,
          isError: false,
          error: null,
          data: toUserDetailsData(payload)
        }
      };

    case types.SEARCH_USER_BY_ID_FAILURE:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          isLoading: false,
          isError: true,
          error: payload,
          data: payload
        }
      };

    case types.GET_USER_COUNT_SUCCESS:
      return {
        ...state,
        userCount: payload
      };

    case types.GET_USER_COUNT_FAILURE:
      return {
        ...state,
        userCount: '-'
      };

    case types.CLEAR_USERS_LIST:
      return {
        ...state,
        usersList: {
          ...state.users,
          isLoading: false,
          isError: false,
          error: null,
          data: null
        }
      };

    case types.CLEAR_USER_DETAILS:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          isLoading: false,
          isError: false,
          error: null,
          data: null
        }
      };

    case types.GETTING_USER_ORDERS:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          orders: {
            ...state.userDetails.orders,
            isLoading: true,
            isError: false,
            error: null,
            data: [],
            totalPages: 0,
            totalOrders: 0
          }
        }
      };

    case types.GET_USER_ORDERS_SUCCESS:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          orders: {
            ...state.userDetails.orders,
            isLoading: false,
            isError: false,
            error: null,
            data: payload.data?.map((item) => toOrderData(item)) || [],
            totalPages: payload.totalPages,
            totalOrders: payload.totalResults
          }
        }
      };

    case types.GET_USER_ORDERS_FAILURE:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          orders: {
            ...state.userDetails.orders,
            isLoading: false,
            isError: true,
            error: payload,
            data: [],
            totalPages: 0,
            totalOrders: 0
          }
        }
      };

    case types.CLEAR_USER_ORDERS:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          orders: {
            ...state.userDetails.orders,
            isLoading: false,
            isError: false,
            error: null,
            data: [],
            totalPages: 0,
            totalOrders: 0
          }
        }
      };

    case types.REJECT_USER_KYC_SUCCESS:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          data: {
            ...state.userDetails.data,
            kycStatus: KYC_STATUS.REJECTED,
            kycRejectionReason: payload.note,
            kycData: state.userDetails.data?.kycData
              ? {
                  ...state.userDetails.data?.kycData,
                  kycStatus: KYC_STATUS.REJECTED,
                  kycRejectionReason: payload.note
                }
              : null
          }
        }
      };

    case types.UPDATE_USER_ADDRESS_SUCCESS:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          data: {
            ...state.userDetails.data,
            kycData: state.userDetails.data?.kycData
              ? {
                  ...state.userDetails.data.kycData,
                  address: {
                    ...state.userDetails.data.kycData.address,
                    ...toUserKycAddress(payload)
                  }
                }
              : null
          }
        }
      };

    case ORDERS.UPDATE_ORDER_METADATA_SUCCESS:
      const metadata = toOrderMetadata(payload);
      const orderId = metadata?.orderId;

      if (!state.userDetails.orders.data?.length)
        return {
          ...state
        };

      const index = state.userDetails.orders?.data?.findIndex(
        (order) => order.metadata?.orderId === orderId
      );

      if (index === -1)
        return {
          ...state
        };

      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          orders: {
            ...state.userDetails.orders,
            data: [
              ...state.userDetails.orders.data.slice(0, index),
              {
                ...state.userDetails.orders.data[index],
                metadata: {
                  ...state.userDetails.orders.data[index].metadata,
                  ...metadata
                }
              },
              ...state.userDetails.orders.data.slice(index + 1)
            ]
          }
        }
      };

    case types.GETTING_USER_REFERRAL_CODE:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          referralCode: {
            ...state.userDetails.referralCode,
            isLoading: true,
            isError: false,
            error: null,
            data: null
          }
        }
      };

    case types.GET_USER_REFERRAL_CODE_SUCCESS:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          referralCode: {
            ...state.userDetails.referralCode,
            isLoading: false,
            isError: false,
            error: null,
            data: payload
          }
        }
      };

    case types.GET_USER_REFERRAL_CODE_FAILURE:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          referralCode: {
            ...state.userDetails.referralCode,
            isLoading: false,
            isError: true,
            error: payload,
            data: payload
          }
        }
      };

    case types.CLEAR_USER_REFERRAL_CODE:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          referralCode: {
            ...state.userDetails.referralCode,
            isLoading: false,
            isError: false,
            error: null,
            data: null
          }
        }
      };

    case types.GETTING_GOLDEN_TICKET_INFO:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          goldenTickets: {
            ...state.userDetails.goldenTickets,
            isLoading: true,
            isError: false,
            error: null,
            data: null
          }
        }
      };

    case types.GET_GOLDEN_TICKET_INFO_SUCCESS:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          goldenTickets: {
            ...state.userDetails.goldenTickets,
            isLoading: false,
            isError: false,
            error: null,
            data: payload
          }
        }
      };

    case types.GET_GOLDEN_TICKET_INFO_FAILURE:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          goldenTickets: {
            ...state.userDetails.goldenTickets,
            isLoading: false,
            isError: true,
            error: payload,
            data: null
          }
        }
      };

    case types.CLEAR_GOLDEN_TICKET_INFO:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          goldenTickets: {
            ...state.userDetails.goldenTickets,
            isLoading: false,
            isError: false,
            error: null,
            data: null
          }
        }
      };

    default:
      return state;
  }
};

export default usersReducer;

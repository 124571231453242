export default function (fetch, baseUrl) {
  return {
    getTransactionsByStatus({ status, page, pageSize, search }) {
      return fetch.get(
        `${baseUrl}?status=${status}&pageNumber=${page}&pageSize=${pageSize}&orderSearchId=${search}`
      );
    },
    getStats() {
      return fetch.get(`${baseUrl}/stats`);
    }
  };
}

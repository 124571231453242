import { VStack, Text } from '@chakra-ui/react';

const DataElement = ({ title, value, children = null }) => {
  return (
    <VStack alignItems={'stretch'} width={'full'}>
      <Text color={'gray'} fontSize={'sm'}>
        {title}
      </Text>
      {!children ? (
        <Text color={'whiteAlpha.800'} fontSize={'sm'}>
          {value || '-'}
        </Text>
      ) : (
        <>{children}</>
      )}
    </VStack>
  );
};

export default DataElement;

import { MANUAL_PAYOUT_BATCH } from '../constants';

const initialState = {
  sheets: [],
  loading: false,
  error: null,
  creatingBatch: false,
};

const manualPayoutBatchReducer = (state = initialState, action) => {
  switch (action.type) {
    case MANUAL_PAYOUT_BATCH.FETCH_SHEETS_REQUEST:
    case MANUAL_PAYOUT_BATCH.DOWNLOAD_SHEET_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case MANUAL_PAYOUT_BATCH.FETCH_SHEETS_SUCCESS:
      return {
        ...state,
        loading: false,
        sheets: action.payload,
        error: null,
      };
    case MANUAL_PAYOUT_BATCH.DOWNLOAD_SHEET_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case MANUAL_PAYOUT_BATCH.FETCH_SHEETS_FAILURE:
    case MANUAL_PAYOUT_BATCH.DOWNLOAD_SHEET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case MANUAL_PAYOUT_BATCH.CREATE_BATCH_REQUEST:
      return {
        ...state,
        creatingBatch: true,
        error: null,
      };
    case MANUAL_PAYOUT_BATCH.CREATE_BATCH_SUCCESS:
      return {
        ...state,
        creatingBatch: false,
        error: null,
      };
    case MANUAL_PAYOUT_BATCH.CREATE_BATCH_FAILURE:
      return {
        ...state,
        creatingBatch: false,
        error: action.payload,
      };
      case MANUAL_PAYOUT_BATCH.UPLOAD_SHEET_REQUEST:
      return {
        ...state,
        uploadingSheet: action.payload, // Track the current uploading sheet
        loading: true,
        error: null,
      };
    case MANUAL_PAYOUT_BATCH.UPLOAD_SHEET_SUCCESS:
      return {
        ...state,
        uploadingSheet: null, // Reset when done
        loading: false,
        error: null,
      };
    case MANUAL_PAYOUT_BATCH.UPLOAD_SHEET_FAILURE:
      return {
        ...state,
        uploadingSheet: null, // Reset when done
        loading: false,
        error: action.payload,
      };
    case MANUAL_PAYOUT_BATCH.FETCH_BATCH_DETAILS_REQUEST:
      return { ...state, loading: true, batchDetails: [] };
    case MANUAL_PAYOUT_BATCH.FETCH_BATCH_DETAILS_SUCCESS:
      return { ...state, loading: false, batchDetails: action.payload };
    case MANUAL_PAYOUT_BATCH.FETCH_BATCH_DETAILS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case MANUAL_PAYOUT_BATCH.FETCH_PENDING_DETAILS_REQUEST:
      return { ...state, loading: true };
    case MANUAL_PAYOUT_BATCH.FETCH_PENDING_DETAILS_SUCCESS:
      return { ...state, loading: false, pendingSheets: action.payload };
    case MANUAL_PAYOUT_BATCH.FETCH_PENDING_DETAILS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default manualPayoutBatchReducer;
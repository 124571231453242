import api from '../../api';
import { setAlert } from './alertActions';

export const getAllVanceCashTxns =
  ({ userId, pageSize = 20, page = 0, onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      const { data } = await api.vanceCash.getAllTransactions({
        page,
        pageSize,
        userId
      });
      onSuccess && onSuccess(data);
    } catch (error) {
      dispatch(
        setAlert({
          title: error.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const creditVanceCash =
  ({ userId, amount, remarks, onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      await api.vanceCash.creditVanceCash({
        userId,
        amount,
        remarks
      });
      onSuccess && onSuccess();
    } catch (error) {
      dispatch(
        setAlert({
          title: error.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const syncVanceCashWallet =
  ({ userId, onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      const { data } = await api.vanceCash.syncVanceCashWallet({ userId });
      onSuccess && onSuccess(data);
    } catch (error) {
      dispatch(
        setAlert({
          title: error.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

import React, { useCallback, useEffect, useRef } from 'react';
import InputComponent from '../../components/Input';
import { Box, Button, Grid, HStack, Select, Stack } from '@chakra-ui/react';
import { MdSearch } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { updateInvestmentOrdersReqData } from '../../store/actions/reqDataActions';
import useDidMountEffect from '../../hooks/useDidMount';
import { useNavigate } from 'react-router-dom';
import useIsMobile from '../../hooks/useIsMobile';
import OrdersList from '../../components/OrdersList';
import { getInvestmentOrders } from '../../store/actions/investmentActions';
import { INVESTMENT_ORDER_SEARCH_FILTERS, PRODUCT } from '../../constants';

const InvestmentOrdersContent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchTimer = useRef(null);
  const isMobile = useIsMobile();

  const searchReqData = useSelector(
    (state) => state.reqData.investments.orders
  );

  const searchResult = useSelector((state) => ({
    orders: state.investments.orders.data || [],
    isLoading: state.investments.orders.isLoading,
    isError: state.investments.orders.isError,
    totalPages: state.investments.orders.totalPages
  }));

  useEffect(() => {
    if (searchResult.orders?.length) return;

    searchOrders(true);
  }, []);

  useDidMountEffect(() => {
    if (searchTimer.current) {
      clearTimeout(searchTimer.current);
      searchTimer.current = setTimeout(() => {
        searchOrders(true);
      }, 2000);
    } else {
      searchOrders(true);
      searchTimer.current = true;
    }
  }, [searchReqData.query]);

  useDidMountEffect(() => {
    searchOrders(true);
  }, [searchReqData.pageNo, searchReqData.filterValue]);

  const searchOrders = async (refresh = false) => {
    if (refresh || !searchResult.orders?.length)
      dispatch(getInvestmentOrders(searchReqData));
  };

  const handleSearchReqChange = (e) => {
    if (e.target.value === searchReqData.query) return;

    dispatch(updateInvestmentOrdersReqData({ query: e.target.value }));
  };

  const handlePageChange = useCallback(
    (pageNo) => {
      pageNo - 1 !== searchReqData.pageNo &&
        dispatch(updateInvestmentOrdersReqData({ pageNo: pageNo - 1 }));
    },
    [dispatch, searchReqData.pageNo]
  );

  const onOrderDetailsClick = useCallback(
    (orderId, userId) => {
      navigate(
        isMobile
          ? `/investments/orders/${orderId}`
          : `/user/${userId}?orderId=${orderId}&product=${PRODUCT.INVEST_MF}`
      );
    },
    [isMobile, navigate]
  );

  const handleFilterKeyChange = (e) => {
    e.target.value !== searchReqData.filterKey &&
      dispatch(
        updateInvestmentOrdersReqData({
          filterKey: e.target.value,
          filterValue: '',
          pageNo: 0
        })
      );
  };

  const handleFilterValueChange = (e) => {
    e.target.value !== searchReqData.filterValue &&
      dispatch(
        updateInvestmentOrdersReqData({
          filterValue: e.target.value,
          pageNo: 0
        })
      );
  };

  return (
    <>
      <InputComponent
        type="text"
        value={searchReqData.query}
        name="search"
        handleChange={handleSearchReqChange}
        placeholder="Search by Order ID"
        inputTitle="Search investment orders"
        leftElement={<MdSearch size="2rem" color="white" />}
        width="40rem"
        maxWidth="100%"
        autoComplete={'off'}
        spellcheck={false}
        autoCorrect={'off'}
      />

      <Box w={'100%'}>
        <Stack
          direction={{ base: 'column', sm: 'row' }}
          alignItems={'space-between'}
          justifyContent={'space-between'}
          w={'100%'}
          maxW={'40rem'}
        >
          <Button
            type="submit"
            color="black"
            rounded="lg"
            colorScheme="brand"
            my={5}
            py={2}
            onClick={() => searchOrders()}
            fontWeight={600}
            isLoading={searchResult.isLoading}
            alignSelf="start"
            style={{
              marginTop: '1em'
            }}
          >
            Search
          </Button>

          <HStack>
            <Box maxWidth="13rem" my={2}>
              <Select
                placeholder="Filter by"
                onChange={handleFilterKeyChange}
                bg="gray !important"
                color="black !important"
                value={searchReqData.filterKey}
                name="filterKey"
                variant={'filled'}
                rounded={'full'}
                size={'sm'}
                _focusVisible={{
                  border: 'none'
                }}
              >
                {Object.values(INVESTMENT_ORDER_SEARCH_FILTERS).map(
                  (filter) => (
                    <option value={filter.key} key={filter.key}>
                      {filter.text}
                    </option>
                  )
                )}
              </Select>
            </Box>
            {INVESTMENT_ORDER_SEARCH_FILTERS[searchReqData.filterKey]
              ?.filterValues && (
              <Box maxWidth="13rem" my={2}>
                <Select
                  placeholder="Filter value"
                  onChange={handleFilterValueChange}
                  bg="gray !important"
                  color="black !important"
                  size={'sm'}
                  value={searchReqData.filterValue}
                  name="filterValue"
                  variant={'filled'}
                  rounded={'full'}
                  _focusVisible={{
                    border: 'none'
                  }}
                >
                  {Object.values(
                    INVESTMENT_ORDER_SEARCH_FILTERS[searchReqData.filterKey]
                      ?.filterValues
                  )?.map((filterValue) => (
                    <option value={filterValue} key={filterValue}>
                      {filterValue}
                    </option>
                  ))}
                </Select>
              </Box>
            )}
          </HStack>
        </Stack>
      </Box>

      <OrdersList
        orders={searchResult.orders}
        pageNo={searchReqData.pageNo}
        totalPages={searchResult.totalPages}
        handlePageChange={handlePageChange}
        onOrderDetailsClick={onOrderDetailsClick}
        product={PRODUCT.INVEST_MF}
      />
    </>
  );
};

export default InvestmentOrdersContent;

export default function workflowApi(fetch, baseUrl) {
  return {
    userDocs({ userId, pageNo = 0, pageSize = 20 }) {
      return fetch(
        `${baseUrl}/docs/user/${userId}?page_size=${pageSize}&page=${pageNo}`
      );
    },

    docByDocUrl({ docUrl }) {
      return fetch(`${baseUrl}/docs/${docUrl}`);
    },

    getWorkflowScreenData({ userId, workflowId }) {
      return fetch(`${baseUrl}/${userId}/screens/${workflowId}`);
    },

    docByDocType({ userId, documentTye }) {
      return fetch(`${baseUrl}/docs/user/${userId}/${documentTye}`);
    }
  };
}

import {
  Heading,
  VStack,
  Flex,
  Box,
  Grid,
  Divider,
  useDisclosure,
  HStack,
  IconButton,
  Input,
  Text,
  Spinner
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../../components/Pagination';
import ContentLayout from '../../layouts/ContentLayout';
import { updateThunesFulfillmentsReqData } from '../../store/actions/reqDataActions';
import useDidMount from '../../hooks/useDidMount';
import {
  forceInitiateFulfillment,
  getThunesOnHoldFulfillments,
  setFulfillmentsToInitialState
} from '../../store/actions/thunesActions';
import FullPageSpinner from '../../components/FullPageSpinner';
import ModalLayout from '../../components/Modal/ModalLayout';
import ConfirmForceInitiate from '../../components/Modal/ConfirmForceInitiate';
import useIsSuperAdmin from '../../hooks/useIsSuperAdmin';
import ThunesFulfillmentCard from '../../components/ThunesCard/ThunesFulfillmentCard';
import JsonViewer from '../../components/Modal/JsonViewer';
import useIsMobile from '../../hooks/useIsMobile';
import UserTransfers from '../User/UserTransfers';
import { useNavigate } from 'react-router-dom';
import FulfilmentChecksModal from '../../components/ThunesCard/FulfillmentChecksModal';
import OrderDetailsCard from '../../components/OrderDetailsCard';
import { MdClose } from 'react-icons/md';

const ThunesFulfillments = () => {
  const dispatch = useDispatch();
  const isSuperAdmin = useIsSuperAdmin();
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const {
    isLoading,
    data: orders,
    totalPages
  } = useSelector((state) => state.thunes.fulfillments);
  const [activeFulfillment, setActiveFulfillment] = useState(null);
  const [activeOnHoldReason, setActiveOnHoldReason] = useState(null);
  const [activeChecksOrderId, setActiveChecksOrderId] = useState(null);

  const {
    isOpen: isForceInitiateOpen,
    onOpen: onForceInitiateOpen,
    onClose: onForceInitiateClose
  } = useDisclosure();

  const {
    isOpen: isOnHoldReasonOpen,
    onOpen: onOnHoldReasonOpen,
    onClose: onOnHoldReasonClose
  } = useDisclosure();

  const {
    isOpen: isOrderChecksOpen,
    onOpen: onOrderChecksOpen,
    onClose: onOrderChecksClose
  } = useDisclosure();

  const searchReqData = useSelector(
    (state) => state.reqData.thunes.fulfillments
  );

  const [isForceInitiateLoading, setIsForceInitiateLoading] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedRecentOrder, setSelectedRecentOrder] = useState(null);

  const handleForceInitiateSubmit = () => {
    setIsForceInitiateLoading(true);
    let pageFix =
      orders.length > 1 || (orders.length === 1 && searchReqData.pageNo === 0)
        ? 0
        : -1;
    dispatch(
      forceInitiateFulfillment({
        fulfillmentId: activeFulfillment.fulfillmentId,
        onSuccess: () => {
          dispatch(
            updateThunesFulfillmentsReqData({
              orderId: '',
              pageNo: searchReqData.pageNo + pageFix
            })
          );
          // dispatch(setFulfillmentsToInitialState());
          setSelectedOrder(null);
          setSelectedRecentOrder(null);
          dispatch(
            getThunesOnHoldFulfillments({
              pageNo: searchReqData.pageNo + pageFix,
              orderId: ''
            })
          );
        },
        onFinish: () => {
          handleForceInitiateClose();
          setIsForceInitiateLoading(false);
        }
      })
    );
  };

  useDidMount(() => {
    triggerGetThunesFulfillments();
    setSelectedOrder(null);
    setSelectedRecentOrder(null);
  }, [searchReqData.pageNo, searchReqData.orderId]);

  useEffect(() => {
    if (!orders?.length) triggerGetThunesFulfillments();
  }, []);

  const triggerGetThunesFulfillments = () => {
    dispatch(getThunesOnHoldFulfillments(searchReqData));
  };

  const onForceInitiateClick = ({ fulfillmentId }) => {
    setActiveFulfillment({ fulfillmentId });
  };

  const onOnHoldReasonClick = ({ fulfillmentId, onHoldReason }) => {
    setActiveOnHoldReason({ fulfillmentId, onHoldReason });
  };

  useEffect(() => {
    if (activeFulfillment) onForceInitiateOpen();
  }, [activeFulfillment]);

  useEffect(() => {
    if (activeOnHoldReason) onOnHoldReasonOpen();
  }, [activeOnHoldReason]);

  const handleForceInitiateClose = () => {
    onForceInitiateClose();
    setActiveFulfillment(null);
  };

  const handleOnHoldReasonClose = () => {
    onOnHoldReasonClose();
    setActiveOnHoldReason(null);
  };

  const handleRecentOrderCardClick = ({ orderId }) => {
    setSelectedRecentOrder(orderId);
  };

  const handlePageChange = (pageNo) => {
    pageNo - 1 !== searchReqData.pageNo &&
      dispatch(updateThunesFulfillmentsReqData({ pageNo: pageNo - 1 }));
  };

  const openRecentOrders = ({ userId, orderId }) => {
    setSelectedOrder({ userId, orderId });
  };

  const handleChecksClick = ({ orderId }) => {
    setActiveChecksOrderId(orderId);
    onOrderChecksOpen();
  };

  const handleChecksClose = () => {
    setActiveChecksOrderId(null);
    onOrderChecksClose();
  };

  const handleSearchOrderIdChange = (e) => {
    const value = e?.target?.value;
    dispatch(
      updateThunesFulfillmentsReqData({
        orderId: value
      })
    );
  };

  return (
    <Box position={'relative'}>
      {activeFulfillment && (
        <ModalLayout
          isOpen={isForceInitiateOpen}
          onClose={handleForceInitiateClose}
          size={'md'}
        >
          <ConfirmForceInitiate
            handleSubmit={handleForceInitiateSubmit}
            isLoading={isForceInitiateLoading}
            prompt={` Force initiate fulfillment  ${activeFulfillment.fulfillmentId} ? `}
          />
        </ModalLayout>
      )}

      {activeOnHoldReason && (
        <ModalLayout
          isOpen={isOnHoldReasonOpen}
          onClose={handleOnHoldReasonClose}
          size={'md'}
        >
          <JsonViewer
            title={'On Hold Reason'}
            subTitle={`Fulfillment ID: ${activeOnHoldReason.fulfillmentId}`}
            jsonString={activeOnHoldReason.onHoldReason}
          />
        </ModalLayout>
      )}
      {activeChecksOrderId && (
        <ModalLayout
          size="2xl"
          isOpen={isOrderChecksOpen}
          onClose={handleChecksClose}
        >
          <FulfilmentChecksModal
            orderId={activeChecksOrderId}
            pageNo={searchReqData?.pageNo}
            handleCancel={handleChecksClose}
            onSubmit={() => {
              dispatch(
                updateThunesFulfillmentsReqData({
                  orderId: ''
                })
              );
              // dispatch(setFulfillmentsToInitialState());
              setSelectedOrder(null);
              setSelectedRecentOrder(null);
            }}
          />
        </ModalLayout>
      )}
      <ContentLayout>
        <Grid gridTemplateColumns={{ base: '1fr', lg: '7fr 0fr 2fr' }}>
          <VStack
            p={10}
            gap={4}
            overflowY={{ base: 'initial', lg: 'scroll' }}
            overflowX={'hidden'}
            h={{ lg: '90vh' }}
            px={{ base: 4, sm: 10 }}
            alignItems={'stretch'}
            className="scroll"
          >
            <Flex
              w={'100%'}
              alignItems={'baseline'}
              justifyContent={'space-between'}
              pb={2}
            >
              <Heading color={'white'}>Thunes On Hold Fulfillments</Heading>
            </Flex>
            {!isLoading ? (
              <>
                <HStack>
                  <Input
                    onChange={handleSearchOrderIdChange}
                    value={searchReqData?.orderId}
                    placeholder="Search by Order ID"
                    color={'white'}
                  />
                </HStack>
                {totalPages > 0 && (
                  <Box alignSelf={'start'}>
                    <Pagination
                      totalPages={totalPages}
                      currentPage={searchReqData.pageNo + 1}
                      onPageChange={handlePageChange}
                    />
                  </Box>
                )}
                {/* {orders?.length === 0 && searchReqData.orderId !== '' && (
                  <Text>{`No Fulfillments found for Order ID - ${searchReqData?.orderId}`}</Text>
                )} */}
                {orders?.length > 0 && (
                  <VStack
                    py={5}
                    alignSelf={'flex-start'}
                    w={{ base: '100%', lg: '56rem' }}
                    maxW={'100%'}
                    gap={1}
                  >
                    {orders.map((item) => (
                      <ThunesFulfillmentCard
                        key={item.id}
                        fulfillmentData={item}
                        isSuperAdmin={isSuperAdmin}
                        onForceInitiateClick={onForceInitiateClick}
                        onOnHoldReasonClick={onOnHoldReasonClick}
                        onRecentTransfersClick={openRecentOrders}
                        onChecksClick={handleChecksClick}
                      />
                    ))}
                  </VStack>
                )}
              </>
            ) : (
              <Spinner />
            )}
          </VStack>
          <Divider
            style={{ margin: '0' }}
            borderColor="whiteAlpha.300"
            orientation="vertical"
          ></Divider>
          {!isMobile && (
            <Flex
              justifyContent={'center'}
              m={0}
              alignSelf={'flex-start'}
              overflowY={'auto'}
              height={'90vh'}
            >
              {selectedRecentOrder === null && selectedOrder?.userId && (
                <VStack w={'100%'}>
                  <Heading px={2} pt={3} color={'white'} fontSize={'1.3rem'}>
                    Recent Transfers
                  </Heading>
                  <UserTransfers
                    isSidebar
                    userId={selectedOrder.userId}
                    setSelectedOrder={handleRecentOrderCardClick}
                    maxW={'350px'}
                  />
                </VStack>
              )}
              {selectedOrder?.userId && selectedRecentOrder && (
                <VStack w={'100%'} alignItems={'stretch'}>
                  <HStack px={3}>
                    <IconButton
                      icon={<MdClose color="white" />}
                      variant={'unstyled'}
                      onClick={() => {
                        setSelectedRecentOrder(null);
                      }}
                    />
                  </HStack>
                  <OrderDetailsCard
                    orderId={selectedRecentOrder}
                    isForceInitiateEnabled={true}
                    onForceReInitiate={() => {}}
                  />
                </VStack>
              )}
            </Flex>
          )}
        </Grid>
      </ContentLayout>
    </Box>
  );
};

export default ThunesFulfillments;

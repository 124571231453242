import { useEffect, useState } from 'react';
import { Box, Text, Spinner, IconButton, Flex } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { MdEdit } from 'react-icons/md';
import { getFeatureFlagValue } from '../../store/actions/configActions';

const FeatureFlagItem = ({
  featureState,
  setFeatureState,
  featureFlag,
  onEditClick,
  isUpdated
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const getValue = () => {
    setIsLoading(true);
    dispatch(
      getFeatureFlagValue({
        featureName: featureFlag,
        onSuccess: (data) => {
          setFeatureState((prev) => ({
            ...prev,
            [data?.featureName]: data?.featureValue
          }));
        },
        onComplete: () => {
          setIsLoading(false);
        }
      })
    );
  };

  useEffect(() => {
    getValue();
  }, []);

  useEffect(() => {
    if (!isUpdated) return;
    getValue();
  }, [isUpdated]);

  return (
    <>
      <Flex width={'full'} gap={6}>
        <Box width={'25vw'}>
          <Text color={'gray.500'} fontSize={'md'}>
            {featureFlag}:
          </Text>
        </Box>
        <Box flex={'1'}>
          {!isLoading ? (
            <Box paddingY={1} maxW={'40vw'} overflowX={'scroll'}>
              <pre style={{ color: 'white' }}>
                {JSON.stringify(featureState[featureFlag], null, 4)}
              </pre>
            </Box>
          ) : (
            <Spinner color="brand.500" />
          )}
        </Box>
        <IconButton
          onClick={() => {
            onEditClick({
              featureName: featureFlag,
              featureValue: featureState[featureFlag]
            });
          }}
          variant={'unstyled'}
          icon={<MdEdit color="white" />}
        />
      </Flex>
    </>
  );
};

export default FeatureFlagItem;

import api from '../../api';
import { YBL_DETAILS as TYPES } from '../constants';
// import { setAlert } from './alertActions';

export const getYblDetails = (userId) => async (dispatch) => {
  try {
    dispatch({
      type: TYPES.GETTING_YBL_DETAILS,
      payload: {}
    });
    const { data } = await api.nronboard.getYblDetails(userId);
    dispatch({
      type: TYPES.GET_YBL_DETAILS_SUCCESS,
      payload: {
        ...data
      }
    });
  } catch (error) {
    console.error(error?.message);
    dispatch({
      type: TYPES.GET_YBL_DETAILS_FAILURE,
      payload: {
        errMsg: error?.message
      }
    });
  }
};

export const patchYblDetails =
  ({ userId, modifiedFields, onComplete, onSuccess }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: TYPES.UPDATING_YBL_DETAILS,
        payload: {}
      });

      const { data } = await api.nronboard.patchYblCustomerDetails(
        userId,
        modifiedFields
      );

      dispatch({
        type: TYPES.UPDATE_YBL_DETAILS_SUCCESS,
        payload: { ...data }
      });

      onSuccess && onSuccess();
    } catch (error) {
      console.error(error?.message);
      dispatch({
        type: TYPES.UPDATE_YBL_DETAILS_FAILURE,
        payload: {
          errMsg: error?.message
        }
      });
    } finally {
      onComplete && onComplete();
    }
  };

// to modify the local state
export const updateYblField = (fieldName, value) => (dispatch) => {
  const payload = {
    [fieldName]: value
  };
  dispatch({
    type: TYPES.MODIFY_YBL_FIELD_DATA,
    payload
  });
};

export const resetYblState = () => (dispatch) => {
  dispatch({
    type: TYPES.RESET_YBL_FORM_STATE
  });
};

import api from '../../api';
import { getErrorMessage } from '../../utils';
import { JOBS as types } from '../constants';
import { setAlert } from './alertActions';

export const getJobs = (searchReqData) => async (dispatch) => {
  try {
    dispatch({
      type: types.GETTING_JOBS
    });

    const { data } = await api.jobs.get(searchReqData);

    return dispatch({
      type: types.GET_JOBS_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch(
      setAlert({
        title: getErrorMessage({
          error,
          genericMessage: 'Failed to get jobs!'
        }),
        status: 'error'
      })
    );
    dispatch({
      type: types.GET_JOBS_FAILURE,
      payload: error
    });
  }
};

export const updateJobStatus =
  ({ jobId, status, onSuccess, onFinish }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: types.UPDATING_JOBS_STATUS
      });
      await api.jobs.updateStatus({ jobId, status });
      if (onSuccess) onSuccess();

      dispatch(
        setAlert({
          title: 'Updated status successfully',
          status: 'success'
        })
      );

      return dispatch({
        type: types.UPDATE_JOBS_STATUS_SUCCESS
      });
    } catch (error) {
      dispatch(
        setAlert({
          title: getErrorMessage({
            error,
            genericMessage: 'Failed to update job status'
          }),
          status: 'error'
        })
      );
      return dispatch({
        type: types.UPDATE_JOBS_STATUS_FAILURE,
        payload: error
      });
    } finally {
      if (onFinish) onFinish();
    }
  };

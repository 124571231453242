import {
  Box,
  Button,
  HStack,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Select,
  Table,
  Tbody,
  Tr,
  Td,
  Text,
  VStack,
  Spinner,
  Thead,
  Th,
  FormLabel
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { getOrderTransferChecks } from '../../store/actions/ordersActions';
import { BENEFICIARY_RISK_REQUEST } from '../../constants';
import { tableCellStyles } from '../NrOnboard/constants';
import { useDispatch } from 'react-redux';
import {
  getThunesOnHoldFulfillments,
  updateBeneficiaryRisks
} from '../../store/actions/thunesActions';

const FulfilmentChecksModal = ({ orderId, pageNo, handleCancel, onSubmit }) => {
  const [orderTransferChecks, setOrderTransferChecks] = useState(null);
  const [loading, setLoading] = useState(false);
  const [riskCategory, setRiskCategory] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    if (orderTransferChecks) return;
    setLoading(true);
    dispatch(
      getOrderTransferChecks({
        orderId,
        onSuccess: (data) => {
          setOrderTransferChecks(data);
        },
        onComplete: () => {
          setLoading(false);
        }
      })
    );
  }, [orderId]);

  const cellStyles = tableCellStyles();

  const handleFormSubmit = (e) => {
    e?.preventDefault();
    setLoading(true);
    dispatch(
      updateBeneficiaryRisks({
        orderId,
        acctNo: '',
        riskCategory,
        onSuccess: () => {
          dispatch(
            getThunesOnHoldFulfillments({
              pageNo
            })
          );
          onSubmit && onSubmit();
        },
        onComplete: () => {
          setLoading(false);
          handleCancel && handleCancel();
        }
      })
    );
  };

  return (
    <>
      <ModalHeader>Fulfillment Checks</ModalHeader>
      <form onSubmit={handleFormSubmit}>
        <ModalBody>
          <VStack alignItems={'stretch'} gap={2}>
            <Box width={'full'}>
              {!loading ? (
                <>
                  {orderTransferChecks?.checks?.length > 0 && (
                    <Table>
                      <Thead>
                        <Tr>
                          <Th style={cellStyles}>CheckName</Th>
                          <Th style={cellStyles}>Result</Th>
                          <Th style={cellStyles}>Message</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {orderTransferChecks?.checks?.map((check) => (
                          <Tr key={check?.checkName}>
                            <Td style={cellStyles}>
                              <Text color={'white'} fontSize={'md'}>
                                {check?.checkName}
                              </Text>
                            </Td>
                            <Td style={cellStyles}>
                              <Text color={'white'} fontSize={'md'}>
                                {check?.result?.result}
                              </Text>
                            </Td>
                            <Td style={cellStyles}>
                              <Text color={'white'} fontSize={'md'}>
                                {check?.result?.message || '-'}
                              </Text>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  )}
                  {orderTransferChecks?.length === 0 && (
                    <Text color={'white'} fontSize={'md'}>
                      No checks found
                    </Text>
                  )}
                </>
              ) : (
                <Spinner />
              )}
            </Box>
            <Box width={'full'}>
              <FormLabel textColor={'white'}>Update Fulfilment</FormLabel>
              <Select
                placeholder="Select Risk Category"
                onChange={(e) => {
                  setRiskCategory(e?.target?.value);
                }}
                value={riskCategory}
              >
                {Object.keys(BENEFICIARY_RISK_REQUEST).map((key) => (
                  <option value={key} key={key}>
                    {key}
                  </option>
                ))}
              </Select>
            </Box>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <HStack justifyContent={'end'}>
            <Button
              variant="outline"
              colorScheme="red"
              type="button"
              onClick={handleCancel}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              disabled={!riskCategory || loading}
              variant="outline"
              colorScheme="brand"
              type="submit"
            >
              Update
            </Button>
          </HStack>
        </ModalFooter>
      </form>
    </>
  );
};

export default FulfilmentChecksModal;

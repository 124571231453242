import {
  DROP_DOWN_FIELDS,
  READ_ONLY_FIELDS,
  FILE_FIELDS,
  COUNTRY,
  ANNUAL_INCOME_RANGES,
  COMPANY_TYPE,
  YBL_CURRENT_ACCOUNT_TYPE,
  EDUCATIONAL_QUALIFICATIONS,
  EMPLOYMENT_TYPES,
  NAME_PREFIX_TYPE,
  MARITAL_STATUS,
  GENDER,
  NATURE_OF_BUSINESS,
  RELATIONSHIP_TYPE,
  CUSTOMER_TYPE,
  PROGRAM_TYPES,
  RESIDENCE_TYPES,
  SOURCE_OF_FUND,
  YES_NO,
  TAX_IDENTIFICATION_TYPES,
  YBL_PRODUCT_TYPE,
  RESIDENTIAL_STATUS,
  PROFESSION,
  NATURE_OF_INDUSTRY,
  ACCOUNT_TYPE,
  BOOLEAN_TYPE,
  DATE_FIELDS,
  BLOCKED_FIELDS
} from './constants';

export function getOptionsDataFromKey(key) {
  switch (key) {
    case DROP_DOWN_FIELDS.countryCode:
    case DROP_DOWN_FIELDS.overseasResidence:
    case DROP_DOWN_FIELDS.country:
    case DROP_DOWN_FIELDS.countryOfCitizenship_pd:
    case DROP_DOWN_FIELDS.countryOfBirth_pd:
    case DROP_DOWN_FIELDS.country_na:
    case DROP_DOWN_FIELDS.country_ga:
    case DROP_DOWN_FIELDS.countryId_cad:
    case DROP_DOWN_FIELDS.countryForJuris_fatcaDetail:
    case DROP_DOWN_FIELDS.nationality_fatcaDetail:
    case DROP_DOWN_FIELDS.residentAddress_fatcaDetail:
    case DROP_DOWN_FIELDS.countryJurisId_resAddress_fatcaDetail:
      return COUNTRY;
    case DROP_DOWN_FIELDS.accountType_isSingle:
    case DROP_DOWN_FIELDS.addNominee_nagd:
    case DROP_DOWN_FIELDS.debitCard_cc:
    case DROP_DOWN_FIELDS.sms_cc:
    case DROP_DOWN_FIELDS.netBanking_cc:
    case DROP_DOWN_FIELDS.estatement_cc:
    case DROP_DOWN_FIELDS.pepConsent_cc:
    case DROP_DOWN_FIELDS.accCibilConsent_cc:
    case DROP_DOWN_FIELDS.tandCConsent_cc:
    case DROP_DOWN_FIELDS.email_cc:
    case DROP_DOWN_FIELDS.infoDecConsent_fatcaDetail:
    case DROP_DOWN_FIELDS.outSiderIndia_fatcaDetail:
    case DROP_DOWN_FIELDS.seaFererOnBank_pd:
    case DROP_DOWN_FIELDS.seaFarerOnBreak_pd:
      return YES_NO;
    case DROP_DOWN_FIELDS.prodAndOffersUpdate_cc:
    case DROP_DOWN_FIELDS.fatcaAndConsent_cc:
    case DROP_DOWN_FIELDS.acceptTermsAndConditionsConsent_cc:
    case DROP_DOWN_FIELDS.isPoi_pio:
    case DROP_DOWN_FIELDS.hasIndianPassport_pio:
    case DROP_DOWN_FIELDS.hasSpouseIndianPassport_pio:
    case DROP_DOWN_FIELDS.isCitizenByVoc_pio:
    case DROP_DOWN_FIELDS.isNriStatusAltered_seafarer:
    case DROP_DOWN_FIELDS.incomeTaxDecConsent_f60d:
    case DROP_DOWN_FIELDS.appliedForPan_f60d:
    case DROP_DOWN_FIELDS.getPermAddForTax_fatcaDetail:
    case DROP_DOWN_FIELDS.isaSeafarerOnBreak_seafarer:
      return BOOLEAN_TYPE;
    case DROP_DOWN_FIELDS.taxIdType_fatcaDetail:
    case DROP_DOWN_FIELDS.taxIdTypeId_resAddress_fatcaDetail:
      return TAX_IDENTIFICATION_TYPES;
    case DROP_DOWN_FIELDS.gender:
      return GENDER;
    case DROP_DOWN_FIELDS.maritalStatus:
      return MARITAL_STATUS;
    case DROP_DOWN_FIELDS.productId:
    case DROP_DOWN_FIELDS.productType_hb:
      return YBL_PRODUCT_TYPE;
    case DROP_DOWN_FIELDS.programType_hb:
      return PROGRAM_TYPES;
    case DROP_DOWN_FIELDS.sourceOfFund_pd:
      return SOURCE_OF_FUND;
    case DROP_DOWN_FIELDS.educationalQualification_pd:
      return EDUCATIONAL_QUALIFICATIONS;
    case DROP_DOWN_FIELDS.residentialStatus_pd:
      return RESIDENTIAL_STATUS;
    case DROP_DOWN_FIELDS.residenceType_pd:
      return RESIDENCE_TYPES;
    case DROP_DOWN_FIELDS.annualIncome_pd:
      return ANNUAL_INCOME_RANGES;
    case DROP_DOWN_FIELDS.employedWith_pd:
      return COMPANY_TYPE;
    case DROP_DOWN_FIELDS.natureOfBusiness_pd:
      return NATURE_OF_BUSINESS;
    case DROP_DOWN_FIELDS.currentAccountType_pd:
      return YBL_CURRENT_ACCOUNT_TYPE;
    case DROP_DOWN_FIELDS.companyType_pd:
      return COMPANY_TYPE;
    case DROP_DOWN_FIELDS.employmentType_pd:
      return EMPLOYMENT_TYPES;
    case DROP_DOWN_FIELDS.profession_pd:
      return PROFESSION;
    case DROP_DOWN_FIELDS.natureOfIndustry_pd:
      return NATURE_OF_INDUSTRY;
    case DROP_DOWN_FIELDS.customerAccountType_pd:
      return ACCOUNT_TYPE;
    case DROP_DOWN_FIELDS.customerType_pd:
      return CUSTOMER_TYPE;
    case DROP_DOWN_FIELDS.nomineeRelationshipId_nagd:
    case DROP_DOWN_FIELDS.relationId_pio:
      return RELATIONSHIP_TYPE;
    case DROP_DOWN_FIELDS.prefix:
      return NAME_PREFIX_TYPE;
    default:
      return {};
  }
}

export const transformFieldToInputData = (key, value) => {
  const isFile = key in FILE_FIELDS;
  const isEditable = !(key in READ_ONLY_FIELDS);
  const isDropdown = key in DROP_DOWN_FIELDS;
  const isDate = key in DATE_FIELDS;
  const isBlocked = key in BLOCKED_FIELDS;
  return {
    isBlocked,
    isFile,
    isEditable,
    isDropdown,
    isDate,
    value,
    name: key
  };
};

export const getPatchData = (source, modified) => {
  return Object.keys(modified)
    .filter((key) => source[key] !== modified[key])
    .reduce((prev, curr) => {
      return {
        ...prev,
        [curr]: modified[curr]
      };
    }, {});
};

export const getNotarizedDocs = (formData) => {
  const NOTARIZED_STATUS_MAP = {
    selfieDocUrl: {
      status: 'isSelfieDocUploaded'
    },
    signatureDocUrl: {
      status: 'isSignatureDocUploaded'
    },
    notarizedPassportFrontDocUrl: {
      status: 'isNotarizedPassportFrontUploaded'
    },
    notarizedPassportBackDocUrl: {
      status: 'isNotarizedPassportBackUploaded'
    },
    notarizedMailingAddressDocUrl: {
      status: 'isNotarizedMailingAddressUploaded'
    },
    visaDocUrl: {
      status: 'isVisaUploaded'
    }
  };
  return Object.keys(NOTARIZED_STATUS_MAP).map((key) => ({
    name: key,
    fileUrl: formData[key],
    status: formData[NOTARIZED_STATUS_MAP[key]['status']]
  }));
};

export const getOtherDocs = (formData) => {
  const OTHER_DOCS = {
    passportFrontDocUrl: 'passportFrontDocUrl',
    passportBackDocUrl: 'passportBackDocUrl',
    brpFrontDocUrl: 'brpFrontDocUrl',
    brpBackDocUrl: 'brpBackDocUrl',
    bankStatementDocUrl: 'bankStatementDocUrl',
    emiratesIdDocUrl: 'emiratesIdDocUrl'
  };
  return Object.keys(OTHER_DOCS).map((key) => ({
    name: key,
    fileUrl: formData[key]
  }));
};

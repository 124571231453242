export default function referralsV2Api(fetch, baseUrl) {
  return {
    syncWallet(userId) {
      return fetch.put(`${baseUrl}/${userId}/sync-wallets`);
    },

    getRedeemedCoins({ status, page = 0, pageSize = 10 }) {
      return fetch.get(
        `${baseUrl}/redeemed-coins?page=${page}&page_size=${pageSize}${
          status?.length ? `&status=${status}` : ''
        }`
      );
    },

    getUserRedeemedCoins(userId, { page = 0, pageSize = 10 }) {
      return fetch.get(
        `${baseUrl}/redeemed-coins/user/${userId}?page=${page}&page_size=${pageSize}`
      );
    },

    markRedeemCoinsCompleted({ redeemId, metadata }) {
      return fetch.put(`${baseUrl}/redeemed-coins/${redeemId}/completed`, {
        metadata
      });
    }
  };
}

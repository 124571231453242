import api from '../../api';
import { TICKETS as types } from '../constants';
import { setAlert } from './alertActions';

export const createTicket =
  ({ ticketType, referenceId, notes, assignedTo, onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      const { data } = await api.tickets.createTicket({
        ticketType,
        referenceId,
        notes,
        assignedTo
      });
      // TODO: might need to update the ops_ticket field of corresponding referenced object
      onSuccess && onSuccess(data);
    } catch (error) {
      console.error(error?.message);
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const updateTicket =
  ({
    ticketType,
    ticketId,
    status,
    assignedTo,
    notes,
    onSuccess,
    onComplete,
    updateStore
  }) =>
  async (dispatch) => {
    try {
      updateStore &&
        dispatch({
          type: types.UPDATING_TICKET_BY_ID,
          payload: {}
        });
      const { data } = await api.tickets.updateTicket({
        ticketId,
        status,
        assignedTo,
        notes
      });
      updateStore &&
        dispatch({
          type: types.UPDATE_TICKET_BY_ID_SUCCESS,
          payload: {
            data: { ...data },
            ticketType
          }
        });
      // to update referenced item ticket-data
      onSuccess && onSuccess(data);
    } catch (error) {
      console.log(error.message);
      updateStore &&
        dispatch({
          type: types.UPDATE_TICKET_BY_ID_FAILURE,
          payload: {
            errMsg: error?.message || 'Something went wrong'
          }
        });
      dispatch(
        setAlert({
          title: error.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const getAllTickets =
  ({
    ticketType = 'PENDING_ORDER',
    status,
    assignedTo,
    search,
    pageNo,
    pageSize
  }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: types?.GETTING_ALL_TICKETS,
        payload: {}
      });

      const { data } = await api.tickets.getAllTicketsByType({
        ticketType,
        status,
        assignedTo,
        search,
        pageNo,
        pageSize
      });

      dispatch({
        type: types?.GET_ALL_TICKETS_SUCCESS,
        payload: {
          ticketType,
          data: data?.data,
          totalPages: data?.totalPages
        }
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: types?.GET_ALL_TICKETS_FAILURE,
        payload: {
          errMsg: error?.message || 'Something went wrong'
        }
      });
    }
  };

export const getTicket =
  ({ referenceId, ticketType, onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      const { data } = await api.tickets.getTicket({
        ticketType,
        referenceId
      });
      onSuccess && onSuccess(data);
    } catch (error) {
      console.error('[NO TICKET CASE]: ', error?.message);
      return;
    } finally {
      onComplete && onComplete();
    }
  };

import { NR_OB_COUNTRY, NR_ON_BOARD_TYPE } from '../../constants';
import { NR_ON_BOARD } from '../constants';
import {
  transformNroOnboarState,
  transformOnboarUserDocs,
  transformOnboardUserDocsV2
} from './utils';

const initialState = {
  details: {
    isLoading: false,
    isError: false,
    error: null,
    data: {
      accountOpening: null,
      application: null,
      delivery: null,
      pickup: null,
      simVerification: null,
      onboardingType: NR_ON_BOARD_TYPE.IN_APP,
      activeStep: null,
      applicationRejectionReason: null,
      country: null
    }
  },
  docs: {
    isLoading: false,
    isError: false,
    error: null,
    data: []
  },
  financialDetails: {
    isLoading: false,
    isError: false,
    error: null,
    data: {}
  },
  ocr: {
    isLoading: false,
    isError: false,
    error: null,
    data: {}
  }
};

const nronboardReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case NR_ON_BOARD.GETTING_ON_BOARD_DETAILS:
      return {
        ...state,
        details: {
          ...state.details,
          isLoading: true
        }
      };
    case NR_ON_BOARD.GET_ON_BOARD_DETAILS_SUCCESS:
      return {
        ...state,
        details: {
          ...state?.details,
          isLoading: false,
          isError: false,
          error: '',
          data: transformNroOnboarState(payload)
        }
      };
    case NR_ON_BOARD.GET_ON_BOARD_DETAILS_FAIL:
      return {
        ...state,
        details: {
          ...state?.details,
          isLoading: false,
          isError: true,
          error: payload?.errorMsg
        }
      };
    case NR_ON_BOARD.GETTING_USER_DOCS:
      return {
        ...state,
        docs: {
          ...state?.docs,
          isLoading: true
        }
      };
    case NR_ON_BOARD.GET_USER_DOCS_SUCCESS:
      return {
        ...state,
        docs: {
          ...state?.docs,
          isLoading: false,
          isError: false,
          data: transformOnboardUserDocsV2(payload)
        }
      };
    case NR_ON_BOARD.GET_USER_DOCS_FAIL:
      return {
        ...state,
        docs: {
          ...state?.docs,
          isLoading: false,
          isError: true,
          error: payload?.errorMsg
        }
      };
    case NR_ON_BOARD.GETTING_FINANCIAL_WORKFLOW_DETAILS:
      return {
        ...state,
        financialDetails: {
          ...state?.financialDetails,
          isLoading: true,
          isError: false,
          error: null
        }
      };
    case NR_ON_BOARD.GET_FINANCIAL_WORKFLOW_DETAILS_SUCCESS:
      return {
        ...state,
        financialDetails: {
          ...state?.financialDetails,
          isLoading: false,
          isError: false,
          error: null,
          data: payload
        }
      };
    case NR_ON_BOARD.GET_FINANCIAL_WORKFLOW_DETAILS_FAILURE:
      return {
        ...state,
        financialDetails: {
          ...state?.financialDetails,
          isLoading: false,
          isError: true,
          error: payload?.errMsg,
          data: {}
        }
      };
    case NR_ON_BOARD.GETTING_OCR_DETAILS:
      return {
        ...state,
        ocr: {
          ...state?.ocr,
          isLoading: true,
          isError: false,
          error: null,
          data: {}
        }
      };
    case NR_ON_BOARD.GET_OCR_DETAILS_SUCCESS:
      return {
        ...state,
        ocr: {
          ...state?.ocr,
          isLoading: false,
          isError: false,
          error: null,
          data: payload
        }
      };
    case NR_ON_BOARD.GET_OCR_DETAILS_FAIL:
      return {
        ...state,
        ocr: {
          ...state?.ocr,
          isLoading: false,
          isError: true,
          error: payload?.errMsg,
          data: {}
        }
      };
    default:
      return {
        ...state
      };
  }
};

export default nronboardReducer;

import { EXCHANGE as types } from '../constants';
import api from '../../api';

export const getQuote = () => async (dispatch) => {
  try {
    dispatch({
      type: types.GETTING_QUOTE
    });

    const { data } = await api.exchangeApi.quote();

    return dispatch({
      type: types.GET_QUOTE_SUCCESS,
      payload: data
    });
  } catch (error) {
    return dispatch({
      type: types.GET_QUOTE_FAILURE,
      payload: error
    });
  }
};

import api from "../../api"
import { CX_USERS as types } from "../constants"

export const getAllCxUsers = () => async (dispatch) => {
    try {        
        dispatch({
            type: types.GETTTING_ALL_USERS,
            payload: {}
        })
        const { data } = await api.cxUsers.getAllUsers();
        dispatch({
            type: types.GET_ALL_USERS_SUCCESS,
            payload: {
                data
            }
        })
    } catch (error) {
        console.log(error);
        dispatch({
            type: types.GET_ALL_USERS_FAILURE,
            payload: {
                errMsg: error?.message
            }
        })
    }
}
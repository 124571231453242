import DisplayData from './DisplayData';
import { NR_OB_UK_STAGES, NR_OB_UAE_STAGES } from '../../constants';
import {
  NrOnboardAppointment,
  NrOnboardDelivery,
  NrOnboardPickup
} from './index';
import NronboardApplication from './Application';

const NrOnboardDetailsWrapper = (props) => {
  const { selectedStage = null, data, userId } = props;
  switch (selectedStage) {
    case NR_OB_UK_STAGES.APPLICATION:
      return <NronboardApplication applicationData={data} userId={userId} />;
    case NR_OB_UAE_STAGES.APPOINTMENT:
      return <NrOnboardAppointment appointmentData={data} userId={userId} />;
    case NR_OB_UK_STAGES.DELIVERY:
      return <NrOnboardDelivery deliveryData={data} userId={userId} />;
    case NR_OB_UK_STAGES.PICKUP:
      return <NrOnboardPickup pickupData={data} userId={userId} />;
    default:
      return <DisplayData data={data} />;
  }
};

export default NrOnboardDetailsWrapper;

export default function (fetch, baseUrl) {
  return {
    getDetails(userId) {
      return fetch.get(`${baseUrl}?user_id=${userId}`);
    },
    updateDetails(payload) {
      return fetch.post(`${baseUrl}`, {
        ...payload
      });
    },
    enableStudentKycFlow(userId) {
      return fetch.post(`${baseUrl}/enable-re-kyc`, { userId });
    }
  };
}

import { Box, HStack, Select } from '@chakra-ui/react';
import { FILTER_BY_PAST_HOURS, NROB_FUNNEL_STATES } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { updateNrobReqData } from '../../store/actions/reqDataActions';

const NrobFunnelLocalFilter = ({ filterType }) => {
  const dispatch = useDispatch();
  const KEY_MAP = {
    [NROB_FUNNEL_STATES.APPOINTMENT_PENDING]: 'appointmentPendingForHours',
    [NROB_FUNNEL_STATES.PENDING_FORM_VERIFICATION]:
      'formVerificationPendingForHours',
    [NROB_FUNNEL_STATES.DOCS_PICKUP_PENDING]: 'docsPickupPendingForHours'
  };

  const key = KEY_MAP[filterType];

  const reqAggregrate = useSelector(
    (state) => state?.reqData?.funnel?.nronboard?.reqData
  );
  const reqData = reqAggregrate[filterType]['additionalFilters'] ?? {
    [key]: ''
  };

  const value = reqData[key];

  const handleChange = (e) => {
    const value = e?.target?.value;
    dispatch(
      updateNrobReqData({
        reqData: {
          ...reqAggregrate,
          [filterType]: {
            ...reqAggregrate[filterType],
            additionalFilters: {
              [key]: value
            }
          }
        }
      })
    );
  };

  if (
    filterType === NROB_FUNNEL_STATES.APPOINTMENT_PENDING ||
    filterType === NROB_FUNNEL_STATES.PENDING_FORM_VERIFICATION ||
    filterType === NROB_FUNNEL_STATES.DOCS_PICKUP_PENDING
  ) {
    return (
      <HStack justifyContent={'end'} width={'auto'} alignItems={'center'}>
        <Box maxWidth={'15rem'}>
          <Select
            placeholder="Filter by past hours"
            value={value}
            onChange={handleChange}
            bg="black"
            name="country"
            color="gray"
            size={'sm'}
            rounded={'lg'}
            _focus={{
              border: '1px solid #81EBAB',
              boxShadow: 'none'
            }}
          >
            {FILTER_BY_PAST_HOURS.map((val) => (
              <option value={val} key={val}>{`${val} hours`}</option>
            ))}
          </Select>
        </Box>
      </HStack>
    );
  }

  return null;
};

export default NrobFunnelLocalFilter;

import { TICKET_STATUS } from '../../constants';

export default function ticketsApi(fetch, baseUrl) {
  return {
    getTicket({ ticketType, referenceId }) {
      return fetch(`${baseUrl}/${ticketType}/${referenceId}`);
    },
    getAllTicketsByType({
      ticketType,
      status = TICKET_STATUS.UNRESOLVED,
      assignedTo = '',
      search = '',
      pageNo = 0,
      pageSize = 10
    }) {
      return fetch.get(
        `${baseUrl}/${ticketType}?page=${pageNo}&page_size=${pageSize}&${
          status && `status=${status}`
        }${assignedTo && `&assigned_to=${assignedTo}`}${
          search && `&search=${search}`
        }`
      );
    },
    createTicket({ ticketType, assignedTo, referenceId, notes }) {
      return fetch.post(`${baseUrl}`, {
        ticketType: ticketType,
        assignedTo: assignedTo?.length ? assignedTo : null,
        referenceId: referenceId,
        notes
      });
    },
    updateTicket({ ticketId, status, assignedTo, notes }) {
      return fetch.put(`${baseUrl}/${ticketId}`, {
        status,
        assignedTo: assignedTo?.length ? assignedTo : null,
        notes
      });
    }
  };
}

import {
  Grid,
  Heading,
  Table,
  Tbody,
  Td,
  Thead,
  Tr,
  VStack,
  Text,
  IconButton,
  Spinner,
  Th,
  useDisclosure
} from '@chakra-ui/react';
import ContentLayout from '../../layouts/ContentLayout';
import { MdEdit } from 'react-icons/md';
import { tableCellStyles } from '../../components/NrOnboard/constants';
import { useEffect, useState } from 'react';
import {
  getIsFxRatePeaking,
  putPollingStatus
} from '../../store/actions/configActions';
import { CURRENCIES } from '../../constants/v2/common';
import api from '../../api';
import ModalLayout from '../../components/Modal/ModalLayout';
import ConfirmModal from '../../components/Modal/ConfirmForceInitiate';
import { useDispatch } from 'react-redux';
import useAuthRole from './../../hooks/useAuthRole';
import { ROLES } from '../../constants';

const FxRatePeakingConfig = () => {
  const [config, setConfig] = useState({});
  const [loading, setLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedCurr, setSelectedCurr] = useState('');
  const dispatch = useDispatch();
  const { hasAnyAuthority } = useAuthRole();

  const cellStyles = tableCellStyles();

  useEffect(() => {
    getFxRatePeakingAllCurrencies();
  }, []);

  const getFxRatePeakingAllCurrencies = () => {
    setLoading(true);
    const ratePeakingPromises = Object.keys(CURRENCIES).map(
      async (currency) => {
        return api.fxRatePeakingConfig
          .getIsFxRatePeaking(currency)
          .then((res) => {
            const { data } = res;
            return { [currency]: data?.isRatePeaked };
          })
          .catch((err) => {
            throw new Error(err?.message);
          });
      }
    );
    Promise.all(ratePeakingPromises)
      .then((res) => {
        const data = res.reduce((prev, curr) => {
          return {
            ...prev,
            ...curr
          };
        }, {});
        setConfig(data);
      })
      .catch()
      .finally(() => {
        setLoading(false);
      });
  };

  const handleEditClick = (currency) => {
    setSelectedCurr(currency);
    onOpen && onOpen();
  };

  const handleClose = () => {
    setSelectedCurr(null);
    onClose && onClose();
  };

  const getPrompt = () => {
    if (!selectedCurr) return '';
    return `Are you sure you want to ${
      !config[selectedCurr] ? 'enable' : 'disable'
    } peaking for this currency - ${selectedCurr} ?`;
  };

  const handleModalSubmit = () => {
    setLoading(true);
    dispatch(
      putPollingStatus({
        currency: selectedCurr,
        isEnabled: !config[selectedCurr] ? 'TRUE' : 'FALSE',
        onComplete: () => {
          setLoading(false);
        },
        onSuccess: () => {
          dispatch(
            getIsFxRatePeaking({
              currency: selectedCurr,
              onSuccess: (value) => {
                setConfig((prev) => ({
                  ...prev,
                  [selectedCurr]: value
                }));
              }
            })
          );
          setSelectedCurr(null);
          onClose && onClose();
        }
      })
    );
  };

  return (
    <>
      <ModalLayout isOpen={isOpen} onClose={handleClose}>
        <ConfirmModal
          prompt={getPrompt()}
          handleSubmit={handleModalSubmit}
          isLoading={loading}
        />
      </ModalLayout>
      <ContentLayout>
        <Grid gridTemplateColumns={{ base: '1fr', lg: '7fr 0fr 2fr' }}>
          <VStack alignItems={'stretch'} p={10} gap={4}>
            <Heading size={'md'} color={'white'}>
              FX Rate Peaking Config
            </Heading>
            <VStack gap={4} alignItems={'stretch'}>
              {!loading ? (
                <Table>
                  <Thead>
                    <Tr>
                      <Th style={cellStyles}>Currency</Th>
                      <Th style={cellStyles}>Is Rate Peaking ?</Th>
                      <Th style={cellStyles}>Update</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {Object.keys(config).map((currency) => {
                      return (
                        <Tr key={currency}>
                          <Td style={cellStyles}>
                            <Text fontSize={'md'} color={'white'}>
                              {currency}
                            </Text>
                          </Td>
                          <Td style={cellStyles}>
                            <Text fontSize={'md'} color={'white'}>
                              {String(config[currency]) || '-'}
                            </Text>
                          </Td>
                          <Td style={cellStyles}>
                            {hasAnyAuthority(
                              ROLES.ADMIN_ROLE,
                              ROLES.CONFIG_MANAGER_ROLE
                            ) ? (
                              <IconButton
                                color={'white'}
                                icon={<MdEdit />}
                                variant={'unstyled'}
                                onClick={() => {
                                  handleEditClick(currency);
                                }}
                              />
                            ) : (
                              <Text fontSize={'medium'} color={'white'}>
                                -
                              </Text>
                            )}
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              ) : (
                <Spinner />
              )}
            </VStack>
          </VStack>
        </Grid>
      </ContentLayout>
    </>
  );
};

export default FxRatePeakingConfig;

import React from 'react';
import { Flex, Input, IconButton } from '@chakra-ui/react';
import { MdSend } from 'react-icons/md';

const Footer = ({ inputMessage, setInputMessage, handleSendMessage }) => {
  return (
    <Flex px={1}>
      <Input
        bg={'shadedGray.100'}
        placeholder="Type Something..."
        color="black"
        border={0}
        rounded="lg"
        _focus={{
          border: '1px solid black'
        }}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            handleSendMessage();
          }
        }}
        value={inputMessage}
        onChange={(e) => setInputMessage(e.target.value)}
      />
      <IconButton
        mx={1}
        colorScheme={'brand'}
        rounded="full"
        disabled={inputMessage.trim().length <= 0}
        onClick={handleSendMessage}
        icon={<MdSend />}
      />
    </Flex>
  );
};

export default Footer;

import {
  Grid,
  useDisclosure,
  Text,
  Button,
  VStack,
  HStack,
  IconButton
} from '@chakra-ui/react';
import DisplayData from './DisplayData';
import ModalLayout from '../Modal/ModalLayout';
import UpdateNrObStatusModal from './UpdateNrObStatusModal';
import { NROB_ACTIONS, NROB_DELIVERY_STATUS } from '../../constants';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  scheduleDocsDelivery,
  updateDocsDeliveryStatus
} from './../../store/actions/nronboardActions';
import { AiFillEdit } from 'react-icons/ai';
import dayjs from 'dayjs';
import ScheduleDeliveryModal from './ScheduleDeliveryModal';

const NrOnboardDelivery = ({ deliveryData = {}, userId }) => {
  const { scheduledDeliveryDate, status, ...data } = deliveryData;
  const [isScheduleLoading, setIsScheduleLoading] = useState(false);

  const {
    isOpen: isUpdateStatusOpen,
    onOpen: onUpdateStatusOpen,
    onClose: onUpdateStatusClose
  } = useDisclosure();

  const {
    isOpen: isScheduleModalOpen,
    onOpen: onScheduleModalOpen,
    onClose: onScheduleModalClose
  } = useDisclosure();

  const dispatch = useDispatch();

  const handleScheduleDeliveryModalSubmit = (deliveryDate) => {
    setIsScheduleLoading(true);
    dispatch(
      scheduleDocsDelivery({
        userId,
        startTime: deliveryDate,
        onComplete: () => {
          setIsScheduleLoading(false);
          onScheduleModalClose && onScheduleModalClose();
        }
      })
    );
  };

  const handleStatusUpdateModalSubmit = (status) => {
    dispatch(
      updateDocsDeliveryStatus({
        userId,
        status,
        onComplete: onUpdateStatusClose
      })
    );
  };

  return (
    <>
      <ModalLayout isOpen={isUpdateStatusOpen} onClose={onUpdateStatusClose}>
        <UpdateNrObStatusModal
          actionType={NROB_ACTIONS.DELIVERY}
          onSubmit={handleStatusUpdateModalSubmit}
        />
      </ModalLayout>
      <ModalLayout isOpen={isScheduleModalOpen} onClose={onScheduleModalClose}>
        <ScheduleDeliveryModal
          isLoading={isScheduleLoading}
          onSubmit={handleScheduleDeliveryModalSubmit}
        />
      </ModalLayout>
      <VStack gap={2} alignItems={'start'}>
        <Grid rowGap={2} width={'full'} gridTemplateColumns={'0.8fr 1fr'}>
          <>
            <Text color={'shadedGray.400'}>Status:</Text>
            <HStack gap={2}>
              <Text color={'white'}>{status}</Text>
              {status === NROB_DELIVERY_STATUS.DOCS_DELIVERY_REQUESTED && (
                <IconButton
                  onClick={() => {
                    onUpdateStatusOpen();
                  }}
                  colorScheme="shadedGray"
                  aria-label="Search database"
                  icon={<AiFillEdit />}
                  size={'sm'}
                  color="altGray.200"
                  rounded={'full'}
                />
              )}
            </HStack>
          </>
          <>
            <Text color={'shadedGray.400'}>
              {status === NROB_DELIVERY_STATUS.DOCS_DELIVERY_FAILED
                ? 'Previous Scheduled Delivery date:'
                : 'Scheduled Delivery Date:'}
            </Text>
            <HStack gap={2}>
              <Text color={'white'}>
                {!scheduledDeliveryDate
                  ? '-'
                  : dayjs(scheduledDeliveryDate).format('DD-MM-YYYY')}
              </Text>
              {(status === NROB_DELIVERY_STATUS.ADDRESS_VERIFIED ||
                status === NROB_DELIVERY_STATUS.DOCS_DELIVERY_FAILED) && (
                <>
                  <Button
                    variant={'outline'}
                    colorScheme={'green'}
                    onClick={() => {
                      onScheduleModalOpen && onScheduleModalOpen();
                    }}
                  >
                    {status === NROB_DELIVERY_STATUS.ADDRESS_VERIFIED
                      ? 'Schedule'
                      : 'Reschedule'}
                  </Button>
                </>
              )}{' '}
            </HStack>
          </>
        </Grid>
        <DisplayData data={data} />
      </VStack>
    </>
  );
};

export default NrOnboardDelivery;

import {
  Button,
  Box,
  FormLabel,
  HStack,
  Input,
  ModalBody,
  ModalFooter,
  ModalHeader,
  VStack,
  Text
} from '@chakra-ui/react';
import { kebabCaseToSpaceSeperate } from '../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from '../../store/actions/alertActions';

const NrOnboardVerifyOcrAddressModal = ({
  addressState,
  setAddressState,
  addressConfig,
  handleDocClick,
  handleUpdate,
  handleDiscard
}) => {
  const { data: documents } = useSelector((state) => state?.nronboard?.docs);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const value = e?.target?.value;
    setAddressState((prevState) => ({
      ...prevState,
      [e?.target?.name]: value
    }));
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    handleUpdate(addressState);
  };

  const DISABLED_FIELDS = {
    country_code: 'country_code'
  };

  const onViewFileClick = (file) => {
    const requiredDoc = documents?.reduce((prev, curr) => {
      if (prev) return prev;
      return curr?.docName === file ? curr : prev;
    }, null);
    if (!requiredDoc) {
      dispatch(
        setAlert({
          title: 'Document not found',
          status: 'error'
        })
      );
      return;
    }
    handleDocClick(requiredDoc.docUrl);
  };

  return (
    <>
      <ModalHeader>Verify Address</ModalHeader>
      <form onSubmit={handleSubmit}>
        <ModalBody>
          <HStack
            alignItems={'start'}
            justifyContent={'center'}
            width={'full'}
            gap={2}
          >
            <VStack width={'full'} alignItems={'start'} gap={1}>
              {/* <Text></Text> */}

              <Text color={'white'} fontSize={'md'}>
                {addressConfig?.selectedAddressProof
                  ? `Use 
                    ${kebabCaseToSpaceSeperate(
                      addressConfig.selectedAddressProof
                    )}
                    as reference`
                  : 'No document is selected for reference'}
              </Text>
              <Button
                variant={'link'}
                colorScheme="brand"
                onClick={() => {
                  onViewFileClick(addressConfig?.selectedAddressProof);
                }}
              >
                View File
              </Button>
            </VStack>
            <VStack width={'full'} alignItems={'stretch'} gap={1}>
              {Object.keys(addressState).map((key) => (
                <Box key={key}>
                  <FormLabel htmlFor={key}>
                    {kebabCaseToSpaceSeperate(key)}
                  </FormLabel>
                  <Input
                    name={key}
                    value={addressState[key] || ''}
                    disabled={key in DISABLED_FIELDS}
                    required
                    onChange={handleChange}
                  />
                </Box>
              ))}
            </VStack>
          </HStack>
        </ModalBody>
        <ModalFooter>
          <HStack justifyContent={'end'}>
            <Button
              variant={'outline'}
              type="button"
              onClick={() => {
                handleDiscard();
              }}
            >
              Cancel
            </Button>
            <Button variant={'outline'} colorScheme="green" type="submit">
              Update
            </Button>
          </HStack>
        </ModalFooter>
      </form>
    </>
  );
};

export default NrOnboardVerifyOcrAddressModal;

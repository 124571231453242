import { ALERT as types } from '../constants';

export const setAlert = ({ title, status }) => ({
  type: types.SET_ALERT,
  payload: {
    title,
    status
  }
});

export const clearAlert = () => ({
  type: types.CLEAR_ALERT
});

// update ticket
// update note
// assign ticket
// get all tickets

const TICKETS = {
    // ASSIGN_TICKET_BY_USER_ID: 'ASSIGN_TICKET_BY_USER_ID',
    GETTING_ALL_TICKETS: 'GETTING_ALL_TICKETS',
    GET_ALL_TICKETS_SUCCESS: 'GET_ALL_TICKETS_SUCCESS',
    GET_ALL_TICKETS_FAILURE: 'GET_ALL_TICKETS_FAILURE',
    UPDATING_TICKET_BY_ID: 'UPDATING_TICKET_BY_ID',
    UPDATE_TICKET_BY_ID_SUCCESS: 'UPDATE_TICKET_BY_ID_SUCCESS',
    UPDATE_TICKET_BY_ID_FAILURE: 'UPDATE_TICKET_BY_ID_FAILURE',
    CREATING_TICKET: 'CREATING_TICKET',
    CREATE_TICKET_SUCCESS: 'CREATE_TICKET_SUCCESS',
    CREATE_TICKET_FAILURE: 'CREATE_TICKET_FAILURE'
}

export default TICKETS;
export default function manualPayoutBatchApi(fetch, baseUrl) {
  return {
    getSheets(payoutPartner) {
      return fetch.get(`${baseUrl}/batches?payout_partner=${payoutPartner}`);
    },

    downloadSheet(batchId) {
      return fetch.get(`${baseUrl}/batches/${batchId}/download`, {
        responseType: 'blob'
      });
    },

    uploadSheet(batchId, file) {
      const formData = new FormData();
      formData.append('file', file);

      return fetch.post(`${baseUrl}/batches/${batchId}/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },

    createBatch(payload) {
      return fetch.post(`${baseUrl}/batches`, payload);
    },
    
    getBatchDetails(batchId) {
      return fetch.get(`${baseUrl}/batches/${batchId}`);
    },

    getPendingSheets(payoutPartner) {
      return fetch.get(`${baseUrl}/batches/pending?payout_partner=${payoutPartner}`);
    },
  };
  
}
import { Code } from '@chakra-ui/react';
import React from 'react';

const JsonTextBox = ({ data, maxH = 'none', minH = 'none' }) => {
  return (
    <Code
      fontSize="sm"
      display="block"
      whiteSpace="pre-wrap"
      background={'altGray.200'}
      color={'white'}
      p={3}
      rounded={'md'}
      maxW={'100%'}
      maxH={maxH}
      minH={minH}
      overflowX={'scroll'}
    >
      {JSON.stringify(data, null, 2)}
    </Code>
  );
};

export default JsonTextBox;

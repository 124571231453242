import {
  VStack,
  Grid,
  Heading,
  useDisclosure,
  Box,
  HStack,
  Table,
  Tbody,
  Tr,
  Td,
  Text,
  Thead,
  Th,
  IconButton,
  Button,
  Spinner
} from '@chakra-ui/react';
import ContentLayout from '../../layouts/ContentLayout';
import ModalLayout from '../../components/Modal/ModalLayout';
import UpdateFeatureFlagModal from '../../components/Config/ReconDashboard/UpdateFeatureFlag';
import WithdrawEventModal from '../../components/Config/ReconDashboard/WithdrawEventModal';
import { useEffect, useState } from 'react';
import {
  getReconFeatureFlagValue,
  updateReconFeatureFlagValue,
  withdrawEvent
} from '../../store/actions/configActions';
import { FEATURE_FLAGS } from '../../constants';
import { useDispatch } from 'react-redux';
import { kebabCaseToSpaceSeperate } from '../../utils';
import { MdEdit } from 'react-icons/md';
import { tableCellStyles } from '../../components/NrOnboard/constants';

const ReconDashboardConfig = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [featureFlagVal, setFeatureFlagVal] = useState('');
  const dispatch = useDispatch();

  const {
    isOpen: isFeatureFlagModalOpen,
    onOpen: onFeatureFlagModalOpen,
    onClose: onFeatureFlagModalClose
  } = useDisclosure();
  const {
    isOpen: iswithdrawModalOpen,
    onOpen: onwithdrawModalOpen,
    onClose: onwithdrawModalClose
  } = useDisclosure();

  const triggerGetFeatureValue = () => {
    return getReconFeatureFlagValue({
      featureFlag: FEATURE_FLAGS.SABER_GBP_TO_USDT_CONVERSION_RATE,
      onSuccess: (data) => {
        const featureValue = data.featureValue;
        setFeatureFlagVal(featureValue);
      },
      onComplete: () => {
        setIsLoading(false);
      }
    });
  };

  const handleUpdateWithdrawCancel = () => {
    onwithdrawModalClose();
  };
  const handleUpdateWithdrawSubmit = ({
    eventId,
    withdrawAmt,
    provider = 'ON_RAMP'
  }) => {
    setIsLoading(true);
    dispatch(
      withdrawEvent({
        eventId,
        withdrawAmt,
        provider,
        onSuccess: () => {
          onwithdrawModalClose();
        },
        onComplete: () => {
          setIsLoading(false);
        }
      })
    );
  };

  const handleUpdateFeatureFlagCancel = () => {
    onFeatureFlagModalClose();
  };
  const handleUpdateFeatureFlagSubmit = ({ featureValue, featureName }) => {
    setIsLoading(true);
    dispatch(
      updateReconFeatureFlagValue({
        featureFlagName: featureName,
        featureFlagValue: featureValue,
        onSuccess: () => {
          triggerGetFeatureValue();
          onFeatureFlagModalClose();
        },
        onComplete: () => {
          setIsLoading(false);
        }
      })
    );
  };

  useEffect(() => {
    setIsLoading(true);
    dispatch(triggerGetFeatureValue());
  }, []);

  const cellStyles = tableCellStyles();

  return (
    <>
      <ModalLayout isOpen={iswithdrawModalOpen} onClose={onwithdrawModalClose}>
        <WithdrawEventModal
          isLoading={isLoading}
          onCancel={handleUpdateWithdrawCancel}
          onSubmit={handleUpdateWithdrawSubmit}
        />
      </ModalLayout>
      <ModalLayout
        isOpen={isFeatureFlagModalOpen}
        onClose={onFeatureFlagModalClose}
      >
        <UpdateFeatureFlagModal
          initialFeatureFlagValue={featureFlagVal}
          onSubmit={handleUpdateFeatureFlagSubmit}
          onCancel={handleUpdateFeatureFlagCancel}
        />
      </ModalLayout>
      <ContentLayout>
        <Grid gridTemplateColumns={{ base: '1fr', lg: '7fr 0fr 2fr' }}>
          <VStack alignItems={'stretch'} p={10} gap={4}>
            <Heading>Recon Dashboard Config</Heading>
            <VStack gap={4} alignItems={'stretch'}>
              {!isLoading ? (
                <>
                  <HStack width={'full'} justifyContent={'end'}>
                    <Button
                      variant={'outline'}
                      onClick={() => {
                        onwithdrawModalOpen();
                      }}
                    >
                      Withdraw
                    </Button>
                  </HStack>
                  <Table>
                    <Thead>
                      <Tr>
                        <Th>Feature Flag</Th>
                        <Th>Value</Th>
                        <Th></Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      <Tr>
                        <Td style={cellStyles}>
                          <Text fontSize={'md'} color={'md'}>
                            {kebabCaseToSpaceSeperate(
                              FEATURE_FLAGS.SABER_GBP_TO_USDT_CONVERSION_RATE
                            )}
                          </Text>
                        </Td>
                        <Td style={cellStyles}>
                          <Text fontSize={'md'} color={'md'}>
                            {featureFlagVal || '-'}
                          </Text>
                        </Td>
                        <Td style={cellStyles}>
                          <IconButton
                            icon={<MdEdit />}
                            onClick={() => {
                              onFeatureFlagModalOpen &&
                                onFeatureFlagModalOpen();
                            }}
                            variant={'unstyled'}
                          />
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </>
              ) : (
                <Spinner />
              )}
            </VStack>
          </VStack>
        </Grid>
      </ContentLayout>
    </>
  );
};

export default ReconDashboardConfig;

import {
  FormLabel,
  Input,
  ModalBody,
  ModalFooter,
  ModalHeader,
  VStack,
  HStack,
  Button,
  Select,
  Checkbox,
  Text
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getBeneficiaryVerificationData } from '../../store/actions/usersActions';
import dayjs from 'dayjs';
import { camelCaseToSpaceSeparated } from '../../utils';

const UpdateBeneficiartModal = ({
  isLoading,
  beneficiaryData,
  onSubmit,
  onCancel
}) => {
  const [beneficiary, setBeneficiary] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    if (!beneficiaryData) return;
    fetchVerificationData();
  }, []);

  const fetchVerificationData = () => {
    dispatch(
      getBeneficiaryVerificationData({
        accountNo: beneficiaryData?.accountNumber || '',
        ifsc: beneficiaryData?.ifscCode || '',
        onSuccess: (data) => {
          const {
            accountNumber,
            ifscCode,
            accountType,
            isAccountValid,
            bankName,
            accHolderName,
            expiration
          } = data;
          setBeneficiary({
            accHolderName: accHolderName || '',
            accountNumber: accountNumber || '',
            ifscCode: ifscCode || '',
            beneficiaryType: accountType || '',
            bankName: bankName || '',
            isAccountValid: !isAccountValid ? false : isAccountValid,
            expiration: expiration
              ? dayjs(expiration).format('YYYY-MM-DD')
              : dayjs().format('YYYY-MM-DD')
          });
        },
        onComplete: () => {}
      })
    );
  };

  const handleChange = (e) => {
    setBeneficiary((prev) => ({
      ...prev,
      [e?.target?.name]:
        e?.target?.name === 'isAccountValid'
          ? e.target.checked
          : e?.target?.value
    }));
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    onSubmit &&
      onSubmit({
        ...beneficiary,
        expiration: beneficiary?.expiration
          ? dayjs(beneficiary?.expiration).format('YYYY-MM-DD')
          : dayjs().format('YYYY-MM-DD')
      });
  };

  return (
    <>
      <ModalHeader>Update Beneficiary</ModalHeader>
      <form method="POST" onSubmit={handleSubmit}>
        <ModalBody>
          <VStack gap={1} alignItems={'stretch'}>
            {Object.keys(beneficiary).map((key) => {
              if (key === 'beneficiaryType') {
                return (
                  <React.Fragment key={key}>
                    <FormLabel>Select Beneficiary Account Type</FormLabel>
                    <Select
                      name={key}
                      value={beneficiary?.beneficiaryType}
                      placeholder="Select Account Type"
                      onChange={(e) => {
                        setBeneficiary((prev) => ({
                          ...prev,
                          beneficiaryType: e?.target?.value
                        }));
                      }}
                    >
                      {/* 
                        SELF,
                        SELF_NRE,
                        INDIVIDUAL,
                        BUSINESS
                      */}
                      <option value={'SELF'}>Self</option>
                      <option value={'SELF_NRE'}>Self NRE</option>
                      <option value={'INDIVIDUAL'}>Individual</option>
                      <option value={'BUSINESS'}>Business</option>
                    </Select>
                  </React.Fragment>
                );
              } else if (key === 'expiration') {
                return (
                  <React.Fragment key={key}>
                    <FormLabel>{camelCaseToSpaceSeparated(key)}</FormLabel>
                    <Input
                      name={key}
                      type="date"
                      value={beneficiary[key]}
                      onChange={handleChange}
                    />
                  </React.Fragment>
                );
              } else if (key === 'isAccountValid') {
                return (
                  <React.Fragment key={key}>
                    <Checkbox
                      name={key}
                      value={beneficiary[key]}
                      onChange={handleChange}
                    >
                      <Text>{camelCaseToSpaceSeparated(key)}</Text>
                    </Checkbox>
                  </React.Fragment>
                );
              } else {
                return (
                  <React.Fragment key={key}>
                    <FormLabel>{camelCaseToSpaceSeparated(key)}</FormLabel>
                    <Input
                      value={beneficiary[key]}
                      name={key}
                      onChange={handleChange}
                    />
                  </React.Fragment>
                );
              }
            })}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <HStack justifyContent={'end'} gap={2}>
            <Button
              variant={'outline'}
              colorScheme="red"
              type="button"
              onClick={onCancel}
              isDisabled={isLoading}
            >
              Cancel
            </Button>
            <Button
              isLoading={isLoading}
              type="submit"
              variant={'outline'}
              colorScheme="brand"
            >
              Update
            </Button>
          </HStack>
        </ModalFooter>
      </form>
    </>
  );
};

export default UpdateBeneficiartModal;

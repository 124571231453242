import {
  Button,
  FormControl,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  Select,
  VStack
} from '@chakra-ui/react';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NR_ACCOUNT_ONBOARDING_STATUS } from '../../constants';
import { updateNrAccountStatus } from '../../store/actions/nrAccountActions';

const UpdateNrAccountStatus = ({ userId, currentStatus, onClose }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    accountStatus: currentStatus || ''
  });

  const updateAccountStatus = useCallback(
    (formData) => {
      setIsLoading(true);
      dispatch(
        updateNrAccountStatus({
          userId,
          accountStatus: formData.accountStatus,
          onSuccess: onClose,
          onFinish: () => setIsLoading(false)
        })
      );
    },
    [dispatch, onClose, userId]
  );

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      updateAccountStatus(formData);
    },
    [formData, updateAccountStatus]
  );

  const handleChange = useCallback((e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  }, []);

  return (
    <>
      <ModalHeader textAlign={'center'} py={0}>
        Update Account Status
      </ModalHeader>

      <ModalCloseButton />
      <ModalBody>
        <FormControl as={'form'} onSubmit={handleSubmit} px={2}>
          <VStack gap={2} w={'100%'} alignItems={'stretch'}>
            <VStack p={6} pb={2}>
              <Select
                placeholder={'Enter account status'}
                name={'accountStatus'}
                value={formData.accountStatus}
                onChange={handleChange}
                size={'sm'}
                w={'15rem'}
                _focusVisible={{
                  boxShadow: 'brand.200',
                  borderColor: 'brand.200'
                }}
                required
              >
                {Object.keys(NR_ACCOUNT_ONBOARDING_STATUS).map((status) => (
                  <option key={status} value={status}>
                    {status}
                  </option>
                ))}
              </Select>
            </VStack>
          </VStack>
          <ModalFooter justifyContent={'center'}>
            <Button
              colorScheme="brand"
              color="altGray.400"
              mr={3}
              size={'sm'}
              fontSize={'0.75rem'}
              type="submit"
              isLoading={isLoading}
            >
              Submit Now
            </Button>
          </ModalFooter>
        </FormControl>
      </ModalBody>
    </>
  );
};

export default UpdateNrAccountStatus;

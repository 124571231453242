import { toHolidayData } from '../../utils';
import { PROVIDER_HOLIDAYS as types } from '../constants';

const initialState = {
  holidays: {
    isLoading: false,
    isError: false,
    error: null,
    data: null,
    totalPages: 0,
    currentPage: 0
  }
};

const providerHolidaysReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.GETTING_ALL_HOLIDAYS:
      return {
        ...state,
        holidays: {
          ...state.holidays,
          isLoading: true,
          isError: false,
          error: null,
          data: null,
          totalPages: 0,
          currentPage: 0
        }
      };

    case types.GET_ALL_HOLIDAYS_SUCCESS:
      return {
        ...state,
        holidays: {
          ...state.holidays,
          isLoading: false,
          isError: false,
          error: null,
          data:
            payload.providerHolidays?.map((holiday) =>
              toHolidayData(holiday)
            ) || [],
          totalPages: payload.totalPages,
          currentPage: payload.currentPageNumber
        }
      };

    case types.GET_ALL_HOLIDAYS_FAILURE:
      return {
        ...state,
        holidays: {
          ...state.holidays,
          isLoading: false,
          isError: true,
          error: payload,
          data: null,
          totalPages: 0,
          currentPage: 0
        }
      };

    default:
      return {
        ...state
      };
  }
};

export default providerHolidaysReducer;

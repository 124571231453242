import {
  Grid,
  HStack,
  Link,
  Stack,
  StackDivider,
  Text,
  VStack
} from '@chakra-ui/react';
import { BsChevronRight } from 'react-icons/bs';
import { getKycV2DetailsTable } from './utils';
import { Link as ReactLink } from 'react-router-dom';

const KycV2Details = ({ kycData, username, userId, country }) => {
  const kycDetailsList = getKycV2DetailsTable(kycData, userId, country);
  return (
    <Stack
      my={0}
      pt={{ base: 2, xl: 0 }}
      alignSelf={{ base: 'flex-start', xl: 'center' }}
      width={{ base: '100%', xl: 'auto' }}
    >
      <VStack
        p={4}
        border={'1px solid gray'}
        rounded={'lg'}
        w={{ base: '100%', xl: '24rem' }}
        divider={
          <StackDivider style={{ margin: '0' }} borderColor="whiteAlpha.300" />
        }
      >
        <HStack
          alignSelf="start"
          spacing={'2.5rem'}
          justifyContent="space-around"
          mb={{ base: '1.25rem', lg: 0 }}
          width={{ base: '100%', xl: 'auto' }}
          py={3}
        >
          <Text fontSize={'lg'} color="gray">
            User Verifications
          </Text>
        </HStack>
        <VStack w={'100%'} pt={4}>
          {kycDetailsList.map((kyc) => (
            <VStack w={'100%'} key={kyc.title}>
              <Grid
                gridTemplateColumns={'1fr 1fr'}
                justifyContent={'space-between'}
                alignItems={'space-between'}
                w={'100%'}
              >
                <Text color={'gray'}>{kyc.title}</Text>
                {kyc.showLink ? (
                  <HStack justifySelf={'flex-end'}>
                    <Link
                      as={ReactLink}
                      to={kyc.detailsLink}
                      color={kyc.color}
                      justifyContent={'flex-start'}
                      w={'100%'}
                      fontSize={'sm'}
                    >
                      <HStack>
                        <Text>{kyc.value}</Text>
                        <BsChevronRight display={'inline'} />
                      </HStack>
                    </Link>
                  </HStack>
                ) : (
                  <HStack
                    color={kyc.color || 'white'}
                    justifySelf={'flex-end'}
                    fontSize={'sm'}
                  >
                    <Text>{kyc.value}</Text>
                    <BsChevronRight display={'inline'} color={'black'} />
                  </HStack>
                )}
              </Grid>
            </VStack>
          ))}
        </VStack>
      </VStack>
    </Stack>
  );
};

export default KycV2Details;

import api from '../../api';
import { setAlert } from './alertActions';

export const getAllUaeManualPaymentsInLastTenMinutes =
  ({ onSuccess, onComplete, onError }) =>
  async (dispatch) => {
    try {
      const { data } =
        await api.uaeManualPayments.getAllPaymentsInLast10Minutes();
      onSuccess && onSuccess(data);
    } catch (error) {
      onError && onError();
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const LOADING = 'LOADING';
export const RESOLVED = 'RESOLVED';
export const REJECTED = 'REJECTED';
export const IDLE = 'IDLE';
export const KYC_STATUS = {
  E_KYC_PENDING: 'E_KYC_PENDING',
  E_KYC_REJECTED: 'E_KYC_REJECTED',
  M_KYC_PENDING: 'M_KYC_PENDING',
  COMPLETED: 'COMPLETED',
  COMPLETED_NEW: 'COMPLETED_NEW',
  REJECTED: 'REJECTED'
};

export const COUNTRY_CODE = {
  '+44': '+44',
  '+971': '+971',
  '+91': '+91',
  '+49': '+49',
  '+33': '+33',
  '+31': '+31',
  '+351': '+351',
  '+34': '+34',
  '+353': '+353',
  '+39': '+39'
};

export const FULFILLMENT_PROVIDER = {
  XENDPAY: 'XENDPAY',
  YES_BANK: 'YES_BANK',
  THUNES: 'THUNES',
  OFF_RAMP: 'OFF_RAMP'
};

export const CONFIG_COUNTRIES = ['GB', 'AE'];

export const WORKFLOWS = {
  KYC_WORKFLOW: 'KYC_WORKFLOW',
  INVESTMENT_KYC_WORKFLOW: 'INVESTMENT_KYC_WORKFLOW',
  INVESTMENT_ADD_NRI_ACCOUNT_WORKFLOW: 'INVESTMENT_ADD_NRI_ACCOUNT_WORKFLOW',
  EFR_KYC_UAE_WORKFLOW: 'EFR_KYC_UAE_WORKFLOW',
  EXPERT_FOLIO_WORKFLOW: 'EXPERT_FOLIO_WORKFLOW',
  HOME_LOANS_WORKFLOW: 'HOME_LOANS_WORKFLOW',
  NRE_NRO_ONBOARDING_WORKFLOW: 'NRE_NRO_ONBOARDING_WORKFLOW'
};

export const WORKFLOWS_V2 = {
  // REMITTANCE KYC
  KYC_WORKFLOW: 'KYC_WORKFLOW',
  RE_KYC_WORKFLOW: 'RE_KYC_WORKFLOW',
  UAE_KYC_WORKFLOW: 'UAE_KYC_WORKFLOW',

  // Investment kyc
  INVESTMENT_KYC_WORKFLOW: 'INVESTMENT_KYC_WORKFLOW',
  INVESTMENT_ADD_NRI_ACCOUNT_WORKFLOW: 'INVESTMENT_ADD_NRI_ACCOUNT_WORKFLOW',

  // NR_ACCOUNT_OPENING_FINANCIAL_DETAILS: 'NR_ACCOUNT_OPENING_FINANCIAL_DETAILS',
  // NR_ACCOUNT_OPENING_VERIFICATION_DOCUMENTS:
  //   'NR_ACCOUNT_OPENING_VERIFICATION_DOCUMENTS',
  // USER_ONBOARDING_BASIC_DETAILS: 'USER_ONBOARDING_BASIC_DETAILS',
  // NR_ACCOUNT_OPENING_FINANCIAL_DETAILS: 'NR_ACCOUNT_OPENING_FINANCIAL_DETAILS',
  // NR_ACCOUNT_OPENING_VERIFICATION_DOCUMENTS:
  //   'NR_ACCOUNT_OPENING_VERIFICATION_DOCUMENTS',
  // UAE_NR_ACCOUNT_OPENING_FINANCIAL_DETAILS:
  //   'UAE_NR_ACCOUNT_OPENING_FINANCIAL_DETAILS',
  // UAE_NR_ACCOUNT_OPENING_VERIFICATION_DOCUMENTS:
  //   'UAE_NR_ACCOUNT_OPENING_VERIFICATION_DOCUMENTS',
  // UAE_NR_ONBOARDING_REMITTANCE_VERIFICATION:
  //   'UAE_NR_ONBOARDING_REMITTANCE_VERIFICATION',

  // Home loan workflow
  HOME_LOAN_WORKFLOW: 'HOME_LOAN_WORKFLOW',

  // Expert folio workflow
  EXPERT_FOLIO_WORKFLOW: 'EXPERT_FOLIO_WORKFLOW',

  // NR Onboarding workflow
  // NRE_NRO_WAITLIST_WORKFLOW: 'NRE_NRO_WAITLIST_WORKFLOW',
  UK_NR_WAITLIST_WORKFLOW: 'UK_NR_WAITLIST_WORKFLOW',
  UAE_NR_WAITLIST_WORKFLOW: 'UAE_NR_WAITLIST_WORKFLOW'

  // // Monetization
  // INVEST_IN_FRE_AND_ASSET_LEASING_SURVEY:
  //   'INVEST_IN_FRE_AND_ASSET_LEASING_SURVEY',
  // INVEST_IN_24K_GOLD_SURVEY: 'INVEST_IN_24K_GOLD_SURVEY',
  // INVEST_IN_P2P_LENDING_SURVEY: 'INVEST_IN_P2P_LENDING_SURVEY',
  // INVEST_IN_ULIP_SURVEY: 'INVEST_IN_ULIP_SURVEY'
};

export const WAITLIST_ADDITIONAL_DETAILS = {
  UAE_NR_WAITLIST_ADDITIONAL_DETAIL_SCREEN_ID:
    'UAE_NR_WAITLIST_ADDITIONAL_DETAIL_SCREEN_ID',
  UK_NR_WAITLIST_ADDITIONAL_DETAIL_SCREEN_ID:
    'UK_NR_WAITLIST_ADDITIONAL_DETAIL_SCREEN_ID'
};

export const ALL_LULU_ORDERS_FUNNEL_TABS = [
  'INITIATED',
  'FUNDS_OUT',
  'COMPLETED',
  'FAILED'
];

export const ALL_FUNNEL_ORDERS_STATUS = [
  // 'initiated',
  // 'executed',
  'agreed',
  'booked',
  'funds_in',
  'funds_out'
];

export const ALL_FUNNEL_V2_ORDERS_STATUS = [
  'CREATED',
  'PROCESSING_DEAL_IN',
  'COMPLETED',
  'FAILED',
  'ON_HOLD'
];

export const ALL_FUNNEL_KYC_STATUS = [
  'NEW',
  'E_KYC_PENDING',
  'E_KYC_REJECTED',
  'M_KYC_PENDING',
  'COMPLETED',
  'REJECTED'
];

export const KYC_REJECTION_REASONS = {
  ADDRESS_DO_NOT_MATCH: 'Address on document should match',
  PROVISIONAL_DL_NOT_ACCEPTED: 'Provisional DL is not accepted',
  DIGITAL_SCANNED_COPY: 'Digitally scanned copy is not accepted',
  INCORRECT_DOCUMENT: 'Document uploaded should be legible',
  NAME_NOT_MATCH: 'Name on document should match',
  SCREENSHOT: 'Screenshot is not accepted',
  WRONG_DOCUMENT: 'Wrong document uploaded',
  OTHER: 'OTHER'
};

export const ROLES = {
  ADMIN_ROLE: 'ADMIN_ROLE',
  MANAGER_ROLE: 'MANAGER_ROLE',
  SUPPORT_ROLE: 'SUPPORT_ROLE',
  USER_ROLE: 'USER_ROLE',
  CONFIG_MANAGER_ROLE: 'CONFIG_MANAGER_ROLE',
  YBL_MANAGER_ROLE: 'YBL_MANAGER_ROLE',
  ORDER_MANAGER_ROLE: 'ORDER_MANAGER_ROLE',
  OPS_ROLE: 'OPS_ROLE',
  OPS_MANAGER_ROLE: 'OPS_MANAGER_ROLE',
  REFERRAL_MANAGER: 'REFERRAL_MANAGER',
  FULFILMENT_REINITIATE: 'FULFILMENT_REINITIATE',
  ACCESS_MANAGER: 'ACCESS_MANAGER'
};

export const FILTER_BY_FLAG = {
  FILTERED: 'filtered',
  UNFILTERED: 'unfiltered'
};

export const NR_ACCOUNT_ONBOARDING_STATUS = {
  ACCOUNT_NOT_INITIATED: 'ACCOUNT_NOT_INITIATED',
  ONBOARDING: 'ONBOARDING',
  UNDER_REVIEW: 'UNDER_REVIEW',
  SIGN_APPLICATION_FORM: 'SIGN_APPLICATION_FORM',
  PICK_UP_SCHEDULED: 'PICK_UP_SCHEDULED',
  DOCUMENTS_PICKED_UP: 'DOCUMENTS_PICKED_UP',
  DOCUMENTS_REACHED: 'DOCUMENTS_REACHED',
  INITIAL_TRANSACTION: 'INITIAL_TRANSACTION',
  NR_ACCOUNT_CREATED: 'NR_ACCOUNT_CREATED'
};

export const NR_ACCOUNT_SEARCH_KEYS = {
  ACCOUNT_STATUS: {
    displayText: 'ACCOUNT_STATUS',
    key: 'accountStatus'
  }
};

export const NR_ONBOARDING_SCREEN_FILLING_STATUS = {
  FILLED: 'FILLED',
  FILLING: 'FILLING'
};

export const REJECT_KYC_OPTIONS = ['POI', 'POA'];

export const KYC_PROVIDER = {
  COMPLY_CUBE: 'COMPLY_CUBE',
  SABER: 'SABER',
  RFX: 'RFX',
  TARRAKKI: 'TARRAKKI',
  EFR: 'EFR',
  ON_RAMP_MONEY: 'ON_RAMP_MONEY',
  ENCRYPTUS: 'ENCRYPTUS',
  BAERSCREST: 'BAERSCREST'
};

export const PRODUCT = {
  REMITTANCE: 'REMITTANCE',
  INVEST_MF: 'INVEST_MF',
  INVEST_MF_ADD_NRI_BANK: 'INVEST_MF_ADD_NRI_BANK'
};

export const ORDER_PRODUCTS = {
  REMITTANCE: 'REMITTANCE',
  INVEST_MF: 'INVEST_MF',
  INVEST_MF_SIP: 'INVEST_MF_SIP'
};

export const KYC_V2_STATUS = {
  NEW: 'NEW',
  PENDING: 'PENDING',
  VERIFIED: 'VERIFIED',
  REJECTED: 'REJECTED',
  RE_KYC_REQUIRED: 'RE_KYC_REQUIRED',
  RE_KYC_PENDING: 'RE_KYC_REQUIRED'
};

export const SOURCE_ACCOUNTS_STATUS = {
  NOT_INITIATED: 'NOT_INITIATED',
  PENDING_VERIFICATION: 'PENDING_VERIFICATION',
  ACTIVE: 'ACTIVE'
};

export const KYC_DOC_STATUS = {
  DRAFT: 'DRAFT',
  SENT_TO_PROVIDER: 'SENT_TO_PROVIDER',
  PENDING_FOR_VERIFICATION: 'PENDING_FOR_VERIFICATION',
  VERIFIED: 'VERIFIED',
  COMPLETED: 'COMPLETED',
  REJECTED: 'REJECTED'
};

export const KYC_CHECK_STATUS = {
  NEW: 'new',
  PENDING: 'pending',
  COMPLETE: 'complete',
  FAILED: 'failed'
};

export const KYC_CHECK_OUTCOME = {
  CLEAR: 'clear',
  ATTENTION: 'attention'
};

export const DASHBOARD_ACTIVE_SEARCH = {
  users: 'users',
  orders: 'orders',
  investments: 'investments',
  lulu: 'lulu'
};

export const VERIFICATIONS_ACTIVE_SEARCH = {
  REMITTANCE: 'REMITTANCE',
  INVESTMENTS: 'INVESTMENTS'
};

export const INVESTMENT_ORDER_SEARCH_FILTERS = {
  TYPE: {
    key: 'TYPE',
    text: 'Order Type',
    filterValues: {
      BUY: 'BUY',
      SELL: 'SELL'
    }
  },
  STATUS: {
    key: 'STATUS',
    text: 'Status',
    filterValues: {
      NEW: 'NEW',
      CREATED: 'CREATED',
      PENDING_PAYMENT: 'PENDING_PAYMENT',
      PROCESSING: 'PROCESSING',
      COMPLETED: 'COMPLETED',
      FAILED: 'FAILED',
      ON_HOLD: 'ON_HOLD'
    }
  }
};

export const JOB_FILTERS = {
  TYPE: {
    FILTER_KEY: 'TYPE',
    FILTER_VALUES: [
      'INVESTOR_STATUS_TYPE',
      'PAN_STATUS_TYPE',
      'ORDER_STATUS_TYPE',
      'INVESTMENT_PAYMENT_TYPE',
      'ADD_FREE_TRANSFER_TYPE',
      'OTHER_TYPE'
    ]
  },

  STATUS: {
    FILTER_KEY: 'STATUS',
    FILTER_VALUES: [
      'CREATED',
      'READY_FOR_EXECUTION',
      'TASK_EXECUTION_STARTED',
      'TASK_EXECUTION_FAILED',
      'POST_TASK_EXECUTION_STARTED',
      'POST_TASK_EXECUTION_FAILED',
      'COMPLETED',
      'FAILED',
      'CLOSED'
    ]
  }
};

export const TERMINAL_JOB_STATUS = ['FAILED', 'CLOSED'];

export const CARD_TYPE = {
  GOLDEN_TICKET_CARD: 'GOLDEN_TICKET_CARD',
  LULU_ORDER_CARD: 'LULU_ORDER_CARD',
  LULU_FUNNEL_ORDER_CARD: 'LULU_FUNNEL_ORDER_CARD',
  JOB_CARD: 'JOB_CARD',
  REDEEMED_COINS_CARD: 'REDEEMED_COINS_CARD',
  OPS_TICKET_CARD: 'OPS_TICKET_CARD',
  NRONBOARD: 'NRONBOARD',
  VDA_FUNNEL: 'VDA_FUNNEL'
};

export const ORDER_TYPE = {
  MANUAL_PAYMENT: 'MANUAL_PAYMENT',
  REMITTANCE: 'REMITTANCE'
};

export const NR_ONBOARDING_STATUS = [
  'NEW',
  'FILLING_FORM',
  'FILLED_FORM',
  'SCHEDULE_PICKUP',
  'PICKUP_REQUEST_RAISED',
  'PICKUP_SCHEDULED',
  'ACCOUNT_DEBIT_FREEZE',
  'ACCOUNT_TRANSFER_PROCESSING',
  'ACCOUNT_READY'
];

export const YBL_ONBOARDING_DOCS = {
  PASSPORT_FRONT: 'PASSPORT_FRONT',
  PASSPORT_BACK: 'PASSPORT_BACK',
  BRP_FRONT: 'BRP_FRONT',
  BRP_BACK: 'BRP_BACK',
  BANK_STATEMENT: 'BANK_STATEMENT',
  SELFIE: 'SELFIE',
  SIGNATURE: 'SIGNATURE'
};

export const CASH_REWARD_STATUS = {
  PENDING: 'PENDING',
  COMPLETED: 'COMPLETED'
};

export const ORDER_ACQUIRER = {
  LEANTECH: 'LEANTECH',
  UAE_MANUAL: 'UAE_MANUAL',
  THUNES_COLLECTION: 'THUNES_COLLECTION',
  TRUELAYER_SABER: 'TRUELAYER_SABER',
  LEANTECH_ON_RAMP: 'LEANTECH_ON_RAMP',
  PLAID: 'PLAID',
  TRUELAYER: 'TRUELAYER',
  VANCE: 'VANCE',
  CHECKOUT: 'CHECKOUT',
  CYBRID_PLAID: 'CYBRID_PLAID'
};

export const ORDER_TYPE_V2 = {
  REMITTANCE: 'REMITTANCE',
  NRE_INITIAL_FUNDING: 'NRE_INITIAL_FUNDING',
  NRO_INITIAL_FUNDING: 'NRO_INITIAL_FUNDING',
  FULFILLMENT_ONLY: 'FULFILLMENT_ONLY',
  MANUAL_PAYMENT: 'MANUAL_PAYMENT'
};

// For Funnel Orders - '/funnel/orders'
export const FILTER_BY_PAST_HOURS = [1, 4, 8, 12, 16, 20, 24, 48, 72, 96, ''];

export const TICKET_TYPES = {
  PENDING_ORDER: 'PENDING_ORDER',
  NR_ONBOARD: 'NR_ONBOARD'
};

export const TICKET_STATUS = {
  UNRESOLVED: 'UNRESOLVED',
  RESOLVED: 'RESOLVED'
};

export const TICKET_TYPE_TO_INDEX_MAPPER = Object.keys(TICKET_TYPES).reduce(
  (prev, ticketType, ind) => ({
    ...prev,
    [ticketType]: ind
  }),
  {}
);

export const INDEX_TO_TICKET_TYPE_MAPPER = Object.keys(TICKET_TYPES).reduce(
  (prev, ticketType) => {
    const index = TICKET_TYPE_TO_INDEX_MAPPER[ticketType];
    return {
      ...prev,
      [index]: ticketType
    };
  },
  {}
);

/* Used for rejection reason on KYC */
export const KYC_REJECT_REASONS_V2 = {
  INVALID_PASSPORT_OR_DL: 'INVALID_PASSPORT_OR_DL',
  PASSPORT_OR_DL_NOT_CLEAR: 'PASSPORT_OR_DL_NOT_CLEAR',
  INVALID_NATIONALITY_PROOF: 'INVALID_NATIONALITY_PROOF',
  SELFIE_NOT_CLEAR: 'SELFIE_NOT_CLEAR',
  USER_ALREADY_EXISTS: 'USER_ALREADY_EXISTS'
};

export const NR_OB_UK_STAGES = {
  APPLICATION: 'APPLICATION',
  PICKUP: 'PICKUP',
  SIM_VERIFICATION: 'SIM_VERIFICATION',
  ACCOUNT: 'ACCOUNT'
  // DELIVERY: 'DELIVERY',
};

export const NR_OB_UAE_STAGES = {
  APPLICATION: 'APPLICATION',
  APPOINTMENT: 'APPOINTMENT',
  SIM_VERIFICATION: 'SIM_VERIFICATION',
  ACCOUNT: 'ACCOUNT'
};

export const NR_ON_BOARD_REJECT_REASONS = {
  INVALID_PASSPORT: 'INVALID_PASSPORT'
};

export const NR_ON_BOARD_TYPE = {
  MANUAL: 'MANUAL',
  IN_APP: 'IN_APP'
};

export const NR_OB_COUNTRY = {
  GB: 'GB',
  AE: 'AE'
};

/*  
  NEW,
  ADDRESS_VERIFIED,
  SIGNED_DOCS_PICKUP_REQUESTED,
  SIGNED_DOCS_PICKUP_UNAVAILABLE,
  SIGNED_DOCS_PICKUP_SCHEDULED,
  SIGNED_DOCS_PICKUP_COMPLETED,
  SIGNED_DOCS_PICKUP_FAILED 
*/
export const NROB_PICKUP_STATUS = {
  SIGNED_DOCS_PICKUP_REQUESTED: 'SIGNED_DOCS_PICKUP_REQUESTED',
  SIGNED_DOCS_PICKUP_SCHEDULED: 'SIGNED_DOCS_PICKUP_SCHEDULED',
  SIGNED_DOCS_PICKUP_UNAVAILABLE: 'SIGNED_DOCS_PICKUP_UNAVAILABLE'
};

export const NROB_PICKUP_ACTIONS = {
  SIGNED_DOCS_PICKUP_FAILED: 'SIGNED_DOCS_PICKUP_FAILED',
  SIGNED_DOCS_PICKUP_COMPLETED: 'SIGNED_DOCS_PICKUP_COMPLETED'
};

/* 
  NEW,
  ADDRESS_VERIFIED,
  APPOINTMENT_REQUESTED,
  APPOINTMENT_UNAVAILABLE,
  APPOINTMENT_SCHEDULED,
  APPOINTMENT_COMPLETED,
  APPOINTMENT_MISSED,
*/
export const NROB_APPOINTMENT_STATUS = {
  NEW: 'NEW',
  ADDRESS_VERIFIED: 'ADDRESS_VERIFIED',
  APPOINTMENT_REQUESTED: 'APPOINTMENT_REQUESTED',
  APPOINTMENT_SCHEDULED: 'APPOINTMENT_SCHEDULED',
  APPOINTMENT_UNAVAILABLE: 'APPOINTMENT_UNAVAILABLE'
};

export const NROB_APPOINTMENT_ACTIONS = {
  APPOINTMENT_MISSED: 'APPOINTMENT_MISSED',
  APPOINTMENT_COMPLETED: 'APPOINTMENT_COMPLETED'
};

export const NROB_DELIVERY_STATUS = {
  DOCS_DELIVERY_REQUESTED: 'DOCS_DELIVERY_REQUESTED',
  ADDRESS_VERIFIED: 'ADDRESS_VERIFIED',
  DOCS_DELIVERY_FAILED: 'DOCS_DELIVERY_FAILED'
};

export const NROB_DELIVERY_ACTIONS = {
  DOCS_DELIVERY_FAILED: 'DOCS_DELIVERY_FAILED',
  DOCS_DELIVERED: 'DOCS_DELIVERED'
};

export const NROB_DOCS_TO_YBL_STATUS = {
  NEW: 'NEW',
  SENT_TO_YBL: 'SENT_TO_YBL'
};
export const NROB_DOCS_TO_YBL_ACTIONS = {
  COMPLETED: 'COMPLETED'
};

export const NROB_ACTIONS = {
  DELIVERY: 'DELIVERY',
  APPOINTMENT: 'APPOINTMENT',
  PICKUP: 'PICKUP',
  DOCS_DELIVERY_TO_YBL: 'DOCS_DELIVERY_TO_YBL'
};

export const NROB_ATTESTED_DOCS = {
  NEW: 'NEW',
  DISPATCHED: 'DISPATCHED',
  DELIVERED: 'DELIVERED'
};

/* 
  NEW,
  BLOCKED,
  FORM_FILLED, (enable documents rejection , address verificaiton)
  ADDRESS_VERIFIED, (enable generate YBL form, only if KYC is verified, disable all docs rejection, hide verify address button)
  FORM_REJECTED, (same as new, dont show any address verifcaion / generate ybl form screen etc)
  FORM_REVIEWED, // marked as OK on alphadesk (hide generate ybl button, disable docs rejection, hide verify address buton)
  APPLICATION_SENT_TO_PROVIDER, // YBL journey completed, under review by YBL (disable trigger apis and update form buttons, show main text -> Application under review, PLease wait)
  FORM_VERIFIED, (flow completed, disable all action buttons in application tab)
*/

export const NROB_STATES = {
  NEW: 'NEW',
  BLOCKED: 'BLOCKED',
  FORM_FILLED: 'FORM_FILLED',
  WAITLIST_FORM_FILLED: 'WAITLIST_FORM_FILLED',
  DOCUMENTS_VERIFIED: 'DOCUMENTS_VERIFIED',
  ADDRESS_VERIFIED: 'ADDRESS_VERIFIED',
  FORM_REJECTED: 'FORM_REJECTED',
  FORM_REVIEWED: 'FORM_REVIEWED', // marked as OK on alphadesk
  APPLICATION_SENT_TO_PROVIDER: 'APPLICATION_SENT_TO_PROVIDER',
  FORM_VERIFIED: 'FORM_VERIFIED'
};

export const NROB_USER_TYPES = {
  NR_WAITLIST: 'NR_WAITLIST'
};

export const NR_OB_UK_REQ_DOCS = [
  'PASSPORT_FRONT',
  'PASSPORT_BACK',
  'BRP_FRONT',
  'BRP_BACK',
  'SELFIE',
  'SIGNATURE',
  'BANK_STATEMENT',
  'UTILITY_BILL',
  'TENANCY_AGREEMENT',
  'OCI_PIO_CARD'
];

export const NR_OB_UAE_REQ_DOCS = [
  'PASSPORT_FRONT',
  'PASSPORT_BACK',
  'SELFIE',
  'SIGNATURE',
  'BANK_STATEMENT',
  'EMIRATES_ID',
  'UTILITY_BILL',
  'TENANCY_AGREEMENT',
  'OCI_PIO_CARD'
];

export const NROB_MANDATORY_DOCS = [
  'PASSPORT_FRONT',
  'PASSPORT_BACK',
  'BRP_FRONT',
  'BRP_BACK',
  'SELFIE',
  'SIGNATURE',
  'BANK_STATEMENT',
  'EMIRATES_ID'
];

/* NRonboard funnel states */

export const NROB_FUNNEL_STATES = {
  FILLING_FORM: 'FILLING_FORM',
  FORM_REJECTED: 'FORM_REJECTED',
  PENDING_FORM_VERIFICATION: 'PENDING_FORM_VERIFICATION',
  PROVIDER_VERIFICATION_PENDING: 'PROVIDER_VERIFICATION_PENDING',
  DOCS_PICKUP_NOT_REQUESTED: 'DOCS_PICKUP_NOT_REQUESTED',
  APPOINTMENT_NOT_REQUESTED: 'APPOINTMENT_NOT_REQUESTED',
  DOCS_PICKUP_PENDING: 'DOCS_PICKUP_PENDING',
  DOCS_DELIVERY_TO_YBL_PENDING: 'DOCS_DELIVERY_TO_YBL_PENDING',
  APPOINTMENT_PENDING: 'APPOINTMENT_PENDING',
  SIM_VERIFICATION_PENDING: 'SIM_VERIFICATION_PENDING',
  INITIAL_FUNDING_PENDING: 'INITIAL_FUNDING_PENDING',
  INITIAL_FUNDING_PROCESSING: 'INITIAL_FUNDING_PROCESSING',
  INITIAL_FUNDING_COMPLETED: 'INITIAL_FUNDING_COMPLETED',
  COMPLETED: 'COMPLETED'
};

export const ORDER_SCREEN_CONF_COMP_TYPES = {
  INPUT_FIELD: 'INPUT_FIELD',
  DROP_DOWN: 'DROP_DOWN',
  UPLOAD_DOC: 'UPLOAD_DOC'
};

export const BENEFICIARY_RISK_REQUEST = {
  DEFAULT_RISK: 'DEFAULT_RISK',
  HIGH_RISK: 'HIGH_RISK',
  MEDIUM_RISK: 'MEDIUM_RISK',
  LOW_RISK: 'LOW_RISK'
};

export const NROB_ACTIVE_STEPS = {
  // NEW: 'NEW',
  ELIGIBLE: 'ELIGIBLE',
  // BLOCKED: 'BLOCKED',
  APPLICATION_PROCESSING: 'APPLICATION_PROCESSING',
  SIM_VERIFICATION_AND_PERMISSIONS: 'SIM_VERIFICATION_AND_PERMISSIONS',
  ACCOUNT_INITIAL_FUNDING: 'ACCOUNT_INITIAL_FUNDING',
  DOCUMENTS_PENDING: 'DOCUMENTS_PENDING',
  ONBOARDING_COMPLETED: 'ONBOARDING_COMPLETED'
};

export const RDA_PROVIDERS = {
  THUNES: 'THUNES',
  YES_BANK_RDA: 'YES_BANK_RDA'
};

export const CONFIG_PROVIDERS = {
  UK_OFF_RAMP: 'UK_OFF_RAMP'
};

export const CONFIG_PROVIDER_LOOKUP = {
  GB: [CONFIG_PROVIDERS.UK_OFF_RAMP],
  AE: []
};

export const FEATURE_FLAGS = {
  SABER_GBP_TO_USDT_CONVERSION_RATE: 'SABER_GBP_TO_USDT_CONVERSION_RATE'
};

export const RECEIPT_REJECTION_REASONS = {
  SCREENSHOT:
    'Looks like you uploaded a screenshot of the Vance payment screen. Please upload the BRN receipt provided by your bank.',
  UNCLEAR:
    'The uploaded file is unclear and missing important information. Please upload a clearer version of the BRN receipt.',
  PASSWORD_PROTECTED:
    'The BRN uploaded is password-protected. Please remove file protection and upload the BRN receipt again.',
  INVALID_DOCUMENT:
    'The document you uploaded is not a BRN receipt. Please upload a valid BRN receipt.'
};
export const ORDER_STATUS = {
  PENDING: 'PENDING',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED'
};
export const FILTER_BY_STATUS = {
  NEW: 'NEW',
  PROCESSING: 'PROCESSING',
  COMPLETED: 'COMPLETED'
};

export const REWARD_TYPE = {
  KYC_INCENTIVIZE: 'KYC_INCENTIVIZE',
  RE_KYC_INCENTIVIZE: 'RE_KYC_INCENTIVIZE',
  UAE_OFFLINE_20AED_NOTE: 'UAE_OFFLINE_20AED_NOTE',
  INFLUENCER_REEL_REWARD: 'INFLUENCER_REEL_REWARD',
  FESTIVE_BONANZA: 'FESTIVE_BONANZA',
  DIWALI_REWARD: 'DIWALI_REWARD',
  UAE_FIRST_TRANSFER_CASHBACK: 'UAE_FIRST_TRANSFER_CASHBACK',
  GOLD_REWARD: 'GOLD_REWARD'
};

export const REWARD_STATUS = {
  INELIGIBLE: 'INELIGIBLE',
  CREATED: 'CREATED', // created but not yet eligible, will get eligible once eligibility_starts_at expires
  ELIGIBLE: 'ELIGIBLE',
  SUBMITTED: 'SUBMITTED',
  EXPIRED: 'EXPIRED',
  COMPLETED: 'COMPLETED'
};

export const RECON_STATUS = {
  PAYMENT_PENDING: 'PAYMENT_PENDING',
  PAYMENT_FAILED: 'PAYMENT_FAILED',
  RECON_PENDING: 'RECON_PENDING',
  MANUAL_RECON_PENDING: 'MANUAL_RECON_PENDING',
  RECON_COMPLETED: 'RECON_COMPLETED',
  RECON_FAILED: 'RECON_FAILED'
};

import { VStack, Text } from '@chakra-ui/react';

const FeatureDetail = ({ featureName, featureValue }) => {
  return (
    <VStack alignItems={'stretch'}>
      <Text color={'gray.500'} fontSize={'sm'}>
        {featureName}
      </Text>
      <Text color={'white'} fontSize={'md'}>
        {featureValue || '-'}
      </Text>
    </VStack>
  );
};

export default FeatureDetail;

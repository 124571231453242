import {
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper
} from '@chakra-ui/react';
import React from 'react';

const NumberInputComponent = ({
  handleChange,
  value,
  min = 0,
  required = false,
  step = 1,
  showStepper = false
}) => {
  return (
    <NumberInput
      size="lg"
      color="white"
      rounded={'lg'}
      focusBorderColor="brand.400"
      required={required}
      autoFocus={false}
      border={'none'}
      errorBorderColor="red.300"
      min={min}
      step={step}
      style={{
        border: 0,
        background: '#414141',
        gap: '12px',
        width: '100%',
        maxWidth: '30rem',
        margin: '0',
        borderRadius: '12px'
      }}
      onChange={handleChange}
      value={value}
    >
      <NumberInputField
        onChange={handleChange}
        boxShadow={'none !important'}
        style={{
          boxShadow: 'none',
          border: 'none',
          borderRadius: '12px'
        }}
      />
      {showStepper && (
        <NumberInputStepper>
          <NumberIncrementStepper border={'none'} color={'white'} />
          <NumberDecrementStepper border={'none'} color={'white'} />
        </NumberInputStepper>
      )}
    </NumberInput>
  );
};

export default NumberInputComponent;

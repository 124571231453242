import { KYC_PROVIDER, PRODUCT } from '../../constants';

export default function kycApi(fetch, baseUrl) {
  return {
    docs({
      provider = KYC_PROVIDER.COMPLY_CUBE,
      product = PRODUCT.REMITTANCE,
      pageNo = 0,
      pageSize = 10,
      userId
    }) {
      return fetch(
        `${baseUrl}/user/${userId}/kyc/details?provider=${provider}&product=${product}&page=${pageNo}&page_size=${pageSize}`
      );
    },

    userKyc({ userId, product, provider }) {
      return fetch(
        `${baseUrl}/user/${userId}/kyc?product=${product}&provider=${provider}`
      );
    },

    checks({ pageNo = 0, pageSize = 10, userId }) {
      return fetch(
        `${baseUrl}/user/${userId}/kyc/checks?&page=${pageNo}&page_size=${pageSize}`
      );
    },

    updateKycStatus(formData, userId) {
      return fetch.put(
        `${baseUrl}/user/${userId}/v2/kyc/details`,
        // `${baseUrl}/user/${userId}/kyc/details`,
        Object.keys(formData).reduce((prev, curr) => {
          if (!formData[curr]) return prev;
          return {
            ...prev,
            [curr]: formData[curr]
          };
        }, {})
      );
    },

    syncUserKyc({ userId, product, provider }) {
      return fetch.get(
        `${baseUrl}/user/${userId}/kyc/sync?product=${product}&provider=${provider}`
      );
    },

    getKycFile(url, userId) {
      return fetch.get(`${baseUrl}/user/${userId}/kyc/docs/${url}`);
    },

    allKycs({ provider, product, status, pageNo, pageSize, query, country }) {
      return fetch.get(
        `${baseUrl}/kyc?provider=${provider}&product=${product}${
          status?.length ? `&status=${status}` : ''
        }&page=${pageNo}&page_size=${pageSize}&country=${country}${
          query?.length ? `&query=${query}` : ''
        }`
      );
    },

    updateCheckStatus({ checkId }) {
      return fetch.put(`${baseUrl}/kyc/checks/${checkId}`);
    },

    createEKyc({ userId }) {
      return fetch.post(`${baseUrl}/user/${userId}/kyc/e-kyc`);
    },

    createMKyc({ userId }) {
      return fetch.post(`${baseUrl}/user/${userId}/kyc/m-kyc`);
    },

    rejectEfrKyc({ userId, data }) {
      return fetch.put(`${baseUrl}/reject-efr-kyc/${userId}`, data);
    }
  };
}

export default function orderScreenConfigApi(fetch, baseUrl) {
  return {
    getScreenConfig({ orderId }) {
      return fetch.get(`${baseUrl}/${orderId}`);
    },
    postScreenConfig({ orderId, configData }) {
      return fetch.post(`${baseUrl}/${orderId}`, { ...configData });
    }
  };
}

import { REQ_DATA as types } from '../constants';

export const updateUsersReqData = (reqData) => ({
  type: types.UPDATE_USERS_REQ_DATA,
  payload: reqData
});

export const updateLuluOrdersReqData = (reqData) => ({
  type: types.UPDATE_LULU_ORDERS_REQ_DATA,
  payload: reqData
});

export const updateOrdersReqData = (reqData) => ({
  type: types.UPDATE_ORDERS_REQ_DATA,
  payload: reqData
});

export const updateActiveSearch = (search) => ({
  type: types.UPDATE_ACTIVE_SEARCH,
  payload: search
});

export const updateActiveOrdersFunnel = (funnel) => ({
  type: types.UPDATE_ACTIVE_ORDERS_FUNNEL,
  payload: funnel
});

export const updateActiveLuluOrdersTab = (tab) => ({
  type: types.UPDATE_ACTIVE_LULU_ORDERS_TAB,
  payload: tab
});

export const updateActiveKycFunnel = (funnel) => ({
  type: types.UPDATE_ACTIVE_KYC_USERS_FUNNEL,
  payload: funnel
});

export const updateFunnelOrdersReqData = ({ status, pageNo }) => ({
  type: types.UPDATE_FUNNEL_ORDERS_REQ_DATA,
  payload: {
    status,
    reqData: {
      pageNo
    }
  }
});

export const updateLuluFunnelOrdersReqData = ({ tab, pageNo }) => ({
  type: types.UPDATE_LULU_FUNNEL_ORDERS_REQ_DATA,
  payload: {
    tab,
    reqData: {
      pageNo
    }
  }
});

export const updateFunnelFilterByFlag = ({ status, filterByFlag }) => ({
  type: types.UPDATE_FUNNEL_FLAGGED_FILTER,
  payload: {
    status,
    filterByFlag
  }
});

export const updateFunnelKycUsersReqData = ({ status, pageNo }) => ({
  type: types.UPDATE_FUNNEL_USERS_REQ_DATA,
  payload: {
    status,
    reqData: {
      pageNo
    }
  }
});

export const updateCampaignsReqData = (reqData) => ({
  type: types.UPDATE_CAMPAIGNS_REQ_DATA,
  payload: reqData
});

export const updateNrUsersReqData = (reqData) => ({
  type: types.UPDATE_NR_USERS_REQ_DATA,
  payload: reqData
});

export const updateProviderHolidaysReqData = (reqData) => ({
  type: types.UPDATE_PROVIDER_HOLIDAYS_REQ_DATA,
  payload: reqData
});

export const updateKycDocsReqData = (reqData) => ({
  type: types.UPDATE_KYC_DOCS_REQ_DATA,
  payload: reqData
});

export const updateKycChecksReqData = (reqData) => ({
  type: types.UPDATE_KYC_CHECKS_REQ_DATA,
  payload: reqData
});

export const updateKycsReqData = (reqData) => ({
  type: types.UPDATE_ALL_KYCS_REQ_DATA,
  payload: reqData
});

// export const updateUserKycReqData = (reqData) => ({
//   type: types.UPDATE_USER_KYC_REQ_DATA,
//   payload: reqData
// });

export const updateInvestmentOrdersReqData = (reqData) => ({
  type: types.UPDATE_INVESTMENT_ORDERS_REQ_DATA,
  payload: reqData
});

export const updateUserInvestmentOrdersReqData = (reqData) => ({
  type: types.UPDATE_USER_INVESTMENT_ORDERS_REQ_DATA,
  payload: reqData
});

export const updateUserRemittanceOrdersReqData = (reqData) => ({
  type: types.UPDATE_USER_TRANSFERS_REQ_DATA,
  payload: reqData
});

export const updateUserReferralReqData = (reqData) => ({
  type: types.UPDATE_USER_REFERRAL_REQ_DATA,
  payload: reqData
});

export const updateUserSipsReqData = (reqData) => ({
  type: types.UPDATE_USER_SIPS_REQ_DATA,
  payload: reqData
});

export const updateThunesReqData = (reqData) => ({
  type: types.UPDATE_THUNES_ORDERS_REQ_DATA,
  payload: reqData
});

export const updateThunesFulfillmentsReqData = (reqData) => ({
  type: types.UPDATE_THUNES_FULFILLMENTS_REQ_DATA,
  payload: reqData
});

export const updateSourceAccountReqData = (reqData) => ({
  type: types.UPDATE_SOURCE_ACCOUNTS_REQ_DATA,
  payload: reqData
});

export const updateJobsReqData = (reqData) => ({
  type: types.UPDATE_JOBS_REQ_DATA,
  payload: reqData
});

export const updateRedeemedCoinsReqData = (reqData) => ({
  type: types.UPDATE_REDEEMED_COINS_REQ_DATA,
  payload: reqData
});

export const updateUserRedeemedCoinsReqData = (reqData) => ({
  type: types.UPDATE_USER_REDEEMED_COINS_REQ_DATA,
  payload: reqData
});

export const updateFunnelV2ReqData = (reqData) => ({
  type: types.UPDATE_FUNNEL_V2_REQ_DATA,
  payload: reqData
});

export const updateFunnelV2OrdersReqData = (status, reqData) => ({
  type: types.UPDATE_FUNNEL_V2_ORDERS_REQ_DATA,
  payload: {
    status,
    reqData
  }
});

export const updateTicketsReqData = (reqData) => ({
  type: types.UPDATE_TICKETS_REQ_DATA,
  payload: {
    ...reqData
  }
});

export const updateNrobReqData = (reqData) => ({
  type: types.UPDATE_NROB_REQ_DATA,
  payload: {
    ...reqData
  }
});

export const updateUserSourceAccountsReqData = (reqData) => ({
  type: types.UPDATE_USER_SOURCE_ACCOUNTS_REQ_DATA,
  payload: {
    ...reqData
  }
});

export const updateRdaReqData = (reqData) => ({
  type: types.UPDATE_RDA_REQ_DATA,
  payload: {
    ...reqData
  }
});

export const updateVanceCashReqData = (reqData) => ({
  type: types.UPDATE_VANCE_CASH_REQ_DATA,
  payload: {
    ...reqData
  }
});

export const updateVdaFunnelReqData = (reqData) => ({
  type: types.UPDATE_VDA_FUNNEL_REQ_DATA,
  payload: {
    ...reqData
  }
});

export const updateUaeManualNonReconciledReqData = (reqData) => ({
  type: types.UPDATE_UAE_MANUAL_NON_RECONCILED,
  payload: {
    ...reqData
  }
});

export const updatePendingOrderReceiptsReqData = (reqData) => ({
  type: types.UPDATE_PENDING_ORDER_RECEIPTS_REQ_DATA,
  payload: {
    ...reqData
  }
});

export const updateRewardsReqData = (reqData) => ({
  type: types.UPDATE_REWARDS_REQ_DATA,
  payload: {
    ...reqData
  }
});

export const updateBaerscrestOpsReqData = (reqData) => ({
  type: types.UPDATE_BAERSCREST_OPS_REQ_DATA,
  payload: {
    ...reqData
  }
});

export const updateRdaDashboardReqData = (reqData) => ({
  type: types.UPDATE_RDA_DASHBOARD_REQ_DATA,
  payload: {
    ...reqData
  }
});

import { FULFILLMENT_PROVIDER } from '../../constants';

export default function providerPotBalance(fetch, baseUrl) {
  return {
    get({ provider = FULFILLMENT_PROVIDER.XENDPAY }) {
      return fetch(`${baseUrl}/${provider}`);
    },

    updateBalance({ providerId, balance }) {
      return fetch.put(`${baseUrl}/${providerId}`, {
        balance
      });
    }
  };
}

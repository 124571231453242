import { JOBS as types } from '../constants';

const initialState = {
  jobs: {
    isLoading: false,
    isError: false,
    error: null,
    data: null,
    totalPages: 0
  }
};

const jobsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.GETTING_JOBS:
      return {
        ...state,
        jobs: {
          ...state.jobs,
          isLoading: true,
          isError: false,
          error: null,
          data: null,
          totalPages: 0
        }
      };

    case types.GET_JOBS_SUCCESS:
      return {
        ...state,
        jobs: {
          ...state.jobs,
          isLoading: false,
          isError: false,
          error: null,
          data: payload.data,
          totalPages: payload.totalPages
        }
      };

    case types.GET_JOBS_FAILURE:
      return {
        ...state,
        jobs: {
          ...state.jobs,
          isLoading: false,
          isError: true,
          error: payload,
          data: null,
          totalPages: 0
        }
      };

    default:
      return {
        ...state
      };
  }
};

export default jobsReducer;

import {
  HStack,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
  Button,
  FormLabel,
  VStack,
  Input
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useState } from 'react';
import { NROB_ACTIONS } from '../../constants';

const ScheduleDeliveryModal = ({
  scheduleType = NROB_ACTIONS.DELIVERY,
  title = 'Schedule Docs Delivery',
  onSubmit = () => {},
  isLoading = false
}) => {
  const [deliveryDate, setDeliveryDate] = useState('');

  const handleSubmit = (e) => {
    e?.preventDefault();
    onSubmit && onSubmit(deliveryDate);
  };

  return (
    <>
      <ModalHeader>
        <Text color={'white'}>{title}</Text>
      </ModalHeader>
      <form onSubmit={handleSubmit}>
        <ModalBody>
          <VStack alignItems={'start'} width={'full'} gap={1}>
            <FormLabel htmlFor="deliveryDate">
              {scheduleType === NROB_ACTIONS.APPOINTMENT
                ? 'Appointment Date'
                : 'Schedule Date'}
            </FormLabel>
            <Input
              required
              type="date"
              name="deliveryDate"
              min={dayjs(Date.now()).format('YYYY-MM-DD')}
              value={deliveryDate}
              onChange={(e) => {
                setDeliveryDate(e?.target?.value);
              }}
            />
          </VStack>
        </ModalBody>
        <ModalFooter>
          <HStack>
            <Button
              isLoading={isLoading}
              colorScheme="green"
              type="submit"
              variant="outline"
            >
              Schedule
            </Button>
          </HStack>
        </ModalFooter>
      </form>
    </>
  );
};

export default ScheduleDeliveryModal;

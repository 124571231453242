import { TICKETS as types } from '../constants';

const initialState = {
  isLoading: false,
  isError: false,
  error: null,
  data: {}
};

const ticketsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.GETTING_ALL_TICKETS:
      return {
        ...state,
        isLoading: true,
        data: {}
      };
    case types.GET_ALL_TICKETS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        error: null,
        data: {
          ...state?.data,
          [payload?.ticketType]: {
            tickets: payload?.data,
            totalPages: payload?.totalPages
          }
        }
      };
    case types.GET_ALL_TICKETS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: payload?.errMsg
      };
    case types.CREATING_TICKET:
      return {
        ...state,
        isLoading: true
      };
    case types.CREATE_TICKET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        error: null,
        data: {
          ...state?.data,
          [payload?.ticketType]: {
            ...state?.data[payload?.ticketType],
            tickets: [
              payload?.data,
              ...state?.data[payload?.ticketType]?.tickets
            ],
            totalPages: Math.ceil(
              (state?.data[payload?.ticketType]?.tickets?.length + 1) /
                payload?.pageSize
            )
          }
        }
      };
    case types.CREATE_TICKET_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: payload?.errMsg
      };
    case types.UPDATING_TICKET_BY_ID:
      return {
        ...state,
        isLoading: true
      };
    case types.UPDATE_TICKET_BY_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        error: null,
        data: {
          ...state?.data,
          [payload?.ticketType]: {
            ...state?.data[payload?.ticketType],
            tickets: state?.data[payload?.ticketType]?.tickets?.map((ticket) =>
              ticket?.ticketId === payload?.data?.ticketId
                ? { ...payload?.data }
                : ticket
            )
          }
        }
      };
    case types.UPDATE_TICKET_BY_ID_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: payload.errMsg
      };
    default:
      return state;
  }
};

export default ticketsReducer;

import { EXCHANGE as types } from '../constants';

const initialState = {
  quote: {
    isLoading: false,
    isError: false,
    error: null,
    data: null
  }
};

const exchangeReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.GETTING_QUOTE:
      return {
        ...state,
        quote: {
          ...state.quote,
          isLoading: false,
          isError: false,
          error: null,
          data: null
        }
      };

    case types.GET_QUOTE_SUCCESS:
      return {
        ...state,
        quote: {
          ...state.quote,
          isLoading: false,
          isError: false,
          error: null,
          data: payload
        }
      };

    case types.GET_QUOTE_FAILURE:
      return {
        ...state,
        quote: {
          ...state.quote,
          isLoading: false,
          isError: true,
          error: payload,
          data: null
        }
      };

    default:
      return {
        ...state
      };
  }
};

export default exchangeReducer;

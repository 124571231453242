import api from '../../api';
import { getErrorMessage } from '../../utils';
import { KYC as types } from '../constants';
import { setAlert } from './alertActions';

export const getKycDocs =
  ({ userId, pageNo, pageSize, provider, product }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: types.GETTING_KYC_DOCS
      });

      const { data } = await api.kyc.docs({
        provider,
        product,
        pageNo,
        pageSize,
        userId
      });

      return dispatch({
        type: types.GET_KYC_DOCS_SUCCESS,
        payload: data
      });
    } catch (error) {
      dispatch({
        type: types.GET_KYC_DOCS_FAILURE,
        payload: error
      });
    }
  };

export const getKycChecks =
  ({ userId, pageNo, pageSize }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: types.GETTING_KYC_CHECKS
      });

      const { data } = await api.kyc.checks({ pageNo, pageSize, userId });

      return dispatch({
        type: types.GET_KYC_CHECKS_SUCCESS,
        payload: data
      });
    } catch (error) {
      dispatch({
        type: types.GET_KYC_CHECKS_FAILURE,
        payload: error
      });
    }
  };

export const updateKycDocStatus =
  ({ userId, formData, onFinish, onSuccess, onFailure }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: types.UPDATING_KYC_DOC_STATUS
      });

      const { data } = await api.kyc.updateKycStatus(formData, userId);

      dispatch({
        type: types.UPDATE_KYC_DOC_STATUS_SUCCESS,
        payload: data
      });

      if (onSuccess) onSuccess();
    } catch (error) {
      dispatch({
        type: types.UPDATE_KYC_DOC_STATUS_FAILURE,
        payload: error
      });
      const errorMessage = error.data
        ? JSON.parse(error.data)?.message
        : error.message;
      if (onFailure) onFailure(errorMessage);
    } finally {
      if (onFinish) onFinish();
    }
  };

export const getAllKycs =
  ({ pageNo, pageSize, provider, product, status, country }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: types.GETTING_ALL_KYCS
      });

      const { data } = await api.kyc.allKycs({
        provider,
        product,
        pageNo,
        pageSize,
        status,
        ...(!country ? { country: 'NONE' } : { country })
      });

      return dispatch({
        type: types.GET_ALL_KYCS_SUCCESS,
        payload: data
      });
    } catch (error) {
      dispatch({
        type: types.GET_ALL_KYCS_FAILURE,
        payload: error
      });
    }
  };

export const getUserKyc =
  ({ userId, product, provider }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: types.GETTING_USER_KYC
      });

      const { data } = await api.kyc.userKyc({ userId, product, provider });

      return dispatch({
        type: types.GET_USER_KYC_SUCCESS,
        payload: data
      });
    } catch (error) {
      return dispatch({
        type: types.GET_USER_KYC_FAILURE,
        payload: error
      });
    }
  };

export const syncUserKyc =
  ({ userId, product, provider, onSuccess, onFinish }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: types.SYNCING_USER_KYC
      });

      const { data } = await api.kyc.syncUserKyc({ userId, product, provider });

      dispatch({
        type: types.SYNC_USER_KYC_SUCCESS,
        payload: data
      });
      if (onSuccess) onSuccess();
    } catch (error) {
      console.log(error);
      dispatch(
        setAlert({
          title: getErrorMessage({
            error,
            genericMessage: 'Failed to sync user kyc!'
          })
        })
      );
      dispatch({
        type: types.SYNC_USER_KYC_FAILURE,
        payload: error
      });
    } finally {
      if (onFinish) onFinish();
    }
  };

export const updateCheckStatus =
  ({ checkId, onSuccess, onFinish }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: types.UPDATING_CHECK_STATUS
      });

      await api.kyc.updateCheckStatus({ checkId });

      dispatch({
        type: types.UPDATE_CHECK_STATUS_SUCCESS
      });
      if (onSuccess) onSuccess();
    } catch (error) {
      console.log(error);
      dispatch(
        setAlert({
          title: getErrorMessage({
            error,
            genericMessage: 'Failed to updated Check Status'
          }),
          status: 'error'
        })
      );
      dispatch({
        type: types.UPDATE_CHECK_STATUS_FAILURE,
        payload: error
      });
    } finally {
      if (onFinish) onFinish();
    }
  };

export const createEKyc =
  ({ userId, onFinish, onSuccess }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: types.CREATING_E_KYC
      });

      await api.kyc.createEKyc({ userId });

      dispatch({
        type: types.CREATE_E_KYC_SUCCESS
      });

      dispatch(
        setAlert({
          title: 'E Kyc created!',
          status: 'success'
        })
      );

      if (onSuccess) onSuccess();
    } catch (error) {
      dispatch(
        setAlert({
          title: getErrorMessage({
            error,
            genericMessage: 'Failed to create E Kyc!'
          }),
          status: 'error'
        })
      );

      dispatch({
        type: types.CREATE_E_KYC_FAILURE
      });
    } finally {
      if (onFinish) onFinish();
    }
  };

export const createMKyc =
  ({ userId, onSuccess, onFinish }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: types.CREATING_M_KYC
      });

      await api.kyc.createMKyc({ userId });

      dispatch({
        type: types.CREATE_M_KYC_SUCCESS
      });

      dispatch(
        setAlert({
          title: 'M Kyc created!',
          status: 'success'
        })
      );

      if (onSuccess) onSuccess();
    } catch (error) {
      dispatch(
        setAlert({
          title: getErrorMessage({
            error,
            genericMessage: 'Failed to create E Kyc!'
          }),
          status: 'error'
        })
      );
      dispatch({
        type: types.CREATE_M_KYC_FAILURE
      });
    } finally {
      if (onFinish) onFinish();
    }
  };

export const clearKycDocs = () => ({
  type: types.CLEAR_KYC_DOCS
});

export const clearKycChecks = () => ({
  type: types.CLEAR_KYC_CHECKS
});

export const clearAllKycs = () => ({
  type: types.CLEAR_ALL_KYCS
});

export const clearUserKyc = () => ({
  type: types.CLEAR_USER_KYC
});

export const rejectEfrKyc =
  ({ userId, provider, product, onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      await api.kyc.rejectEfrKyc({ userId, data: { product, provider } });
      onSuccess && onSuccess();
    } catch (error) {
      dispatch(
        setAlert({
          title: error.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

import React, { useCallback, useState } from 'react';
import {
  Flex,
  ModalBody,
  Textarea,
  Button,
  ModalFooter,
  FormControl,
  FormLabel,
  FormErrorMessage,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper
} from '@chakra-ui/react';
import api from '../../api';
import { getUserById } from '../../store/actions/usersActions';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../store/actions/alertActions';

const AddFreeTransfers = ({ userId, onClose }) => {
  const dispatch = useDispatch();
  const [count, setCount] = useState();
  const [reason, setReason] = useState('');
  const [isInvalid, setIsInvalid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  //accept only integer part of number
  const handleCountInputChange = useCallback((value) => {
    setIsInvalid(false);
    setCount(Math.floor(Number(value)));
  }, []);

  const handleReasonInputChange = useCallback((e) => {
    setIsInvalid(false);
    setReason(e?.target?.value);
  }, []);

  const isFormValid = useCallback((formData) => {
    return formData?.count && formData?.reason?.length;
  }, []);

  const addFreeTransfers = useCallback(
    ({ count, reason }) => {
      setIsLoading(true);
      api.user
        ?.addFreeTransfers({ userId, count, reason })
        .then(() => {
          onClose();

          dispatch(
            setAlert({
              title: `Added ${count} free transfer${
                count === 1 ? '' : 's'
              } to user account`,
              status: 'success'
            })
          );

          //refetch user details, to get user details with updated free transfer count
          dispatch(getUserById(userId));
        })
        .catch((error) => {
          dispatch(
            setAlert({
              title:
                error?.message ||
                'Failed to add free transfers to user account',
              status: 'error'
            })
          );
        })
        .finally(() => setIsLoading(false));
    },
    [userId, onClose, dispatch]
  );

  const handleSubmit = useCallback(() => {
    const formData = {
      count,
      reason
    };

    if (!isFormValid(formData)) {
      setIsInvalid(true);
      return;
    }

    addFreeTransfers(formData);
  }, [count, reason, addFreeTransfers, isFormValid]);

  return (
    <ModalBody>
      <FormControl isInvalid={isInvalid}>
        <Flex>
          <Flex w={'40%'} justifyContent={'flex-end'}>
            <FormLabel
              alignSelf={'flex-end'}
              fontSize={'0.75rem'}
              fontWeight={'500'}
              mb={0}
              lineHeight={'2rem'}
              pr={2}
            >
              Count :
            </FormLabel>
          </Flex>
          <NumberInput
            size={'sm'}
            fontWeight={'600'}
            w={'60%'}
            bg={'shadedGray.400'}
            color="#111"
            border={0}
            placeholder={'Number of free transfers to add'}
            _placeholder={{ opacity: 1, color: 'shadedGray.600' }}
            required
            rounded={'md'}
            min={0}
            onChange={handleCountInputChange}
            value={count}
          >
            <NumberInputField value={count} rounded="md" />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </Flex>
        <Flex my={'1rem'}>
          <Flex
            w={'40%'}
            justifyContent={'flex-end'}
            height={'100%'}
            alignItems={'center'}
          >
            <FormLabel
              alignSelf={'flex-end'}
              fontSize={'0.75rem'}
              fontWeight={'500'}
              mb={0}
              lineHeight={'2rem'}
              pr={2}
            >
              Reason :
            </FormLabel>
          </Flex>
          <Textarea
            resize={'none'}
            size={'sm'}
            fontSize={'0.75rem'}
            fontWeight={'600'}
            w={'60%'}
            type="text"
            rounded="lg"
            bg={'shadedGray.400'}
            color={'shadedGray.800'}
            border={0}
            placeholder={''}
            _placeholder={{ opacity: 1, color: 'shadedGray.600' }}
            required
            value={reason}
            onChange={handleReasonInputChange}
          />
        </Flex>
        <FormErrorMessage textAlign={'center'} display={'block'}>
          Please enter count and reason
        </FormErrorMessage>
      </FormControl>
      <ModalFooter justifyContent={'center'}>
        <Button
          colorScheme="brand"
          color="altGray.400"
          mr={3}
          size={'sm'}
          fontSize={'0.75rem'}
          type="submit"
          onClick={handleSubmit}
          isLoading={isLoading}
        >
          Submit Now
        </Button>
      </ModalFooter>
    </ModalBody>
  );
};

export default AddFreeTransfers;

import { doesExist, formatDate } from '../../utils';

export const getSipDetailsTable = (sipData) => [
  {
    title: 'SIP Details',
    data: [
      {
        name: 'Sip Id:',
        value: sipData?.sipId || '-',
        isCopyable: true
      },
      {
        name: 'Amount:',
        value:
          doesExist(sipData?.currency) && doesExist(sipData?.currency)
            ? `${sipData?.currency} ${sipData?.amount}`
            : '-'
      },

      {
        name: 'Status:',
        value: sipData?.status || '-'
      },
      {
        name: 'Previous status:',
        value: sipData?.previousStatus || '-'
      },
      {
        name: 'Installments:',
        value: sipData?.installments || '-'
      },
      {
        name: 'Pending installments:',
        value: sipData?.pendingInstallments || '-'
      },
      {
        name: 'Frequency:',
        value: sipData?.frequency || '-'
      },
      {
        name: 'Start Date:',
        value: formatDate(sipData?.startDate) || '-'
      },
      {
        name: 'Sip Date:',
        value: sipData?.sipDate || '-'
      },
      {
        name: 'Created at:',
        value: formatDate(sipData?.createdAt) || '-'
      },
      {
        name: 'Updated at:',
        value: formatDate(sipData?.updatedAt) || '-'
      }
    ]
  }
];

import { formatDate } from '../../utils';

export const getUserDetailsTable = (user) =>
  !user
    ? null
    : [
        {
          title: 'User Details',
          data: [
            {
              name: ' Name:',
              value: user.name || '-'
            },

            {
              name: 'Phone:',
              value: `${user.code ? user.code : ''} ${user.phoneNumber}`
            },
            {
              name: 'Email:',
              value: user.email || '-'
            },
            {
              name: 'Created at:',
              value: formatDate(user.createdAt) || '-'
            },
            {
              name: 'RFX Kyc Status:',
              value: user.kycStatus || '-'
            }
          ]
        }
      ];

import auth from '../../auth';

export default function authApi(fetch, baseUrl = '') {
  return {
    me() {
      return fetch.get(`${baseUrl}/admin/me`);
    },

    login({ username, password }) {
      return fetch.post(`${baseUrl}/login/admin`, {
        phoneNo: username,
        deviceId: localStorage.getItem('device-id') || 'device_xyz_id',
        password
      });
    },
    logout() {
      let promise = new Promise((resolve, reject) => {
        setTimeout(async () => {
          await auth.removeToken();
          resolve('User logged out!');
          // reject('An error occurred!');
        }, 2000);
      });
      return promise;
    }
  };
}

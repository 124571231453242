import api from '../../api';
import RDA from '../constants/rdaConstants';
import { setAlert } from './alertActions';

export const getRdaPots =
  ({ active = false, pageSize = 10, page = 0, onComplete, onSuccess }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: RDA.GETTING_ALL_POTS
      });
      const { data } = await api.rda.getRdaPots({
        active,
        pageSize,
        page: page - 1
      });
      dispatch({
        type: RDA.GET_ALL_POTS_SUCCESS,
        payload: {
          data: data?.results,
          totalPages: data?.totalPages
        }
      });
      onSuccess && onSuccess();
    } catch (error) {
      console.error(error.message);
      dispatch(
        setAlert({
          title: error.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const createPot =
  ({ amount, fxRate, onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      const payload = {
        amount,
        fxRate
      };
      await api.rda.createPot({
        payload
      });
      onSuccess && onSuccess();
    } catch (error) {
    } finally {
      onComplete && onComplete();
    }
  };

export const deletePot =
  ({ potId, onComplete, onSuccess }) =>
  async (dispatch) => {
    try {
      await api.rda.deletePot({ potId });
      onSuccess && onSuccess();
    } catch (error) {
      console.error(error.message);
      dispatch(
        setAlert({
          title: error.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const getRdaProvider =
  ({ provider, providers, onComplete, onSuccess }) =>
  async (dispatch) => {
    try {
      const proms = providers.map(
        async (provider) => await api.rda.getRdaProvider({ provider })
      );
      const responses = await Promise.all(proms);
      const data = responses.map((response) => response?.data);
      onSuccess && onSuccess(data);
    } catch (error) {
      console.error(error.message);
      dispatch(
        setAlert({
          title: error.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const getForexRate =
  ({ onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      const { data } = await api.rda.getFxRate();
      onSuccess && onSuccess(data?.fxRate);
    } catch (error) {
      console.error(error.message);
      dispatch(
        setAlert({
          title: error.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

export const updateRdaProviderStatus =
  ({ status, reason, provider, onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      const { data } = await api.rda.updateRdaProvider({
        provider,
        status,
        reason
      });
      onSuccess && onSuccess();
    } catch (error) {
      console.error(error.message);
      dispatch(
        setAlert({
          title: error.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };

// export const updateActiveOnly = () => (dispatch) => {
//   dispatch({
//     type: RDA.UPDATE_ACTIVE_ONLY
//   });
// };
export const getRdaTransactions = (filters) => async (dispatch) => {
  try {
    dispatch({ type: RDA.GETTING_RDA_TRANSACTIONS });
    const { data } = await api.getRdaTransactions(filters);
    dispatch({ type: RDA.GET_RDA_TRANSACTIONS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: RDA.GET_RDA_TRANSACTIONS_FAILURE, payload: error });
  }
};

export const getRdaTransactionDetails = (orderId) => async (dispatch) => {
  try {
    dispatch({ type: RDA.GETTING_RDA_TRANSACTION_DETAILS });
    const { data } = await api.getRdaTransactionDetails(orderId);
    dispatch({ type: RDA.GET_RDA_TRANSACTION_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: RDA.GET_RDA_TRANSACTION_DETAILS_FAILURE, payload: error });
  }
};
import { NRE_NRO as types } from '../constants';

const initialState = {
  onboarding: {
    status: {
      isLoading: false,
      isError: false,
      error: null,
      data: null
    }
  }
};

const nreNroReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.GETTING_ONBOARDING_STATUS:
      return {
        ...state,
        onboarding: {
          ...state.onboarding,
          status: {
            ...state.onboarding.status,
            isLoading: true,
            isError: false,
            error: null,
            data: null
          }
        }
      };

    case types.GET_ONBOARDING_STATUS_SUCCESS:
      return {
        ...state,
        onboarding: {
          ...state.onboarding,
          status: {
            ...state.onboarding.status,
            isLoading: false,
            isError: false,
            error: null,
            data: payload
          }
        }
      };

    case types.GET_ONBOARDING_STATUS_FAILURE:
      return {
        ...state,
        onboarding: {
          ...state.onboarding,
          status: {
            ...state.onboarding.status,
            isLoading: false,
            isError: true,
            error: payload,
            data: null
          }
        }
      };

    default:
      return { ...state };
  }
};

export default nreNroReducer;

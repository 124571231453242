import { Box, Spinner, VStack, Text, Grid } from '@chakra-ui/react';
import WorkflowScreenDataCard from '../WorkflowScreenDataCard';
import { camelCaseToSpaceSeparated } from '../../utils';
import { toWorkflowScreenDataListV2 } from '../../utils';

const NrObFinancialWorkflowDetails = ({
  userId,
  financialDetails,
  isLoading
}) => {
  return (
    <>
      <Box padding={1}>
        <VStack alignItems={'start'} width={'auto'} gap={2}>
          <Text color={'white'} fontSize={'xl'}>
            Financial Details
          </Text>
          {/* TODO: show workflow screens data here  */}
          {isLoading ? (
            <Spinner></Spinner>
          ) : Object.keys(financialDetails).length > 0 ? (
            <Grid
              gridTemplateColumns={{ base: '1fr', md: '1fr 1fr' }}
              w={'100%'}
              gap={6}
            >
              {Object.keys(financialDetails).map((screen) => (
                <WorkflowScreenDataCard
                  key={screen}
                  title={camelCaseToSpaceSeparated(screen)}
                  data={toWorkflowScreenDataListV2(financialDetails[screen])}
                  userId={userId}
                  isV2={true}
                />
              ))}
            </Grid>
          ) : (
            <Text color={'gray.400'}>No Financial Details Found</Text>
          )}
        </VStack>
      </Box>
    </>
  );
};

export default NrObFinancialWorkflowDetails;

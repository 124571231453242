import { WORKFLOW as types } from '../constants';
import { toWorkflowDoc } from './utils';

const initialState = {
  docs: {
    isLoading: false,
    isError: false,
    error: null,
    data: null,
    totalPages: 0
  },
  screensData: {
    workflowId: null,
    isLoading: false,
    isError: false,
    error: null,
    data: null
  }
};

const workflowReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.GETTING_USER_DOCS:
      return {
        ...state,
        docs: {
          ...state.docs,
          isLoading: true,
          isError: false,
          error: null,
          data: null,
          totalPages: 0
        }
      };

    case types.GET_USER_DOCS_SUCCESS:
      return {
        ...state,
        docs: {
          ...state.docs,
          isLoading: false,
          isError: false,
          error: null,
          data: payload.data?.map((item) => toWorkflowDoc(item)),
          totalPages: payload.totalPages
        }
      };

    case types.GET_USER_DOCS_FAILURE:
      return {
        ...state,
        docs: {
          ...state.docs,
          isLoading: false,
          isError: true,
          error: payload,
          data: null,
          totalPages: 0
        }
      };

    case types.CLEAR_USER_DOCS:
      return {
        ...state,
        docs: {
          ...state.docs,
          isLoading: false,
          isError: true,
          error: null,
          data: null,
          totalPages: 0
        }
      };

    case types.GETTING_WORKFLOW_SCREENS_DATA:
      return {
        ...state,
        screensData: {
          ...state.screensData,
          workflowId: payload.workflowId,
          isLoading: true,
          isError: false,
          error: null,
          data: null
        }
      };

    case types.GET_WORKFLOW_SCREENS_DATA_SUCCESS:
      return {
        ...state,
        screensData: {
          ...state.screensData,
          workflowId: payload.workflowId || state.screensData.workflowId,
          isLoading: false,
          isError: false,
          error: null,
          data: payload.data
        }
      };

    case types.GET_WORKFLOW_SCREENS_DATA_FAILURE:
      return {
        ...state,
        screensData: {
          ...state.screensData,
          workflowId: payload.workflowId || state.screensData.workflowId,
          isLoading: false,
          isError: true,
          error: payload.data,
          data: null
        }
      };

    default:
      return { ...state };
  }
};

export default workflowReducer;

import {
  Button,
  Grid,
  HStack,
  Icon,
  Spinner,
  Stack,
  StackDivider,
  Text,
  VStack
} from '@chakra-ui/react';
import React, { useState } from 'react';
import {
  MdOutlineCancel,
  MdOutlineDone,
  MdOutlineOpenInNew
} from 'react-icons/md';
import { getErrorMessage, getKycDocStatusColor } from '../../utils';
import { KYC_DOC_STATUS, KYC_PROVIDER, ROLES } from '../../constants';
import useIsAdmin from '../../hooks/useIsAdmin';
import { AiOutlineFolderOpen } from 'react-icons/ai';
import api from '../../api';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../store/actions/alertActions';
import useAuthRole from '../../hooks/useAuthRole';

const KycDocCard = ({
  onKycDocActionClick,
  onKycInfoClick,
  doc,
  userId,
  onRemarksClick,
  onRejectReasonClick,
  onORKYCVerifyClick,
  kycProvider,
  onVerifyClick,
  onVerifyClickV2
}) => {
  const isAdmin = useIsAdmin();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { hasAnyAuthority } = useAuthRole();

  const handleDocumentTypeClick = () => {
    setIsLoading(true);
    api.workflow
      .docByDocType({ userId, documentTye: doc.subDocumentType })
      .then((res) => res.data)
      .then((data) => window.open(data.url))
      .catch((error) => {
        dispatch(
          setAlert({
            title: getErrorMessage({
              error,
              genericMessage:
                'Something went wrong while fetching the document!'
            }),
            status: 'error'
          })
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleVerifyClick = () => {
    if (onVerifyClickV2) {
      onVerifyClickV2({
        provider: kycProvider,
        payload: {
          kyc: doc,
          newStatus: KYC_DOC_STATUS.VERIFIED
        }
      });
      return;
    }
  };

  const showActionBtns = ({ kycProvider, kycStatus, kycType }) => {
    if (
      kycProvider === KYC_PROVIDER.ON_RAMP_MONEY &&
      kycType === 'ON_RAMP_KYC_DATA' &&
      kycStatus === KYC_DOC_STATUS.PENDING_FOR_VERIFICATION
    )
      return true;

    if (
      kycProvider === KYC_PROVIDER.ENCRYPTUS &&
      kycStatus === KYC_DOC_STATUS.PENDING_FOR_VERIFICATION
    ) {
      if (kycType !== 'ENCRYPTUS_KYC_DOCS') return false;
      return true;
    }
    if (kycType === 'DUPLICATE_CHECK' && kycStatus === 'DRAFT') return true;

    if (
      kycProvider === KYC_PROVIDER.ON_RAMP_MONEY &&
      kycType === 'PROOF_OF_IDENTITY'
    )
      return false;

    if (kycStatus === KYC_DOC_STATUS.PENDING_FOR_VERIFICATION) return true;
    return false;
  };

  return (
    <VStack
      w={{ base: '100%', md: '100%' }}
      maxW={'800px'}
      bg="backGround"
      borderRadius="12px"
      divider={
        <StackDivider style={{ margin: '0' }} borderColor="whiteAlpha.300" />
      }
    >
      <Grid
        gridTemplateColumns={'repeat(2,auto)'}
        rowGap={2}
        w={'90%'}
        justifyContent="space-between"
        m={'1rem'}
      >
        <HStack>
          <Text fontSize={{ base: 'sm', sm: 'md' }} color="gray">
            {doc.kycType}
          </Text>
        </HStack>

        <HStack>
          <Icon
            viewBox="0 0 200 200"
            color={getKycDocStatusColor(doc.status)}
            w="8px"
          >
            <path
              fill="currentColor"
              d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
            />
          </Icon>
          <Text fontSize={{ base: 'sm', sm: 'md' }} color="white">
            {doc.status}
          </Text>
        </HStack>
      </Grid>
      <Grid
        gridTemplateColumns={{
          base: '1fr',
          md: isAdmin ? `1fr 0.7fr 0.7fr 115px 115px` : `1fr 1fr 1fr`
        }}
        gap={2}
        alignItems={'flex-start'}
        w={'90%'}
        m={'1rem'}
        textAlign={{ base: 'end', md: 'start' }}
      >
        <Stack
          direction={{ base: 'row', md: 'column' }}
          justifyContent={'space-between'}
          height={'100%'}
        >
          <Text fontSize={'sm'} color={'gray'}>
            Sub Doc Type
          </Text>
          {doc.subDocumentType && doc.subDocumentType !== 'NA' ? (
            <HStack cursor={'pointer'} onClick={handleDocumentTypeClick}>
              {isLoading ? (
                <Spinner size={'sm'} color={'white'} />
              ) : (
                <AiOutlineFolderOpen color={'white'} />
              )}
              <Text fontSize={'sm'} color={'white'}>
                {doc.subDocumentType}
              </Text>
            </HStack>
          ) : (
            <Text fontSize={'sm'} color={'white'}>
              -
            </Text>
          )}
        </Stack>
        <Stack
          direction={{ base: 'row', md: 'column' }}
          justifyContent={'space-between'}
          height={'100%'}
        >
          <Text fontSize={'sm'} color={'gray'}>
            Kyc Info
          </Text>
          {doc.kycInfo?.length ? (
            <MdOutlineOpenInNew
              color={'gray'}
              cursor={'pointer'}
              onClick={() =>
                onKycInfoClick({ kycDetailsId: doc.id, kycInfo: doc.kycInfo })
              }
            />
          ) : (
            <Text fontSize={'sm'} color={'white'}>
              -
            </Text>
          )}
        </Stack>

        <Stack
          direction={{ base: 'row', md: 'column' }}
          justifyContent={'space-between'}
          height={'100%'}
        >
          <Text fontSize={'sm'} color={'gray'}>
            Remarks
          </Text>
          {doc.remarks?.length ? (
            <MdOutlineOpenInNew
              color={'gray'}
              cursor={'pointer'}
              onClick={() =>
                onRemarksClick({
                  kycDetailsId: doc.id,
                  kycType: doc.kycType,
                  remarks: doc.remarks
                })
              }
            />
          ) : (
            <Text fontSize={'sm'} color={'white'}>
              -
            </Text>
          )}
        </Stack>

        {doc?.status === KYC_DOC_STATUS.REJECTED && (
          <Stack
            direction={{ base: 'row', md: 'column' }}
            justifyContent={'space-between'}
            height={'100%'}
          >
            <Text fontSize={'sm'} color={'gray'}>
              Rejection Reason
            </Text>
            {doc.rejectionReason?.length ? (
              <MdOutlineOpenInNew
                color={'gray'}
                cursor={'pointer'}
                onClick={() =>
                  onRejectReasonClick({
                    kycDetailsId: doc.id,
                    kycType: doc.kycType,
                    rejectionReason: doc.rejectionReason
                  })
                }
              />
            ) : (
              <Text fontSize={'sm'} color={'white'}>
                -
              </Text>
            )}
          </Stack>
        )}

        {hasAnyAuthority(ROLES.ADMIN_ROLE, ROLES.MANAGER_ROLE) &&
          doc.kycType === 'DUPLICATE_CHECK' &&
          doc.status === KYC_DOC_STATUS.REJECTED && (
            <>
              <Button
                mt={{ base: 2, md: 0 }}
                ml={{ base: 0, md: 1 }}
                rightIcon={<MdOutlineCancel />}
                type="submit"
                rounded="full"
                colorScheme="red"
                alignSelf={{ base: 'center', md: 'center' }}
                variant={'outline'}
                px={5}
                onClick={() => {
                  onKycDocActionClick({
                    kyc: doc,
                    newStatus: KYC_DOC_STATUS.PENDING_FOR_VERIFICATION
                  });
                }}
              >
                Unblock
              </Button>
            </>
          )}

        {isAdmin &&
          showActionBtns({
            kycProvider,
            kycStatus: doc?.status,
            kycType: doc.kycType
          }) && (
            <>
              {kycProvider !== KYC_PROVIDER.ENCRYPTUS && (
                <Button
                  mt={{ base: 2, md: 0 }}
                  ml={{ base: 0, md: 1 }}
                  rightIcon={<MdOutlineCancel />}
                  type="submit"
                  rounded="full"
                  colorScheme="red"
                  alignSelf={{ base: 'center', md: 'center' }}
                  variant={'outline'}
                  px={5}
                  onClick={() => {
                    onKycDocActionClick({
                      kyc: doc,
                      newStatus: KYC_DOC_STATUS.REJECTED
                    });
                  }}
                >
                  Reject
                </Button>
              )}
              <Button
                mt={{ base: 2, md: 0 }}
                ml={{ base: 0, md: 1 }}
                rightIcon={<MdOutlineDone color="black" />}
                type="submit"
                rounded="full"
                color={'black'}
                colorScheme="brand"
                alignSelf={{ base: 'center', md: 'center' }}
                px={5}
                onClick={handleVerifyClick}
              >
                Verify
              </Button>
            </>
          )}
      </Grid>
    </VStack>
  );
};

export default KycDocCard;

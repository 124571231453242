import axios from 'axios';
import auth from '../auth';
import userApi from './user';
import authApi from './auth';
import ordersApi from './orders';
import jigneshApi from './jignesh';
import searchApi from './search';
import referralsApi from './referrals';
import { decamelizeKeys } from 'humps';
import { camelCaseKeys, toErrorData } from '../utils';
import nrApi from './nrAccount';
import exchangeApi from './exchange';
import providerHolidays from './providerHolidays';
import providerPotBalance from './providerPotBalance';
import workflowApi from './workflow';
import kycApi from './kyc';
import investmentsApi from './investments';
import thunesApi from './thunes';
import sourceAccountApi from './sourceAccount';
import jobsApi from './jobs';
import configApi from './config';
import referralsV2Api from './referrals_v2';
import nreNroApi from './nreNro';
import ticketsApi from './tickets';
import cxUsers from './cxUsers';
import workflowApiV2 from './workflowsV2';
import nronboardApi from './nrOnboard';
import userSourceAccount from './userSourceAccount';
import orderScreenConfigApi from './orderScreenConfig';
import platformTransferFee from './platformTransferFee';
import uaeManualPayments from './uaeManualPayments';
import rda from './rda';
import vanceCash from './vanceCash';
import vdaFunnel from './vdaFunnel';
import reconDashboard from './reconDashboard';
import fxRatePeakingConfig from './fxRatePeakingConfig';
import transferExperienceConfig from './transferExperienceConfig';
import featureFlags from './featureFlags';
import studentProgram from './studentProgram';
import manualPayoutBatchApi from './manualPayoutBatch';
import accessManagement from './accessManagement';
import rewardsApi from './rewards';
import baerscrestOps from './baerscrest';
import falconTransactionsSearch from './b2bDashboard/Falcon';
import rdaDashboard from './rdaDashboard';

const isDevelopment =
  !process.env.REACT_APP_ENV || process.env.REACT_APP_ENV === 'development';

/* PROD */
const url = !isDevelopment
  ? 'https://alphadesk-api.vance.club'
  : 'https://alphadesk.stg-api.vance.club'; // Replace with Env-Specific URL

console.log(url);
/* LOCAL SERVER */
// const url = 'http://localhost:5001';

export const fetch = axios.create({
  baseURL: url,
  timeout: 30000,
  headers: {
    common: {
      'Content-Type': 'application/json'
    }
  },
  transformResponse: [(res) => res] // change to res.data
});

export const rawFetch = (url) =>
  axios.create({
    baseURL: url,
    timeout: 30000,
    headers: {
      common: {
        'Content-Type': 'application/json'
      }
    }
  });

const WHITELISTED_ENDPOINTS = [
  '/ybl/customer-details',
  '/ocr-address',
  '/address',
  '/ocr-details-update'
];

const isWhiteListed = (url) => {
  return (
    WHITELISTED_ENDPOINTS.filter((endpoint) => url.includes(endpoint)).length >
    0
  );
};

fetch.interceptors.request.use((config) => {
  const token = auth.getToken();
  if (token) {
    config.headers['Authorization'] = `Bearer ${auth.getToken()}`;
  }
  config.headers['x-device-id'] = auth.getDeviceId();

  /* decamalize  params, and body */
  if (config.headers['Content-Type'] === 'multipart/form-data') return config;
  if (config.params) {
    config.params = decamelizeKeys(config.params);
  }

  const heavyEndpoints = [
    '/application/verify',
    '/notarization',
    '/admin/vda',
    '/admin/vda/stats',
    '/workflows/v2'
  ];
  if (config.url.includes('/ybl/trigger')) {
    config.timeout = null;
  } else if (heavyEndpoints.some((endpoint) => config.url.includes(endpoint))) {
    config.timeout = 30000;
  }

  if (isWhiteListed(config.url)) {
    return config;
  }

  if (config.data) {
    const decamelizedData = decamelizeKeys(config.data);
    // Remove the snake_case version and keep only the camelCase version
    if (config.data.countryCode) {
      delete decamelizedData.country_code;
      decamelizedData.countryCode = config.data.countryCode;
    }
    config.data = decamelizedData;
  }
  return config;
});

//returns parsed and camelized response data
fetch.interceptors.response.use(
  (response) => {
    if (isWhiteListed(response.config.url)) {
      response.data = JSON.parse(response.data);
      return response;
    }

    if (
      response.data &&
      response.headers['content-type'] === 'application/json'
    ) {
      response.data = camelCaseKeys(JSON.parse(response.data)); //parse and camelize
    }

    return response;
  },
  (error) => {
    return Promise.reject(toErrorData(error));
  }
);

const api = {
  user: userApi(fetch, '/admin/user'),
  auth: authApi(fetch),
  jignesh: jigneshApi(fetch, '/admin/ask-jignesh'),
  search: searchApi(fetch, '/admin'),
  orders: ordersApi(fetch, '/admin/orders'),
  referrals: referralsApi(fetch, '/referrals/campaign'),
  nrAccount: nrApi(fetch, '/admin/nr-account'),
  exchangeApi: exchangeApi(fetch, '/admin'),
  providerHolidays: providerHolidays(
    fetch,
    '/admin/config/fulfillment-holiday'
  ),
  providerPotBalance: providerPotBalance(fetch, '/provider-pot-balance'),
  workflow: workflowApi(fetch, '/workflows'),
  userOrders(userId) {
    return ordersApi(fetch, `/admin/user/${userId}/order`);
  },
  kyc: kycApi(fetch, `/admin`),
  investments: investmentsApi(fetch, '/investments'),
  thunes: thunesApi(fetch, ''),
  sourceAccounts: sourceAccountApi(fetch, '/source-account'),
  userSourceAccounts: userSourceAccount(fetch, '/admin/leantech'),
  jobs: jobsApi(fetch, '/job'),
  config: configApi(fetch, '/admin/config'),
  referralsV2: referralsV2Api(fetch, '/v2/referrals'),
  nreNro: nreNroApi(fetch, '/nre-nro'),
  tickets: ticketsApi(fetch, '/admin/ops-ticket'),
  cxUsers: cxUsers(fetch, '/admin/alphadesk-user/OPS_ROLE'),
  workflowsV2: workflowApiV2(fetch, '/workflows/v2'),
  nronboard: nronboardApi(fetch, '/nre-nro/onboarding'),
  orderScreenConfig: orderScreenConfigApi(
    fetch,
    '/admin/orders/compliance-flag'
  ),
  platformTransferFees: platformTransferFee(fetch, '/rate-comparator'),
  uaeManualPayments: uaeManualPayments(fetch, '/uae-manual-payments/pending'),
  rda: rda(fetch, '/rda'),
  vanceCash: vanceCash(fetch, '/v4/referrals'),
  vdaFunnel: vdaFunnel(fetch, '/admin/vda'),
  reconDashboard: reconDashboard(fetch, '/recon-dashboard'),
  fxRatePeakingConfig: fxRatePeakingConfig(fetch, '/config/fx-rate-peaking'),
  downtimeConfig: transferExperienceConfig(fetch, '/downtime'),
  featureFlags: featureFlags(fetch, '/admin/feature-flags'),
  studentProgram: studentProgram(fetch, '/student-program'),
  manualPayoutBatch: manualPayoutBatchApi(fetch, '/manual-payouts'),
  accessManagement: accessManagement(fetch, '/access-management'),
  rewards: rewardsApi(fetch, '/admin/rewards'),
  baerscrestOps: baerscrestOps(fetch, '/admin/baerscrest-ops'),
  falconTransactionsSearch: falconTransactionsSearch(
    fetch,
    '/admin/falcon/dashboard'
  ),
  rdaDashboard: rdaDashboard(fetch, '/admin/rda-dashboard')
};

export default api;

import {
  Button,
  FormControl,
  FormLabel,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  Text,
  Textarea,
  VStack
} from '@chakra-ui/react';
import React, { useState } from 'react';

const ConfirmWithRemarksModal = ({
  initialValue = '',
  title,
  subtitle,
  label = 'Remarks: ',
  placeholder = 'Enter remarks',
  handleSubmit,
  isLoading
}) => {
  const [remarks, setRemarks] = useState(initialValue);

  const handleLocalSubmit = (e) => {
    e.preventDefault();
    handleSubmit({ remarks });
  };

  const handleChange = (e) => setRemarks(e.target.value);

  return (
    <>
      <ModalHeader textAlign={'center'} py={1}>
        {title}
      </ModalHeader>
      {subtitle?.length && (
        <Text
          textAlign={'center'}
          fontSize={'0.75rem'}
          color={'shadedGray.400'}
          py={1}
        >
          {subtitle}
        </Text>
      )}

      <ModalCloseButton />
      <ModalBody>
        <FormControl as={'form'} onSubmit={handleLocalSubmit} px={2}>
          <VStack gap={2} w={'100%'} alignItems={'stretch'}>
            <VStack alignItems={'flex-start'}>
              <FormLabel
                fontSize={'0.9rem'}
                fontWeight={'500'}
                mb={0}
                lineHeight={'2rem'}
                pr={2}
              >
                {label}
              </FormLabel>

              <Textarea
                my={2}
                className={'scroll-sm'}
                resize={'none'}
                size={'sm'}
                pl={3}
                fontSize={'0.75rem'}
                fontWeight={'600'}
                type="text"
                rounded="lg"
                bg={'shadedGray.400'}
                color={'shadedGray.800'}
                border={0}
                placeholder={placeholder}
                _placeholder={{ opacity: 1, color: 'shadedGray.600' }}
                value={remarks}
                onChange={handleChange}
                name={'remarks'}
                required
              />
            </VStack>
          </VStack>
          <ModalFooter justifyContent={'center'}>
            <Button
              colorScheme="brand"
              color="altGray.400"
              mr={3}
              size={'sm'}
              fontSize={'0.75rem'}
              type="submit"
              isLoading={isLoading}
            >
              Submit Now
            </Button>
          </ModalFooter>
        </FormControl>
      </ModalBody>
    </>
  );
};

export default ConfirmWithRemarksModal;

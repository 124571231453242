import React from 'react';
import { MdArrowBack } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

const BackButton = () => {
  const navigate = useNavigate();

  return (
    <MdArrowBack
      cursor={'pointer'}
      style={{ alignSelf: 'flex-start' }}
      color="gray"
      size={'2rem'}
      onClick={() => {
        navigate(-1);
      }}
    />
  );
};

export default BackButton;

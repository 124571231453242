import { useEffect, useRef } from 'react';

/* this hook is a wrapper over useEffect hook, so that it does not trigger on first render */
const useDidMountEffect = (func, deps) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
  }, deps);
};

export default useDidMountEffect;

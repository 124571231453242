import { VStack, Text, HStack, Spinner, Button } from '@chakra-ui/react';
import React, { useState } from 'react';
import api from '../../api';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../store/actions/alertActions';
import { triggerWorkflowByDoc } from '../../store/actions/workFlowsActionsV2';
import { useParams } from 'react-router-dom';

const WorkflowDocCard = ({ doc, hasOcr = false }) => {
  const { userId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const handleCardClick = () => {
    setIsLoading(true);
    api.workflow
      .docByDocUrl({ docUrl: doc.urlPath })
      .then((res) => res.data)
      .then((data) => window.open(data.url))
      .catch((err) => {
        console.error(err?.message);
        dispatch(
          setAlert({
            title: 'Something went wrong while fetching the document!',
            status: 'error'
          })
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleTriggerOCRClick = (e) => {
    e?.stopPropagation();
    setIsLoading(true);
    dispatch(
      triggerWorkflowByDoc({
        userId,
        subDocType: doc?.documentId,
        documentKey: doc?.urlPath,
        onSuccess: () => {
          /* TODO: Figure out a way to reload the OCR Data */
        },
        onComplete: () => {
          setIsLoading(false);
        }
      })
    );
  };

  const OCR_REQUIRED_DOCS = [
    'BANK_STATEMENT',
    'PASSPORT_FRONT',
    'PASSPORT_BACK',
    'BRP_FRONT',
    'BRP_BACK',
    'EMIRATES_ID',
    'VISA'
  ];

  return (
    <VStack
      cursor={'pointer'}
      bg="backGround"
      borderRadius="12px"
      w={'52rem'}
      maxW={'100%'}
      onClick={handleCardClick}
      alignItems={'flex-start'}
      p={4}
    >
      <HStack justifyContent={'space-between'} w={'90%'}>
        <Text color={'white'}>{doc.documentId}</Text>
        {isLoading && <Spinner size={'md'} color={'brand.200'}></Spinner>}
        {hasOcr && (
          <Button
            variant={'link'}
            color={'gray'}
            disabled={isLoading}
            isLoading={isLoading}
            onClick={handleTriggerOCRClick}
          >
            {!isLoading ? 'Trigger OCR' : <Spinner />}
          </Button>
        )}
      </HStack>
    </VStack>
  );
};

export default WorkflowDocCard;

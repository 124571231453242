const SOURCE_ACCOUNTS = {
  GETTING_SOURCE_ACCOUNTS: 'GETTING_INVESTMENT_SOURCE_ACCOUNTS',
  GET_SOURCE_ACCOUNTS_SUCCESS: 'GET_INVESTMENT_SOURCE_ACCOUNTS_SUCCESS',
  GET_SOURCE_ACCOUNTS_FAILURE: 'GET_INVESTMENT_SOURCE_ACCOUNTS_FAILURE',

  GETTING_USER_SOURCE_ACCOUNTS: 'GETTING_USER_SOURCE_ACCOUNTS',
  GET_USER_SOURCE_ACCOUNTS_SUCCESS: 'GET_USER_SOURCE_ACCOUNTS_SUCCESS',
  GET_USER_SOURCE_ACCOINTS_FAILURE: 'GET_USER_SOURCE_ACCOINTS_FAILURE',
  DELETING_USER_SOURCE_ACCOUNT: 'DELETING_USER_SOURCE_ACCOUNT',
  DELETE_USER_SOURCE_ACCOUNT_SUCCESS: 'DELETE_USER_SOURCE_ACCOUNT_SUCCESS',
  DELETE_USER_SOURCE_ACCOUNT_FAILURE: 'DELETE_USER_SOURCE_ACCOUNT_FAILURE'
};

export default SOURCE_ACCOUNTS;

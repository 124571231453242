const THUNES_COLLECTION = {
  GETTING_THUNES_ON_HOLD_ORDERS: 'GETTING_THUNES_ON_HOLD_ORDERS',
  GET_THUNES_ON_HOLD_ORDERS_SUCCESS: 'GET_THUNES_ON_HOLD_ORDERS_SUCCESS',
  GET_THUNES_ON_HOLD_ORDERS_FAILURE: 'GET_THUNES_ON_HOLD_ORDERS_FAILURE',

  FORCE_INITIATING_TRANSFER: 'FORCE_INITIATING_TRANSFER',
  FORCE_INITIATE_TRANSFER_SUCCESS: 'FORCE_INITIATE_TRANSFER_SUCCESS',
  FORCE_INITIATE_TRANSFER_FAILURE: 'FORCE_INITIATE_TRANSFER_FAILURE',

  GETTING_THUNES_ON_HOLD_FULFILLMENTS: 'GETTING_THUNES_ON_HOLD_FULFILLMENTS',
  GET_THUNES_ON_HOLD_FULFILLMENTS_SUCCESS:
    'GET_THUNES_ON_HOLD_FULFILLMENTS_SUCCESS',
  GET_THUNES_ON_HOLD_FULFILLMENTS_FAILURE:
    'GET_THUNES_ON_HOLD_FULFILLMENTS_FAILURE',

  FORCE_INITIATING_FULFILLMENT: 'FORCE_INITIATING_FULFILLMENT',
  FORCE_INITIATE_FULFILLMENT_SUCCESS: 'FORCE_INITIATE_FULFILLMENT_SUCCESS',
  FORCE_INITIATE_FULFILLMENT_FAILURE: 'FORCE_INITIATE_FULFILLMENT_FAILURE',

  GET_FULFILLMENTS_BY_ORDER_ID_LOADING: 'GET_FULFILLMENTS_BY_ORDER_ID_LOADING',
  GET_FULFILLMENTS_BY_ORDER_ID_SUCCESS: 'GET_FULFILLMENTS_BY_ORDER_ID_SUCCESS',
  GET_FULFILLMENTS_BY_ORDER_ID_FAILURE: 'GET_FULFILLMENTS_BY_ORDER_ID_FAILURE',

  GET_RECENT_ORDERS_LOADING: 'GET_RECENT_ORDER_LOADING',
  GET_RECENT_ORDERS_SUCCESS: 'GET_RECENT_ORDERS_SUCCESS',
  GET_RECENT_ORDERS_FAILURE: 'GET_RECENT_ORDERS_FAILURE',

  GET_FULFILLMENT_ORDER_DETAILS_LOADING:
    'GET_FULFILLMENT_ORDER_DETAILS_LOADING',
  GET_FULFILLMENT_ORDER_DETAILS_SUCCESS:
    'GET_FULFILLMENT_ORDER_DETAILS_SUCCESS',
  GET_FULFILLMENT_ORDER_DETAILS_FAILURE:
    'GET_FULFILLMENT_ORDER_DETAILS_FAILURE',

  SET_FULFILLMENTS_TO_INITIAL_STATE: 'SET_FULFILLMENTS_TO_INITIAL_STATE'
};

export default THUNES_COLLECTION;

import {
  Divider,
  Grid,
  Heading,
  Spinner,
  Table,
  Tbody,
  Text,
  Thead,
  Tr,
  Td,
  VStack,
  HStack,
  Button,
  useDisclosure,
  IconButton,
  Switch,
  FormControl,
  FormLabel,
  Box
} from '@chakra-ui/react';
import ContentLayout from '../../layouts/ContentLayout';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  createPot,
  deletePot,
  getForexRate,
  getRdaPots
} from '../../store/actions/rdaActions';
import Pagination from '../../components/Pagination';
import { tableCellStyles } from '../../components/NrOnboard/constants';
import dayjs from 'dayjs';
import ModalLayout from '../../components/Modal/ModalLayout';
import CreatePot from '../../components/Rda/modal/CreatePot';
import ConfirmModal from '../../components/Modal/ConfirmForceInitiate';
import { MdOutlineDelete } from 'react-icons/md';
import RdaFxRate from '../../components/Rda/FxRate';
import { updateRdaReqData } from '../../store/actions/reqDataActions';
import GenericUpdateModal from '../../components/Modal/GenericUpdateModal';

const Rda = ({}) => {
  const { page, pageSize, active } = useSelector(
    (state) => state?.reqData?.rda
  );

  const {
    isOpen: isCreateModalOpen,
    onClose: onCreateModalClose,
    onOpen: onCreateModalOpen
  } = useDisclosure();

  const {
    isOpen: isDeleteModalOpen,
    onOpen: onDeleteModalOpen,
    onClose: onDeleteModalClose
  } = useDisclosure();

  const dispatch = useDispatch();

  const {
    isLoading,
    data: pots,
    totalPages
  } = useSelector((state) => state?.rda);

  const [selectedPot, setSelectedPot] = useState(null);
  const [btnLoading, setBtnLoading] = useState(false);

  useEffect(() => {
    dispatch(
      getRdaPots({
        active,
        pageSize,
        page
      })
    );
  }, []);

  const cellStyles = tableCellStyles();

  const handleCreatePot = ({ amount, fxRate }) => {
    setBtnLoading(true);
    dispatch(
      createPot({
        amount,
        fxRate,
        onComplete: () => {
          setBtnLoading(false);
          onCreateModalClose();
        },
        onSuccess: () => {
          dispatch(
            getRdaPots({
              active,
              page,
              pageSize
            })
          );
        }
      })
    );
  };

  const handleDeletePot = () => {
    setBtnLoading(true);
    dispatch(
      deletePot({
        potId: selectedPot,
        onComplete: () => {
          setBtnLoading(false);
          onDeleteModalClose();
        },
        onSuccess: () => {
          setSelectedPot(null);
          dispatch(
            getRdaPots({
              active,
              page,
              pageSize
            })
          );
        }
      })
    );
  };

  useEffect(() => {
    dispatch(
      getRdaPots({
        active,
        pageSize,
        page
      })
    );
  }, [page, active]);

  const handlePageChange = (val) => {
    dispatch(
      updateRdaReqData({
        page: val,
        pageSize,
        active
      })
    );
  };

  const handleActiveToggle = () => {
    dispatch(
      updateRdaReqData({
        page,
        pageSize,
        active: !active
      })
    );
  };

  return (
    <>
      <ModalLayout isOpen={isCreateModalOpen} onClose={onCreateModalClose}>
        <GenericUpdateModal
          title="Create Pot"
          data={{
            amount: 0,
            fxRate: 0
          }}
          onSubmit={handleCreatePot}
          onCancel={onCreateModalClose}
          isLoading={btnLoading}
        />
      </ModalLayout>
      <ModalLayout
        isOpen={isDeleteModalOpen}
        onClose={() => {
          onDeleteModalClose();
          setSelectedPot(null);
        }}
      >
        <ConfirmModal
          prompt={'Are you sure you want to archive this pot?'}
          handleSubmit={handleDeletePot}
          isLoading={btnLoading}
        />
      </ModalLayout>
      {/* <ContentLayout>
        <Grid gridTemplateColumns={{ base: '1fr', lg: '7fr 0fr 2fr' }}> */}
      <VStack
        gap={'30px'}
        overflowY={{ base: 'initial', lg: 'scroll' }}
        overflowX={'hidden'}
        h={{ lg: '90vh' }}
        py={10}
        px={{ base: 4, sm: 10 }}
        alignItems={'stretch'}
        className="scroll"
      >
        <Heading textColor={'white'} size={'md'}>
          RDA
        </Heading>
        <HStack width={'full'} justifyContent={'space-between'}>
          <RdaFxRate />
          <Box>
            <FormControl>
              <FormLabel color={'white'} fontSize={'md'}>
                Active only
              </FormLabel>
              <Switch
                colorScheme="brand"
                isChecked={active}
                size={'md'}
                onChange={handleActiveToggle}
              />
            </FormControl>
          </Box>
          <Button
            variant={'outline'}
            colorScheme="brand"
            onClick={onCreateModalOpen}
          >
            Create Pot
          </Button>
        </HStack>
        <Pagination
          currentPage={page}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
        {isLoading ? (
          <Spinner />
        ) : (
          pots?.length > 0 && (
            <Table>
              <Thead>
                <Tr>
                  <Td style={cellStyles}>
                    <Text color={'gray'} fontSize={'medium'}>
                      ID
                    </Text>
                  </Td>
                  <Td style={cellStyles}>
                    <Text color={'gray'} fontSize={'medium'}>
                      Current Balance
                    </Text>
                  </Td>
                  <Td style={cellStyles}>
                    <Text color={'gray'} fontSize={'medium'}>
                      LockIn Balance
                    </Text>
                  </Td>
                  <Td style={cellStyles}>
                    <Text color={'gray'} fontSize={'medium'}>
                      Fx Rate
                    </Text>
                  </Td>
                  <Td style={cellStyles}>
                    <Text color={'gray'} fontSize={'medium'}>
                      Active
                    </Text>
                  </Td>
                  <Td style={cellStyles}>
                    <Text color={'gray'} fontSize={'medium'}>
                      Created At
                    </Text>
                  </Td>
                </Tr>
              </Thead>
              <Tbody>
                {pots?.map((pot) => (
                  <Tr key={pot?.id}>
                    <Td style={cellStyles}>
                      <Text color={'white'} fontSize={'medium'}>
                        {pot?.id}
                      </Text>
                    </Td>
                    <Td style={cellStyles}>
                      <Text color={'white'} fontSize={'medium'}>
                        {pot?.currentBalance}
                      </Text>
                    </Td>
                    <Td style={cellStyles}>
                      <Text color={'white'} fontSize={'medium'}>
                        {pot?.lockInBalance}
                      </Text>
                    </Td>
                    <Td style={cellStyles}>
                      <Text color={'white'} fontSize={'medium'}>
                        {pot?.fxRate}
                      </Text>
                    </Td>
                    <Td style={cellStyles}>
                      <Text color={'white'} fontSize={'medium'}>
                        {String(!pot?.archived)}
                      </Text>
                    </Td>
                    <Td style={cellStyles}>
                      <Text color={'white'} fontSize={'medium'}>
                        {dayjs(pot?.createdAt).format('DD MMM YYYY')}
                      </Text>
                    </Td>
                    <Td style={cellStyles}>
                      <IconButton
                        colorScheme="brand"
                        icon={<MdOutlineDelete color="red" />}
                        variant={'unstyled'}
                        disabled={pot?.archived}
                        onClick={() => {
                          setSelectedPot(pot?.id);
                          onDeleteModalOpen();
                        }}
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          )
        )}
        {!isLoading && pots.length === 0 && (
          <Text color={'white'} fontSize={'medium'}>
            No pots available
          </Text>
        )}
      </VStack>
      {/* <Divider
            style={{ margin: '0' }}
            borderColor="whiteAlpha.300"
            orientation="vertical"
          ></Divider>
        </Grid>
      </ContentLayout> */}
    </>
  );
};

export default Rda;

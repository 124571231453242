import { ORDER_SCREEN_CONFIG as types } from '../constants';

const initialState = {
  isLoading: false,
  isError: false,
  error: null,
  data: {
    // ref for order
    orderId: null,
    screenConfig: [],
    isComplianceFlag: true
  },
  prev: {
    // ref for order
    orderId: null,
    screenConfig: [],
    isComplianceFlag: true,
    isAdditionalDetailsRequired: false
  }
};

const orderScreenConfigReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case types.UPDATE_SCREEN_CONF_LOCAL:
      return {
        ...state,
        data: payload
      };
    case types.GETTING_SCREEN_CONFIG:
      return {
        ...state,
        isLoading: true,
        isError: false,
        error: null
      };
    case types.GET_ORDER_SCREEN_CONF_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        error: null,
        data: {
          orderId: payload?.orderId,
          screenConfig: payload?.screenConfig,
          isComplianceFlag: payload?.isComplianceFlag,
          isAdditionalDetailsRequired: payload?.isAdditionalDetailsRequired
        },
        prev: {
          orderId: payload?.orderId,
          screenConfig: payload?.screenConfig,
          isComplianceFlag: payload?.isComplianceFlag,
          isAdditionalDetailsRequired: payload?.isAdditionalDetailsRequired
        }
      };
    case types.GET_SCREEN_CONFIG_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: payload?.errMsg
      };
    default:
      return state;
  }
};

export default orderScreenConfigReducer;

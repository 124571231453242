export default function vanceCash(fetch, baseUrl) {
  return {
    getAllTransactions({ userId, page, pageSize }) {
      return fetch(
        `${baseUrl}/${userId}/vance-cash/transactions?page=${page}&page_size=${pageSize}`
      );
    },
    creditVanceCash({ amount, remarks, userId }) {
      return fetch.put(`${baseUrl}/${userId}/update-user-wallet`, {
        amount,
        remarks
      });
    },
    syncVanceCashWallet({ userId }) {
      return fetch.put(`${baseUrl}/${userId}/sync-wallet`);
    }
  };
}

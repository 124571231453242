import {
  Box,
  Button,
  FormLabel,
  HStack,
  Input,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Select,
  VStack
} from '@chakra-ui/react';
import { useState } from 'react';
import { ORDER_ACQUIRER } from '../../constants';

const UpdateTurnAroundTimeModal = ({
  isLoading,
  initialTat,
  onCancel,
  onSubmit
}) => {
  const initialState = !initialTat
    ? {
        type: '',
        value: ''
      }
    : initialTat;
  const [tat, setTat] = useState(initialState);

  const handleCancel = () => {
    setTat(initialState);
    onCancel && onCancel();
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    onSubmit && onSubmit(tat);
  };

  return (
    <>
      <ModalHeader>
        {!initialTat ? 'Create New TAT' : `Update TAT - ${tat?.type}`}
      </ModalHeader>
      <form onSubmit={handleSubmit}>
        <ModalBody>
          <VStack gap={3} alignItems={'stretch'}>
            {!initialTat && (
              <Box>
                <FormLabel>Acquirer</FormLabel>
                <Select
                  required
                  value={tat?.type}
                  onChange={(e) => {
                    setTat((prev) => ({ ...prev, type: e?.target?.value }));
                  }}
                  placeholder="select acquirer"
                >
                  {Object.keys(ORDER_ACQUIRER).map((acquirer) => (
                    <option value={acquirer} key={acquirer}>
                      {acquirer}
                    </option>
                  ))}
                </Select>
              </Box>
            )}
            <Box>
              <FormLabel>Turn Around Time</FormLabel>
              <Input
                value={tat?.value}
                onChange={(e) => {
                  setTat((prev) => ({
                    ...prev,
                    value: e?.target?.value
                  }));
                }}
              />
            </Box>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <HStack justifyContent={'end'} gap={2}>
            <Button
              isDisabled={isLoading}
              variant={'outline'}
              colorScheme="red"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              isLoading={isLoading}
              type="submit"
              variant={'outline'}
              colorScheme="brand"
            >
              Update
            </Button>
          </HStack>
        </ModalFooter>
      </form>
    </>
  );
};

export default UpdateTurnAroundTimeModal;

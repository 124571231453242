import {
  Box,
  FormLabel,
  Heading,
  HStack,
  Input,
  Select,
  Spinner,
  Text,
  VStack
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Pagination from '../../Pagination';
import TxnDetailsCard from './TxnDetailsCard';
import GenericFiltersComponent from '../common/FilterBuilder';

const TxnScreenTemplate = ({
  title,
  action,
  reqParams,
  txnData,
  handlePageChange,
  namePrefix,
  handleParamsChange,
  cardType,
  featureFilters = {}
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(action({ ...reqParams }));
  }, [...Object.values(reqParams)]);

  const { pageNumber, pageSize } = reqParams;
  const { isLoading, isError, error, data, totalPages } = txnData;

  return (
    <VStack alignItems={'stretch'} width={'full'} gap={2}>
      <Heading color={'white'} size={'md'}>
        {title}
      </Heading>
      <GenericFiltersComponent
        features={featureFilters}
        featuresState={reqParams}
        onChange={handleParamsChange}
      />
      <Pagination
        currentPage={pageNumber + 1}
        totalPages={totalPages}
        onPageChange={(e) => handlePageChange(e, namePrefix)}
      />
      {isError && (
        <Text color={'gray.500'} fontSize={'md'}>
          {error}
        </Text>
      )}
      {isLoading && <Spinner color="#81ebab" />}
      {!isLoading &&
        (data.length > 0 ? (
          <VStack alignItems={'stretch'} width={'full'} gap={3}>
            {data?.map((value) => (
              <TxnDetailsCard cardType={cardType} {...value} />
            ))}
          </VStack>
        ) : (
          <Text color={'gray.500'} fontSize={'md'}>
            No Txns Found
          </Text>
        ))}
    </VStack>
  );
};

export default TxnScreenTemplate;

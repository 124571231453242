import {
  Button,
  HStack,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Select
} from '@chakra-ui/react';
import { NROB_ATTESTED_DOCS } from '../../constants';
import { useState } from 'react';

const UpdateAttestedDocsModal = ({
  attestedDocsStatus = NROB_ATTESTED_DOCS.NEW,
  isLoading = false,
  handleSubmit,
  handleCancel
}) => {
  const [status, setStatus] = useState(attestedDocsStatus);
  const getStatusEnums = (status) => {
    switch (status) {
      case NROB_ATTESTED_DOCS.NEW:
        return [NROB_ATTESTED_DOCS.DISPATCHED, NROB_ATTESTED_DOCS.DELIVERED];
      case NROB_ATTESTED_DOCS.DISPATCHED:
        return [NROB_ATTESTED_DOCS.DELIVERED];
      case NROB_ATTESTED_DOCS.DELIVERED:
        return [];
      default:
        return Object.keys(NROB_ATTESTED_DOCS);
    }
  };
  return (
    <>
      <ModalHeader>Attested Docs Status</ModalHeader>
      <ModalBody>
        <Select
          name="status"
          value={status}
          onChange={(e) => {
            setStatus(e?.target?.value);
          }}
          placeholder="Choose status to update"
          disabled={isLoading}
        >
          {getStatusEnums(attestedDocsStatus).map((i) => (
            <option key={i} value={i}>
              {i}
            </option>
          ))}
        </Select>
      </ModalBody>
      <ModalFooter>
        <HStack>
          <Button
            isLoading={isLoading}
            variant={'outline'}
            colorScheme="red"
            onClick={() => {
              handleCancel && handleCancel();
            }}
          >
            Cancel
          </Button>
          <Button
            variant={'outline'}
            colorScheme="green"
            onClick={() => {
              handleSubmit && handleSubmit(status);
            }}
            isLoading={isLoading}
          >
            Update
          </Button>
        </HStack>
      </ModalFooter>
    </>
  );
};

export default UpdateAttestedDocsModal;

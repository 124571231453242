import {
  Heading,
  HStack,
  VStack,
  Link,
  Text,
  Flex,
  Box,
  Grid,
  Divider,
  Button
} from '@chakra-ui/react';
import React, { useEffect, useRef } from 'react';
import { BsArrowRight } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { Link as ReactLink, useSearchParams } from 'react-router-dom';
import CampaignCard from '../../components/CampaignCard';
import Pagination from '../../components/Pagination';
import ContentLayout from '../../layouts/ContentLayout';
import { searchCampaigns } from '../../store/actions/referralActions';
import { updateCampaignsReqData } from '../../store/actions/reqDataActions';
import useDidMount from '../../hooks/useDidMount';
import useIsAdmin from '../../hooks/useIsAdmin';
import InputComponent from '../../components/Input';
import { MdSearch } from 'react-icons/md';

const Campaigns = () => {
  const dispatch = useDispatch();
  const isAdmin = useIsAdmin();
  const [searchParams] = useSearchParams();
  const refresh = JSON.parse(searchParams.get('refresh'));
  const searchTimer = useRef(null);

  const { isLoading, data, totalPages } = useSelector(
    (state) => state.referral.campaign.campaignList
  );

  const searchReqData = useSelector(
    (state) => state.reqData.referral.campaigns
  );

  const campaignList = data[searchReqData.pageNo];

  useDidMount(() => {
    getCampaigns();
  }, [searchReqData.pageNo]);

  useDidMount(() => {
    if (searchTimer.current) {
      clearTimeout(searchTimer.current);
      searchTimer.current = setTimeout(() => {
        getCampaigns(true);
      }, 2000);
    } else {
      getCampaigns(true);
      searchTimer.current = true;
    }
  }, [searchReqData.query]);

  useEffect(() => {
    if (refresh) {
      //reset page number and refetch data
      dispatch(
        updateCampaignsReqData({
          pageNo: 0
        })
      );
      dispatch(
        searchCampaigns(
          {
            pageNo: 0,
            pageSize: 10,
            query: searchReqData.query
          },
          true
        )
      );
    } else if (!campaignList?.length) dispatch(searchCampaigns(searchReqData));
  }, []);

  const getCampaigns = (refresh = false) => {
    if (refresh || !campaignList?.length)
      dispatch(searchCampaigns(searchReqData));
  };

  const handleSearchReqChange = (e) => (e) => {
    dispatch(
      updateCampaignsReqData({
        query: e.target.value,
        pageNo: 0
      })
    );
  };

  const handlePageChange = (pageNo) => {
    pageNo - 1 !== searchReqData.pageNo &&
      dispatch(updateCampaignsReqData({ pageNo: pageNo - 1 }));
  };

  return (
    <ContentLayout>
      <Grid gridTemplateColumns={{ base: '1fr', lg: '7fr 0fr 2fr' }}>
        <VStack
          p={10}
          gap={4}
          overflowY={{ base: 'initial', lg: 'scroll' }}
          overflowX={'hidden'}
          h={{ lg: '90vh' }}
          px={{ base: 4, sm: 10 }}
          alignItems={'stretch'}
          className="scroll"
        >
          <Flex
            w={'100%'}
            alignItems={'baseline'}
            justifyContent={'space-between'}
            pb={2}
          >
            <Heading color={'colorPrimary'}>Active Campaigns</Heading>
            {isAdmin && (
              <Link
                as={ReactLink}
                to="/campaign/create"
                color="brand.500"
                justifyContent={'flex-start'}
              >
                <HStack>
                  <Text>Create new campaign</Text>
                  <BsArrowRight display={'inline'} />
                </HStack>
              </Link>
            )}
          </Flex>
          <VStack>
            <InputComponent
              type="text"
              value={searchReqData.query}
              name="search"
              handleChange={handleSearchReqChange('query')}
              placeholder="Search by campaign name"
              leftElement={<MdSearch size="2rem" color="white" />}
              width="40rem"
              maxWidth="100%"
              autoComplete={'off'}
              spellcheck={'false'}
            />

            <Button
              type="submit"
              color="black"
              rounded="lg"
              colorScheme="brand"
              my={5}
              py={2}
              onClick={() => dispatch(searchCampaigns(searchReqData))}
              fontWeight={600}
              isLoading={isLoading}
              alignSelf="start"
              style={{
                marginTop: '1em'
              }}
            >
              Search
            </Button>
          </VStack>
          {totalPages > 0 && (
            <Box alignSelf={'start'}>
              <Pagination
                totalPages={totalPages}
                currentPage={searchReqData.pageNo + 1}
                onPageChange={handlePageChange}
              />
            </Box>
          )}
          <VStack alignSelf={'flex-start'} maxW={'100%'} gap={1}>
            {campaignList?.length > 0 &&
              campaignList.map((item) => (
                <CampaignCard key={item.id} campaign={item} />
              ))}
          </VStack>
        </VStack>
        <Divider
          style={{ margin: '0' }}
          borderColor="whiteAlpha.300"
          orientation="vertical"
        ></Divider>
      </Grid>
    </ContentLayout>
  );
};

export default Campaigns;

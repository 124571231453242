import {
  Button,
  FormControl,
  FormLabel,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  Select,
  Text,
  VStack
} from '@chakra-ui/react';
import React, { useCallback, useState } from 'react';

const UpdateJobStatusModal = ({
  allowedJobStatus,
  currentJobStatus,
  jobId,
  handleSubmit,
  isLoading
}) => {
  const [jobStatus, setJobStatus] = useState(currentJobStatus);

  const handleChange = useCallback((e) => {
    setJobStatus(e.target.value);
  }, []);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleSubmit({
      jobId,
      jobStatus
    });
  };
  return (
    <>
      <ModalHeader textAlign={'center'} py={0}>
        Update Job Status
      </ModalHeader>
      <Text textAlign={'center'} fontSize={'0.75rem'} color={'shadedGray.400'}>
        for job {jobId}
      </Text>
      <ModalCloseButton />
      <ModalBody>
        <FormControl as={'form'} onSubmit={handleFormSubmit} px={2}>
          <VStack gap={2} w={'100%'} alignItems={'stretch'}>
            <VStack alignItems={'flex-start'}>
              <FormLabel
                fontSize={'0.9rem'}
                fontWeight={'500'}
                mb={0}
                lineHeight={'2rem'}
                pr={2}
              >
                New Status :
              </FormLabel>
              <Select
                rounded={'lg'}
                placeholder={'Enter new job status'}
                name={'job status'}
                value={jobStatus}
                onChange={handleChange}
                size={'sm'}
                w={'100%'}
                _focusVisible={{
                  boxShadow: 'brand.200',
                  borderColor: 'brand.200'
                }}
                required
              >
                {allowedJobStatus.map((status) => (
                  <option key={status} value={status} my={3}>
                    {status}
                  </option>
                ))}
              </Select>
            </VStack>
          </VStack>
          <ModalFooter justifyContent={'center'}>
            <Button
              colorScheme="brand"
              color="altGray.400"
              mr={3}
              size={'sm'}
              fontSize={'0.75rem'}
              type="submit"
              isLoading={isLoading}
            >
              Submit Now
            </Button>
          </ModalFooter>
        </FormControl>
      </ModalBody>
    </>
  );
};

export default UpdateJobStatusModal;

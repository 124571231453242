export default function (fetch, baseUrl) {
  return {
    getFeatureFlagValue({ featureName }) {
      return fetch.get(`${baseUrl}/${featureName}`);
    },
    updateFeatureFlagValue({ featureName, featureValue }) {
      return fetch.put(baseUrl, {
        featureName,
        featureValue
      });
    }
  };
}

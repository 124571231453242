/* fields */
export const FILE_FIELDS = {
  selfieDocUrl: 'selfieDocUrl',
  signatureDocUrl: 'signatureDocUrl',
  notarizedPassportFrontDocUrl: 'notarizedPassportFrontDocUrl',
  notarizedPassportBackDocUrl: 'notarizedPassportBackDocUrl',
  notarizedMailingAddressDocUrl: 'notarizedMailingAddressDocUrl',
  visaDocUrl: 'visaDocUrl',
  existingBankAccountNo: 'existingBankAccountNo',
  passportFrontDocUrl: 'passportFrontDocUrl',
  passportBackDocUrl: 'passportBackDocUrl',
  brpFrontDocUrl: 'brpFrontDocUrl',
  brpBackDocUrl: 'brpBackDocUrl',
  bankStatementDocUrl: 'bankStatementDocUrl',
  emiratesIdDocUrl: 'emiratesIdDocUrl'
};

export const READ_ONLY_FIELDS = {
  journeyStep: 'journeyStep',
  userId: 'userId'
};

export const DATE_FIELDS = {
  passportIssueDate: 'passportIssueDate',
  visaIssueDate: 'visaIssueDate',
  visaExpiryDate: 'visaExpiryDate',
  passportExpiryDate: 'passportExpiryDate',
  dob_nagd: 'dob_nagd',
  startDateOfNewContract_seafarer: 'startDateOfNewContract_seafarer',
  dateOfArrivalLastJourney_seafarer: 'dateOfArrivalLastJourney_seafarer',
  dateOfDepartureLastJourney_seafarer: 'dateOfDepartureLastJourney_seafarer'
};

export const BLOCKED_FIELDS = {
  userId: 'userId',
  modeOfOperation: 'modeOfOperation',
  accountType_isSingle: 'accountType_isSingle'
};

export const DROP_DOWN_FIELDS = {
  accountType_isSingle: 'accountType_isSingle', // YES NO
  prefix: 'prefix', // PREFIX ENUM
  gender: 'gender', // GENDER ENUM
  countryCode: 'countryCode', // COUNTRY CODES ENUM
  maritalStatus: 'maritalStatus', // MARITAL STATUS ENUM
  overseasResidence: 'overseasResidence', // COUNTRY CODES ENUM
  productId: 'productId', // YBL PRODUCT ID
  country: 'country', // COUNTRY CODES ENUM
  productType_hb: 'productType_hb', // YBL PRODUCT ID
  programType_hb: 'programType_hb', // PROGRAM_TYPE_HB_ENUM
  sourceOfFund_pd: 'sourceOfFund_pd', // SOURCE OF FUND ENUM
  educationalQualification_pd: 'educationalQualification_pd', // EDUCATION QUALIFICATION ENUM
  residentialStatus_pd: 'residentialStatus_pd', // RESIDENTIAL STATUS ENUM
  residenceType_pd: 'residenceType_pd', // RESIDENCE TYPE ENUM
  annualIncome_pd: 'annualIncome_pd', // ANNUAL INCOME ENUM
  countryOfCitizenship_pd: 'countryOfCitizenship_pd', // COUNTRY CODE ENUM
  employedWith_pd: 'employedWith_pd', // EMPLOYED WITH ENUM
  natureOfBusiness_pd: 'natureOfBusiness_pd', //  NATURE OF BUSINESS ENUM
  currentAccountType_pd: 'currentAccountType_pd', // current account type
  seaFererOnBank_pd: 'seaFererOnBank_pd', // YES NO
  seaFarerOnBreak_pd: 'seaFarerOnBreak_pd', // YES NO
  companyType_pd: 'companyType_pd', // COMPANY TYPE ENUM
  countryOfBirth_pd: 'countryOfBirth_pd', // COUNTRY CODE ENUM
  employmentType_pd: 'employmentType_pd', // EMPLOYMENT TYPE ENUM
  profession_pd: 'profession_pd', // PROFESSION
  natureOfIndustry_pd: 'natureOfIndustry_pd', // NATURE OF INDUSTRY
  customerAccountType_pd: 'customerAccountType_pd', // CUSTOMER ACCOUNT TYPE
  customerType_pd: 'customerType_pd', // CUSTOMER TYPE
  addNominee_nagd: 'addNominee_nagd', // YES NO ENUM
  nomineeRelationshipId_nagd: 'nomineeRelationshipId_nagd', // NOMINEE RELATIONSHIP ID
  country_na: 'country_na', // COUNTRY CODE
  country_ga: 'country_ga', // COUNTRY CODE
  debitCard_cc: 'debitCard_cc', // YES NO ENUM
  sms_cc: 'sms_cc', // YES NO
  netBanking_cc: 'netBanking_cc', // YES NO
  estatement_cc: 'estatement_cc', // YES NO
  pepConsent_cc: 'pepConsent_cc', // YES NO
  accCibilConsent_cc: 'accCibilConsent_cc', // YES NO
  tandCConsent_cc: 'tandCConsent_cc', // YES NO
  email_cc: 'email_cc', // YES NO
  prodAndOffersUpdate_cc: 'prodAndOffersUpdate_cc', // BOOLEAN
  fatcaAndConsent_cc: 'fatcaAndConsent_cc', // BOOLEAN
  acceptTermsAndConditionsConsent_cc: 'acceptTermsAndConditionsConsent_cc', // BOOLEAN
  isPoi_pio: 'isPoi_pio', // BOOLEAN
  hasIndianPassport_pio: 'hasIndianPassport_pio', // BOOLEAN
  hasSpouseIndianPassport_pio: 'hasSpouseIndianPassport_pio', // BOOLEAN
  isCitizenByVoc_pio: 'isCitizenByVoc_pio', // BOOLEAN
  relationId_pio: 'relationId_pio', // RELATIONSHIP TYPE
  isNriStatusAltered_seafarer: 'isNriStatusAltered_seafarer', // BOOLEAN
  isaSeafarerOnBreak_seafarer: 'isaSeafarerOnBreak_seafarer', // BOOLEAN
  countryId_cad: 'countryId_cad', // COUNTRY CODE
  incomeTaxDecConsent_f60d: 'incomeTaxDecConsent_f60d', // BOOLEAN
  appliedForPan_f60d: 'appliedForPan_f60d', // BOOLEAN
  taxIdType_fatcaDetail: 'taxIdType_fatcaDetail', // TAX IDENTIFICATION TYPE
  infoDecConsent_fatcaDetail: 'infoDecConsent_fatcaDetail', // YES NO
  countryForJuris_fatcaDetail: 'countryForJuris_fatcaDetail', // COUNTRY CODE
  nationality_fatcaDetail: 'nationality_fatcaDetail', // COUNTRY CODE
  residentAddress_fatcaDetail: 'residentAddress_fatcaDetail', // COUNTRY CODE
  outSiderIndia_fatcaDetail: 'outSiderIndia_fatcaDetail', // YES NO
  getPermAddForTax_fatcaDetail: 'getPermAddForTax_fatcaDetail', // BOOLEAN
  countryJurisId_resAddress_fatcaDetail:
    'countryJurisId_resAddress_fatcaDetail', // COUNTRY CODE
  taxIdTypeId_resAddress_fatcaDetail: 'taxIdTypeId_resAddress_fatcaDetail' // TAX IDENTIFICATION TYPE
};
/* fields */

/* Dropdown fields */
export const BOOLEAN_TYPE = {
  false: false,
  true: true
};

export const YES_NO = {
  YES: 'YES',
  NO: 'NO'
};

export const YBL_PRODUCT_TYPE = {
  NRE: 'NRE',
  NRO: 'NRO'
};

export const RESIDENTIAL_STATUS = {
  NRI: 'NRI',
  PIO: 'PTO'
};

export const EDUCATIONAL_QUALIFICATIONS = {
  PROFESSIONAL: 'PROFESSIONAL',
  POST_GRADUATE: 'POST_GRADUATE',
  TWELFTH_OR_BELOW: 'TWELFTH_OR_BELOW',
  TENTH_OR_BELOW: 'TENTH_OR_BELOW',
  OTHERS: 'OTHERS',
  GRADUATE: 'GRADUATE'
};

export const RESIDENCE_TYPES = {
  COMPANY_OWNED: 'COMPANY_OWNED',
  RENTED: 'RENTED',
  SELF_OWNED: 'SELF_OWNED',
  FAMILY_OWNED: 'FAMILY_OWNED',
  PG_ACCOMMODATION: 'PG_ACCOMMODATION',
  OTHERS: 'OTHERS'
};

export const PROFESSION = {
  LAWYER: 'LAWYER',
  ARCHITECT: 'ARCHITECT',
  CHARTERED_ACCOUNTANT: 'CHARTERED_ACCOUNTANT',
  DOCTOR: 'DOCTOR',
  COST_ACCOUNTANT: 'COST_ACCOUNTANT',
  COMPANY_SECRETARY: 'COMPANY_SECRETARY',
  OTHERS: 'OTHERS'
};

export const ANNUAL_INCOME_RANGES = {
  LESS_THAN_ONE_LAKH: 'LESS_THAN_ONE_LAKH',
  ONE_TO_TWO_LAKHS: 'ONE_TO_TWO_LAKHS',
  TWO_TO_FIVE_LAKHS: 'TWO_TO_FIVE_LAKHS',
  FIVE_TO_TEN_LAKHS: 'FIVE_TO_TEN_LAKHS',
  TEN_TO_TWENTY_LAKHS: 'TEN_TO_TWENTY_LAKHS',
  TWENTY_TO_FIFTY_LAKHS: 'TWENTY_TO_FIFTY_LAKHS',
  FIFTY_LAKHS_TO_ONE_CRORE: 'FIFTY_LAKHS_TO_ONE_CRORE',
  ONE_TO_FIVE_CRORES: 'ONE_TO_FIVE_CRORES',
  FIVE_TO_TEN_CRORES: 'FIVE_TO_TEN_CRORES',
  MORE_THAN_TEN_CRORES: 'MORE_THAN_TEN_CRORES'
};

export const PROGRAM_TYPES = {
  YES_FIRST: 'YES_FIRST',
  YES_PREMIA: 'YES_PREMIA',
  YES_PROSPERITY: 'YES_PROSPERITY',
  YES_PRIVATE: 'YES_PRIVATE'
};

export const EMPLOYMENT_TYPES = {
  SALARIED: 'SALARIED',
  SELF_EMPLOYED_BUSINESS: 'SELF_EMPLOYED_BUSINESS',
  AGRICULTURIST_FARMER: 'AGRICULTURIST_FARMER',
  FILMSTAR: 'FILMSTAR',
  HOMEMAKER: 'HOMEMAKER',
  RETIRED: 'RETIRED',
  STUDENT: 'STUDENT',
  OTHERS: 'OTHERS',
  NOT_WORKING: 'NOT_WORKING',
  DIPLOMAT: 'DIPLOMAT',
  PROFESSIONAL: 'PROFESSIONAL'
};

export const SOURCE_OF_FUND = {
  SALARY: 'SALARY',
  SUPPORTED_BY_FAMILY_INCOME: 'SUPPORTED_BY_FAMILY_INCOME',
  BUSINESS_INCOME: 'BUSINESS_INCOME',
  FEES: 'FEES',
  REMUNERATION: 'REMUNERATION',
  INCOME_FROM_AGRICULTURE: 'INCOME_FROM_AGRICULTURE',
  RENT: 'RENT',
  PENSION: 'PENSION',
  INVESTMENTS: 'INVESTMENTS',
  ANCESTRAL_PROPERTY: 'ANCESTRAL_PROPERTY',
  COMMISSION: 'COMMISSION',
  GIFT: 'GIFT'
};

export const ADDRESS_TYPES = {
  MAILING_ADDRESS: 'MAILING_ADDRESS',
  OVERSEAS_ADDRESS: 'OVERSEAS_ADDRESS',
  PERMANENT_ADDRESS: 'PERMANENT_ADDRESS'
};

export const ACCOUNT_TYPE = {
  SINGLE: 'SINGLE',
  JOINT: 'JOINT'
};

export const COMPANY_TYPE = {
  PARTNERSHIP_FIRM: 'PARTNERSHIP FIRM',
  EDUCATIONAL_INSTITUTE: 'EDUCATIONAL INSTITUTE',
  RELIGIOUS_INSTITUTE: 'RELIGIOUS INSTITUTE',
  EMBASSY: 'EMBASSY',
  NGO: 'NGO',
  PSU: 'PSU',
  PROPRIETORSHIP_FIRM: 'PROPRIETORSHIP FIRM',
  DEFENSE_ORGANISATION: 'DEFENSE ORGANISATION',
  TRUST: 'TRUST',
  WAGE_EARNER: 'WAGE EARNER',
  PRIVATE_LTD_COMPANY: 'PRIVATE LTD COMPANY',
  PUBLIC_LTD_COMPANY: 'PUBLIC LTD COMPANY',
  HOUSEHOLD_SERVICES: 'HOUSEHOLD SERVICES',
  MULTINATIONAL: 'MULTINATIONAL',
  GOVERNMENT: 'GOVERNMENT'
};

export const CUSTOMER_TYPE = {
  NRI: 'NRI',
  PIO: 'PIO/OCI'
  // SEAFARER: 'SEAFARER'
};

export const EMPLOYER_TYPE = {
  GOVERNMENT_ORG: 'GOVERNMENT_ORG',
  PRIVATE_SECTOR: 'PRIVATE_SECTOR'
};

export const NATURE_OF_INDUSTRY = {
  AGRICULTURE: 'AGRICULTURE',
  TRADING: 'TRADING',
  MANUFACTURING: 'MANUFACTURING',
  REAL_ESTATE: 'REAL_ESTATE',
  SERVICE_PROVIDER: 'SERVICE_PROVIDER'
};

export const NATURE_OF_BUSINESS = {
  ANTIQUE_ART_DEALER: 'ANTIQUE_ART_DEALER',
  EXCHANGE_HOUSE: 'EXCHANGE_HOUSE',
  MONEY_CHANGER_TRANSFER_AGENT: 'MONEY_CHANGER_TRANSFER_AGENT',
  PETROL_PUMP: 'PETROL_PUMP',
  AUTO_DEALERS_NEW_VEHICLES_MOTOR: 'AUTO_DEALERS_NEW_VEHICLES_MOTOR',
  DEALERS_BROKERS_USED_CAR_BOAT_PLANE: 'DEALERS_BROKERS_USED_CAR_BOAT_PLANE',
  LIQUOR_DISTRIBUTORSHIP_STANDALONE_WINE_SHOPS:
    'LIQUOR_DISTRIBUTORSHIP_STANDALONE_WINE_SHOPS',
  NBFC_EXCEPT_HOUSING_FINANCE_SYSTEMATICALLY_IMPORTANT:
    'NBFC_EXCEPT_HOUSING_FINANCE_SYSTEMATICALLY_IMPORTANT',
  TRADERS_AGENTS_IMPORT_EXPORT: 'TRADERS_AGENTS_IMPORT_EXPORT',
  BROKER_STOCK_COMMODITIES: 'BROKER_STOCK_COMMODITIES',
  ARMS_EXPLOSIVES: 'ARMS_EXPLOSIVES',
  MONEY_LENDER: 'MONEY_LENDER',
  SCRAP_METAL_DEALERS: 'SCRAP_METAL_DEALERS',
  BULLION_GEMS_JEWELLERY: 'BULLION_GEMS_JEWELLERY',
  ELECTRONIC_ITEMS: 'ELECTRONIC_ITEMS',
  MLM_CHIT_FUNDS: 'MLM_CHIT_FUNDS',
  OFF_SHORE_CORPORATION: 'OFF_SHORE_CORPORATION',
  VENTURE_CAPITAL_COMPANIES: 'VENTURE_CAPITAL_COMPANIES',
  NONE_OF_THE_BELOW: 'NONE_OF_THE_BELOW',
  FILM_PRODUCTION_DISTRIBUTION_HOUSE_MOVIE_THEATRES:
    'FILM_PRODUCTION_DISTRIBUTION_HOUSE_MOVIE_THEATRES',
  AUCTION_HOUSE: 'AUCTION_HOUSE',
  INVESTMENT_MANAGEMENT: 'INVESTMENT_MANAGEMENT',
  CASINO_NIGHT_CLUB_BETTING: 'CASINO_NIGHT_CLUB_BETTING',
  LABOUR_SUPPLY: 'LABOUR_SUPPLY',
  TELEMARKETERS: 'TELEMARKETERS',
  COOPERATIVE_BANKS: 'COOPERATIVE_BANKS',
  INTERNET_SERVICE_PROVIDER: 'INTERNET_SERVICE_PROVIDER',
  TRANSPORT_OPERATORS: 'TRANSPORT_OPERATORS',
  SOCIAL_MEDIA_MARKETING: 'SOCIAL_MEDIA_MARKETING',
  TRADING: 'TRADING',
  SERVICE_PROVIDER: 'SERVICE_PROVIDER'
};

export const RELATIONSHIP_TYPE = {
  SPOUSE: 'SPOUSE',
  FATHER: 'FATHER',
  MOTHER: 'MOTHER',
  GRANDFATHER: 'GRANDFATHER',
  GRANDMOTHER: 'GRANDMOTHER',
  SPOUSE_FATHER: 'SPOUSE_FATHER',
  SPOUSE_MOTHER: 'SPOUSE_MOTHER',
  SPOUSE_GRANDFATHER: 'SPOUSE_GRANDFATHER',
  SPOUSE_GRANDMOTHER: 'SPOUSE_GRANDMOTHER',
  HUSBAND: 'HUSBAND',
  WIFE: 'WIFE',
  CHILD_FATHER: 'CHILD_FATHER',
  CHILD_MOTHER: 'CHILD_MOTHER',
  SON: 'SON',
  DAUGHTER: 'DAUGHTER',
  SISTER: 'SISTER',
  BROTHER: 'BROTHER'
};

export const YBL_CURRENT_ACCOUNT_TYPE = {
  SINGLE: 'SINGLE',
  JOINT: 'JOINT'
};

export const COUNTRY = {
  AFGHANISTAN: 'AFGHANISTAN',
  ALBANIA: 'ALBANIA',
  ALGERIA: 'ALGERIA',
  AMERICAN_SAMOA: 'AMERICAN SAMOA',
  ANDORRA: 'ANDORRA',
  ANGOLA: 'ANGOLA',
  ANGUILLA: 'ANGUILLA',
  ANTARCTICA: 'ANTARCTICA',
  ANTIGUA_AND_BARBUDA: 'ANTIGUA AND BARBUDA',
  ARGENTINA: 'ARGENTINA',
  ARMENIA: 'ARMENIA',
  ARUBA: 'ARUBA',
  AUSTRALIA: 'AUSTRALIA',
  AUSTRIA: 'AUSTRIA',
  AZERBAIJAN: 'AZERBAIJAN',
  BAHAMAS: 'BAHAMAS',
  BAHRAIN: 'BAHRAIN',
  BARBADOS: 'BARBADOS',
  BELARUS: 'BELARUS',
  BELGIUM: 'BELGIUM',
  BELIZE: 'BELIZE',
  BENIN: 'BENIN',
  BERMUDA: 'BERMUDA',
  BOLIVIA: 'BOLIVIA',
  BOSNIA_AND_HERZEGOVINA: 'BOSNIA AND HERZEGOVINA',
  BOTSWANA: 'BOTSWANA',
  BOUVET_ISLAND: 'BOUVET ISLAND',
  BRAZIL: 'BRAZIL',
  BRITISH_INDIAN_OCEAN_TERRITORY: 'BRITISH INDIAN OCEAN TERRITORY',
  BRUNEI: 'BRUNEI',
  BULGARIA: 'BULGARIA',
  BURKINA_FASO: 'BURKINA FASO',
  BURUNDI: 'BURUNDI',
  CAMBODIA: 'CAMBODIA',
  CAMEROON: 'CAMEROON',
  CANADA: 'CANADA',
  CAPE_VERDE: 'CAPE VERDE',
  CAYMAN_ISLANDS: 'CAYMAN ISLANDS',
  CENTRAL_AFRICAN_REPUBLIC: 'CENTRAL AFRICAN REPUBLIC',
  CHAD: 'CHAD',
  CHILE: 'CHILE',
  CHINA: 'CHINA',
  CHRISTMAS_ISLAND: 'CHRISTMAS ISLAND',
  COCOS_ISLANDS: 'COCOS ISLANDS',
  COLOMBIA: 'COLOMBIA',
  COMOROS: 'COMOROS',
  CONGO: 'CONGO',
  CONGO_DEMOCRATIC: 'CONGO DEMOCRATIC',
  COOK_ISLANDS: 'COOK ISLANDS',
  COSTA_RICA: 'COSTA RICA',
  COTE_D_IVOIRE: 'COTE D?IVOIRE',
  CROATIA: 'CROATIA',
  CYPRUS: 'CYPRUS',
  CZECH_REPUBLIC: 'CZECH REPUBLIC',
  DENMARK: 'DENMARK',
  DJIBOUTI: 'DJIBOUTI',
  DOMINICA: 'DOMINICA',
  DOMINICAN_REPUBLIC: 'DOMINICAN REPUBLIC',
  EAST_TIMOR: 'EAST TIMOR',
  ECUADOR: 'ECUADOR',
  EGYPT: 'EGYPT',
  EL_SALVADOR: 'EL SALVADOR',
  EQUATORIAL_GUINEA: 'EQUATORIAL GUINEA',
  ERITREA: 'ERITREA',
  ESTONIA: 'ESTONIA',
  ETHIOPIA: 'ETHIOPIA',
  FALKLAND_ISLANDS: 'FALKLAND ISLANDS',
  FAROE_ISLANDS: 'FAROE ISLANDS',
  FIJI: 'FIJI',
  FRANCE: 'FRANCE',
  FRENCH_GUIANA: 'FRENCH GUIANA',
  FRENCH_POLYNESIA: 'FRENCH POLYNESIA',
  FRENCH_SOUTHERN_TERRITORIES: 'FRENCH SOUTHERN TERRITORIES',
  GABON: 'GABON',
  GAMBIA: 'GAMBIA',
  GEORGIA: 'GEORGIA',
  GERMANY: 'GERMANY',
  GHANA: 'GHANA',
  GIBRALTAR: 'GIBRALTAR',
  GREECE: 'GREECE',
  GREENLAND: 'GREENLAND',
  GRENADA: 'GRENADA',
  GUADELOUPE: 'GUADELOUPE',
  GUAM: 'GUAM',
  GUATEMALA: 'GUATEMALA',
  GUERNSEY: 'GUERNSEY',
  GUINEA: 'GUINEA',
  GUINEA_BISSAU: 'GUINEA-BISSAU',
  GUYANA: 'GUYANA',
  HAITI: 'HAITI',
  HEARD_MCDONALD_ISLANDS: 'HEARD & MCDONALD ISLANDS',
  HONDURAS: 'HONDURAS',
  HONG_KONG: 'HONG KONG',
  HUNGARY: 'HUNGARY',
  ICELAND: 'ICELAND',
  INDIA: 'INDIA',
  INDONESIA: 'INDONESIA',
  IRAQ: 'IRAQ',
  IRELAND: 'IRELAND',
  ISLE_OF_MAN: 'ISLE OF MAN'
};

export const GENDER = {
  MALE: 'MALE',
  FEMALE: 'FEMALE',
  OTHER: 'OTHER'
};

export const MARITAL_STATUS = {
  SINGLE: 'SINGLE',
  MARRIED: 'MARRIED'
};

export const NAME_PREFIX_TYPE = {
  MR: 'MR.',
  MS: 'MS.'
};

export const TAX_IDENTIFICATION_TYPES = {
  EMIRATES_ID: 'EMIRATES_ID',
  TAX_RESIDENCY_CERTIFICATE: 'TAX_RESIDENCY_CERTIFICATE',
  TAX_DOMICILE_CERTIFICATE: 'TAX_DOMICILE_CERTIFICATE',
  UTR: 'UTR',
  NINO: 'NINO',
  NIE: 'NIE',
  ID_CARD: 'ID_CARD',
  SSN: 'SSN',
  EIN: 'EIN',
  ITIN: 'ITIN',
  TAXPAYER_IDENTIFICATION_NUMBER: 'TAXPAYER_IDENTIFICATION_NUMBER',
  TIN: 'TIN',
  RIF: 'RIF',
  NIT: 'NIT',
  TPIN: 'TPIN',
  TIN_TAXPAYERS_ID: 'TIN_TAXPAYERS_ID',
  NATIONAL_ID_NUMBER: 'NATIONAL_ID_NUMBER',
  TIN_TAXPAYERS: 'TIN_TAXPAYERS',
  NIF: 'NIF',
  NRT: 'NRT',
  TAX_REGISTER_NUMBER: 'TAX_REGISTER_NUMBER',
  TIN_TAXPAYERS_ID_2: 'TIN_TAXPAYERS_ID_2',
  CUIL: 'CUIL',
  CUIT: 'CUIT',
  TAXPAYERS_IDENTIFICATION: 'TAXPAYERS_IDENTIFICATION',
  TIN_TAXPAYERS_ID_3: 'TIN_TAXPAYERS_ID_3',
  TFN: 'TFN',
  TIN_TAXPAYERS_ID_4: 'TIN_TAXPAYERS_ID_4',
  IDENTITY_CARD: 'IDENTITY_CARD',
  PIN: 'PIN',
  BAHRAIN_CPR: 'BAHRAIN_CPR',
  ID_CARD_NUMBER: 'ID_CARD_NUMBER',
  TIN_TAXPAYERS_2: 'TIN_TAXPAYERS_2',
  TIN_TAXPAYERS_3: 'TIN_TAXPAYERS_3',
  NUMERO_NATIONAL: 'NUMERO_NATIONAL',
  TIN_TAXPAYERS_4: 'TIN_TAXPAYERS_4',
  TAXPAYER_NUMBER: 'TAXPAYER_NUMBER',
  TAXPAYER_IDENTIFICATION_2: 'TAXPAYER_IDENTIFICATION_2',
  CADASTRO_DE_PESSOAS_FISICAS: 'CADASTRO_DE_PESSOAS_FISICAS',
  NRIC_NUMBER: 'NRIC_NUMBER',
  UCN: 'UCN',
  PERSONAL_NUMBER: 'PERSONAL_NUMBER',
  CANADIAN_SIN: 'CANADIAN_SIN',
  RUT: 'RUT',
  TIN_TAXPAYERS_5: 'TIN_TAXPAYERS_5',
  TAXPAYERS_IDENTIFICATION_2: 'TAXPAYERS_IDENTIFICATION_2',
  SINGLE_TAX_REGISTRATION: 'SINGLE_TAX_REGISTRATION',
  NIT_2: 'NIT_2',
  RMD_NO: 'RMD_NO',
  PHYSICAL_IDENTIFICATION_NUMBER: 'PHYSICAL_IDENTIFICATION_NUMBER',
  DIMEX: 'DIMEX',
  NITE: 'NITE',
  OIB: 'OIB',
  TIN_TAXPAYERS_6: 'TIN_TAXPAYERS_6',
  TAX_IDENTIFICATION_CODE: 'TAX_IDENTIFICATION_CODE',
  PERSONAL_NUMBER_2: 'PERSONAL_NUMBER_2',
  CPR_NUMBER: 'CPR_NUMBER',
  TIN_TAXPAYERS_7: 'TIN_TAXPAYERS_7',
  TAX_PAYER_NO: 'TAX_PAYER_NO',
  REGISTRO_UNICO_DE_CONTRIBUYENTES: 'REGISTRO_UNICO_DE_CONTRIBUYENTES',
  PERSONAL_IDENTIFICATION_CODE: 'PERSONAL_IDENTIFICATION_CODE',
  TAX_IDENTIFICATION_NUMBER: 'TAX_IDENTIFICATION_NUMBER',
  FAROESE_P_NUMBER: 'FAROESE_P_NUMBER',
  TAX_IDENTIFICATION_NUMBER_2: 'TAX_IDENTIFICATION_NUMBER_2',
  FINNISH_SSN_INDIVIDUALS: 'FINNISH_SSN_INDIVIDUALS',
  NUMERO_FISCAL_DE_REFERENCE: 'NUMERO_FISCAL_DE_REFERENCE',
  NATIONAL_NIN: 'NATIONAL_NIN',
  NATIONAL_ID_CARD_NUMBER: 'NATIONAL_ID_CARD_NUMBER',
  TIN_TAXPAYERS_8: 'TIN_TAXPAYERS_8',
  TAX_IDENTIFICATION_NUMBER_3: 'TAX_IDENTIFICATION_NUMBER_3',
  TAX_IDENTIFICATION_NUMBER_4: 'TAX_IDENTIFICATION_NUMBER_4',
  TAX_REFERENCE_NUMBER: 'TAX_REFERENCE_NUMBER',
  TAXPAYER_IDENTIFICATION_NUMBER_2: 'TAXPAYER_IDENTIFICATION_NUMBER_2',
  CPR_NUMBER_2: 'CPR_NUMBER_2',
  TIN_TAXPAYERS_9: 'TIN_TAXPAYERS_9',
  SOCIAL_INSURANCE_NUMBER: 'SOCIAL_INSURANCE_NUMBER',
  TIN_TAXPAYERS_10: 'TIN_TAXPAYERS_10',
  TAX_IDENTIFICATION_NUMBER_5: 'TAX_IDENTIFICATION_NUMBER_5',
  HKID_NUMBER: 'HKID_NUMBER',
  TAX_IDENTIFICATION_NUMBER_6: 'TAX_IDENTIFICATION_NUMBER_6',
  ICELANDIC_IDENTIFICATION_NUMBER: 'ICELANDIC_IDENTIFICATION_NUMBER',
  PAN: 'PAN',
  NPWP: 'NPWP',
  PERSONAL_PUBLIC_SERVICE_NUMBER: 'PERSONAL_PUBLIC_SERVICE_NUMBER',
  TAX_REFERENCE_NUMBER_2: 'TAX_REFERENCE_NUMBER_2',
  NINO_2: 'NINO_2',
  ID_NUMBER: 'ID_NUMBER',
  CODICE_FISCALE: 'CODICE_FISCALE',
  INDIVIDUAL_NUMBER: 'INDIVIDUAL_NUMBER',
  INDIVIDUAL_SSN: 'INDIVIDUAL_SSN',
  PERSONAL_IDENTIFICATION_NUMBER: 'PERSONAL_IDENTIFICATION_NUMBER',
  KUWAIT_CIVIL_ID: 'KUWAIT_CIVIL_ID',
  TAXPAYER_REGISTRATION_NUMBER: 'TAXPAYER_REGISTRATION_NUMBER',
  TAX_IDENTIFICATION_NUMBER_7: 'TAX_IDENTIFICATION_NUMBER_7',
  TIN_TAXPAYERS_11: 'TIN_TAXPAYERS_11',
  TAXPAYER_IDENTIFICATION_NUMBER_3: 'TAXPAYER_IDENTIFICATION_NUMBER_3',
  PEID_NUMMER: 'PEID_NUMMER',
  TAXPAYER_IDENTIFICATION_NUMBER_4: 'TAXPAYER_IDENTIFICATION_NUMBER_4',
  NATIONAL_IDENTIFICATION_NUMBER: 'NATIONAL_IDENTIFICATION_NUMBER',
  NATIONAL_IDENTIFIER: 'NATIONAL_IDENTIFIER'
};
/* Dropdown fields */

/* Journey Step */
export const FTNR_JOURNEY_STEPS = {
  NEW: 'NEW',
  CUSTOMER_DEDUPE_COMPLETED: 'CUSTOMER_DEDUPE_COMPLETED',
  CUSTOMER_DETAILS_COMPLETED: 'CUSTOMER_DETAILS_COMPLETED',
  SUBMIT_JOURNEY_FAILED: 'SUBMIT_JOURNEY_FAILED',
  SUBMIT_JOURNEY_COMPLETED: 'SUBMIT_JOURNEY_COMPLETED'
};

// disable trigger and edit:
export const FTNR_DISABLED_JOURNEY_STEPS = {
  UPLOAD_DOCS_COMPLETED: 'UPLOAD_DOCS_COMPLETED',
  STAGE_INFO_FAILED: 'STAGE_INFO_FAILED',
  COMPLETED: 'COMPLETED',
  BLOCKED: 'BLOCKED',
  SENT_TO_PROVIDER: 'SENT_TO_PROVIDER'
};

export const FTNR_NOTARIZED_DOCS_LIST = {
  notarizedMailingAddressDocUrl: 'NOTARIZED_MAILING_ADDRESS',
  notarizedPassportBackDocUrl: 'NOTARIZED_PASSPORT_BACK',
  notarizedPassportFrontDocUrl: 'NOTARIZED_PASSPORT_FRONT'
};

/* Journey Step */

export const FTNR_FORM_MAPPER = {
  journeyStep: 'journeyStep',
  userId: 'userId',
  modeOfOperation: 'modeOfOperation',
  accountType_isSingle: 'accountType_isSingle',
  demographic: {
    name: 'name',
    pan: 'pan',
    fatherName: 'fatherName',
    passportNum: 'passportNum',
    email: 'email',
    gender: 'gender',
    placeOfIssue: 'placeOfIssue',
    prefix: 'prefix',
    mobile: 'mobile',
    countryCode: 'countryCode',
    spouseName: 'spouseName',
    motherName: 'motherName',
    maritalStatus: 'maritalStatus',
    placeOfBirth: 'placeOfBirth',
    passportIssueDate: 'passportIssueDate',
    visaIssueDate: 'visaIssueDate',
    visaExpiryDate: 'visaExpiryDate',
    visaNum: 'visaNum',
    dob: 'dob',
    overseasResidence: 'overseasResidence',
    passportExpiryDate: 'passportExpiryDate',
    productId: 'productId',
    aadhaarNum: 'aadhaarNum',
    flatNo: 'flatNo',
    streetNo: 'streetNo',
    landMark: 'landMark',
    country: 'country',
    pinCode: 'pinCode',
    state: 'state',
    city: 'city',
    nameOnDebitCard: 'nameOnDebitCard'
  },
  profileDetails: {
    nameOnDebitCard: 'nameOnDebitCard',
    debitCardId: 'debitCardId',
    state_hb: 'state_hb',
    city_hb: 'city_hb',
    branch_hb: 'branch_hb',
    rmReferralCode: 'rmReferralCode',
    promoCode: 'promoCode',
    productType_hb: 'productType_hb',
    programType_hb: 'programType_hb',
    sourceOfFund_pd: 'sourceOfFund_pd',
    educationalQualification_pd: 'educationalQualification_pd',
    residentialStatus_pd: 'residentialStatus_pd',
    residenceType_pd: 'residenceType_pd',
    annualIncome_pd: 'annualIncome_pd',
    countryOfCitizenship_pd: 'countryOfCitizenship_pd',
    employedWith_pd: 'employedWith_pd',
    nameOfBusiness_pd: 'nameOfBusiness_pd',
    natureOfBusiness_pd: 'natureOfBusiness_pd',
    periodInCurrentBusiness_pd: 'periodInCurrentBusiness_pd',
    currentAccountType_pd: 'currentAccountType_pd',
    seaFererOnBank_pd: 'seaFererOnBank_pd',
    seaFarerOnBreak_pd: 'seaFarerOnBreak_pd',
    companyType_pd: 'companyType_pd',
    countryOfBirth_pd: 'countryOfBirth_pd',
    employmentType_pd: 'employmentType_pd',
    profession_pd: 'profession_pd',
    natureOfIndustry_pd: 'natureOfIndustry_pd',
    customerAccountType_pd: 'customerAccountType_pd',
    customerType_pd: 'customerType_pd'
  },
  // nomineeDetails: {
  //   addNominee_nagd: 'addNominee_nagd',
  //   nomineeRelationshipId_nagd: 'nomineeRelationshipId_nagd',
  //   nomineeName_nagd: 'nomineeName_nagd',
  //   dob_nagd: 'dob_nagd',
  //   flatNo_na: 'flatNo_na',
  //   streetNo_na: 'streetNo_na',
  //   landMark_na: 'landMark_na',
  //   townOrCity_na: 'townOrCity_na',
  //   state_na: 'state_na',
  //   country_na: 'country_na',
  //   zipCode_na: 'zipCode_na',
  //   guardianName_gd: 'guardianName_gd',
  //   age_gd: 'age_gd',
  //   flatNo_ga: 'flatNo_ga',
  //   streetNo_ga: 'streetNo_ga',
  //   landMark_ga: 'landMark_ga',
  //   city_ga: 'city_ga',
  //   state_ga: 'state_ga',
  //   country_ga: 'country_ga',
  //   zipCode_ga: 'zipCode_ga'
  // },
  consentCapture: {
    debitCard_cc: 'debitCard_cc',
    sms_cc: 'sms_cc',
    netBanking_cc: 'netBanking_cc',
    estatement_cc: 'estatement_cc',
    pepConsent_cc: 'pepConsent_cc',
    accCibilConsent_cc: 'accCibilConsent_cc',
    tandCConsent_cc: 'tandCConsent_cc',
    email_cc: 'email_cc',
    prodAndOffersUpdate_cc: 'prodAndOffersUpdate_cc',
    fatcaAndConsent_cc: 'fatcaAndConsent_cc',
    catDetails: {
      taxIdNum_fatcaDetail: 'taxIdNum_fatcaDetail',
      taxIdType_fatcaDetail: 'taxIdType_fatcaDetail',
      infoDecConsent_fatcaDetail: 'infoDecConsent_fatcaDetail',
      countryForJuris_fatcaDetail: 'countryForJuris_fatcaDetail',
      nationality_fatcaDetail: 'nationality_fatcaDetail',
      residentAddress_fatcaDetail: 'residentAddress_fatcaDetail',
      outSiderIndia_fatcaDetail: 'outSiderIndia_fatcaDetail',
      getPermAddForTax_fatcaDetail: 'getPermAddForTax_fatcaDetail',
      residentialAddress: {
        countryJurisId_resAddress_fatcaDetail:
          'countryJurisId_resAddress_fatcaDetail',
        taxIdTypeId_resAddress_fatcaDetail:
          'taxIdTypeId_resAddress_fatcaDetail',
        taxIdNum_resAddress_fatcaDetail: 'taxIdNum_resAddress_fatcaDetail'
      }
    }
  },
  pio: {
    isPoi_pio: 'isPoi_pio',
    hasIndianPassport_pio: 'hasIndianPassport_pio',
    hasSpouseIndianPassport_pio: 'hasSpouseIndianPassport_pio',
    isCitizenByVoc_pio: 'isCitizenByVoc_pio',
    relationId_pio: 'relationId_pio',
    relativeName_pio: 'relativeName_pio'
  },
  seafarer: {
    isNriStatusAltered_seafarer: 'isNriStatusAltered_seafarer',
    registeredCompanyName_seafarer: 'registeredCompanyName_seafarer',
    startDateOfNewContract_seafarer: 'startDateOfNewContract_seafarer',
    dateOfArrivalLastJourney_seafarer: 'dateOfArrivalLastJourney_seafarer',
    dateOfDepartureLastJourney_seafarer: 'dateOfDepartureLastJourney_seafarer',
    isaSeafarerOnBreak_seafarer: 'isaSeafarerOnBreak_seafarer',
    cad: {
      flatNoBuildingName_cad: 'flatNoBuildingName_cad',
      street_cad: 'street_cad',
      landmark_cad: 'landmark_cad',
      countryId_cad: 'countryId_cad',
      pincode_cad: 'pincode_cad',
      state_cad: 'state_cad',
      city_cad: 'city_cad'
    }
  },
  form60Declaration: {
    incomeTaxDecConsent_f60d: 'incomeTaxDecConsent_f60d',
    agriculturalIncome_f60d: 'agriculturalIncome_f60d',
    appliedForPan_f60d: 'appliedForPan_f60d',
    otherIncome_f60d: 'otherIncome_f60d',
    panAppNum_f60d: 'panAppNum_f60d',
    panAckDate_f60d: 'panAckDate_f60d'
  },
  documents: {
    // documentsToBeUploaded: {
    //   selfieDocUrl: 'selfieDocUrl',
    //   signatureDocUrl: 'signatureDocUrl',
    //   notarizedPassportFrontDocUrl: 'notarizedPassportFrontDocUrl',
    //   notarizedPassportBackDocUrl: 'notarizedPassportBackDocUrl',
    //   notarizedMailingAddressDocUrl: 'notarizedMailingAddressDocUrl',
    //   visaDocUrl: 'visaDocUrl'
    // },
    // others: {
    //   existingBankAccountNo: 'existingBankAccountNo',
    //   passportFrontDocUrl: 'passportFrontDocUrl',
    //   passportBackDocUrl: 'passportBackDocUrl',
    //   brpFrontDocUrl: 'brpFrontDocUrl',
    //   brpBackDocUrl: 'brpBackDocUrl',
    //   bankStatementDocUrl: 'bankStatementDocUrl',
    //   emiratesIdDocUrl: 'emiratesIdDocUrl'
    // }
  }
};

export const VIEW_MAPPER = {};

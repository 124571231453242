const WORKFLOW_V2 = {
  GETTING_USER_DOCS: 'GETTING_USER_DOCS_V2',
  GET_USER_DOCS_SUCCESS: 'GET_USER_DOCS_SUCCESS_V2',
  GET_USER_DOCS_FAILURE: 'GETTING_USER_DOCS_FAILURE_V2',
  CLEAR_USER_DOCS: 'CLEAR_USER_DOCS_V2',
  GETTING_WORKFLOW_SCREENS_DATA: 'GETTING_WORKFLOW_SCREENS_DATA_V2',
  GET_WORKFLOW_SCREENS_DATA_SUCCESS: 'GET_WORKFLOW_SCREENS_DATA_SUCCESS_V2',
  GET_WORKFLOW_SCREENS_DATA_FAILURE: 'GET_WORKFLOW_SCREENS_DATA_FAILURE_V2'
};

export default WORKFLOW_V2;

import { Box, Button, Stack, VStack, useDisclosure } from '@chakra-ui/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  yblCustomerDetails,
  yblDedupe,
  yblSubmitJourney,
  yblUploadDoc
} from '../../store/actions/nreNroActions';
import ModalLayout from '../../components/Modal/ModalLayout';
import ConfirmModal from '../../components/Modal/ConfirmForceInitiate';
import GenericSelectModal from '../../components/Modal/GenericSelectModal';
import { YBL_ONBOARDING_DOCS } from '../../constants';

const TriggerApisBar = ({ userId }) => {
  const dispatch = useDispatch();
  const [activeConfirmModal, setActiveConfirmModal] = useState(null);
  const [isDedupeLoading, setIsDedupeLoading] = useState(false);
  const [isCustomerDetailsLoading, setIsCustomerDetailsLoading] =
    useState(false);
  const [isSubmitJourneyLoading, setIsSubmitJourneyLoading] = useState(false);
  const [isUploadDocLoading, setIsUploadDocLoading] = useState(false);

  const {
    isOpen: isConfirmModalOpen,
    onOpen: onConfirmModalOpen,
    onClose: onConfirmModalClose
  } = useDisclosure();

  const {
    isOpen: isSelectModalOpen,
    onOpen: onSelectModalOpen,
    onClose: onSelectModalClose
  } = useDisclosure();

  const CONFIRM_MODALS = useMemo(
    () => ({
      DEDUPE: 'DEDUPE',
      CUSTOMER_DETAILS: 'CUSTOMER_DETAILS',
      SUBMIT_JOURNEY: 'SUBMIT_JOURNEY'
    }),
    []
  );

  useEffect(() => {
    if (activeConfirmModal) onConfirmModalOpen();
    else onConfirmModalClose();
  }, [activeConfirmModal]);

  const handleDedupeSubmit = () => {
    setIsDedupeLoading(true);
    dispatch(
      yblDedupe({
        userId,
        onFinish: () => {
          setIsDedupeLoading(false);
        },
        onSuccess: () => setActiveConfirmModal(null)
      })
    );
  };

  const handleCustomerDetailsSubmit = () => {
    setIsCustomerDetailsLoading(true);
    dispatch(
      yblCustomerDetails({
        userId,
        onFinish: () => {
          setIsCustomerDetailsLoading(false);
        },
        onSuccess: () => setActiveConfirmModal(null)
      })
    );
  };

  const handleSubmitJourneySubmit = () => {
    setIsSubmitJourneyLoading(true);
    dispatch(
      yblSubmitJourney({
        userId,
        onFinish: () => {
          setIsSubmitJourneyLoading(false);
        },
        onSuccess: () => setActiveConfirmModal(null)
      })
    );
  };

  const handleConfirmModalSubmit = () => {
    switch (activeConfirmModal) {
      case CONFIRM_MODALS.DEDUPE:
        handleDedupeSubmit();
        break;
      case CONFIRM_MODALS.CUSTOMER_DETAILS:
        handleCustomerDetailsSubmit();
        break;
      case CONFIRM_MODALS.SUBMIT_JOURNEY:
        handleSubmitJourneySubmit();
        break;
      default:
        console.log('Unknown submit action!');
    }
  };

  const handleSelectModalSubmit = ({ referenceId, value }) => {
    setIsUploadDocLoading(false);
    dispatch(
      yblUploadDoc({
        userId: referenceId,
        docType: value,
        onFinish: () => setIsUploadDocLoading(false),
        onSuccess: () => onConfirmModalClose()
      })
    );
  };

  const modalPrompt = useMemo(() => {
    switch (activeConfirmModal) {
      case CONFIRM_MODALS.DEDUPE:
        return 'Trigger YBL Dedupe Api?';
      case CONFIRM_MODALS.CUSTOMER_DETAILS:
        return 'Trigger YBL Customer Details Api?';
      case CONFIRM_MODALS.SUBMIT_JOURNEY:
        return 'Trigger YBL Submit Journey Api?';
      default:
        return '';
    }
  }, [activeConfirmModal, CONFIRM_MODALS]);

  const getModalLoadingState = () => {
    switch (activeConfirmModal) {
      case CONFIRM_MODALS.DEDUPE:
        return isDedupeLoading;
      case CONFIRM_MODALS.CUSTOMER_DETAILS:
        return isCustomerDetailsLoading;
      case CONFIRM_MODALS.SUBMIT_JOURNEY:
        return isSubmitJourneyLoading;
      default:
        return false;
    }
  };

  const handleModalClose = () => {
    setActiveConfirmModal(null);
    onConfirmModalClose();
  };

  return (
    <Box position={'relative'}>
      <ModalLayout
        isOpen={isConfirmModalOpen}
        onClose={handleModalClose}
        size={'md'}
      >
        <ConfirmModal
          handleSubmit={handleConfirmModalSubmit}
          isLoading={getModalLoadingState()}
          prompt={modalPrompt}
        />
      </ModalLayout>

      <ModalLayout
        isOpen={isSelectModalOpen}
        onClose={onSelectModalClose}
        size={'md'}
      >
        <GenericSelectModal
          referenceId={userId}
          options={Object.keys(YBL_ONBOARDING_DOCS)}
          initialValue={''}
          title={'Upload YBL Document'}
          placeholder={'Select Doc type'}
          label={'Document: '}
          handleSubmit={handleSelectModalSubmit}
          isLoading={isUploadDocLoading}
        />
      </ModalLayout>
      <VStack pt={2}>
        <Stack w={'100%'} direction={{ base: 'column', sm: 'row' }}>
          <Button
            color="black"
            rounded="lg"
            colorScheme="brand"
            size={'sm'}
            onClick={() => setActiveConfirmModal(CONFIRM_MODALS.DEDUPE)}
          >
            Dedupe
          </Button>
          <Button
            color="black"
            rounded="lg"
            colorScheme="brand"
            size={'sm'}
            onClick={() =>
              setActiveConfirmModal(CONFIRM_MODALS.CUSTOMER_DETAILS)
            }
          >
            Customer Details
          </Button>
          <Button
            color="black"
            rounded="lg"
            colorScheme="brand"
            size={'sm'}
            onClick={() => setActiveConfirmModal(CONFIRM_MODALS.SUBMIT_JOURNEY)}
          >
            Submit Journey
          </Button>
          <Button
            color="black"
            rounded="lg"
            colorScheme="brand"
            size={'sm'}
            onClick={onSelectModalOpen}
          >
            Upload Doc
          </Button>
        </Stack>
      </VStack>
    </Box>
  );
};

export default TriggerApisBar;

import React, { useCallback, useEffect, useState } from 'react';
import ContentLayout from '../../layouts/ContentLayout';
import { useDispatch, useSelector } from 'react-redux';
import { updateJobsReqData } from '../../store/actions/reqDataActions';
import { CARD_TYPE, JOB_FILTERS } from '../../constants';

import useDidMountEffect from '../../hooks/useDidMount';
import {
  Box,
  Divider,
  Flex,
  Grid,
  Heading,
  Spinner,
  VStack,
  Select,
  HStack,
  useDisclosure,
  Button
} from '@chakra-ui/react';
import Pagination from '../../components/Pagination';
import { getJobs, updateJobStatus } from '../../store/actions/jobsActions';
import ModalLayout from '../../components/Modal/ModalLayout';
import JsonViewer from '../../components/Modal/JsonViewer';
import useIsAdmin from '../../hooks/useIsAdmin';
import UpdateJobStatusModal from '../../components/Modal/UpdateJobStatusModal';
import GenericCard from '../../components/GenericCard';

const Jobs = () => {
  const {
    data: jobs,
    isLoading,
    totalPages
  } = useSelector((state) => state.jobs.jobs);
  const dispatch = useDispatch();
  const searchReqData = useSelector((state) => state.reqData.jobs);
  const isAdmin = useIsAdmin();

  const {
    isOpen: isJsonViewerOpen,
    onOpen: onJsonViewerOpen,
    onClose: onJsonViewerClose
  } = useDisclosure();

  const {
    isOpen: isConfirmUpdateStatusOpen,
    onOpen: onConfirmUpdateStatusOpen,
    onClose: onConfirmUpdateStatusClose
  } = useDisclosure();

  const [isUpdatingStatus, setIsUpdatingStatus] = useState();
  const [activeJsonData, setActiveJsonData] = useState(null);
  const [activeJob, setActiveJob] = useState(null);

  const handleUpdateJobStatusSubmit = ({ jobId, jobStatus }) => {
    setIsUpdatingStatus(true);
    dispatch(
      updateJobStatus({
        jobId: jobId,
        status: jobStatus,
        onSuccess: () => {
          dispatch(updateJobsReqData({ pageNo: 0 }));
          dispatch(getJobs({ ...searchReqData, pageNo: 0 }));
        },
        onFinish: () => {
          handleUpdateStatusClose();
          setIsUpdatingStatus(false);
        }
      })
    );
  };

  const onUpdateStatusClick = ({ jobId, jobStatus }) => {
    setActiveJob({ jobId, jobStatus });
  };

  const onRemarksClick = (jobData) => {
    setActiveJsonData({
      jobId: jobData.jobId,
      jsonContent: jobData.remarks,
      type: 'remarks'
    });
  };

  const onAdditionalInfoClick = (jobData) => {
    setActiveJsonData({
      jobId: jobData.jobId,
      jsonContent: jobData,
      type: 'additional_info'
    });
  };

  useEffect(() => {
    if (activeJob) onConfirmUpdateStatusOpen();
  }, [activeJob]);

  useEffect(() => {
    if (activeJsonData) onJsonViewerOpen();
  }, [activeJsonData]);

  const handleUpdateStatusClose = () => {
    onConfirmUpdateStatusClose();
    setActiveJob(null);
  };

  const handleJsonViewClose = () => {
    onJsonViewerClose();
    setActiveJsonData(null);
  };

  useEffect(() => {
    if (jobs?.length) return;

    triggerGetJobs(searchReqData);
  }, []);

  useDidMountEffect(() => {
    triggerGetJobs(searchReqData);
  }, [searchReqData]);

  const triggerGetJobs = useCallback(
    (searchReqData) => {
      dispatch(getJobs(searchReqData));
    },
    [dispatch]
  );

  const handlePageChange = useCallback(
    (pageNo) => {
      pageNo - 1 !== searchReqData.pageNo &&
        dispatch(updateJobsReqData({ pageNo: pageNo - 1 }));
    },
    [searchReqData.pageNo, dispatch]
  );

  const handleFilterChange = useCallback(
    (e) => {
      const filterKey = e.target.name;
      const filterValue = e.target.value;

      searchReqData.filters[filterKey] !== e.target.value &&
        dispatch(
          updateJobsReqData({
            pageNo: 0,
            filters: {
              ...searchReqData.filters,
              [filterKey]: filterValue
            }
          })
        );
    },
    [dispatch, searchReqData.filters]
  );

  return (
    <Box position="relative">
      {activeJob && (
        <ModalLayout
          isOpen={isConfirmUpdateStatusOpen}
          onClose={handleUpdateStatusClose}
          size={'md'}
        >
          <UpdateJobStatusModal
            allowedJobStatus={JOB_FILTERS.STATUS.FILTER_VALUES}
            currentJobStatus={activeJob.jobStatus}
            jobId={activeJob.jobId}
            handleSubmit={handleUpdateJobStatusSubmit}
            isLoading={isUpdatingStatus}
          />
        </ModalLayout>
      )}

      {activeJsonData && (
        <ModalLayout
          isOpen={isJsonViewerOpen}
          onClose={handleJsonViewClose}
          size={'md'}
        >
          <JsonViewer
            title={
              activeJsonData.type === 'remarks' ? 'Remarks' : 'Additional Info'
            }
            subTitle={`Job ID: ${activeJsonData.jobId}`}
            jsonString={activeJsonData.jsonContent}
          />
        </ModalLayout>
      )}
      <ContentLayout>
        <Grid gridTemplateColumns={{ base: '1fr', lg: '7fr 0fr 2fr' }}>
          <VStack
            p={10}
            gap={4}
            overflowY={{ base: 'initial', lg: 'scroll' }}
            overflowX={'hidden'}
            h={{ lg: '90vh' }}
            px={{ base: 4, sm: 10 }}
            alignItems={'stretch'}
            className="scroll"
          >
            <Flex
              w={'100%'}
              alignItems={'baseline'}
              justifyContent={'space-between'}
              pb={2}
            >
              <Heading color={'white'}>Scheduled Jobs</Heading>
            </Flex>

            <HStack w={'100%'} gap={2}>
              {Object.keys(searchReqData.filters).map((filterKey) => (
                <Box maxWidth="15rem" key={filterKey}>
                  <Select
                    placeholder={`Filter ${filterKey}`}
                    onChange={handleFilterChange}
                    name={filterKey}
                    value={searchReqData.filters[filterKey]}
                    bg="black"
                    color="gray"
                    size={'sm'}
                  >
                    {JOB_FILTERS[filterKey].FILTER_VALUES.map((filterValue) => (
                      <option value={filterValue} key={filterValue}>
                        {filterValue}
                      </option>
                    ))}
                  </Select>
                </Box>
              ))}
            </HStack>

            {totalPages > 0 && (
              <Box alignSelf={'start'}>
                <Pagination
                  totalPages={totalPages}
                  currentPage={searchReqData.pageNo + 1}
                  onPageChange={handlePageChange}
                />
              </Box>
            )}
            {isLoading && <Spinner size={'lg'} color={'brand.200'} />}
            <VStack
              alignSelf={'flex-start'}
              w={'100%'}
              alignItems={'flex-start'}
              gap={1}
            >
              {jobs?.length > 0 &&
                jobs.map((item) => (
                  <GenericCard
                    key={item.jobId}
                    cardType={CARD_TYPE.JOB_CARD}
                    cardData={item}
                    onRemarksClick={() => onRemarksClick(item)}
                    onAdditionalInfoClick={() => onAdditionalInfoClick(item)}
                    ActionButtons={
                      !isAdmin
                        ? []
                        : [
                            <Button
                              key={'update-job-button'}
                              mt={{ base: 2, md: 0 }}
                              ml={{ base: 0, md: 1 }}
                              type="submit"
                              rounded="full"
                              color={'black'}
                              colorScheme="brand"
                              alignSelf={{ base: 'center', md: 'center' }}
                              px={5}
                              onClick={() => {
                                onUpdateStatusClick({
                                  jobId: item.jobId,
                                  jobStatus: item.jobStatus
                                });
                              }}
                            >
                              Update Status
                            </Button>
                          ]
                    }
                  />
                ))}
            </VStack>
          </VStack>
          <Divider
            style={{ margin: '0' }}
            borderColor="whiteAlpha.300"
            orientation="vertical"
          ></Divider>
        </Grid>
      </ContentLayout>
    </Box>
  );
};

export default Jobs;

function getDeviceId() {
  return window.localStorage.getItem('device-id');
}

function getToken() {
  return window.localStorage.getItem('vance-app-frontend-token');
}

function setToken(token) {
  return window.localStorage.setItem('vance-app-frontend-token', token);
}

async function removeToken() {
  return window.localStorage.removeItem('vance-app-frontend-token');
}

async function getRole() {
  return await JSON.parse(window.localStorage.getItem('vance-app-user-role'));
}

function setRole(role) {
  return window.localStorage.setItem(
    'vance-app-user-role',
    JSON.stringify(role)
  );
}

export default {
  getToken,
  setToken,
  removeToken,
  getDeviceId,
  getRole,
  setRole
};

import api from '../../api';
import { getErrorMessage } from '../../utils';
import { SOURCE_ACCOUNTS as types } from '../constants';
import { setAlert } from './alertActions';

export const getSourceAccounts =
  ({ status, pageNo, pageSize }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: types.GETTING_SOURCE_ACCOUNTS
      });

      const { data } = await api.sourceAccounts.getSourceAccounts({
        pageNo,
        pageSize,
        status
      });

      return dispatch({
        type: types.GET_SOURCE_ACCOUNTS_SUCCESS,
        payload: data
      });
    } catch (error) {
      dispatch(
        setAlert({
          title: getErrorMessage({
            error,
            genericMessage: 'Failed to get source accounts'
          }),
          status: 'error'
        })
      );
      dispatch({
        type: types.GET_SOURCE_ACCOUNTS_FAILURE,
        payload: error
      });
    }
  };

export const getUserSourceAccounts =
  ({ userId, page = 0, pageSize = 10 }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: types.GETTING_USER_SOURCE_ACCOUNTS
      });
      const { data } = await api.userSourceAccounts.getUserSourceAccounts({
        userId,
        page,
        pageSize
      });
      dispatch({
        type: types.GET_USER_SOURCE_ACCOUNTS_SUCCESS,
        payload: { ...data }
      });
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
      dispatch({
        type: types.GET_USER_SOURCE_ACCOINTS_FAILURE,
        payload: {
          errMsg: error?.message
        }
      });
    }
  };

export const delinkUserSourceAccount =
  ({ userId, sourceAccountId, onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: types.DELETING_USER_SOURCE_ACCOUNT
      });
      await api.userSourceAccounts.deleteUserSourceAccount({
        userId,
        sourceAccountId
      });
      dispatch({
        type: types.DELETE_USER_SOURCE_ACCOUNT_SUCCESS
      });
      onSuccess && onSuccess();
    } catch (error) {
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
      dispatch({
        type: types.DELETE_USER_SOURCE_ACCOUNT_FAILURE,
        payload: {
          errMsg: error?.message
        }
      });
    } finally {
      onComplete && onComplete();
    }
  };

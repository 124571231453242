import React, { useEffect, useState } from 'react';
import ContentLayout from '../../layouts/ContentLayout';
import {
  Text,
  Grid,
  Heading,
  VStack,
  Divider,
  useDisclosure,
  Box,
  IconButton,
  Button,
  HStack
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getPollingStatus,
  getSettlementMessage,
  updatePollingStatus,
  updateSettlementMessage
} from '../../store/actions/configActions';
import { CONFIG_COUNTRIES, ROLES } from '../../constants';
import ModalLayout from '../../components/Modal/ModalLayout';
import UpdateSettlementMsg from '../../components/Modal/UpdateSettlementMsg';
import { AiFillEdit } from 'react-icons/ai';
import ConfirmModal from '../../components/Modal/ConfirmForceInitiate';
import { Link, useNavigate } from 'react-router-dom';
import useIsAdmin from '../../hooks/useIsAdmin';
import FundBenchMarkConfig from './FundBenchmarkConfig';
import useAuthRole from '../../hooks/useAuthRole';
import ConfigList from '../../components/Config/ConfigList';
import { kebabCaseToSpaceSeperate } from '../../utils';
import { FaArrowRight } from 'react-icons/fa';

const Config = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAdmin = useIsAdmin();
  const { hasAuthority } = useAuthRole();
  const { data: settlementMessages, isLoading: isSettlementMessagesLoading } =
    useSelector((state) => state.config.settlementMessages);
  const { data: pollingStatus } = useSelector(
    (state) => state.config.pollingStatus
  );

  const [activeCountry, setActiveCountry] = useState(null);
  const [isUpdatingSettlementMsg, setIsUpdatingSettlementMsg] = useState(false);
  const [isUpdatingPollingStatus, setIsUpdatingPollingStatus] = useState(false);

  const {
    isOpen: isUpdateSettlementModalOpen,
    onOpen: onUpdateSettlementModalOpen,
    onClose: onUpdateSettlementModalClose
  } = useDisclosure();

  const {
    isOpen: isConfirmStatusModalOpen,
    onOpen: onConfirmStatusModalOpen,
    onClose: onConfirmStatusModalClose
  } = useDisclosure();

  const onUpdateSettlementMsgClick = (country) => setActiveCountry(country);

  useEffect(() => {
    if (activeCountry) onUpdateSettlementModalOpen();
  }, [activeCountry]);

  const handleUpdateSettlementModalClose = () => {
    onUpdateSettlementModalClose();
    setActiveCountry(null);
  };

  const handleUpdatePollingStatusClose = () => {
    onConfirmStatusModalClose();
  };

  useEffect(() => {
    dispatch(getSettlementMessage(CONFIG_COUNTRIES));
    dispatch(getPollingStatus());
  }, []);

  const handleUpdateSettlementSubmit = ({
    isDelayed,
    countryCode,
    settlementMessage
  }) => {
    setIsUpdatingSettlementMsg(true);
    dispatch(
      updateSettlementMessage({
        countryCode,
        isDelayed,
        settlementMessage,
        onFinish: () => {
          setIsUpdatingSettlementMsg(false);
          handleUpdateSettlementModalClose();
        }
      })
    );
  };

  const handleUpdatePollingStatusSubmit = () => {
    if (!pollingStatus) return;
    setIsUpdatingPollingStatus(true);
    dispatch(
      updatePollingStatus({
        pollingStatus:
          pollingStatus.poolingEnabled === 'TRUE' ? 'FALSE' : 'TRUE',
        onFinish: () => {
          setIsUpdatingPollingStatus(false);
          handleUpdatePollingStatusClose();
        }
      })
    );
  };

  if (!isAdmin) navigate('/');

  return (
    <Box position={'relative'}>
      {activeCountry && (
        <ModalLayout
          isOpen={isUpdateSettlementModalOpen}
          onClose={handleUpdateSettlementModalClose}
          size={'md'}
        >
          <UpdateSettlementMsg
            countryCode={activeCountry}
            currentMessage={
              settlementMessages[activeCountry]?.settlementMessage
            }
            isDelayed={settlementMessages[activeCountry]?.isDelayed}
            isLoading={isUpdatingSettlementMsg}
            handleSubmit={handleUpdateSettlementSubmit}
          />
        </ModalLayout>
      )}
      <ModalLayout
        isOpen={isConfirmStatusModalOpen}
        onClose={handleUpdatePollingStatusClose}
        size={'md'}
      >
        <ConfirmModal
          handleSubmit={handleUpdatePollingStatusSubmit}
          isLoading={isUpdatingPollingStatus}
          prompt={`${
            pollingStatus?.poolingEnabled === 'TRUE' ? 'Disable' : 'Enable'
          } Polling?`}
        />
      </ModalLayout>
      <ContentLayout>
        <Grid gridTemplateColumns={{ base: '1fr', lg: '7fr 0fr 2fr' }}>
          <VStack alignItems={'flex-start'} p={10} gap={4}>
            <Heading color={'colorPrimary'}>Config Settings</Heading>
            <VStack gap={4} alignItems={'stretch'} w={'100%'} as={'form'}>
              {' '}
              <Heading fontSize={'1.3rem'} color={'white'}>
                Settlement Messages
              </Heading>
              {!isSettlementMessagesLoading &&
                settlementMessages &&
                Object.keys(settlementMessages).map((country) => (
                  <Grid
                    key={country}
                    gridTemplateColumns={{
                      base: '1fr',
                      md: '0.2fr 0.4fr 0.2fr auto'
                    }}
                  >
                    <Text color={'gray'}>
                      {kebabCaseToSpaceSeperate(country)}:
                    </Text>
                    <Text color={'white'}>
                      {settlementMessages[country]?.settlementMessage}
                    </Text>
                    <Text color={'white'}>
                      {String(settlementMessages[country]?.isDelayed) || '-'}
                    </Text>
                    {hasAuthority(ROLES.CONFIG_MANAGER_ROLE) && (
                      <IconButton
                        rounded={'full'}
                        bg={'altGray.400'}
                        color={'brand.500'}
                        _hover={{
                          background: 'brand.500',
                          color: 'altGray.500'
                        }}
                        size={'sm'}
                        fontSize={'0.9rem'}
                        fontWeight={400}
                        icon={<AiFillEdit />}
                        onClick={() => onUpdateSettlementMsgClick(country)}
                      />
                    )}
                  </Grid>
                ))}
            </VStack>

            <Divider
              orientation="horizontal"
              borderColor={'whiteAlpha.300'}
              style={{ margin: '0' }}
            />

            <VStack gap={4} alignItems={'stretch'} w={'100%'} as={'form'}>
              {' '}
              <Heading fontSize={'1.3rem'} color={'white'}>
                Polling Status:
              </Heading>
              <Grid
                gridTemplateColumns={{
                  base: '1fr',
                  md: '0.3fr 0.7fr auto'
                }}
              >
                <Text color={'gray'}>Polling enabled:</Text>
                <Text color={'white'}>
                  {pollingStatus?.poolingEnabled || '-'}
                </Text>
                {hasAuthority(ROLES.CONFIG_MANAGER_ROLE) && (
                  <Button
                    variant={'link'}
                    color={'brand.500'}
                    fontWeight={'200'}
                    onClick={onConfirmStatusModalOpen}
                    rightIcon={<AiFillEdit color={'brand.500'} />}
                    _hover={{
                      textDecor: 'underline'
                    }}
                  >
                    {pollingStatus?.poolingEnabled === 'TRUE'
                      ? 'Disable'
                      : 'Enable'}
                  </Button>
                )}
              </Grid>
            </VStack>

            <Divider
              orientation="horizontal"
              borderColor={'whiteAlpha.300'}
              style={{ margin: '0' }}
            />

            <FundBenchMarkConfig />

            <Divider
              orientation="horizontal"
              borderColor={'whiteAlpha.300'}
              style={{ margin: '0' }}
            />
            <ConfigList />
            <Link to={'/config/fx-rate-peaking'}>
              <HStack gap={1}>
                <Text fontSize={'md'} color="#81EBAB">
                  FX Rate Peaking Config
                </Text>
                <FaArrowRight color="#81EBAB" />
              </HStack>
            </Link>
            <Link to={'/config/transfer-experience'}>
              <HStack gap={1}>
                <Text fontSize={'md'} color="#81EBAB">
                  Transfer Experience
                </Text>
                <FaArrowRight color="#81EBAB" />
              </HStack>
            </Link>
          </VStack>
          <Divider
            style={{ margin: '0' }}
            borderColor="whiteAlpha.300"
            orientation="vertical"
          ></Divider>
        </Grid>
      </ContentLayout>
    </Box>
  );
};

export default Config;

export default function searchApi(fetch, baseUrl) {
  return {
    users({
      query = '',
      pageNo = 0,
      pageSize = 10,
      kycFilter = '',
      countryCode
    }) {
      return fetch(
        `${baseUrl}/user?page_size=${pageSize}&search=${query}&page=${pageNo}${
          kycFilter?.length ? '&kyc_status=' + kycFilter : ''
        }${
          countryCode?.length
            ? `&country_code=${encodeURIComponent(countryCode)}`
            : ''
        }`
      );
    },

    orders(query, pageNo = 0, pageSize = 10) {
      return fetch(
        `${baseUrl}/orders/search/${query.trim()}?page_size=${pageSize}&page=${pageNo}`
      );
    },
    ordersV2(query, orderType, pageNo = 0, pageSize = 10) {
      return fetch(
        `${baseUrl}/orders/search?page_size=${pageSize}&page=${pageNo}${
          query?.length ? `&query=${query.trim()}` : ''
        }${orderType?.length ? `&order_type=${orderType.trim()}` : ''}`
      );
    },
    luluOrders({ query, pageNo = 0, pageSize = 10 }) {
      return fetch(
        `${baseUrl}/orders/lulu/search/${query.trim()}?page_size=${pageSize}&page=${pageNo}`
      );
    }
  };
}

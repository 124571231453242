import {
  Box,
  Button,
  FormLabel,
  HStack,
  Input,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Select,
  VStack
} from '@chakra-ui/react';
import { FEATURE_FLAGS } from '../../../constants';
import { kebabCaseToSpaceSeperate } from '../../../utils';
import { useState } from 'react';

const UpdateFeatureFlagModal = ({
  isLoading,
  initialFeatureFlagValue,
  onCancel,
  onSubmit
}) => {
  const [featureConfig, setFeatureConfig] = useState({
    featureValue: initialFeatureFlagValue || '',
    featureName: FEATURE_FLAGS.SABER_GBP_TO_USDT_CONVERSION_RATE
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit && onSubmit(featureConfig);
  };

  return (
    <>
      <ModalHeader>Update Feature Flag</ModalHeader>
      <form onSubmit={handleSubmit}>
        <ModalBody>
          <VStack width={'full'} alignItems={'stretch'} gap={3}>
            <Box>
              <FormLabel>Feature Name</FormLabel>
              <Select
                value={featureConfig?.featureName}
                onChange={(e) => {
                  setFeatureConfig((prev) => ({
                    ...prev,
                    featureName: e.target.value
                  }));
                }}
              >
                {Object.keys(FEATURE_FLAGS).map((featureFlag) => (
                  <option value={featureFlag} key={featureFlag}>
                    {kebabCaseToSpaceSeperate(featureFlag)}
                  </option>
                ))}
              </Select>
            </Box>
            <Box>
              <FormLabel>Feature Value</FormLabel>
              <Input
                value={featureConfig.featureValue}
                onChange={(e) => {
                  setFeatureConfig((prev) => ({
                    ...prev,
                    featureValue: e.target.value
                  }));
                }}
              />
            </Box>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <HStack justifyContent={'end'} gap={2}>
            <Button
              onClick={onCancel}
              variant={'outline'}
              colorScheme="red"
              type="button"
              isDisabled={isLoading}
            >
              Cancel
            </Button>
            <Button
              isLoading={isLoading}
              type="submit"
              variant={'outline'}
              colorScheme="green"
            >
              Submit
            </Button>
          </HStack>
        </ModalFooter>
      </form>
    </>
  );
};

export default UpdateFeatureFlagModal;

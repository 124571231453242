const FUNNEL = {
  UPDATE_FUNNEL_ORDERS_STATUS_COUNT: 'UPDATE_FUNNEL_ORDERS_STATUS_COUNT',
  GETTING_FUNNEL_ORDERS: 'GETTING_FUNNEL_ORDERS',
  GET_FUNNEL_ORDERS_SUCCESS: 'GET_FUNNEL_ORDERS_SUCCESS',
  GET_FUNNEL_ORDERS_FAILURE: 'GET_FUNNEL_ORDERS_FAILURE',
  GETTING_LULU_FUNNEL_ORDERS: 'GETTING_LULU_FUNNEL_ORDERS',
  GET_LULU_FUNNEL_ORDERS_SUCCESS: 'GET_LULU_FUNNEL_ORDERS_SUCCESS',
  GET_LULU_FUNNEL_ORDERS_FAILURE: 'GET_LULU_FUNNEL_ORDERS_FAILURE',
  GETTING_FUNNEL_USERS: 'GETTING_FUNNEL_USERS',
  GET_FUNNEL_USERS_SUCCESS: 'GET_FUNNEL_USERS_SUCCESS',
  GET_FUNNEL_USERS_FAILURE: 'GET_FUNNEL_ORDERS_FAILURE',

  GETTING_FUNNEL_V2_ORDERS: 'GETTING_FUNNEL_V2_ORDERS',
  GET_FUNNEL_V2_ORDERS_SUCCESS: 'GET_FUNNEL_V2_ORDERS_SUCCESS',
  GET_FUNNEL_V2_ORDERS_FAILURE: 'GET_FUNNEL_V2_ORDERS_FAILURE',
  /* NROB_FUNNEL */
  GETTING_NROB_FUNNEL: 'GETTING_NROB_FUNNEL',
  GET_NROB_FUNNEL_SUCCESS: 'GET_NROB_FUNNEL_SUCCESS',
  GET_NROB_FUNNEL_FAILURE: 'GET_NROB_FUNNEL_FAILURE',
  /* VDA FUNNEL */
  GETTING_SABER_FUNNEL: 'GETTING_SABER_FUNNEL',
  GET_SABER_FUNNEL_SUCCESS: 'GET_SABER_FUNNEL_SUCCESS',
  GET_SABER_FUNNEL_FAILURE: 'GET_SABER_FUNNEL_FAILURE'
};

export default FUNNEL;

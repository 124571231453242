export default function (fetch, baseUrl) {
  return {
    getDowntimeConfig() {
      return fetch(`${baseUrl}/acquirer-downtime`);
    },
    updateAcquirerDowntime({
      paymentAcquirer,
      downtimeStartDate,
      downtimeEndDate,
      downtimeReason
    }) {
      return fetch.put(`${baseUrl}/acquirer-downtime`, {
        paymentAcquirer,
        downtimeStartDate,
        downtimeEndDate,
        downtimeReason
      });
    }
  };
}

import {
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  Text
} from '@chakra-ui/react';
import React, { useMemo } from 'react';
import JsonTextBox from '../JsonTextBlock';

const JsonViewer = ({ jsonString, title, subTitle }) => {
  const jsonObj = useMemo(() => {
    if (!jsonString) return jsonString;
    let jsonObj;
    try {
      jsonObj = JSON.parse(jsonString);
      return jsonObj;
    } catch (err) {
      return jsonString;
    }
  }, [jsonString]);

  return (
    <>
      <ModalHeader textAlign={'center'} py={0}>
        {title}
      </ModalHeader>
      {subTitle && (
        <Text
          textAlign={'center'}
          fontSize={'0.75rem'}
          color={'shadedGray.400'}
        >
          {subTitle}
        </Text>
      )}
      <ModalCloseButton />
      <ModalBody pb={3}>
        {jsonObj && <JsonTextBox minH={'3rem'} data={jsonObj} maxH="75vh" />}
      </ModalBody>
    </>
  );
};

export default JsonViewer;

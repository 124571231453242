import {
  Box,
  Divider,
  Flex,
  Grid,
  Heading,
  HStack,
  Link,
  Spinner,
  StackDivider,
  Text,
  VStack
} from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import DetailsTable from '../OrderDetailsCard/DetailsTable';
import { Link as ReactLink, useNavigate } from 'react-router-dom';
import { BsArrowRight } from 'react-icons/bs';
import {
  clearSipDetails,
  getSipDetails
} from '../../store/actions/investmentActions';
import { getSipDetailsTable } from './utils';
import { ORDER_PRODUCTS, PRODUCT } from '../../constants';
import OrdersList from '../OrdersList';
import useIsMobile from '../../hooks/useIsMobile';
import InvestmentOrderDetailsCard from '../InvestmentOrderDetailsCard';

const SipDetailCard = ({ sipId, showNavLink = false }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [activeOrder, setActiveOrder] = useState(null);
  const [sipDetailsTable, setSipDetailsTable] = useState([]);

  const { data: sipDetails, isLoading } = useSelector(
    (state) => state.investments.sipDetails
  );

  useEffect(() => {
    dispatch(getSipDetails(sipId));
  }, [sipId, dispatch]);

  useEffect(() => {
    setSipDetailsTable(sipDetails ? getSipDetailsTable(sipDetails) : null);
  }, [sipDetails]);

  useEffect(() => {
    return () => dispatch(clearSipDetails());
  }, [dispatch]);

  const onOrderDetailsClick = useCallback(
    (orderId) => {
      if (isMobile) navigate(`/investments/orders/${orderId}`);
      else setActiveOrder(orderId);
    },
    [isMobile, navigate]
  );

  if (isLoading)
    return (
      <Flex m={'1rem'} justifyContent={'center'} alignItems={'center'} pt={4}>
        <Spinner color="brand.600" />
      </Flex>
    );

  return (
    <Box m={'1rem'} mt={0} mb={0} mr={0} pr={'1rem'} h={'100%'}>
      <Grid gridTemplateColumns={{ base: '1fr', lg: '7fr 2fr' }} h={'100%'}>
        <VStack
          alignItems={'stretch'}
          overflowY={'scroll'}
          className="scroll-sm"
          divider={
            <StackDivider
              style={{ margin: '0' }}
              borderColor="whiteAlpha.300"
            />
          }
        >
          <VStack py={4} alignItems={'stretch'}>
            {sipDetailsTable?.length &&
              sipDetailsTable.map(
                (item, i) =>
                  item.data?.length && (
                    <Box key={item.title || i} pb={3}>
                      {i === 0 ? (
                        <Flex
                          justifyContent={'space-between'}
                          alignItems={'baseline'}
                          pr={6}
                        >
                          <Heading
                            fontSize={'2xl'}
                            color={'brand.500'}
                            mb={'0.5rem'}
                          >
                            {item.title}
                          </Heading>
                          {showNavLink && (
                            <Link
                              as={ReactLink}
                              to={`/user/${sipDetails?.userId}?product=${ORDER_PRODUCTS.INVEST_MF_SIP}`}
                              color="brand.500"
                              fontSize={'sm'}
                            >
                              <HStack>
                                <Text>User Details</Text>
                                <BsArrowRight display={'inline'} />
                              </HStack>
                            </Link>
                          )}
                        </Flex>
                      ) : (
                        <Heading
                          fontSize={'lg'}
                          color={'white'}
                          mb={'0.5rem'}
                          mt={4}
                        >
                          {item.title}
                        </Heading>
                      )}
                      {item.data && (
                        <DetailsTable isSidebar={false} details={item.data} />
                      )}
                    </Box>
                  )
              )}
          </VStack>
          <VStack w={'100%'} alignItems={'stretch'} gap={'0.5rem'}>
            <OrdersList
              orders={sipDetails?.orders}
              pageNo={0}
              totalPages={0}
              onOrderDetailsClick={onOrderDetailsClick}
              product={PRODUCT.INVEST_MF}
            />
          </VStack>
        </VStack>
        {!isMobile && (
          <Box m={0} alignSelf={'flex-start'}>
            {activeOrder && (
              <InvestmentOrderDetailsCard
                userId={sipDetails.userId}
                orderId={activeOrder}
                isSidebar
              />
            )}
          </Box>
        )}
      </Grid>
    </Box>
  );
};

export default SipDetailCard;

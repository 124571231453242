import React, { useEffect, useState } from 'react';
import {
  Text,
  Grid,
  Heading,
  VStack,
  useDisclosure,
  Box,
  IconButton,
  Spinner
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getFundBenchmarkReturns,
  updateFdReturns,
  updateNiftyReturns
} from '../../store/actions/configActions';
import ModalLayout from '../../components/Modal/ModalLayout';
import { AiFillEdit } from 'react-icons/ai';
import useIsSuperAdmin from '../../hooks/useIsSuperAdmin';
import UpdateBenchmarkReturns from '../../components/Modal/UpdateBenchmarkReturns';
import useAuthRole from '../../hooks/useAuthRole';
import { ROLES } from '../../constants';

const FundBenchMarkConfig = () => {
  const dispatch = useDispatch();
  const { hasAuthority } = useAuthRole();
  const { data: benchmarkReturns, isLoading } = useSelector(
    (state) => state.config.fundBenchmarkReturns
  );

  const [isUpdatingReturns, setIsUpdatingReturns] = useState(false);
  const [activeBenchmark, setActiveBenchmark] = useState(null);

  const {
    isOpen: isUpdateReturnsModalOpen,
    onOpen: onUpdateReturnsModalOpen,
    onClose: onUpdateReturnsModalClose
  } = useDisclosure();

  const onUpdateReturnsClick = (benchmark) => setActiveBenchmark(benchmark);

  useEffect(() => {
    if (activeBenchmark) onUpdateReturnsModalOpen();
  }, [activeBenchmark]);

  const handleUpdateReturnsModalClose = () => {
    onUpdateReturnsModalClose();
    setActiveBenchmark(null);
  };

  useEffect(() => {
    dispatch(getFundBenchmarkReturns());
  }, []);

  const handleUpdateReturnsModalSubmit = ({ returnValue, benchmark }) => {
    switch (benchmark) {
      case 'Nifty': {
        setIsUpdatingReturns(true);
        dispatch(
          updateNiftyReturns({
            niftyReturns: returnValue,
            onFinish: () => {
              setIsUpdatingReturns(false);
              handleUpdateReturnsModalClose();
            }
          })
        );
        break;
      }
      case 'FD': {
        setIsUpdatingReturns(true);
        dispatch(
          updateFdReturns({
            fdReturns: returnValue,
            onFinish: () => {
              setIsUpdatingReturns(false);
              handleUpdateReturnsModalClose();
            }
          })
        );
        break;
      }
      default: {
        //do  nothing
        console.log('unsupported return type');
      }
    }
  };

  const getBenchmarkValue = (benchmark) => {
    switch (benchmark) {
      case 'Nifty':
        return benchmarkReturns.niftyReturns;
      case 'FD':
        return benchmarkReturns.fdReturns;
      default:
        return 0;
    }
  };

  if (isLoading)
    return (
      <VStack w={'100%'}>
        <Spinner color={'brand.500'} />
      </VStack>
    );

  return (
    <Box position={'relative'} w={'100%'}>
      {activeBenchmark && (
        <ModalLayout
          isOpen={isUpdateReturnsModalOpen}
          onClose={handleUpdateReturnsModalClose}
          size={'md'}
        >
          <UpdateBenchmarkReturns
            isLoading={isUpdatingReturns}
            benchmark={activeBenchmark}
            currentValue={getBenchmarkValue(activeBenchmark)}
            handleSubmit={handleUpdateReturnsModalSubmit}
          />
        </ModalLayout>
      )}

      <VStack gap={4} alignItems={'stretch'} w={'100%'} as={'form'}>
        {' '}
        <Heading fontSize={'1.3rem'} color={'white'}>
          Fund Benchmarks
        </Heading>
        <Grid
          gridTemplateColumns={{
            base: '1fr',
            md: '0.3fr 0.7fr auto'
          }}
        >
          <Text color={'gray'}>Nifty:</Text>
          <Text color={'white'}>{benchmarkReturns?.niftyReturns}</Text>
          {hasAuthority(ROLES.CONFIG_MANAGER_ROLE) && (
            <IconButton
              rounded={'full'}
              bg={'altGray.400'}
              color={'brand.500'}
              _hover={{
                background: 'brand.500',
                color: 'altGray.500'
              }}
              size={'sm'}
              fontSize={'0.9rem'}
              fontWeight={400}
              icon={<AiFillEdit />}
              onClick={() => onUpdateReturnsClick('Nifty')}
            />
          )}
        </Grid>
        <Grid
          gridTemplateColumns={{
            base: '1fr',
            md: '0.3fr 0.7fr auto'
          }}
        >
          <Text color={'gray'}>FD:</Text>
          <Text color={'white'}>{benchmarkReturns?.fdReturns}</Text>
          {hasAuthority(ROLES.CONFIG_MANAGER_ROLE) && (
            <IconButton
              rounded={'full'}
              bg={'altGray.400'}
              color={'brand.500'}
              _hover={{
                background: 'brand.500',
                color: 'altGray.500'
              }}
              size={'sm'}
              fontSize={'0.9rem'}
              fontWeight={400}
              icon={<AiFillEdit />}
              onClick={() => onUpdateReturnsClick('FD')}
            />
          )}
        </Grid>
      </VStack>
    </Box>
  );
};

export default FundBenchMarkConfig;

export default function platformTransferFee(fetch, baseUrl) {
  return {
    getPlatforms() {
      return fetch.get(`${baseUrl}/apps`);
    },
    updatePlatform({ payload }) {
      return fetch.put(`${baseUrl}/update-providers`, { ...payload });
    }
  };
}

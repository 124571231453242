import {
  Button,
  Grid,
  HStack,
  Heading,
  Table,
  Tbody,
  Td,
  Tr,
  VStack,
  Text,
  useDisclosure,
  IconButton,
  Thead,
  Th,
  Switch
} from '@chakra-ui/react';
import ContentLayout from '../../layouts/ContentLayout';
import ModalLayout from '../../components/Modal/ModalLayout';
import { useDispatch, useSelector } from 'react-redux';
import ScreenConfigCompModal from '../../components/OrderScreenConfig/ScreenConfigComponentModal';
import { tableCellStyles } from '../../components/NrOnboard/constants';
import { MdClose, MdEdit } from 'react-icons/md';
import { useEffect, useState } from 'react';
import {
  getOrderScreenConfigData,
  updateScreenConfLocal,
  updateScreenConfig
} from '../../store/actions/orderScreenConfigActions';
import { useParams } from 'react-router-dom';
import FullPageSpinner from '../../components/FullPageSpinner';
import { transformOrderScreenConfToPayload } from '../../components/OrderScreenConfig/utils';
import ConfirmModal from '../../components/Modal/ConfirmForceInitiate';
import { ORDER_SCREEN_CONF_COMP_TYPES } from '../../constants';
import api from '../../api';

const OrderScreenConfig = () => {
  const { orderId } = useParams();
  const {
    isOpen: isScrConfCompOpen,
    onOpen: onScrConfCompOpen,
    onClose: onScrConfCompClose
  } = useDisclosure();

  const { userId } = useSelector((state) => state?.auth?.user);

  const {
    data: orderScreenConfig,
    prev: prevOrderScrConf,
    isLoading
  } = useSelector((state) => state?.orderScreenConfig);

  const dispatch = useDispatch();

  const [selectedItem, setSelectedItem] = useState(null);
  const [btnsLoading, setBtnsLoading] = useState(false);

  const cellStyles = tableCellStyles(3);

  const handleEditClick = (id) => {
    setSelectedItem(id);
    onScrConfCompOpen();
  };
  const handleCancel = () => {
    setSelectedItem(null);
    onScrConfCompClose();
  };

  const handleSubmit = (data) => {
    if (!selectedItem) {
      return handleAddElement({
        newElData: data
      });
    }
    const payload = {
      ...orderScreenConfig,
      screenConfig: orderScreenConfig?.screenConfig?.map((configEl) =>
        configEl?.id === selectedItem
          ? {
              ...data
            }
          : configEl
      )
    };
    setBtnsLoading(true);
    dispatch(
      updateScreenConfig({
        configData: transformOrderScreenConfToPayload(payload),
        orderId,
        onSuccess: () => {
          dispatch(getOrderScreenConfigData({ orderId }));
          setSelectedItem(null);
          onScrConfCompClose();
        },
        onComplete: () => {
          setBtnsLoading(false);
        }
      })
    );
  };

  useEffect(() => {
    if (!orderId) return;
    dispatch(getOrderScreenConfigData({ orderId }));
  }, [orderId]);

  const handleDocClick = (docUrl) => {
    api.workflowsV2
      .getDocByUrl({ userId, docUrl })
      .then(({ data }) => window.open(data?.url, '_blank'))
      .catch((err) => console.log(err));
  };

  const handleDeleteEl = (elId) => {
    const newScreenConfig = orderScreenConfig?.screenConfig?.map((el) =>
      el?.id === elId
        ? {
            ...el,
            value: null
          }
        : el
    );
    setBtnsLoading(true);
    dispatch(
      updateScreenConfig({
        orderId,
        configData: {
          ...orderScreenConfig,
          screenConfig: newScreenConfig
        },
        onSuccess: () => {
          dispatch(getOrderScreenConfigData({ orderId }));
        },
        onComplete: () => {
          setBtnsLoading(false);
        }
      })
    );
  };

  const handleFlagUpdate = ({ flagName }) => {
    console.log({
      flagName,
      value: orderScreenConfig[flagName]
    });
    const newScrConf = {
      ...orderScreenConfig,
      [flagName]: !orderScreenConfig[flagName]
    };
    dispatch(updateScreenConfLocal(newScrConf));
  };

  const handleAddElement = ({ newElData }) => {
    dispatch(
      updateScreenConfLocal({
        ...orderScreenConfig,
        screenConfig: [...orderScreenConfig?.screenConfig, newElData]
      })
    );
    onScrConfCompClose();
  };

  const isScrConfChanged = (obj1, obj2) => {
    // Check if both objects are of type object
    if (
      typeof obj1 !== 'object' ||
      typeof obj2 !== 'object' ||
      obj1 === null ||
      obj2 === null
    ) {
      return obj1 === obj2; // Check for strict equality for non-objects
    }

    // Check if both objects have the same number of properties
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    if (keys1.length !== keys2.length) {
      return false;
    }

    // Compare each property recursively
    for (let key of keys1) {
      if (
        !obj2.hasOwnProperty(key) ||
        !isScrConfChanged(obj1[key], obj2[key])
      ) {
        return false;
      }
    }

    return true;
  };

  const handleUpdateClick = () => {
    setBtnsLoading(true);
    dispatch(
      updateScreenConfig({
        configData: {
          isComplianceFlag: orderScreenConfig?.isComplianceFlag,
          isAdditionalDetailsRequired:
            orderScreenConfig?.isAdditionalDetailsRequired,
          screenConfig: orderScreenConfig?.screenConfig
        },
        orderId,
        onSuccess: () => {
          dispatch(getOrderScreenConfigData({ orderId }));
        },
        onComplete: () => {
          setBtnsLoading(false);
        }
      })
    );
  };

  if (isLoading) return <FullPageSpinner />;

  return (
    <>
      <ModalLayout isOpen={isScrConfCompOpen} onClose={handleCancel}>
        <ScreenConfigCompModal
          data={
            !selectedItem
              ? null
              : orderScreenConfig?.screenConfig?.reduce((prev, curr) => {
                  return curr?.id === selectedItem ? curr : prev;
                }, {})
          }
          isLoading={btnsLoading}
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
        />
      </ModalLayout>
      <ContentLayout>
        <Grid gridTemplateColumns={{ base: '1fr', lg: '7fr 0fr 2fr' }}>
          <VStack
            alignItems={'stretch'}
            width={'auto'}
            gap={4}
            overflowY={{ base: 'initial', lg: 'scroll' }}
            overflowX={'hidden'}
            h={{ lg: '90vh' }}
            py={10}
            px={{ base: 4, sm: 10 }}
            className="scroll"
          >
            <HStack justifyContent={'space-between'} alignItems={'center'}>
              <Heading size={'lg'} color={'white'}>
                Order Screen Config
              </Heading>
              <Button
                variant={'outline'}
                colorScheme="brand"
                onClick={handleUpdateClick}
                disabled={isScrConfChanged(prevOrderScrConf, orderScreenConfig)}
                isLoading={btnsLoading}
              >
                Update
              </Button>
            </HStack>
            <Table>
              <Tbody>
                <Tr>
                  <Td style={cellStyles}>
                    <Text color={'gray.400'} fontSize={'md'}>
                      Order ID:
                    </Text>
                  </Td>
                  <Td style={cellStyles}>
                    <Text color={'white'} fontSize={'md'}>
                      {orderId}
                    </Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td style={cellStyles}>
                    <Text color={'gray.400'} fontSize={'md'}>
                      isComplianceFlag:
                    </Text>
                  </Td>
                  <Td style={cellStyles}>
                    <Switch
                      onChange={() => {
                        handleFlagUpdate({
                          flagName: 'isComplianceFlag'
                        });
                      }}
                      name="is-editable"
                      isChecked={orderScreenConfig?.isComplianceFlag}
                      size={'lg'}
                      colorScheme="brand"
                    />
                  </Td>
                </Tr>
                <Tr>
                  <Td style={cellStyles}>
                    <Text color={'gray.400'} fontSize={'md'}>
                      isAdditionalDetailsRequired:
                    </Text>
                  </Td>
                  <Td style={cellStyles}>
                    <Switch
                      onChange={() =>
                        handleFlagUpdate({
                          flagName: 'isAdditionalDetailsRequired'
                        })
                      }
                      name="is-editable"
                      isChecked={orderScreenConfig?.isAdditionalDetailsRequired}
                      size={'lg'}
                      colorScheme="brand"
                    />
                  </Td>
                </Tr>
              </Tbody>
            </Table>
            {orderScreenConfig?.isComplianceFlag && (
              <VStack alignItems={'start'} gap={4}>
                <HStack
                  width={'full'}
                  justifyContent={'space-between'}
                  alignContent={'center'}
                >
                  <Heading size={'md'} color={'white'}>
                    Screen Config
                  </Heading>
                  <Button
                    colorScheme="brand"
                    variant={'outline'}
                    onClick={onScrConfCompOpen}
                    disabled={!orderScreenConfig?.isAdditionalDetailsRequired}
                  >
                    Add Component
                  </Button>
                </HStack>
                {orderScreenConfig?.screenConfig?.length > 0 ? (
                  <>
                    <Table>
                      <Thead>
                        <Tr>
                          <Th style={cellStyles}>#</Th>
                          <Th style={cellStyles}>Label</Th>
                          <Th style={cellStyles}>Element Type</Th>
                          <Th style={cellStyles}>Value</Th>
                          <Th style={cellStyles}>Edit</Th>
                          <Th style={cellStyles}>VALUE-NULL</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {orderScreenConfig?.screenConfig
                          ?.filter((configEl) => configEl)
                          ?.map((configEl, ind) => (
                            <Tr key={configEl?.id}>
                              <Td style={cellStyles}>
                                <Text fontSize={'md'} color={'white'}>
                                  {ind + 1}
                                </Text>
                              </Td>
                              <Td style={cellStyles}>
                                <Text fontSize={'md'} color={'white'}>
                                  {configEl?.label}
                                </Text>
                              </Td>
                              <Td style={cellStyles}>
                                <Text fontSize={'md'} color={'white'}>
                                  {configEl?.type}
                                </Text>
                              </Td>
                              <Td style={cellStyles}>
                                {configEl?.type !==
                                ORDER_SCREEN_CONF_COMP_TYPES.UPLOAD_DOC ? (
                                  <Text fontSize={'md'} color={'white'}>
                                    {configEl?.value || '-'}
                                  </Text>
                                ) : !configEl?.value ? (
                                  '-'
                                ) : (
                                  <Button
                                    variant={'link'}
                                    onClick={() =>
                                      handleDocClick(configEl?.value)
                                    }
                                  >
                                    View File
                                  </Button>
                                )}
                              </Td>
                              <Td style={cellStyles}>
                                <IconButton
                                  variant={'unstyled'}
                                  onClick={() => handleEditClick(configEl?.id)}
                                  icon={<MdEdit color="white" />}
                                  disabled={
                                    !orderScreenConfig?.isAdditionalDetailsRequired
                                  }
                                />
                              </Td>
                              <Td style={cellStyles}>
                                <IconButton
                                  variant={'unstyled'}
                                  onClick={() => handleDeleteEl(configEl?.id)}
                                  icon={<MdClose color="red" />}
                                  disabled={
                                    !orderScreenConfig?.isAdditionalDetailsRequired
                                  }
                                />
                              </Td>
                            </Tr>
                          ))}
                      </Tbody>
                    </Table>
                  </>
                ) : (
                  <Text color={'gray.400'}>No Config Elements</Text>
                )}
              </VStack>
            )}
          </VStack>
        </Grid>
      </ContentLayout>
    </>
  );
};

export default OrderScreenConfig;

import {
  Grid,
  HStack,
  Stack,
  StackDivider,
  Text,
  VStack,
  Link,
  Button
} from '@chakra-ui/react';
import { Link as ReactLink } from 'react-router-dom';
import { getSourceAccountStatusColor } from '../../utils';

const SourceAccountCard = ({
  accountData,
  isUserSrcAcct = false,
  onDelinkClick = () => {}
}) => {
  return (
    <VStack
      alignSelf={'flex-start'}
      w={{ base: '100%', md: '100%' }}
      maxW={'850px'}
      bg="backGround"
      borderRadius="12px"
      divider={
        <StackDivider style={{ margin: '0' }} borderColor="whiteAlpha.300" />
      }
    >
      <Grid
        gridTemplateColumns={'repeat(2,auto)'}
        rowGap={2}
        w={'90%'}
        justifyContent="space-between"
        m={'1rem'}
      >
        <HStack alignItems={'flex-start'}>
          <Text fontSize={{ base: 'sm', sm: 'md' }} color="white">
            {accountData.acquirer}
          </Text>
        </HStack>

        <HStack alignItems={'flex-start'}>
          <Text
            fontSize={{ base: 'sm', sm: 'md' }}
            color={getSourceAccountStatusColor(accountData.status) || 'white'}
          >
            {accountData.status}
          </Text>
        </HStack>
      </Grid>
      <Grid
        gridTemplateColumns={{
          base: '1fr',
          md: `1fr 1fr 1fr 1fr`
        }}
        gap={2}
        alignItems={'flex-start'}
        w={'90%'}
        m={'1rem'}
        textAlign={{ base: 'end', md: 'start' }}
      >
        <Stack
          direction={{ base: 'row', md: 'column' }}
          justifyContent={'space-between'}
          height={'100%'}
        >
          <Text fontSize={'sm'} color={'gray'}>
            {isUserSrcAcct ? 'Bank:' : 'User:'}
          </Text>
          {!isUserSrcAcct ? (
            <Link
              as={ReactLink}
              to={`/user/${accountData.userId}`}
              color="white"
              fontSize={'sm'}
            >
              <HStack pt={2}>
                <Text>{accountData.userName}</Text>
              </HStack>
            </Link>
          ) : (
            <Text color="white" fontSize={'sm'}>
              {accountData?.bankName}
            </Text>
          )}
        </Stack>
        <Stack
          direction={{ base: 'row', md: 'column' }}
          justifyContent={'space-between'}
          height={'100%'}
        >
          <Text fontSize={'sm'} color={'gray'}>
            Bank Id
          </Text>
          <Text fontSize={'sm'} color={'white'}>
            {accountData.bankIdentifier || '-'}
          </Text>
        </Stack>
        <Stack
          direction={{ base: 'row', md: 'column' }}
          justifyContent={'space-between'}
          height={'100%'}
        >
          <Text fontSize={'sm'} color={'gray'}>
            IBAN
          </Text>
          <Text fontSize={'sm'} color={'white'}>
            {accountData.iban || '-'}
          </Text>
        </Stack>
        {isUserSrcAcct && (
          <HStack justifyContent={'end'}>
            <Stack
              direction={{ base: 'row', md: 'column' }}
              justifyContent={'space-between'}
              height={'100%'}
            >
              <Button
                onClick={onDelinkClick}
                variant={'outline'}
                colorScheme="red"
              >
                Delink
              </Button>
            </Stack>
          </HStack>
        )}
      </Grid>
    </VStack>
  );
};

export default SourceAccountCard;

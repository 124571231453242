import React, { useCallback, useEffect, useRef } from 'react';
import InputComponent from '../../components/Input';
import { Button, Box, Select } from '@chakra-ui/react';
import { MdSearch } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { COUNTRY_CODE } from '../../constants';
import { getAllUsers, getUserCount } from '../../store/actions/usersActions';
import { updateUsersReqData } from '../../store/actions/reqDataActions';
import useDidMountEffect from '../../hooks/useDidMount';
import UsersList from '../../components/UsersList';

const UsersContent = () => {
  const dispatch = useDispatch();
  const searchTimer = useRef(null);

  const searchReqData = useSelector((state) => state.reqData.dashboard.users);

  const searchResult = useSelector((state) => ({
    users: state.resData.users.data[searchReqData.pageNo] || [],
    totalPages: state.resData.users.totalPages || 0,
    isLoading: state.resData.users.isLoading,
    isError: state.resData.users.isError,
    error: state.resData.users.error,
    userCount: state.users.userCount
  }));

  //refetch users data on change in username query, with a delay of 2 seconds
  useDidMountEffect(() => {
    if (searchTimer.current) {
      clearTimeout(searchTimer.current);
      searchTimer.current = setTimeout(() => {
        searchUsers(true);
      }, 2000);
    } else {
      searchUsers(true);
      searchTimer.current = true;
    }
  }, [searchReqData.query]);

  useDidMountEffect(() => {
    searchUsers(true);
  }, [searchReqData.kycFilter, searchReqData.countryCode]);

  useDidMountEffect(() => {
    searchUsers();
  }, [searchReqData.pageNo]);

  useEffect(() => {
    if (searchResult.users?.length) return;

    dispatch(getAllUsers(searchReqData));
    dispatch(getUserCount());
  }, []);

  // isPageChanged is true if search is triggered from page change
  const searchUsers = async (refresh = false) => {
    if (refresh || !searchResult.users?.length)
      dispatch(getAllUsers(searchReqData, refresh));
  };

  const handleSearchReqChange = useCallback(
    (e) => {
      if (e.target.value === searchReqData.query) return;
      dispatch(updateUsersReqData({ query: e.target.value, pageNo: 0 }));
    },
    [dispatch, searchReqData.query]
  );

  const handleFilterChange = useCallback(
    (e) => {
      dispatch(
        updateUsersReqData({ [e.target.name]: e.target.value, pageNo: 0 })
      );
    },
    [dispatch]
  );

  const handlePageChange = useCallback(
    (pageNo) => {
      pageNo - 1 !== searchReqData.pageNo &&
        dispatch(updateUsersReqData({ pageNo: pageNo - 1 }));
    },
    [dispatch, searchReqData.pageNo]
  );

  return (
    <>
      <InputComponent
        type="text"
        value={searchReqData.query}
        name="search"
        handleChange={handleSearchReqChange}
        placeholder="Search by name, email, or mobile number...."
        inputTitle="Search users"
        leftElement={<MdSearch size="2rem" color="white" />}
        width="40rem"
        maxWidth="100%"
        autoComplete={'off'}
        spellcheck={false}
        autoCorrect={'off'}
      />

      {/* <Box width="100%">
        <Box maxWidth="20rem" my={2}>
          <Select
            placeholder="Filter KYC status"
            onChange={handleFilterChange}
            name="kycFilter"
            bg="black"
            color="gray"
            value={searchReqData.kycFilter}
          >
            {Object.keys(KYC_STATUS).map((status) => (
              <option value={status} key={status}>
                {status}
              </option>
            ))}
          </Select>
        </Box>
      </Box> */}
      <Box width="100%">
        <Box maxWidth="13rem" my={2}>
          <Select
            placeholder="Filter Country Code"
            onChange={handleFilterChange}
            bg="black"
            color="gray"
            value={searchReqData.countryCode}
            name="countryCode"
          >
            {Object.keys(COUNTRY_CODE).map((status) => (
              <option value={status} key={status}>
                {status}
              </option>
            ))}
          </Select>
        </Box>
      </Box>
      <Button
        type="submit"
        color="black"
        rounded="lg"
        colorScheme="brand"
        py={2}
        onClick={() => searchUsers()}
        fontWeight={600}
        isLoading={searchResult.isLoading}
        alignSelf="start"
        style={{
          marginTop: '1em'
        }}
      >
        Search
      </Button>

      <UsersList
        users={searchResult.users}
        pageNo={searchReqData.pageNo}
        totalPages={searchResult.totalPages}
        handlePageChange={handlePageChange}
      />
    </>
  );
};

export default UsersContent;

import { KYC as types } from '../constants';

const initialState = {
  kycs: {
    isLoading: false,
    isError: false,
    error: null,
    data: null,
    totalPages: 0
  },
  user: {
    kyc: {
      isLoading: false,
      isError: false,
      error: null,
      data: null
    },
    docs: {
      isLoading: false,
      isError: false,
      error: null,
      data: null,
      totalPages: 0
    },
    checks: {
      isLoading: false,
      isError: false,
      error: null,
      data: null,
      totalPages: 0
    }
  }
};

const kycReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.GETTING_KYC_DOCS:
      return {
        ...state,
        user: {
          ...state.user,
          docs: {
            ...state.user.docs,
            isLoading: true,
            isError: false,
            error: null,
            data: null,
            totalPages: 0
          }
        }
      };

    case types.GET_KYC_DOCS_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          docs: {
            ...state.user.docs,
            isLoading: false,
            isError: false,
            error: null,
            data: payload.data,
            totalPages: payload.totalPages
          }
        }
      };

    case types.GET_KYC_DOCS_FAILURE:
      return {
        ...state,
        user: {
          ...state.user,
          docs: {
            ...state.user.docs,
            isLoading: false,
            isError: true,
            error: payload,
            data: null,
            totalPages: 0
          }
        }
      };

    case types.CLEAR_KYC_DOCS:
      return {
        ...state,
        user: {
          ...state.user,
          docs: {
            isLoading: false,
            isError: false,
            error: null,
            data: null,
            totalPages: 0
          }
        }
      };

    case types.GETTING_KYC_CHECKS:
      return {
        ...state,
        user: {
          ...state.user,
          checks: {
            ...state.user.checks,
            isLoading: true,
            isError: false,
            error: null,
            data: null,
            totalPages: 0
          }
        }
      };

    case types.GET_KYC_CHECKS_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          checks: {
            ...state.user.checks,
            isLoading: false,
            isError: false,
            error: null,
            data: payload.data,
            totalPages: payload.totalPages
          }
        }
      };

    case types.GET_KYC_CHECKS_FAILURE:
      return {
        ...state,
        user: {
          ...state.user,
          checks: {
            ...state.user.checks,
            isLoading: false,
            isError: true,
            error: payload,
            data: null,
            totalPages: 0
          }
        }
      };

    case types.CLEAR_KYC_CHECKS:
      return {
        ...state,
        user: {
          ...state.user,
          checks: {
            isLoading: false,
            isError: false,
            error: null,
            data: null,
            totalPages: 0
          }
        }
      };

    case types.GETTING_ALL_KYCS:
      return {
        ...state,
        kycs: {
          ...state.kycs,
          isLoading: true,
          isError: false,
          error: null,
          data: null,
          totalPages: 0
        }
      };

    case types.GET_ALL_KYCS_SUCCESS:
      return {
        ...state,
        kycs: {
          ...state.kycs,
          isLoading: false,
          isError: false,
          error: null,
          data: payload.data,
          totalPages: payload.totalPages
        }
      };

    case types.GET_ALL_KYCS_FAILURE:
      return {
        ...state,
        kycs: {
          ...state.kycs,
          isLoading: false,
          isError: true,
          error: payload,
          data: null,
          totalPages: 0
        }
      };

    case types.CLEAR_ALL_KYCS:
      return {
        ...state,
        kycs: {
          isLoading: false,
          isError: false,
          error: null,
          data: null,
          totalPages: 0
        }
      };

    case types.GETTING_USER_KYC:
      return {
        ...state,
        user: {
          ...state.user,
          kyc: {
            ...state.user.kyc,
            isLoading: true,
            isError: false,
            error: null,
            data: null
          }
        }
      };

    case types.GET_USER_KYC_FAILURE:
      return {
        ...state,
        user: {
          ...state.user,
          kyc: {
            ...state.user.kyc,
            isLoading: false,
            isError: true,
            error: payload,
            data: null
          }
        }
      };

    case types.GET_USER_KYC_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          kyc: {
            ...state.user.kyc,
            isLoading: false,
            isError: false,
            error: null,
            data: payload
          }
        }
      };

    case types.CLEAR_USER_KYC:
      return {
        ...state,
        user: {
          ...state.user,
          kyc: {
            isLoading: false,
            isError: false,
            error: null,
            data: null
          }
        }
      };

    case types.SYNCING_USER_KYC:
      return {
        ...state,
        user: {
          ...state.user,
          kyc: {
            ...state.user.kyc,
            isLoading: true,
            isError: false,
            error: null,
            data: null
          }
        }
      };
    case types.SYNC_USER_KYC_SUCCESS:
      return {
        ...state,
        kycs: {
          ...state.kycs,
          isLoading: false,
          isError: false,
          error: null,
          totalPages: 0,
          data: null
        },
        user: {
          ...state.user,
          kyc: {
            ...state.user.kyc,
            isLoading: false,
            isError: false,
            error: null,
            data: payload
          }
        }
      };

    case types.SYNC_USER_KYC_FAILURE:
      return {
        ...state,
        user: {
          ...state.user,
          kyc: {
            ...state.user.kyc,
            isLoading: false,
            isError: true,
            error: payload,
            data: null
          }
        }
      };

    case types.UPDATE_CHECK_STATUS_SUCCESS:
      return {
        ...initialState
      };

    case types.CREATE_E_KYC_SUCCESS:
      return {
        ...initialState
      };

    case types.CREATE_M_KYC_SUCCESS:
      return {
        ...initialState
      };

    default:
      return {
        ...state
      };
  }
};

export default kycReducer;

import { Table, TableContainer, Tbody, Td, Tr, Text } from '@chakra-ui/react';
import React from 'react';
import useIsMobile from '../../hooks/useIsMobile';
import CopyableText from '../CopyableText';

const DetailsTable = ({
  details,
  isSidebar = false,
  titleW = 45,
  rowGap = 0
}) => {
  const isMobile = useIsMobile();

  return (
    <TableContainer mt={'1rem'}>
      <Table
        variant="simple"
        w={'100%'}
        style={{ borderCollapse: 'separate', borderSpacing: `0 ${rowGap}` }}
      >
        <Tbody>
          {details?.map((item, idx) => (
            <Tr key={idx}>
              <Td
                color={'gray'}
                fontSize="sm"
                pt="0"
                pb={'1.3'}
                pl={isSidebar ? 0 : 4}
                border="none"
                w={`${titleW}%`}
                overflowWrap={'break-word'}
              >
                {item.name}
              </Td>
              <Td
                color={'white'}
                fontSize="sm"
                p="0"
                pb={'1.3'}
                border="none"
                pr={4}
                w={`${100 - titleW}%`}
              >
                {item.isCopyable ? (
                  <CopyableText
                    w={isMobile ? 'initial' : '100%'}
                    minW={isMobile ? 'none' : '160px'}
                    wordBreak="break-all"
                    whiteSpace={'normal'}
                    m={0}
                    title={item.name.trim().slice(0, item.name.length - 1)}
                  >
                    {item.value}
                  </CopyableText>
                ) : (
                  <Text
                    w={isMobile ? 'initial' : '100%'}
                    minW={isMobile ? 'none' : '160px'}
                    wordBreak="break-all"
                    whiteSpace={'normal'}
                    m={0}
                  >
                    {item.value}
                  </Text>
                )}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default DetailsTable;

import { Box, useDisclosure, VStack, Text, Button } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import ModalLayout from '../Modal/ModalLayout';
import OrderMetadataModal from '../Modal/OrderMetadataModal';
import OrderCard from '../OrderCard';
import Pagination from '../Pagination';
import { CARD_TYPE, ORDER_PRODUCTS } from '../../constants';
import InvestmentOrderCard from '../InvestmentOrderCard';
import SipCard from '../Sip/SipCard';
import GenericCard from '../GenericCard';
import { MdEast } from 'react-icons/md';

const OrdersList = ({
  orders,
  pageNo,
  totalPages,
  handlePageChange,
  onOrderDetailsClick,
  show,
  product = ORDER_PRODUCTS.REMITTANCE,
  lulu = false,
  isSidebar = false
}) => {
  const {
    isOpen: isEditFlagOpen,
    onOpen: onEditFlagOpen,
    onClose: onEditFlagClose
  } = useDisclosure();

  const [activeFlag, setActiveFlag] = useState({
    orderId: null,
    note: '',
    complianceFlagged: false
  });

  useEffect(() => {
    if (activeFlag?.orderId) onEditFlagOpen();
  }, [activeFlag]);

  const handleFlagClick = (orderData) =>
    setActiveFlag({
      orderId: orderData?.orderId,
      note: orderData?.metadata?.details,
      complianceFlagged: orderData?.metadata?.complianceFlagged
    });

  return (
    <>
      <ModalLayout isOpen={isEditFlagOpen} onClose={onEditFlagClose}>
        <OrderMetadataModal
          orderId={activeFlag?.orderId}
          onClose={onEditFlagClose}
          initNote={activeFlag?.note}
          initFlag={!activeFlag?.complianceFlagged}
          title={'Update compliance flag'}
          successMessage={`Updated compliance flag for order ${activeFlag?.orderId}`}
          failureMessage={`Failed to update compliance flag for order ${activeFlag?.orderId}`}
        />
      </ModalLayout>
      {!isSidebar && totalPages > 1 && (
        <Box alignSelf={'start'}>
          <Pagination
            totalPages={totalPages}
            currentPage={pageNo + 1}
            onPageChange={handlePageChange}
          />
        </Box>
      )}
      <VStack
        py={5}
        alignSelf={'flex-start'}
        w={{ base: '100%', lg: '56rem' }}
        maxW={'100%'}
        alignItems={'stretch'}
        gap={1}
      >
        {orders?.length > 0 &&
          orders.map((item, idx) =>
            product === ORDER_PRODUCTS.INVEST_MF ? (
              <InvestmentOrderCard
                key={idx}
                orderData={item}
                onOrderDetailsClick={(orderId) =>
                  onOrderDetailsClick(orderId, item.userId)
                }
              />
            ) : product === ORDER_PRODUCTS.REMITTANCE ? (
              lulu ? (
                <GenericCard
                  key={idx}
                  cardData={item}
                  cardType={CARD_TYPE.LULU_FUNNEL_ORDER_CARD}
                  ActionButtons={
                    item.referredUserId === null
                      ? []
                      : [
                          <Button
                            key={'order-details-button'}
                            mt={{ base: 2, md: 0 }}
                            ml={{ base: 0, md: 1 }}
                            rightIcon={<MdEast color="black" />}
                            type="submit"
                            rounded="lg"
                            color={'black'}
                            colorScheme="brand"
                            alignSelf={{ base: 'center', md: 'center' }}
                            px={5}
                            onClick={() => {
                              onOrderDetailsClick(item.orderId, item.userId);
                            }}
                          >
                            Order details
                          </Button>
                        ]
                  }
                />
              ) : (
                <OrderCard
                  key={idx}
                  orderData={item}
                  onOrderDetailsClick={(orderId) =>
                    onOrderDetailsClick(orderId, item.userId)
                  }
                  onFlagButtonClick={() => handleFlagClick(item)}
                  show={show}
                  isSidebar={isSidebar}
                />
              )
            ) : (
              <SipCard
                key={idx}
                orderData={item}
                onOrderDetailsClick={(sipId) =>
                  onOrderDetailsClick(sipId, item.userId)
                }
              />
            )
          )}
        {orders?.length === 0 && <Text color="brand.500">No orders</Text>}
      </VStack>
    </>
  );
};

export default OrdersList;

import { VStack } from '@chakra-ui/react';
import FtnrDocumentTab from './FtnrDocumentTab';
import { getNotarizedDocs, getOtherDocs } from './utils';

const FtnrDocuments = ({ formData, handleViewFileClick }) => {
  const documentTabs = {
    documentsToBeUploaded: getNotarizedDocs(formData),
    otherDocuments: getOtherDocs(formData)
  };
  return (
    <VStack alignItems={'stretch'} gap={4}>
      <FtnrDocumentTab
        userId={formData?.userId}
        title={'Documents to be uploaded'}
        documents={documentTabs?.documentsToBeUploaded}
        handleViewFileClick={handleViewFileClick}
        hasStatus={true}
      />
      <FtnrDocumentTab
        userId={formData?.userId}
        title={'Other documents'}
        documents={documentTabs?.otherDocuments}
        handleViewFileClick={handleViewFileClick}
      />
    </VStack>
  );
};

export default FtnrDocuments;

import { toSearchInvestmentOrdersDto } from '../../utils';

export default function investmentsApi(fetch, baseUrl = '') {
  return {
    orders({ pageNo = 0, pageSize = 10, query, filterKey, filterValue }) {
      return fetch.post(
        `${baseUrl}/orders`,
        toSearchInvestmentOrdersDto({
          pageNo,
          pageSize,
          query,
          filterKey,
          filterValue
        })
      );
    },

    orderById({ orderId }) {
      return fetch.get(`${baseUrl}/orders/${orderId}`);
    },

    userOrders({ userId, pageNo = 0, pageSize = 10 }) {
      return fetch.get(
        `${baseUrl}/user/${userId}/orders?page=${pageNo}&page_size=${pageSize}`
      );
    },

    userSips({ userId, pageNo = 0, pageSize = 10 }) {
      return fetch.get(
        `${baseUrl}/user/${userId}/sips?page=${pageNo}&page_size=${pageSize}`
      );
    },

    sipById({ sipId }) {
      return fetch.get(`${baseUrl}/sips/${sipId}`);
    }
  };
}

import {
  Button,
  Grid,
  HStack,
  Stack,
  StackDivider,
  Text,
  VStack
} from '@chakra-ui/react';
import React from 'react';
import { MdEast } from 'react-icons/md';
import { getInvestmentOrderStatusColor } from '../../utils';
import CopyableText from '../CopyableText';

const InvestmentOrderCard = ({ onOrderDetailsClick, orderData }) => {
  return (
    <VStack
      bg="backGround"
      borderRadius="12px"
      w={'52rem'}
      maxW={'100%'}
      divider={
        <StackDivider style={{ margin: '0' }} borderColor="whiteAlpha.300" />
      }
    >
      <Grid
        gridTemplateColumns={{
          base: 'repeat(2, auto)',
          md: 'repeat(3, auto)'
        }}
        rowGap={2}
        w={'90%'}
        justifyContent="space-between"
        m={'1rem'}
      >
        <HStack>
          <Text fontSize={{ base: 'sm', sm: 'md' }} color="gray">
            Order ID:
          </Text>
          <CopyableText
            title={'Order ID'}
            fontSize={{ base: 'sm', sm: 'md' }}
            color="white"
          >
            {orderData.orderId}
          </CopyableText>
        </HStack>
        <HStack>
          <Text
            fontSize={{ base: 'sm', sm: 'md' }}
            color={getInvestmentOrderStatusColor(orderData.orderStatus)}
          >
            {orderData.orderStatus}
          </Text>
        </HStack>
        <Stack direction={{ base: 'column', sm: 'row' }}>
          <Text fontSize={{ base: 'sm', sm: 'md' }} color="gray">
            Created on:
          </Text>
          <Text fontSize={{ base: 'sm', sm: 'md' }} color="white">
            {orderData.createdAt}
          </Text>
        </Stack>
      </Grid>
      <Grid
        gridTemplateColumns={{
          base: '1fr',
          md: `1fr 1fr 1.2fr auto`
        }}
        gap={2}
        alignItems={'flex-start'}
        w={'90%'}
        m={'1rem'}
        textAlign={{ base: 'end', md: 'start' }}
      >
        <Stack
          direction={{ base: 'row', md: 'column' }}
          justifyContent={'space-between'}
        >
          <Text fontSize={'sm'} color={'gray'}>
            Order Type
          </Text>
          <Text fontSize={'sm'} color={'white'}>
            {orderData.orderType}
          </Text>
        </Stack>
        <Stack
          direction={{ base: 'row', md: 'column' }}
          justifyContent={'space-between'}
        >
          <Text fontSize={'sm'} color={'gray'}>
            Payment mode
          </Text>
          <Text fontSize={'sm'} color={'white'}>
            {orderData.isSip ? 'SIP' : 'LUMP'}
          </Text>
        </Stack>
        <Stack
          direction={{ base: 'row', md: 'column' }}
          justifyContent={'space-between'}
        >
          <Text fontSize={'sm'} color={'gray'}>
            Amount
          </Text>
          <Text fontSize={'sm'} color={'white'}>
            {`${orderData.currency} ${orderData.amount}`}
          </Text>
        </Stack>
        <Button
          mt={{ base: 2, md: 0 }}
          ml={{ base: 0, md: 1 }}
          rightIcon={<MdEast color="black" />}
          type="submit"
          rounded="lg"
          color={'black'}
          colorScheme="brand"
          alignSelf={{ base: 'center', md: 'center' }}
          px={5}
          onClick={() => {
            onOrderDetailsClick(orderData.orderId);
          }}
        >
          Order details
        </Button>
      </Grid>
    </VStack>
  );
};

export default InvestmentOrderCard;

import { ACCESS_MANAGEMENT } from '../constants';

const initialState = {
  userPermissions: [],
  isLoadingUserPermissions: false,
  userPermissionsError: null,
  allRoles: [],
  isLoadingRoles: false,
  rolesError: null,
  isCreatingUser: false,
  createUserError: null
};

const accessManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACCESS_MANAGEMENT.GET_USER_PERMISSIONS:
      return { ...state, isLoadingUserPermissions: true, userPermissionsError: null };
    case ACCESS_MANAGEMENT.GET_USER_PERMISSIONS_SUCCESS:
      return { ...state, isLoadingUserPermissions: false, userPermissions: action.payload };
    case ACCESS_MANAGEMENT.GET_USER_PERMISSIONS_FAILURE:
      return { ...state, isLoadingUserPermissions: false, userPermissionsError: action.payload };
    case ACCESS_MANAGEMENT.UPDATE_USER_PERMISSIONS:
      return { ...state, isLoadingUserPermissions: true, userPermissionsError: null };
    case ACCESS_MANAGEMENT.UPDATE_USER_PERMISSIONS_SUCCESS:
      return {
        ...state,
        isLoadingUserPermissions: false,
        userPermissions: state.userPermissions.map(user =>
          user.userId === action.payload.userId 
            ? { 
                ...user, 
                roles: user.roles
                  .filter(role => !action.payload.rolesToRemove.includes(role)) // Remove roles
                  .concat(action.payload.rolesToAdd) // Add new roles
                  .filter((role, index, self) => self.indexOf(role) === index) // Ensure uniqueness
              } 
            : user
        ),
      };
    case ACCESS_MANAGEMENT.UPDATE_USER_PERMISSIONS_FAILURE:
      return { ...state, isLoadingUserPermissions: false, userPermissionsError: action.payload };
    case ACCESS_MANAGEMENT.GET_ALL_ROLES:
      return { ...state, isLoadingRoles: true, rolesError: null };
    case ACCESS_MANAGEMENT.GET_ALL_ROLES_SUCCESS:
      return { ...state, isLoadingRoles: false, allRoles: action.payload };
    case ACCESS_MANAGEMENT.GET_ALL_ROLES_FAILURE:
      return { ...state, isLoadingRoles: false, rolesError: action.payload };
      case ACCESS_MANAGEMENT.CREATE_USER:
        return { ...state, isCreatingUser: true, createUserError: null };
      case ACCESS_MANAGEMENT.CREATE_USER_SUCCESS:
        return { ...state, isCreatingUser: false };
      case ACCESS_MANAGEMENT.CREATE_USER_FAILURE:
        return { ...state, isCreatingUser: false, createUserError: action.payload };
    default:
      return state;
  }
};

export default accessManagementReducer;

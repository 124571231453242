import { useSelector } from 'react-redux';
import { ROLES } from '../constants';
import { useMemo } from 'react';
import { useCallback } from 'react';

export default function useAuthRole() {
  const role = useSelector((state) => state.auth.role);
  const isAdmin = useMemo(
    () =>
      role?.includes(ROLES.MANAGER_ROLE) || role?.includes(ROLES.ADMIN_ROLE),
    [role]
  );
  const isManager = useMemo(() => role?.includes(ROLES.MANAGER_ROLE), [role]);
  const hasAuthority = useCallback(
    (requiredRole) => role.includes(requiredRole),
    [role]
  );
  const hasAnyAuthority = useCallback(
    (...requiredRoles) =>
      requiredRoles.some((requiredRole) => role.includes(requiredRole)),
    [role]
  );

  return { isAdmin, isManager, hasAuthority, hasAnyAuthority };
}
